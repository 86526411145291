<template>
  <div class="containe">
    <template>
      <div class="integration--content">
        <button
          @click="processAddschueduler"
          class="add_button"
          id="btn-add--schedule"
        >
          <i
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.0001 4.79999L12 19.2M19.2 12L4.80005 12"
                stroke="#E6E6E6"
                stroke-width="2"
                stroke-linecap="round"
              /></svg
          ></i>
          Add
        </button>
      </div>

      <data-list
        class="mt-4"
        :loading="loading"
        :items="scheduledData"
        :customSizes="true"
        id="table--schedule"
      >
        <template #content>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 150px;
              text-align: center;
            "
            class="custom-header-rows"
          >
            <div class="elt-row max-width">
              <h3>Name</h3>
            </div>
            <div class="elt-row max-width">
              <h3>SCHEDULE</h3>
            </div>
            <div class="elt-row max-width">
              <h3>LAST RUN</h3>
            </div>
            <div class="elt-row max-width">
              <h3>NEXT RUN</h3>
            </div>
            <div
              class="elt-row max-width"
              style="white-space: nowrap; padding-left: 0 !important"
            >
              <h3>ACTIVE</h3>
            </div>
            <div class="elt-row max-width">
              <h3>LAST UPDATED</h3>
            </div>
            <div class="elt-row max-width"></div>
          </div>
          <div class="tblb--row mb-2" v-if="loading">
            <div class="tblb--inner">
              <img
                src="/img/loading.gif"
                alt="loading..."
                style="height: 64px; width: 64px"
              />
            </div>
          </div>
          <template v-else>
            <template v-if="scheduledData.length > 0">
              <div class="shcedule-overflow">
                <div
                  v-for="(elt, id) in scheduledData"
                  :key="id"
                  class="tblb--row mb-2"
                >
                  <div
                    class="tblb--inner"
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 150px;
                      text-align: center;
                    "
                  >
                    <div class="elt-row max-width">
                      <span class="test-run-name">{{ elt.name }}</span
                      ><br />
                      <span class="test-run-description">{{
                        elt.description
                      }}</span>
                    </div>
                    <div class="elt-row max-width">
                      <div class="schedule-crud">
                        <div class="text">
                          <p>Begins</p>
                          <p>Ends</p>
                          <p>Type</p>
                          <p>Expression</p>
                        </div>
                        <div class="date">
                          <el-tooltip
                            :content="
                              parseDate(
                                elt.triggerSchedule.schedDefinition
                                  .referenceDate
                              )
                            "
                          >
                            <span
                              class="test-time-cont test-time-v-color"
                              style="max-width: 100%"
                            >
                              {{
                                parseDate(
                                  elt.triggerSchedule.schedDefinition
                                    .referenceDate
                                )
                              }}
                            </span>
                          </el-tooltip>
                          <el-tooltip
                            :content="
                              parseDate(
                                elt.triggerSchedule.schedDefinition.endDate
                              )
                            "
                          >
                            <span class="test-time-cont" style="max-width: 99%">
                              {{
                                parseDate(
                                  elt.triggerSchedule.schedDefinition.endDate
                                )
                              }}
                            </span>
                          </el-tooltip>
                          <span
                            class="test-time-cont test-time-v-color"
                            style="max-width: 100%"
                          >
                            {{
                              elt.triggerSchedule.schedDefinition.scheduleType
                            }}
                          </span>
                          <span class="test-time-cont" style="max-width: 100%">
                            {{
                              elt.triggerSchedule.schedDefinition
                                .scheduleExpression
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="elt-row max-width">
                      <el-tooltip
                        :content="parseDate(getLatestRun(elt).runDate)"
                      >
                        <a
                          v-if="getLatestRun(elt).requestId.length > 0"
                          class="test-time-cont"
                          style="
                            max-width: 100%;
                            background-color: #e6e6e6;
                            border: 1px solid #e6e6e6;
                            color: #1f213e;
                          "
                          :href="
                            '/details/test-explorer/view-test/' +
                            getLatestRun(elt).requestId
                          "
                        >
                          {{ parseDate(getLatestRun(elt).runDate) }}
                        </a>
                      </el-tooltip>
                    </div>
                    <div class="elt-row max-width">
                      <div
                        v-for="item in getNextFivePendingRuns(
                          elt.triggeredRuns
                        )"
                        :key="item.runTriggeredAt"
                      >
                        <el-tooltip
                          v-if="item.runTriggeredAt"
                          class="item"
                          :content="parseDate(item.runTriggeredAt)"
                          placement="top"
                        >
                          <span
                            class="test-time-cont test-time-v-color"
                            style="
                              max-width: 99%;
                              background-color: #e6e6e6;
                              border: 1px solid #e6e6e6;
                              color: #1f213e;
                            "
                          >
                            <i class="far fa-clock mr-1"></i>
                            {{ parseDate(item.runTriggeredAt) }}
                          </span>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="elt-row max-width">
                      <span
                        v-if="elt.isActive"
                        class="test-time-cont time-active-color"
                      >
                        Active
                      </span>
                      <span v-else class="test-time-cont time-deactive-color">
                        Deactivate
                      </span>
                    </div>
                    <div class="elt-row max-width">
                      <el-tooltip :content="parseDate(elt.updatedAt)">
                        <span
                          class="test-time-cont"
                          style="
                            max-width: 100%;
                            background-color: #e6e6e6;
                            border: 1px solid #e6e6e6;
                            color: #1f213e;
                          "
                        >
                          {{ parseDate(elt.updatedAt) }}
                        </span>
                      </el-tooltip>
                    </div>
                    <div
                      class="elt-row flex--elt max-width"
                      style="justify-content: center; gap: 5px"
                    >
                      <el-tooltip content="Edit" placement="top">
                        <el-button
                          type="primary"
                          icon="el-icon-edit"
                          @click.stop="processEditschueduler(elt)"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="Delete" placement="top">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="handleDeleteschueduler(elt)"
                          circle
                          plainaddWebhook
                          size="small"
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="tblb--row mb-2" v-else>
              <div class="tblb--inner px-3 py-3">No Data ...</div>
            </div>
          </template>
        </template>
      </data-list>
    </template>
    <!-- model for add scheduled start -->
    <div class="is-scrollable webhook--modal">
      <modal :show.sync="ScheduledRunsModal" v-loading="addScheduledLoading">
        <template slot="header">
          <h5 v-show="methodeType === 'add'" class="modal-title">
            New Scheduled Run
          </h5>
          <h5 v-show="methodeType === 'edit'" class="modal-title">
            Edit Scheduled Run
          </h5>
        </template>
        <div v-show="methodeType === 'add'" class="mt--1">
          <div class="mt-4">
            <div class="mod--content">
              <el-form
                class="small-spaces"
                @submit.native.prevent="addScheduled"
              >
                <el-form-item label="Name" required>
                  <el-input
                    class="custom-width-input"
                    v-model="newScheduledRun.name"
                    placeholder="Enter a name for this Scheduled"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Description">
                  <el-input
                    type="textarea"
                    placeholder="Enter a description for this Scheduled"
                    v-model="newScheduledRun.description"
                  ></el-input>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="Test script file" required>
                      <el-select
                        style="width: 100%"
                        popper-class="custom-dropdown-popup"
                        placeholder="Select the test script file for this Scheduled"
                        v-model="newScheduledRun.fileKey"
                      >
                        <el-option
                          v-for="(item, id) in scriptFiles"
                          :value="item.value"
                          :label="item.label"
                          :key="id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Recurrence rules (Run)" required>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-radio
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="once"
                        border
                        size="medium"
                      >
                        once
                      </el-radio>
                      <el-radio
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="daily"
                        border
                        size="medium"
                      >
                        daily
                      </el-radio>
                      <el-radio
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="weekly"
                        border
                        size="medium"
                      >
                        weekly
                      </el-radio>
                      <el-radio
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="monthly"
                        border
                        size="medium"
                      >
                        monthly
                      </el-radio>
                      <el-radio
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="1 5 * * *"
                        border
                        size="medium"
                      >
                        Use crontab expression
                      </el-radio>
                    </el-col>
                  </el-row>
                  <el-row
                    :gutter="20"
                    class="scheduled--crontab"
                    v-if="
                      newScheduledRun.triggerSchedule.scheduleExpression !==
                        'once' &&
                      newScheduledRun.triggerSchedule.scheduleExpression !==
                        'weekly' &&
                      newScheduledRun.triggerSchedule.scheduleExpression !==
                        'daily' &&
                      newScheduledRun.triggerSchedule.scheduleExpression !==
                        'monthly'
                    "
                  >
                    <el-col :span="11">
                      <el-input
                        v-model="
                          newScheduledRun.triggerSchedule.scheduleExpression
                        "
                        show-word-limit
                      ></el-input>
                    </el-col>
                    <el-col :span="13">
                      <el-link
                        type="primary"
                        href="https://crontab.guru/#1_5_*_*_*"
                        target="_blank"
                      >
                        Check the format here
                      </el-link>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="Set up run schedule" required>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content=" Schedule one hour from your actual hour and it's available for three months."
                        placement="top-start"
                      >
                        <el-radio
                          v-model="newScheduledRun.methods"
                          label="simple"
                          border
                          size="medium"
                        >
                          Simple
                        </el-radio>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="  Program yourself the date and time of the start and end."
                        placement="top-start"
                      >
                        <el-radio
                          v-model="newScheduledRun.methods"
                          label="Advanced"
                          border
                          size="medium"
                        >
                          Advanced
                        </el-radio>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row
                    :gutter="20"
                    class="scheduled--crontab"
                    v-if="newScheduledRun.methods !== 'simple'"
                  >
                    <el-col :span="12">
                      <el-form-item label="Start time">
                        <el-date-picker
                          v-model="startTime"
                          :picker-options="pickerBeginDateBefore"
                          :disabledDate="pickerBeginDateBefore"
                          type="datetime"
                          placeholder="Select date and time"
                          format="yyyy-MM-dd h:mm:ss A"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="End time">
                        <el-date-picker
                          v-model="endTime"
                          :picker-options="pickerBeginDateBefore"
                          :disabledDate="pickerBeginDateBefore"
                          type="datetime"
                          placeholder="Select date and time"
                          format="yyyy-MM-dd h:mm:ss A"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-collapse class="mt-3">
                  <el-collapse-item
                    class="custom-option-tests"
                    title="Test run options"
                  >
                    <template slot="title">
                      <i
                        style="margin-left: 15px"
                        class="header-icon el-icon-setting"
                      ></i>
                      <span class="collapse--title ml-2">Test run options</span>
                    </template>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Run name">
                          <el-input
                            class="input-width-tall"
                            placeholder="Enter a name for this scheduled run"
                            v-model="newScheduledRun.runOptions.runName"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Run tags">
                          <el-input
                            placeholder="Enter tags for this scheduled run"
                            v-model="newScheduledRun.runOptions.runTags"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="Description">
                          <el-input
                            type="textarea"
                            placeholder="Enteer a description for this scheduled run"
                            v-model="newScheduledRun.runOptions.runDesc"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Browser Width">
                          <el-input
                            class="small-inputs"
                            v-model="newScheduledRun.runOptions.browserWidth"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Browser Height">
                          <el-input
                            class="small-inputs"
                            v-model="newScheduledRun.runOptions.browserHeight"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Step wait time">
                          <el-input
                            class="small-inputs"
                            v-model="newScheduledRun.runOptions.stepWait"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Run timeout (sec)">
                          <el-input
                            class="small-inputs"
                            v-model="
                              newScheduledRun.runOptions.runnerUserTimeout
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" class="my-3">
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Ignore disabled elements"
                            v-model="newScheduledRun.runOptions.ignoreDisabled"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Ignore invisible elements"
                            v-model="newScheduledRun.runOptions.ignoreInvisible"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Collect extra element data & try to fix broken selectors (slow)"
                            v-model="newScheduledRun.runOptions.slowHeal"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Generate selectors for all elements on each visited page (slow)"
                            v-model="newScheduledRun.runOptions.allSuggest"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Collect page-interaction event data for test case augmentation"
                            v-model="newScheduledRun.runOptions.tgAnalyze"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Verify suggested selectors"
                            v-model="
                              newScheduledRun.runOptions.runSelectorVerification
                            "
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Try to avoid string/text methods when generating XPath selectors"
                            v-model="
                              newScheduledRun.runOptions.xpathDownweightStrings
                            "
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Generate CSS selectors during run (slow, needed for Cypress export)"
                            v-model="
                              newScheduledRun.runOptions.generateCssSelectors
                            "
                          ></el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Environment start URL for test run"
                        >
                          <el-input
                            class="input-width-tall"
                            placeholder="https://example.com"
                            v-model="newScheduledRun.runOptions.runStartUrl"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Avoid these attributes when generating selectors"
                        >
                          <el-input
                            class="input-width-tall"
                            placeholder="Enter attributes to avoid, separated by spaces, e.g. id class href"
                            v-model="
                              newScheduledRun.runOptions.badAttributeList
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Use these attributes when generating selectors"
                        >
                          <el-input
                            class="input-width-tall"
                            placeholder="Enter attributes to use, separated by spaces, e.g. id class href"
                            v-model="
                              newScheduledRun.runOptions.goodAttributeList
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-form>
            </div>
          </div>
        </div>
        <template slot="footer"
          ><div style="gap: 5px" class="edit-accordion-btns mt-4 flex">
            <div>
              <button
                @click="addScheduled"
                v-show="methodeType === 'add'"
                class="add_button"
                native-type="submit"
              >
                Add
              </button>
              <button
                @click="editScheduled"
                v-show="methodeType === 'edit'"
                class="add_button"
                native-type="submit"
              >
                Update
              </button>
            </div>
            <div>
              <button
                class="add_button"
                style="
                  background-color: #dbdbdb;
                  border: 1px solid #dbdbdb;
                  color: #1f213e;
                "
                @click="ScheduledRunsModal = false"
              >
                Cancel
              </button>
            </div>
          </div></template
        >
        <div v-show="methodeType === 'edit'">
          <div class="mt-4">
            <div class="mod--content">
              <el-form
                class="small-spaces"
                @submit.native.prevent="editScheduled"
              >
                <el-form-item label="Name" required>
                  <el-input
                    class="input-width-tall"
                    v-model="editScheduledRun.name"
                    placeholder="Enter a name for this scheduled run"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Description">
                  <el-input
                    type="textarea"
                    placeholder="Enter a description for this scheduled run"
                    v-model="editScheduledRun.description"
                  ></el-input>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Test script file" required>
                      <el-select
                        style="width: 100%"
                        class="select-primary"
                        placeholder="Select the test script file for this scheduled run"
                        v-model="editScheduledRun.fileKey"
                      >
                        <el-option
                          v-for="(item, id) in scriptFiles"
                          class="select-primary"
                          :value="item.value"
                          :label="item.label"
                          :key="id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      class="block"
                      label="Change end date of scheduled run"
                      required
                    >
                      <el-date-picker
                        class="imput--date--simple input-width-tall"
                        v-model="editScheduledRun.triggerSchedule.endDate"
                        type="datetime"
                        placeholder="Select date and time"
                        format="yyyy-MM-dd h:mm:ss A"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Recurrence rule (Run)">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-radio
                        v-model="
                          editScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="once"
                        border
                        size="medium"
                      >
                        once</el-radio
                      >
                      <el-radio
                        v-model="
                          editScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="daily"
                        border
                        size="medium"
                      >
                        daily</el-radio
                      >
                      <el-radio
                        v-model="
                          editScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="weekly"
                        border
                        size="medium"
                      >
                        weekly</el-radio
                      >
                      <el-radio
                        v-model="
                          editScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="monthly"
                        border
                        size="medium"
                      >
                        monthly</el-radio
                      >
                      <el-radio
                        v-model="
                          editScheduledRun.triggerSchedule.scheduleExpression
                        "
                        label="1 5 * * *"
                        border
                        size="medium"
                      >
                        Use crontab expression
                      </el-radio>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <div
                      v-if="
                        editScheduledRun.triggerSchedule.scheduleExpression !==
                          'once' &&
                        editScheduledRun.triggerSchedule.scheduleExpression !==
                          'weekly' &&
                        editScheduledRun.triggerSchedule.scheduleExpression !==
                          'daily' &&
                        editScheduledRun.triggerSchedule.scheduleExpression !==
                          'monthly'
                      "
                    >
                      <el-col :span="11">
                        <el-input
                          v-model="
                            editScheduledRun.triggerSchedule.scheduleExpression
                          "
                          type="text"
                          show-word-limit
                        ></el-input>
                      </el-col>
                      <el-col :span="13">
                        <el-link
                          type="primary"
                          href="https://crontab.guru/#1_5_*_*_*"
                          target="_blank"
                        >
                          Check the format here
                        </el-link>
                      </el-col>
                    </div>
                  </el-row>
                </el-form-item>
                <el-form-item label="Scheduled run is active">
                  <el-switch
                    style="justify-content: start"
                    v-model="editScheduledRun.isActive"
                    active-color="#1F213E"
                    inactive-color="#DBDBDB"
                  >
                  </el-switch>
                </el-form-item>
                <el-collapse class="mt-3">
                  <el-collapse-item
                    class="custom-option-tests"
                    title="Test run options"
                  >
                    <template slot="title">
                      <i class="header-icon el-icon-setting"></i>
                      <span class="collapse--title ml-2">Test run options</span>
                    </template>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Run name">
                          <el-input
                            placeholder="Enter a name for this scheduled run"
                            v-model="editScheduledRun.runOptions.runName"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Run tags">
                          <el-input
                            placeholder="Enter tags for this scheduled run"
                            v-model="editScheduledRun.runOptions.runTags"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="Description">
                          <el-input
                            type="textarea"
                            placeholder="Enteer a description for this scheduled run"
                            v-model="editScheduledRun.runOptions.runDesc"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Browser Width">
                          <el-input
                            v-model="editScheduledRun.runOptions.browserWidth"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Browser Height">
                          <el-input
                            v-model="editScheduledRun.runOptions.browserHeight"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Step wait time">
                          <el-input
                            v-model="editScheduledRun.runOptions.stepWait"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Run timeout (sec)">
                          <el-input
                            v-model="
                              editScheduledRun.runOptions.runnerUserTimeout
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" class="my-3">
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Ignore disabled elements"
                            v-model="editScheduledRun.runOptions.ignoreDisabled"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Ignore invisible elements"
                            v-model="
                              editScheduledRun.runOptions.ignoreInvisible
                            "
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Collect extra element data & try to fix broken selectors (slow)"
                            v-model="editScheduledRun.runOptions.slowHeal"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Generate selectors for all elements on each visited page (slow)"
                            v-model="editScheduledRun.runOptions.allSuggest"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Collect page-interaction event data for test case augmentation"
                            v-model="editScheduledRun.runOptions.tgAnalyze"
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Verify suggested selectors"
                            v-model="
                              editScheduledRun.runOptions
                                .runSelectorVerification
                            "
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Try to avoid string/text methods when generating XPath selectors"
                            v-model="
                              editScheduledRun.runOptions.xpathDownweightStrings
                            "
                          ></el-checkbox>
                        </el-form-item>
                        <el-form-item class="no-space">
                          <el-checkbox
                            label="Generate CSS selectors during run (slow, needed for Cypress export)"
                            v-model="
                              editScheduledRun.runOptions.generateCssSelectors
                            "
                          ></el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Environment start URL for test run"
                        >
                          <el-input
                            placeholder="https://example.com"
                            v-model="editScheduledRun.runOptions.runStartUrl"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Avoid these attributes when generating selectors"
                        >
                          <el-input
                            placeholder="Enter attributes to avoid, separated by spaces, e.g. id class href"
                            v-model="
                              editScheduledRun.runOptions.badAttributeList
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="Use these attributes when generating selectors"
                        >
                          <el-input
                            placeholder="Enter attributes to use, separated by spaces, e.g. id class href"
                            v-model="
                              editScheduledRun.runOptions.goodAttributeList
                            "
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>
              </el-form>
            </div>
          </div>
          <template v-show="methodeType === 'edit'" slot="footer"
            ><div class="edit-accordion-btns mt-4 flex">
              <div>
                <button
                  class="cancel_button"
                  @click="ScheduledRunsModal = false"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button class="add_button" native-type="submit">Update</button>
              </div>
            </div></template
          >
        </div>
      </modal>
    </div>
    <!-- model for add scheduled end -->
  </div>
</template>
<script>
import axios from "axios";
import DataList from "@/components/Custom/DataList.vue";
import { Option, Select, Table, TableColumn } from "element-ui";
import moment from "moment-timezone";

export default {
  name: "ScheduledRun",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    DataList,
  },
  props: {
    scriptFiles: {
      type: Array,
    },
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      pickerBeginDateAfter: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      maxPending: 0,
      ScheduledRunsModal: false,
      scheduledData: [],
      loading: false,
      showScheduled: false,
      methodeType: "add",
      addScheduledLoading: false,
      defaultsStartDate: "",
      defaultsEndDate: "",
      tHeader: [
        { label: this.$t("scheduled_run.name"), key: "", width: "10%" },
        { label: this.$t("scheduled_run.schedule"), key: "", width: "26%" },
        { label: this.$t("scheduled_run.last_run"), key: "", width: "18%" },
        { label: this.$t("scheduled_run.next_run"), key: "", width: "18%" },
        { label: this.$t("scheduled_run.is_active"), key: "", width: "10%" },
        { label: this.$t("scheduled_run.last_updated"), key: "", width: "18%" },
        { label: "", key: "", width: "10%" },
      ],
      startTime: "",
      endTime: "",
      newScheduledRun: {
        name: "",
        description: "",
        fileKey: null,
        methods: "simple",
        triggerSchedule: {
          referenceTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          referenceDate: "",
          endDate: "",
          scheduleType: "",
          scheduleExpression: "once",
        },
        scheduledOrigin: "add",
        runOptions: {
          runName: "",
          runDesc: "",
          runTags: "",
          browserWidth: 1920,
          browserHeight: 1080,
          stepWait: 5.0,
          ignoreInvisible: true,
          ignoreDisabled: true,
          slowHeal: false,
          allSuggest: false,
          tgAnalyze: true,
          runnerUserTimeout: 3600.0,
          runSelectorVerification: false,
          runStartUrl: "",
          badAttributeList: "",
          goodAttributeList: "",
          xpathDownweightStrings: false,
          generateCssSelectors: false,
        },
      },
      editScheduledRun: {
        name: "",
        description: "",
        fileKey: null,
        endDate: "",
        triggerId: "",
        isActive: false,
        triggerSchedule: {
          endDate: "",
          scheduleType: "recurrence",
          scheduleExpression: "once",
        },
        runOptions: {
          runName: "",
          runDesc: "",
          runTags: "",
          browserWidth: 1920,
          browserHeight: 1080,
          stepWait: 5.0,
          ignoreInvisible: true,
          ignoreDisabled: true,
          slowHeal: false,
          allSuggest: false,
          tgAnalyze: true,
          runnerUserTimeout: 3600.0,
          runSelectorVerification: false,
          runStartUrl: "",
          badAttributeList: "",
          goodAttributeList: "",
          xpathDownweightStrings: false,
          generateCssSelectors: false,
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },

  created() {
    this.getScheduledListe();
    let newDate = new Date();
    let toDayDate = new Date(newDate);
    this.defaultsEndDate = toDayDate.setDate(toDayDate.getDate() + 90);
    this.defaultsStartDate = newDate.setHours(newDate.getHours() + 1);
  },
  methods: {
    parseDate(val) {
      if (val) return moment(val).format("MMM Do YYYY, h:mm:ss a");
    },

    validatorRequired(val) {
      let methode = null;
      if (val === "add") {
        methode = { ...this.newScheduledRun };
      } else {
        methode = { ...this.editScheduledRun };
      }
      if (methode.name === "") {
        this.$notify({
          type: "danger",
          message: "the name field is required ",
        });
        return 1;
      }
      if (methode.fileKey === null) {
        this.$notify({
          type: "danger",
          message: "the test script file field is required ",
        });
        return 1;
      }
      if (methode.triggerSchedule.scheduleType === "crontab") {
        let splitExpression = methode.triggerSchedule.scheduleExpression
          .trim()
          .split(" ");
        if (splitExpression.length > 5) {
          this.$notify({
            type: "danger",
            message: "invalid crontab format.",
          });
          return 1;
        } else {
          return 0;
        }
      }
    },
    async addScheduled() {
      this.formatScheduleTypeEspressionDate();
      if (this.validatorRequired("add") === 1) return;

      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .post(
          `${process.env.VUE_APP_API_URL_PREFIX}/schedruns/v1`,
          this.newScheduledRun,
          headers
        )
        .then((response) => {
          if (response.message === "Request failed with status code 400") {
            this.$notify({
              type: "danger",
              message:
                "Triggered runs cannot be scheduled to start in the past.",
            });
          } else if (response.data.status === "success") {
            this.$notify({
              type: "success",
              message: "Your test is scheduled",
            });
            this.getScheduledListe();
          } else {
            this.$notify({
              type: "danger",
              message: "an error has occurred ",
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$notify({
            type: "danger",
            message: "an error has occurred",
          });
        });
      this.ScheduledRunsModal = false;
    },
    editScheduled() {
      this.formatScheduleTypeEspressionDate("edit");
      if (this.validatorRequired("edit") === 1) return;
      const editScheduledRun = this.editScheduledRun;
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/schedruns/v1/${this.editScheduledRun.triggerId}`,
          editScheduledRun,
          headers
        )
        .then((response) => {
          if (!response.message === "Request failed with status code") {
            this.$notify({
              type: "danger",
              message: "an error has occurred",
            });
          } else if (response.data.status === "success") {
            this.$notify({
              type: "success",
              message: response.data.message,
            });
            this.getScheduledListe();
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$notify({
            type: "danger",
            message: "an error has occurred",
          });
        });
      this.methodeType = "add";
      this.ScheduledRunsModal = false;
    },
    formatScheduleTypeEspressionDate(val) {
      if (val === "edit") {
        this.editScheduledRun.triggerSchedule.endDate = moment(
          this.editScheduledRun.triggerSchedule.endDate
        ).format("YYYY-MM-DDTHH:mm:ss");
        if (
          this.editScheduledRun.triggerSchedule.scheduleExpression === "once" ||
          this.editScheduledRun.triggerSchedule.scheduleExpression ===
            "weekly" ||
          this.editScheduledRun.triggerSchedule.scheduleExpression ===
            "daily" ||
          this.editScheduledRun.triggerSchedule.scheduleExpression === "monthly"
        ) {
          this.editScheduledRun.triggerSchedule.scheduleType = "recurrence";
        } else {
          this.editScheduledRun.triggerSchedule.scheduleType = "crontab";
        }
      } else {
        if (this.newScheduledRun.methods === "simple") {
          this.newScheduledRun.triggerSchedule.endDate = moment()
            .add(90, "days")
            .format("YYYY-MM-DDTHH:mm:ss");
          this.newScheduledRun.triggerSchedule.referenceDate = moment()
            .add(1, "hours")
            .format("YYYY-MM-DDTHH:mm:ss");
        } else {
          this.newScheduledRun.triggerSchedule.referenceDate = moment(
            this.startTime
          ).format("YYYY-MM-DDTHH:mm:ss");
          this.newScheduledRun.triggerSchedule.endDate = moment(
            this.endTime
          ).format("YYYY-MM-DDTHH:mm:ss");
        }
        if (
          this.newScheduledRun.triggerSchedule.scheduleExpression === "once" ||
          this.newScheduledRun.triggerSchedule.scheduleExpression ===
            "weekly" ||
          this.newScheduledRun.triggerSchedule.scheduleExpression === "daily" ||
          this.newScheduledRun.triggerSchedule.scheduleExpression === "monthly"
        ) {
          this.newScheduledRun.triggerSchedule.scheduleType = "recurrence";
        } else {
          this.newScheduledRun.triggerSchedule.scheduleType = "crontab";
        }
      }
    },
    async getScheduledListe() {
      this.loading = true;
      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .get(`${process.env.VUE_APP_API_URL_PREFIX}/schedruns/v1/all`, headers)
        .then((response) => {
          console.log(response);

          if (response.data) {
            this.scheduledData = Object.values(response.data.response);
          } else {
            this.scheduledData = [];

            const responseArray = response.message.split(" ");
            // if (responseArray.includes("404")) {
            //   this.$notify({
            //     type: "warning",
            //     message: "No scheduled runs found",
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });

      this.loading = false;
    },
    async processEditschueduler(row) {
      this.editScheduledRun.name = row.name;
      this.editScheduledRun.description = row.description;
      this.editScheduledRun.isActive = row.isActive;
      this.editScheduledRun.fileKey = row.fileKey;
      this.editScheduledRun.triggerSchedule.endDate =
        row.triggerSchedule.endDate;
      this.editScheduledRun.triggerSchedule.scheduleType =
        row.triggerSchedule.schedDefinition.scheduleType;
      this.editScheduledRun.triggerSchedule.scheduleExpression =
        row.triggerSchedule.schedDefinition.scheduleExpression;
      this.editScheduledRun.runOptions.runDesc = row.providedRunOptions.runDesc;
      this.editScheduledRun.runOptions.runTags = row.providedRunOptions.runTags;
      this.editScheduledRun.runOptions.browserWidth =
        row.providedRunOptions.browserWidth;
      this.editScheduledRun.runOptions.runName = row.providedRunOptions.runName;
      this.editScheduledRun.runOptions.browserHeight =
        row.providedRunOptions.browserHeight;
      this.editScheduledRun.runOptions.stepWait =
        row.providedRunOptions.stepWait;
      this.editScheduledRun.runOptions.ignoreInvisible =
        row.providedRunOptions.ignoreInvisible;
      this.editScheduledRun.runOptions.slowHeal =
        row.providedRunOptions.slowHeal;
      this.editScheduledRun.runOptions.allSuggest =
        row.providedRunOptions.allSuggest;
      this.editScheduledRun.runOptions.tgAnalyze =
        row.providedRunOptions.tgAnalyze;
      this.editScheduledRun.runOptions.runnerUserTimeout =
        row.providedRunOptions.runnerUserTimeout;
      this.editScheduledRun.runOptions.runSelectorVerification =
        row.providedRunOptions.runSelectorVerification;
      this.editScheduledRun.runOptions.runStartUrl =
        row.providedRunOptions.runStartUrl;
      this.editScheduledRun.runOptions.badAttributeList =
        row.providedRunOptions.badAttributeList;
      this.editScheduledRun.runOptions.goodAttributeList =
        row.providedRunOptions.goodAttributeList;
      this.editScheduledRun.runOptions.xpathDownweightStrings =
        row.providedRunOptions.xpathDownweightStrings;
      this.editScheduledRun.runOptions.generateCssSelectors =
        row.providedRunOptions.generateCssSelectors;
      this.editScheduledRun.triggerId = row.triggerId;
      this.ScheduledRunsModal = true;
      this.methodeType = "edit";
    },
    processAddschueduler() {
      this.methodeType = "add";
      this.ScheduledRunsModal = true;
    },
    async handleDeleteschueduler(row) {
      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .delete(
          `${process.env.VUE_APP_API_URL_PREFIX}/schedruns/v1/${row.triggerId}`,
          headers
        )
        .then((response) => {
          if (!response.message === "Request failed with status code") {
            this.$notify({
              type: "danger",
              message: "an error has occurred",
            });
            this.loading = false;
          } else if (response.data.status === "success") {
            this.$notify({
              type: "success",
              message: "Your scheduled run was deleted successfully",
            });
            this.getScheduledListe();
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.$notify({
            type: "danger",
            message: "an error has occurred",
          });
        });
    },
    getNextFivePendingRuns(val) {
      // sort by runTriggeredAt -> this is a text datetime, which is sortable alphabeticaly
      const sortDesc = (a, b) => {
        if (a.runTriggeredAt > b.runTriggeredAt) {
          return 1;
        } else if (a.runTriggeredAt < b.runTriggeredAt) {
          return -1;
        } else {
          return 0;
        }
      };
      // get first five items in the sorted array that are later than the current time
      const dtNow = moment().tz("UTC").format("YYYY-MM-DDTHH:mm:ssZ");
      return val
        .filter(
          (val) => val.runStatus === "scheduled" && val.runTriggeredAt > dtNow
        )
        .sort(sortDesc)
        .slice(0, 5);
    },
    getLatestRun(val) {
      // get run with max runTriggeredAt -> will be the latest executed run
      const pendingRuns = val.triggeredRuns.filter(
        (val) => val.runStatus !== "scheduled"
      );

      if (pendingRuns.length > 0) {
        // get item with max runTriggeredAt
        const latestRun = pendingRuns.reduce((accumulator, currentItem) => {
          if (currentItem.runTriggeredAt > accumulator.runTriggeredAt) {
            return currentItem;
          } else {
            return accumulator;
          }
        });
        return {
          requestId: latestRun.runTestRequestId,
          runDate: latestRun.runExecutedAt,
        };
      }

      return { requestId: "", runDate: "" };
    },
  },
};
</script>
<style>
/* .el-select-dropdown {
  width: 50%;
  transform-origin: center bottom;
  z-index: 2005;
  position: fixed;
  top: 94px;
  left: 401px;
} */
</style>
<style scoped lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.imput--date--simple {
  width: 100% !important;
  height: 5.9vh !important;
}

.scheduled--crontab {
  margin-top: 15px;

  input {
    height: 40px !important;
  }

  .el-date-editor {
    width: 100% !important;
  }
}

.test-time-v-color {
  border: solid 1px #d6d6d6;
  background: #d6d6d6;
  color: var(--main-blue-color);
}
.shcedule-overflow {
  height: 69vh;
  overflow-y: scroll;
}
.time-active-color {
  border: solid 1px rgb(230, 174, 108) !important;
  background: rgb(230, 174, 108) !important;
  color: #e6e6e6 !important;
}

.time-deactive-color {
  border: solid 1px rgb(230, 174, 108) !important;
  background: rgb(230, 174, 108) !important;
  color: #e6e6e6 !important;
}
.small-inputs {
  max-width: 100px;
}
.small-inputs input {
  max-width: 100px !important;
}

.test-disable-v-color {
  border: solid 1px #f56c6c !important;
  background: #f9ebeb !important;
  color: #f56c6c !important;
}

.test-time-cont {
  margin: 0 1px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-datetime-picker .block:last-child {
  border-right: none;
}

.demo-datetime-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
textarea::placeholder {
  color: #6e6e6e !important;
}

.modal-body {
  height: 84vh;
  overflow-x: auto;
}

.modal-content {
  max-height: 95vh !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.schedule-crud {
  width: 100%;
  display: flex;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;

    p {
      margin-right: 4px;
      font-size: 0.8em;
      color: var(--main-blue-color);
      margin-top: 5px;
      font-weight: bold;
      text-align: right;
    }
  }

  .date {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
}

.test-run-description {
  font-size: 0.85em;
  color: var(--main-blue-color);
}
</style>
