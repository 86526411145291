<template>
  <div class="suite-list-item-page">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
            "
          >
            <div style="display: flex; gap: 10px; align-items: center">
              <h3 style="white-space: nowrap; margin-bottom: 0">All Runs</h3>
              <el-input class="custom-pad" placeholder="Search" v-model="query">
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-search"
                  style="
                    transform: rotate(0deg) !important;
                    font-size: 20px;
                    margin-left: 12px;
                  "
                ></i>
              </el-input>
            </div>
            <div>
              <span style="margin-right: 5px">Tests: {{ total }}</span>
              <span style="margin-right: 5px">Show</span>
              <el-select
                class="small--select icon-pad"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <text> per page </text>
            </div>
          </div>
        </template>

        <div class="re-run-modal-container">
          <modal :show.sync="showReRunModal">
            <template slot="header">
              <h5 class="modal-title" id="exampleModalLabel">
                Retry this test run
              </h5>
            </template>
            <div>
              <div class="re-run-modal">
                <div class="run-name">
                  <label class="model-label" for="run_name"
                    >New run name* :</label
                  >
                  <input
                    type="text"
                    id="run_name"
                    name="run_name"
                    placeholder="Enter a name for this test run"
                    v-model="run_name"
                  /><br />
                  <label class="model-label" for="run_description"
                    >New run description:</label
                  >
                  <textarea
                    id="run_description"
                    name="run_description"
                    rows="2"
                    placeholder="Enter an optional description or comments for this test run"
                    v-model="run_description"
                  ></textarea
                  ><br />
                </div>

                <div class="mt-2">
                  <h4 class="fbb">Test Script Options</h4>
                  <div class="three-input">
                    <div class="tic">
                      <label class="model-label" for="browser_width"
                        >Browser Width</label
                      >
                      <input
                        type="text"
                        id="browser_width"
                        name="browser_width"
                        placeholder="1920"
                        v-model="browser_width"
                      />
                    </div>
                    <div class="tic">
                      <label class="model-label" for="browser_height"
                        >Browser Height</label
                      >
                      <input
                        type="text"
                        id="fname"
                        name="browser_height"
                        placeholder="1980"
                        v-model="browser_height"
                      />
                    </div>

                    <div class="tic">
                      <label class="model-label" for="max_wait_time"
                        >Max Wait Time</label
                      >
                      <input
                        type="text"
                        id="max_wait_time"
                        name="max_wait_time"
                        placeholder="5.0"
                        v-model="max_wait_time"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-checks-container">
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="ignore_disabled_elements"
                      name="ignore_disabled_elements"
                      value="Bike"
                      v-model="ignore_disabled_elements"
                    />
                    <label for="ignore_disabled_elements"
                      >Ignore disabled elements</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="ignore_invisible_elements"
                      name="ignore_invisible_elements"
                      value="Bike"
                      v-model="ignore_invisible_elements"
                    />
                    <label for="ignore_invisible_elements"
                      >Ignore invisible elements</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="extra_element_data"
                      name="extra_element_data"
                      value="Bike"
                      v-model="extra_element_data"
                    />
                    <label for="extra_element_data"
                      >Collect extra element data & try to fix broken selectors
                      (slow)</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="all_suggest"
                      name="all_suggest"
                      value="Bike"
                      v-model="all_suggest"
                    />
                    <label for="all_suggest"
                      >Generate selectors for all elements on each visited page
                      (slow)</label
                    >
                  </div>
                  <div class="modal-checks">
                    <input
                      type="checkbox"
                      id="collect_page_interaction_data"
                      name="collect_page_interaction_data"
                      value="Bike"
                      v-model="collect_page_interaction_data"
                    />
                    <label for="collect_page_interaction_data"
                      >Collect page-interaction event data for test case
                      augmentation</label
                    >
                  </div>
                </div>
              </div>
              <el-checkbox class="mt-4" v-model="checkedOrg"
                >Share test run with organization</el-checkbox
              >
            </div>
            <template slot="footer">
              <button class="cancel_button" @click="showReRunModal = false">
                Cancel
              </button>
              <button class="add_button" @click="RerunItem">
                Retry Test Run
              </button>
            </template>
          </modal>
        </div>

        <div class="edit-test-modal-container">
          <modal class="small-modal" :show.sync="showEditTestModal">
            <template slot="header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update test run name or description
              </h5>
            </template>
            <div>
              <div class="re-run-modal">
                <div class="run-name">
                  <label class="model-label" for="run_name"
                    >New run name*</label
                  >
                  <input
                    type="text"
                    id="run_name"
                    name="run_name"
                    placeholder="Enter a name for this test run"
                    v-model="run_name"
                  /><br />

                  <div class="edit-test-select">
                    <label class="model-label">New run state</label>
                    <el-select
                      class="select-primary w-100 h-100"
                      v-model="editTestOpt.editTestOptCurrent"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in editTestOpt.editTestDropDownOpt"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <br />
                  <label class="model-label" for="run_description"
                    >New run description</label
                  >
                  <textarea
                    id="run_description"
                    name="run_description"
                    rows="5"
                    placeholder="Enter description"
                    v-model="run_description"
                  ></textarea
                  ><br />
                </div>
              </div>
            </div>
            <template slot="footer">
              <button class="cancel_button" @click="showEditTestModal = false">
                Cancel
              </button>
              <button class="add_button" @click="editItem()">Update</button>
            </template>
          </modal>
        </div>

        <div class="all-runs-table">
          <!-- {{ log("suite table", suiteData) }} -->
          <data-list
            :loading="loading"
            :customSizes="true"
            :items="filteredResultSet"
            ref="dataList"
            style="overflow-y: scroll; height: 53vh"
          >
            <template style="overflow: hidden" #content>
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 100px 1fr 50px 1fr;
                  text-align: center;
                "
                class="custom-header-rows"
              >
                <div class="elt-row max-width">
                  <h3>Name</h3>
                </div>
                <div class="elt-row max-width">
                  <h3>Tags</h3>
                </div>
                <div class="elt-row max-width">
                  <h3>Status</h3>
                </div>
                <div class="elt-row max-width">
                  <h3>Created</h3>
                </div>
                <div
                  class="elt-row max-width"
                  style="white-space: nowrap; padding-left: 0 !important"
                >
                  <h3>Steps</h3>
                </div>
                <div class="elt-row max-width"></div>
              </div>
              <div
                v-for="elt in filteredResultSet"
                :key="elt.id"
                class="tblb--row mb-2"
                :class="elt.status === 'processing' && 'tblb--processing'"
                @click="_onRowClicked(elt)"
              >
                <div v-if="elt.status === 'processing'" class="tblb--over">
                  <div class="tblbo--img" v-if="elt.thumbnail">
                    <img
                      :src="`data:image/png;base64,${elt.thumbnail}`"
                      alt="screenshot of current step"
                    />
                  </div>
                  <div class="mx-5 fl--center">
                    <span class="s--icon mr-4">
                      <i class="el-icon-loading"></i>
                    </span>
                    <span class="s--name">{{ elt.runName }}</span>
                  </div>
                  <div class="tblo--step">
                    <span class="s--txt">
                      Processing Step:
                      <u class="h2">{{ elt.xpathCount }}</u>
                    </span>
                  </div>
                  <el-button
                    type="danger"
                    @click.stop="CancelrunItem(elt)"
                    plain
                    size="small"
                    class="mr-5"
                  >
                    Cancel
                    <i class="el-icon-scissors ml-2"></i>
                  </el-button>
                </div>
                <div
                  class="tblb--inner"
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr 100px 1fr 50px 1fr;
                    text-align: center;
                  "
                >
                  <div class="elt-row max-width">
                    <span class="test-run-name">{{ elt.runName }}</span>
                    <br />
                    <span v-if="elt.status === 'passed'" class="test-time-cont">
                      <i class="far fa-clock mr-1"></i>
                      {{ secondsToRealTime(elt) }}
                    </span>
                  </div>
                  <div class="elt-row max-width">
                    <span v-if="elt.runDesc" class="run-tags">
                      {{ elt.runDesc }}
                    </span>

                    <span v-if="elt.runTags" class="run-tags-cont">
                      <span v-for="(tags, index) in elt.runTags" :key="index">
                        <span
                          v-if="paserTag(tags) == 'native'"
                          class="run-tags"
                        >
                          {{ tags }}
                        </span>
                        <span v-else class="run-tags is-flex">
                          <img
                            :src="paserTag(tags).img"
                            alt="sauce lab tag"
                            width="20"
                          />
                          {{ paserTag(tags).label }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="elt-row max-width">
                    <el-alert
                      class="no--close"
                      :title="elt.status"
                      :type="
                        elt.status === 'passed'
                          ? 'success'
                          : elt.status === 'failed'
                          ? 'error'
                          : 'danger'
                      "
                      show-icon
                    ></el-alert>
                  </div>
                  <div class="elt-row max-width">
                    <span class="elt--txt text-date">
                      {{ formatDate(elt.acceptedAt) }}
                    </span>
                  </div>
                  <div class="elt-row max-width">
                    <span class="elt--value">
                      {{ elt.stepCount }}
                    </span>
                  </div>
                  <div
                    class="elt-row max-width"
                    style="display: flex; gap: 5px; justify-content: center"
                  >
                    <el-tooltip
                      id="listItemPageTableEditTestBtn"
                      class="item"
                      effect="light"
                      content="Edit this test"
                      placement="top"
                    >
                      <el-button
                        class="ml-2"
                        type="primary"
                        icon="el-icon-edit"
                        @click.stop="editTestModal(elt)"
                        circle
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>

                    <el-tooltip
                      id="listItemPageTableRerunTestBtn"
                      class="item"
                      effect="light"
                      content="Rerun this test"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-refresh"
                        @click.stop="RerunItemModal(elt)"
                        circle
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>

                    <el-tooltip
                      id="listItemPageTableDeleteTestBtn"
                      class="item"
                      effect="light"
                      content="Delete this test"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        @click.stop="deleteItem(elt, 'sauce_labs')"
                        circle
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </data-list>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap for-pagination-circle-cursor"
        >
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :total="total"
            :page-count="pages"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import DataList from "@/components/Custom/DataList.vue";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { touches } from "d3";
import swal from "sweetalert2";
export default {
  layout: "DashboardLayout",
  props: {
    getSuiteFunction: Function,
    suiteId: String,
    suiteItems: Object,
    loading: Boolean,
  },
  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    DataList,
  },

  data() {
    return {
      editTestOpt: {
        editTestOptCurrent: "Select",
        editTestDropDownOpt: ["passed", "failed", "finished"],
      },
      helpsauce: [
        {
          value: "Linux",
          label: "Linux",
          img: "/img/linux.png",
        },
        {
          value: "Windows 11",
          label: "Windows 11",
          img: "/img/windows.png",
        },
        {
          value: "Windows 10",
          label: "Windows 10",
          img: "/img/windows.png",
        },
        {
          value: "Windows 8.1",
          label: "Windows 8.1",
          img: "/img/windows.png",
        },
        {
          value: "Windows 8",
          label: "Windows 8",
          img: "/img/windows.png",
        },
        {
          value: "macOS 13",
          label: "Ventura",
          img: "/img/macos.png",
        },
        {
          value: "macOS 12",
          label: "Monterey",
          img: "/img/macos.png",
        },
        {
          value: "macOS 11.00",
          label: "Big Sur",
          img: "/img/macos.png",
        },
        {
          value: "macOS 10.5",
          label: "Catalina",
          img: "/img/macos.png",
        },
        {
          value: "ios Simulator",
          label: "IOS Simulator",
          img: "/img/ios.webp",
        },
        {
          value: "ios Real",
          label: "IOS Real",
          img: "/img/ios.webp",
        },
        {
          value: "Android Simulator",
          label: "Android Simulator",
          img: "/img/android.png",
        },
        {
          value: "Android Real",
          label: "Android Real",
          img: "/img/android.png",
        },
        {
          label: "Chrome",
          value: "chrome",
          img: "/img/chrome.png",
        },
        {
          label: "Firefox",
          value: "firefox",
          img: "/img/Firefox.png",
        },
        {
          label: "MicrosoftEdge",
          value: "MicrosoftEdge",
          img: "/img/Edge.png",
        },
        {
          label: "Safari",
          value: "Safari",
          img: "/img/Safari.png",
        },
      ],
      tHeader: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("list_item_page.tags"),
          key: "runDesc",
          sortable: false,
        },
        { label: this.$t("list_item_page.status"), key: "", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        {
          label: this.$t("list_item_page.total_steps"),
          key: "",
          sortable: false,
          width: "12%",
        },
        { label: "", key: "", sortable: false },
      ],
      forEditTest: "",
      forEditTestRowReqId: "",
      showEditTestModal: false,
      forReRunTest: "",
      showReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "true",
      ignore_invisible_elements: "true",
      collect_page_interaction_data: "true",
      extra_element_data: false,
      downweight_strings: false,
      all_suggest: false,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timout_cancel_run: "",
      timout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      filesList: [],
      addTestModal: false,
      log: console.log,
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      filteredTotal: [],
      filteredResults: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      // suiteItems: {},
      suiteData: [],
      user: null,
      polling: 5000,
      checkedOrg: false,
    };
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "geSuitetList",
      immediate: false,
      deep: true,
    },
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    paserTag(val) {
      let valReturn = "native";
      this.helpsauce.map((item) => {
        if (item.value == val || item.label == val) valReturn = item;
      });
      return valReturn;
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    formatDate(val) {
      let day_display = "";
      let time_display = "";
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (time_zone.toLowerCase().includes("america")) {
        day_display = moment(val * 1000).format("MMM Do YYYY");
        time_display = moment(val * 1000).format("LT");
      } else {
        day_display = moment(val * 1000).format("Do MMM YYYY");
        time_display = moment(val * 1000).format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    _onRowClicked(elt) {
      if (elt.requestId) {
        this.$router.push({
          name: "View Item",
          params: {
            id: elt.requestId,
          },
        });
      }
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    editTestModal(row) {
      this.run_name = row.runName;
      this.run_description = row.runDesc;
      this.editTestOpt.editTestOptCurrent = row.status;
      this.forEditTestRowReqId = row.requestId;
      this.showEditTestModal = true;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    // tableRowClassName({ row, rowIndex }) {
    //   if (this.items[0].status === "processing" && rowIndex === 0) {
    //     return "onlyForRunningTestRow";
    //   }
    //   return "";
    // },
    onRowClicked(item, index, event) {
      const element = event.srcElement.id;
      if (element === "") {
        this.$router.push({
          name: "View Item",
          params: {
            id: item.requestId,
          },
        });
      }
    },
    getListDebounced: _.debounce(async function () {
      await this.geSuitetList();
    }, 300),

    pollData() {
      this.polling = setInterval(() => {
        this.getListDebounced();
      }, 5000);
    },

    formatColDate(row, col, value, index) {
      let date = value.slice(0, 10);
      let time = "";
      for (var i = 0; i < value.length; i++) {
        if (value[i] == "T") {
          time = value.slice(i + 1, i + 6);
        }
      }
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }
      let html = (
        <div>
          <div>{date}</div>
          <div>
            {time} {time_zone}
          </div>
        </div>
      );
      return html;
    },
    async geSuitetList() {
      if (this.suiteItems) {
        this.suiteData = Object.values(this.suiteItems);
        // console.log(Object.values(this.suiteData));
        this.total = this.filteredResultSet.length;
        console.log("filter", this.filteredResultSet);
        // Object.values(this.suiteItems).map((v, i) => {
        //   console.log(v);
        //   this.suiteData.push(v);
        // });
      }
      // console.log("suiteItems", this.suiteItems);
      // if (this.suiteId) {
      //   axios
      //     .get(
      //       process.env.VUE_APP_API_URL_PREFIX + `/testsuite/v1/${this.suiteId}`
      //     )
      //     .then((response) => {
      //       console.log(
      //         "getting data fron test suite id",
      //         response.data.response
      //       );
      //       this.suiteItems = response.data.response;
      //       this.total = this.filteredResultSet.length;
      //     })
      //     .catch((error) => {
      //       this.$notify({
      //         type: "danger",
      //         message: "Oops, something went wrong!",
      //       });
      //     });
      // }
      // if (true) {
      //   axios
      //     .get(
      //       `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/ada8e6068857703915bee60e69cd0f79`
      //     )
      //     .then((response) => {
      //       console.log("getting data fron test suite id", response);
      //       // this.suiteItems = response.data.response;
      //       // this.total = this.filteredResultSet.length;
      //     })
      //     .catch((error) => {
      //       this.$notify({
      //         type: "danger",
      //         message: "Oops, something went wrong!",
      //       });
      //     });
      // }
    },
    parseBrowserWidth(strWidth) {
      const width = parseInt(strWidth, 10);
      if (width === undefined || width === null) return 1920;
      if (width > 1920) return 1920;
      if (width < 800) return 800;
      return width;
    },
    parseBrowserHeight(strHeight) {
      const height = parseInt(strHeight, 10);
      if (height === undefined || height === null) return 1080;
      if (height > 1080) return 1080;
      if (height < 600) return 600;
      return height;
    },
    parseStepWait(strWait) {
      const waitSec = parseFloat(strWait);
      if (waitSec && waitSec <= 30.0 && waitSec >= 0.5) {
        return waitSec;
      }
      return 5.0;
    },
    parseBrowserResolution(browserResolution) {
      const splitValue = browserResolution.split("x");
      return {
        browserWidth: parseInt(splitValue[0], 10),
        browserHeight: parseInt(splitValue[1], 10),
      };
    },
    createRedoRequestBody(states) {
      const browserRes = this.parseBrowserResolution(
        states?.runOptions.runner_resolution ?? "1920x1080"
      );
      const step = parseFloat(states?.runOptions.runner_step_wait ?? "5.0");
      return JSON.stringify({
        runName: states.runName,
        runDesc: states.runDesc,
        browserWidth: this.parseBrowserWidth(browserRes.browserWidth),
        browserHeight: this.parseBrowserHeight(browserRes.browserHeight),
        stepWait: this.parseStepWait(step),
        ignoreInvisible: states?.runOptions.filter_invisible_elements ?? true,
        ignoreDisabled: states?.runOptions.filter_disabled_elements ?? true,
        slowHeal: states?.runOptions.handle_failure === "suggest-xpaths",
        allSuggest: states?.runOptions.allsuggest_enabled ?? false,
        tgAnalyze: states?.runOptions.use_tg_analyze ?? true,
      });
    },

    async RerunItemModal(row) {
      console.log(row);
      this.forReRunTest = row;
      this.showReRunModal = true;
      this.run_name = row.runOptions.test_run_name;
      this.run_description = row.runOptions.test_run_description;
      this.ignore_disabled_elements = row.runOptions.filter_disabled_elements;
      this.ignore_invisible_elements = row.runOptions.filter_invisible_elements;
      this.extra_element_data = true;
      this.all_suggest = row.runOptions.allsuggest_enabled;
      this.collect_page_interaction_data = row.runOptions.use_tg_analyze;
      this.handle_failure = row.runOptions.handle_failure;
    },

    async RerunItem() {
      const row = this.forReRunTest;
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };
      let reRunWithUpdatedFields = JSON.stringify({
        runName: this.run_name,
        runDesc: this.run_description,
        browserWidth: this.browser_width,
        browserHeight: this.browser_height,
        stepWait: this.max_wait_time,
        ignoreInvisible: this.ignore_invisible_elements,
        ignoreDisabled: this.ignore_disabled_elements,
        slowHeal: this.handle_failure === "suggest-xpaths",
        allSuggest: this.all_suggest,
        tgAnalyze: this.collect_page_interaction_data,
        shareRun: this.checkedOrg ? "org" : null,
      });
      try {
        let res = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX + `/testrun/v1/redo/${requestId}`,
          reRunWithUpdatedFields,
          { headers }
        );
        if (res.status != 202) {
          this.$notify({
            type: "warning",
            message: `Could not rerun test! ${res.data.message}`,
          });
          this.checkedOrg = false;
        } else {
          this.$notify({
            type: "success",
            message: "Rerunning test!",
          });
          this.forReRunTest = "";
          this.checkedOrg = false;
        }
      } catch (error) {
        this.$notify({
          type: "warning",
          message: `${error.data.message}`,
        });
      }
      this.checkedOrg = false;
      this.showReRunModal = false;
    },

    async CancelrunItem(row) {
      const requestId = row.requestId;

      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX +
            `/testrun/v1/cancel/${requestId}`,
          { headers }
        )
        .then(async (response) => {
          this.$notify({
            type: "success",
            message: "Stopped test run!",
          });

          await this.getSuiteFunction();
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: "Error stopping test run!",
          });
        });
    },

    async deleteItem(row) {
      const requestId = row.requestId;
      // console.log("requestId: ", requestId);
      // if (this.$isDemo == 1 && ["1", "2"].includes(id)) {
      //   this.$notify({
      //     type: "danger",
      //     message: "You are not allowed not change data of default items.",
      //   });
      //   return;
      // }
      const confirmation = await swal.fire({
        title: "Test Run " + row.runName + " will be removed?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          // TODO: check with backend on permissions to delete
          // await this.$store.dispatch("profile/me");
          // this.user = await { ...this.$store.getters["profile/me"] };
          // if (this.user.roles[0].name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          await this.$store.dispatch("items/destroy", requestId);
          await this.geSuitetList();
          this.$notify({
            type: "success",
            message: "Test deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    viewItem(row) {
      this.$router.push({ name: "View Item", params: { id: row.requestId } });
    },
    async editItem() {
      console.log("editItem is being called");
      let formValues = {
        nameChange: this.run_name,
        statusChange: this.editTestOpt.editTestOptCurrent,
        descChange: this.run_description,
      };

      if (formValues) {
        const params = {
          id: this.forEditTestRowReqId,
          values: formValues,
        };
        this.showEditTestModal = false;
        this.run_name = "";
        this.editTestOpt.editTestOptCurrent = "";
        this.run_description = "";
        await this.$store.dispatch("items/update", params);
        await this.geSuitetList();
        this.$notify({
          type: "success",
          message: this.run_name + " updated successfully.",
        });
      }
    },
    async sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      await this.geSuitetList();
    },
  },
  computed: {
    filteredResultSet() {
      let suitItemForTable = Object.values(this.suiteItems);
      return suitItemForTable.filter((item) => {
        if (this.query) {
          const query = this.query.toLowerCase();

          if (item.runName.toLowerCase().includes(query)) return item;

          let match = null;
          if (item.runTags) {
            item.runTags.filter(function (str) {
              match = str.includes(query);
            });
            if (match) return item;
          }

          if (item.runDesc) {
            if (item.runDesc.toLowerCase().includes(query)) return item;
          }

          if (item.status) {
            if (item.status.toLowerCase().includes(query)) return item;
          }
        } else {
          return item;
        }
      });
    },

    filteredList() {
      // console.log("this.filteredResults", this.filteredResults);
      return this.filteredResults.slice(this.from, this.to);
    },

    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },

    from() {
      let lowBound =
        this.pagination.perPage * (this.pagination.currentPage - 1);
      if (this.filteredResults.length < lowBound) {
        lowBound = this.filteredResults.length;
      }
      return lowBound;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.filteredResults.length < highBound) {
        highBound = this.filteredResults.length;
      }
      return highBound;
    },
  },
};
</script>

<style>
.input-search--suite div input {
  color: black !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}
.row-suite--selection div div input {
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.row-suite--selection
  div
  div
  span
  span
  .el-select__caret.el-input__icon.el-icon-arrow-up {
  color: black !important;
}
.input-search--suite div .el-input__inner {
  color: black;
  padding: 1rem;
}
.input-search--suite div .el-input__icon.el-icon-search {
  margin-right: 10px;
  color: black;
}
.card-header {
  padding-left: 10px;
}
.modal-content {
  border-radius: 10px;
}
</style>
