<template>
  <div class="tagged--ipt mt--3" style="height: 35px">
    <div
      class="tagged--ipt_content pl-2"
      ref="data-rendered"
      @click="focusInput('start')"
    ></div>
    <input
      type="text"
      class="tagged--ipt_ipt px-2"
      @click="focusInput('start')"
      @input="handleUltraIpt()"
      @change="handleUltraIpt()"
      @keyup.backspace="handleDeleteLastDataValue()"
      @blur="focusInput('leave')"
      v-model="ultraIpt"
      :list="`list-options-${this.datalist_id}`"
      ref="tagged-input"
    />
    <datalist :id="`list-options-${this.datalist_id}`">
      <option
        v-for="elt in _optionsList"
        :key="elt.key"
        :value="elt.value"
        :label="elt.label"
      />
    </datalist>
    <!-- <el-tooltip content="Clear value" placement="top">
      <el-button
        @click="handleClearDataValue()"
        icon="el-icon-brush"
        class="ic--btn mr-2 py-2"
      >
      </el-button>
    </el-tooltip>   -->
  </div>
</template>

<script>
let datalist_id = 0;

export default {
  name: "tagged-input",
  props: {
    inputValue: String,
    optionsList: Array,
    mode: {
      type: String,
      validator: (value) => ["selector", "data"].includes(value),
    },
  },
  data() {
    return {
      ultraIpt: "",
    };
  },
  computed: {
    _optionsList() {
      if (this.mode == "data") {
        return this.optionsList.map((item) => {
          let obj = {
            key: item.name,
            value: item.name,
            label:
              item.data && item.data.trim().length !== 0
                ? item.data
                : item.init_value,
          };
          return obj;
        });
      } else if (this.mode == "selector") {
        return this.optionsList.map((item) => {
          let obj = {
            key: item.id,
            value: item,
            label: "",
          };
          return obj;
        });
      }
      return [];
    },
    value() {
      return (
        this.$refs["data-rendered"].innerHTML +
        this.ultraIpt
          .replace(/<span class="ml-1 u--tag">/g, "${")
          .replace(/<\/span>/g, "}")
          .replace(/<i class="ml-1 u--tag--i">/g, "")
          .replace(/<i class="ml-1">/g, "")
          .replace(/<\/i>/g, "")
          .replace(/  /g, " ")
          .replace(/{ /g, "{")
          .replace(/ }/g, "}")
          .trim()
      );
    },
  },
  methods: {
    focusInput(val) {
      const content = this.$refs["data-rendered"];
      const input = this.$refs["tagged-input"];
      const end = input.value.length;
      input.setSelectionRange(end, end);
      if (val === "start") {
        input.focus();
        content.classList.add("focused");
        input.classList.add("focused");
        console.log(content.innerHTML);
        this.toggleInputMax("on");
      } else if (val === "leave") {
        input.blur();
        input.classList.remove("focused");
        content.classList.remove("focused");
        this.toggleInputMax("off");
      }
    },
    handleClearDataValue() {
      this.$refs["data-rendered"].innerHTML = "";
      this.ultraIpt = "";
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const isVariable =
          this._optionsList.findIndex((v) => v.key === this.ultraIpt.trim()) >=
          0;
        const elt = isVariable
          ? document.createElement("span")
          : document.createElement("i");
        elt.classList.add("ml-1");
        isVariable
          ? elt.classList.add("u--tag")
          : elt.classList.add("u--tag--i");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("i");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
        }
        this.$refs["data-rendered"].appendChild(elt);
      }
    },
    handleDeleteLastDataValue() {
      const content = this.$refs["data-rendered"];
      let getStr = content.innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<\/span>/g, ",")
        .replace(/<i class="ml-1 u--tag--i">/g, "")
        .replace(/<i class="ml-1">/g, "")
        .replace(/<\/i>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());

      if (this.ultraIpt.length <= 0) {
        content.innerHTML = "";
        getStr.slice(0, -1).map((item) => {
          const isVariable =
            this._optionsList.findIndex((v) => v.key === item.trim()) >= 0;
          const elt = isVariable
            ? document.createElement("span")
            : document.createElement("i");
          elt.classList.add("ml-1");
          isVariable
            ? elt.classList.add("u--tag")
            : elt.classList.add("u--tag--i");
          elt.innerHTML = " " + item + " ";
          content.appendChild(elt);
        });
        this.toggleInputMax("on");
      }
    },
    setDataRendered(val) {
      if (val != null) {
        const ultraIptSet = val
          .replace(" ", '<i class="ml-1"> </i>')
          .replace(/\${/g, '<span class="ml-1 u--tag">')
          .replace(/}/g, "</span>");
        this.$refs["data-rendered"].innerHTML = ultraIptSet;
      }
    },
    toggleInputMax(val) {
      const content = this.$refs["data-rendered"];
      const input = this.$refs["tagged-input"];
      if (content.innerHTML === "") {
        if (val == "on") {
          content.style = "width: 0;";
          input.style = "max-width: 100%";
        } else if (val == "off") {
          content.style.removeProperty("width");
          input.style.removeProperty("max-width");
        }
      }
    },
  },
  beforeCreate() {
    this.datalist_id = datalist_id.toString();
    datalist_id += 1;
  },
  mounted() {
    this.setDataRendered(this.inputValue);
  },
  watch: {
    inputValue: function (val, oldVal) {
      this.setDataRendered(val);
    },
  },
};
</script>
<style lang="scss">
.tagged--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  cursor: text;
  .tagged--ipt_content,
  .tagged--ipt_ipt {
    display: flex;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }
  .tagged--ipt_content {
    align-items: center;
    white-space: nowrap;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#5e72e4, 0.75);
      background: rgba(#5e72e4, 0.15);
      border-radius: 0.25rem;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .u--tag--i {
      display: inline-block;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  .tagged--ipt_content.focused {
    z-index: 10;
    max-width: 15%;
    // width:50%;
    justify-content: flex-start;
  }
  .tagged--ipt_ipt {
    max-width: 0%;
  }
  .tagged--ipt_ipt.focused {
    max-width: 100%;
  }
  .btn--clean {
    max-width: 22%;
  }
}
</style>
