<template>
  <div>
    <div style="display: flex">
      <!-- <LeftSideBar>
          <div class="custom-btn-group align-items-center">
            <el-button @click="showComponent('overview')" icon="el-icon-pie-chart"
              class="tab--btn button-menu button-menu-item truncate" :class="{ active: showOverview }">
              {{ $t("view_item_page.overview") }}
            </el-button>
            <el-button @click="showComponent('suggestions')" icon="el-icon-sunrise-1"
              class="tab--btn button-menu button-menu-item truncate" :class="{ active: showSuggestions }">
              {{ $t("view_item_page.advanced") }}
            </el-button>
            <el-button @click="showComponent('testenhancer')" icon="el-icon-s-ticket"
              class="tab--btn button-menu button-menu-item truncate" :class="{ active: showTestEnhancer }">
              {{ $t("view_item_page.enhancer") }}
            </el-button>
          </div>
        </LeftSideBar> -->
      <div class="right-content mt-2 px-6">
        <div class="logo_section_tests">
          <h2 class="page_name">API TEST</h2>
        </div>
        <div>
          <div class="mid--page custom-mid-nav">
            <div class="mp--r">
              <div class="c--breadcrumb" style="width: auto">
                <router-link to="/details/test-explorer/list-tests"
                  ><i class="el-icon-house"></i
                ></router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <router-link to="/details/api-tests">API Tests</router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <span v-if="runName">{{
                  runName.split("").length > 100 ? runName : runName
                }}</span>
                <span v-else class="s--icon"
                  ><i class="el-icon-loading"></i
                ></span>
              </div>
            </div>
            <div class="mp--l">
              <!-- <div v-show="showOverview" class="fl--center">
                  <el-button @click=sharedTestRun() class="tab--btn mr-2" v-if="shareTest !== 'unshare'">
                    Shared test run
                    <el-tooltip
                      :content="$t('view_item_page.view_websocket_logs')"
                      placement="top"
                    >
                      <small>
                        <i class="fas fa-code"></i>
                      </small>
                    </el-tooltip>
                  </el-button>
                  <button v-if="items.status === 'processing'" @click="openWebSocketModal()" class="ic--btn mr-2">
                    <el-tooltip :content="$t('view_item_page.view_websocket_logs')" placement="top">
                      <small>
                        <i class="fas fa-code"></i>
                      </small>
                    </el-tooltip>
                  </button>
                  <button v-if="showRunnerLogButton || items.status !== 'processing'" @click="openScriptModal()"
                    class="ic--btn mr-2">
                    <el-tooltip :content="$t('view_item_page.view_runner_log')" placement="top">
                      <small>
                        <i class="fas fa-terminal"></i>
                      </small>
                    </el-tooltip>
                  </button>
                </div> -->
            </div>
          </div>
          <div class="second-card">
            <div class="row">
              <div class="w-100" id="content">
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    text-align: center;
                  "
                  class="custom-header-rows"
                >
                  <div class="elt-row max-width">
                    <h3>CALL URL</h3>
                  </div>
                  <div class="elt-row max-width">
                    <h3>STATUS</h3>
                  </div>
                  <div class="elt-row max-width">
                    <h3>RESPONSE TIME</h3>
                  </div>
                  <div class="elt-row max-width">
                    <h3>CREATED</h3>
                  </div>
                  <div
                    class="elt-row max-width"
                    style="white-space: nowrap; padding-left: 0 !important"
                  ></div>
                </div>
                <data-list
                  :loading="loading"
                  :items="resultApi"
                  :customSizes="false"
                  ref="dataList"
                  emptyText="No test run api found."
                >
                  <template #content>
                    <div
                      v-for="(row, index) in resultApi"
                      :key="index"
                      class="tblb--row mb-2"
                    >
                      <el-card
                        class="trsl--elt"
                        shadow="hover"
                        style="padding-bottom: 0"
                      >
                        <div>
                          <el-row
                            type="flex"
                            justify="space-between"
                            class="el--flex drag-only-row"
                          >
                            <el-col
                              style="width: 5%; text-align: start"
                              class="trsl--txt"
                            >
                              <span class="test-run-name">
                                {{ row.stepNumber }}
                              </span>
                            </el-col>
                            <el-col
                              :style="
                                'width: calc(100% /' + Header.length + ')'
                              "
                              style="text-align: start"
                              class="trsl--txt test-run-name-callApi mx-2 truncate"
                            >
                              <el-tag
                                :key="row.method"
                                :type="getTagType(row.method)"
                                size="small"
                                class="api-call-tag"
                                effect="dark"
                              >
                                {{ row.method }}
                              </el-tag>
                              <el-tooltip
                                class="item elt-row"
                                effect="light"
                                :content="row.url"
                                placement="top"
                                style="font-size: 0.8em"
                              >
                                <span class="elt-row">{{ row.url }}</span>
                              </el-tooltip>
                            </el-col>
                            <el-col
                              :style="
                                'width: calc(100% /' + Header.length + ')'
                              "
                              style="text-align: center"
                              class="trsl--txt"
                            >
                              <el-alert
                                class="no--close elt--banner_overlay_status tags-call-api"
                                show-icon
                                :closable="false"
                                :title="`${row.status_code}`"
                                :type="
                                  row.status_code < 299 ||
                                  row.status_code == 'passed'
                                    ? 'success'
                                    : 'error'
                                "
                              ></el-alert>
                            </el-col>
                            <el-col
                              class="elt-row"
                              :style="
                                'width: calc(100% /' + Header.length + ')'
                              "
                            >
                              <span class="test-time-cont">
                                <i class="far fa-clock mr-1"></i>
                                {{ Math.round(row.responseTime, 1) }} ms
                              </span>
                            </el-col>
                            <el-col
                              class="trsl--txt"
                              :style="
                                'width: calc(100% /' + Header.length + ')'
                              "
                            >
                              <span class="elt--txt">
                                {{ formatDate(row.created_at) }}
                              </span>
                            </el-col>
                            <el-col
                              :style="
                                'width: calc(100% /' + Header.length + ')'
                              "
                              style="text-align: start"
                              class="trsl--txt"
                            >
                              <!-- <el-tooltip  effect="light" content="Virtualization" placement="top" >
                                                    <el-button type="warning" icon="el-icon-aim" plain circle @click="openApicallsvirtModal(row)"></el-button>
                                                    </el-tooltip> -->
                              <el-tooltip
                                effect="light"
                                content="Detail"
                                placement="top"
                              >
                                <el-button
                                  type="success"
                                  icon="el-icon-view"
                                  plain
                                  circle
                                  @click="openEditAccordion(index)"
                                ></el-button>
                              </el-tooltip>
                              <el-tooltip
                                effect="light"
                                content="Run API"
                                placement="top"
                              >
                                <el-button
                                  type="primary"
                                  style="padding: 8.7px"
                                  plain
                                  circle
                                  @click="openReRunApi(row, index)"
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.000000pt"
                                    height="15.000000pt"
                                    viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                      fill="#5f72e4"
                                      stroke="none"
                                    >
                                      <path
                                        d="M1762 5090 c-590 -75 -1065 -496 -1197 -1062 -18 -75 -20 -78 -69
                                                                        -110 -134 -86 -279 -264 -344 -420 -56 -135 -76 -236 -75 -388 0 -151 24 -267
                                                                        81 -392 80 -177 227 -341 390 -437 l91 -54 3 -546 3 -546 31 -67 c40 -82 119
                                                                        -157 200 -190 56 -23 62 -23 535 -26 444 -3 478 -4 473 -20 -3 -9 -31 -118
                                                                        -62 -242 -48 -194 -55 -235 -52 -295 8 -116 71 -211 176 -262 l57 -28 555 0
                                                                        555 0 57 28 c105 51 168 146 176 262 3 60 -4 101 -52 295 -31 124 -59 233 -62
                                                                        242 -5 16 29 17 473 20 473 3 479 3 535 26 81 33 160 108 200 190 l33 67 3
                                                                        557 3 558 67 40 c275 163 456 445 490 763 34 320 -161 702 -463 904 -169 114
                                                                        -392 183 -590 183 l-61 0 -26 58 c-91 196 -268 381 -465 484 -183 96 -329 130
                                                                        -535 127 l-128 -2 -85 57 c-153 103 -343 180 -523 211 -110 20 -305 27 -400
                                                                        15z m359 -225 c174 -30 355 -109 502 -218 88 -65 86 -64 238 -52 373 30 737
                                                                        -200 870 -550 46 -122 49 -124 226 -123 173 0 257 -18 393 -83 241 -115 412
                                                                        -335 466 -598 47 -236 -67 -527 -270 -692 l-61 -49 -5 312 c-6 349 -9 361 -78
                                                                        452 -20 27 -59 64 -85 83 -85 57 -121 63 -412 63 -248 0 -264 -1 -288 -20 -38
                                                                        -30 -52 -80 -33 -123 26 -63 45 -67 318 -67 233 0 245 -1 282 -23 22 -12 49
                                                                        -38 60 -57 21 -34 21 -48 24 -833 l2 -797 -1710 0 -1710 0 2 798 c3 784 3 798
                                                                        24 832 11 19 38 45 60 57 37 22 49 23 282 23 273 0 292 4 318 67 19 43 5 93
                                                                        -33 123 -24 19 -40 20 -294 20 -245 0 -273 -2 -323 -20 -111 -42 -190 -125
                                                                        -226 -239 -18 -57 -20 -91 -20 -367 0 -167 -4 -304 -8 -304 -19 0 -143 113
                                                                        -187 171 -61 81 -118 203 -139 296 -23 103 -21 260 4 357 51 194 180 366 351
                                                                        466 72 43 82 59 99 151 86 474 469 857 945 944 97 18 315 18 416 0z m2145
                                                                        -3642 c-8 -58 -33 -102 -76 -130 l-33 -23 -1597 0 -1597 0 -34 23 c-40 26 -79
                                                                        98 -79 144 l0 33 1711 0 1712 0 -7 -47z m-1268 -400 c23 -24 36 -66 87 -270
                                                                        59 -241 59 -242 42 -278 -31 -65 -30 -65 -567 -65 -537 0 -536 0 -567 65 -17
                                                                        36 -17 37 42 278 51 204 64 246 87 270 l26 27 412 0 412 0 26 -27z"
                                      />
                                      <path
                                        d="M2691 3994 c-47 -40 -49 -77 -8 -173 l36 -82 -37 8 c-71 14 -243 6
                                                                        -327 -16 -293 -77 -515 -289 -606 -581 -21 -66 -24 -95 -24 -235 0 -140 3
                                                                        -169 23 -235 44 -139 91 -214 142 -225 58 -13 130 44 130 102 0 10 -15 54 -34
                                                                        97 -176 403 96 861 524 883 l85 4 -55 -28 c-72 -37 -98 -78 -81 -133 14 -47
                                                                        53 -80 95 -80 44 0 415 164 444 196 13 15 22 38 22 58 0 45 -163 414 -196 444
                                                                        -35 31 -93 30 -133 -4z"
                                      />
                                      <path
                                        d="M3158 3365 c-29 -16 -58 -62 -58 -92 0 -10 15 -54 34 -97 107 -245
                                                                        57 -515 -129 -701 -110 -110 -246 -172 -395 -181 l-85 -5 55 28 c93 47 113
                                                                        121 50 184 -44 44 -75 39 -266 -45 -93 -41 -179 -77 -191 -80 -34 -11 -73 -63
                                                                        -73 -99 0 -18 38 -118 87 -231 72 -164 93 -203 118 -218 43 -26 88 -23 124 8
                                                                        47 39 49 77 9 171 l-35 78 131 -3 c108 -3 147 0 219 17 316 75 553 311 628
                                                                        628 28 118 23 321 -11 428 -27 85 -71 174 -98 198 -27 25 -81 30 -114 12z"
                                      />
                                    </g>
                                  </svg>
                                </el-button>
                              </el-tooltip>
                            </el-col>
                          </el-row>
                        </div>
                        <div
                          class="edit-accordion"
                          v-show="index === selectedItemId"
                        >
                          <el-divider></el-divider>
                          <vue-json-pretty
                            :data="getJsonData(row)"
                            :showSelectController="true"
                            :showIcon="true"
                            :showLineNumber="true"
                          />
                          <div
                            class="py-2"
                            style="display: flex; justify-content: end"
                          >
                            <base-button
                              type="secondary"
                              @click="openEditAccordion(index)"
                            >
                              {{ $t("list_item_page.close") }}
                            </base-button>
                          </div>
                        </div>
                      </el-card>
                    </div>
                  </template>
                </data-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-test-project-modal">
      <modal :show.sync="responseRequestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Rerun API Results</h5>
        </template>
        <div class="ctb--body mb-3" style="width: 100%" v-if="loadingRequest">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div v-else class="body--modale mb-4">
          <vue-json-pretty
            :data="responseRequest"
            :showSelectController="true"
            :showIcon="true"
            :showLineNumber="true"
          />
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="responseRequestModal = false">
              Cancel
            </base-button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import axios from "axios";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue";
import moment from "moment";
import "moment-timezone";
import VueJsonPretty from "vue-json-pretty";
import DataList from "@/components/Custom/DataList.vue";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export var runActive = false;
export default {
  layout: "DashboardLayout",
  components: {
    BaseButton,
    VueJsonPretty,
    DataList,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],
  data() {
    return {
      loading: false,
      loading2: false,
      selectedItemId: null,
      items: {},
      toShowApi: [],
      runName: "",
      checkedApi: {},
      reRunUrl: "",
      methodReRun: "",
      showReRunId: "",
      showReRunIndex: 0,
      requestHeaders: [],
      responseHeaders: [],
      requestBody: [],
      responseBody: [],
      metadata: [],
      editApi: false,
      showModalReRun: false,
      responseRequestModal: false,
      reRunEditData: false,
      loadingRequest: false,
      resultApi: [],
      Header: [
        {
          label: "",
          key: "_id",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Call Url".toUpperCase(),
          key: "",
          sortable: false,
        },
        {
          label: "Status code".toUpperCase(),
          key: "",
          sortable: false,
        },
        {
          label: "Response Time".toUpperCase(),
          key: "",
          sortable: false,
        },
        {
          label: "created".toUpperCase(),
          key: "",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", sortable: false },
      ],
      responseRequest: [],
    };
  },
  async created() {
    const name = this.$route.params.name;
    this.runName = name;
    this.get();
  },
  methods: {
    formatRequired(row, col, value, index) {
      if (value) {
        return "Y";
      } else {
        return "N";
      }
    },
    goBack() {
      this.$router.push({
        name: "List Items",
      });
    },
    showComponent(name) {
      this.showOverview = false;
      this.showSuggestions = false;

      if (name === "overview") {
        this.showOverview = true;
      } else if (name === "suggestions") {
        this.showSuggestions = true;
      }
    },
    getOverview() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    async get() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testapi/${id}`
        );

        if (res.status === 200) {
          this.resultApi = res.data.response.apiCallsStep;
        }
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "Cannot load user Test... Try refresh",
        // });
      } finally {
        this.loading = false;
      }
    },
    handleCheckAllChange(id, value) {
      this.checkedApi[id].value = !value;
    },
    getTagType(method) {
      if (method.toString().toLowerCase() == "get") {
        return "success";
      }
      if (method.toString().toLowerCase() == "post") {
        return "";
      }
      if (method.toString().toLowerCase() == "put") {
        return "warning";
      }
      if (method.toString().toLowerCase() == "delete") {
        return "danger";
      }
      if (method.toString().toLowerCase() == "patch") {
        return "info";
      }
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    async openReRunApi(el, index) {
      const confirmation = await swal.fire({
        title: "Modify request ?",
        text: "You had the option of modifying the request (method, url, request header, request body).",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Modify and run!",
        cancelButtonText: "Run as is ",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
      });
      this.reRunUrl = el.url;
      this.methodReRun = el.method;
      this.showReRunId = el.apicall_uid;
      this.showReRunIndex = index + 1;
      this.requestHeaders = [];
      this.responseHeaders = [];
      this.requestBody = [];
      this.responseBody = [];
      this.metadata = [];

      Object.entries(el.request_headers).map((item) => {
        this.requestHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.request_body && Array.isArray(el.request_body)) {
        Object.entries(el.request_body).map((item) => {
          this.requestBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.requestBody = [];
      }
      Object.entries(el.response_headers).map((item) => {
        this.responseHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.response_body && Array.isArray(el.response_body)) {
        Object.entries(el.response_body).map((item) => {
          this.responseBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.responseBody = [];
      }
      if (el.metadata && Array.isArray(el.metadata)) {
        Object.entries(el.metadata).map((item) => {
          this.metadata.push({ name: item[0], value: item[1] });
        });
      } else {
        this.metadata = [];
      }
      if (confirmation.value === true) {
        this.editApi = false;
        this.showModalReRun = true;
        this.responseRequestModal = false;
        this.reRunEditData = true;
      } else {
        this.showReRunId = el.apicall_uid;
        this.reRunEditData = false;
        this.responseRequestModal = true;
        this.reRun(false);
      }
    },
    async reRun(type) {
      let apiCallId = this.showReRunId;
      this.error = [];
      this.loadingRequest = true;
      let data = {};
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (this.requestTimeout > 0.1) {
        data.requestTimeout = this.requestTimeout;
      }
      if (this.connectionTimeout > 0.1) {
        data.connectionTimeout = this.connectionTimeout;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (this.requestHeaders.length > 0) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          if (item.name != "") {
            finalyHeader[item.name] = item.value;
          }
        });
        data.requestHeaders = finalyHeader;
      } else {
        data.requestHeaders = null;
      }
      if (this.requestBody.length > 0) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.requestBody = finalyBody;
      } else {
        data.requestBody = null;
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      let resp = null;
      if (this.reRunEditData == true) {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
      } else {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId
        );
      }
      if (resp.data.status === "success") {
        this.responseRequest = resp.data.response;
        delete this.responseRequest.apicall_uid;
        delete this.responseRequest.session_uid;
        delete this.responseRequest.testrun_requestid;
        delete this.responseRequest.updated_at;
        delete this.responseRequest.created_at;
        delete this.responseRequest.pageviews_project_id;
        this.loadingRequest = false;
        this.responseRequestModal = true;
        this.showModalReRun = false;
      } else if (resp.data.status === "failed") {
        if (resp.data.response) {
          this.responseRequest = resp.data.response;
          delete this.responseRequest.apicall_uid;
          delete this.responseRequest.session_uid;
          delete this.responseRequest.testrun_requestid;
          delete this.responseRequest.updated_at;
          delete this.responseRequest.created_at;
          delete this.responseRequest.pageviews_project_id;
          this.responseRequestModal = true;
          this.showModalReRun = false;
          this.loadingRequest = false;
        } else {
          this.error.push(resp.data.message);
          this.$notify({
            type: "danger",
            message: `an error has occurred! (${resp.data.message})`,
          });
          this.responseRequest = [];
          this.loadingRequest = false;
        }
      } else {
        this.responseRequest = [];
        this.loadingRequest = false;
      }
    },
    getJsonData(el) {
      let dat = el;
      delete dat.session_uid;
      delete dat.testrun_requestid;
      delete dat.pageviews_project_id;
      return dat;
    },
    openEditAccordion(id) {
      this.selectedItemId = this.selectedItemId !== id ? id : null;
    },
  },
};
</script>
<style>
.custom-mid-nav .c--breadcrumb {
  margin-left: 0;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.el-icon-house {
  font-size: 18px;
  font-weight: 400;
}
.el-icon-d-arrow-right {
  color: black;
  font-size: 16px !important;
  font-weight: 400;
  margin-left: 5px !important;
}
</style>
