<template>
  <div class="container-fluid list-item-page mt--4">
    <div
      class="xs:hidden sm:hidden c--breadcrumb"
      :class="{ to_move: $sidebar.isMinimized }"
    >
      <router-link to="/details/test-explorer/list-tests" class=""
        ><i class="el-icon-house"></i
      ></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link to="/details/test-explorer/list-tests">{{
        $t("view_item_page.test_explorer")
      }}</router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span>{{ $t("generate_test.generate_test") }}</span>
    </div>
    <div class="mid--page_container">
      <h5
        class="modal-title"
        id="generateTestModal"
        style="margin-bottom: 10px"
      >
        Generate test given natural language instruction
      </h5>
      <div class="body--modale">
        <div class="modal-add-containt">
          <div
            class="add-test-modal"
            v-if="genLoading"
            style="width: 100%; padding: 20px; background: #fff"
          >
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
          <div v-else class="add-test-modal">
            <div class="run-name">
              <label class="model-label is-required" for="g_run_name">
                {{ $t("list_item_page.run_name") }}
              </label>
              <input
                type="text"
                id="g_run_name"
                name="run_name"
                :placeholder="$t('list_item_page.placeholder_run_name')"
                v-model="run_name"
              />
              <label class="model-label is-required" for="g_run_url">
                Start URL
              </label>
              <input
                type="text"
                id="g_run_url"
                name="run_url"
                placeholder="https://www.amazon.com/"
                v-model="testrun_start_url"
              />
              <label class="model-label is-required" for="g_run_description">
                Instruction
              </label>
              <textarea
                id="g_run_description"
                name="run_description"
                rows="2"
                placeholder="Enter a natural language instruction for an AI agent (e.g. Add a milk item in the cart)"
                v-model="run_description"
              ></textarea>
              <div v-for="(item, id) in personas" :key="id" style="width: 100%">
                <label class="model-label">
                  {{ "Scenario #" + id }}
                </label>
                <textarea
                  :name="'run_description_' + id"
                  rows="2"
                  v-model="item.scenario"
                ></textarea>
              </div>
            </div>

            <div class="s-a-tabs">
              <ul class="nav nav-tabs nav-justified">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('settings')"
                    :class="{ active: isActive('settings') }"
                    href="#home"
                    >{{ $t("list_item_page.settings") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('advanced')"
                    :class="{ active: isActive('advanced') }"
                    href="#profile"
                    >{{ $t("list_item_page.advanced") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content py-3" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('settings') }"
                  id="settings"
                >
                  <div class="mt-2">
                    <h4 class="fbb">{{ $t("list_item_page.test_script") }}</h4>
                    <div class="three-input">
                      <div class="tic">
                        <label class="model-label" for="browser_width">{{
                          $t("list_item_page.browser_width")
                        }}</label>
                        <input
                          type="text"
                          id="browser_width"
                          name="browser_width"
                          placeholder="1920"
                          v-model="browser_width"
                        />
                      </div>
                      <div class="tic">
                        <label class="model-label" for="browser_height">{{
                          $t("list_item_page.browser_height")
                        }}</label>
                        <input
                          type="text"
                          id="fname"
                          name="browser_height"
                          placeholder="1980"
                          v-model="browser_height"
                        />
                      </div>

                      <div class="tic">
                        <label class="model-label" for="max_wait_time">{{
                          $t("list_item_page.max_wait")
                        }}</label>
                        <input
                          type="text"
                          id="max_wait_time"
                          name="max_wait_time"
                          placeholder="5.0"
                          v-model="max_wait_time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('advanced') }"
                  id="advanced"
                >
                  <div class="modal-checks-container">
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_disabled_elements"
                        name="ignore_disabled_elements"
                        v-model="ignore_disabled_elements"
                      />
                      <label for="ignore_disabled_elements">{{
                        $t("list_item_page.ignore_disabled")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_invisible_elements"
                        name="ignore_invisible_elements"
                        v-model="ignore_invisible_elements"
                      />
                      <label for="ignore_invisible_elements">{{
                        $t("list_item_page.ignore_invisible")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="collect_page_interaction_data"
                        name="collect_page_interaction_data"
                        v-model="collect_page_interaction_data"
                      />
                      <label for="collect_page_interaction_data">{{
                        $t("list_item_page.collect_interaction")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="extra_element_data"
                        name="extra_element_data"
                        v-model="extra_element_data"
                      />
                      <label for="extra_element_data">{{
                        $t("list_item_page.collect_extra_element")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="all_suggest"
                        name="all_suggest"
                        v-model="all_suggest"
                      />
                      <label for="all_suggest">{{
                        $t("list_item_page.generate_selectors")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="downweight_strings"
                        name="downweight_strings"
                        v-model="downweight_strings"
                      />
                      <label for="downweight_strings">{{
                        $t("list_item_page.try_to_avoid")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="verify_suggested_selectors"
                        name="verify_suggested_selectors"
                        v-model="verify_suggested_selectors"
                      />
                      <label for="verify_suggested_selectors">{{
                        $t("list_item_page.verify_suggested")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="enable_integrations"
                        name="enable_integrations"
                        v-model="enable_integrations"
                      />
                      <label for="enable_integrations">{{
                        $t("list_item_page.trigger_integrations")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="generate_css_selectors"
                        name="generate_css_selectors"
                        v-model="generate_css_selectors"
                      />
                      <label for="generate_css_selectors">
                        Generate CSS selectors during run (slow, needed for
                        Cypress export)
                      </label>
                    </div>
                    <br />
                    <label class="model-label" for="bad_attributes_list">{{
                      $t("list_item_page.avoid_attributes")
                    }}</label>
                    <textarea
                      id="bad_attributes_list"
                      name="bad_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('list_item_page.placeholder_avoid_attributes')
                      "
                      v-model="bad_attributes_list"
                    ></textarea>

                    <label class="model-label" for="good_attributes_list">{{
                      $t("list_item_page.use_attributes")
                    }}</label>
                    <textarea
                      id="good_attributes_list"
                      name="good_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('list_item_page.placeholder_use_attributes')
                      "
                      v-model="good_attributes_list"
                    ></textarea>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="continue_test_suite_on_fail"
                        name="continue_test_suite_on_fail"
                        v-model="continue_test_suite_on_fail"
                      />
                      <label for="continue_test_suite_on_fail">{{
                        $t("list_item_page.continue_test_suite")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="timeout_cancel_run"
                        name="timeout_cancel_run"
                        v-model="timeout_cancel_run"
                      />
                      <label for="timeout_cancel_run">{{
                        $t("list_item_page.timeout_and_cancel")
                      }}</label>
                      <input
                        class="mod-chk-last-input"
                        type="text"
                        id="timeout_cancel_run_value"
                        name="timeout_cancel_run_value"
                        v-model="timeout_cancel_run_value"
                        :placeholder="
                          $t('list_item_page.placeholder_timeout_and_cancel')
                        "
                      />
                      {{ $t("list_item_page.seconds") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add-generate-test-footer-btn">
        <el-tooltip
          :disabled="!(run_description === '')"
          :content="`${
            run_description === '' ? 'Instruction cannot be empty' : ''
          }`"
          placement="top"
        >
          <span>
            <base-button
              :disabled="run_description === ''"
              type="info"
              @click="refinePrompt"
              >Refine prompt</base-button
            >
          </span>
        </el-tooltip>
        <el-tooltip
          :disabled="
            !(
              run_name === '' ||
              testrun_start_url === '' ||
              run_description === ''
            )
          "
          :content="`${run_name === '' ? 'Run name cannot be empty;' : ''} ${
            testrun_start_url === '' ? 'Start URL cannot be empty;' : ''
          } ${run_description === '' ? 'Instruction cannot be empty' : ''}`"
          placement="top"
        >
          <span>
            <base-button
              :disabled="
                run_name === '' ||
                testrun_start_url === '' ||
                run_description === ''
              "
              type="primary"
              @click="generateTest"
              >Generate</base-button
            >
          </span>
        </el-tooltip>
        <base-button type="danger" @click="cleanvalue()">clean</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      forEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      generate_css_selectors: false,
      testrun_start_url: "",
      personas: [],
      genLoading: false,
    };
  },
  methods: {
    cleanvalue() {
      this.personas = [];
      this.run_name = "";
      this.testrun_start_url = "";
      this.run_description = "";
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    refinePrompt() {
      this.genLoading = true;
      const payload = {
        instruction: this.run_description,
      };
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };

      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/generate_scenarios",
          payload
        )
        .then((response) => {
          if (response.data && response.data.status != "failed") {
            console.log(response);
            this.testrun_start_url = response.data.url;
            this.run_description = response.data.scenario;
            this.personas = response.data.personas;
            this.genLoading = false;
          } else if (response.data && response.data.status === "failed") {
            this.genLoading = false;
            this.$notify({
              type: "danger",
              message: response.data.message,
            });
          } else {
            this.genLoading = false;
            this.$notify({
              type: "danger",
              message: "an error occurred!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            message: "Cannot refine prompt. Details: ",
            error,
          });
          this.genLoading = false;
        });
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    generateTest() {
      this.genLoading = true;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail
          ? "continue"
          : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
        runTags: parsedRunTags.join(","),
      };

      let instructions = [this.run_description].concat(
        this.personas.map((p) => p.scenario)
      );
      const payload = {
        run_name: this.run_name,
        url: this.testrun_start_url,
        instructions: instructions,
        run_options: runOptions,
      };

      this.generateTestModal = false;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };

      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/generate_test",
          payload
        )
        .then((response) => {
          console.log(response.data);
          if (response.data && response.data.status != "failed") {
            this.$notify({
              type: "success",
              message: this.run_name + " added successfully.",
            });
            this.run_name = "";
            this.run_description = "";
            this.testrun_start_url = "";
            this.personas = [];
            // this.pollStatus = true;
            // this.singleTestId = true;
            this.genLoading = false;
            this.$router.push({
              name: "List Items",
            });
          } else if (response.data && response.data.status === "failed") {
            this.genLoading = false;
            this.$notify({
              type: "danger",
              message: response.data.message,
            });
          } else {
            this.genLoading = false;
            this.$notify({
              type: "danger",
              message: "an error occurred!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            message: this.run_name + " was not added successfully. Details: ",
            error,
          });
        });
    },
  },
};
</script>
<style lang="scss">
.add-generate-test-footer-btn {
  margin: 20px 0;
  display: flex;
  justify-content: right;
}
</style>
