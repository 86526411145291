<template>
  <div class="wrapper">
    <!-- <div :class="{ fake : isActiveDash}" style="overflow: hidden;" class="right-content right-content-loading"></div>   -->
    <onboarding
      ref="onboarding"
      :steps="allStep"
      :showonbarding="showOnboarding"
    />
    <notifications></notifications>
    <div class="main-content">
      <leftsidebar>
        <div class="headr_logo">
          <div class="img_contaitner_logo">
            <img src="/img/brand/logo_wring.svg" width="40px" />
          </div>
          <h1 class="logo-text-wring">WRINGR</h1>
        </div>
        <div class="nav_buttons_left_side">
          <side-bar>
            <template slot="links">
              <div class="is-content-nav">
                <div class="is-item-nav">
                  <div @click="isActive('dash')" class="splitItm">
                    <sidebar-item
                      id="DashboardDashboard"
                      :link="{
                        name: `Dashboard`,
                        icon: 'ni ni-app text-primary',
                        path: '/dashboard',
                        children: '/dashboard',
                      }"
                    ></sidebar-item>
                  </div>
                  <!-- <sidebar-item id="DashboardDashboard"  :link="{
              name: `Dashboard`,
              icon: 'ni ni-app text-primary',
              path: '/dashboard',
              children:'/dashboard'
            }" ></sidebar-item> -->

                  <div @click="isActive()" class="splitItm">
                    <sidebar-item
                      id="DashboardLiveEvent"
                      :link="{
                        name: `Projects`,
                        icon: 'ni ni-folder-custom text-primary',
                        path: '/details/project-explorer/list-projects',
                        children: '/details/project-explorer',
                      }"
                    />
                  </div>

                  <!-- <div @click="isActive('tests')" :class="{ active : isActiveTests }" class="btn_container splitItm">
              <sidebar-item id="DashboardRunList" :link="{
              name: `Tests`,
              icon: 'ni ni-bullet-list-67 text-primary',
              path: '/details/list-tests',
              children:'/details/test-explorer'
            }" />
<i  :class= "{activeBtn : !dropActiveTests}"  @click.stop="dropMenu(`tests`)" class="dropDown"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7 14.5834L12.0008 10L17 14.5834" stroke="#E6E6E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></i></div> -->

                  <!-- <ul  id="tests" class="list_children_buttons tests splitItm">
              <li @click="isActive()">
            <sidebar-item id="ApiTests" :link="{
              name: `API`,
              path: '/details/api-tests',
              children:'/details/api-tests'
            }" />
        </li>
        <li @click="isActive()">
          <sidebar-item id="SharedTests" :link="{
              name: `Shared`,
              path: '/details/shared-tests',
              children:'/details/shared-tests'
            }" />
        </li>
        <li @click="isActive()">
          <sidebar-item id="SauceLabsTests" :link="{
              name: `Sauce Labs`,
              path: '/details/sauce-tests',
              children:'/details/sauce-tests'
            }" />
        </li>
        <li @click="isActive()">
          <sidebar-item id="AWSTests" :link="{
              name: `AWS`,
              path: '/details/aws-tests',
              children:'/details/aws-tests'
            }" />
        </li>
        
          
          </ul>     -->
                  <!-- <sidebar-item id="DashboardVirtualizeApi" :link="{
              name: $t('nav.virtualizeApi'),
              icon: 'ni ni-planet text-primary',
              path: '/details/project-explorer/virtualize-api-projects',
            }" /> -->
                  <div
                    @click="isActive('int')"
                    :class="{ active: isActiveInt }"
                    class="btn_container splitItm"
                  >
                    <sidebar-item
                      id="DashboardIntergrations"
                      :link="{
                        name: `Integrations`,
                        icon: 'ni ni-sound-wave text-primary',
                        path: '/details/integrations',
                        children: '/details/integrations',
                      }"
                    />

                    <!-- <i :class= "{activeBtn : !dropActiveIntegr}"  @click.stop="dropMenu('integr')" class="dropDown"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#1F213E">
  <path d="M7 14.5834L12.0008 10L17 14.5834" stroke="#E6E6E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></i> -->
                  </div>
                  <!-- <ul  id="integr" class="list_children_buttons integr splitItm">
              <li @click="isActive()">
                <sidebar-item id="CLIntegr" :link="{
              name: `CI/CD`,
              path: '/details/clintegr',
              children:'/details/clintegr'
            }" />
              </li>
              <li @click="isActive()"> <sidebar-item id="Triggers" :link="{
                          name: `Triggers`,
                          path: '/details/triggers',
                          children:'/details/triggers'
                        }" />
              </li>
              <li @click="isActive()">
                <sidebar-item id="Sheduels" :link="{
              name: `Schedule`,
              path: '/details/sheduels',
              children:'/details/sheduels'
            }" />
              </li>
              
            </ul> -->

                  <!-- <div @click="isActive()" class="splitItm">
              <sidebar-item id="DashboardChatWring" :link="{
              name: 'Chat',
              icon: 'ni ni-chat-round text-primary',
              path: '/details/chatwring',
              children:'/details/chatwring'
            }" />
            </div> -->
                </div>

                <!-- <button class="switch-theme-btn" @click="toggleDarkMode">
              <svg xmlns="http://www.w3.org/2000/svg" fill="black" height="16" width="12" viewBox="0 0 384 512"><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/></svg>
                  </button> -->
                <!-- <ul class="navbar-nav align-items-center mr-5">
            <base-dropdown menu-on-right class="nav-item no--hover" tag="li" title-tag="a" title-classes="nav-link pr-0">
              <a href="#" class="flex" @click.prevent slot="title-container">
                <div class="media align-items-center">
                  
                  <span :data-content="avatarText" class="avatar-image" id="topbarPofile">
                    
                    <div class="if-img" v-if="user.profile_pic">
                      <img :src="user.profile_pic" />
                    </div>
                    <div class="if-img-noavatar"  v-else>
                      {{ getAvatarInitials() }}
                    </div>
                  </span>
                </div>
              </a>
              <template>
                <div class="dropdown-header noti-title">
                  <h6 class="text-overflow m-0">
                    {{ $t("dashboard_navbar.welcome") }} {{ avatarText }} !
                  </h6>
                </div>
                <a :href="logOut" class="elt--dropdown">
                  <i class="ni ni-single-02"></i>
                  <span>{{ $t("dashboard_navbar.my_profile") }}</span>
                </a>
                <a class="elt--dropdown" href="/details/subscription">
                  <i class="ni ni-briefcase-24 "></i>
                  <span>{{ $t("dashboard_navbar.subscription") }}</span>
                </a>
                <a class="elt--dropdown" href="https://aichemydev.github.io/Wring_docs/docs/intro">
                  <i class="fas fa-question-circle"></i>
                  <span>{{ $t("dashboard_navbar.documentation") }}</span>
                </a>
                <a class="elt--dropdown" href="https://github.com/aichemydev/wring-support/issues ">
                  <i class="fas  fa-flag"></i>
                  <span>{{ $t("dashboard_navbar.report") }}</span>
                </a>
                <a class="elt--dropdown" @click.stop="isClick = true">
                  <li id="topbarLangue">
                    <el-dropdown trigger="click" class="el-dropdown-link_2" @command="switchLg">
                      <span type="primary" class="el-dropdown-link--inner">
                        {{ $t("dashboard_navbar.language")
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="myDropdown__menu__2">
                        <el-dropdown-item command="en" class="myDropdown__item__2">{{ $t("dashboard_navbar.english")
                        }}</el-dropdown-item>
                        <el-dropdown-item command="es" class="myDropdown__item__2">{{ $t("dashboard_navbar.spanish")
                        }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </a>
                <div class="dropdown-divider"></div>
                <a class="elt--dropdown red" @click.prevent="logout()">
                  <i class="ni ni-user-run"></i>
                  <span>{{ $t("dashboard_navbar.logout") }}</span>
                </a>
              </template>
            </base-dropdown>
          </ul>
        </div>
        <div class="sidebar--botom"> -->
                <!-- <el-button id="DashboardHelppage" type="primary" icon="el-icon-warning-outline" @click="openonbrading()">
            Help
          </el-button> -->
              </div>
            </template>
          </side-bar>
        </div>
        <button
          @click="resizeMenu"
          :class="!isResizeMenu ? 'btn-menu-resize-active' : ''"
          class="btn-menu-resize"
        >
          <i style="margin-top: 1px"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 7L15 12L10 17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
          ></i>
        </button>
        <!-- <label class="my_switch">
  <input type="checkbox" v-model="toggleState">
  <span :class="{'slider' : true, 'round':true,'moon_custom': !toggleState,'sun_custom':toggleState}"></span>
</label> -->
      </leftsidebar>
      <ul class="navbar-nav align-items-center avatar_custom">
        <base-dropdown
          menu-on-right
          class="nav-item no--hover"
          tag="li"
          title-tag="a"
          title-classes="nav-link pr-0"
        >
          <a
            href="#"
            class="flex justify-center"
            @click.prevent
            slot="title-container"
          >
            <div class="media align-items-center">
              <span
                :data-content="avatarText"
                class="avatar-image"
                id="topbarPofile"
              >
                <div class="if-img" v-if="user.profile_pic">
                  <img :src="user.profile_pic" />
                </div>
                <div class="if-img-noavatar" v-else>
                  {{ getAvatarInitials() }}
                </div>
              </span>
            </div>
          </a>
          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">
                {{ $t("dashboard_navbar.welcome") }} {{ avatarText }} !
              </h6>
            </div>
            <a @click.prevent="profileTab(profile)" class="elt--dropdown">
              <i class="ni ni-single-02"></i>
              <span>{{ $t("dashboard_navbar.my_profile") }}</span>
            </a>
            <a class="elt--dropdown" href="/details/subscription">
              <i class="ni ni-briefcase-24"></i>
              <span>{{ $t("dashboard_navbar.subscription") }}</span>
            </a>
            <a
              class="elt--dropdown"
              href="https://aichemydev.github.io/Wring_docs/docs/intro"
            >
              <i class="fas fa-question-circle"></i>
              <span>{{ $t("dashboard_navbar.documentation") }}</span>
            </a>
            <a
              class="elt--dropdown"
              href="https://github.com/aichemydev/wring-support/issues "
            >
              <i class="fas fa-flag"></i>
              <span>{{ $t("dashboard_navbar.report") }}</span>
            </a>
            <a class="elt--dropdown" @click.stop="isClick = true">
              <li id="topbarLangue">
                <el-dropdown
                  trigger="click"
                  class="el-dropdown-link_2"
                  @command="switchLg"
                >
                  <span type="primary" class="el-dropdown-link--inner">
                    {{ $t("dashboard_navbar.language")
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="myDropdown__menu__2">
                    <el-dropdown-item
                      command="en"
                      class="myDropdown__item__2"
                      >{{ $t("dashboard_navbar.english") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="es"
                      class="myDropdown__item__2"
                      >{{ $t("dashboard_navbar.spanish") }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </a>
            <div class="dropdown-divider"></div>
            <a class="elt--dropdown red" @click.prevent="logout()" to="">
              <i class="ni ni-user-run"></i>
              <span>{{ $t("dashboard_navbar.logout") }}</span>
            </a>
          </template>
        </base-dropdown>
      </ul>
      <!-- <dashboard-navbar v-if="this.$route.path === isDashboard " :type="$route.meta.navbarType"></dashboard-navbar> -->

      <!-- <div @click="$sidebar.displaySidebar(false)"> -->
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <!-- </div> -->
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import onboarding from "../../components/onboarding.vue";
import leftsidebar from "@/components/SidebarPlugin/LeftSideBar.vue";
import axios from "axios";
import Vuex from "vuex";
import { Collapse } from "element-ui";
import { active } from "d3";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    onboarding,
    leftsidebar,
  },
  data() {
    return {
      isActiveInt: false,
      isActiveTests: false,
      isActiveDash: true,
      isResizeMenu: true,
      dropActiveIntegr: false,
      dropActiveTests: false,
      toggleState: false,
      showInstallation: false,
      isActiveDropDown: false,

      logoutLink: process.env.VUE_APP_KEYCLOAK_LOGOUT_URL,

      roles: [],
      year: new Date().getFullYear(),
      overflowHidden: "height: 86vh; overflow: hidden;",
      overflowAuto: "height: 86vh; overflow: auto;",
      logo: {
        type: String,
        default: "/favicon.png",
        description: "Sidebar app logo",
      },
      user: [],
      logOut: "https://auth.wring.dev/realms/devwringdev/account/#/",
      profile: "",
      isClick: false,
      showPageview: true,
      stepsOnboarding: [
        {
          titel: "Welcome",
          desc: "Wringr is a test automation solution that uses deep learning to auto heal your tests. With Wringr's proprietary machine learning algorithms, we help you keep your tests healthy and up-to-date in order to support new features quickly and efficiently.",
          position: "right",
          img: "/favicon.png",
          element: null,
        },
        // {
        // titel: "Dashboard",
        // desc: "The Dashboard provides a quick overview of your tests, including key metrics like test cases and assertions, run time and execution time saved.",
        // position: "right",
        // element: "DashboardDashboard",
        // },
        {
          titel: "Test Runs",
          desc: "The test explorer allows you to see all the tests you have added to Wringr. ",
          position: "right",
          element: "DashboardRunList",
        },
        {
          titel: "Live event",
          desc: "Live Events or as we call it - 'Pageviews' is a tool that allows you to record user actions on a website and generate a test case from these actions. ",
          position: "right",
          element: "DashboardLiveEvent",
        },
        {
          titel: "Intergrations",
          desc: "The integrations page shows available integrations as cards located at the top of the integrations page.",
          position: "right",
          element: "DashboardIntergrations",
        },
        // {
        // titel: "Installation",
        // desc: "You'll find quick instructions for installing Wring Interceptor for Selenium and Cypress in the languages that we support.",
        // position: "right",
        // element: "DashboardInstallation",
        // },
        {
          titel: "User profile",
          desc: "You'll be able to check your profile and update your data there.",
          position: "right",
          element: "DashboardUserProfile",
        },
        {
          titel: "Subscription",
          desc: "To check for our Subscription plan, please visit Subscription tab. ",
          position: "top",
          element: "DashboardSubcription",
        },
        {
          titel: "Report Problems",
          desc: "If you find any bug/problem with our platform, please write an issue for us. ",
          position: "top",
          element: "star-button",
        },
        {
          titel: "Documentation",
          desc: "To learn more on how to use our platform, please check our documentation site. ",
          position: "top",
          element: "docs-button",
        },
        {
          titel: "Help",
          desc: "As a new user, we thought it would be good to walk you through our platform. ",
          position: "top",
          element: "DashboardHelppage",
        },
        {
          titel: "Language",
          desc: "Our platform supports two languages, more to come. ",
          position: "bottom",
          element: "topbarLangue",
        },
        {
          titel: "Profile",
          desc: "You can view your profile with just a click away. ",
          position: "bottom",
          element: "topbarPofile",
        },
      ],
      listItemStep: [
        {
          titel: "Table List",
          desc: "You will be able to view your tests listed there.",
          position: "right",
          element: "listItemPageTableListe",
        },
        {
          titel: "Table Card",
          desc: "You will be able to have a card view of your tests",
          position: "right",
          element: "listItemPageTableCard",
        },
        {
          titel: "Table Thumb",
          desc: "You will be able to have your tests listed with screenshots.",
          position: "right",
          element: "listItemPageTableThumb",
        },
        {
          titel: "Filter",
          desc: "Filter allows you to search easily across tests.",
          position: "right",
          element: "listItemPageTableFilter",
        },
        {
          titel: "Add test",
          desc: "You can also add tests via your selenium/Cypress code, or the Wringr Observer.",
          position: "right",
          element: "listItemPageTableAddtest",
        },
        {
          titel: "Number of elements ",
          desc: "You can choose how many tests you want to see/check.",
          position: "bottom",
          element: "listItemPageTableNumberElement",
        },
        {
          titel: "Edit test",
          desc: "You can change the value of a field after you create it!",
          position: "bottom",
          element: "listItemPageTableEditTestBtn",
        },
        {
          titel: "Rerun test",
          desc: "Rerun allows you to run the same test again.",
          position: "bottom",
          element: "listItemPageTableRerunTestBtn",
        },
        {
          titel: "Delete test",
          desc: "You can delete permanently your test.",
          position: "bottom",
          element: "listItemPageTableDeleteTestBtn",
        },
      ],
      listProjectStep: [
        {
          titel: "PageView Project",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjecPageViewtProject",
        },
        {
          titel: "PageView Event",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjectPageViewEvent",
        },
        {
          titel: "Filter",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "right",
          element: "listProjectFilter",
        },
        {
          titel: "Add Project ",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectAddProject",
        },
        {
          titel: "Edit test",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectEdit",
        },
        {
          titel: "Project Detail",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectDetail",
        },
        {
          titel: "Delete Project",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "listProjectDeleteTestBtn",
        },
      ],
      integrationStep: [
        {
          titel: "CI integrations",
          desc: "The CI Integrations displays all active integrations per user. Different actions are available for different integrations",
          position: "right",
          element: "intergrationsCi",
        },
        {
          titel: "Triggers",
          desc: "The Wringr platform can execute test runs based on a trigger from an external service. ",
          position: "right",
          element: "intergrationsTriggers",
        },
        {
          titel: "Scheduled Runs",
          desc: "Wringr is the easiest way to schedule your test runs for every day, every week, or any other days you want.",
          position: "right",
          element: "intergrationsScheduled",
        },
        {
          titel: " Github ",
          desc: "The Wringr integration with Github enables fast and easy reporting to speed up issue creation. When a test that has been run on the Wringr platform, fails, you can be immediately notified in your teams Github.",
          position: "bottom",
          element: "intergrationsGithub",
        },
        {
          titel: " Atlassian - JIRA ",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, nam! Facere laborum quia commodi harum. Veniam corrupti nulla reiciendis itaque eveniet nostrum est dignissimos quae aut vel, reprehenderit iste natus.",
          position: "bottom",
          element: "intergrationsAtlassianJIRA",
        },
        {
          titel: " Gitlab ",
          desc: "The Wringr Gitlab integration can be used to keep track of your test runs in Gitlab. ",
          position: "bottom",
          element: "intergrationsGitlab",
        },
        {
          titel: " Slack ",
          desc: "The Wringr Reporter Slack app keeps your team up to date with the status of your tests to a slack channel.",
          position: "bottom",
          element: "intergrationsSlack",
        },
        {
          titel: " Jenkins ",
          desc: "Automate your tests with Jenkins while taking advantage of the advanced healing and intelligence offered by Wringr by integrating Jenkins with the Wringr Interceptor.",
          position: "top",
          element: "intergrationsJenkins",
        },
        {
          titel: " Circle CI ",
          desc: "Use the Wringr Interceptor with your CircleCI builds! Getting Started with CircleCI is easy.",
          position: "top",
          element: "intergrationsCircleCI",
        },
        {
          titel: " Travis CI ",
          desc: "Use the Wringr Interceptor with Travis CI in a couple simple steps.",
          position: "top",
          element: "intergrationsTravisCI",
        },
        {
          titel: "Salesforce",
          desc: "Add your Salesforce data integration from your own account.",
          position: "top",
          element: "intergrationsSalesforce",
        },
        {
          titel: "Edit",
          desc: "You can change the value of a field after you create it!",
          position: "bottom",
          element: "intergrationsEdit",
        },
        {
          titel: "Delete ",
          desc: "You can delete permanently your tests.",
          position: "bottom",
          element: "intergrationsDeleteTestBtn",
        },
      ],
      installation: [
        {
          titel: "Selenium",
          desc: "The Selenium Tab allows you to use the selenium packages Wringr supports. ",
          position: "right",
          element: "installationSelenium",
        },
        {
          titel: "Cypress",
          desc: "The Cypress Tab allows you to use the cypress packages Wringr supports. ",
          position: "right",
          element: "installationCypress",
        },
        {
          titel: "Observer",
          desc: "You will be able to  download Wringr Smart Observer here. ",
          position: "right",
          element: "installationObserver",
        },
        {
          titel: "Windows",
          desc: "Wringr supports Selenium/Cypress and allows you to easily run them in Windows.",
          position: "right",
          element: "installationWindows",
        },
        {
          titel: "Linux MacOs",
          desc: "Wringr supports Selenium/Cypress and allows you to easily run them in Linux/MacOS",
          position: "right",
          element: "installationLinuxMacOs",
        },
        {
          titel: "Java Interceptor Packages ",
          desc: "Here, you can get started with Java Interceptor Package. ",
          position: "bottom",
          element: "installationJava",
        },
        {
          titel: "Python Interceptor Packages ",
          desc: "Here, you can get started with Python Interceptor Package. ",
          position: "bottom",
          element: "installationPython",
        },
        {
          titel: "Javascript Intercetor Packages ",
          desc: "Here, you can get started with Javascript Interceptor Package. ",
          position: "top",
          element: "installationJavascript",
        },
      ],
      listUserProfil: [
        {
          titel: "Edit User info",
          desc: "Change your informations(name/image/email)",
          position: "right",
          element: "userProfileEdit",
        },
        {
          titel: "Edit user password",
          desc: "Change/Update your password!.",
          position: "left",
          element: "userProfileEditPassword",
        },
      ],
      showOnboarding: "false",
      stepStat: 0,
      isDashboard: "/dashboard",
      toShowLeftBar: false,
      avatarText: "",
    };
  },
  async created() {
    this.addFocusToDiv();
    if (window.location.href.includes("app")) {
      this.profile =
        "https://keycloak.app.wringr.ai/realms/Wring/account/#/personal-info";
      this.logOut =
        "https://keycloak.app.wringr.ai/realms/Wring/protocol/openid-connect/logout";
    } else if (window.location.href.includes("dev")) {
      this.profile = process.env.VUE_APP_KEYCLOAK_PROFILE_URL;
      this.logOut =
        "https://keycloak.dev.wringr.ai/realms/Wring/protocol/openid-connect/logout";
    } else if (window.location.href.includes("localhost")) {
      this.profile = process.env.VUE_APP_KEYCLOAK_PROFILE_URL;
      this.logOut =
        "https://keycloak.dev.wringr.ai/realms/Wring/protocol/openid-connect/logout";
    } else if (window.location.href.includes("key")) {
      this.profile = process.env.VUE_APP_KEYCLOAK_PROFILE_URL;
      this.logOut =
        "https://keycloak.key.wring.dev/realms/Wring/protocol/openid-connect/logout";
    }

    //process.env.VUE_APP_USER_PROFILE_URL  ? this.logOut = process.env.VUE_APP_USER_PROFILE_URL : this.logOut = "https://auth.wring.dev/realms/devwringdev/account/"
    // if(localStorage.getItem("wring-user-info")){
    //   this.user = JSON.parse(localStorage.getItem("wring-user-info"))
    //   this.vallidePageview()
    //   this.avatarText = this.user.full_name
    //     ? this.user.full_name
    //     : "N/A";
    // }else{
    this.giveClickEvents();
    await this.getData();
    // }
    // this.vallidePageview()
    this.activePath = this.$route.name;
  },
  watch: {
    $route(from, to) {
      this.activePath = from.name;
    },
  },
  methods: {
    resizeMenu() {
      this.isResizeMenu = !this.isResizeMenu;
      var navLinksText = document.querySelectorAll(".nav-link-text");
      var rightContentContainer = document.querySelector(".right-content");
      var textLogo = document.querySelector(".logo-text-wring");
      var imgLogo = document.querySelector(".headr_logo");
      var leftSideBar = document.querySelector(".left-side-bar");
      var columnsMenu = document.querySelectorAll(".list_children_buttons");
      var dropDonws = document.querySelectorAll(".dropDown");
      dropDonws.forEach((item) => {
        item.style.display = "none";
      });

      if (this.isResizeMenu == false) {
        for (var i = 0; i < columnsMenu.length; i++) {
          columnsMenu[i].style.display = "none";
          columnsMenu[i].style.opacity = "0";
          columnsMenu[i].style.visibility = "hidden !important";
        }
        for (var i = 0; i < navLinksText.length; i++) {
          navLinksText[i].style.display = "none";
        }
        textLogo.style.display = "none";
        imgLogo.style.marginLeft = "9px";
        document.styleSheets[0].insertRule(
          ".right-content { width: 96% !important; }",
          0
        );
        leftSideBar.style.width = "3%";
      } else {
        var dropDonws = document.querySelectorAll(".dropDown");
        dropDonws.forEach((item) => {
          item.style.display = "block";
        });
        for (var i = 0; i < columnsMenu.length; i++) {
          columnsMenu[i].style.display = "block";
          columnsMenu[i].style.visibility = "visible";
          columnsMenu[i].style.opacity = "1";
        }
        for (var i = 0; i < navLinksText.length; i++) {
          navLinksText[i].style.display = "inline-block";
        }
        imgLogo.style.marginRight = "0px";
        textLogo.style.display = "inline-block";
        var styles =
          document.styleSheets[0].cssRules || document.styleSheets[0].rules;
        for (var i = 0; i < styles.length; i++) {
          if (styles[i].selectorText === ".right-content") {
            document.styleSheets[0].deleteRule(i);
            break;
          }
        }
        leftSideBar.style.width = "15%";
      }
    },
    addFocusToDiv() {
      var divElements = document.querySelectorAll(".btn_container");
      for (var i = 0; i < divElements.length; i++) {
        divElements[i].addEventListener("focus", function () {
          this.classList.add("focused");
        });
        divElements[i].addEventListener("blur", function () {
          this.classList.remove("focused");
        });
      }
      console.log("create");
    },
    showContent(val) {
      this.showTrigger = false;
      this.showReport = false;
      this.scheduledRun = false;
      this.showInstallation = false;
      if (val === "report") {
        this.showReport = true;
      } else if (val === "Scheduled") {
        this.scheduledRun = true;
      } else if (val === "installation") {
        this.showInstallation = true;
      } else {
        this.showTrigger = true;
      }
    },
    giveClickEvents() {
      let links = document.querySelectorAll(".nav-link");
      for (let i = 0; i < links.length; i++) {
        links[i].appendChild();
      }
    },
    dropMenu(name_list) {
      if (name_list == "integr") {
        let list = document.getElementById(name_list);

        this.dropActiveIntegr = !this.dropActiveIntegr;
        if (this.dropActiveIntegr) {
          list.style.display = "none";
          list.style.opacity = "0";
          list.style.visibility = "hidden !important";
        } else {
          list.style.display = "block";
          list.style.visibility = "visible";
          list.style.opacity = "1";
        }
      } else {
        let list = document.getElementById(name_list);

        this.dropActiveTests = !this.dropActiveTests;
        if (this.dropActiveTests) {
          list.style.display = "none";
          list.style.opacity = "0";
          list.style.visibility = "hidden !important";
        } else {
          list.style.display = "block";
          list.style.visibility = "visible";
          list.style.opacity = "1";
        }
      }
    },
    toggleDarkMode() {
      this.$store.dispatch("toggleDarkMode");
      console.log(this.$store.getters.isDarkMode);
      if (this.$store.getters.isDarkMode) {
        let body = document.querySelector("body");
        body.classList.add("dark_mode_body");
        require("assets/css/dark_style.css");
        console.log(body);
      } else {
        let body = document.querySelector("body");
        body.classList.remove("dark_mode_body");
        body.classList.add("light_mode_body");
        console.log(body);
      }
    },
    setFooter() {
      let rContent = document.getElementsByClassName("right-content")[0];
      console.log("yeeeeeee", rContent);
      let footer = document.createElement("footer");
      footer.classList.add("footer");
      footer.classList.add("mt-2");
      footer.classList.add("px-4");
      footer.innerHTML = `<div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6">
          <div class="copyright text-center text-lg-right text-muted">
            © ${this.year}
          </div>
        </div>
      </div>`;
      rContent.appendChild(footer);
    },
    showLeftBar() {
      const leftBar = document.querySelector(".left-side-bar");
      if (this.toShowLeftBar === false && leftBar !== null) {
        leftBar.classList.remove("is-open");
        leftBar.classList.toggle("is-close");
        this.toShowLeftBar = true;
      } else if (leftBar !== null) {
        leftBar.classList.remove("is-close");
        leftBar.classList.toggle("is-open");
        // leftBar.style.display = "flex"
        // leftBar.style.transition = "0.5s"
        this.toShowLeftBar = false;
      }
    },
    initOnboarding() {
      if (!localStorage.getItem("wringHelpTooltips")) {
        localStorage.setItem("wringHelpTooltips", "true");
      }
      this.showOnboarding = localStorage.getItem("wringHelpTooltips");
      if (this.routeName === "List Items") {
        this.stepsOnboarding = this.stepsOnboarding.concat(
          this.listProjectStep
        );
      }
    },
    switchLg(lg) {
      this.$i18n.locale = lg;
      localStorage.setItem("aichemy--current_language", lg);
    },
    getAvatarInitials() {
      if (this.avatarText) {
        const avatarArray = this.avatarText.split(" ");

        if (avatarArray.length > 1)
          return avatarArray.map((word) => word[0]).join("");
        return this.avatarText.substring(0, 2);
      }
    },
    goToProfile() {
      this.$router.push({
        name: "User Profile",
      });
    },
    async getData() {
      try {
        axios
          .get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
          .then((response) => {
            if (
              response &&
              response.message === "Request failed with status code 402"
            ) {
              console.log("navbar 402");
              localStorage.removeItem("vue-authenticate.access_token");
              localStorage.removeItem("testgold.aioToken");
              localStorage.setItem(
                "login--message",
                "User trial period has ended. Please contact support@testgold.dev."
              );
              window.location.reload();
            }

            if (response.data) {
              localStorage.setItem(
                "wring-user-info",
                JSON.stringify(response.data.response)
              );
              this.user = response.data.response;
              this.vallidePageview();
              this.avatarText = this.user.full_name
                ? this.user.full_name
                : "N/A";
            }
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e.response.message}`,
        });
      }
    },
    async logout() {
      try {
        const postLogoutRedirectUri = "https://dev.wringr.ai/dashboard";
        // await this.$store.dispatch("logout");
        localStorage.removeItem("vue-authenticate.access_token");
        localStorage.removeItem("vue-authenticate.is_sharing");
        localStorage.removeItem("testgold.aioToken");
        localStorage.removeItem("testgold.userInfo");
        if (window.location.href.includes("app")) {
          window.location.replace(
            "https://keycloak.app.wringr.ai/realms/Wring/protocol/openid-connect/logout"
          );
        } else if (window.location.href.includes("dev")) {
          window.location.replace(
            `https://keycloak.dev.wringr.ai/realms/Wring/protocol/openid-connect/logout?client_id=Wring-client&post_logout_redirect_uri=${encodeURIComponent(
              postLogoutRedirectUri
            )}`
          );
        } else if (window.location.href.includes("localhost")) {
          window.location.replace(
            `https://keycloak.dev.wringr.ai/realms/Wring/protocol/openid-connect/logout?client_id=Wring-client&post_logout_redirect_uri=${encodeURIComponent(
              postLogoutRedirectUri
            )}`
          );
        } else if (window.location.href.includes("key")) {
          window.location.replace(
            "https://keycloak.key.wring.dev/realms/Wring/protocol/openid-connect/logout"
          );
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, Cannot logout ! If problem persist, please contact support",
        });
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async vallidePageview() {
      console.log(this.user);
      if (
        this.user.tier_limits.userActionsAllowedServices.includes(
          "pageview-events"
        )
      ) {
        this.showPageview = true;
      } else {
        this.showPageview = false;
      }
    },
    // async getData() {
    //   const res = await axios.get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
    //   if (res.data.status === "success") {
    //     this.user = res.data.response
    //     this.vallidePageview()
    //   } else {
    //     window.location.reload();
    //   }
    // },
    openonbrading() {
      this.showOnboarding = "true";
      this.$refs.onboarding.initializeshowonbarding();
    },
    isActive(name) {
      this.isActiveDash = false;
      this.isActiveInt = false;
      this.isActiveTests = false;
      if (name == "int") {
        this.isActiveInt = true;
      } else if (name == "tests") {
        this.isActiveTests = true;
      } else if (name == "dash") {
        this.isActiveDash = true;
      }
    },
    profileTab(profile) {
      window.open(profile, "_blank");
    },
  },
  mounted() {
    this.initScrollbar();
    setTimeout(() => {
      this.initOnboarding();
      // this.setFooter()
    }, 2000);
  },
  computed: {
    allStep() {
      if (this.$route.name === "List Items") {
        return this.stepsOnboarding.concat(this.listItemStep);
      } else if (this.$route.name === "List Poject") {
        return this.stepsOnboarding.concat(this.listProjectStep);
      } else if (this.$route.name === "Integrations") {
        return this.stepsOnboarding.concat(this.integrationStep);
      } else if (this.$route.name === "Installation") {
        return this.stepsOnboarding.concat(this.installation);
      } else if (this.$route.name === "User Profile") {
        return this.stepsOnboarding.concat(this.listUserProfil);
      } else {
        return this.stepsOnboarding;
      }
    },
  },
};
</script>
<style lang="scss">
// .dropDownReverse{
//   transform: rotate(180deg);
// }
.avatar_custom {
  z-index: 1;
  position: absolute;
  right: 20px;
  height: 50px;
  top: 20px;
}
.el-button p {
  font-size: 0.8rem;
  font-weight: 600;
}
.el-button {
  display: flex;
  margin: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.btn_container {
  margin-bottom: 10px;
  height: 30px;
}
.activeBtn {
}
.dropDown svg {
  fill: #e6e6e6;
  border-color: #e6e6e6;
}
.dropDown {
  position: relative;
  top: -34px;
  margin-left: 70%;
}
.activeBtn svg {
  transition: 0.3s;
  transform: rotate(0deg) !important;
}
.dropDown svg {
  transform: rotate(180deg);
}

.list_children_buttons {
  display: block;
  opacity: 1;
  visibility: visible;
  list-style-type: none;
  font-size: 0.82rem;
  transition: 0.5s;
}
.white_line_none {
  width: 100%;
  height: 32px;
  background-color: #e6e6e6;
  position: absolute;
  display: none;
  transition: 1s;
}

.nav_buttons_left_side {
  width: 100%;
  margin-top: 50px;
}
.headr_logo {
  display: flex;
  gap: 12px;
}
.headr_logo h1 {
  color: #e6e6e6;
}
.switch-theme-btn {
  position: fixed;
  width: 35px;
  height: 35px;
  right: 115px;
  font-weight: 700;
  border-radius: 50%;
  color: #5603ad;
  font-size: 0.9rem;
  background-color: white;
  border: solid 0.1px #5603ad;
}
.light_mode_body {
  background-color: #f7fafc !important;
}
.dark_mode_body {
  background-color: #1b1b1f !important;
}

.my_switch {
  position: absolute;
}

/* .switch {
  position: relative;

  display: inline-block;
  margin-right:6rem;
  bottom:20px;
  width: 60px;

  height: 30px;
}


/* Hide default HTML checkbox */
.my_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.btn-menu-resize-active {
  margin-left: 20px !important;
  margin-right: 50px;
}
.btn-menu-resize-active i {
  margin-bottom: 2px !important;
  margin-left: 0 !important;
  transform: rotate(0deg) !important;
}
.btn-menu-resize i {
  transform: rotate(180deg);
}
.btn-menu-resize {
  z-index: 999;
  position: fixed;
  bottom: 40px;
  margin-left: 180px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-blue-color);
  border: 2px solid white;
  color: white;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.splitItm {
  width: 100%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: var(--main-blue-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--main-blue-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}

body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}

html,
body {
  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-track {
    background: #5e72e499 !important;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #5e72e4 !important;
  }
}

.sidebar--botom {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  // flex-direction: column;
}

.git--btn {
  padding: 0 !important;
  margin-left: 51.5vw !important;
  margin-right: 0 !important;

  a {
    margin: 0 !important;
    padding: 1rem !important;
    // border-radius: 0.4rem;
    // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    // background-color: #212529;
    border: solid 0px !important;
    border-radius: 0 !important;
    color: #000000 !important;

    i {
      font-size: 1.1rem !important;
    }

    &:hover {
      i,
      .nav-link-text {
        color: initial !important;
      }
    }
  }
}
.activebtn svg path {
  stroke: black;
}
.left-side-bar.is-open {
  transition: all ease 0.5s !important;
  display: flex !important;
}

.left-side-bar.is-close {
  min-width: 100px !important;
  width: 100px;
  transition: all ease 0.5s !important;

  .custom-btn-group {
    button {
      span p {
        display: none !important;
      }
    }
  }
}

.doc--btn {
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  a {
    margin: 0 !important;
    padding: 1rem !important;
    border: solid 0px !important;
    border-radius: 0 !important;
    // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    i {
      font-size: 1.1rem !important;
    }

    &:hover {
      i,
      .nav-link-text {
        color: initial !important;
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
  position: relative;
}

.conming--soon--menu {
  // display: flex;
  align-items: center;

  a {
    padding-right: 0.4em !important;
    margin-bottom: -15px !important;
  }
}

.disabled-link::before {
  content: "Upgrade to Premium";
  margin-left: 55px;
  border: 1px solid red !important;
  padding: 0.2em;
  background: #f9ebeb !important;
  color: red !important;
  border-radius: 2px;
  font-size: 0.5rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  right: 16px;
  bottom: 40%;
}

.el-dropdown-link {
  cursor: pointer;
  color: white;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid white;
    border-radius: 0.5rem;
  }
}

.el-dropdown:hover {
  color: white;
}

.el-dropdown-link_2 {
  cursor: pointer;
  color: #6e6e6d;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid #6e6e6d;
    border-radius: 0.5rem;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  top: 2px;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none !important;
}
.active .dropDown svg {
  fill: var(--main-blue-color);
  border-color: var(--main-blue-color);
}
.active .dropDown svg path {
  stroke: var(--main-blue-color);
}

.myDropdown__menu {
  border: 2px solid white;
  background-color: orange;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item {
  color: #616161;
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
  }
}

.myDropdown__menu__2 {
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0;
}

.myDropdown__item__2 {
  color: #616161;
  background-color: #ebeced;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
  }
}

.avatar-image i {
  color: #5e72e4;
}
.avatar-image::before,
.avatar-image::after {
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
}
.avatar-image::before {
  content: "";
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  display: block;
  position: fixed;
  margin-top: 50px;
  margin-left: 15px;
}
.avatar-image::after {
  content: attr(data-content);
  color: black;
  display: block;
  position: fixed;
  right: 50px;
  margin-top: 6px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  z-index: 999;
}
.avatar-image:hover:before,
.avatar-image:hover::after {
  visibility: visible;
  opacity: 1;
}

.if-img {
  width: 50px;
  height: 50px;
  border-radius: 0.3rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.if-img-noavatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid;
  background-color: #ffffff;
  border-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--main-blue-color);
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.3s;

  img {
    width: 100%;
  }
}

.no--hover {
  .nav-link {
    transform: translate3d(0, 0, 0);

    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }
}

.dropdown-menu.show {
  width: 12em !important;
  left: 92% !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0;
}

.elt--dropdown {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  color: initial;

  &:hover {
    background-color: #5e72e4;
    color: #ffffff;

    #topbarLangue {
      .el-dropdown {
        color: white;
      }
    }
  }

  &.red {
    color: #f5365c;

    &:hover {
      background-color: #f5365c;
      color: #ffffff;
    }
  }
}

.is-content-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.is-item-nav {
  width: 100%;
  gap: 10px;
  flex-direction: column;
  display: flex;
  align-items: start;
  justify-content: center;
}

.nav-link {
  align-items: center;
  // width:5vw !important;
  display: flex;
  margin-bottom: 0 !important;
}

.navbar-brand {
  height: auto !important;
  padding: 0 !important;
  margin-right: 0;
  font-size: 1em !important;
}

.footer {
  padding: 1em;
  background: #ffffff;
}

.nav-link i {
  padding-bottom: 6px;
}
.fake {
  opacity: 0 !important;
}
</style>
