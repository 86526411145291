<template>
  <card class="card-stats" :show-footer-line="true">
    <div style="display: -webkit-box" class="row">
      <div class="col card-details">
        <slot>
          <h5 class="card-title text-uppercase text-muted mb-0" v-if="title">
            {{ title }}
          </h5>
          <span
            style="font-size: 20px; color: #1f213e"
            class="h2 font-weight-bold mb-0 card-subtitle"
            v-if="subTitle"
            >{{ subTitle }}</span
          >
          <span v-if="subTitleTime" :id="`time-stat-card_${id_card}`"></span>
        </slot>
      </div>

      <div class="col-auto" v-if="$slots.icon || icon">
        <slot name="icon">
          <div
            class="icon icon-shape text-white rounded-circle shadow"
            :class="[`bg-${type}`, iconClasses]"
          >
            <i :class="icon"></i>
          </div>
        </slot>
      </div>
    </div>

    <slot name="footer"> </slot>
  </card>
</template>
<script>
import Card from "./Card.vue";

export default {
  name: "stats-card",
  components: {
    Card,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    icon: String,
    title: String,
    subTitle: String,
    subTitleTime: String,
    iconClasses: [String, Array],
    id_card: String,
  },
  created() {
    this.splitTime();
  },
  methods: {
    splitTime() {
      this.$nextTick(() => {
        console.log(this.id_card);
        const span = document.getElementById(`time-stat-card_${this.id_card}`);
        if (this.subTitleTime.includes("h")) {
          var partsHour = this.subTitleTime.split("h");
          var partsMin = partsHour[1].split("m");
          var partsSeconds = partsMin[1].split("s");
          const minuteSpan = document.createElement("span");
          const hourSpan = document.createElement("span");
          const secondSpan = document.createElement("span");
          minuteSpan.textContent = partsMin[0];
          hourSpan.textContent = partsHour[0];
          secondSpan.textContent = partsSeconds[0];
          const letterSpanMin = document.createElement("span");
          const letterSpanHour = document.createElement("span");
          const letterSpanS = document.createElement("span");
          letterSpanMin.textContent = "m";
          letterSpanHour.textContent = "h";
          letterSpanS.textContent = "s";
          letterSpanMin.classList.add("letter");
          letterSpanHour.classList.add("letter");
          letterSpanS.classList.add("letter");
          span.appendChild(hourSpan);
          span.appendChild(letterSpanHour);
          span.appendChild(minuteSpan);
          span.appendChild(letterSpanMin);
          span.appendChild(secondSpan);
          span.appendChild(letterSpanS);
        } else if (this.subTitleTime.includes("m")) {
          var partsMin = this.subTitleTime.split("m");
          var partsSeconds = partsMin[1].split("s");
          const minuteSpan = document.createElement("span");
          const hourSpan = document.createElement("span");
          const secondSpan = document.createElement("span");
          minuteSpan.textContent = partsMin[0];
          secondSpan.textContent = partsSeconds[0];
          const letterSpanMin = document.createElement("span");
          const letterSpanHour = document.createElement("span");
          const letterSpanS = document.createElement("span");
          letterSpanMin.textContent = "m";
          letterSpanS.textContent = "s";
          letterSpanMin.classList.add("letter");
          letterSpanS.classList.add("letter");
          span.appendChild(minuteSpan);
          span.appendChild(letterSpanMin);
          span.appendChild(secondSpan);
          span.appendChild(letterSpanS);
        } else if (this.subTitleTime.includes("s")) {
          var partsSeconds = this.subTitleTime.split("s");
          const secondSpan = document.createElement("span");
          secondSpan.textContent = partsSeconds[0];
          const letterSpanS = document.createElement("span");
          letterSpanS.textContent = "s";
          letterSpanS.classList.add("letter");
          span.appendChild(secondSpan);
          span.appendChild(letterSpanS);
        } else {
          this.subTitleTime = this.subTitleTime.toString();
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/sass/custom/variablesCustom";
.card-stats {
  transition: 0.3s;
}
.card-stats:hover {
  box-shadow: $card-shadow;
}
</style>
