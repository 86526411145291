<template>
  <div style="height: 100%">
    <div
      v-if="isDataLoading"
      class="flex justify-center align-center mt-5"
      :style="{ display: fullsize ? 'none' : '' }"
    >
      <img
        src="/img/loading.gif"
        alt="loading..."
        style="height: 64px; width: 64px"
      />
    </div>
    <div
      id="myDiagramDiv"
      ref="diagram"
      :style="{ height: fullsize ? '100vh' : '50vh' }"
      style="width: 100%; background: #e6e6e6"
    ></div>
    <button
      v-if="fullsize && !isDataLoading"
      class="btn-close-fullscreen"
      @click="$parent.fullSize = false"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
          d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"
        />
      </svg>
    </button>
  </div>
</template>
<script>
import cytoscape from "cytoscape";
import { Loading } from "element-ui";
import VFlowChart from "/vue2-flow.esm.js";
import axios from "axios";
import { lab, random, svg } from "d3";
import dagre from "cytoscape-dagre";
import coseBilkent from "cytoscape-cose-bilkent";
import cola from "cytoscape-cola";
import klay from "cytoscape-klay";
import elk from "cytoscape-elk";
import go, { Model, Shape, Spot, TreeModel } from "gojs";

export default {
  components: {},
  props: {
    fullsize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDataLoading: false,
      myDiagram: null,
      isShowKeyFlow: false,
      allFlows: [],
      links: [],
      flows: [],
      flow_nodes: [],
      lastClickTime: 0,
      nodes: [],
      edges: [],
    };
  },

  async mounted() {
    await this.getData();
    await this.initDiagram(this.nodes, this.links);
  },

  methods: {
    clearDiagram() {
      this.myDiagram.div = null;
    },
    initDiagram(nodes, links) {
      this.isDataLoading = true;
      const $ = go.GraphObject.make;
      this.myDiagram = new go.Diagram("myDiagramDiv");
      this.myDiagram.div = document.getElementById("myDiagramDiv");
      this.myDiagram.initialContentAlignment = go.Spot.Center;
      // this.myDiagram.padding = new go.Margin(10);
      this.myDiagram.contentAlignment = go.Spot.Center;

      this.myDiagram.nodeTemplate = $(
        go.Node,
        "Vertical",
        {
          background: "#E6E6E6",
          movable: false,
          click: (e, node) => {
            // highlight all Links and Nodes coming out of a given Node
            this.handleShowPopUp(node.data.key, node.data.source.split(",")[1]);
            this.myDiagram.startTransaction("highlight");
            this.myDiagram.clearHighlighteds();
            node.findLinksOutOf().each((l) => (l.isHighlighted = true));
            node.findNodesOutOf().each((n) => (n.isHighlighted = true));
            this.myDiagram.commitTransaction("highlight");
          },
        },

        $(
          go.Picture,
          {
            imageStretch: go.GraphObject.Default,
          },
          new go.Binding("source")
        )
      );
      this.myDiagram.linkTemplate = $(
        go.Link,
        {
          routing: go.Link.AvoidsNodes,
          corner: 15,
          toolTip: $(
            go.Adornment,
            "Auto",
            $(go.Shape, { fill: "#FFFFCC" }),
            $(
              go.TextBlock,
              { margin: 4, font: "bold 16px sans-serif" },
              new go.Binding("text", "text_tooltip")
            )
          ),
        },
        $(
          go.Shape,
          new go.Binding("stroke", "isHighlighted", (h) =>
            h ? "red" : "black"
          ).ofObject(),
          new go.Binding("strokeWidth", "isHighlighted", (h) =>
            h ? 3 : 1
          ).ofObject()
        ),

        $(
          go.Shape,
          { toArrow: "Standard", strokeWidth: 10 },
          new go.Binding("fill", "isHighlighted", (h) =>
            h ? "red" : "black"
          ).ofObject()
        ),

        $(
          go.Panel,
          "Auto",
          { margin: 100 },
          $(
            go.Shape,

            {
              figure: "RoundedRectangle",
              strokeWidth: 1.5,
              fill: "#FFFFFF",
              stroke: "#FFFFFF",
            }
          ),
          $(go.TextBlock, {
            textAlign: "center",
            width: 200,
            height: 20,
            shadowVisible: false,
            margin: 20,
            font: "bold 24px sans-serif",
            stroke: "#1F213E",
          }).bind("text", "text")
        )
      );

      const nodeDataArray = nodes.map((node) => {
        return {
          key: node.id,
          source:
            node.screenshot == null
              ? "/img/black_square.svg"
              : `data:image/png;base64,${node.screenshot}`,
          text: node.label,
        };
      });
      const linksDataArray = links.map((link) => {
        return {
          from: link.source,
          to: link.target,
          text: link.description,
          text_tooltip: link.description,
        };
      });
      this.links = linksDataArray;
      this.myDiagram.layout = $(go.LayeredDigraphLayout, {
        layerSpacing: 700,
        columnSpacing: 300,
        layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource,
      });

      this.myDiagram.model = new go.GraphLinksModel(nodeDataArray, this.links);

      this.myDiagram.toolManager.hoverDelay = 0;
      this.myDiagram.padding = new go.Margin(300);
      var rect = new go.Rect(10, 10, 3000, 3000);
      this.myDiagram.zoomToRect(rect);
      this.$nextTick(() => {
        this.isDataLoading = false;
      });
    },

    async createDiagram() {
      this.clearDiagram();
      await this.getData();
      await this.initDiagram(this.nodes, this.links);
    },
    async updataNetwork() {
      this.isShowKeyFlow = true;

      //Getting nodes and links for chosen flow
      const label_choosen_flow = localStorage.getItem("selectKeyFlow");
      const chosen_flow = this.allFlows.find(
        (flow) => flow.label == label_choosen_flow
      );
      const flow_links = chosen_flow.edges.map((link) => {
        var description_text = "";
        for (let i = 0; i < this.links.length; i++) {
          if (this.links[i].from == link[0] || this.links[i].to == link[1]) {
            description_text = this.links[i].text;
          }
        }
        return {
          description: description_text,
          source: link[0],
          target: link[1],
        };
      });
      const flow_nodes = chosen_flow.nodes.map((node) => {
        for (let i = 0; i < this.nodes.length; i++) {
          if (this.nodes[i].id == node) return this.nodes[i];
        }
      });
      //draw flow
      this.clearDiagram();
      this.initDiagram(flow_nodes, flow_links);
    },

    handleShowPopUp(node_id, screenshot) {
      let currentTime = Date.now();
      if (currentTime - this.lastClickTime < 300) {
        let buttonShow = document.querySelector(".btn-graph--show");
        let buttonClear = document.querySelector(".btn-graph--clear");
        let closeGraph = document.getElementById("close-img");
        let imgModal = document.getElementById("imgModal");
        let modalImg = document.getElementById("screenshot");
        imgModal.style.display = "block";
        this.getAppMapScreen(modalImg, node_id, screenshot);
        this.$nextTick(() => {
          JSON.stringify(localStorage.setItem("idNode", node_id));
          this.$parent.showMapScreen();
          console.log(this.$parent);
        });
      }
      this.lastClickTime = currentTime;
    },
    async getAppMapScreen(img, id, oldImg) {
      let appMapId = localStorage.getItem("appMapId");
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&app_map_id=${appMapId}&node_id=${id}`;
      let loadingInstance = Loading.service({ fullscreen: true });
      try {
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "finished") ||
          res.data.status == 200
        ) {
          console.log(res);
          if (res.data.exploration_results !== null) {
            if (res.data.exploration_results.screenshot !== undefined) {
              img.src = `data:image/png;base64,${res.data.exploration_results.screenshot}`;
            } else {
              img.src = `data:image/png;base64,${oldImg}`;
            }
          } else {
            img.src = `data:image/png;base64,${oldImg}`;
          }
          this.$nextTick(() => {
            loadingInstance.close();
          });
        } else {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          img.src = `data:image/png;base64,${oldImg}`;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // Get info
    async getData() {
      this.isDataLoading = true;
      try {
        let appMapId = localStorage.getItem("appMapId");
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&app_map_id=${appMapId}`;
        const response = await axios.get(url);
        console.log(response);
        this.nodes = response.data.summary_graph.nodes;
        this.allFlows = response.data.flows;

        for (let a = 0; a < response.data.summary_graph.links.length; a++) {
          let link = response.data.summary_graph.links[a];
          this.links.push(link);
        }
      } catch (error) {
        console.error("Error", error);
        this.isDataLoading = false;
      }
    },
  },
};
</script>

<style>
.new_element {
  width: 500px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background-color: #5603ad;
}

.resizer {
  z-index: 2;
  width: 3px;
  height: 90%;
  background-color: #5603ad;
  position: fixed;
  cursor: col-resize;
}

.btn-icon {
  position: absolute;
}

.btn-details-gallery {
  max-width: 30px;
}

.btn-gallery_round {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: var(--main-blue-color);
  z-index: 1;
  border-radius: 50%;
  right: -30px !important;
  top: 50%;
  display: flex;
  align-items: start;
  color: white;
  justify-content: center;
  flex-direction: column;
}

.btn-gallery_round_open {
  position: fixed;
  right: 435px;
  margin-right: -15px;
  z-index: 1;
  text-align: right;
  top: 50%;
  transition: 0.5s;
  width: 50px;
  height: 50px;
  background-color: var(--main-blue-color);
  border-radius: 50%;
  align-items: start;
  color: white;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.fullscreenloading {
  position: absolute;
  z-index: 100;
}
.btn-gallery {
  position: relative;
  display: block;
  right: 8px;
  width: 24px;
  height: 24px;
  background-size: cover;

  color: white;

  background-color: var(--main-blue-color);
}

.btn-menu {
  position: fixed;
  margin-left: 0px;
}

.menu-buttons {
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.fade-in {
  pointer-events: all;
  opacity: 1;
}

.con-context {
  left: 40%;
  top: 10%;
  display: inline-block;
  z-index: 11;
  color: black;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  position: fixed;
  background-color: white;
  color: black;
  widows: auto;
  height: auto;
}

.node__content {
  position: relative;
  z-index: 10;
}

.index_img {
  position: absolute;
  font-size: 28px;
  padding: 10px;
  color: white;
}

.chart__self {
  border: 2px solid #e6e6e6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.photo {
  width: 424px;
  height: 270px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid gray;
  border-radius: 10px;
  transition: 0.1s;
}

.photo:hover {
  border: 2px solid black;
}

.photo-container {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: white;
  margin-right: 10px;
  z-index: 2;
  display: grid;
  justify-content: center;
  overflow-y: auto;
  padding: 30px;
  gap: 45px;
  text-align: right;
  width: 440px;
  height: 90%;
  position: fixed;
  right: -50%;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  transition: 0.5s;
  overflow: auto;
  resize: horizontal;
}

.photo-container-open {
  right: 0;
}

.text_context {
  display: flex;
  font-size: 16px;
  color: black;
  word-break: break-word;
}

.newconn {
  display: inline-block;
  white-space: nowrap;
  flex-direction: row;
  align-items: end;
  padding: 20px;
  position: absolute;
  max-width: auto;
  height: auto;
  background: white;
  /* shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.btn-container {
  position: fixed;
  right: 0;
  z-index: 1;
  text-align: right;
  margin-top: 10px;
  margin-right: 85px;
  transition: 0.5s;
}

.shifted-btn-container {
  right: 435px;
}

.btn-details {
  z-index: 1;
  max-width: 30px;
  min-width: 30px;
  border-radius: 20px;
  margin-right: 10px;
  background: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  color: white;
  padding: 10px;
  font-weight: 600;
  transition: 0.4s;
}
.right-content {
  overflow: hidden !important;
}
.btn-details:hover {
  background-color: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  transform: translateY(-5px);
}

.btn-close-det {
  position: absolute;
  left: 0;
  top: 0;
  margin: 7%;
  border-radius: 20px;
  background: #5603ad;
  border: 1px solid #5603ad;
  color: white;
  padding: 10px;
  font-weight: 600;
  transition: 0.3s;
}

.btn-close-det:hover {
  background-color: #6600ba;
  border: 1px solid #6600ba;
  transform: translateY(-5px);
}

.btn-popup {
  width: 68px;
  height: 66px;
  margin-bottom: 100px;
  border-radius: 50%;
  border: 1px solid #5603b5;
  color: white;
  font-size: 32px;
  background-color: #5603b5;
  transition: 0.3s;
}

.btn-popup:hover {
  font-size: 32px;

  background-color: #6600ba;
  border: 1px solid #6600ba;
}

.dis-popup {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
}
#myDiagramDiv div {
  overflow: hidden !important;
}
.dis-text {
  cursor: pointer;
  background-color: black;
  border-radius: 5px;
  color: white;
  font-size: 24px;
  word-break: break-word;
  max-width: 50%;
  font-weight: 600;
  margin-bottom: 8%;
  padding: 20px;
  transition: 0.3s;
}

.dis-text:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  border: 1px solid white;
}

.popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
}

.popup-content {
  cursor: pointer;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 80%;
  height: 80%;
}

.popup-content:hover {
  border: 1px solid white;
}

rect {
  overflow: scroll;
}

.chart__self {
  height: 83vh;
}

span {
  background-size: 100% 100%;
  background-clip: border-box;
  background-position: center;
  background-repeat: no-repeat;
}

p .flow-conainer {
  width: 100%;
  position: relative;
  height: 100%;
  right: 0;
}

.toolbar {
  height: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.context__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 358.2px;
  height: 56px;
  background: #ffffff;
  /* shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.context__button {
  /* Frame 46424 */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  height: 100%;
  background: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.context__button-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  height: 100%;
  /* Greys/Cool Grey/50 */
  background: #f9fafb;
  border-radius: 0px 4px 4px 0px;
  color: #9ca3af;
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.context__link {
  margin: auto;
  text-decoration: none;
  color: #9ca3af;
}
</style>
