<template>
  <div class="left-side-bar">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "leftsidebar",
  data() {
    return {
      log: console.log,
    };
  },
  props: {},
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss">
@import "../../assets/sass/custom/variablesCustom";
$pColor: #525f7f;
$color: #5e72e4;
.left-side-bar {
  position: absolute;
  width: 15%;
  height: 100vh;
  background-color: var(--main-blue-color);
  display: flex;
  flex-direction: column;
  border-top-right-radius: 20px;
  justify-content: flex-start;
  align-items: start;
  padding-bottom: 40px;
  transition: 0.4s;
}

@media (max-width: 400px) {
  .left-side-bar {
    min-width: 100px;
  }
  .custom-btn-group {
    justify-items: center;
  }
}
.mp--l {
  // span {
  //   color: rgba($pColor, 0.65);
  //   cursor: pointer;

  //   //font-size: 1.25rem;
  //   &.active {
  //     color: #25a18e;
  //   }
  // }

  .small_btn {
    background: rgba($color, 0.1);
    border: solid 0.1px $color;

    i,
    span {
      font-size: 0.75rem !important;
      font-weight: 600;
      color: $color;
    }

    &:hover {
      background: rgba($color, 0.3);

      i,
      span {
        color: rgba($color, 0.75);
      }
    }

    &.active {
      i,
      span {
        color: #fff !important;
      }
    }
  }

  .dashed--btn {
    border-style: dashed;
  }
}
.mp--r {
  .pag--details {
    color: rgba($pColor, 0.65);
    font-size: 0.85rem;
    white-space: nowrap;
  }
}
.custom_tooltip {
  bottom: 12%;
  position: fixed;
  left: 24.5%;
}
.custom-btn-group {
  display: grid;
  .active {
    i {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
}
.button-menu {
  margin-top: 1rem !important;
  border-radius: 30px !important;
  margin-left: 0 !important;
}
.button-menu-integration {
  width: 100%;
  border-radius: 30px !important;
  margin-top: 1rem !important;
  margin-left: 0 !important;
}

@media (max-width: 400px) {
  .button-menu-integration {
    width: 50%;
    span {
      display: none;
    }
  }
}
.custom-btn-group {
  display: flex;
  gap: 5px;
}
.custom_btn_second {
  bottom: 5%;
  position: fixed;
  z-index: 9;
  left: 22%;
  font-size: 25px !important;
}
</style>
