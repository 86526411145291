<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/test-explorer/list-tests"
        ><i class="el-icon-house"></i
      ></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link to="/details/project-explorer/list-projects">
        Apps
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link
        :to="`/details/project-explorer/view-project/${$route.params.id}`"
      >
        Live events
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Details Screenshots </span>
    </div>
    <template>
      <div
        v-loading="loadingRun"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <!-- <div class="mid--page_container">
          <div class="mid--page my-3 pb-3" style="border: none;">
            <div class="mp--l">
              <el-input :placeholder="$t('list_item_page.search')" v-model="queryCoveringtests">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <el-button @click="toggleShowFilters = !toggleShowFilters" icon="el-icon-guide"
                class="ml-3 small_btn dashed--btn" plain size="small">
                {{ $t("list_item_page.filter") }}
              </el-button>
            </div>
          </div>
        </div> -->
        <div class="eff--page">
          <!-- <div class="mod--content lite" v-if="toggleShowFilters">
            <br />
            <el-form class="small-spaces" @submit.native.prevent="getCoveringTests()">
              <el-row :gutter="24">
                <el-col :span="9">
                  <el-form-item label="Date">
                    <el-date-picker style="width: 100%" type="date" placeholder="from" v-model="filterDate[0]"
                      :picker-options="pickerBeginDateBefore" :disabledDate="pickerBeginDateBefore"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="limit">
                    <el-select v-model="limitCoveringtests" placeholder="Select limit">
                      <el-option v-for="item in limitCoveringtestsList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Select Cov %">
                    <el-select v-model="SelectCov" placeholder="Select Cov" style="width: 100%">
                      <el-option v-for="item in SelectCovList" :key="item" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <base-button type="primary" native-type="submit">
                Process filter
              </base-button>
              <base-button type="info" @click="toggleShowFilters = !toggleShowFilters">
                Close
              </base-button>
            </el-form>
          </div> -->
          <template>
            <data-list
              :tHeader="Header3"
              :loading="loadingSteps"
              :items="toShowSteps"
              :customSizes="true"
              ref="dataList"
            >
              <template #content>
                <div
                  v-for="(elt, id) in toShowSteps"
                  :key="id"
                  class="tblb--row mb-2"
                >
                  <div class="tblb--inner">
                    <div class="elt-row" style="width: 40%">
                      <el-tooltip
                        class="item"
                        effect="light"
                        :content="elt.url"
                        placement="top"
                      >
                        <span
                          class="run-tags"
                          style="
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ elt.url }}
                        </span>
                      </el-tooltip>
                    </div>
                    <div class="elt-row" style="width: 15%">
                      {{ elt.tests[0].statistics["relative covering percent"] }}
                    </div>
                    <div class="elt-row" style="width: 20%">
                      {{ elt.tests[0].statistics["covered elements"] }}
                    </div>
                    <div class="elt-row" style="width: 15%">
                      <el-tooltip
                        class="item"
                        effect="light"
                        content="Details"
                        placement="top"
                      >
                        <el-button
                          type="primary"
                          round
                          plain
                          size="small"
                          style="margin-left: 10px"
                        >
                          Details
                        </el-button>
                      </el-tooltip>

                      <!-- <el-tooltip
                      class="item"
                      effect="light"
                      content="Download test file"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-download"
                        @click.stop="actionCoveringtests('downloadTestFile', elt)"
                        circle
                        plain
                        size="small"
                      >
                      </el-button>
                    </el-tooltip> -->
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      Header2: [
        {
          label: "",
          key: "_id",
          width: "6%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Continent/Country".toUpperCase(),
          key: "",
          width: "23%",
          sortable: false,
        },
        {
          label: "url".toUpperCase(),
          key: "",
          width: "27%",
          sortable: false,
        },
        {
          label: "browser".toUpperCase(),
          key: "",
          width: "12%",
          sortable: false,
        },
        {
          label: "start time".toUpperCase(),
          key: "",
          width: "16%",
          sortable: false,
        },
        {
          label: "duration".toUpperCase(),
          key: "",
          width: "12%",
          sortable: false,
        },
      ],
      Header3: [
        {
          label: "Screenshot".toUpperCase(),
          key: "",
          width: "40%",
          sortable: false,
        },
        {
          label: "Step".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "description".toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        {
          label: "action".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
      ],
      Header: [
        {
          label: "Project name".toUpperCase(),
          key: "name",
          width: "60%",
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSession: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSessionProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      result: [],
      eventSessionType: "pageviews",
      filteredResults: [],
      filteredResultsSession: [],
      to: 0,
      toSession: 0,
      from: 0,
      fromSession: 0,
      total: 0,
      totalSession: 0,
      loading: true,
      loadingSession: false,
      loadingSteps: false,
      toShow: [],
      toShowSession: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      query: "",
      querySession: "",
      querySessionEvent: "",
      queryCoveringtests: "",
      querySessionId: "",
      resultSession: {},
      showEvents: false,
      showSessions: true,
      events: [],
      tagsEvent: {},
      resultEvent: {},
      showVideo: false,
      id: "",
      loadingVideo: false,
      addTestModal: false,
      orEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      filesList: [],
      filesListName: "",
      loadingModalAddRun: false,
      lastUrlTest: "",
      showProject: false,
      showEvent: true,
      addTestProject: false,
      projectName: "",
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      projectId: null,
      showDetailProject: false,
      projectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      loadingDetailProject: true,
      selectProjectId: undefined,
      ultraIpt: "",
      showCoveringtests: false,
      toShowCoveringtests: [],
      toShowSteps: [],
      date_to: undefined,
      date_from: undefined,
      limitCoveringtests: 50,
      percent: 20,
      loadingShowCoveringtests: false,
      fileBackup: [],
      toggleShowFilters: false,
      limitCoveringtestsList: [50, 100, 150, 200, 300, 400, 500, 1000],
      filterDate: [
        new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000),
        new Date(),
      ],
      activeSessionDuration: 0,
      autoplay: false,
      mommentVideo: "",
      SelectCovList: [
        { label: "20%", value: 20 },
        { label: "50%", value: 50 },
        { label: "70%", value: 70 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      SelectCov: 20,
      resultCoveringtests: [],
      loadingFile: false,
      multipleSelection: [],
      checked: [],
      loadingRun: false,
    };
  },
  async created() {
    sessionStorage.getItem("testEventPageNbr")
      ? (this.pagination.perPage = parseInt(
          sessionStorage.getItem("testEventPageNbr")
        ))
      : (this.pagination.perPage = 10);
    sessionStorage.getItem("sesssionPageNbr")
      ? (this.paginationSession.perPage = parseInt(
          sessionStorage.getItem("sessionPageNbr")
        ))
      : (this.paginationSession.perPage = 10);
    sessionStorage.getItem("projectPageNbr")
      ? (this.paginationProject.perPage = parseInt(
          sessionStorage.getItem("projectPageNbr")
        ))
      : (this.paginationProject.perPage = 10);
    this.total = this.result.length;
    this.totalProject = this.resultProject.length;
    await this.getSteps();
  },
  methods: {
    routerIds() {
      console.log(this.$route.params);
    },
    remove(i) {
      this.filesList.splice(i, 1);
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },

    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    },
    async getSteps() {
      this.loadingSteps = true;

      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;
      let sessionId = this.$route.params.session_id;
      const resp = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          `/rl/v1/stages?session_id=${sessionId}&project_id=${projectId}`
      );
      const respInfo = resp.data.response;
      this.loadingSteps = false;
    },
    savePagination() {
      sessionStorage.setItem("testEventPageNbr", this.pagination.perPage);
      this.getEvents();
    },
    savePaginationSession() {
      sessionStorage.setItem("sessionPageNbr", this.paginationSession.perPage);
      this.getSessions();
    },
    handleSizeChangeSession(val) {
      this.fromSession = (val - 1) * this.paginationSession.perPage;
      this.toSesion =
        parseInt(this.fromSession) + parseInt(this.paginationSession.perPage);
      const sortByMapped = (map, compareFn) => (a, b) =>
        compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsSession = [...this.filteredResultsSession].sort(
        byPrice
      );
      this.toShowSession = this.filteredResultsSession;
    },
    handleSizeCoveringTests() {
      const sortByMapped = (map, compareFn) => (a, b) =>
        compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(
        byPrice
      );
      this.toShowProject = this.filteredResultsProject;
    },
    handleCurrentChangeSession(val) {
      val === "next"
        ? (this.paginationSession.currentPage =
            this.paginationSession.currentPage + 1)
        : (this.paginationSession.currentPage =
            this.paginationSession.currentPage - 1);
      this.getSessions();
    },
    initializeSessionState() {
      this.filteredResultsSession = this.filteredResultSessionSet;
      this.handleSizeChangeSession(this.paginationSession.currentPage);
    },
    initializeSessionEvent() {
      this.tagsEvent = this.filteredResultSessionEventSet;
    },
    initializeCoveringtestsState() {
      this.toShowCoveringtests = this.filteredResultCoveringtestsSet;
    },
    forStepNumber(row) {
      return row + this.fromSession + 1;
    },
    removedtag(row) {
      return row.replace("$", "");
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getFiletest(val) {
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", val.tests[0].session_id);
      params.append("time_gap", this.timeGap);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX + "/pageviews/testgen?" + params
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            } else if (response.status === 200) {
              this.builFile(response.data);
            } else {
              this.$notify({
                type: "danger",
                message: `recovery of the execution file failed : ${response.data.message}`,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "recovery of the execution file failed ",
          });
        });
    },
    builFile(file) {
      if (file.length) {
        // Just get the last test
        // file = file[file.length - 1];
        file.map((item, index) => {
          if (item.tests[0].commands[0].command) {
            let jsonStringified = JSON.stringify(item);
            let jsonBlob = new Blob([jsonStringified], {
              type: "application/json",
            });
            let testFile = new File(
              [jsonBlob],
              `multiple-run-key-flows-file_${this.fileBackup.length}.wring`
            );
            this.fileBackup.push(testFile);
            this.filesList.push(testFile);
          }
        });
      } else {
        if (file.tests[0].commands[0].command) {
          let jsonStringified = JSON.stringify(file);
          let jsonBlob = new Blob([jsonStringified], {
            type: "application/json",
          });
          let testFile = new File(
            [jsonBlob],
            `multiple-run-key-flows-file_${this.fileBackup.length}.wring`
          );
          this.filesList.push(testFile);
          this.fileBackup.push(testFile);
        }
        this.loadingModalAddRun = false;
      }
    },
    initchecked() {
      for (let i = 0; i < this.resultCoveringtests.length; i++) {
        this.checked[i] = false;
      }
    },
    async getCoveringTests() {
      this.loadingShowCoveringtests = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      this.date_from = moment(this.filterDate[0]).format("YYYY-MM-DD");
      // this.date_to = moment(this.filterDate[1]).format("YYYY-MM-DD");
      // params.append("date_to", this.date_to);
      params.append("date_from", this.date_from);
      if (this.date_from == "Invalid date") {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "Invalid date.",
        });
        this.loadingShowCoveringtests = false;
        return;
      }
      params.append("limit", this.limitCoveringtests);
      params.append("srcdata", "sessions");
      params.append("project_id", projectId);
      // params.append("percent", this.SelectCov);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/keyflow?${params}`;
      try {
        axios.defaults.validateStatus = (status) => {
          return (
            status === 400 || (status >= 200 && status < 300) || status === 500
          );
        };
        const res = await axios.get(url, params);
        if (res.data && res.status === 200) {
          this.resultCoveringtests = res.data.testcases.reverse();
          // this.resultCoveringtests = res.data.testcases.map((item, id) => {
          //   item.statistics = {}
          //   item.statistics['relative covering percent'] = res.data.info.data.covering_statistics.percentages[id]
          //   item.statistics['covered elements'] = res.data.info.data.covering_statistics.cluster_sizes[id]
          //   return item
          // });
          await this.initchecked();
        } else {
          this.toShowCoveringtests = [];
          console.log(res.data.message);
        }
      } catch (error) {
        console.log("an error occurred while covering the project.:", error);
      } finally {
        this.loadingShowCoveringtests = false;
        this.initializeCoveringtestsState();
      }
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.resultProject = res.data.response;
          this.totalProject = res.data.response.length;
        } else {
          this.resultProject = [];
          this.totalProject = 0;
        }
      } catch (error) {
        console.log(errors);
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
        this.selectProjectId = Number(this.$route.params.id);
        this.filteredResultsProject = this.filteredResultProjectSet;
      }
    },
    handleMultipleSelection(elt, id) {
      if (this.checked[id]) {
        elt["index"] = id;
        this.multipleSelection.push(elt);
      } else {
        this.multipleSelection = this.multipleSelection.filter((item) => {
          if (item.index != id) {
            return item;
          }
        });
      }
    },
    async RunAll() {
      this.filesList = [];
      this.fileBackup = [];
      const time_gap = await swal.fire({
        title: "Inactivity period for splitting tests",
        input: "number",
        inputPlaceholder: "0 to 10,000",
        preConfirm: (value) => {
          if (value == "") {
            swal.showValidationMessage("minimum value is 0");
          } else if (parseInt(value) > 10000) {
            swal.showValidationMessage("maximum value is 10000");
          }
          return value;
        },
        showCloseButton: true,
      });

      if (isNaN(Number(time_gap.value))) {
        return;
      } else {
        this.timeGap = time_gap.value;
      }

      this.loadingRun = true;
      for (let item of this.multipleSelection) {
        await this.getFiletest(item);
      }
      if (this.fileBackup.length > 0) this.addTestModal = true;
      this.loadingRun = false;
    },
  },
  computed: {
    filteredResultSet() {
      const seq = this.result;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.query) {
              return item.person.properties.$geoip_country_name
                .toLowerCase()
                .includes(this.query.toLowerCase());
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultSessionSet() {
      const seq = this.resultSession;

      if (this.eventSessionType === "mobile") {
        return seq && Array.isArray(seq)
          ? seq.filter((item) => {
              if (!this.querySession) {
                return item;
              }
            })
          : [];
      } else {
        return seq && Array.isArray(seq)
          ? seq.filter((item) => {
              if (this.querySession) {
                if (
                  item.person.properties.$geoip_country_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$geoip_country_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$geoip_continent_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$geoip_continent_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_current_url.toLowerCase()
                ) {
                  return item.person.properties.$initial_current_url
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.app_name.toLowerCase()) {
                  return item.app_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.operating_system.toLowerCase()) {
                  return item.operating_system
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.person.properties.$initial_os.toLowerCase()) {
                  return item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_browse.toLowerCase()
                ) {
                  return item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (this.formatDate(item.start_time).toLowerCase()) {
                  return this.formatDate(item.start_time)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  this.formatDate(item.session_created_at).toLowerCase()
                ) {
                  return this.formatDate(item.session_created_at)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  this.secondsToRealTime(item.recording_duration).toLowerCase()
                ) {
                  return this.secondsToRealTime(item.recording_duration)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                }
              } else {
                return item;
              }
            })
          : [];
      }
    },
    filteredResultSessionEventSet() {
      const seq = this.resultEvent;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.querySessionEvent) {
              if (
                item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              } else if (
                item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultCoveringtestsSet() {
      const seq = this.resultCoveringtests;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.queryCoveringtests.length > 0) {
              if (
                item.name
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase())
              ) {
                return item.name
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase());
              } else if (
                item.url
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase())
              ) {
                return item.url
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase());
              }
            } else if (item.tests[0]) {
              return item;
            }
          })
        : [];
    },
    filteredList() {
      return this.filteredResults.slice(this.from, this.to);
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    pagesSession() {
      return Math.ceil(
        this.filteredResultsSession.length / this.paginationSession.perPage
      );
    },
    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
    canAddPreviousSession() {
      if (this.paginationSession.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    querySession: {
      handler: "initializeSessionState",
      immediate: true,
    },
    querySessionEvent: {
      handler: "initializeSessionEvent",
      immediate: true,
    },
    paginationSession: {
      handler: "initializeSessionState",
      immediate: false,
      deep: true,
    },
    queryCoveringtests: {
      handler: "initializeCoveringtestsState",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .modal-body {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.run-name {
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  color: var(--main-blue-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  font-weight: 600;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #6e6e6e;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  color: var(--main-blue-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: inline-block;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: var(--main-blue-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 6px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  height: 30px;
  display: inline-block;
  max-width: 100% !important;
  // padding: 7px 0.35rem;
  text-align: center;
  text-transform: capitalize;
  border: solid 0.1px #759dcb;
  background: #759dcb;
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #e6e6e6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 50%;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 700px;
  height: 10vh;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid #cccccc !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container
  .edit-test-select
  .el-select
  .el-input
  .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 60px !important;
}
.small--select input {
  padding-right: 20px !important;
}

.c--breadcrumb {
  padding-left: 5px;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: #53568a;
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: #53568a;
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  // border-bottom: solid 0.1px rgba($pColor, 0.25);
  .el-input__inner::placeholder {
    color: #7d7d7d;
    font-weight: 500;
    font-size: 14px;
  }
  .el-icon-search:before {
    color: #7d7d7d;
  }
  .el-input__inner {
    // padding: 10px 100px 10px 40px;
    border-radius: 10px;
    font-size: 0.75rem;
    background: #ccc;
    border: 1px solid #ccc;
    font-size: 15px;
    color: black;
    font-weight: 400;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
  }

  .mp--l {
    // span {
    //   color: rgba($pColor, 0.65);
    //   cursor: pointer;

    //   //font-size: 1.25rem;
    //   &.active {
    //     color: #25a18e;
    //   }
    // }

    .small_btn {
      background: var(--main-blue-color);

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: #cccccc !important;
    color: #393939;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #dbdbdb;
    box-shadow: 0;
    border-radius: 10px;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #e6e6e6;
          background: var(--main-blue-color);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          color: var(--main-blue-color);
          font-weight: 400;
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  height: 100;
  display: block;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #e6e6e6;
    color: #6e6e6e;
    box-shadow: 0 0 0 0 rgba(136, 152, 170, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 200px !important;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      border-bottom: 1px solid var(--main-blue-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          gap: 5px;
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      gap: 50px;
      padding: 0 10px 0 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.modal-body {
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #e6e6e6;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}
.el-icon-setting {
  margin-left: 10px;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}

.sessionEvent-detail-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }

  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}

.showCoveringtests-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}

.swal2-popup #swal2-title {
  color: var(--main-blue-color) !important;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block !important;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}

.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;

  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}

.ultra--ipt_ipt {
  width: 100%;
}

.ultra--ipt {
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}

.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}

.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}

.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}

.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}
.big-rrweb .ticks .tick-hover-box .tick-marker {
  width: 3px;
}
.big-rrweb .ticks .tick-hover-box .tick-thumb {
  width: 9px;
  height: 9px;
  right: -1px;
}
.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}

.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}

.container--event--liste {
  margin: 0 0 0 30px;
  padding: 8px;
  width: 100%;
  border: solid 1px #c6c6c6;
  border-radius: 5px;
  max-height: 656px;
  overflow: hidden;
}

.event--liste {
  max-height: 580px;
  overflow-x: auto;
  overflow-y: auto;
}

.event--liste::-webkit-scrollbar {
  display: none;
}

.content {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;

  .time--tag {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 1rem !important;
    width: 96%;
  }

  .right--content {
    width: 80%;
    flex-direction: column;
  }

  .icon--event {
    width: 20%;
    display: flex;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.content:hover {
  background-color: white;

  .icon--event {
    background-color: white;

    i {
      color: #ffff;
    }
  }
}

.active--event {
  background-color: #e8eeff;

  .icon--event {
    background-color: $color;

    i {
      color: #ffff;
    }
  }
}

.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px var(--main-blue-color) !important;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    border-color: white !important;

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}

.mod--content {
  margin-bottom: 30px;
}
</style>
