<template>
  <div>
    <div
      class="mid--page"
      :style="{ 'flex-direction': selectorDetails ? 'column' : '' }"
      style="border-bottom: none; padding: 20px 0 10px 0"
    >
      <div class="mp--l adpt--container" style="width: 100%">
        <template v-if="buttons">
          <div class="adpt--menu">
            <el-button
              class="tab--btn adpt--btn"
              :class="{ active: runStepDetails }"
              @click="toggleContent('showRunStepDetails')"
              id="viewEditSteps"
            >
              Simple
            </el-button>
            <el-button
              class="tab--btn adpt--btn"
              :class="{ active: selectorDetails }"
              @click="toggleContent('showSelectorDetails')"
              id="btnAdvanced"
            >
              Advanced
            </el-button>
            <el-button
              v-if="
                this.items.allSuggestResults !== undefined &&
                this.items.allSuggestResults !== null
              "
              icon="ni-column-horizontal"
              class="tab--btn secondary adpt--btn"
              :class="{ active: allSuggest }"
              @click="toggleContent('showAllSuggest')"
            >
              All Selectors
            </el-button>
            <el-button
              v-show="showApiCall"
              icon="el-icon-sort"
              class="tab--btn adpt--btn"
              :class="{ active: apiCall }"
              @click="toggleContent('showApiCall')"
            >
              Api call
            </el-button>
          </div>
        </template>
        <slot> </slot>
      </div>
      <div
        v-if="selectorDetails"
        class="mp--r adpt--page-details"
        :style="{ 'margin-bottom': selectorDetails ? '10px' : '' }"
        style="width: 100%; justify-content: right; margin-top: 12px"
      >
        <template v-if="selectorDetails">
          <span style="white-space: nowrap; margin: 0 10px 0 10px"
            >Steps : {{ total }}</span
          >
          <span>Show</span>
          <el-select
            style="width: 60px"
            v-model="pagination.perPage"
            @change="savePagination"
            placeholder="Per page"
            class="small--select"
          >
            <el-option
              v-for="(item, id) in pagination.perPageOptions"
              :key="id"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else-if="allSuggest">
          <span style="white-space: nowrap; margin: 0 10px 0 10px"
            >Steps : {{ totalAll }}</span
          >
          <span>Show</span>
          <el-select
            v-model="paginationAll.perPage"
            @change="savePaginationAll"
            placeholder="Per page"
            class="small--select"
          >
            <el-option
              v-for="(item, id) in paginationAll.perPageOptions"
              :key="id"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else-if="apiCall">
          <span style="white-space: nowrap; margin: 0 10px 0 10px"
            >Steps : {{ totalApi }}</span
          >
          <span>Show</span>
          <el-select
            v-model="limitCallApi"
            @change="apiSession"
            placeholder="Per page"
            class="small--select"
          >
            <el-option
              v-for="(item, id) in paginationApi.perPageOptions"
              :key="id"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </template>
      </div>
    </div>
    <template v-if="apiCall">
      <div class="mb-4" style="display: flex">
        <div v-loading="loadingSession" style="width: 50%">
          <canvas id="myChart"></canvas>
        </div>

        <div class="ctb--body mb-3" style="width: 50%" v-if="loadingSession">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div
          v-else-if="apiDetail.apicallStatistics"
          class="ctb--body ml-4 mb-3"
          style="width: 50%"
        >
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Total api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-gem"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    apiDetail.apicallStatistics.total_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green"
                    >100%
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-up"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                        <path d="M17 6h6v6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Successful api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-rocket"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    apiDetail.apicallStatistics.successful_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green"
                    >{{
                      pc(
                        apiDetail.apicallStatistics.successful_calls,
                        apiDetail.apicallStatistics.total_calls
                      )
                    }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-up"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                        <path d="M17 6h6v6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Failed api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-medkit"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    apiDetail.apicallStatistics.failed_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green faild"
                    >{{
                      pc(
                        apiDetail.apicallStatistics.failed_calls,
                        apiDetail.apicallStatistics.total_calls
                      )
                    }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-down"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 18l-9.5-9.5l-5 5L1 6"></path>
                        <path d="M17 18h6v-6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="ctb--body ml-4 mb-3" style="width: 50%">
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 100% !important">
            <div class="ctbb--inner">No item found</div>
          </div>
        </div>
      </div>
      <div class="ctb--body mb-3" v-if="loadingSession">
        <div class="ctbb--row mb-2">
          <img
            src="/img/loading.gif"
            alt="loading..."
            style="height: 64px; width: 64px"
          />
        </div>
      </div>
      <div v-else class="mb-4">
        <data-list
          :tHeader="Header"
          :loading="loadingSession"
          :items="toShowApi.apicalls"
          :customSizes="true"
          ref="dataList"
          id="tableDetailsTests"
        >
          <template #content>
            <div v-if="toShowApi.apicalls">
              <div
                v-for="(row, index) in toShowApi.apicalls"
                :key="index"
                class="tblb--row mb-2"
                id="detailsTblbRow"
              >
                <el-card class="trsl--elt" shadow="hover">
                  <el-row
                    type="flex"
                    justify="space-between"
                    class="el--flex drag-only-row"
                  >
                    <el-col
                      style="width: 5%; text-align: start"
                      class="trsl--txt"
                    >
                      <span class="test-run-name">
                        {{ index + 1 }}
                      </span>
                    </el-col>
                    <el-col
                      style="width: 45%; text-align: start"
                      class="trsl--txt test-run-name-callApi"
                    >
                      <el-tooltip
                        class="item elt-row"
                        effect="light"
                        :content="row.url"
                        placement="top"
                        style="font-size: 0.8em"
                      >
                        <span class="elt-row">{{ row.url }}</span>
                      </el-tooltip>
                    </el-col>
                    <el-col
                      style="width: 15%; text-align: start"
                      class="trsl--txt"
                    >
                      <el-alert
                        class="no--close elt--banner_overlay_status tags-call-api"
                        show-icon
                        :closable="false"
                        :title="`${row.status_code}`"
                        :type="row.status_code < 299 ? 'success' : 'error'"
                      ></el-alert>
                    </el-col>
                    <el-col
                      class="trsl--txt"
                      style="text-align: start; width: 15%"
                    >
                      <span class="elt--txt">
                        {{ formatDate(row.created_at) }}
                      </span>
                    </el-col>
                    <el-col
                      style="width: 20%; text-align: start"
                      class="trsl--txt"
                    >
                      <el-tooltip content="Edit api" placement="top">
                        <el-button
                          type="info"
                          icon="el-icon-edit"
                          @click.stop="proccessEditApi(row, index)"
                          id="proccesEditApi"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="Detail api" placement="top">
                        <el-button
                          type="success"
                          icon="el-icon-view"
                          @click.stop="openEditAccordion(index)"
                          id="btnEditAccordion"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="reRun api" placement="top">
                        <el-button
                          type="primary"
                          icon="el-icon-refresh"
                          @click.stop="openReRunApi(row, index)"
                          id="btnReRunApi"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="Delete api" placement="top">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="deleteApiCall(row)"
                          id="btnDeleteApiCall"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <div
                    class="edit-accordion mt-5"
                    v-show="index === selectedItemId"
                  >
                    <vue-json-pretty
                      :data="row"
                      :showSelectController="true"
                      :showIcon="true"
                      :showLineNumber="true"
                    />
                  </div>
                </el-card>
              </div>
            </div>
            <el-card v-else class="trsl--elt" shadow="hover">
              <el-row
                type="flex"
                justify="space-between"
                class="el--flex drag-only-row"
                :gutter="24"
              >
                <el-col :span="24" class="trsl--txt">
                  <span> No items found </span>
                </el-col>
              </el-row>
            </el-card>
          </template>
        </data-list>
      </div>
      <el-button-group>
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          :disabled="apiDetail.currentPage === 1"
          @click.prevent="handleCurrentChangeCallApi('Previous')"
        >
          Previous
        </el-button>
        <el-button
          type="primary"
          @click.prevent="handleCurrentChangeApi('next')"
          :disabled="apiDetail.currentPage === apiDetail.totalPages"
        >
          Next
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </el-button-group>
    </template>
    <data-list
      style="overflow-y: scroll; height: 55vh"
      v-if="selectorDetails"
      :loading="loading"
      :items="toShow"
      :customSizes="true"
    >
      <template #content>
        <div
          style="
            display: grid;
            grid-template-columns: 100px 1fr 1fr 1fr 1fr 100px;
            text-align: center;
          "
          class="custom-header-rows"
        >
          <div class="elt-row max-width">
            <h3>STEP</h3>
          </div>
          <div class="elt-row max-width">
            <h3>FIX</h3>
          </div>
          <div class="elt-row max-width">
            <h3>PAGE URL</h3>
          </div>
          <div class="elt-row max-width">
            <h3>ORIGINAL PATH</h3>
          </div>
          <div
            class="elt-row max-width"
            style="white-space: nowrap; padding-left: 0 !important"
          >
            <h3>AI SUGGESTS</h3>
          </div>
          <div class="elt-row max-width"></div>
        </div>
        <div v-for="elt in toShow" :key="elt.id" class="tblb--row mb-2">
          <div
            class="tblb--inner"
            style="
              display: grid;
              grid-template-columns: 100px 1fr 1fr 1fr 1fr 100px;
              text-align: center;
            "
          >
            <div class="elt-row max-width">
              <span class="test-run-name">{{ elt.$id + 1 }}</span>
            </div>
            <div class="elt-row max-width">
              <el-tooltip
                class="item"
                :content="TestGoldStatus(elt)"
                placement="top"
              >
                <span class="test-time-cont" style="max-width: 100%">
                  {{ TestGoldStatus(elt) }}
                </span>
              </el-tooltip>
            </div>
            <div class="elt-row max-width">
              <span>{{ elt.pageUrl }}</span>
            </div>
            <div
              class="elt-row max-width"
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 100px;
              "
            >
              <span>{{ elt.originalSelector }}</span>
            </div>
            <div class="elt-row max-width">
              <span>{{ TestGoldPath(elt) }}</span>
            </div>
            <div
              class="elt-row max-width"
              style="margin-right: 10px; padding: 0 !important"
            >
              <el-button
                style="padding: 4px 6px; border-radius: 10px"
                @click="
                  elt.healedSelector
                    ? setHealModal(elt)
                    : setNormalDetailModal(elt)
                "
                type="success"
                plain
                round
                size="small"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 12L12 16.5M12 8.66455V8.625M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    stroke="#1F213E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </data-list>

    <data-list
      v-else-if="allSuggest"
      :tHeader="tHeader2"
      :loading="loadingAll"
      :items="toShowAll"
      :customSizes="true"
    >
      <template #content>
        <div
          class="px-3 py-2 mb-4 mt-2 rounded border border-solid border-warning"
          style="background: #fb634033"
          v-if="finalList.length < items.allSuggestCount"
        >
          <i class="el-icon-warning text-warning"></i>
          <h4 class="text-warning text-center">
            Your current tier only lets you access
            <b>{{ finalList.length }}</b> out of the
            <b>{{ items.allSuggestCount }}</b> selectors found. To see all,
            please
            <a href="mailto: support@aichemy.dev" class="text-primary underline"
              >upgrade</a
            >
            your account.
          </h4>
        </div>
        <div v-for="elt in toShowAll" :key="elt.id" class="tblb--row mb-2">
          <div class="tblb--inner">
            <div class="elt-row" style="width: 5%">
              <span class="test-run-name">{{ elt.$id + 1 }}</span>
            </div>
            <div class="elt-row" style="width: 20%">
              <span>{{ elt.url }}</span>
            </div>
            <div class="elt-row" style="width: 30%">
              <span>{{ elt.absXpath }}</span>
            </div>
            <div class="elt-row" style="width: 30%">
              <ul>
                <li v-for="s in elt.sugXpath" :key="s.id">{{ s }}</li>
              </ul>
            </div>
            <div class="elt-row" style="width: 15%">
              <el-button
                @click="setDetailModal(elt)"
                icon="el-icon-info"
                type="success"
                plain
                round
                size="small"
              >
                {{ $t("suggestions.see_more") }}
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </data-list>

    <test-run-steps
      v-if="runStepDetails"
      ref="testRunSteps"
      :items="items"
    ></test-run-steps>

    <el-pagination
      v-if="selectorDetails"
      background
      layout="prev, pager, next"
      class="pagination-no-border"
      :total="total"
      :page-size="pagination.perPage"
      :page-sizes="pagination.perPageOptions"
      :current-page.sync="pagination.currentPage"
      @current-change="handleCurrentChange"
    />
    <el-pagination
      v-else-if="allSuggest"
      background
      layout="prev, pager, next"
      class="pagination-no-border"
      :total="totalAll"
      :page-size="paginationAll.perPage"
      :page-sizes="paginationAll.perPageOptions"
      :current-page.sync="paginationAll.currentPage"
      @current-change="handleCurrentChangeAll"
    />

    <template>
      <div class="details-modal-container">
        <modal class="small-modal" :show.sync="showNormalDetailModal">
          <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("suggestions.more_details") }}
            </h5>
          </template>
          <div>
            <div class="details-modal">
              <div style="padding-left: 0" class="col">
                <el-tooltip
                  placement="top"
                  :content="originalPathForMoreDetailsModal"
                  v-if="originalPathForMoreDetailsModal"
                >
                  <div v-if="originalPathForMoreDetailsModal">
                    <h5 class="modal-small-title">
                      {{ $t("suggestions.original_path") }}
                    </h5>
                    <h3 style="word-break: break-word; font-weight: 600">
                      {{ originalPathForMoreDetailsModal }}
                    </h3>
                  </div>
                </el-tooltip>
                <br />

                <h5 class="modal-small-title">
                  {{ $t("suggestions.suggested_selectors") }}
                </h5>
                <div class="container-cont-sugg">
                  <div
                    class="container-suggestion"
                    v-for="data in this.normalsuggest"
                    :key="data"
                  >
                    {{ data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>

      <div class="heal-row-modal-container">
        <modal :show.sync="showHealRowModal">
          <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel">More Details</h5>
          </template>
          <div>
            <div class="details-modal">
              <div class="col">
                <div v-if="loading" class="mt-5 text-center">
                  <img
                    src="../../../assets/images/loader.gif"
                    alt="Loading Animation"
                    style="width: 100px; height: 100px"
                  />
                </div>
                <div v-else class="mt-5">
                  <template v-if="annotatedScreenshot.isMultiple">
                    <div class="mb-2">
                      <img
                        :src="annotatedScreenshot.picture[0]"
                        alt="Preview Image"
                        class="p--img"
                      />
                    </div>
                    <div>
                      <img
                        :src="annotatedScreenshot.picture[1]"
                        alt="Preview Image"
                        class="p--img"
                      />
                    </div>
                  </template>
                  <img
                    v-else
                    :src="annotatedScreenshot.picture"
                    alt="Preview Image"
                    style="width: 100%; height: 500px"
                  />
                </div>
                <br />
                <br />

                <div class="d-flex">
                  <h3>Healed Selector:</h3>
                  <span> &nbsp; {{ healedSelectorForHealRowModal }}</span>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>

      <div class="details-modal-container">
        <modal :show.sync="showDetailModal">
          <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel">More Details</h5>
          </template>
          <div>
            <div class="details-modal">
              <div class="col">
                <div class="mt--5">
                  <img
                    :src="`data:image/png;base64,
                          ${this.screenshot}`"
                    style="width: 100%; height: auto"
                  />
                </div>
                <div class="mt-4">
                  <h5>Original Xpath:</h5>
                  <ul id="ogData">
                    <li>
                      {{ this.originalXpath }}
                    </li>
                  </ul>
                </div>

                <h5>Suggested Xpaths:</h5>
                <ul id="moreSuggestData">
                  <li v-for="data in this.moreSuggestData" :key="data">
                    {{ data }}
                  </li>
                </ul>
                <h5>Element Description:</h5>
                <ul id="descData">
                  <li>
                    <small>{{ this.elemDesc }} </small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </modal>
      </div>
      <div class="add-test-project-modal">
        <modal :show.sync="showModalReRun" v-loading="loadingRequest">
          <template slot="header">
            <h5 class="modal-title" id="addTestModal">
              reRun calls api {{ showReRunIndex }}
            </h5>
          </template>
          <div class="body--modale">
            <div class="modal-add-containt">
              <div class="add-test-project-modal">
                <div class="run-name">
                  <label class="model-label" for="url"> Url </label>
                  <input
                    type="text"
                    id="url"
                    name="url"
                    placeholder="Url"
                    v-model="reRunUrl"
                  />
                  <label class="model-label" for="request_method">
                    request method
                  </label>
                  <el-select
                    id="request_method"
                    v-model="methodReRun"
                    collapse-tags
                    laceholder="request method"
                  >
                    <el-option
                      v-for="(item, id) in allMethodReRun"
                      :key="id"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <el-divider></el-divider>
                  <div class="label-request">
                    <label
                      class="model-label"
                      for="requestHeaders"
                      style="font-size: 1em"
                    >
                      Request headers
                    </label>
                    <el-tooltip
                      content="Add new column (request headers)"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="addNewColumnRequestHeaders"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <div class="label-request">
                    <label class="model-label" for="requestHeaders">
                      Name
                    </label>
                    <label class="model-label" for="requestHeaders">
                      Value
                    </label>
                  </div>
                  <div
                    v-for="(elt, id) in requestHeaders"
                    :key="`${id}headers`"
                    class="label-request mt-2"
                  >
                    <input
                      class="mr-2"
                      type="text"
                      :id="elt.name"
                      :name="elt.name"
                      placeholder="column request headers name"
                      v-model="elt.name"
                    />
                    <input
                      class="ml-2"
                      type="text"
                      :id="elt.value"
                      :name="elt.value"
                      placeholder="column request headers value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestHeaders(id)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <el-divider></el-divider>
                  <div class="label-request">
                    <label
                      class="model-label"
                      for="requestHeaders"
                      style="font-size: 1em"
                    >
                      Request body
                    </label>
                    <el-tooltip
                      content="Add new column (request body)"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="addNewColumnRequestBody"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <div class="label-request">
                    <label
                      class="model-label"
                      for="requestHeaders"
                      style="width: 32%; text-align: left"
                    >
                      Name
                    </label>
                    <label
                      class="model-label"
                      for="requestHeaders"
                      style="width: 35%; text-align: left"
                    >
                      Value
                    </label>
                  </div>
                  <div
                    v-for="(elt, id) in requestBody"
                    :key="`${id}body`"
                    style="width: 100%"
                  >
                    <div class="label-request mt-2">
                      <input
                        class="mr-2"
                        type="text"
                        placeholder="column request body name"
                        v-model="elt.name"
                      />
                      <el-select
                        :id="`body-type${id}`"
                        v-model="elt.type"
                        collapse-tags
                        laceholder="request method"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, id) in bodyType"
                          :key="id"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                      <input
                        v-if="elt.type === 'text' || elt.type === 'number'"
                        class="ml-2"
                        :type="elt.type"
                        placeholder="column request body value"
                        v-model="elt.value"
                      />
                      <el-tooltip
                        class="ml-2"
                        content="Delete column"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          icon="el-icon-delete-solid"
                          size="mini"
                          @click="deleteColumnRequestBody(id)"
                        ></el-button>
                      </el-tooltip>
                    </div>
                    <div
                      v-if="elt.type === 'array' || elt.type === 'object'"
                      class="mt-2 request-object px-3 py-2"
                    >
                      {{ `${elt.type === "array" ? "[" : "{"}` }}
                      <textarea
                        class="request-object py-0 px-4 m-0"
                        v-model="elt.value"
                        style="border: none"
                        rows="8"
                      >
                      </textarea>
                      {{ `${elt.type === "array" ? "]" : "}"}` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template slot="footer">
            <div class="add-test-footer-btn">
              <base-button type="secondary" @click="showModalReRun = false">
                {{ $t("list_item_page.close") }}
              </base-button>
              <span>
                <base-button type="primary" @click="reRun">reRun</base-button>
              </span>
            </div>
          </template>
        </modal>
      </div>
      <div class="response-request-modal">
        <modal :show.sync="responseRequestModal">
          <template slot="header">
            <h5 class="modal-title" id="addTestModal">
              reRun calls api response
            </h5>
          </template>
          <div class="ctb--body mb-3" style="width: 100%" v-if="loadingRequest">
            <div class="ctbb--row mb-2">
              <img
                src="/img/loading.gif"
                alt="loading..."
                style="height: 64px; width: 64px"
              />
            </div>
          </div>
          <div v-else class="body--modale mb-4">
            <vue-json-pretty
              :data="responseRequest"
              :showSelectController="true"
              :showIcon="true"
              :showLineNumber="true"
            />
          </div>
        </modal>
      </div>
    </template>
  </div>
</template>

<script>
import { Select, Option, Table, TableColumn } from "element-ui";
import DataList from "@/components/Custom/DataList.vue";
import TestRunSteps from "@/views/Examples/ItemManagement/testRunSteps.vue";
import axios from "axios";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  name: "Suggestions",
  components: {
    TestRunSteps,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    DataList,
    VueJsonPretty,
  },
  props: {
    items: {
      type: Object,
      default: Object.assign({}),
    },
  },

  data() {
    return {
      Header: [
        {
          label: "",
          key: "_id",
          width: "5%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Url".toUpperCase(),
          key: "",
          width: "45%",
          sortable: false,
        },
        {
          label: "Status code".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "Started".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "20%", sortable: false },
        { selectorDetails: true, total: 0 },
      ],
      loading: false,
      loadingAll: false,
      annotatedScreenshot: "",
      healedSelectorForHealRowModal: "",
      showHealRowModal: false,
      originalPathForMoreDetailsModal: "",
      shortPathForMoreDetailsModal: "",
      log: console.log,
      data_dict: {},
      allSuggest: false,
      selectorDetails: false,
      runStepDetails: true,
      buttons: true,
      showDetailModal: false,
      showNormalDetailModal: false,
      moreSuggestData: [],
      originalXpath: "",
      screenshot: "",
      elemDesc: "",
      normalsuggest: [],
      finalList: [],
      suggestionsItems: [],
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      totalFAS: 0,
      // filteredTotal: [],
      filteredResults: [],
      filteredResultsFAS: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationAll: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      toShow: [],
      toShowAll: [],
      to: 0,
      from: 0,
      toAll: 0,
      fromAll: 0,
      tHeader: [
        {
          label: this.$t("suggestions.step").toUpperCase(),
          key: "_id",
          width: "10%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("suggestions.fix").toUpperCase(),
          key: "",
          width: "10%",
          sortable: false,
        },
        {
          label: this.$t("suggestions.page_url").toUpperCase(),
          key: "",
          width: "25%",
          sortable: false,
        },
        {
          label: this.$t("suggestions.original_path").toUpperCase(),
          key: "",
          width: "25%",
          sortable: false,
        },
        {
          label: this.$t("suggestions.quikly_suggest").toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        {
          label: this.$t("suggestions.details").toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
      ],
      tHeader2: [
        {
          label: this.$t("suggestions.step").toUpperCase(),
          key: "_id",
          width: "5%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("suggestions.page_url").toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        {
          label: "Original path".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        {
          label: "Wringr path",
          key: "",
          width: "30%",
          sortable: false,
        },
        {
          label: this.$t("suggestions.details").toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
      ],
      apiCall: false,
      apiDetail: [],
      loadingSession: true,
      loadingRequest: false,
      responseRequest: [],
      responseRequestModal: false,
      selectedItemId: null,
      paginationApi: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      // computed:{
      //   filteredData(){
      //     const startIndex = (this.pagination.currentPage -1)* this.pagination.perPage;
      //     const endIndex = startIndex + this.pagination.perPage;
      //     return this.toShow.slice(startIndex,endIndex);
      //   }

      // },
      toApi: 0,
      totalApi: 0,
      fromApi: 0,
      toShowApi: [],
      callApiCurrentPage: 1,
      limitCallApi: 10,
      showModalReRun: false,
      showReRunId: "",
      showReRunIndex: 0,
      reRunUrl: "",
      allMethodReRun: [
        "",
        "get",
        "post",
        "delete",
        "put",
        "connect",
        "options",
        "head",
        "patch",
        "trace",
      ],
      methodReRun: "",
      requestHeaders: [{ name: "Content-Type", value: "" }],
      requestBody: [{ name: "", type: "text", value: "" }],
      bodyType: ["text", "array", "object", "number"],
      showApiCall: false,
    };
  },
  created() {
    this.pagination.perPage =
      sessionStorage.getItem("suggestionPageNbr") !== null
        ? parseInt(sessionStorage.getItem("suggestionPageNbr"))
        : 10;
    console.log("created items", this.items);
    this.getSuggestions();
    this.getAllSuggestions();

    if (this.apiDetail.length < 1 || this.apiDetail.length == 0) {
      this.apiSession();
    } else {
      this.showApiCall = true;
    }
  },

  methods: {
    showApiCalls(id) {
      this.$router.push({
        name: "Api call Session",
        params: {
          id: this.$route.params.id,
          name: this.projectDetail.name,
          session_id: id,
        },
      });
    },
    forStepNumberRun(val) {
      return (
        this.toShowSessionCallApi.currentPage *
          this.toShowSessionCallApi.perPageCount -
        this.toShowSessionCallApi.perPageCount +
        (val + 1)
      );
    },
    handleCurrentChange(num) {
      this.from = (num - 1) * this.pagination.perPage;
      this.to = parseInt(this.from) + parseInt(this.pagination.perPage);
      this.toShow = [...this.filteredResults].filter((item, val) => {
        return this.from <= val && val < this.to;
      });
    },
    handleSizeChange(val) {
      this.pagination.perPage = val;
      this.handleCurrentChange(this.pagination.currentPage);
    },
    handleCurrentChangeAll(val) {
      this.fromAll = (val - 1) * this.paginationAll.perPage;
      this.toAll =
        parseInt(this.fromAll) + parseInt(this.paginationAll.perPage);
      this.toShowAll = [...this.finalList].slice(this.fromAll, this.toAll);
    },
    handleSizeChangeAll(val) {
      this.paginationAll.perPage = val;
      this.handleCurrentChangeAll(this.paginationAll.currentPage);
    },
    savePagination() {
      sessionStorage.setItem("suggestionPageNbr", this.pagination.perPage);
      this.handleSizeChange(this.pagination.perPage);
    },
    savePaginationAll() {
      sessionStorage.setItem(
        "suggestionAllPageNbr",
        this.paginationAll.perPage
      );
      this.handleSizeChangeAll(this.paginationAll.perPage);
    },
    setNormalDetailModal(row) {
      this.showNormalDetailModal = true;
      this.normalsuggest = [];
      let output;
      if (row.healedSelector) {
        output = row.healedSelector;
      } else {
        if (row.suggestedFixes.candidateSelectors) {
          output = row.suggestedFixes.candidateSelectors;
          this.normalsuggest = this.normalsuggest.concat(output);
          let isNormalSize = row.originalSelector;
          if (isNormalSize.length > 70) {
            let tempArray = isNormalSize.split("").slice(0, 71).join("");
            tempArray += "...";
            this.shortPathForMoreDetailsModal = tempArray;
            this.originalPathForMoreDetailsModal = row.originalSelector;
          } else {
            this.originalPathForMoreDetailsModal = row.originalSelector;
          }
        } else {
          output = "";
        }
      }
    },
    setDetailModal(row) {
      this.showDetailModal = true;
      this.originalXpath = "";
      this.moreSuggestData = [];
      this.screenshot = row.img;
      this.originalXpath = row.absXpath;
      this.elemDesc = row.desc;
      this.moreSuggestData = this.moreSuggestData.concat(row.sugXpath);
    },
    toggleContent(para) {
      let buttons = document.querySelector(".container--buttons");
      if (para === "showSelectorDetails") {
        this.selectorDetails = true;
        buttons.style.right = "16rem";
        this.allSuggest = false;
        this.apiCall = false;
        this.runStepDetails = false;
      } else if (para === "showRunStepDetails") {
        buttons.style.right = "10px";
        this.selectorDetails = false;
        this.allSuggest = false;
        this.apiCall = false;
        this.runStepDetails = true;
      } else if (para === "showApiCall") {
        this.selectorDetails = false;
        this.allSuggest = false;
        this.runStepDetails = false;
        this.apiCall = true;
      } else {
        this.selectorDetails = false;
        this.allSuggest = true;
        this.runStepDetails = false;
        this.apiCall = false;
      }
    },
    getSuggestions() {
      const xpathEncounters = Object.values(this.items.xpathEncounters);
      const selectorEncounters = Object.values(this.items.selectorEncounters);

      let items = xpathEncounters.concat(selectorEncounters);
      if (items) {
        this.data_dict["xpathEncounters"] = items;
        this.suggestionsItems = items.map((elt, id) => {
          return { ...elt, $id: id };
        });
        this.filteredResults = this.filteredResultSet;
        this.total = this.filteredResults.length;
        this.handleCurrentChange(this.pagination.currentPage);
        return items;
      } else return [];
    },
    getAllSuggestions() {
      if (this.items.allSuggestResults) {
        this.data_dict["allSuggestItems"] = {};
        let temp = Object.entries(this.items.allSuggestResults);
        var newList = [];
        var entry = {};
        for (const [key, value] of temp) {
          var newtemp = Object.values(value);
          for (let i = 0; i < value.allAbsXpaths.length; i++) {
            entry.absXpath = newtemp[0][i];
            entry.sugXpath = newtemp[1][i];
            entry.desc = newtemp[2][i];
            entry.img = newtemp[3][i];
            entry.url = key;
            newList.push(entry);
            entry = {};
          }
        }
        this.finalList = newList.map((elt, id) => {
          return { ...elt, $id: id };
        });
        this.totalAll = this.finalList.length;
        this.handleCurrentChangeAll(this.paginationAll.currentPage);
      }
    },
    forStepNumber(row) {
      return row + this.from + 1;
    },

    async setHealModal(row) {
      this.loading = true;
      this.showHealRowModal = true;
      this.healedSelectorForHealRowModal = row.healedSelector;
      const walserver_request_id = row.testRequestId;
      const sent_at = row.sentAt;
      const selector_id = row.selectorId;
      const processing_type = row.processingType;
      const selector_type = row.selectorType;
      const params = {
        sent_at: sent_at,
        selector_type: selector_type,
        id: selector_id,
        walserver_request_id: walserver_request_id,
        processing_type: processing_type,
      };
      await this.$store.dispatch("items/get_screenshot", params);
      this.annotatedScreenshot = this.$store.getters["items/screenshot"];
      this.annotatedScreenshot && (this.loading = false);
    },
    pc(t, v) {
      return `${(t * 100) / v}%`;
    },
    openEditAccordion(id) {
      this.selectedItemId = this.selectedItemId !== id ? id : null;
    },
    copy(text) {
      // navigator.clipboard.writeText(text).then(() => {
      //     this.$notify({
      //         type: "success",
      //         message: "Api calls Copied Successfully.",
      //     });
      // });
      try {
        let jsonString = JSON.stringify(text);

        const textarea = document.createElement("textarea");
        textarea.value = jsonString;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$notify({
          type: "success",
          message: "Api calls Copied Successfully.",
        });
      } catch (error) {}
    },
    handleCurrentChangeCallApi(val) {
      val === "next"
        ? (this.callApiCurrentPage = this.callApiCurrentPage + 1)
        : (this.callApiCurrentPage = this.callApiCurrentPage - 1);
      this.apiSession();
    },
    handleCurrentChangeApi(val) {
      this.fromApi = (val - 1) * this.paginationApi.perPage;
      this.toApi = parseInt(this.fromApi) + parseInt(this.pagination.perPage);
      this.toShowApi = [...this.apiDetail].filter((item, val) => {
        return this.fromApi <= val && val < this.toApi;
      });
    },
    async apiSession() {
      let testRequestId = this.items.requestId;
      this.loadingSession = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const params = new URLSearchParams();
      params.append("page", this.callApiCurrentPage);
      params.append("count", this.limitCallApi);
      let filter = `?page=${this.callApiCurrentPage}&count=${this.limitCallApi}`;
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/test/" +
            testRequestId +
            filter
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the test failed ",
              });
              this.apiDetail = [];
            } else if (response.data.status === "success") {
              if (response.data.response) {
                this.apiDetail = response.data.response;
                this.totalApi = this.apiDetail.length;
                this.showApiCall = true;
                this.handleCurrentChangeApi(this.paginationApi.currentPage);
                this.showGraph();
                this.loadingSession = false;
              } else {
                this.apiDetail = [];
                this.loadingSession = false;
                this.showApiCall = false;
              }
            } else if (response.data.status === "failure") {
              this.loadingSession = false;
              this.showApiCall = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingSession = false;
          this.showApiCall = false;
        });
      this.loadingSession = false;
    },
    async deleteApiCall(el) {
      const apiCallId = el.apiCallId;
      const confirmation = await swal.fire({
        title: "Api call '" + el.url + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.loadingSession = true;
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 300);
        };
        const resp = await axios.delete(
          process.env.VUE_APP_API_URL_PREFIX + `/apicalls/v1/call/${apiCallId}`
        );
        if (resp.data.status === "success") {
          this.$notify({
            type: "success",
            message: "api call delete successfully.",
          });
          this.loadingSession = false;
          this.apiSession();
        } else {
          this.loadingSession = false;
          this.$notify({
            type: "danger",
            message: `an error has occurred! (${resp.data.message})`,
          });
        }
      }
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    showGraph() {
      let label = [];
      let data = [];
      if (this.apiDetail.apicallStatistics.resptime_average) {
        label.push("resptime_average");
        data.push(this.apiDetail.apicallStatistics.resptime_average.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_max) {
        label.push("resptime_max");
        data.push(this.apiDetail.apicallStatistics.resptime_max.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_min) {
        label.push("resptime_min");
        data.push(this.apiDetail.apicallStatistics.resptime_min.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_p50) {
        label.push("resptime_p50");
        data.push(this.apiDetail.apicallStatistics.resptime_p50.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_p95) {
        label.push("resptime_p95");
        data.push(this.apiDetail.apicallStatistics.resptime_p95.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_p99) {
        label.push("resptime_p99");
        data.push(this.apiDetail.apicallStatistics.resptime_p99.toFixed(2));
      }
      if (this.apiDetail.apicallStatistics.resptime_stddev) {
        label.push("resptime_stddev");
        data.push(this.apiDetail.apicallStatistics.resptime_stddev.toFixed(2));
      }
      var ctx = document.getElementById("myChart").getContext("2d");
      ctx.innerHTML = "";
      var myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              label: "Request time",
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(95, 114, 228, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(95, 114, 228, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Request time Statistics (Time milliseconds)",
            fontSize: 17, // Change this value to modify the font size
          },
          tooltips: {
            titleFontSize: 15, // Change this value to modify the title font size
            bodyFontSize: 15, // Change this value to modify the body font size
            callbacks: {
              label: function (tooltipItem, data) {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                if (data.labels[tooltipItem.index] === "resptime_average")
                  return ` The average response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_max")
                  return ` The shortest response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_min")
                  return ` The longest response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p50")
                  return ` The median response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p95")
                  return ` The 95th percentile response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p99")
                  return ` The 99th percentile response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_stddev")
                  return ` The standard deviation of the response time of all API calls in this session in milliseconds : ${value}`;
              },
            },
          },
          legend: {
            display: false,
            labels: {
              fontSize: 24, // Change this value to modify the font size
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 15, // Change this value to modify the font size
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 12, // Change this value to modify the font size
                },
              },
            ],
          },
        },
      });
    },
    async openReRunApi(el, index) {
      const confirmation = await swal.fire({
        title: "Modify request ?",
        text: "You had the option of modifying the request (method, url, request header, request body).",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Modify and run!",
        cancelButtonText: "Run as is ",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
      });
      if (confirmation.value === true) {
        this.showModalReRun = true;
        this.showReRunId = el.apicall_uid;
        this.showReRunIndex = index + 1;
        this.requestHeaders = [{ name: "Content-Type", value: "" }];
        this.responseRequest = [];
        this.responseRequestModal = false;
      } else {
        this.showReRunId = el.apicall_uid;
        this.showReRunIndex = index + 1;
        this.requestHeaders = [{ name: "Content-Type", value: "" }];
        this.responseRequest = [];
        this.responseRequestModal = true;
        this.reRun();
      }
    },
    addNewColumnRequestHeaders() {
      this.requestHeaders.push({ name: "", value: "" });
    },
    deleteColumnRequestHeaders(index) {
      this.requestHeaders.splice(index, 1);
    },
    addNewColumnRequestBody() {
      this.requestBody.push({ name: "", type: "text", value: "" });
    },
    deleteColumnRequestBody(index) {
      this.requestBody.splice(index, 1);
    },
    async reRun() {
      let apiCallId = this.showReRunId;
      this.loadingRequest = true;
      let data = {};
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (
        this.requestHeaders[0].value != "" &&
        this.requestHeaders[0].value.length > 0
      ) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          finalyHeader[item.name] = item.value;
        });
        data.requestHeaders = finalyHeader;
      }
      if (
        this.requestBody[0].value != "" &&
        this.requestBody[0].value.length > 0
      ) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array")
            finalyBody[item.name] = JSON.parse(`[${item.value}]`);
          if (item.type === "object")
            finalyBody[item.name] = JSON.parse(`{${item.value}}`);
        });
        data.requestBody = finalyBody;
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      let resp = null;
      if (data.length > 0) {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
      } else {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId
        );
      }
      if (resp.data.status === "success") {
        this.responseRequest = resp.data.response;
        this.loadingRequest = false;
        this.responseRequestModal = true;
        this.showModalReRun = false;
      } else if (resp.data.status === "failed") {
        if (resp.data.response) {
          this.responseRequest = resp.data.response;
          this.responseRequestModal = true;
          this.showModalReRun = false;
          this.loadingRequest = false;
        } else {
          this.responseRequest = [];
          this.loadingRequest = false;
        }
      } else {
        this.responseRequest = [];
        this.loadingRequest = false;
      }
    },
  },
  computed: {
    TestGoldPath() {
      return (row) => {
        let output;
        if (row.healedSelector) {
          output = row.healedSelector;
        } else {
          if (row.suggestedFixes.candidateSelectors) {
            output = row.suggestedFixes.candidateSelectors[0];
          } else {
            output = "";
          }
        }
        return output;
      };
    },
    TestGoldStatus() {
      return (row) => {
        let output;
        if (row.processingStatus === "success") {
          if (row.healedSelector) {
            output = "Heal";
          } else {
            if (row.processingType === "xpath-suggest") {
              output = "Heal";
            } else {
              output = this.$t("suggestions.improvement");
            }
          }
        } else {
          output = row.processingStatus;
        }
        return output;
      };
    },
    AllSuggestSx() {
      return (row) => {
        if (row.sugXpath) {
          return row.sugXpath[0];
        }
      };
    },
    filteredResultSet() {
      return this.suggestionsItems.filter((item) => {
        if (this.query) {
          const query = this.query.toLowerCase();
          if (
            item.originalSelector &&
            item.originalSelector.toLowerCase().includes(query)
          ) {
            return item;
          }

          if (item.pageUrl && item.pageUrl.toLowerCase().includes(query)) {
            return item;
          }

          if (this.TestGoldStatus(item)) {
            const testgoldStatus = this.TestGoldStatus(item).toLowerCase();
            if (testgoldStatus.includes(query)) {
              return item;
            }
          }

          if (this.TestGoldPath(item)) {
            const testgoldPath = this.TestGoldPath(item).toLowerCase();
            if (testgoldPath.includes(query)) {
              return item;
            }
          }
        } else {
          return item;
        }
      });
    },
    filteredList() {
      return this.filteredResults.slice(this.from, this.to);
    },
  },
};
</script>

<style scoped lang="scss">
.details-modal {
  max-height: 500px;
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 400px) {
  .custom--dropdown {
    margin-left: 0px !important;
  }
  .adpt--container {
    display: block;
    .adpt--menu {
      margin-bottom: 5px;
    }
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  border: 1px solid black;
  box-shadow: 10px 10px 4px #808080;
}
.custom--dropdown {
  margin-left: 26vw;
}
.container-cont-sugg {
  background-color: #d6d6d6;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}
.container-suggestion {
  font-weight: 600;
  width: fit-content;
  background-color: #d6d6d6;
  margin: 10px 0 10px 0;
  border-radius: 10px;
  color: var(--main-blue-color);
}
.table-header-gradient {
  background-image: linear-gradient(to right, #f56646, #fbb140) !important;
}
.heal-row-modal-container .details-modal {
  max-height: 650px;
}
.modal-small-title {
  color: var(--main-blue-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.modal-body {
  height: 60vh;
  overflow-y: scroll;
}
</style>
