import { render, staticRenderFns } from "./chatWring.vue?vue&type=template&id=4ade9186&scoped=true"
import script from "./chatWring.vue?vue&type=script&lang=js"
export * from "./chatWring.vue?vue&type=script&lang=js"
import style0 from "./chatWring.vue?vue&type=style&index=0&id=4ade9186&prod&lang=scss"
import style1 from "./chatWring.vue?vue&type=style&index=1&id=4ade9186&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ade9186",
  null
  
)

export default component.exports