<template>
  <!-- <div
    :class="isDarkMode ? `sidenav navbar-expand navbar-light bg-dark overflow-hidden ` : `sidenav navbar-expand navbar-light bg-white overflow-hidden`"
      
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
  >
  
    <div class="scrollbar-inner" ref="sidebarScrollArea"> -->
  <!-- <div class="sidenav-header d-flex align-items-center"> -->
  <!-- <a class="navbar-brand" href="#"> -->
  <!-- <img :src="logo" class="navbar-brand-img" alt="Sidebar logo" /> -->
  <!-- <span class="nav-nb--title">Wring</span>
        </a> -->
  <!-- <div class="ml-auto"> -->
  <!-- Sidenav toggler -->
  <!-- <div
            class="sidenav-toggler d-none d-xl-block"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div> -->
  <!-- </div> -->
  <!-- <slot></slot> -->
  <!-- <div class="navbar-inner">
        <a
          v-if="$sidebar.isMinimized"
          class="navbar-brand"
          href="#"
          style="
            display: block;
            width: 30px;
            padding: 0px !important;
            margin: 0px !important;
            transform: translateX(-4px);
            height: auto;
          "
        > -->

  <!-- <img :src="logo" class="navbar-brand-img" alt="Sidebar logo" /> -->
  <!-- </a> -->
  <div>
    <ul class="navbar-nav">
      <slot name="links">
        <sidebar-item
          v-for="(link, index) in sidebarLinks"
          :key="link.name + index"
          :link="link"
        >
          <sidebar-item
            v-for="(subLink, index) in link.children"
            :key="subLink.name + index"
            :link="subLink"
          >
          </sidebar-item>
        </sidebar-item>
      </slot>
    </ul>

    <slot name="links-after"></slot>
  </div>
  <!-- </div> -->
  <!-- </div>
  </div> -->
</template>
<script>
export default {
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  name: "sidebar",
  data() {
    return {
      log: console.log,
    };
  },
  props: {
    title: {
      type: String,
      default: "Wring",
      description: "Sidebar title",
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title",
    },
    logo: {
      type: String,
      default: "/favicon.png",
      description: "Sidebar app logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    toggleDarkMode() {
      this.$store.dispatch("toggleDarkMode");
    },
    smallerScreenResizeHandler(e) {
      this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
      this.minimizeSidebar();
    },
    minimizeSidebar() {
      // console.log("minimizeSidebar called");
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  created() {},
  mounted() {
    // window.addEventListener("resize", this.myEventHandler);
    window
      .matchMedia("(max-width: 1200px)")
      .addListener(this.smallerScreenResizeHandler);
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
  },
  beforeUnmount() {
    window.removeEventListener(
      "(max-width: 1200px)",
      this.smallerScreenResizeHandler
    );

    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style lang="scss">
.sidenav {
  // border-bottom: 1px solid black;
  // padding: .5rem ;
}
.navbar-inner {
  // overflow-x: hidden !important;
}
.navbar-nav {
  // align-items: baseline;
  // flex-direction: row !important;
  // justify-content: center;
}
.mxxafter {
  // display: flex;
  // align-items: center;
  // justify-content: center !important;
}
.small-logo img {
  // height: 40px;
}

.navbar-brand {
  // height: 60px;
  // padding: 15px !important;

  img {
    // width: 40px;
    // margin-right: 10px;
  }

  .nav-nb--title {
    // color: #1a237e;
    // font-weight: 700;
    // text-transform: uppercase;
    // font-size: 1rem;
  }
}
</style>
