<template>
  <div class="right-content mt-2 px-5">
    <div class="c--breadcrumb mb-3">
      <router-link to="/details/test-explorer/list-tests"
        ><i class="el-icon-house"></i
      ></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link to="/details/project-explorer/list-projects">
        Apps
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link
        :to="`/details/project-explorer/view-project/${$route.params.id}`"
      >
        {{ $route.params.name }}
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Api Calls</span>
    </div>
    <template>
      <div class="mb-6" style="display: flex">
        <div v-loading="loadingSession">
          <!-- <canvas  id="myChart" ></canvas> -->
        </div>

        <div class="ctb--body mb-3" style="width: 50%" v-if="loadingSession">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div
          v-else-if="sessionsDetail.apicallStatistics"
          class="ctb--body ml-4 mb-3"
          style="width: 50%; display: flex"
        >
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Total api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-gem"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    sessionsDetail.apicallStatistics.total_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green"
                    >100%
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-up"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                        <path d="M17 6h6v6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Successful api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-rocket"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    sessionsDetail.apicallStatistics.successful_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green"
                    >{{
                      pc(
                        sessionsDetail.apicallStatistics.successful_calls,
                        sessionsDetail.apicallStatistics.total_calls
                      )
                    }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-up"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                        <path d="M17 6h6v6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ctbb--row mb-2 py-3 px-3" style="width: 48% !important">
            <div class="ctbb--inner">
              <div class="dashboard-tile">
                <div class="tile-head is-flex-graph">
                  <h3 class="dark-inverted">Failed api calls</h3>
                  <div class="v-icon is-small is-primary is-rounded">
                    <i aria-hidden="true" class="fas fa-medkit"></i>
                  </div>
                </div>
                <div class="tile-body">
                  <span class="dark-inverted">{{
                    sessionsDetail.apicallStatistics.failed_calls
                  }}</span>
                </div>
                <div class="tile-foot">
                  <span class="text-h-green faild"
                    >{{
                      pc(
                        sessionsDetail.apicallStatistics.failed_calls,
                        sessionsDetail.apicallStatistics.total_calls
                      )
                    }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      data-icon="feather:trending-down"
                      class="iconify iconify--feather"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="m23 18l-9.5-9.5l-5 5L1 6"></path>
                        <path d="M17 18h6v-6"></path>
                      </g></svg></span
                  ><span style="font-size: 0.7em">For this session</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="ctb--body ml-4 mb-3" style="width:50%;">
                <div class="ctbb--row mb-2 py-3 px-3" style="width: 100% !important;" >
                    <div class="ctbb--inner">
                    No item found
                    </div>
                </div>
            </div> -->
      </div>
      <div class="mid--page pb-3 my-3" style="border: none">
        <div class="mp--l"></div>
        <div class="mp--r">
          <span class="pag--details mr-3"
            >{{
              sessionsDetail.perPageCount
                ? sessionsDetail.currentPage * sessionsDetail.perPageCount -
                  sessionsDetail.perPageCount +
                  1
                : 1
            }}
            {{ $t("list_item_page.to") }}
            {{
              sessionsDetail.currentPage
                ? sessionsDetail.currentPage * sessionsDetail.perPageCount
                : 10
            }}
            {{ $t("list_item_page.of") }}
            {{ sessionsDetail.totalCallCount || 0 }}
            {{ $t("list_item_page.entries") }}
          </span>
          <el-select
            v-model="limitCallApi"
            @change="detailsSession"
            placeholder="Per page"
            class="small--select"
          >
            <el-option
              v-for="(item, id) in paginationSession.perPageOptions"
              :key="id"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="ctb--body mb-3" v-if="loadingSession">
        <div class="ctbb--row mb-2">
          <img
            src="/img/loading.gif"
            alt="loading..."
            style="height: 64px; width: 64px"
          />
        </div>
      </div>
      <div v-else class="mb-4 api-call-content">
        <data-list
          :tHeader="Header"
          :loading="loadingSession"
          :items="sessionsDetail.apicalls"
          :customSizes="true"
          ref="dataList"
        >
          <template #content>
            <div v-if="sessionsDetail.apicalls">
              <div
                v-for="(row, index) in sessionsDetail.apicalls"
                :key="index"
                class="tblb--row mb-2"
              >
                <el-card class="trsl--elt" shadow="hover">
                  <div>
                    <el-row
                      type="flex"
                      justify="space-between"
                      class="el--flex drag-only-row"
                    >
                      <el-col
                        style="width: 2%; text-align: start"
                        class="trsl--txt"
                      >
                        <span class="test-run-name">
                          {{ index + 1 }}
                        </span>
                      </el-col>
                      <el-col
                        style="width: 45%; text-align: start"
                        class="trsl--txt test-run-name-callApi mx-2"
                      >
                        <el-tag
                          :key="row.method"
                          :type="getTagType(row.method)"
                          size="small"
                          class="api-call-tag"
                          effect="dark"
                        >
                          {{ row.method }}
                        </el-tag>
                        <el-tooltip
                          class="item elt-row"
                          effect="light"
                          :content="row.url"
                          placement="top"
                          style="font-size: 0.8em"
                        >
                          <span class="elt-row">{{ row.url }}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col
                        style="width: 13%; text-align: center"
                        class="trsl--txt"
                      >
                        <el-alert
                          class="no--close elt--banner_overlay_status tags-call-api ml-auto mr-auto"
                          show-icon
                          :closable="false"
                          :title="`${row.status_code}`"
                          :type="row.status_code < 299 ? 'success' : 'error'"
                        ></el-alert>
                      </el-col>
                      <el-col
                        class="elt-row"
                        style="text-align: center; width: 6%"
                      >
                        <span class="test-time-cont">
                          <i class="far fa-clock mr-1"></i>
                          {{ row.response_time }} ms
                        </span>
                      </el-col>
                      <el-col
                        style="width: 14%; text-align: center"
                        class="trsl--txt"
                      >
                        <el-alert
                          class="no--close tags-call-api ml-auto mr-auto"
                          :closable="false"
                          :title="
                            row.is_active === true ? 'active' : 'not activate'
                          "
                          :type="
                            row.is_active === true
                              ? 'success'
                              : row.is_active === false
                              ? 'error'
                              : 'danger'
                          "
                          show-icon
                        >
                        </el-alert>
                      </el-col>
                      <el-col
                        class="trsl--txt"
                        style="text-align: center; width: 15%"
                      >
                        <span class="elt--txt">
                          {{ formatDate(row.created_at) }}
                        </span>
                      </el-col>
                      <el-col
                        style="width: 5%; text-align: start"
                        class="trsl--txt"
                      >
                        <el-button
                          class="mr-3"
                          type="primary"
                          icon="el-icon-view"
                          circle
                          @click="openEditAccordion(index)"
                        ></el-button>
                        <el-dropdown trigger="click">
                          <el-button type="text">
                            <i class="fas fa-ellipsis-v mt-2"></i>
                          </el-button>
                          <el-dropdown-menu slot="dropdown">
                            <a
                              type="text"
                              @click.stop="openReRunApi(row, index)"
                              class="dropdown-item"
                              data-toggle="tooltip"
                              style="cursor: pointer"
                            >
                              <i class="el-icon-refresh"></i>
                              Rerun
                            </a>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="edit-accordion" v-show="index === selectedItemId">
                    <el-divider></el-divider>
                    <vue-json-pretty
                      :data="getJsonData(row)"
                      :showSelectController="true"
                      :showIcon="true"
                      :showLineNumber="true"
                    />
                    <div
                      class="py-2"
                      style="display: flex; justify-content: end"
                    >
                      <base-button
                        type="secondary"
                        @click="openEditAccordion(index)"
                      >
                        {{ $t("list_item_page.close") }}
                      </base-button>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
            <el-card v-else class="trsl--elt" shadow="hover">
              <el-row
                type="flex"
                justify="space-between"
                class="el--flex drag-only-row"
                :gutter="24"
              >
                <el-col :span="24" class="trsl--txt">
                  <span> No items found </span>
                </el-col>
              </el-row>
            </el-card>
          </template>
        </data-list>
      </div>
      <el-button-group>
        <el-button
          class="custom-pag-btn"
          type="primary"
          icon="el-icon-arrow-left"
          :disabled="sessionsDetail.currentPage === 1"
          @click="handleCurrentChangeCallApi('Previous')"
        >
          Previous
        </el-button>
        <el-button
          class="custom-pag-btn"
          type="primary"
          @click="handleCurrentChangeCallApi('next')"
          :disabled="sessionsDetail.currentPage === sessionsDetail.totalPages"
        >
          Next
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </el-button-group>
    </template>

    <div class="add-test-project-modal">
      <modal :show.sync="showModalReRun" v-loading="loadingRequest">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Re-run api</h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="url"> Url </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Url"
                  v-model="reRunUrl"
                />
                <div style="display: flex">
                  <div class="mr-2">
                    <label class="model-label" for="request_method">
                      request method
                    </label>
                    <el-select
                      id="request_method"
                      v-model="methodReRun"
                      collapse-tags
                      laceholder="request method"
                      style="height: auto"
                    >
                      <el-option
                        v-for="(item, id) in allMethodReRun"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="ml-2 mr-2">
                    <label class="model-label" for="connectionTimeout">
                      Connection timeout
                    </label>
                    <el-input-number
                      v-model="connectionTimeout"
                      :precision="1"
                      :step="0.1"
                    ></el-input-number>
                  </div>
                  <div class="ml-2">
                    <label class="model-label" for="requestTimeout">
                      Request timeout
                    </label>
                    <el-input-number
                      v-model="requestTimeout"
                      :precision="1"
                      :step="0.1"
                    ></el-input-number>
                  </div>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request headers
                  </label>
                  <el-tooltip
                    content="Add new column (request headers)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestHeaders"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request">
                  <label class="model-label" for="requestHeaders"> Name </label>
                  <label class="model-label" for="requestHeaders">
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestHeaders"
                  :key="`${id}headers`"
                  class="label-request mt-2"
                >
                  <input
                    class="mr-2"
                    type="text"
                    :id="elt.name"
                    :name="elt.name"
                    placeholder="column request headers name"
                    v-model="elt.name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    :disabled="elt.name.length == ''"
                    :id="elt.value"
                    :name="elt.value"
                    placeholder="column request headers value"
                    v-model="elt.value"
                  />

                  <el-tooltip
                    class="ml-2"
                    content="Delete column"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete-solid"
                      size="mini"
                      @click="deleteColumnRequestHeaders(id)"
                    ></el-button>
                  </el-tooltip>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request body
                  </label>
                  <el-tooltip
                    content="Add new column (request body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="width: 32%; text-align: left"
                  >
                    Name
                  </label>
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="width: 35%; text-align: left"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestBody"
                  :key="`${id}body`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2 mb-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column request body name"
                      v-model="elt.name"
                    />
                    <el-select
                      :id="`body-type${id}`"
                      v-model="elt.type"
                      collapse-tags
                      laceholder="request method"
                      style="width: 100%"
                      :disabled="elt.name.length == ''"
                    >
                      <el-option
                        v-for="(item, id) in bodyType"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <input
                      v-if="elt.type === 'text' || elt.type === 'number'"
                      class="ml-2"
                      :disabled="elt.name.length == ''"
                      :type="elt.type"
                      placeholder="column request body value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <vue-json-editor
                    v-if="elt.type === 'array' || elt.type === 'object'"
                    v-model="elt.value"
                    :show-btns="false"
                    :expandedOnStart="false"
                  ></vue-json-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showModalReRun = false">
              {{ $t("list_item_page.close") }}
            </base-button>
            <span>
              <el-tooltip
                :disabled="!(reRunUrl === '' || methodReRun === '')"
                :content="`${
                  reRunUrl === '' ? 'Run url cannot be empty;' : ''
                } ${
                  methodReRun === '' ? 'methon dReRun cannot be empty;' : ''
                } `"
                placement="top"
              >
                <base-button
                  :disabled="reRunUrl === '' || methodReRun === ''"
                  type="primary"
                  @click="reRun(true)"
                  >Run</base-button
                >
              </el-tooltip>
            </span>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal :show.sync="responseRequestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            reRun calls api response
          </h5>
        </template>
        <div class="ctb--body mb-3" style="width: 100%" v-if="loadingRequest">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div v-else class="body--modale mb-4">
          <vue-json-pretty
            :data="responseRequest"
            :showSelectController="true"
            :showIcon="true"
            :showLineNumber="true"
          />
        </div>
      </modal>
    </div>

    <div class="add-test-project-modal">
      <modal :show.sync="showModalAddApi" v-loading="loadingRequest">
        <template slot="header">
          <h5 v-if="editApi === true" class="modal-title" id="addTestModal">
            Edit calls api {{ showReRunIndex }}
          </h5>
          <h5 v-else class="modal-title" id="addTestModal">Add calls api</h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div
              v-if="editApi === true"
              class="btn-group align-items-center mb-4"
            >
              <el-button
                @click="editor = 'ui'"
                icon="el-icon-setting"
                class="tab--btn secondary"
                :class="{ active: editor == 'ui' }"
              >
                View ui
              </el-button>
              <el-button
                @click="editor = 'code'"
                icon="el-icon-guide"
                class="tab--btn secondary"
                :class="{ active: editor == 'code' }"
              >
                View code
              </el-button>
            </div>
            <div
              v-if="editApi === false || editor === 'ui'"
              class="add-test-project-modal"
            >
              <div class="run-name">
                <div class="label-request">
                  <label
                    class="model-label mr-2 is-required--custom"
                    for="url"
                    style="width: 50%; text-align: start"
                    :required="true"
                  >
                    Url
                  </label>
                  <label
                    class="model-label ml-2 is-required--custom"
                    for="originatingPageUrl"
                    style="width: 50%; text-align: start"
                  >
                    Originating page url
                  </label>
                </div>
                <div class="label-request">
                  <input
                    class="mr-2"
                    type="text"
                    id="url"
                    name="url"
                    placeholder="Url"
                    v-model="reRunUrl"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    id="originatingPageUrl"
                    name="originatingPageUrl"
                    placeholder="Originating page url"
                    v-model="originatingPageUrl"
                  />
                </div>
                <div class="label-request">
                  <label
                    class="model-label mr-2 is-required--custom"
                    for="request_method"
                    style="width: 50%; text-align: start"
                  >
                    Request method
                  </label>
                  <label
                    class="model-label ml-2 is-required--custom"
                    for="request_method"
                    style="width: 50%; text-align: start"
                  >
                    Response type
                  </label>
                </div>
                <div class="label-request">
                  <el-select
                    class="mr-2"
                    id="request_method"
                    v-model="methodReRun"
                    collapse-tags
                    laceholder="request method"
                  >
                    <el-option
                      v-for="(item, id) in allMethodReRun"
                      :key="id"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    class="ml-2"
                    id="request_method"
                    v-model="responseTypeValue"
                    collapse-tags
                    laceholder="request method"
                  >
                    <el-option
                      v-for="(item, id) in responseType"
                      :key="id"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="label-request">
                  <label
                    class="model-label mr-2 is-required--custom"
                    for="request_method"
                    style="width: 50%; text-align: start"
                  >
                    Status
                  </label>

                  <label
                    class="model-label ml-2 is-required--custom"
                    for="url"
                    style="width: 50%; text-align: start"
                  >
                    Response time (milliseconds)
                  </label>
                </div>
                <div class="label-request">
                  <el-select
                    class="mr-2"
                    id="request_method"
                    v-model="statusAddApi"
                    collapse-tags
                    laceholder="request method"
                  >
                    <el-option
                      v-for="(item, id) in listStatusAddApi"
                      :key="id"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <input
                    class="ml-2"
                    type="number"
                    id="ResponseTime"
                    name="ResponseTime"
                    placeholder="Response time"
                    v-model="ResponseTime"
                  />
                </div>
                <div class="label-request" style="justify-content: flex-start">
                  <label
                    class="model-label is-required--custom"
                    for="isActive"
                    style="width: auto"
                  >
                    Is active
                  </label>
                  <el-switch
                    class="ml-5 mt-2"
                    v-model="isActiveApi"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="true"
                    :inactive-value="false"
                  >
                  </el-switch>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request headers
                  </label>
                  <el-tooltip
                    content="Add new column (request headers)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestHeaders"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="requestHeaders.length != 0" class="label-request">
                  <label
                    class="model-label is-required--custom"
                    for="requestHeaders"
                    style="width: 50%"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required--custom"
                    for="requestHeaders"
                    style="width: 50%"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestHeaders"
                  :key="`${id}requesHeaders`"
                  class="label-request mt-2"
                >
                  <input
                    class="mr-2"
                    type="text"
                    :id="elt.name"
                    :name="elt.name"
                    placeholder="column request headers name"
                    v-model="elt.name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    :disabled="elt.name.length == ''"
                    :id="elt.value"
                    :name="elt.value"
                    placeholder="column request headers value"
                    v-model="elt.value"
                  />
                  <el-tooltip
                    class="ml-2"
                    content="Delete column"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete-solid"
                      size="mini"
                      @click="deleteColumnRequestHeaders(id)"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request mt-4">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request body
                  </label>
                  <el-tooltip
                    content="Add new column (request body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="requestBody.length != 0" class="label-request">
                  <label
                    class="model-label is-required--custom"
                    for="requestHeaders"
                    style="width: 32%; text-align: left"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required--custom"
                    for="requestHeaders"
                    style="width: 35%; text-align: left"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestBody"
                  :key="`${id}requesBody`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2 mb-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column request body name"
                      v-model="elt.name"
                    />
                    <el-select
                      :id="`body-type${id}`"
                      v-model="elt.type"
                      collapse-tags
                      laceholder="request method"
                      :disabled="elt.name.length == ''"
                    >
                      <el-option
                        v-for="(item, id) in bodyType"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <input
                      v-if="elt.type === 'text' || elt.type === 'number'"
                      class="ml-2"
                      :type="elt.type"
                      :disabled="elt.name.length == ''"
                      placeholder="column request body value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <vue-json-editor
                    v-if="elt.type === 'array' || elt.type === 'object'"
                    v-model="elt.value"
                    :show-btns="false"
                    :expandedOnStart="false"
                  ></vue-json-editor>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Response headers
                  </label>
                  <el-tooltip
                    content="Add new column (Response headers)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestHeaders('response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="responseHeaders.length != 0" class="label-request">
                  <label
                    class="model-label is-required--custom"
                    for="responseHeadersName"
                    style="width: 50%"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required--custom"
                    for="responseHeadersValue"
                    style="width: 50%"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in responseHeaders"
                  :key="`${id}responseHeaders`"
                  class="label-request mt-2"
                >
                  <input
                    class="mr-2"
                    type="text"
                    :id="elt.name"
                    :name="elt.name"
                    placeholder="column response headers name"
                    v-model="elt.name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    :disabled="elt.name.length == ''"
                    :id="elt.value"
                    :name="elt.value"
                    placeholder="column response headers value"
                    v-model="elt.value"
                  />
                  <el-tooltip
                    class="ml-2"
                    content="Delete column"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete-solid"
                      size="mini"
                      @click="deleteColumnRequestHeaders(id, 'response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request mt-4">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Response body
                  </label>
                  <el-tooltip
                    content="Add new column (response body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody('response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="responseBody.length != 0" class="label-request">
                  <label
                    class="model-label is-required--custom"
                    for="responseBodyName"
                    style="width: 32%; text-align: left"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required--custom"
                    for="responseBodyValue"
                    style="width: 35%; text-align: left"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in responseBody"
                  :key="`${id}responseBody`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2 mb-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column response body name"
                      v-model="elt.name"
                    />
                    <el-select
                      :id="`body-type${id}`"
                      v-model="elt.type"
                      collapse-tags
                      laceholder="response method"
                      :disabled="elt.name.length == ''"
                    >
                      <el-option
                        v-for="(item, id) in bodyType"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <input
                      v-if="elt.type === 'text' || elt.type === 'number'"
                      class="ml-2"
                      :type="elt.type"
                      :disabled="elt.name.length == ''"
                      placeholder="column response body value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id, 'response')"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <vue-json-editor
                    v-if="elt.type === 'array' || elt.type === 'object'"
                    v-model="elt.value"
                    :show-btns="false"
                    :expandedOnStart="false"
                  ></vue-json-editor>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Meta data
                  </label>
                  <el-tooltip
                    content="Add new column (response body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody('metadata')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="metadata.length != 0" class="label-request">
                  <label
                    class="model-label is-required--custom"
                    for="metadataBodyName"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required--custom"
                    for="metadataBodyValue"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in metadata"
                  :key="`${id}metadata`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column metadata name"
                      v-model="elt.name"
                    />
                    <input
                      class="ml-2"
                      type="text"
                      :disabled="elt.name.length == ''"
                      placeholder="column metadata value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id, 'metadata')"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="editor === 'code' && editApi === true"
              class="add-test-project-modal"
            >
              <vue-json-editor
                v-model="setEditEl"
                :show-btns="false"
                :expandedOnStart="false"
              ></vue-json-editor>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showModalAddApi = false">
              {{ $t("list_item_page.close") }}
            </base-button>
            <el-tooltip
              v-if="editApi === true && editor == 'ui'"
              :disabled="
                !(
                  reRunUrl === '' ||
                  originatingPageUrl === '' ||
                  methodReRun === '' ||
                  responseTypeValue === '' ||
                  statusAddApi === ''
                )
              "
              :content="`${
                originatingPageUrl === ''
                  ? 'originating PageUrl  cannot be empty;'
                  : ''
              } ${methodReRun === '' ? 'method ReRun cannot be empty;' : ''}
                                    ${
                                      reRunUrl === ''
                                        ? 'run url  cannot be empty;'
                                        : ''
                                    } ${
                responseTypeValue === ''
                  ? 'response type  cannot be empty;'
                  : ''
              } 
                                    ${
                                      statusAddApi === ''
                                        ? 'status cannot be empty;'
                                        : ''
                                    }
                                `"
              placement="top"
            >
              <span>
                <base-button
                  type="primary"
                  :disabled="
                    reRunUrl === '' ||
                    originatingPageUrl === '' ||
                    methodReRun === '' ||
                    responseTypeValue === '' ||
                    statusAddApi === ''
                  "
                  @click="saveEditApi"
                  >Save</base-button
                >
              </span>
            </el-tooltip>
            <el-tooltip
              v-else-if="editApi === true && editor == 'code'"
              :disabled="
                !(
                  setEditEl.url === '' ||
                  !setEditEl.url ||
                  setEditEl.originating_page_url === '' ||
                  !setEditEl.originating_page_url ||
                  setEditEl.method === '' ||
                  !setEditEl.method ||
                  setEditEl.response_time === '' ||
                  !setEditEl.response_time ||
                  setEditEl.status === '' ||
                  !setEditEl.status
                )
              "
              :content="`${
                !setEditEl.originating_page_url ||
                setEditEl.originating_page_url === ''
                  ? ' key originating_page_url  cannot be empty;'
                  : ''
              } ${
                !setEditEl.method || setEditEl.method === ''
                  ? 'key method cannot be empty;'
                  : ''
              }
                                    ${
                                      !setEditEl.url || setEditEl.url === ''
                                        ? 'key url  cannot be empty;'
                                        : ''
                                    } ${
                setEditEl.response_type === ''
                  ? 'key response_type  cannot be empty;'
                  : ''
              } 
                                    ${
                                      statusAddApi === ''
                                        ? 'status cannot be empty;'
                                        : ''
                                    }
                                `"
              placement="top"
            >
              <span>
                <base-button
                  type="primary"
                  :disabled="
                    setEditEl.url === '' ||
                    !setEditEl.url ||
                    setEditEl.originating_page_url === '' ||
                    !setEditEl.originating_page_url ||
                    setEditEl.method === '' ||
                    !setEditEl.method ||
                    setEditEl.response_time === '' ||
                    !setEditEl.response_time ||
                    setEditEl.status === '' ||
                    !setEditEl.status
                  "
                  @click="saveEditApi"
                  >edit</base-button
                >
              </span>
            </el-tooltip>
            <el-tooltip
              v-else
              :disabled="
                !(
                  reRunUrl === '' ||
                  originatingPageUrl === '' ||
                  methodReRun === '' ||
                  responseTypeValue === '' ||
                  statusAddApi === ''
                )
              "
              :content="`${
                originatingPageUrl === ''
                  ? 'originating PageUrl  cannot be empty;'
                  : ''
              } ${methodReRun === '' ? 'methon dReRun cannot be empty;' : ''}
                                    ${
                                      reRunUrl === ''
                                        ? 'run url  cannot be empty;'
                                        : ''
                                    } ${
                responseTypeValue === ''
                  ? 'response type  cannot be empty;'
                  : ''
              } 
                                    ${
                                      statusAddApi === ''
                                        ? 'status cannot be empty;'
                                        : ''
                                    }
                                `"
              placement="top"
            >
              <span>
                <base-button
                  type="primary"
                  :disabled="
                    reRunUrl === '' ||
                    originatingPageUrl === '' ||
                    methodReRun === '' ||
                    responseTypeValue === '' ||
                    statusAddApi === ''
                  "
                  @click="addApi"
                  >Add</base-button
                >
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";

import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import DataList from "@/components/Custom/DataList.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import vueJsonEditor from "vue-json-editor";
export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    VueJsonPretty,
    vueJsonEditor,
    DataList,
  },
  data() {
    return {
      jsonData: '{"name": "John Doe", "age": 30, "city": "New York"}',
      options: {
        maxDepth: 4,
        rootObjectKey: "root",
        modifiable: false,
        link: false,
        limitRenderDepth: false,
      },
      originatingPageUrl: "",
      sessionsDetail: [],
      loadingSession: true,
      loadingRequest: false,
      responseRequest: [],
      responseRequestModal: false,
      selectedItemId: null,
      paginationSession: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      Header: [
        {
          label: "",
          key: "_id",
          width: "2%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Url".toUpperCase(),
          key: "",
          width: "45%",
          sortable: false,
        },
        {
          label: "Status code".toUpperCase(),
          key: "",
          width: "13%",
          sortable: false,
        },
        {
          label: "respTime".toUpperCase(),
          key: "",
          width: "6%",
          sortable: false,
        },
        {
          label: "Status".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Started".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "5%", sortable: false },
      ],
      callApiCurrentPage: 1,
      limitCallApi: 10,
      showModalReRun: false,
      showReRunId: "",
      showReRunIndex: 0,
      reRunUrl: "",
      allMethodReRun: [
        "",
        "get",
        "post",
        "delete",
        "put",
        "connect",
        "options",
        "head",
        "patch",
        "trace",
      ],
      methodReRun: "",
      requestHeaders: [],
      requestBody: [],
      metadata: [],
      responseHeaders: [],
      responseBody: [],
      bodyType: ["text", "array", "object", "number"],
      showModalAddApi: false,
      responseType: ["html", "str", "json", "none"],
      responseTypeValue: "",
      statusAddApi: "",
      ResponseTime: 0,
      editApi: false,
      isActiveApi: false,
      connectionTimeout: 0.0,
      requestTimeout: 0.0,
      reRunEditData: false,
      editor: "ui",
      setEditEl: [],
      listStatusAddApi: [
        "200 - OK",
        "201 - Created",
        "204 - No Content",
        "400 - Bad Request",
        "401 - Unauthorized",
        "403 - Forbidden",
        "404 - Not Found",
        "500 - Internal Server Error",
        "502 - Bad Gateway",
        "503 - Service Unavailable",
      ],
    };
  },
  async created() {
    await this.detailsSession();
  },
  methods: {
    getTagType(method) {
      if (method.toLowerCase() == "get") {
        return "success";
      }
      if (method.toLowerCase() == "post") {
        return "";
      }
      if (method.toLowerCase() == "put") {
        return "warning";
      }
      if (method.toLowerCase() == "delete") {
        return "danger";
      }
      if (method.toLowerCase() == "patch") {
        return "info";
      }
    },
    getJsonData(el) {
      let dat = el;
      delete dat.session_uid;
      delete dat.testrun_requestid;
      delete dat.pageviews_project_id;
      return dat;
    },
    pc(t, v) {
      return `${(t * 100) / v}%`;
    },
    openEditAccordion(id) {
      this.selectedItemId = this.selectedItemId !== id ? id : null;
    },
    copy(text) {
      try {
        let jsonString = JSON.stringify(text);

        const textarea = document.createElement("textarea");
        textarea.value = jsonString;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$notify({
          type: "success",
          message: "Api calls Copied Successfully.",
        });
      } catch (error) {}
    },
    handleCurrentChangeCallApi(val) {
      val === "next"
        ? (this.callApiCurrentPage = this.callApiCurrentPage + 1)
        : (this.callApiCurrentPage = this.callApiCurrentPage - 1);
      this.detailsSession();
    },
    async detailsSession() {
      let sessionId = this.$route.params.session_id;
      this.loadingSession = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const params = new URLSearchParams();
      params.append("page", this.callApiCurrentPage);
      params.append("count", this.limitCallApi);
      let filter = `?page=${this.callApiCurrentPage}&count=${this.limitCallApi}`;
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/session/" +
            sessionId +
            filter
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the session failed ",
              });
              this.sessionsDetail = [];
            } else if (response.status === 200) {
              this.sessionsDetail = response.data;
              this.callApiCurrentPage = response.data.currentPage;
              this.limitCallApi = response.data.perPageCount;
              this.showGraph();
              this.loadingSession = false;
            } else if (response.data.status === "failure") {
              this.$notify({
                type: "danger",
                message: "the recovery of the session failed ",
              });
              this.sessionsDetail = [];
              this.loadingSession = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingSession = false;
          // this.$notify({
          //     type: "danger",
          //     message: "an error occurred while retrieving data ",
          // });
        });
      this.loadingSession = false;
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    showGraph() {
      let label = [];
      let data = [];
      if (this.sessionsDetail.apicallStatistics.resptime_average) {
        label.push("resptime_average");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_average.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_max) {
        label.push("resptime_max");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_max.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_min) {
        label.push("resptime_min");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_min.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_p50) {
        label.push("resptime_p50");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_p50.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_p95) {
        label.push("resptime_p95");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_p95.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_p99) {
        label.push("resptime_p99");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_p99.toFixed(2)
        );
      }
      if (this.sessionsDetail.apicallStatistics.resptime_stddev) {
        label.push("resptime_stddev");
        data.push(
          this.sessionsDetail.apicallStatistics.resptime_stddev.toFixed(2)
        );
      }
      var ctx = document.getElementById("myChart").getContext("2d");
      ctx.innerHTML = "";
      var myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              label: "Request time",
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(95, 114, 228, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(95, 114, 228, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Request time Statistics (Time milliseconds)",
            fontSize: 17, // Change this value to modify the font size
          },
          tooltips: {
            titleFontSize: 15, // Change this value to modify the title font size
            bodyFontSize: 15, // Change this value to modify the body font size
            callbacks: {
              label: function (tooltipItem, data) {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                if (data.labels[tooltipItem.index] === "resptime_average")
                  return ` The average response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_max")
                  return ` The shortest response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_min")
                  return ` The longest response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p50")
                  return ` The median response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p95")
                  return ` The 95th percentile response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_p99")
                  return ` The 99th percentile response time of all API calls in this session in milliseconds : ${value}`;
                if (data.labels[tooltipItem.index] === "resptime_stddev")
                  return ` The standard deviation of the response time of all API calls in this session in milliseconds : ${value}`;
              },
            },
          },
          legend: {
            display: false,
            labels: {
              fontSize: 24, // Change this value to modify the font size
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 10, // Change this value to modify the font size
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 11, // Change this value to modify the font size
                },
              },
            ],
          },
        },
      });
    },
    async openReRunApi(el, index) {
      const confirmation = await swal.fire({
        title: "Modify request ?",
        text: "You had the option of modifying the request (method, url, request header, request body).",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Modify and run!",
        cancelButtonText: "Run as is ",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
      });
      this.reRunUrl = el.url;
      this.methodReRun = el.method;
      this.showReRunId = el.apicall_uid;
      this.showReRunIndex = index + 1;
      this.requestHeaders = [];
      Object.entries(el.request_headers).map((item) => {
        this.requestHeaders.push({ name: item[0], value: item[1] });
      });
      this.requestBody = [];
      if (el.response_body) {
        Object.entries(el.response_body).map((item) => {
          this.requestBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      }
      console.log("yesss", el);
      if (confirmation.value === true) {
        this.editApi = false;
        this.showModalReRun = true;
        this.responseRequestModal = false;
        this.reRunEditData = true;
      } else {
        this.showReRunId = el.apicall_uid;
        this.reRunEditData = false;
        this.responseRequestModal = true;
        this.reRun(false);
      }
    },
    async proccessEditApi(el, index) {
      this.setEditEl = el;
      delete this.setEditEl.created_at;
      delete this.setEditEl.pageviews_project_id;
      delete this.setEditEl.session_uid;
      delete this.setEditEl.testrun_requestid;
      delete this.setEditEl.updated_at;
      this.editApi = true;
      this.editor = "ui";
      this.showModalAddApi = true;
      this.reRunUrl = el.url;
      this.originatingPageUrl = el.originating_page_url || "";
      this.methodReRun = el.method;
      this.responseTypeValue = el.response_type;
      this.statusAddApi = el.status;
      this.ResponseTime = el.response_time;
      this.isActiveApi = el.is_active;
      this.showReRunId = el.apicall_uid;
      this.showReRunIndex = index + 1;
      this.requestHeaders = [];
      this.responseHeaders = [];
      this.requestBody = [];
      this.responseBody = [];
      this.metadata = [];
      Object.entries(el.request_headers).map((item) => {
        this.requestHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.request_body) {
        Object.entries(el.request_body).map((item) => {
          this.requestBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.requestBody = [];
      }
      Object.entries(el.response_headers).map((item) => {
        this.responseHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.response_body) {
        Object.entries(el.response_body).map((item) => {
          this.responseBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.responseBody = [];
      }
      if (el.metadata) {
        Object.entries(el.metadata).map((item) => {
          this.metadata.push({ name: item[0], value: item[1] });
        });
      } else {
        this.metadata = [];
      }
      this.responseRequestModal = false;
    },
    addNewColumnRequestHeaders(el) {
      if (el === "response") {
        this.responseHeaders.push({ name: "", value: "" });
      } else {
        this.requestHeaders.push({ name: "", value: "" });
      }
    },
    deleteColumnRequestHeaders(index, el) {
      if (el === "response") {
        if (this.responseHeaders.length == 1) {
          if (this.editApi == false) return;
          this.responseHeaders = [];
        } else {
          this.responseHeaders.splice(index, 1);
        }
      } else {
        if (this.requestHeaders.length == 1) {
          if (this.editApi == false) return;
          this.requestHeaders = [];
        } else {
          this.requestHeaders.splice(index, 1);
        }
      }
    },
    addNewColumnRequestBody(el) {
      if (el === "response") {
        this.responseBody.push({ name: "", type: "text", value: "" });
      } else if (el === "metadata") {
        this.metadata.push({ name: "", value: "" });
      } else {
        this.requestBody.push({ name: "", type: "text", value: "" });
      }
    },
    deleteColumnRequestBody(index, el) {
      if (el === "response") {
        if (this.responseBody.length == 1) {
          this.responseBody = [];
        } else {
          this.responseBody.splice(index, 1);
        }
      } else if (el === "metadata") {
        if (this.metadata.length == 1) {
          this.metadata = [];
        } else {
          this.metadata.splice(index, 1);
        }
      } else {
        if (this.requestBody.length == 1) {
          this.requestBody = [];
        } else {
          this.requestBody.splice(index, 1);
        }
      }
    },
    async reRun(type) {
      let apiCallId = this.showReRunId;
      this.loadingRequest = true;
      let data = {};
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (this.requestTimeout > 0.1) {
        data.requestTimeout = this.requestTimeout;
      }
      if (this.connectionTimeout > 0.1) {
        data.connectionTimeout = this.connectionTimeout;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (this.requestHeaders.length > 0) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          if (item.name != "") {
            finalyHeader[item.name] = item.value;
          }
        });
        data.requestHeaders = finalyHeader;
      } else {
        data.requestHeaders = null;
      }
      if (this.requestBody.length > 0) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.requestBody = finalyBody;
      } else {
        data.requestBody = null;
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      let resp = null;
      if (this.reRunEditData == true) {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
      } else {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId
        );
      }
      if (resp.data.status === "success") {
        this.responseRequest = resp.data.response;
        delete this.responseRequest.apicall_uid;
        delete this.responseRequest.session_uid;
        delete this.responseRequest.testrun_requestid;
        delete this.responseRequest.updated_at;
        delete this.responseRequest.created_at;
        delete this.responseRequest.pageviews_project_id;
        this.loadingRequest = false;
        this.responseRequestModal = true;
        this.showModalReRun = false;
      } else if (resp.data.status === "failed") {
        if (resp.data.response) {
          this.responseRequest = resp.data.response;
          delete this.responseRequest.apicall_uid;
          delete this.responseRequest.session_uid;
          delete this.responseRequest.testrun_requestid;
          delete this.responseRequest.updated_at;
          delete this.responseRequest.created_at;
          delete this.responseRequest.pageviews_project_id;
          this.responseRequestModal = true;
          this.showModalReRun = false;
          this.loadingRequest = false;
        } else {
          this.$notify({
            type: "danger",
            message: `an error has occurred! (${resp.data.message})`,
          });
          this.responseRequest = [];
          this.loadingRequest = false;
        }
      } else {
        this.responseRequest = [];
        this.loadingRequest = false;
      }
    },
    processAddApi() {
      this.editApi = false;
      this.showModalAddApi = true;
      this.reRunUrl = "";
      this.originatingPageUrl = "";
      this.methodReRun = "";
      this.responseBody = [];
      this.requestBody = [];
      this.requestHeaders = [
        { name: "Content-Type", value: "application/json" },
      ];
      this.responseHeaders = [
        { name: "Content-Type", value: "application/json" },
      ];
      this.ResponseTime = 0;
      this.responseTypeValue = "";
      this.statusAddApi = "";
    },
    async addApi() {
      let apiCallId = this.showReRunId;
      this.loadingRequest = true;
      let data = {};
      data.request = {};
      data.response = {};
      data.sessionId = this.$route.params.session_id;
      data.pageviewsProjectId = this.$route.params.id;
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (Number(this.ResponseTime) > 0) {
        data.responseTime = Number(this.ResponseTime);
      }
      if (this.originatingPageUrl != "" && this.originatingPageUrl.length > 0) {
        data.originatingPageUrl = this.originatingPageUrl;
      }
      if (this.statusAddApi != "" && this.statusAddApi.length > 0) {
        data.status = this.statusAddApi;
      }
      if (this.responseTypeValue != "" && this.responseTypeValue.length > 0) {
        data.responseType = this.responseTypeValue;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (this.metadata.length > 0) {
        let finalymMtadata = {};
        this.metadata.map((item) => {
          if (item.name != "") {
            finalymMtadata[item.name] = item.value;
          }
        });
        data.metadata = finalymMtadata;
      }
      if (this.requestHeaders.length > 0) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          if (item.name != "") {
            finalyHeader[item.name] = item.value;
          }
        });
        data.request.headers = finalyHeader;
      }
      if (this.responseHeaders.length > 0) {
        let finalyResponsHeader = {};
        this.responseHeaders.map((item) => {
          if (item.name != "") {
            finalyResponsHeader[item.name] = item.value;
          }
        });
        data.response.headers = finalyResponsHeader;
      }
      if (this.requestBody.length > 0) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            console.log("yess", item.name);
          finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.request.body = finalyBody;
      }
      if (this.responseBody.length > 0) {
        let finalyBody = {};
        this.responseBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.response.body = finalyBody;
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      const resp = await axios.post(
        process.env.VUE_APP_API_URL_PREFIX + "/apicalls/v1/call",
        data,
        { headers: { "Content-Type": "application/json" } }
      );
      if (resp.data.status === "success") {
        this.responseRequestModal = false;
        this.showModalAddApi = false;
        this.loadingRequest = false;
        this.detailsSession();
      } else if (resp.data.status === "failed") {
        if (resp.data.response) {
          this.showModalAddApi = false;
          this.loadingRequest = false;
        } else {
          this.responseRequest = [];
          this.loadingRequest = false;
        }
      } else {
        this.responseRequest = [];
        this.loadingRequest = false;
      }
    },
    async saveEditApi() {
      if (this.editor == "code") {
        await this.proccessEditApi(this.setEditEl, this.showReRunIndex);
      }
      let apiCallId = this.showReRunId;
      let data = {};
      data.isActive = this.isActiveApi;
      data.sessionId = this.$route.params.session_id;
      data.pageviewsProjectId = this.$route.params.id;
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (this.requestTimeout > 0.1) {
        data.requestTimeout = this.requestTimeout;
      }
      if (this.connectionTimeout > 0.1) {
        data.connectionTimeout = this.connectionTimeout;
      }
      if (Number(this.ResponseTime) > 0) {
        data.responseTime = Number(this.ResponseTime);
      }
      if (this.originatingPageUrl != "" && this.originatingPageUrl.length > 0) {
        data.originatingPageUrl = this.originatingPageUrl;
      }
      if (this.statusAddApi != "" && this.statusAddApi.length > 0) {
        data.status = this.statusAddApi;
      }
      if (this.responseTypeValue != "" && this.responseTypeValue.length > 0) {
        data.responseType = this.responseTypeValue;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (this.metadata.length > 0) {
        let finalymMtadata = {};
        this.metadata.map((item) => {
          if (item.name != "") {
            finalymMtadata[item.name] = item.value;
          }
        });
        data.metadata = finalymMtadata;
      }
      if (this.requestHeaders.length > 0) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          if (item.name != "") {
            finalyHeader[item.name] = item.value;
          }
        });
        data.requestHeaders = finalyHeader;
      }
      if (this.responseHeaders.length > 0) {
        let finalyResponsHeader = {};
        this.responseHeaders.map((item) => {
          if (item.name != "") {
            finalyResponsHeader[item.name] = item.value;
          }
        });
        data.responseHeaders = finalyResponsHeader;
      }
      if (this.requestBody.length > 0) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.requestBody = JSON.stringify(finalyBody);
      }
      if (this.responseBody.length > 0) {
        let finalyBody = {};
        this.responseBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.responseBody = JSON.stringify(finalyBody);
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      const resp = await axios.put(
        process.env.VUE_APP_API_URL_PREFIX + `/apicalls/v1/call/${apiCallId}`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );
      if (resp.data.status === "success") {
        this.loadingRequest = false;
        this.showModalAddApi = false;
        this.$notify({
          type: "success",
          message: "Edit api call successfully.",
        });
        this.detailsSession();
      } else if (resp.data.status === "failed") {
        this.loadingRequest = false;
        this.$notify({
          type: "danger",
          message: `an error has occurred! (${resp.data.message})`,
        });
      }
    },
    async deleteApiCall(el) {
      console.log(el);
      const apiCallId = el.apicall_uid;
      const confirmation = await swal.fire({
        title:
          "Api call '" +
          (el.url || el.originating_page_url) +
          "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.loadingSession = true;
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 300);
        };
        const resp = await axios.delete(
          process.env.VUE_APP_API_URL_PREFIX + `/apicalls/v1/call/${apiCallId}`
        );
        if (resp.data.status === "success") {
          this.$notify({
            type: "success",
            message: "api call delete successfully.",
          });
          this.loadingSession = false;
          this.detailsSession();
        } else {
          this.loadingSession = false;
          this.$notify({
            type: "danger",
            message: `an error has occurred! (${resp.data.message})`,
          });
        }
      }
    },
    getType(el) {
      let type = typeof el;
      if (type === "string") return "text";
      return type;
    },
  },
};
</script>
<style lang="scss">
#myChart {
  width: 100% !important;
  height: 400px !important;
}
.analytics-dashboard .dashboard-tile {
  flex: 1;
  display: inline-block;
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  border-radius: var(--radius-large);
  border: 1px solid var(--fade-grey-dark-3);
  transition: all 0.3s;
  font-family: var(--font);
}
.test-run-name-callApi {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.elt--txt {
  color: var(--main-blue-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.test-run-name {
  font-size: 0.81rem;
}
.no--close {
  padding: 0.25rem 0.35rem;

  .el-alert__closebtn {
    display: none;
  }
}
.script {
  text-align: start;
  padding: 5px 5px;
  background-color: #161b22 !important;
}
pre {
  overflow: hidden;
}
.notranslate {
  position: relative;
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  color: #ffff;
  background-color: #161b22;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.is-flex-code {
  display: flex;
  align-items: start;
  margin-bottom: 5px;
}
::-webkit-scrollbar {
  background-color: #aaa !important;
  height: 5px !important;
}
.is-elt {
  color: #79c0ff;
}
.is-key {
  color: #7ee787;
}

.Header-api {
  background: var(--primary);
  border-radius: 1em;
  .test-run-name {
    color: #6e6e6e;
  }
}
.tags-call-api {
  width: 80px;
  display: flex;
}
.icon-copy {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.is-flex-graph {
  display: flex;
  justify-content: space-between;
  i {
    color: #5f72e4;
  }
}
.tile-body > .dark-inverted {
  font-size: 2em;
  font-weight: 800;
}
.tile-body {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.tile-foot {
  margin-top: 23px;
  justify-content: space-between;
  padding: 0 0 20px 0;
  border-bottom: 1px solid var(--main-blue-color);
  display: flex;
  align-items: center;
  text-align: left;
}
.text-h-green {
  margin-right: 5px;
  color: #67c33a;
}
.faild {
  color: #c33a3a;
}
.label-request {
  display: flex;
  gap: 2;
  justify-content: space-between;
  width: 100%;
  label {
    width: 50%;
    text-align: start;
  }
}
.request-object {
  background-color: #161b22;
  color: #7ee787;
  border-radius: 6px;
  text-align: left;
}

.response-request-modal {
  .modal {
    .modal-dialog {
      .modal-content {
        max-height: 90vh;
      }
    }
  }
}

.el-input-number {
  line-height: 31px;
}
.vjs-tree {
  width: 100%;
}
.add-test-project-modal > div {
  width: 100%;
}

.api-call-tag {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.api-call-content {
  .elt--header {
    text-align: center;
  }

  .elt--header:nth-child(2) {
    text-align: start;
  }
}

// .el-select .el-input .el-input__inner{
//     height: calc(1.5em + 1.25rem + 2px) !important;
// }
</style>
<style lang="scss" scoped>
.ctbb--row {
  width: 100% !important;
}
</style>
