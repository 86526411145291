<template>
  <div class="list-item-page">
    <div v-if="!showTestGraph && fullSize" class="modal" style="display: block">
      <app-map :fullsize="true"></app-map>
    </div>
    <div
      class="modal"
      style="display: block; background-color: #e6e6e6"
      v-if="showTestGraph && fullSize"
    >
      <test-graph
        :isFullscreen="true"
        :items="toShowAppMap"
        :nameApp="projectDetail.name"
        :highlight_edges="highlights"
        ref="test-graph"
        :style="clientHeight"
        id="app-map"
      >
      </test-graph>
    </div>
    <div style="display: flex">
      <div
        @click="modal_id = null"
        :style="{ overflowY: showTestSuit ? 'scroll !important' : 'hidden' }"
        :class="
          showAppMap || showApi ? 'right-content' : 'right-content px-4 pt-4'
        "
      >
        <div
          class="c--breadcrumb"
          style="justify-content: space-between; width: 94%; padding-left: 0"
        >
          <div class="my-3">
            <router-link to="/">
              <svg
                style="margin-right: 5px"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="home icon">
                  <path
                    id="Icon"
                    d="M9 21V14.1528C9 13.5226 9.53726 13.0116 10.2 13.0116H13.8C14.4627 13.0116 15 13.5226 15 14.1528V21M11.3046 3.21117L3.50457 8.48603C3.18802 8.7001 3 9.04665 3 9.41605V19.2882C3 20.2336 3.80589 21 4.8 21H19.2C20.1941 21 21 20.2336 21 19.2882V9.41605C21 9.04665 20.812 8.70011 20.4954 8.48603L12.6954 3.21117C12.2791 2.92961 11.7209 2.92961 11.3046 3.21117Z"
                    stroke="#8F8F8F"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </g>
              </svg>
            </router-link>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="chevron-double-left icon">
                <path
                  id="Icon"
                  d="M13.4 5.80018L19 11.4002L13.4 17.0002M5 5.80018L10.6 11.4002L5 17.0002"
                  stroke="#8F8F8F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <router-link to="/details/project-explorer/list-projects"
              ><span
                class="text-near-arrow"
                style="
                  color: #8f8f8f;
                  font-size: 14px;
                  font-weight: 500;
                  margin-left: 5px;
                "
              >
                Projects</span
              >
            </router-link>
          </div>
          <div style="display: flex; align-items: center">
            <div class="text-guide"><h3>Need help</h3></div>
            <el-tooltip
              content="Show Guide Line"
              placement="top"
              effect="light"
            >
              <el-button
                style="position: relative; right: 0"
                class="btn-guide--line question-icon-custom"
                type="info"
                size="default"
                circle
                @click="showGuide()"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="page_name_section">
          <h2 style="white-space: nowrap" class="page_name">{{ page_name }}</h2>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(31, 33, 62);
            align-items: baseline;
          "
        >
          <div class="custom_btns" style="border-bottom: 0">
            <button
              @click="showComponent('start')"
              :class="this.showStart ? '' : 'add_button_not_active'"
              class="add_button_menu"
            >
              <!-- v-if="isAppMapEmpty"  -->
              Start
            </button>
            <!-- <button
              :class="this.showAppMap ? '' : 'add_button_not_active'"
              @click="showComponent('appmap')"
              class="add_button_menu"
            >
              AppMap
            </button> -->
            <button
              :class="this.showEvent ? '' : 'add_button_not_active'"
              @click="showComponent('event')"
              class="add_button_menu"
            >
              Sessions
            </button>
            <!-- <button
              :class="this.showApi ? '' : 'add_button_not_active'"
              @click="showComponent('api')"
              class="add_button_menu"
            >
              API
            </button> -->
            <!-- <button
              :class="this.showTestSuit ? '' : 'add_button_not_active'"
              @click="showComponent('testsuite')"
              class="add_button_menu"
            >
              Test Suite
            </button> -->
            <button
              :class="this.showAppChat ? '' : 'add_button_not_active'"
              @click="showComponent('appChat')"
              class="add_button_menu"
            >
              WringrGPT
            </button>
          </div>
          <!-- <button :style="{ display : showAppMap ? '' : 'none' }" class="btn-menu-resize-appmap"><span style="padding-top:6px" :class="isFlowMenu ? 'rotate-icon' : ''" class="arrow_up_custom" @click="closeFlowMenu()"></span></button> -->
          <div v-if="showAppMap">
            <button
              v-if="!fullSize"
              @click="closeFlowMenu"
              style="background: none; border: none; height: 40px; width: 40px"
            >
              <i style="height: 24px; width: 24px"
                ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
                  />
                </svg>
                ></i
              >
            </button>
            <img
              v-else
              src="/img/loading.gif"
              alt="loading..."
              style="height: 40px; width: 40px"
            />
          </div>
        </div>

        <div
          class="c--breadcrumb"
          :style="showAppMap ? displayNone : displayBlock"
        >
          <router-link to="/details/test-explorer/list-tests"
            ><i class="el-icon-house"></i
          ></router-link>
          <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
          <router-link to="/details/project-explorer/list-projects">
            Apps
          </router-link>
          <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
          <span> {{ projectDetail.name ? projectDetail.name : "" }}</span>
        </div>
        <el-alert
          v-if="textAnswer !== ' '"
          type="info"
          title="Answer"
          style="height: 50px"
          :description="textAnswer"
        />
        <template v-if="showEvent">
          <div class="mid--page no-border">
            <div class="mp--l">
              <div class="btn-group align-items-center">
                <!-- <el-tooltip class="item" effect="light" content="Session Videos" placement="top">
                  <el-button @click="showComponentEvent('video')" icon="fa el-icon-video-camera-solid"
                    class="large_btn--def large_btn--adpt" :class="{ 'bg-primary active': video }">
                    Video
                  </el-button>
                </el-tooltip> -->
                <!-- <el-tooltip class="item" effect="light" content="APIs invoked" placement="top">
                  <button @click="showComponentEvent('callApi')" icon="fa el-icon-s-promotion"
                  :class="this.callApi ? '' : 'add_button_not_active'"
                    class="add_button">
                    API
                  </button>
                </el-tooltip> -->

                <!-- <el-tooltip
                  class="item"
                  effect="light"
                  content="Load Desktop"
                  placement="top"
                >
                  <button
                    @click="getSessions('desktop')"
                    icon="fa el-icon-video-camera-solid"
                    :class="this.loadWebSessions ? '' : 'add_button_not_active'"
                    class="add_button"
                  >
                    Web
                  </button>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="light"
                  content="Load mobile envents"
                  placement="top"
                >
                  <button
                    @click="getSessions('mobile')"
                    icon="fa el-icon-video-camera-solid"
                    :class="
                      this.loadMobileSessions ? '' : 'add_button_not_active'
                    "
                    class="add_button"
                  >
                    Mobile
                  </button>
                </el-tooltip> -->
              </div>
            </div>
            <div v-if="video" class="mid--page_container cstm-mid--page">
              <div
                class="mid--page"
                style="border: none; margin: 14px 0 14px 0"
              >
                <div class="mp--r">
                  <span style="display: flex; gap: 40px" class="pag--details">
                    <span class="text-info-steps"
                      >Sessions : {{ totalSession }}</span
                    >
                    <span class="text-info-steps">Show</span>
                  </span>
                  <el-select
                    v-model="paginationSession.perPage"
                    @change="savePaginationSession"
                    placeholder="Per page"
                    class="small--select icon-pad"
                  >
                    <el-option
                      v-for="(item, id) in paginationSession.perPageOptions"
                      :key="id"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div
              v-if="callApi"
              class="mid--page_container"
              style="margin-left: 300px"
            >
              <div class="mid--page pb-3 my-3" style="border: none">
                <div class="mp--l">
                  <el-input
                    :placeholder="$t('list_item_page.search')"
                    v-model="querySession"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-search"
                      style="transform: rotate(0deg) !important"
                    ></i>
                  </el-input>
                </div>
                <div class="mp--r">
                  <span class="pag--details mr-3 ml-3"
                    >{{
                      toShowSessionCallApi.perPageCount
                        ? toShowSessionCallApi.currentPage *
                            toShowSessionCallApi.perPageCount -
                          toShowSessionCallApi.perPageCount +
                          1
                        : 1
                    }}
                    {{ $t("list_item_page.to") }}
                    {{
                      toShowSessionCallApi.currentPage
                        ? toShowSessionCallApi.currentPage *
                          toShowSessionCallApi.perPageCount
                        : 10
                    }}
                    {{ $t("list_item_page.of") }}
                    {{ toShowSessionCallApi.totalSessionCount || 0 }}
                    {{ $t("list_item_page.entries") }}
                  </span>
                  <el-select
                    v-model="limitCallApi"
                    @change="getSessionCallApi"
                    placeholder="Per page"
                    class="small--select"
                  >
                    <el-option
                      v-for="(item, id) in paginationSession.perPageOptions"
                      :key="id"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 14px; display: flex">
            <el-input
              @input="filterSessionsApi"
              class="custom-pad"
              placeholder="Search"
              v-model="filterValue"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
                style="
                  transform: rotate(0deg) !important;
                  font-size: 20px;
                  margin-left: 12px;
                "
              ></i>
            </el-input>
            <el-progress
              class="upload-bar"
              type="dashboard"
              v-if="uploadingFile"
              :percentage="percentageUpload"
              :color="colorsUpload"
            ></el-progress>
            <el-popover
              placement="top-start"
              width="400"
              v-model="showPoppupUpload"
              v-if="!uploadingFile"
              class="popup-download"
            >
              <p style="word-break: break-word">
                Please upload a new test flow or update an existing test flow.
              </p>

              <div class="buttons-uplaoding">
                <!-- <div class="uploading-new"> -->
                <el-tooltip content="Create new test script" placement="top">
                  <el-upload
                    v-if="!uploadingFile"
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-change="onFileChange"
                    :show-file-list="false"
                    :auto-upload="false"
                    ref="uploadNewTest"
                    style="margin-bottom: 10px"
                  >
                    <el-button class="upload-btn add_button" size="small"
                      >New Test Flow</el-button
                    >

                    <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
                  </el-upload>
                </el-tooltip>

                <!-- </div> -->

                <!-- <el-upload
              v-if="!uploadingFile"  
              class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="onFileChangeMulti"
                :show-file-list="false"
                :auto-upload="false"
                ref="upload"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :before-upload="beforeFileUpload"
                :file-list="fileList"
              > -->
                <el-popover
                  v-if="!checkedShow"
                  placement="left"
                  width="450"
                  :offset="200"
                  popper-class="once-popup"
                  v-model="visibleShow"
                >
                  <p style="word-break: break-word">
                    Only changes to the scripts for features/elements in the
                    original script that are also present in the new test flow
                    will be merged.
                  </p>
                  <div style="text-align: right; margin: 0">
                    <el-checkbox v-model="checkedPopup"
                      >Don't show this message again</el-checkbox
                    >
                    <el-button type="primary" size="mini" @click="handleGotIt"
                      >Got It</el-button
                    >
                  </div>
                  <el-button
                    class="upload-btn add_button"
                    @click="(showModalMerge = true), (showPoppupUpload = false)"
                    slot="reference"
                    >Merge Test Flows</el-button
                  >
                </el-popover>
                <el-tooltip
                  content="Merge changes from previous test script into new test flow. Please attach 3 files"
                  placement="top"
                  class="item"
                  effect="light"
                >
                  <el-button
                    class="upload-btn add_button"
                    v-if="checkedShow"
                    size="small"
                    @click="(showModalMerge = true), (showPoppupUpload = false)"
                    >Merge Test Flows</el-button
                  >
                </el-tooltip>
                <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
                <!-- </el-upload> -->
              </div>
              <el-tooltip
                content="Upload existing API description as a json"
                placement="left"
              >
                <el-upload
                  v-if="!uploadingFile"
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-change="onFileChangeAPI"
                  :show-file-list="false"
                  :auto-upload="false"
                  ref="uploadNewTest"
                >
                  <el-button class="upload-btn add_button" size="small"
                    >New API Test</el-button
                  >
                </el-upload>
              </el-tooltip>
              <el-button
                class="upload-btn add_button"
                icon="upload-icon"
                size="small"
                slot="reference"
                v-if="!uploadingFile"
                >Upload</el-button
              >
            </el-popover>
          </div>

          <div v-if="video">
            <data-list
              :loading="loadingSession"
              :items="filteredResultsSessionAPI"
              :customSizes="true"
              ref="dataList"
            >
              <template #content>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 100px 1fr 1fr 1fr;
                    text-align: center;
                  "
                  class="tblb--row header-custom"
                >
                  <div>
                    <h3></h3>
                  </div>

                  <div
                    class="flex btns-gap justify-center"
                    v-if="eventSessionType !== 'mobile'"
                    style="width: 75%"
                  >
                    <h3>URL</h3>
                    <div
                      @click="sortList('$current_url')"
                      :class="
                        sortedBy == '$current_url'
                          ? 'enable-sort'
                          : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['$current_url']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <div
                    class="flex btns-gap justify-center"
                    style="width: 75%"
                    v-else
                  >
                    <h3>URL</h3>
                    <div
                      @click="sortList('$current_url')"
                      :class="
                        sortedBy == '$current_url'
                          ? 'enable-sort'
                          : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['$current_url']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <!-- <div class="flex btns-gap justify-center">
                    <h3>BROWSER</h3>
                    <div
                      @click="sortList('$browser')"
                      :class="
                        sortedBy == '$browser' ? 'enable-sort' : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['$browser']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div> -->
                  <div class="flex btns-gap justify-center" style="width: 27%">
                    <h3>CREATED</h3>
                    <div
                      @click="sortList('start_time')"
                      :class="
                        sortedBy == 'start_time'
                          ? 'enable-sort'
                          : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['start_time']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="flex btns-gap justify-center" style="width: 5%">
                    <h3>STEPS</h3>
                    <div
                      @click="sortList('step_count')"
                      :class="
                        sortedBy == 'step_count'
                          ? 'enable-sort'
                          : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['recording_duration']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="table-body-scroll">
                  <div
                    v-for="(elt, id) in sortedToShow"
                    :key="id"
                    class="tblb--row mb-2"
                  >
                    <!-- :class="
                      Number(elt.step_count) < 1 ? 'not-video-test' : ''
                    " -->
                    <!-- @click="
                      Number(elt.recording_duration) < 1
                        ? ''
                        : showDetailsSession(elt)
                    " -->
                    <el-tooltip
                      v-if="!(Number(elt.recording_duration) < 1)"
                      :disabled="!(Number(elt.recording_duration) < 1)"
                      :content="
                        Number(elt.recording_duration) < 1
                          ? 'this session contains no videos or tests'
                          : ''
                      "
                      placement="top"
                    >
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 100px 1fr 1fr 1fr 1fr;
                          text-align: center;
                        "
                        class="tblb--inner"
                      >
                        <div
                          class="elt-row max-width"
                          style="display: grid; justify-items: center"
                          @click="
                            Number(elt.recording_duration) < 1
                              ? ''
                              : showDetailsSession(elt)
                          "
                        >
                          <span style="overflow: visible" class="test-run-name">
                            {{ forStepNumber(id) }}
                          </span>
                          <el-tag
                            type="success"
                            size="mini"
                            v-if="elt?.customjson.session_type === 'api'"
                            >API</el-tag
                          >
                        </div>

                        <!-- <div class="elt-row" style="width:10%">
                        <span v-if="eventSessionType !== 'mobile'">
                          {{
                            elt.person.properties.$geoip_continent_name
                            ? elt.person.properties.$geoip_continent_name
                            : "N/A"
                          }}
                          /{{
                            elt.person.properties.$geoip_country_name
                            ? elt.person.properties.$geoip_country_name
                            : "N/A"
                          }}
                        </span>
                        <span v-else>
                          {{
                            elt.country_name
                            ? elt.country_name
                            : elt.geolocated_country
                              ? elt.geolocated_country
                              : "N/A"
                          }}
                        </span>
                      </div> -->

                        <div
                          class="elt-row max-width"
                          v-if="eventSessionType !== 'mobile'"
                        >
                          <el-tooltip
                            class="item"
                            effect="light"
                            :content="
                              elt.person.properties.$initial_current_url
                                ? elt.person.properties.$initial_current_url
                                : elt.person.properties.$current_url
                                ? elt.person.properties.$current_url
                                : elt.person.properties.$host
                            "
                            placement="top"
                          >
                            <span
                              class="run-tags-url"
                              style="
                                max-width: 100%;
                                white-space: nowrap;
                                text-transform: lowercase !important;
                              "
                            >
                              {{
                                elt.person.properties.$initial_current_url
                                  ? elt.person.properties.$initial_current_url
                                  : elt.person.properties.$current_url
                                  ? elt.person.properties.$current_url
                                  : elt.person.properties.$host
                              }}
                            </span>
                          </el-tooltip>
                          <div class="container--tags">
                            <el-tag
                              class="elt-row--tag"
                              v-for="(tag, index) in elt.tags"
                              closable
                              :key="index"
                              type="success"
                              size="mini"
                              @close="handleDelete(elt, tag, index)"
                            >
                              {{ tag }}
                            </el-tag>
                          </div>
                        </div>
                        <div class="elt-row max-width" v-else>
                          <el-tooltip
                            class="item"
                            effect="light"
                            :content="elt.app_name"
                            placement="top"
                          >
                            <span
                              class="run-tags"
                              style="
                                display: inline;
                                overflow: visible;
                                white-space: nowrap;
                              "
                            >
                              {{ elt.app_name }} / {{ elt.operating_system }}
                            </span>
                          </el-tooltip>
                        </div>

                        <!-- <div class="elt-row max-width">
                          <span
                            style="white-space: nowrap; overflow: visible"
                            class="elt--txt"
                            v-if="eventSessionType !== 'mobile'"
                          >
                            {{
                              elt.person.properties.$initial_os
                                ? elt.person.properties.$initial_os
                                : elt.person.properties.$os
                            }}
                            /
                            {{
                              elt.person.properties.$initial_browser
                                ? elt.person.properties.$initial_browser
                                : elt.person.properties.$browser
                            }}
                          </span>
                          <span class="elt--txt" v-else>
                            {{ elt.user_agent }}
                          </span>
                        </div> -->

                        <div class="elt-row max-width">
                          <span
                            style="white-space: nowrap"
                            class="elt--txt"
                            v-if="eventSessionType !== 'mobile'"
                          >
                            {{ formatDate(elt.start_time) }}/<br />
                            {{ formatHours(elt.start_time) }}
                          </span>
                          <span
                            style="white-space: nowrap"
                            class="elt--txt"
                            v-else
                          >
                            {{ formatDate(elt.session_created_at) }}
                          </span>
                        </div>

                        <div class="elt-row" style="margin-left: 50%">
                          <span
                            class="test-time-cont"
                            v-if="eventSessionType !== 'mobile'"
                          >
                            <!-- <i class="far fa-clock mr-1"></i> -->
                            <!-- {{ secondsToRealTime(elt.recording_duration) }} -->
                            {{
                              elt.step_count !== undefined &&
                              elt.step_count !== null &&
                              elt.step_count !== 0
                                ? elt.step_count
                                : 10
                            }}
                          </span>
                          <span
                            style="overflow: visible; display: inline"
                            class="test-time-cont"
                            v-else
                          >
                            <!-- <i class="far fa-clock mr-1"></i> -->
                            {{ secondsToRealTime(elt.session_duration) }} /
                            {{ elt.event_count }}
                          </span>
                        </div>
                        <div
                          class="flex max-width"
                          style="gap: 5px; justify-content: center"
                        >
                          <!-- <el-tooltip
                            v-if="eventSessionType !== 'mobile'"
                            class="item"
                            effect="light"
                            content="Sauce cucumber run"
                            placement="top"
                          >
                            <el-button
                              @click.stop="processSouceCucumberRun(elt)"
                              class="table-buttons-custom"
                              type="danger"
                              size="small"
                              > <img src="/img/integrations/Saucelabs.png" style="width: 24px; height: 24px;" /> 
                              </el-button>
                          </el-tooltip> -->
                          <el-tooltip
                            v-if="eventSessionType !== 'mobile'"
                            class="item"
                            effect="light"
                            content="Steps"
                            placement="top"
                          >
                            <el-button
                              @click.stop="showStepsList(elt)"
                              class="table-buttons-custom"
                              type="danger"
                              size="small"
                              ><span class="list-ul-icon"></span
                            ></el-button>
                          </el-tooltip>
                          <!-- <el-tooltip v-if="eventSessionType !== 'mobile'" class="item" effect="light" content="Api call"
                          placement="top">
                          <el-button class="table-buttons-custom" type="info"  @click.stop="showApiCalls(elt.id)"  
                            size="small"><i class="ni-file-search"></i></el-button>
                        </el-tooltip> -->
                          <el-tooltip
                            v-if="eventSessionType !== 'mobile'"
                            class="item"
                            effect="light"
                            content="Delete session"
                            placement="top"
                          >
                            <el-button
                              class="table-buttons-custom"
                              type="danger"
                              @click.stop="deleteSesson(elt.id)"
                              size="small"
                              ><span class="trash-icon"></span
                            ></el-button>
                          </el-tooltip>
                          <el-tooltip
                            v-if="eventSessionType !== 'mobile'"
                            class="item"
                            effect="light"
                            content="Add Fixture"
                            placement="top"
                          >
                            <el-button
                              v-if="elt?.customjson.session_type !== 'api'"
                              class="table-buttons-custom"
                              type="danger"
                              @click.stop="openFixtureAccordion(elt, id)"
                              size="small"
                              ><span class="tag-icon"></span
                            ></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </data-list>
            <el-pagination
              v-if="!loadingSession && filteredResultsSessionAPI.length > 0"
              background
              layout="prev, pager, next"
              :total="totalSession"
              :page-size="paginationSession.perPage"
              :page-sizes="paginationSession.perPageOptions"
              :current-page.sync="paginationSession.currentPage"
              @current-change="getSessions"
            >
            </el-pagination>
          </div>
          <div v-if="callApi" class="eff--page">
            <data-list
              :tHeader="tHeaderCllApi"
              :loading="loadingSessionCallApi"
              :items="toShowSessionCallApi.sessions"
              :customSizes="true"
              ref="dataList"
            >
              <template #content>
                <div
                  v-for="(elt, id) in toShowSessionCallApi.sessions"
                  :key="id"
                  class="tblb--row mb-2"
                  @click="showApiCalls(elt.session_uid)"
                >
                  <div class="tblb--inner">
                    <div class="elt-row" style="width: 5%">
                      <span class="test-run-name">
                        {{ forStepNumberRun(id) }}
                      </span>
                    </div>

                    <div class="elt-row" style="width: 19%">
                      <el-tooltip
                        class="item"
                        effect="light"
                        :content="elt.first_origin_pageurl"
                        placement="top"
                      >
                        <span class="test-run-name">
                          {{ elt.first_origin_pageurl }} </span
                        >F
                      </el-tooltip>
                    </div>
                    <div class="elt-row" style="width: 19%">
                      <el-tooltip
                        class="item"
                        effect="light"
                        :content="elt.last_origin_pageurl"
                        placement="top"
                      >
                        <span class="test-run-name">
                          {{ elt.last_origin_pageurl }}
                        </span>
                      </el-tooltip>
                    </div>

                    <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{ elt.successful_calls }}
                      </span>
                    </div>

                    <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{ elt.failed_calls }}
                      </span>
                    </div>

                    <div class="elt-row" style="width: 9%">
                      <span class="elt--value">
                        {{ elt.total_calls }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 15%">
                      <span class="elt--txt">
                        {{ formatDate(elt.session_created_at) }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 15%">
                      <span class="elt--txt">
                        {{
                          +(
                            Math.round(
                              elt.resptime_average.toFixed(2) + "e+2"
                            ) + "e-2"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
            <el-button-group>
              <el-button
                style="padding: 12px 20px"
                class="custom-pag-btn"
                type="primary"
                icon="el-icon-arrow-left"
                :disabled="toShowSessionCallApi.currentPage === 1"
                @click="handleCurrentChangeCallApi('Previous')"
              >
                Previous
              </el-button>
              <el-button
                style="padding: 12px 20px"
                class="custom-pag-btn"
                type="primary"
                @click="handleCurrentChangeCallApi('next')"
                :disabled="
                  toShowSessionCallApi.currentPage ===
                  toShowSessionCallApi.totalPages
                "
              >
                Next
                <i class="el-icon-arrow-right el-icon-right"></i>
              </el-button>
            </el-button-group>
          </div>
        </template>
        <template v-if="showStart">
          <div>
            <div class="mid--page_container">
              <div class="mid--page pb-3" style="border: none">
                <div class="container--start">
                  <div class="left--side-container">
                    <div class="text--start">
                      <div class="desc-header">
                        <div>
                          <h3 class="desc-lets-start-wring">
                            {{ $t("page_start.desc_first") }}
                          </h3>
                        </div>
                        <div
                          style="display: flex; justify-content: space-between"
                        >
                          <div style="display: flex; gap: 30px">
                            <img
                              width="66px"
                              height="50px"
                              src="/img/brand/wring_black.png"
                            />
                            <h3 class="desc-use-wring">
                              {{ $t("page_start.desc_second") }}
                            </h3>
                          </div>
                          <!-- <div class="glean-box">
                            <div class="glean-container-start-page">
                              <div style="background: #4f5170">
                                <img
                                  width="32px"
                                  height="32px"
                                  src="/img/brand/logo_wring.svg"
                                />
                              </div>
                              <div><span class="folder-icon"></span></div>
                              <div><span class="play-icon"></span></div>
                              <div class="last-glean-container">
                                <span class="stop-icon"></span>
                              </div>
                            </div>
                            <h3 class="decs-glean-text">
                              Leverage Glean: it generates videos from observing
                              your activity
                            </h3>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin: 16px 0 12px 0;
                      "
                    >
                      <div>
                        <h3 class="header-desc-steps">Steps to start</h3>
                        <ul class="steps-list-start">
                          <li class="custom--card">
                            <h3 class="medium-text">
                              {{ $t("page_start.text_card_first") }} <br />
                              <!-- {{ $t("page_start.text_card_sub") }} -->
                            </h3>
                          </li>
                          <li class="custom--card">
                            <h3 class="medium-text">
                              {{ $t("page_start.text_card_second") }}
                            </h3>
                          </li>
                          <li class="custom--card">
                            <h3 class="medium-text">
                              {{ $t("page_start.text_card_third") }}
                            </h3>
                          </li>
                        </ul>
                      </div>
                      <div class="right--side-container">
                        <div>
                          <div>
                            <h3 class="header-desc-steps">
                              {{ $t("page_start.progress_tracker") }}
                            </h3>
                            <div class="check-boxes">
                              <el-checkbox
                                text-color="#1F213E"
                                fill="#1F213E"
                                v-model.lazy="checked1"
                                ><h3 class="small-text">
                                  {{ $t("page_start.checked1") }}
                                </h3></el-checkbox
                              >
                              <!-- <el-checkbox v-model="checked2"
                                ><h3 class="small-text">
                                  {{ $t("page_start.checked2") }}
                                </h3></el-checkbox
                              > -->
                              <el-checkbox v-model="checked3"
                                ><h3 class="small-text">
                                  {{ $t("page_start.checked3") }}
                                </h3></el-checkbox
                              >
                              <el-checkbox v-model="checked4"
                                ><h3 class="small-text">
                                  {{ $t("page_start.checked4") }}
                                </h3></el-checkbox
                              >
                              <!-- <el-checkbox v-model="checked5"
                                ><h3 class="small-text">s
                                  {{ $t("page_start.checked5") }}
                                </h3></el-checkbox
                              > -->
                              <!-- <el-checkbox v-model="checked6"
                                ><h3 class="small-text">
                                  {{ $t("page_start.checked6") }}
                                </h3></el-checkbox
                              > -->
                            </div>
                            <el-progress
                              type="circle"
                              :percentage="progressBar()"
                              :color="colors"
                            ></el-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showTestSuit">
          <div class="mid--page_container" style="overflow-y: scroll">
            <div
              class="mid--page"
              style="border: none; justify-content: center"
            >
              <div class="mp--l"></div>
            </div>
          </div>
          <div style="padding-top: 20px">
            <div
              class="test-info-section"
              v-for="(elt, id) in toShow"
              :key="id"
              @click="showFileTest(elt)"
              :disabled="
                keyTestFile[0] && keyTestFile[0] != 'null' ? false : true
              "
            >
              <div class="section-flex">
                <div class="section">
                  <h3>Name:</h3>
                  <span class="test-run-name">{{ elt.name }}</span>
                  <el-tooltip
                    v-if="!elt.shared || elt.shared != true"
                    id="listItemPageTableEditTestBtn"
                    class="item"
                    effect="light"
                    content="Edit"
                    placement="top"
                  >
                    <el-button
                      style="
                        padding: 5px 5px;
                        border: 0px solid #1f213e;
                        color: #1f213e;
                        background-color: #e6e6e6;
                      "
                      icon="ni-edit"
                      @click.stop="editTestModal(elt)"
                      plain
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="sheduel-btns">
                  <el-tooltip
                    v-if="showTestSuit"
                    class="item"
                    effect="light"
                    content="Schedule test"
                    placement="top"
                  >
                    <button
                      icon="el-icon-alarm-clock"
                      id="btn-schedule"
                      class="btn-sheduel"
                      @click.stop="isshowShed"
                    >
                      {{ showShed ? "Tests" : "Schedule" }}
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    v-if="showTestSuit"
                    id="btn-run--suite"
                    class="item"
                    effect="light"
                    content="Run Test Suite"
                    placement="top"
                  >
                    <button
                      class="btn-sheduel"
                      icon="fa fa-play"
                      @click.stop="runAllTest()"
                      :disabled="
                        keyTestFile[0] && keyTestFile[0] != 'null'
                          ? false
                          : true
                      "
                    >
                      Run Test
                    </button>
                  </el-tooltip>
                </div>
              </div>
              <div class="section">
                <span>
                  <h3>Tags:</h3>
                  {{ elt.description }}
                </span>

                <span v-if="elt.tags" class="run-tags-cont">
                  <span
                    style="margin: 0"
                    class="run-tags"
                    v-for="(tags, index) in elt.tags"
                    :key="index"
                  >
                    {{ tags }}
                  </span>
                </span>
              </div>
              <div class="section">
                <h3>Status:</h3>
                <el-alert
                  style="
                    display: flex;
                    align-items: center;
                    max-width: 100px;
                    justify-content: center;
                    background-color: #e6ae6c;
                  "
                  class="no--close"
                  :title="elt.isActive === true ? 'Active' : 'Disabled'"
                  :type="
                    elt.isActive === true
                      ? 'success'
                      : elt.isActive === false
                      ? 'danger'
                      : 'danger'
                  "
                  show-icon
                ></el-alert>
              </div>
              <div class="section">
                <h3>Created:</h3>
                <span class="elt--txt">
                  {{ formatDate(elt.acceptedAt) }}
                </span>
              </div>
              <div class="section-flex">
                <div class="section">
                  <h3>Total File:</h3>
                  <span
                    style="
                      color: #1f213e;
                      text-align: center;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                    "
                    class="text-value"
                  >
                    {{ fileAppAtt.length }}
                  </span>
                  <el-tooltip
                    id="listItemPageTableRerunTestBtn"
                    class="item"
                    effect="light"
                    content="Add file"
                    placement="top"
                  >
                    <el-button
                      icon="el-icon-plus"
                      id="add-file--modal"
                      @click.stop="addFileModal(elt)"
                      style="
                        padding: 5px 5px;
                        border: 1px solid #1f213e;
                        color: #1f213e;
                        background-color: #e6e6e6;
                      "
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>

            <h3 v-if="!showShed">Runs</h3>
            <h3 v-else>Schedule</h3>
            <div>
              <scheduled-run
                v-if="showShed"
                id="scheduled-run"
                ref="scheduledRun"
                :scriptFiles="scriptFiles"
              ></scheduled-run>
            </div>
            <data-list
              v-if="!showShed"
              class="custom-tbl-overflow"
              id="runs-test--suite"
              v-show="isRunTestData"
              :loading="loadingTestDelete"
              :items="detailTest"
              :customSizes="true"
              ref="dataList"
            >
              <template #content>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;
                    text-align: center;
                  "
                  class="tblb--row header-custom"
                >
                  <div class="flex btns-gap justify-center">
                    <h3>NAME</h3>
                    <div
                      @click="sortList('name')"
                      :class="
                        sortedBy == 'name' ? 'enable-sort' : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['name']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>

                  <div class="flex btns-gap justify-center">
                    <h3>TAGS</h3>
                    <div
                      @click="sortList('tags')"
                      :class="
                        sortedBy == 'tags' ? 'enable-sort' : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['tags']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="flex btns-gap justify-center">
                    <h3>STATUS</h3>
                    <div
                      @click="sortList('status')"
                      :class="
                        sortedBy == 'status' ? 'enable-sort' : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['status']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="flex btns-gap justify-center">
                    <h3>MESSAGE</h3>
                    <div
                      @click="sortList('message')"
                      :class="
                        sortedBy == 'message' ? 'enable-sort' : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['message']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="flex btns-gap justify-center">
                    <h3>CREATED</h3>
                    <div
                      @click="sortList('acceptedAt')"
                      :class="
                        sortedBy == 'acceptedAt'
                          ? 'enable-sort'
                          : 'disable-sort'
                      "
                    >
                      <i
                        :class="
                          isReversed['acceptedAt']
                            ? 'arrow-sort-up'
                            : 'arrow-sort-down'
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  class="tblb--row mb-2"
                  v-for="(elt, id) in sortedToShow"
                  :key="elt.id"
                  @click="_onRowClicked(keyTest[id])"
                >
                  <div
                    class="tblb--inner"
                    style="
                      display: grid;
                      grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;
                      text-align: center;
                    "
                  >
                    <div class="elt-row max-width" style="overflow: visible">
                      <span class="test-run-name">{{ elt.name }}</span>
                      <br />
                    </div>
                    <div class="elt-row max-width" style="overflow: visible">
                      <span>
                        {{ elt.description }}
                      </span>

                      <span v-if="elt.tags" class="run-tags-cont">
                        <span
                          class="run-tags"
                          v-for="(tags, index) in elt.tags"
                          :key="index"
                        >
                          {{ tags }}
                        </span>
                      </span>
                    </div>
                    <div class="elt-row max-width">
                      <el-alert
                        class="no--close"
                        :title="elt.status"
                        :type="
                          elt.status === 'passed'
                            ? 'success'
                            : elt.status === 'failed'
                            ? 'error'
                            : 'danger'
                        "
                        show-icon
                      ></el-alert>
                    </div>
                    <div
                      class="elt-row max-width"
                      style="overflow: hidden; text-overflow: ellipsis"
                    >
                      <span style="overflow: hidden; text-overflow: ellipsis">
                        {{ elt.description }}
                      </span>

                      <span class="run-tags-cont">
                        <span
                          style="overflow: hidden; text-overflow: ellipsis"
                          class="run-tags max-width"
                        >
                          {{ elt.message }}
                        </span>
                      </span>
                    </div>
                    <div class="elt-row max-width">
                      <span class="elt--txt">
                        {{ formatDate(elt.acceptedAt) }}
                      </span>
                    </div>
                    <div
                      class="elt-row max-width"
                      style="overflow: visible; text-align: center"
                    >
                      <el-tooltip
                        class="item"
                        effect="light"
                        content="Delete Test Suite Run"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="deleteTestSuitRun(elt, keyTest[id])"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                      <!-- <el-tooltip class="item" effect="light" content="Download  file" placement="top">
                        <el-button type="info" icon="el-icon-download" circle plain size="small" @click.stop="getListFileTestSuit(elt,keyTest[id])"></el-button>
                      </el-tooltip> -->
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
          </div>
        </template>
        <template v-if="showAppMap">
          <div
            v-if="showUpdatingBar"
            v-loading="loadingUpdatingBar"
            class="updating-modal"
          >
            <el-steps class="updating-steps" :active="activeSteps" align-center>
              <el-step
                title="Step 1"
                description="Retrieving sessions data..."
              ></el-step>
              <el-step
                title="Step 2"
                description="Processing user flows..."
              ></el-step>
              <el-step
                title="Step 3"
                description="Grabbing page screenshots..."
              ></el-step>
              <el-step
                title="Step 4"
                description="Finding keyflows..."
              ></el-step>
              <el-step
                title="Step 5"
                description="Labeling keyflows..."
              ></el-step>
              <el-step
                title="Step 6"
                description="Clustering users..."
              ></el-step>
              <el-step
                title="Step 7"
                description="Calculating page maps..."
              ></el-step>
              <el-step
                title="Step 8"
                description="Running exploration agent..."
              ></el-step>
            </el-steps>
          </div>
          <div v-loading="loadingShowAppMap">
            <!-- <el-tag type="info" class="appmap-label" effect="plain" v-if="!isAppMapEmpty && !loadingShowAppMap">
              <i class="el-icon-time"></i> Updated:
              {{ formatDateToLocalTime(allDataAppMap.created_at) }}
            </el-tag> -->
            <div id="wring-graph" class="wring-graph">
              <div
                v-if="Object.entries(toShowAppMap).length !== 0"
                class="graph"
              >
                <div
                  class="header-appmap"
                  :class="isFlowMenu ? '' : 'close-menu'"
                >
                  <div class="pickup-keyflow-container">
                    <h3 class="label-selectors-appmap">Pick a key flow</h3>
                    <el-select
                      id="key_flow_select"
                      v-model="selectKeyFlow"
                      :loading="loadingShowAppMap"
                      loading-text="loading ..."
                      no-data-text="no key flow found "
                      @input="changeKeyFlow()"
                      placeholder="Choose"
                      class="custom--select custom-keyflow-select"
                    >
                      <el-option
                        label="ALL"
                        value="ALL"
                        class="default--label"
                      ></el-option>
                      <el-option
                        v-for="(item, id) in toShowKeyFlows"
                        :key="id"
                        :label="
                          item.label +
                          ` (${(item.users_pct_norm * 100).toFixed(2)}%)`
                        "
                        :value="id"
                      >
                      </el-option>
                    </el-select>

                    <div class="modal-test-btns">
                      <el-tooltip
                        class=""
                        effect="light"
                        :content="`${
                          selectKeyFlowPathUser
                            ? 'Add Key Flow to Test Suite'
                            : 'Please select a Key Flow'
                        }`"
                        placement="top"
                      >
                        <el-button
                          icon="fa el-icon-plus"
                          size="small"
                          id="test--btn"
                          class="custom_btn_settings add_btn--set button-menu"
                          style="width: 100%; max-width: 126px"
                          @click="selectKeyFlowPathUser ? keyFlowTesSuit() : ''"
                          ><span style="font-size: 14px; font-weight: 600"
                            >Test</span
                          >
                        </el-button>
                      </el-tooltip>
                      <el-button
                        class="custom_btn_settings add_btn--set button-menu mt-0 mb-3"
                        id="model--btn"
                        :icon="
                          loadingDai == false
                            ? 'el-icon-download'
                            : 'el-icon-loading'
                        "
                        size="small"
                        @click="downloadDai()"
                        style="width: 100%; max-width: 126px"
                      >
                        Model
                      </el-button>
                    </div>
                  </div>
                  <div class="pick-date-container">
                    <h3 class="label-selectors-appmap">Choose flow version</h3>
                    <el-select
                      id="date_map_select"
                      v-model="selectDateAppmap"
                      :loading="loadingShowAppMap"
                      loading-text="loading ..."
                      no-data-text="no date appMap found "
                      @input="changeDateAppMap()"
                      placeholder="Select a date"
                      class="custom-select--date"
                    >
                      <div v-for="(item, id) in toShowDateAppMap" :key="id">
                        <el-option
                          v-if="item.status === 'FINISHED'"
                          :label="formatDateAppMap(item.created_date)"
                          :value="id"
                        >
                        </el-option>
                      </div>
                    </el-select>
                    <img
                      v-if="videoLoading"
                      src="/img/loading.gif"
                      style="height: 24px; width: 24px"
                    />
                    <el-tooltip
                      id="btn-run--suite"
                      class="item"
                      effect="light"
                      content="Run Test Suite"
                      placement="top"
                    >
                      <el-button
                        v-if="keyFlowSnapshot && videoLoading == false"
                        @click="getKeyFlowVideo()"
                        class="mt-2 play-flow"
                        icon="fa fa-play"
                      >
                      </el-button>
                    </el-tooltip>
                  </div>
                  <div class="header-appmap-btns">
                    <el-button
                      id="btn-update--appmap"
                      class="custom_btn--refresh"
                      @click="refreshMap()"
                    >
                      <i
                        v-if="loadindRefresh === false"
                        class="el-icon-refresh-left"
                      ></i>
                      <i v-else class="el-icon-loading"></i>
                    </el-button>
                    <button
                      id="shitcher-appmap"
                      @click="showTestGraph = !showTestGraph"
                      class="switcher"
                    >
                      <svg
                        style="fill: #1f213e"
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        width="20px"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <test-graph
                  v-if="showTestGraph && !fullSize"
                  :items="toShowAppMap"
                  :nameApp="projectDetail.name"
                  :highlight_edges="highlights"
                  ref="test-graph"
                  :style="clientHeight"
                  id="app-map"
                >
                </test-graph>
                <app-map
                  v-else-if="!showTestGraph && !fullSize"
                  ref="app-map"
                  id="app-map"
                ></app-map>
              </div>
              <div
                class="mt-7"
                v-else-if="!loadingShowAppMap"
                style="text-align: center"
              >
                Start adding sessions to create an appmap
              </div>
              <div
                id="imgModal"
                class="modal"
                style="backdrop-filter: blur(2px)"
              >
                <span
                  class="close"
                  id="close-img"
                  style="
                    z-index: 19;
                    cursor: pointer;
                    top: 8%;
                    right: 15%;
                    position: relative;
                    font-size: 40px;
                  "
                  @click="closeModalScreenshots"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20 4L4 20M20 20L4 4"
                      stroke="#1F213E"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                  ></span
                >
                <div id="caption"></div>
                <!-- <el-button style="z-index: 19" class="btn-graph btn-graph--show" @click="showMapScreen()">Show Details</el-button>
                  <el-button style="z-index: 19" class="btn-graph btn-graph--clear" @click="clearMapScreen()">Clear Details</el-button> -->
                <div
                  v-if="showedMapScreen"
                  id="ss-container"
                  style="position: absolute; top: 15%; left: 15%"
                >
                  <div v-for="(item, id) in rect" :key="id">
                    <el-tooltip
                      effect="light"
                      :content="
                        item.boundingBox.text +
                        ` 'Transitions:' ` +
                        item.boundingBox.interactions_count
                      "
                      placement="top"
                    >
                      <appMapSimItem
                        id="remove--old"
                        ref="ss_remove"
                        :key="id"
                        :rect="item.boundingBox"
                      ></appMapSimItem>
                    </el-tooltip>
                  </div>
                </div>
                <!-- <div v-for="(item, id) in rectNodeData" :key="id" class="simItemBB" :style="cssVars"></div> -->
                <div style="width: 100%; height: 100%; position: absolute">
                  <!-- <div class="heatmap-container" ref="heatmap" style="position:absolute;width:100%;height:100%;"></div> -->
                  <img
                    class="modal-content"
                    id="screenshot"
                    ref="ss_img"
                    style="width: 70%; top: 15%; left: 15%; max-height: 80vh"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showApi">
          <div class="mid--page_container">
            <div class="mid--page" style="border: none; width: 100%">
              <div class="column_custom">
                <!-- <div class="page_name_section">
                  <h2 style="margin-left:18px" class="page_name">API</h2>
                  <div class="c--breadcrumb">
          <router-link to="/">
            <svg style="margin-right: 5px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="home icon">
            <path id="Icon" d="M9 21V14.1528C9 13.5226 9.53726 13.0116 10.2 13.0116H13.8C14.4627 13.0116 15 13.5226 15 14.1528V21M11.3046 3.21117L3.50457 8.48603C3.18802 8.7001 3 9.04665 3 9.41605V19.2882C3 20.2336 3.80589 21 4.8 21H19.2C20.1941 21 21 20.2336 21 19.2882V9.41605C21 9.04665 20.812 8.70011 20.4954 8.48603L12.6954 3.21117C12.2791 2.92961 11.7209 2.92961 11.3046 3.21117Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round"/>
            </g></svg>
            </router-link>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chevron-double-left icon">
            <path id="Icon" d="M13.4 5.80018L19 11.4002L13.4 17.0002M5 5.80018L10.6 11.4002L5 17.0002" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
              </svg>
                    <span class="text-near-arrow" style="color: #8F8F8F ; font-size: 14px; font-weight: 500; margin-left: 5px"> Projects</span>
                  </div>
                </div>
                <div class="custom_btns">
            <button v-if="isAppMapEmpty" @click="showComponent('start')" class="add_button"><p class="menu-text">Start</p></button>
            <button :class="this.showAppMap ? '' : 'add_button_not_active' " @click="showComponent('appmap')" class="add_button"><p class="menu-text">App Map</p></button>
            <button :class="this.showEvent ? '' : 'add_button_not_active' " @click="showComponent('event')" class="add_button"><p class="menu-text">Replay</p></button>
            <button :class="this.showApi ? '' : 'add_button_not_active' " @click="showComponent('api')" class="add_button"><p class="menu-text">API</p></button>
            <button :class="this.showTestSuit ? '' : 'add_button_not_active' " @click="showComponent('testsuite')" class="add_button"><p class="menu-text">Test Suite</p></button>
          </div> -->
                <!-- <el-tooltip v-if="eventSessionType === 'mobile'" class="item" effect="light" content="Mobile sessions"
              placement="top">
              <el-button @click="showComponent('event')" icon="el-icon-film" class="small_btn button-menu"
                :class="{ 'bg-primary active': showEvent }">
              </el-button>
            </el-tooltip> -->

                <div
                  class="container--api-settings"
                  v-if="showApi"
                  style="text-align: left !important; display: none"
                >
                  <el-divider></el-divider>
                  <el-collapse
                    class="adpt-card--filter"
                    v-if="mobileResolution"
                    v-model="activeFilter"
                  >
                    <el-collapse-item title="Filters" class="truncate" name="1">
                      <el-card class="box-card">
                        <div slot="header" class="clearfix">
                          <span style="font-weight: 600">
                            <i class="fa fa-filter"></i> Filters</span
                          >
                          <el-button
                            style="float: right"
                            type="danger"
                            plain
                            size="mini"
                            @click="clearFilter"
                            >Clear <i class="el-icon-close"></i
                          ></el-button>
                        </div>
                        <el-collapse v-model="activeNames">
                          <el-collapse-item
                            title="Time range"
                            class="truncate"
                            name="5"
                          >
                            <div class="is-flex mb-4">
                              <el-button size="mini" @click="getApiCall">
                                Update
                              </el-button>
                              <div class="custom-date-div"></div>
                              <el-button
                                size="mini"
                                type="primary"
                                plain
                                @click="customDate = !customDate"
                              >
                                {{
                                  customDate === false ? "Custom" : "Default"
                                }}</el-button
                              >
                            </div>
                            <div
                              v-if="customDate === false"
                              style="width: 100%"
                            >
                              <el-select
                                v-model="selectTime"
                                style="height: 35px !important"
                                placeholder="select a time period"
                                @change="setByFilter"
                              >
                                <el-option
                                  v-for="(item, id) in listTime"
                                  :key="id"
                                  :label="item.label"
                                  :value="item.value"
                                ></el-option>
                              </el-select>
                            </div>
                            <div style="display: inline-grid" v-else>
                              <el-date-picker
                                @change="setByFilter"
                                class="mb-2"
                                style="height: 35px; width: 100%"
                                v-model="datePikerStart"
                                :disabledDate="disabledDateStart"
                                :picker-options="disabledDateStart"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="Start"
                              >
                              </el-date-picker>
                              <el-date-picker
                                @change="setByFilter"
                                style="height: 35px; width: 100%"
                                v-model="datePikerEnd"
                                @focus="disabledDate"
                                :disabledDate="disabledDate"
                                :picker-options="disabledDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="End"
                              >
                              </el-date-picker>
                            </div>
                          </el-collapse-item>
                          <el-collapse-item
                            title="Status code"
                            class="truncate"
                            name="2"
                          >
                            <div
                              v-for="(n, id) in statusArray"
                              :key="id"
                              class="flex"
                              style="
                                border: none;
                                justify-content: space-between;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  @change="selectStatus(id)"
                                  v-model="n.status"
                                ></el-checkbox>
                                <el-tag
                                  :type="getCodeType(n.value)"
                                  size="small"
                                  class="tag-virsz ml-2"
                                >
                                  {{ n.type }}
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p>{{ n.total }}</p>
                              </div>
                            </div>
                          </el-collapse-item>
                          <el-collapse-item
                            title="HTTP Methods (5)"
                            class="truncate"
                            name="3"
                          >
                            <template slot="title">
                              HTTP Methods <span class="ml-1 danger"></span>
                            </template>
                            <div class="http-section">
                              <div
                                class="flex"
                                style="
                                  border: none;
                                  justify-content: space-between;
                                  align-items: center;
                                "
                              >
                                <div class="get-post-put">
                                  <div class="flex">
                                    <el-checkbox
                                      v-model="checkedGet"
                                      @change="getApiCall()"
                                    ></el-checkbox>
                                    <el-tag
                                      type="success"
                                      size="small"
                                      class="tag-virsz ml-2"
                                      effect="dark"
                                    >
                                      Get
                                    </el-tag>
                                  </div>
                                  <div class="mp--r">
                                    <p>{{ checkedGetValue }}</p>
                                  </div>
                                </div>
                                <div
                                  class="flex"
                                  style="
                                    border: none;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                >
                                  <div class="flex">
                                    <el-checkbox
                                      v-model="checkedPost"
                                      @change="getApiCall()"
                                    ></el-checkbox>
                                    <el-tag
                                      size="small"
                                      class="tag-virsz ml-2"
                                      effect="dark"
                                    >
                                      Post
                                    </el-tag>
                                  </div>
                                  <div class="mp--r">
                                    <p>{{ checkedPostValue }}</p>
                                  </div>
                                </div>
                                <div
                                  class="flex"
                                  style="
                                    border: none;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                >
                                  <div class="flex">
                                    <el-checkbox
                                      v-model="checkedPut"
                                      @change="getApiCall()"
                                    ></el-checkbox>
                                    <el-tag
                                      type="warning"
                                      size="small"
                                      class="tag-virsz ml-2"
                                      effect="dark"
                                    >
                                      Put
                                    </el-tag>
                                  </div>
                                </div>
                                <div class="delete-patch">
                                  <div class="mp--r">
                                    <p>{{ checkedPutValue }}</p>
                                  </div>
                                </div>
                                <div
                                  class="flex"
                                  style="
                                    border: none;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                >
                                  <div class="flex">
                                    <el-checkbox
                                      v-model="checkedDelete"
                                      @change="getApiCall()"
                                    ></el-checkbox>
                                    <el-tag
                                      type="danger"
                                      size="small"
                                      class="tag-virsz ml-2"
                                      effect="dark"
                                    >
                                      Delete
                                    </el-tag>
                                  </div>
                                  <div class="mp--r">
                                    <p>{{ checkedDeleteValue }}</p>
                                  </div>
                                </div>
                                <div
                                  class="flex"
                                  style="
                                    border: none;
                                    justify-content: space-between;
                                    align-items: center;
                                  "
                                >
                                  <div class="flex">
                                    <el-checkbox
                                      v-model="checkedPatch"
                                      @change="getApiCall()"
                                    ></el-checkbox>
                                    <el-tag
                                      type="info"
                                      size="small"
                                      class="tag-virsz ml-2"
                                      effect="dark"
                                    >
                                      Patch
                                    </el-tag>
                                  </div>
                                </div>
                              </div>
                              <div class="mp--r">
                                <p>{{ checkedPatchValue }}</p>
                              </div>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </el-card>
                    </el-collapse-item>
                  </el-collapse>
                  <el-card class="box-card" v-if="!mobileResolution">
                    <div slot="header" class="clearfix">
                      <span style="font-weight: 600">
                        <i class="fa fa-filter"></i> Filters</span
                      >
                      <el-button
                        style="float: right"
                        type="danger"
                        plain
                        size="mini"
                        @click="clearFilter"
                        >Clear <i class="el-icon-close"></i
                      ></el-button>
                    </div>
                    <el-collapse v-model="activeNames">
                      <el-collapse-item
                        title="Time range"
                        class="truncate"
                        name="5"
                      >
                        <div class="is-flex mb-4">
                          <el-button size="mini" @click="getApiCall">
                            Update
                          </el-button>
                          <el-button
                            size="mini"
                            type="primary"
                            plain
                            @click="customDate = !customDate"
                          >
                            {{
                              customDate === false ? "Custom" : "Default"
                            }}</el-button
                          >
                        </div>
                        <div v-if="customDate === false" style="width: 100%">
                          <el-select
                            v-model="selectTime"
                            style="height: 35px !important"
                            placeholder="select a time period"
                            @change="setByFilter"
                          >
                            <el-option
                              v-for="(item, id) in listTime"
                              :key="id"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </div>
                        <div style="display: inline-grid" v-else>
                          <el-date-picker
                            @change="setByFilter"
                            class="mb-2"
                            style="height: 35px; width: 100%"
                            v-model="datePikerStart"
                            :disabledDate="disabledDateStart"
                            :picker-options="disabledDateStart"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="Start"
                          >
                          </el-date-picker>
                          <el-date-picker
                            @change="setByFilter"
                            style="height: 35px; width: 100%"
                            v-model="datePikerEnd"
                            @focus="disabledDate"
                            :disabledDate="disabledDate"
                            :picker-options="disabledDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="End"
                          >
                          </el-date-picker>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item
                        title="Status code"
                        class="truncate"
                        name="2"
                      >
                        <div
                          v-for="(n, id) in statusArray"
                          :key="id"
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              @change="selectStatus(id)"
                              v-model="n.status"
                            ></el-checkbox>
                            <el-tag
                              :type="getCodeType(n.value)"
                              size="small"
                              class="tag-virsz ml-2"
                            >
                              {{ n.type }}
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ n.total }}</p>
                          </div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item
                        title="HTTP Methods (5)"
                        class="truncate"
                        name="3"
                      >
                        <template slot="title">
                          HTTP Methods <span class="ml-1 danger"> (5)</span>
                        </template>
                        <div
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              v-model="checkedGet"
                              @change="getApiCall()"
                            ></el-checkbox>
                            <el-tag
                              type="success"
                              size="small"
                              class="tag-virsz ml-2"
                              effect="dark"
                            >
                              Get
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ checkedGetValue }}</p>
                          </div>
                        </div>
                        <div
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              v-model="checkedPost"
                              @change="getApiCall()"
                            ></el-checkbox>
                            <el-tag
                              size="small"
                              class="tag-virsz ml-2"
                              effect="dark"
                            >
                              Post
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ checkedPostValue }}</p>
                          </div>
                        </div>
                        <div
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              v-model="checkedPut"
                              @change="getApiCall()"
                            ></el-checkbox>
                            <el-tag
                              type="warning"
                              size="small"
                              class="tag-virsz ml-2"
                              effect="dark"
                            >
                              Put
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ checkedPutValue }}</p>
                          </div>
                        </div>
                        <div
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              v-model="checkedDelete"
                              @change="getApiCall()"
                            ></el-checkbox>
                            <el-tag
                              type="danger"
                              size="small"
                              class="tag-virsz ml-2"
                              effect="dark"
                            >
                              Delete
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ checkedDeleteValue }}</p>
                          </div>
                        </div>

                        <div
                          class="flex"
                          style="
                            border: none;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex">
                            <el-checkbox
                              v-model="checkedPatch"
                              @change="getApiCall()"
                            ></el-checkbox>
                            <el-tag
                              type="info"
                              size="small"
                              class="tag-virsz ml-2"
                              effect="dark"
                            >
                              Patch
                            </el-tag>
                          </div>
                          <div class="mp--r">
                            <p>{{ checkedPatchValue }}</p>
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-card>
                </div>
              </div>
              <!-- <div class="mp--l">
                  <el-tag type="info" effect="plain" style="color:black;font-weight:600;font-size:14px;">
                    {{
                      totalCallCount == 0
                      ? "No API Calls"
                      : `${totalCallCount} API Calls`
                    }}
                    <i class="el-icon-time"></i>
                  </el-tag>
                  <el-tooltip effect="light" content="Select Api" placement="top" :disabled="!(checkedApi.length > 0)">
                    <div>
                      <el-button style="font-weight:600;font-size:14px;" class="ml-2 mr-2" slot="append" type="primary"
                        size="small" id="convert-api" :disabled="checkedApi.length > 0" @click="runApiTest('value')">
                        <i class="el-icon-plus"></i> Convert to API
                        Test</el-button>
                    </div>
                  </el-tooltip>
                  <el-button v-show="error.length > 0" class="ml-2" type="danger" plain size="small"
                    @click="showIssues = !showIssues">{{ showIssues == true ? 'Hide issues' : 'Show Issues' }} <i
                      class="el-icon-warning-outline"></i></el-button>
                </div>
                <div class="mp--r" style="flex-direction: row; align-items: center">
                  <span class="pag--details mr-3">{{
                    pagination.perPage
                    ? pagination.currentPage * pagination.perPage -
                    pagination.perPage +
                    1
                    : 1
                  }}
                    {{ $t("list_item_page.to") }}
                    {{
                      pagination.currentPage && pagination.perPage
                      ? pagination.currentPage * pagination.perPage
                      : 10
                    }}
                    {{ $t("list_item_page.of") }} {{ totalCallCount || 0 }}
                    {{ $t("list_item_page.entries") }}
                  </span>
                  <el-select v-model="pagination.perPage" @input="getApiCall" placeholder="Per page" class="small--select">
                    <el-option v-for="(item, id) in pagination.perPageOptions" :key="id" :label="item"
                      :value="item"></el-option>
                  </el-select>
                </div> -->
            </div>
            <div v-show="showIssues" class="container-alert">
              <el-alert
                :title="error.toString()"
                type="error"
                :closable="false"
                show-icon
              >
              </el-alert>
            </div>
            <!-- <div class="is-flex mt-2 adpt--flex">
                <el-tooltip effect="light" content="Add key" placement="top">
                  <div>
                    <el-button slot="append" type="primary" size="medium" :disabled="isKey" @click="handleUltraIpt('key')">
                      <i class="el-icon-key"></i> Key</el-button>
                  </div>
                </el-tooltip>
                <el-tooltip effect="light" content="Add value" placement="top">
                  <div>
                    <el-button class="ml-2 mr-2" slot="append" type="success" size="medium" :disabled="isValue"
                      @click="handleUltraIpt('value')">
                      <i class="el-icon-receiving"></i> Value</el-button>
                  </div>
                </el-tooltip>
                <el-tooltip effect="light" :disabled="!(isValue == false && isKey == false)" content="Select key or value "
                  placement="top">
                  <div class="ultra--ipt">
                    <div class="ultra--ipt_content pl-2" ref="data-rendered-detail"></div>
                    <input type="text" :disabled="isValue == false && isKey == false" class="ultra--ipt_ipt px-2"
                      placeholder="Custom filter" @input="handleUltraIpt" @change="handleUltraIpt"
                      @keyup.backspace="handleDeleteLastDataValue" v-model="ultraIpt" />
                  </div>
                </el-tooltip>
                <el-tooltip effect="light" content="Process filter" placement="top">
                  <div>
                    <el-button class="ml-2" slot="append" type="primary" size="medium" icon="el-icon-search"
                      @click="handleSubmitSearch"></el-button>
                  </div>
                </el-tooltip>
                <el-tooltip effect="light" content="Reset" placement="top">
                  <div>
                    <el-button class="ml-2" slot="append" type="danger" size="medium" plain @click="handleClearSearch">Clear
                      <i class="el-icon-close"></i></el-button>
                  </div>
                </el-tooltip>
              </div> -->
          </div>
          <div class="search_section">
            <div
              class="mp--l"
              style="margin-top: 16px; display: flex; gap: 14px"
            >
              <div class="ultra--ipt">
                <div
                  class="ultra--ipt_content"
                  ref="data-rendered-detail"
                ></div>
                <input
                  style="
                    padding-left: 52px;
                    border: 1px solid #cccccc;
                    border-radius: 10px;
                    color: #7d7d7d;
                  "
                  type="text"
                  :disabled="isValue == false && isKey == false"
                  class="ultra--ipt_ipt"
                  :placeholder="isValue || isKey ? '' : 'Search'"
                  :class="isValue || isKey ? 'activeUltraInput' : ''"
                  @input="handleUltraIpt"
                  @change="handleUltraIpt"
                  @keyup.backspace="handleDeleteLastDataValue"
                  v-model="ultraIpt"
                />
                <i
                  v-if="isValue == false && isKey == false"
                  slot="suffix"
                  class="icon_search"
                  style="transform: rotate(0deg) !important"
                  ><svg
                    style="position: absolute; right: 286px; bottom: -9px"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="search icon">
                      <path
                        id="Icon"
                        d="M16.927 17.04L20.4001 20.4M19.2801 11.44C19.2801 15.7699 15.77 19.28 11.4401 19.28C7.11019 19.28 3.6001 15.7699 3.6001 11.44C3.6001 7.11006 7.11019 3.59998 11.4401 3.59998C15.77 3.59998 19.2801 7.11006 19.2801 11.44Z"
                        stroke="#7D7D7D"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                </i>
              </div>

              <i @click="showFilters" style="margin-top: 7px; cursor: pointer"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6.46154 12H17.5385M4 7H20M10.1538 17H13.8462"
                    stroke="#1F213E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                ></i
              >
              <div style="display: flex">
                <div style="position: absolute; right: 185px; margin-top: 6px">
                  <el-tag
                    type="info"
                    effect="plain"
                    style="
                      background: #e6e6e6;
                      color: #1f213e;
                      border: 1px solid #e6e6e6;
                      font-weight: 600;
                      font-size: 14px;
                    "
                  >
                    {{
                      totalCallCount == 0
                        ? "No API Calls"
                        : `API Calls: ${totalCallCount}`
                    }}
                  </el-tag>
                </div>
                <div>
                  <h3
                    style="
                      position: absolute;
                      right: 108px;
                      margin-top: 12px;
                      color: #1f213e;
                      font-weight: 600;
                      font-size: 14px;
                    "
                  >
                    Show
                  </h3>
                  <el-select
                    style="position: absolute; right: 24px"
                    v-model="pagination.perPage"
                    @input="getApiCall"
                    placeholder="Per page"
                    class="small--select icon-pad"
                  >
                    <el-option
                      v-for="(item, id) in pagination.perPageOptions"
                      :key="id"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div
            :style="{ height: isShowFilters ? '56vh' : '56vh' }"
            style="overflow-y: scroll; overflow-x: hidden"
          >
            <div
              :class="isShowFilters ? '' : 'not-show-filter'"
              class="filter-section"
            >
              <div class="header-filter">
                <h3 class="subtitle-text">Filter</h3>
              </div>
              <div class="filter-body">
                <div style="width: 100%">
                  <div class="btns-kye-value">
                    <h3 class="small-text">Parameter</h3>
                    <button
                      slot="append"
                      :disabled="isKey"
                      @click="handleUltraIpt('key')"
                      class="add_button"
                    >
                      KEY
                    </button>
                    <button
                      slot="append"
                      :disabled="isValue"
                      @click="handleUltraIpt('value')"
                      class="add_button"
                    >
                      Value
                    </button>
                  </div>
                  <el-collapse
                    class="adpt-card--filter"
                    v-if="mobileResolution"
                    v-model="activeFilter"
                  >
                    <el-collapse-item title="Filters" class="truncate" name="1">
                      <div slot="header" class="clearfix">
                        <span style="font-weight: 600">
                          <i class="fa fa-filter"></i> Filters</span
                        >
                        <el-button
                          style="float: right"
                          type="danger"
                          plain
                          size="mini"
                          @click="clearFilter"
                          >Clear <i class="el-icon-close"></i
                        ></el-button>
                      </div>
                      <el-collapse v-model="activeNames">
                        <el-collapse-item
                          title="Time range"
                          class="truncate"
                          name="5"
                        >
                          <div class="is-flex mb-4">
                            <el-button size="mini" @click="getApiCall">
                              Update
                            </el-button>
                            <el-button
                              size="mini"
                              type="primary"
                              plain
                              @click="customDate = !customDate"
                            >
                              {{
                                customDate === false ? "Custom" : "Default"
                              }}</el-button
                            >
                          </div>
                          <div v-if="customDate === false" style="width: 100%">
                            <el-select
                              v-model="selectTime"
                              style="height: 35px !important"
                              placeholder="select a time period"
                              @change="setByFilter"
                            >
                              <el-option
                                v-for="(item, id) in listTime"
                                :key="id"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </div>
                          <div style="display: inline-grid" v-else>
                            <el-date-picker
                              @change="setByFilter"
                              class="mb-2"
                              style="height: 35px; width: 100%"
                              v-model="datePikerStart"
                              :disabledDate="disabledDateStart"
                              :picker-options="disabledDateStart"
                              value-format="yyyy-MM-dd"
                              type="date"
                              placeholder="Start"
                            >
                            </el-date-picker>
                            <el-date-picker
                              @change="setByFilter"
                              style="height: 35px; width: 100%"
                              v-model="datePikerEnd"
                              @focus="disabledDate"
                              :disabledDate="disabledDate"
                              :picker-options="disabledDate"
                              value-format="yyyy-MM-dd"
                              type="date"
                              placeholder="End"
                            >
                            </el-date-picker>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item
                          title="Status code"
                          class="truncate"
                          name="2"
                        >
                          <div
                            v-for="(n, id) in statusArray"
                            :key="id"
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                @change="selectStatus(id)"
                                v-model="n.status"
                              ></el-checkbox>
                              <el-tag
                                :type="getCodeType(n.value)"
                                size="small"
                                class="tag-virsz ml-2"
                              >
                                {{ n.type }}
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ n.total }}</p>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item
                          title="HTTP Methods (5)"
                          class="truncate"
                          name="3"
                        >
                          <template slot="title">
                            HTTP Methods <span class="ml-1 danger"> (5)</span>
                          </template>
                          <div
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                v-model="checkedGet"
                                @change="getApiCall()"
                              ></el-checkbox>
                              <el-tag
                                type="success"
                                size="small"
                                class="tag-virsz ml-2"
                                effect="dark"
                              >
                                Get
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ checkedGetValue }}</p>
                            </div>
                          </div>
                          <div
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                v-model="checkedPost"
                                @change="getApiCall()"
                              ></el-checkbox>
                              <el-tag
                                size="small"
                                class="tag-virsz ml-2"
                                effect="dark"
                              >
                                Post
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ checkedPostValue }}</p>
                            </div>
                          </div>
                          <div
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                v-model="checkedPut"
                                @change="getApiCall()"
                              ></el-checkbox>
                              <el-tag
                                type="warning"
                                size="small"
                                class="tag-virsz ml-2"
                                effect="dark"
                              >
                                Put
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ checkedPutValue }}</p>
                            </div>
                          </div>
                          <div
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                v-model="checkedDelete"
                                @change="getApiCall()"
                              ></el-checkbox>
                              <el-tag
                                type="danger"
                                size="small"
                                class="tag-virsz ml-2"
                                effect="dark"
                              >
                                Delete
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ checkedDeleteValue }}</p>
                            </div>
                          </div>
                          <div
                            class="flex"
                            style="
                              border: none;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="flex">
                              <el-checkbox
                                v-model="checkedPatch"
                                @change="getApiCall()"
                              ></el-checkbox>
                              <el-tag
                                type="info"
                                size="small"
                                class="tag-virsz ml-2"
                                effect="dark"
                              >
                                Patch
                              </el-tag>
                            </div>
                            <div class="mp--r">
                              <p>{{ checkedPatchValue }}</p>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-collapse-item>
                  </el-collapse>
                  <el-card
                    style="
                      background: #dbdbdb !important;
                      margin-left: -10px;
                      border: 1px solid #dbdbdb !important;
                      display: flex;
                      width: 80%;
                    "
                    class="box-card"
                    v-if="!mobileResolution"
                  >
                    <el-collapse v-model="activeNames">
                      <el-collapse-item
                        title="Time range"
                        class="truncate"
                        name="5"
                      >
                        <div class="date-picker-container">
                          <div style="display: flex; gap: 12px">
                            <button class="add_button" @click="getApiCall">
                              Update
                            </button>
                            <h3 style="margin-top: 8px">Show last</h3>
                            <el-select
                              :disabled="customDate"
                              class="select-data"
                              v-model="selectTime"
                              placeholder=""
                              @change="setByFilter"
                            >
                              <el-option
                                v-for="(item, id) in listTime"
                                :key="id"
                                :label="item.value"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                            <h3 style="margin-top: 8px">days</h3>
                          </div>

                          <div class="flex">
                            <div class="custom-date-div">
                              <el-checkbox
                                class="checkbox-date big-check"
                                text-color="red"
                                v-model="customDate"
                              ></el-checkbox>
                              <h3 style="margin: 0">Custom</h3>
                            </div>
                            <div class="flex" style="gap: 40px">
                              <el-date-picker
                                :disabled="!customDate"
                                @change="setByFilter"
                                class="mb-2 no-input-pad date-picker-input"
                                style="height: 35px"
                                v-model="datePikerStart"
                                :disabledDate="disabledDateStart"
                                :picker-options="disabledDateStart"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="Start"
                              >
                              </el-date-picker>
                              <el-date-picker
                                class="no-input-pad date-picker-input"
                                :disabled="!customDate"
                                @change="setByFilter"
                                style="height: 35px"
                                v-model="datePikerEnd"
                                @focus="disabledDate"
                                :disabledDate="disabledDate"
                                :picker-options="disabledDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="End"
                              >
                              </el-date-picker>
                            </div>
                          </div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item
                        title="Status code"
                        class="truncate"
                        name="2"
                      >
                        <div class="all_status">
                          <div class="column">
                            <div
                              v-for="(n, id) in statusArray.slice(0, 3)"
                              :key="id"
                              class="flex"
                              style="
                                border: none;
                                justify-content: space-between;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  @change="selectStatus(id)"
                                  v-model="n.status"
                                ></el-checkbox>
                                <el-tag
                                  :type="getCodeType(n.value)"
                                  size="small"
                                  class="tag-virsz ml-2"
                                >
                                  {{ n.type }}
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p>{{ n.total }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="column">
                            <div
                              v-for="(n, id) in statusArray.slice(3, 6)"
                              :key="id"
                              class="flex"
                              style="
                                border: none;
                                justify-content: space-between;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  @change="selectStatus(id + 3)"
                                  v-model="n.status"
                                ></el-checkbox>
                                <el-tag
                                  :type="getCodeType(n.value)"
                                  size="small"
                                  class="tag-virsz ml-2"
                                >
                                  {{ n.type }}
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p>{{ n.total }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="column">
                            <div
                              v-for="(n, id) in statusArray.slice(6, 10)"
                              :key="id"
                              class="flex"
                              style="
                                border: none;
                                justify-content: space-between;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  @change="selectStatus(id + 6)"
                                  v-model="n.status"
                                ></el-checkbox>
                                <el-tag
                                  :type="getCodeType(n.value)"
                                  size="small"
                                  class="tag-virsz ml-2"
                                >
                                  {{ n.type }}
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p>{{ n.total }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="column">
                            <div
                              v-for="(n, id) in statusArray.slice(10, 13)"
                              :key="id"
                              class="flex"
                              style="
                                border: none;
                                justify-content: space-between;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  @change="selectStatus(id + 10)"
                                  v-model="n.status"
                                ></el-checkbox>
                                <el-tag
                                  :type="getCodeType(n.value)"
                                  size="small"
                                  class="tag-virsz ml-2"
                                >
                                  {{ n.type }}
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p>{{ n.total }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item
                        style="background: #dbdbdb"
                        title="HTTP Methods (5)"
                        class="truncate"
                        name="3"
                      >
                        <template slot="title">
                          HTTP Methods <span class="ml-1 danger"></span>
                        </template>
                        <div class="http-buttons">
                          <div class="http-buttons-first">
                            <div
                              class="flex"
                              style="
                                border: none;
                                gap: 5px;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  v-model="checkedGet"
                                  @change="getApiCall()"
                                ></el-checkbox>
                                <el-tag
                                  type="success"
                                  size="small"
                                  class="tag-virsz ml-2"
                                  effect="dark"
                                >
                                  Get
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p style="margin: 4px">{{ checkedGetValue }}</p>
                              </div>
                            </div>
                            <div
                              class="flex"
                              style="
                                border: none;
                                gap: 5px;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  v-model="checkedPost"
                                  @change="getApiCall()"
                                ></el-checkbox>
                                <el-tag
                                  size="small"
                                  class="tag-virsz ml-2"
                                  effect="dark"
                                >
                                  Post
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p style="margin: 4px">
                                  {{ checkedPostValue }}
                                </p>
                              </div>
                            </div>

                            <div
                              class="flex"
                              style="
                                border: none;
                                gap: 5px;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  v-model="checkedPut"
                                  @change="getApiCall()"
                                ></el-checkbox>
                                <el-tag
                                  type="warning"
                                  size="small"
                                  class="tag-virsz ml-2"
                                  effect="dark"
                                >
                                  Put
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p style="margin: 4px">{{ checkedPutValue }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="http-btns-second">
                            <div
                              class="flex"
                              style="
                                border: none;
                                gap: 5px;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  v-model="checkedDelete"
                                  @change="getApiCall()"
                                ></el-checkbox>
                                <el-tag
                                  type="danger"
                                  size="small"
                                  class="tag-virsz ml-2"
                                  effect="dark"
                                >
                                  Delete
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p style="margin: 4px">
                                  {{ checkedDeleteValue }}
                                </p>
                              </div>
                            </div>
                            <div
                              class="flex"
                              style="
                                border: none;
                                gap: 5px;
                                align-items: center;
                              "
                            >
                              <div class="flex">
                                <el-checkbox
                                  class="checkbox-date"
                                  v-model="checkedPatch"
                                  @change="getApiCall()"
                                ></el-checkbox>
                                <el-tag
                                  type="info"
                                  size="small"
                                  class="tag-virsz ml-2"
                                  effect="dark"
                                >
                                  Patch
                                </el-tag>
                              </div>
                              <div class="mp--r">
                                <p style="margin: 4px">
                                  {{ checkedPatchValue }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-card>
                </div>
                <div class="clearfix clear_btns">
                  <button
                    style="float: right"
                    @click="handleSubmitSearch"
                    class="add_button"
                  >
                    Apply
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.46154 12H17.5385M4 7H20M10.1538 17H13.8462"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    style="float: right"
                    @click="clearFilter"
                    class="clear_btn add_button"
                  >
                    Clear <i class="trash-icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="!isShowFilters" class="mb-4" style="display: flex">
              <div
                class="ctb--body adpt-ctb--card--flex mb-3"
                style="width: 100%"
                v-if="sessionsDetail.resptime_max ? false : loading"
              >
                <!-- <div class="ctbb--row mb-2">
                  <img src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
                </div> -->
              </div>
              <div
                v-else-if="sessionsDetail"
                class="ctb--body flex justify-between"
                :class="isMobile ? 'adpt-ctb--card' : ''"
                style="width: 100%"
              >
                <div
                  class="ctbb--row mb-2 py-3 px-3 card--animation card-api-stat"
                >
                  <div class="ctbb--inner">
                    <div class="dashboard-tile">
                      <div class="tile-head is-flex-graph custom-card-header">
                        <div class="v-icon is-small is-primary is-rounded">
                          <i
                            aria-hidden="true"
                            style="font-size: 1.3em"
                            class="ni-clock_check"
                          ></i>
                        </div>
                        <h3 class="dark-inverted">Latency</h3>
                      </div>

                      <div class="tile-foot">
                        <el-tag type="success" size="mini">
                          <span class="el-tag--success"
                            >Time
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              data-icon="feather:trending-up"
                              class="iconify iconify--feather"
                            >
                              <g
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              >
                                <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                                <path d="M17 6h6v6"></path>
                              </g>
                            </svg>
                          </span>
                        </el-tag>
                        <span style="margin-top: 0" class="small-text">
                          From {{ formatDateState(projectDetail.createdAt) }} -
                          {{ formatDateState(Date.now()) }}</span
                        >
                      </div>
                      <div class="tile-body custom_tile_body">
                        <span class="text-card-tile-footer"> Average (ms)</span>
                        <span class="dark-inverted">{{
                          sessionsDetail.resptime_max
                            ? valueonk(sessionsDetail.resptime_max)
                            : 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="ctbb--row mb-2 py-3 px-3 card--animation card-api-stat"
                >
                  <div class="ctbb--inner">
                    <div class="dashboard-tile">
                      <div class="tile-head is-flex-graph custom-card-header">
                        <div class="v-icon is-small is-primary is-rounded">
                          <i aria-hidden="true" class="ni-check-broken"></i>
                        </div>
                        <h3 class="dark-inverted">Success</h3>
                      </div>
                      <div class="tile-foot">
                        <span class="text-h-green">
                          <el-tag type="success" size="mini">
                            {{
                              sessionsDetail.successful_calls
                                ? pc(
                                    sessionsDetail.successful_calls,
                                    sessionsDetail.total_calls
                                  )
                                : "N/A "
                            }}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              data-icon="feather:trending-up"
                              class="iconify iconify--feather"
                            >
                              <g
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              >
                                <path d="m23 6l-9.5 9.5l-5-5L1 18"></path>
                                <path d="M17 6h6v6"></path>
                              </g>
                            </svg>
                          </el-tag>
                        </span>
                        <span style="margin-top: 0" class="small-text"
                          >From {{ formatDateState(projectDetail.createdAt) }} -
                          {{ formatDateState(Date.now()) }}</span
                        >
                      </div>
                      <div class="tile-body custom_tile_body">
                        <span class="text-card-tile-footer"> Total</span>
                        <span class="dark-inverted">{{
                          sessionsDetail.successful_calls
                            ? valueonk(sessionsDetail.successful_calls)
                            : 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="ctbb--row mb-2 py-3 px-3 card--animation card-api-stat"
                >
                  <div class="ctbb--inner">
                    <div class="dashboard-tile">
                      <div class="tile-head is-flex-graph custom-card-header">
                        <div class="v-icon is-small is-primary is-rounded">
                          <i aria-hidden="true" class="ni-alert-triangle"></i>
                        </div>
                        <h3 class="dark-inverted">Error</h3>
                      </div>
                      <div class="tile-foot">
                        <span class="text-h-green">
                          <el-tag type="danger" size="mini">
                            {{
                              sessionsDetail.failed_calls
                                ? pc(
                                    sessionsDetail.failed_calls,
                                    sessionsDetail.total_calls
                                  )
                                : "N/A "
                            }}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              data-icon="feather:trending-down"
                              class="iconify iconify--feather"
                            >
                              <g
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              >
                                <path d="m23 18l-9.5-9.5l-5 5L1 6"></path>
                                <path d="M17 18h6v-6"></path>
                              </g>
                            </svg>
                          </el-tag>
                        </span>
                        <span style="margin-top: 0" class="small-text"
                          >From {{ formatDateState(projectDetail.createdAt) }} -
                          {{ formatDateState(Date.now()) }}</span
                        >
                      </div>
                      <div class="tile-body custom_tile_body">
                        <span class="text-card-tile-footer"> Total</span>
                        <span class="dark-inverted">{{
                          sessionsDetail.failed_calls
                            ? valueonk(sessionsDetail.failed_calls)
                            : 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <div
                  style="margin-left: 0px; margin-top: 10px !important"
                  class="ctbb--row mb-2 py-3 px-3 card--animation card-api-stat"
                  v-for="(project, index) in tagsSearch"
                  :key="index"
                  v-if="project.id === projectDetail.projectId"
                >
                  <div class="ctbb--inner">
                    <div class="dashboard-tile">
                      <div
                        class="tile-head is-flex-graph"
                        style="justify-content: start; gap: 5px"
                      >
                        <div class="v-icon is-small is-primary is-rounded">
                          <i aria-hidden="true" class="fas fa-search"></i>
                        </div>
                        <h3 class="dark-inverted">
                          Last search for <i>{{ project.name }}</i>
                        </h3>
                      </div>
                      <div class="tile-foot" v-if="project.keys.length">
                        <i>Keys</i><br />
                        <span
                          class="text-h-green"
                          v-for="(el, index) in project.keys"
                          :key="index"
                        >
                          <p class="text-total">
                            Total Result
                            {{ el.totalCallCount ? el.totalCallCount : 0 }}:
                          </p>
                          <el-tag
                            type="success"
                            size="medium"
                            :closable="true"
                            @close="
                              () =>
                                handleTagClose(el.filter, 'keys', project.id)
                            "
                            @click="
                              () => handleClickLastSearch(el.filter, 'key')
                            "
                          >
                            {{ el.filter }}
                          </el-tag>
                        </span>
                      </div>

                      <div class="tile-foot" v-if="project.values.length">
                        <i>Values</i> <br />
                        <span
                          class="text-h-green"
                          v-for="(el, index) in project.values"
                          :key="index"
                        >
                          <p class="text-total">
                            Total Result
                            {{ el.totalCallCount ? el.totalCallCount : 0 }}:
                          </p>
                          <el-tag
                            type="success"
                            size="medium"
                            :closable="true"
                            @click="
                              () => handleClickLastSearch(el.filter, 'value')
                            "
                            @close="
                              () =>
                                handleTagClose(el.filter, 'values', project.id)
                            "
                          >
                            {{ el.filter }}
                          </el-tag>
                        </span>
                      </div>

                      <div
                        class="tile-foot"
                        v-if="!project.values.length && !project.keys.length"
                      >
                        <i>No keys</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="ctb--body mb-3" style="width: 50%">
                <div
                  class="ctbb--row mb-2 py-3 px-3 card--animation"
                  style="width: 100% !important"
                >
                  <div class="ctbb--inner">No item found</div>
                </div>
              </div>
            </div>
            <div v-if="!isShowFilters" class="convert-apicall-section">
              <h3 class="text-convert">
                Choose API calls and convert them to API test
              </h3>
              <button
                :disabled="!enableConverButton"
                @click="runApiTest('value')"
                style="display: flex; align-items: center"
                class="add_button"
              >
                <i style="margin-right: 4px"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="arrow-refresh icon">
                      <path
                        id="Icon"
                        d="M19.4221 8.01389C18.0322 5.61438 15.4343 4 12.4588 4C9.08513 4 6.19686 6.07535 5.00433 9.01736M16.9806 9.01736H21V5.00347M5.57787 16.0417C6.96782 18.4412 9.56573 20.0556 12.5412 20.0556C15.9149 20.0556 18.8031 17.9802 19.9957 15.0382M8.0194 15.0382H4V19.0521"
                        stroke="#E6E6E6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </i>
                Convert
              </button>
            </div>
            <div class="vtza-c-r mt-4">
              <data-list
                :loading="loading"
                :items="filteredResultApi"
                :customSizes="false"
                ref="dataList"
                emptyText="No endpoints found."
              >
                <template #content>
                  <el-card
                    style="height: 65px; background: #cccccc !important"
                    class="trsl--elt custom-elt-body-no-padding custom-header-el-card"
                  >
                    <el-row
                      style="
                        display: grid;
                        grid-template-columns: 100px 1fr 200px 1fr 1fr 1fr 1fr;
                        text-align: center;
                      "
                      type="flex"
                      justify="space-between"
                      class="el--flex drag-only-row custom-header-rows"
                    >
                      <th><span class="th--label"></span></th>
                      <th class="flex btns-gap justify-center">
                        <span class="th--label">HTTP METHOD</span>
                        <div
                          @click="sortList('method')"
                          :class="
                            sortedBy == 'method'
                              ? 'enable-sort'
                              : 'disable-sort'
                          "
                        >
                          <i
                            :class="
                              isReversed['method']
                                ? 'arrow-sort-up'
                                : 'arrow-sort-down'
                            "
                          ></i>
                        </div>
                      </th>
                      <th class="flex btns-gap justify-center">
                        <span class="th--label">CALL URL</span>
                        <div
                          @click="sortList('url')"
                          :class="
                            sortedBy == 'url' ? 'enable-sort' : 'disable-sort'
                          "
                        >
                          <i
                            :class="
                              isReversed['url']
                                ? 'arrow-sort-up'
                                : 'arrow-sort-down'
                            "
                          ></i>
                        </div>
                      </th>
                      <th class="flex btns-gap justify-center">
                        <span class="th--label">STATUS</span>
                        <div
                          @click="sortList('status_code')"
                          :class="
                            sortedBy == 'status_code'
                              ? 'enable-sort'
                              : 'disable-sort'
                          "
                        >
                          <i
                            :class="
                              isReversed['status_code']
                                ? 'arrow-sort-up'
                                : 'arrow-sort-down'
                            "
                          ></i>
                        </div>
                      </th>
                      <th class="flex btns-gap justify-center">
                        <span class="th--label">TIME</span>
                        <div
                          @click="sortList('response_time')"
                          :class="
                            sortedBy == 'response_time'
                              ? 'enable-sort'
                              : 'disable-sort'
                          "
                        >
                          <i
                            :class="
                              isReversed['response_time']
                                ? 'arrow-sort-up'
                                : 'arrow-sort-down'
                            "
                          ></i>
                        </div>
                      </th>
                      <th class="flex btns-gap justify-center">
                        <span class="th--label">CREATED</span>
                        <div
                          @click="sortList('created_at')"
                          :class="
                            sortedBy == 'created_at'
                              ? 'enable-sort'
                              : 'disable-sort'
                          "
                        >
                          <i
                            :class="
                              isReversed['created_at']
                                ? 'arrow-sort-up'
                                : 'arrow-sort-down'
                            "
                          ></i>
                        </div>
                      </th>
                      <th><span class="th--label"></span></th>
                    </el-row>
                  </el-card>
                  <div
                    v-for="(row, index) in sortedToShow"
                    :key="index"
                    class="tblb--row mb-2"
                  >
                    <el-card
                      class="trsl--elt custom-elt-body-no-padding"
                      shadow="hover"
                      style="padding-bottom: 0"
                    >
                      <div>
                        <div
                          style="
                            display: grid;
                            grid-template-columns: 100px 1fr 200px 1fr 1fr 1fr 1fr;
                            text-align: center;
                          "
                          type="flex"
                          class="el--flex drag-only-row"
                        >
                          <div class="trsl--txt max-width">
                            <span class="test-run-name">
                              <!-- {{ forStepNumberRunApi(index)}} -->
                              <el-checkbox
                                :checked="checkedApi[row.apicall_uid].value"
                                @change="
                                  handleCheckAllChange(
                                    checkedApi[row.apicall_uid].id,
                                    checkedApi[row.apicall_uid].value
                                  )
                                "
                              ></el-checkbox>
                            </span>
                          </div>
                          <div
                            class="trsl--txt test-run-name-callApi mx-2 truncate max-width"
                          >
                            <el-tag
                              :key="row.method"
                              :type="getTagType(row.method)"
                              size="small"
                              class="api-call-tag"
                              effect="dark"
                            >
                              {{ row.method }}
                            </el-tag>
                          </div>
                          <div class="max-width">
                            <el-tooltip
                              effect="light"
                              :content="row.url"
                              placement="top"
                              style="
                                font-size: 14px;
                                color: #000000;
                                font-weight: 400;
                              "
                            >
                              <span>{{ truncateString(row.url, 30) }}</span>
                            </el-tooltip>
                          </div>
                          <div class="max-width">
                            <el-alert
                              class="no--close elt--banner_overlay_status tags-call-api"
                              show-icon
                              :closable="false"
                              :title="`${row.status_code}`"
                              :type="
                                row.status_code < 299 ? 'success' : 'error'
                              "
                            ></el-alert>
                          </div>
                          <div class="elt-row max-width">
                            <span class="test-time-cont">
                              <i class="far fa-clock mr-1"></i>
                              {{ row.response_time }} ms
                            </span>
                          </div>
                          <div class="trsl--txt max-width">
                            <span class="elt--txt">
                              {{ formatDate(row.created_at) }}<br />
                              {{ formatHours(row.created_at) }}
                            </span>
                          </div>
                          <div class="trsl--txt max-width">
                            <el-tooltip
                              effect="light"
                              content="Virtualization"
                              placement="top"
                            >
                              <el-button
                                id="btn-api--calls"
                                style="
                                  background-color: #d6d6d6;
                                  border-radius: 10px;
                                "
                                circle
                                @click="openApicallsvirtModal(row)"
                              >
                                <i
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.9999 17.3999V11.3999M19.6799 17.7599C18.6195 17.7599 17.7599 18.6195 17.7599 19.6799C17.7599 20.7403 18.6195 21.5999 19.6799 21.5999C20.7403 21.5999 21.5999 20.7403 21.5999 19.6799C21.5999 18.6195 20.7403 17.7599 19.6799 17.7599ZM19.6799 17.7599L19.6799 12.2399C19.6799 11.7097 19.2501 11.2799 18.7199 11.2799H5.2799C4.74971 11.2799 4.3199 11.7097 4.3199 12.2399L4.3199 17.7599M4.3199 17.7599C3.25951 17.7599 2.3999 18.6195 2.3999 19.6799C2.3999 20.7403 3.25951 21.5999 4.3199 21.5999C5.38029 21.5999 6.2399 20.7403 6.2399 19.6799C6.2399 18.6195 5.38029 17.7599 4.3199 17.7599ZM11.9999 21.5999C10.9395 21.5999 10.0799 20.7403 10.0799 19.6799C10.0799 18.6195 10.9395 17.7599 11.9999 17.7599C13.0603 17.7599 13.9199 18.6195 13.9199 19.6799C13.9199 20.7403 13.0603 21.5999 11.9999 21.5999ZM5.9999 7.1999H17.9999C18.6626 7.1999 19.1999 6.66264 19.1999 5.9999V3.5999C19.1999 2.93716 18.6626 2.3999 17.9999 2.3999H5.9999C5.33716 2.3999 4.7999 2.93716 4.7999 3.5999V5.9999C4.7999 6.66264 5.33716 7.1999 5.9999 7.1999Z"
                                      stroke="#1F213E"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    /></svg></i
                              ></el-button>
                            </el-tooltip>
                            <el-tooltip
                              effect="light"
                              content="Detail"
                              placement="top"
                            >
                              <el-button
                                id="btn-edit--accordion"
                                style="
                                  background-color: #d6d6d6;
                                  border-radius: 10px;
                                "
                                circle
                                @click="openEditAccordion(index)"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M11.4 21.6001H5.39999C4.0745 21.6001 2.99999 20.5256 3 19.2001L3.00009 4.80013C3.0001 3.47466 4.07462 2.40015 5.40009 2.40015H16.2004C17.5258 2.40015 18.6004 3.47466 18.6004 4.80015V9.60015M19.8 19.8001L21 21.0001M7.20037 7.20015H14.4004M7.20037 10.8001H14.4004M7.20037 14.4001H10.8004M20.4 17.4001C20.4 19.057 19.0569 20.4001 17.4 20.4001C15.7431 20.4001 14.4 19.057 14.4 17.4001C14.4 15.7433 15.7431 14.4001 17.4 14.4001C19.0569 14.4001 20.4 15.7433 20.4 17.4001Z"
                                    stroke="#1F213E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                ></el-button
                              >
                            </el-tooltip>
                            <el-tooltip
                              effect="light"
                              content="Run API"
                              placement="top"
                            >
                              <el-button
                                id="btn-rerun--api"
                                style="
                                  background-color: #d6d6d6;
                                  border-radius: 10px;
                                "
                                plain
                                circle
                                @click="openReRunApi(row, index)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M4.87115 14.5C5.88914 17.6939 8.80463 20 12.2424 20C16.5268 20 20 16.4183 20 12C20 7.58172 16.5268 4 12.2424 4C9.37103 4 6.86399 5.60879 5.52267 8M7.87879 9H4V5"
                                    stroke="#1F213E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </el-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                      <div
                        class="edit-accordion"
                        v-show="index === selectedItemId"
                      >
                        <el-divider></el-divider>
                        <vue-json-pretty
                          :data="getJsonData(row)"
                          :showSelectController="true"
                          :showIcon="true"
                          :showLineNumber="true"
                        />
                        <div
                          class="py-2"
                          style="display: flex; justify-content: end"
                        >
                          <base-button
                            type="secondary"
                            @click="openEditAccordion(index)"
                          >
                            {{ $t("list_item_page.close") }}
                          </base-button>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </template>
              </data-list>
              <el-pagination
                v-if="filteredResultApi.length > 0"
                background
                layout="prev, pager, next"
                :total="totalCallCount"
                :page-size="pagination.perPage"
                :page-sizes="pagination.perPageOptions"
                :current-page.sync="pagination.currentPage"
                @current-change="getApiCall"
              >
              </el-pagination>
            </div>
          </div>
        </template>
        <template v-if="showAppChat">
          <div
            style="
              display: grid;
              grid-template-rows: 87vh;
              margin-top: 20px;
              overflow: hidden !important;
            "
            class="container-chat"
          >
            <div style="display: flex">
              <div class="right-chat--side">
                <div class="cstm-container">
                  <div
                    :style="{
                      paddingTop: postedMessages.length !== 0 ? '0px' : '0px',
                    }"
                    class="messages-history"
                    v-if="postedMessages.length !== 0"
                  >
                    <div
                      class="container--message"
                      v-for="(message, id) in postedMessages"
                      :key="id"
                    >
                      <el-col class="cstm-el--col" :span="15">
                        <el-card style="overflow: visible" shadow="hover">
                          <div class="header--message">
                            <img
                              class="avatar--message"
                              alt="avatar"
                              :src="
                                message.role === 'user'
                                  ? '/img/icons/common/user_icon.png'
                                  : '/img/brand/wring.png'
                              "
                              width="30"
                            />
                            <h4>
                              {{
                                message.role === "user"
                                  ? userName.full_name
                                  : ""
                              }}
                            </h4>
                          </div>
                          <div class="body--message">
                            <span v-html="message.content"></span>
                            <button
                              v-if="isFeatureInAnswer(id)"
                              :id="'download_btn_' + id"
                              class="add_button"
                              @click="openEditModal(id)"
                            >
                              Edit File
                            </button>
                            <!-- <div v-if="message.role != 'user'" :id="'options-message_'+id" class="options-message">
                                    <div style="display: inline-block;"  class="mr-4 rrwew-content video-chat" :id="'rrweb_'+id">
                                        <img v-if="loadingVideo && id === postedMessages.length - 1" src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
                                     </div>
                                     <div class="btns-option-message">
                                        <button @click="convertApiCalls(id)" style="display: none;" class="add_button" :id="'convert_button_'+id">Convert</button>
                                     <a target="_blank" :id="'test_link_'+id" class="add_button" href="" style="display: none;">Show Test</a>
                                     </div>
                                     <div :id="'chart_'+id" class="custom-chart chart-message" style="width:400px;height:200px;"><v-chart class="chart" :autoresize="true"
                                                    :option="overviewPieChart[id]" /></div>
                                    </div> -->
                          </div>
                        </el-card>
                      </el-col>
                    </div>
                    <div
                      v-if="isStreaming && displayedText != ''"
                      class="container--message"
                    >
                      <el-col class="cstm-el--col" :span="15">
                        <el-card style="overflow: visible" shadow="hover">
                          <div class="header--message">
                            <img
                              class="avatar--message"
                              alt="avatar"
                              src="/img/brand/wring.png"
                              width="30"
                            />
                          </div>
                          <div class="body--message">
                            <span class="streaming">{{ displayedText }}</span>
                          </div>
                        </el-card>
                      </el-col>
                    </div>
                  </div>
                  <div class="messages-history" v-else>
                    <div class="container--greeting-session">
                      <img src="/img/brand/wring_black.png" width="100" />
                      <h2 class="hello-text">How can I help you today?</h2>
                    </div>
                  </div>
                  <div
                    v-if="suggestionMessages.length > 0"
                    class="body-suggestion"
                  >
                    <div
                      v-for="message in suggestionMessages"
                      @click="sendSuggestion(message)"
                      class="message-suggestion"
                    >
                      <h2>{{ processSuggestMessage(message) }}</h2>
                    </div>
                  </div>
                  <form
                    class="form form-settings"
                    @submit.prevent="handleSubmitChat"
                  >
                    <div
                      class="typing-indicator app-indicator"
                      v-if="loadingMessage"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <div class="message-place">
                      <el-input
                        class="message-input"
                        placeholder="Please write your message"
                        v-model="currentMessage"
                      >
                      </el-input>
                      <!--                        <el-tooltip class="item" effect="light"-->
                      <!--                            :disabled="selectedVersion === '' || selectedProject === '' ? false : true"-->
                      <!--                            content="Please select chat version and app" placement="top">-->
                      <!--                            -->
                      <!--                        </el-tooltip>-->
                      <button
                        :disabled="isSumbit"
                        class="btn-send--message"
                        @click.stop="handleSubmitChat"
                      >
                        <span class="send-mes-icon"></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- <modal class="small-modal pt-modal" :show.sync="isEditMessage">
          <div style="width:100%">
            <h5 class="edit-message-header">Edit Message</h5>
          <div class="edit-message-body input-width-tall">
            <textarea
            id="area-edit"
            class="el-input__inner"
            :placeholder="'Write your prompt'"
              ></textarea>
            <div v-if="isEditLoading" class="el-input__inner loading-container">
              <img src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
            </div>
            <div
            v-else-if="messageEdit != '' && !isEditLoading"
            id="message-edit"
            class="el-input__inner"
            v-html='messageEdit'
              >
          </div>
          </div>
          <div class="edit-message-footer">
            <button class="cancel_button" @click="isEditMessage = false">Cancel</button>
            <button class="add_button" @click="sendEditMessage">Change</button>
            <button v-if="messageEdit != ''" class="add_button" @click="downloadFileChat">Download File</button>
            <button v-if="messageEdit != '' && !isTestDownload" class="add_button" @click="downloadTestChat">Download Test</button>
            <button v-else class="cancel_button" @click="downloadTestChat">
              <img src="/img/loading.gif" alt="loading..." style="height: 22px; width: 22px" />
            </button>
          </div>
          </div>
        </modal> -->
              <div class="container-modal--gen">
                <modal class="small-modal pt-modal" :show.sync="isEditMessage">
                  <div style="width: 100%">
                    <h5 class="edit-message-header">
                      What Type Of Tests To Generate
                    </h5>
                    <div class="edit-message-body input-width-tall">
                      <!-- <div v-if="loadingTestFile" class="el-input__inner loading-container">
                  <img src="/img/loading.gif" alt="loading..." style="width: 30%; height: 30%;" />
                </div>
                <el-input v-if="!loadingTestFile" class="input-width-tall" type="textarea" id="stepInputPrompt" name="stepInputPrompt"
                v-model="dowloadPrompt" :autosize="{ minRows: 8, maxRows: 30 }"
                placeholder="Give Some Hints To Guide Wringr AI To Create Tests"></el-input> -->
                      <textarea
                        id="area-edit"
                        class="el-input__inner"
                        :placeholder="'Refine this Scenario'"
                        v-model="userPrompt"
                      ></textarea>
                      <div
                        v-if="isEditLoading && !showProgressBar"
                        class="el-input__inner loading-container"
                      >
                        <img
                          src="/img/loading.gif"
                          alt="loading..."
                          style="height: 64px; width: 64px"
                        />
                      </div>
                      <div
                        class="el-input__inner"
                        v-if="isEditLoading && showProgressBar"
                      >
                        <el-progress
                          class="prg-modal"
                          type="dashboard"
                          :percentage="percentage"
                          :color="colors"
                        ></el-progress>
                      </div>
                      <div
                        v-else-if="!isEditLoading && !edittingPrompt"
                        id="message-edit"
                        class="el-input__inner"
                        v-html="messageEdit"
                        contenteditable="true"
                      ></div>
                      <!-- <div style="position: relative; width: 100%;" v-if="edittingPrompt && !loadingTestFile">
                  <span class="close-gallery" id="close-gallery" 
                    style="position: absolute; cursor: pointer; top: -5px; right: 4%; font-size: 40px;" @click="closeEdittingPrompt()">&times;
                  </span>
                  <textarea
                    
                    id="message-edit"
                    class="el-input__inner"
                    v-model="showEditPrompt"
                      >
                  </textarea>
                  <button class="add_button" @click="modifyEdittingPrompt()">Modify</button>
                </div> -->
                    </div>
                    <!-- <div class="edit-message-footer">
                <div class="left-sbmt"> 
                  <button class="add_button clear_btn-sp" v-if="userPrompt !== ''" @click="userPrompt = ''">Clear</button>
                </div>
                <div class="right-btns"> 
                  <button class="add_button" @click="sendEditMessage">Change</button>
                  <button v-if="dowloadPrompt != ''" class="add_button" @click="$refs.btnDownloadScripts.click()">Generate Test</button>
                  <button class="cancel_button" @click="showGenTest = false" style="margin-left: 10px;">Cancel</button>
                </div> -->
                    <!-- <button v-else class="cancel_button" @click="showGenTest = false">
                  <img src="/img/loading.gif" alt="loading..." style="height: 22px; width: 22px" />
                </button> -->
                    <!-- </div> -->
                    <div class="edit-message-footer">
                      <button
                        class="cancel_button"
                        @click="isEditMessage = false"
                      >
                        Cancel
                      </button>
                      <button
                        v-if="messageEdit != '' && !isTestDownload"
                        :class="
                          userPrompt == '' ? 'cancel_button' : 'add_button'
                        "
                        :disabled="userPrompt == ''"
                        @click="sendEditMessage"
                      >
                        Refine
                      </button>
                      <button
                        v-if="messageEdit != '' && !isTestDownload"
                        class="add_button"
                        @click="downloadFileChat"
                      >
                        Download File
                      </button>
                      <button
                        v-if="messageEdit != '' && !isTestDownload"
                        class="add_button"
                        @click="$refs.btnDownloadScripts.click()"
                      >
                        Download Test
                      </button>
                    </div>
                  </div>
                </modal>
                <div class="">
                  <el-dropdown
                    trigger="click"
                    style="display: none"
                    :hide-on-click="false"
                    type="primary"
                    class="add_button"
                    id="btnDownloadTests"
                  >
                    <el-tooltip
                      :content="$t('view_item_page.download_scripts')"
                      placement="top"
                    >
                      <button
                        style="display: none"
                        ref="btnDownloadScripts"
                        class="add_button"
                        id="btnDownloadSccripts"
                      >
                        Download Test
                      </button>
                    </el-tooltip>

                    <el-dropdown-menu
                      slot="dropdown"
                      class="cstm-dropdown--download"
                    >
                      <span
                        class="close-gallery"
                        id="close-gallery"
                        style="
                          position: absolute;
                          cursor: pointer;
                          top: -5px;
                          right: 4%;
                          font-size: 40px;
                        "
                        @click="$refs.btnDownloadScripts.click()"
                        >&times;
                      </span>
                      <h2 class="title-download">Choose Format</h2>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="wringr"
                          class="ic--btn"
                        >
                          <el-tooltip
                            content="Download wringr script file"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/favicon.png"
                              alt="Observer script file"
                            />
                          </el-tooltip>
                          <h2>Wringr</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="playwright"
                          class="ic--btn"
                        >
                          <el-tooltip
                            content="Download playwright script file"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/playwright.svg"
                              alt="Observer script file"
                            />
                          </el-tooltip>
                          <h2>Playwright</h2>
                        </el-radio>
                      </el-dropdown-item>

                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="cucumber"
                          class="ic--btn"
                        >
                          <el-tooltip
                            content="Download cucumber script file"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/Cucumber.svg"
                              alt="Observer script file"
                            />
                          </el-tooltip>
                          <h2>Cucumber</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="cucumberconfig"
                          class="ic--btn"
                        >
                          <el-tooltip
                            content="Download cucumber config file"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/config.svg"
                              alt="Observer script file"
                            />
                          </el-tooltip>
                          <h2>Cucumber config</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="sensetalk_zip"
                          class="ic--btn"
                        >
                          <el-tooltip
                            content="Download sensetalk suite file"
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/SensetalkSuite.png"
                              alt="Observer script file"
                            />
                          </el-tooltip>
                          <h2>Sensetalk suite</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          class="ic--btn"
                          v-model="chosenDownloadFile"
                          label="selenium"
                        >
                          <el-tooltip
                            :content="
                              $t('view_item_page.download_selenium_script')
                            "
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/selenium.png"
                              alt="Selenium"
                            />
                          </el-tooltip>
                          <h2>Selenium</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="cypress"
                          class="ic--btn"
                        >
                          <el-tooltip
                            :content="
                              $t('view_item_page.download_generated_cypress')
                            "
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/cypress.svg"
                              alt="Katalon"
                            />
                          </el-tooltip>
                          <h2>Cypress</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="sensetalk"
                          class="ic--btn"
                        >
                          <el-tooltip
                            :content="
                              $t('view_item_page.download_generated_sensetalk')
                            "
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/eggplant_logo.svg"
                              alt="Sensetalk"
                            />
                          </el-tooltip>
                          <h2>Sensetalk</h2>
                        </el-radio>
                      </el-dropdown-item>
                      <el-dropdown-item class="custom--dropdown--item">
                        <el-radio
                          v-model="chosenDownloadFile"
                          label="puppeteer"
                          class="ic--btn"
                        >
                          <el-tooltip
                            :content="
                              $t('view_item_page.download_generated_puppeteer')
                            "
                            placement="top"
                          >
                            <img
                              src="../../../assets/images/pptr.svg"
                              alt="Puppeteer"
                            />
                          </el-tooltip>
                          <h2>Puppeteer</h2>
                        </el-radio>
                      </el-dropdown-item>

                      <div class="btns-download">
                        <button
                          class="add_button mt-4"
                          type="button"
                          @click.prevent="
                            downloadTestFileChat(chosenDownloadFile) &&
                              $refs.btnDownloadScripts.click()
                          "
                        >
                          Submit
                        </button>
                        <el-switch
                          class="custom-switch-round"
                          v-model="updateValidate"
                          active-color="#1F213E"
                          active-text="Validate"
                          inactive-color="#808080"
                        >
                        </el-switch>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              <div class="left-chat--side">
                <div class="chat-history container">
                  <div class="history-header">
                    <el-button
                      class="btn-new--chat"
                      @click="createNewSession((isButton = true))"
                    >
                      <div class="new--chat">
                        <!-- <img src="/img/brand/logo_wring.svg" width="40px" /> -->
                        <h2 class="hstr">New Chat</h2>
                      </div>
                      <svg
                        fill="#1F213E"
                        style="width: 24px; height: 24px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        />
                      </svg>
                    </el-button>
                  </div>
                  <hr
                    class="my-3 cstm--line"
                    style="
                      border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                      border-style: solid none none;
                      border-width: 1px 0px 0px;
                      border-image: none 100% / 1 / 0 stretch;
                      min-width: 98%;
                      overflow: visible;
                      box-sizing: content-box;
                      height: 0px;
                    "
                  />
                  <!-- <el-tree ref="elTree" id="elTree" class="custom-tree" :data="projectTree" :props="defaultProps"
                    @node-click="handleNodeClick"></el-tree> -->

                  <div
                    class="history-messages-name"
                    v-loading="loadingSessionChats"
                  >
                    <div
                      style="padding-left: 10px"
                      v-for="(item, id) in showSelectedSessions"
                      :key="id"
                    >
                      <!-- <h2 class="project--time"> {{ item.time === 'today' && item.content.length !== 0 ? 'Today :' :
                            item.time === 'yesterday'
                                &&
                                item.content.length !== 0 ? 'Yesterday :' : item.time === '2 days ago' && item.content.length
                                    !==
                                    0 ? '2 Days Ago' : '' }}
                        </h2> -->
                      <div class="container-project--history">
                        <h2
                          class="project--name"
                          @click="handleShowMessage(item)"
                        >
                          {{ item.title }}
                        </h2>
                        <div
                          @click.stop="openEditNameChatModal(item, id)"
                          class="dots"
                        >
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </div>
                      </div>
                      <div
                        v-if="modal_id == id"
                        class="modal-chat-session-wrapper"
                      >
                        <div class="modal-chat-session">
                          <div @click="handleClickDelete(item)">
                            <span>
                              <svg
                                fill="#1F213E"
                                style="
                                  width: 18px;
                                  height: 18px;
                                  margin-bottom: 12px;
                                "
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                />
                              </svg>
                            </span>
                            <h2>Rename</h2>
                          </div>
                          <div @click="handleClickDelete(item)">
                            <span class="trash-icon"></span>
                            <h2>Delete</h2>
                          </div>
                        </div>

                        <!-- <span @click="handleClickDelete(item)" class="trash-icon"></span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Modal add test start-->
    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="addTestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Add to a test suite</h5>
        </template>
        <!-- <div class="mid--page mb-3" style="border: 0">
            <div class="mp--r"></div>
            <div class="mp--l"> -->
        <el-dropdown trigger="click" @command="downloadTestFile">
          <button :disabled="loadingModalAddRun" class="ic--btn mr-2">
            <el-tooltip content="Download test files" placement="top">
              <i class="fa fa-download" aria-hidden="true"></i>
            </el-tooltip>
          </button>
          <el-dropdown-menu
            slot="dropdown"
            class="cstm-drp"
            style="max-height: 50vh; overflow-x: auto"
            v-loading="loadingFile"
          >
            <el-dropdown-item
              icon="el-icon-download"
              v-for="(ls, id) in fileBackup"
              :key="id"
              :command="id"
            >
              {{ ls.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- </div>
          </div> -->
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <!-- <label class="model-label is-required" for="run_name"> -->
                <!-- {{ $t("list_item_page.run_name") }} -->
                <!-- </label> -->
                <label class="model-label" for="Keyflow"> Name </label>
                <input
                  class=""
                  type="text"
                  id="run_name"
                  name="run_name"
                  :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="labelSelectedKeyFlow"
                />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select
                  id="Test_Script_Files"
                  v-model="listAddSuitTest"
                  multiple
                  collapse-tags
                  laceholder="Select execution file"
                >
                  <el-option
                    v-for="(item, id) in fileBackup"
                    :key="id"
                    :label="item.name"
                    :value="item"
                  >
                    <span style="float: left; width: 100%">{{
                      item.name
                    }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <el-tooltip
              :disabled="
                !(listAddSuitTest.length === 0 || labelSelectedKeyFlow === '')
              "
              :content="`${
                labelSelectedKeyFlow === '' ? 'Run name cannot be empty' : ''
              } ${
                listAddSuitTest.length === 0
                  ? 'Please select execution file'
                  : ''
              }`"
              placement="top"
            >
              <span>
                <button
                  class="add_button"
                  :disabled="
                    listAddSuitTest.length === 0 || labelSelectedKeyFlow === ''
                  "
                  type="primary"
                  @click="saveAddFileTestSuite"
                >
                  Add
                </button>
              </span>
            </el-tooltip>
            <button
              class="cancel_button"
              type="secondary"
              @click="
                addTestModal = false;
                cleanUpState();
              "
            >
              {{ $t("list_item_page.close") }}
            </button>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="toggleFixture">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Add New Fixture</h5>
        </template>
        <el-select
          class="select-danger"
          placeholder="Choose Fixture"
          v-model="currentFixture"
          v-if="listFixtureApp !== null || listFixtureApp?.length > 0"
        >
          <el-option
            v-for="(option, id) in listFixtureApp.fixtureTags"
            class="select-danger"
            :value="option"
            :label="option"
            :key="id"
          >
          </el-option>
        </el-select>
        <label v-else> Pleas Add Fixture For This App </label>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <span>
              <button
                class="add_button"
                type="primary"
                @click="handleFixture()"
              >
                Add
              </button>
            </span>
            <button
              class="cancel_button"
              type="secondary"
              @click="toggleFixture = false"
            >
              {{ $t("list_item_page.close") }}
            </button>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal :show.sync="addTestModalUser">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Add a test to the App test suite
          </h5>
        </template>
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label is-required" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input
                  type="text"
                  id="run_name"
                  name="run_name"
                  :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="labelSelectedUserId"
                />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select
                  id="Test_Script_Files"
                  v-model="listAddSuitTest"
                  multiple
                  collapse-tags
                  laceholder="Select execution file"
                >
                  <el-option
                    v-for="(item, id) in fileBackup"
                    :key="id"
                    :label="item.name"
                    :value="item"
                  >
                    <span style="float: left; width: 100%">{{
                      item.name
                    }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button
              type="secondary"
              @click="
                addTestModal = false;
                cleanUpState();
              "
            >
              {{ $t("list_item_page.close") }}
            </base-button>

            <el-tooltip
              :disabled="
                !(listAddSuitTest.length === 0 || labelSelectedUserId === '')
              "
              :content="`${
                labelSelectedUserId === '' ? 'Run name cannot be empty' : ''
              } ${
                listAddSuitTest.length === 0
                  ? 'Please select execution file'
                  : ''
              }`"
              placement="top"
            >
              <span>
                <base-button
                  :disabled="
                    listAddSuitTest.length === 0 || labelSelectedUserId === ''
                  "
                  type="primary"
                  @click="saveAddFileTestSuite"
                  >{{ $t("list_item_page.add_to_testsuite") }}</base-button
                >
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <el-drawer
        title="Scheduled Run"
        :visible.sync="scheduledRun"
        direction="rtl"
        class="custom-drawer--title"
      >
        <div class="body--modale">
          <scheduled-run
            id="scheduled-run"
            ref="scheduledRun"
            :scriptFiles="scriptFiles"
          ></scheduled-run>
        </div>
      </el-drawer>
    </div>

    <!-- Modal add project start-->
    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="toggleEditTestModal">
        <template slot="header">
          <h5 class="modal-title">Edit Test Suite</h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name">
                  Test Suite Name</label
                >
                <input
                  type="text"
                  id="teste_suit_name"
                  name="teste_suit_name"
                  placeholder="teste suit name"
                  v-model="suit_run_name"
                />
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button class="cancel_button" @click="toggleEditTestModal = false">
              {{ $t("list_item_page.close") }}
            </button>
            <div>
              <el-tooltip
                :disabled="!(suit_run_name.length === 0)"
                :content="`${
                  suit_run_name.length === 0
                    ? 'teste suit name cannot be empty'
                    : ''
                } `"
                placement="top"
              >
                <span>
                  <button
                    :disabled="suit_run_name.length === 0"
                    class="add_button"
                    @click="saveEditTestSuite('add')"
                  >
                    Save
                  </button>
                </span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add project end-->

    <div class="add-file-modal">
      <modal class="small-modal" :show.sync="toggleReAddFileModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Add file</h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-modal">
              <div class="s-a-tabs">
                <div class="tab-content py-3" id="myTabContent">
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('settings') }"
                    id="settings"
                  >
                    <div class="mt-4">
                      <h4 class="fbb mt-1">
                        {{ $t("list_item_page.test_script_files") }}
                      </h4>
                      <div class="upload-craglist upload-file-div-wrapper">
                        <div
                          class="drop-down-container"
                          @dragover="dragover"
                          @dragleave="dragleave"
                          @drop="drop"
                          ref="dropDownContainer"
                        >
                          <input
                            type="file"
                            multiple="multiple"
                            name="fields[assetsFieldHandle][]"
                            id="swal-test-files"
                            class="w-px h-px opacity-0 overflow-hidden absolute fileInput"
                            v-on:change="onChange"
                            ref="file"
                            accept=".side, .wring"
                          />

                          <label
                            for="assetsFieldHandle"
                            class="block cursor-pointer"
                          >
                            <div>
                              {{ $t("list_item_page.upload_test_script")
                              }}<br />
                              {{ $t("list_item_page.up_to_50_project") }}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div
                        class="file-lists-container mt-4"
                        v-if="listAddSuitTest.length !== 0"
                      >
                        <div class="file-head-clearall">
                          <div class="list-heading">
                            {{ $t("list_item_page.uploaded_files") }}
                          </div>
                          <div class="clearall" @click="removeAllFiles">
                            {{ $t("list_item_page.clear_all") }}
                          </div>
                        </div>

                        <div class="craglist-files">
                          <ul class="mt-1">
                            <li
                              class="text-sm p-2"
                              v-for="(file, index) in listAddSuitTest"
                              :key="index"
                            >
                              {{ file.name }}
                              <i
                                @click="remove(listAddSuitTest.indexOf(file))"
                                class="ni ni-fat-remove rmv-btn"
                              ></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              style="
                background-color: #dbdbdb;
                color: #1f213e;
                border: 1px solid #dbdbdb;
              "
              class="add_button"
              type="secondary"
              @click="toggleReAddFileModal = false"
            >
              {{ $t("list_item_page.close") }}
            </button>

            <el-tooltip
              :disabled="!(listAddSuitTest.length === 0)"
              :content="`${
                listAddSuitTest.length === 0
                  ? 'Please upload at least one file'
                  : ''
              }`"
              placement="top"
            >
              <span>
                <button
                  class="add_button"
                  :disabled="listAddSuitTest.length === 0"
                  type="primary"
                  @click="saveAddFileTestSuite"
                >
                  <i
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0001 4.79999L12 19.2M19.2 12L4.80005 12"
                        stroke="#E6E6E6"
                        stroke-width="2"
                        stroke-linecap="round"
                      /></svg></i
                  >Add
                </button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>

    <div class="add-test-project-modal">
      <modal :show.sync="addTestAppModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Run test suite</h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div v-if="keyTestFile[0]" class="run-name">
                <div class="is-flex">
                  <div
                    class="is-flex mr-5"
                    style="flex-direction: row; align-items: center"
                  >
                    <h3 style="margin-bottom: 0">Same browser session</h3>
                    <label
                      style="position: relative; bottom: 0; margin-right: 0"
                      class="my_switch"
                    >
                      <input type="checkbox" v-model="suiteSingleRun" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- <div class="is-flex">
                    <label v-if="suiteSingleRun === false" class="model-label mr-3" for="run_description">Filter displayed
                      :</label>
                    <el-switch v-if="suiteSingleRun === false" v-model="filterDisplayed" active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </div> -->
                </div>
                <label class="model-label is-required" for="run_name">
                  <h3>Name</h3>
                </label>
                <input
                  type="text"
                  id="run_name"
                  name="suiteRunName"
                  :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="suiteRunName"
                />
                <label
                  v-if="suiteSingleRun === false"
                  class="model-label is-required"
                  for="runStartUrl"
                  ><h3>Start URL</h3>
                </label>
                <input
                  v-if="suiteSingleRun === false"
                  type="text"
                  id="runStartUrl"
                  name="runStartUrl"
                  placeholder="Start URL"
                  v-model="runStartUrl"
                />
                <label class="model-label" for="runStartUrl"
                  ><h3>Tags</h3></label
                >
                <input
                  type="text"
                  id="runTags"
                  name="runTags"
                  placeholder="Run tags"
                  v-model="runTagsSuit"
                />
                <label class="model-label" for="run_name">
                  Execution Cloud
                </label>
                <div class="is-flex" style="flex-direction: row">
                  <span
                    ><el-radio v-model="runServer" label="wring" border
                      >Wring</el-radio
                    ></span
                  >
                  <el-tooltip
                    :disabled="sourceLabsIsIntegrate"
                    :content="`${
                      sourceLabsIsIntegrate === false
                        ? 'No Sauce Labs integration was found'
                        : ''
                    } `"
                    placement="top"
                  >
                    <span>
                      <el-radio
                        v-model="runServer"
                        class="ml-1"
                        :disabled="!sourceLabsIsIntegrate"
                        label="source"
                        border
                        >Sauce Labs</el-radio
                      >
                    </span>
                  </el-tooltip>
                  <el-tooltip
                    :disabled="awsIsIntegrate"
                    :content="`${
                      awsIsIntegrate === false
                        ? 'No AWS integration was found'
                        : ''
                    } `"
                    placement="top"
                  >
                    <el-radio
                      :disabled="!awsIsIntegrate"
                      v-model="runServer"
                      class="ml-1"
                      label="aws"
                      border
                    ></el-radio
                  ></el-tooltip>
                </div>
                <label
                  v-show="runServer == 'source'"
                  class="model-label"
                  for="Sauce_Labs_Integration"
                >
                  Sauce Labs Integration
                </label>
                <el-select
                  v-show="runServer == 'source'"
                  v-model="integrationSaurceLabsIndex"
                  placeholder="Select Souce labs"
                >
                  <el-option
                    v-for="(item, id) in integrationSaurceLabs"
                    :key="id"
                    :label="getLabelSauceLabs(item)"
                    :value="id"
                  >
                  </el-option>
                </el-select>
                <label
                  v-show="runServer == 'aws'"
                  class="model-label"
                  for="aws_Integration"
                >
                  AWS Integration
                </label>
                <el-select
                  v-show="runServer == 'aws'"
                  v-model="integrationAwsIndex"
                  placeholder="Select AWS"
                >
                  <el-option
                    v-for="(item, id) in integrationAws"
                    :key="id"
                    :label="getLabelAws(item)"
                    :value="id"
                  >
                  </el-option>
                </el-select>

                <label
                  v-if="run_all === false"
                  class="model-label is-required"
                  for="Test_Script_Files"
                >
                  Test Script Files :
                </label>
                <el-alert
                  v-if="run_all === false && suiteSingleRun === true"
                  title="The order of file selection would be the order of execution!"
                  type="info"
                  class="mt-2 mb-2"
                >
                </el-alert>
                <el-select
                  v-if="run_all === false"
                  id="Test_Script_Files"
                  v-model="suiteFiles"
                  multiple
                  collapse-tags
                  placeholder="Select execution file"
                >
                  <el-option
                    v-for="(item, id) in fileAppAtt"
                    :key="id"
                    :label="item.fileName"
                    :value="item.fileKey"
                  >
                    <span style="float: left; width: 100%">{{
                      item.fileName
                    }}</span>
                  </el-option>
                </el-select>
                <div class="s-a-tabs">
                  <div class="custom-tabs">
                    <div
                      class="custom-tabs-link left-link"
                      :class="{ active_tab: isActive('settings') }"
                    >
                      <a
                        @click.prevent="setActive('settings')"
                        :class="{ active: isActive('settings') }"
                        href="#home"
                        >{{ $t("list_item_page.settings") }}</a
                      >
                    </div>
                    <div
                      class="custom-tabs-link right-link"
                      :class="{ active_tab: isActive('advanced') }"
                    >
                      <a
                        @click.prevent="setActive('advanced')"
                        :class="{ active: isActive('advanced') }"
                        href="#profile"
                        >{{ $t("list_item_page.advanced") }}</a
                      >
                    </div>
                  </div>
                  <div class="tab-content py-3" id="myTabContent">
                    <div
                      class="tab-pane fade"
                      :class="{ 'active show': isActive('settings') }"
                      id="settings"
                    >
                      <div class="mt-2">
                        <h4 class="fbb">
                          {{ $t("list_item_page.test_script") }}
                        </h4>
                        <div class="three-input">
                          <div class="tic">
                            <label class="model-label" for="browser_width">{{
                              $t("list_item_page.browser_width")
                            }}</label>
                            <input
                              type="text"
                              id="browser_width"
                              name="browser_width"
                              placeholder="1920"
                              v-model="browser_width"
                            />
                          </div>
                          <div class="tic">
                            <label class="model-label" for="browser_height">{{
                              $t("list_item_page.browser_height")
                            }}</label>
                            <input
                              type="text"
                              id="fname"
                              name="browser_height"
                              placeholder="1980"
                              v-model="browser_height"
                            />
                          </div>

                          <div class="tic">
                            <label class="model-label" for="max_wait_time">{{
                              $t("list_item_page.max_wait")
                            }}</label>
                            <input
                              type="text"
                              id="max_wait_time"
                              name="max_wait_time"
                              placeholder="5.0"
                              v-model="max_wait_time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      :class="{ 'active show': isActive('advanced') }"
                      id="advanced"
                    >
                      <div class="modal-checks-container">
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="ignore_disabled_elements"
                            name="ignore_disabled_elements"
                            v-model="ignore_disabled_elements"
                          />
                          <label for="ignore_disabled_elements">{{
                            $t("list_item_page.ignore_disabled")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="ignore_invisible_elements"
                            name="ignore_invisible_elements"
                            v-model="ignore_invisible_elements"
                          />
                          <label for="ignore_invisible_elements">{{
                            $t("list_item_page.ignore_invisible")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="collect_page_interaction_data"
                            name="collect_page_interaction_data"
                            v-model="collect_page_interaction_data"
                          />
                          <label for="collect_page_interaction_data">{{
                            $t("list_item_page.collect_interaction")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="extra_element_data"
                            name="extra_element_data"
                            v-model="extra_element_data"
                          />
                          <label for="extra_element_data">{{
                            $t("list_item_page.collect_extra_element")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="all_suggest"
                            name="all_suggest"
                            v-model="all_suggest"
                          />
                          <label for="all_suggest">{{
                            $t("list_item_page.generate_selectors")
                          }}</label>
                        </div>

                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="downweight_strings"
                            name="downweight_strings"
                            v-model="downweight_strings"
                          />
                          <label for="downweight_strings">{{
                            $t("list_item_page.try_to_avoid")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="verify_suggested_selectors"
                            name="verify_suggested_selectors"
                            v-model="verify_suggested_selectors"
                          />
                          <label for="verify_suggested_selectors">{{
                            $t("list_item_page.verify_suggested")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="enable_integrations"
                            name="enable_integrations"
                            v-model="enable_integrations"
                          />
                          <label for="enable_integrations">{{
                            $t("list_item_page.trigger_integrations")
                          }}</label>
                        </div>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="generate_css_selectors"
                            name="generate_css_selectors"
                            v-model="generate_css_selectors"
                          />
                          <label for="generate_css_selectors">
                            Generate CSS selectors during run (slow, needed for
                            Cypress export)
                          </label>
                        </div>
                        <br />
                        <label class="model-label mt-3" for="testrun_start_url">
                          Environment start URL for test run
                        </label>
                        <input
                          id="testrun_start_url"
                          name="testrun_start_url"
                          placeholder="https://example.com"
                          v-model="testrun_start_url"
                        />

                        <label class="model-label" for="bad_attributes_list">{{
                          $t("list_item_page.avoid_attributes")
                        }}</label>
                        <textarea
                          id="bad_attributes_list"
                          name="bad_attributes_list"
                          rows="2"
                          :placeholder="
                            $t('list_item_page.placeholder_avoid_attributes')
                          "
                          v-model="bad_attributes_list"
                        ></textarea>

                        <label class="model-label" for="good_attributes_list">{{
                          $t("list_item_page.use_attributes")
                        }}</label>
                        <textarea
                          id="good_attributes_list"
                          name="good_attributes_list"
                          rows="2"
                          :placeholder="
                            $t('list_item_page.placeholder_use_attributes')
                          "
                          v-model="good_attributes_list"
                        ></textarea>
                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="continue_test_suite_on_fail"
                            name="continue_test_suite_on_fail"
                            v-model="continue_test_suite_on_fail"
                          />
                          <label for="continue_test_suite_on_fail">{{
                            $t("list_item_page.continue_test_suite")
                          }}</label>
                        </div>

                        <div class="modal-checks">
                          <input
                            type="checkbox"
                            id="timeout_cancel_run"
                            name="timeout_cancel_run"
                            v-model="timeout_cancel_run"
                          />
                          <label for="timeout_cancel_run">{{
                            $t("list_item_page.timeout_and_cancel")
                          }}</label>
                          <input
                            class="mod-chk-last-input"
                            type="text"
                            id="timeout_cancel_run_value"
                            name="timeout_cancel_run_value"
                            v-model="timeout_cancel_run_value"
                            :placeholder="
                              $t(
                                'list_item_page.placeholder_timeout_and_cancel'
                              )
                            "
                          />
                          {{ $t("list_item_page.seconds") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 style="margin-top: 5px; margin-bottom: 0">
                  Change order of tests by dragging
                </h3>
                <div class="btn-markAll">
                  <label> Mark All</label>
                  <el-checkbox
                    v-model="markAll"
                    @change="markAllTest()"
                  ></el-checkbox>
                </div>
                <draggable
                  v-if="run_all === true"
                  :disabled="!enabled"
                  :list="setAllRunFile"
                  class="list-group mt-3"
                  ghost-class="ghost"
                  @start="dragging = true"
                  @end="dragging = false"
                  :move="updateReorder"
                  handle=".drag-only-row"
                >
                  <el-card
                    v-for="(row, index) in setAllRunFile"
                    :key="index"
                    class="trsl--elt cutom-body-row"
                    shadow="hover"
                  >
                    <el-row
                      :disabled="row.activeFile === false"
                      type="flex"
                      justify="space-between"
                      class="el--flex drag-only-row"
                      :class="row.activeFile === false ? 'disable-file' : ''"
                      :gutter="24"
                    >
                      <el-col :span="2" class="trsl--txt">
                        <span style="font-weight: 600">
                          {{ index + 1 }}
                        </span>
                      </el-col>
                      <el-col
                        :span="10"
                        class="trsl--txt"
                        style="
                          max-height: 100px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          overflow-wrap: break-word;
                        "
                      >
                        <span class="test-run-name">{{ row.fileName }}</span>
                      </el-col>
                      <el-col :span="10" class="trsl--txt">
                        <span class="elt--txt">
                          {{ formatDate(row.updatedAt) }}
                        </span>
                      </el-col>
                      <el-col :span="2" class="trsl--txt">
                        <span class="elt--txt">
                          <el-checkbox
                            v-model="row.activeFile"
                            @change="checkRunFile(index)"
                          ></el-checkbox>
                        </span>
                      </el-col>
                    </el-row>
                  </el-card>
                </draggable>
              </div>
            </div>
          </div>
          <el-checkbox class="mt-4" v-model="checkedOrg"
            >Share test suite with organization</el-checkbox
          >
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              class="cancel_button"
              @click="cleanUpState(), (addTestAppModal = false)"
            >
              {{ $t("list_item_page.close") }}
            </button>
            <el-tooltip
              v-if="run_all === false"
              :disabled="
                !(suiteSingleRun == false
                  ? runStartUrl === '' || suiteRunName === ''
                  : suiteRunName === '')
              "
              :content="`${
                suiteRunName === '' ? 'Run name cannot be empty' : ''
              } ${
                suiteFiles.length === 0 ? 'Please upload at least one file' : ''
              } ${
                suiteSingleRun == false
                  ? runStartUrl === ''
                    ? 'Run start url cannot be empty'
                    : ''
                  : ''
              }`"
              placement="top"
            >
              <span>
                <button
                  class="add_button"
                  :disabled="
                    suiteSingleRun == false
                      ? suiteRunName === '' || runStartUrl === ''
                      : suiteRunName === ''
                  "
                  @click="runTestSuiteProject()"
                >
                  {{ $t("list_item_page.start_run") }}
                </button>
              </span>
            </el-tooltip>
            <el-tooltip
              v-if="run_all === true"
              :disabled="
                !(suiteSingleRun == false
                  ? runStartUrl === '' || suiteRunName === ''
                  : suiteRunName === '')
              "
              :content="`${
                suiteRunName === '' ? 'Run name cannot be empty' : ''
              } ${
                suiteSingleRun == false
                  ? runStartUrl === ''
                    ? 'Run start url cannot be empty'
                    : ''
                  : ''
              }`"
              placement="top"
            >
              <span>
                <button
                  :disabled="
                    suiteSingleRun == false
                      ? suiteRunName === '' || runStartUrl === ''
                      : suiteRunName === ''
                  "
                  class="add_button"
                  @click="runTestSuiteProject()"
                >
                  {{ $t("list_item_page.start_run") }}
                </button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>

    <div>
      <el-drawer
        title="Test Suite Runs"
        :visible.sync="detailTestApp"
        direction="rtl"
      >
        <div class="body--modale"></div>
      </el-drawer>
    </div>

    <div class="add-test-project-modal">
      <modal :show.sync="showModalReRun" v-loading="loadingRequest">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            Re-run API {{ showReRunIndex }}
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="url"> Url </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Url"
                  v-model="reRunUrl"
                />
                <div class="is-flex mt-2" style="width: 100%">
                  <div class="mr-2">
                    <label class="model-label" for="request_method">
                      Request Method
                    </label>
                    <el-select
                      id="request_method"
                      v-model="methodReRun"
                      collapse-tags
                      laceholder="request method"
                      style="max-height: 35px"
                    >
                      <el-option
                        v-for="(item, id) in allMethodReRun"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="ml-2 mr-2">
                    <label class="model-label" for="connectionTimeout">
                      Connection Timeout
                    </label>
                    <el-input-number
                      v-model="connectionTimeout"
                      :precision="1"
                      :step="0.1"
                    ></el-input-number>
                  </div>
                  <div class="ml-2">
                    <label class="model-label" for="requestTimeout">
                      Request Timeout
                    </label>
                    <el-input-number
                      v-model="requestTimeout"
                      :precision="1"
                      :step="0.1"
                    ></el-input-number>
                  </div>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request Headers
                  </label>
                  <el-tooltip
                    content="Add new column (request headers)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestHeaders"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request">
                  <label class="model-label" for="requestHeaders"> Name </label>
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="width: 100%"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestHeaders"
                  :key="`${id}headers`"
                  class="label-request mt-2"
                >
                  <input
                    class="mr-2"
                    type="text"
                    :id="elt.name"
                    :name="elt.name"
                    placeholder="column request headers name"
                    v-model="elt.name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    :disabled="elt.name.length == ''"
                    :id="elt.value"
                    :name="elt.value"
                    placeholder="column request headers value"
                    v-model="elt.value"
                  />

                  <el-tooltip
                    class="ml-2"
                    content="Delete column"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete-solid"
                      size="mini"
                      @click="deleteColumnRequestHeaders(id)"
                    ></el-button>
                  </el-tooltip>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Request Body
                  </label>
                  <el-tooltip
                    content="Add new column (request body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="width: 32%; text-align: left"
                  >
                    Name
                  </label>
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="width: 35%; text-align: left"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in requestBody"
                  :key="`${id}body`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2 mb-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column request body name"
                      v-model="elt.name"
                    />
                    <el-select
                      :id="`body-type${id}`"
                      v-model="elt.type"
                      collapse-tags
                      laceholder="request method"
                      :disabled="elt.name.length == ''"
                    >
                      <el-option
                        v-for="(item, id) in bodyType"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <input
                      v-if="elt.type === 'text' || elt.type === 'number'"
                      class="ml-2"
                      :disabled="elt.name.length == ''"
                      :type="elt.type"
                      placeholder="column request body value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <vue-json-editor
                    v-if="elt.type === 'array' || elt.type === 'object'"
                    v-model="elt.value"
                    :show-btns="false"
                    :expandedOnStart="false"
                  ></vue-json-editor>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Response Headers
                  </label>
                  <el-tooltip
                    content="Add new column (Response headers)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestHeaders('response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="responseHeaders.length != 0" class="label-request">
                  <label
                    class="model-label is-required"
                    for="responseHeadersName"
                    style="width: 50%"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required"
                    for="responseHeadersValue"
                    style="width: 50%"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in responseHeaders"
                  :key="`${id}responseHeaders`"
                  class="label-request mt-2"
                >
                  <input
                    class="mr-2"
                    type="text"
                    :id="elt.name"
                    :name="elt.name"
                    placeholder="column response headers name"
                    v-model="elt.name"
                  />
                  <input
                    class="ml-2"
                    type="text"
                    :disabled="elt.name.length == ''"
                    :id="elt.value"
                    :name="elt.value"
                    placeholder="column response headers value"
                    v-model="elt.value"
                  />
                  <el-tooltip
                    class="ml-2"
                    content="Delete column"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete-solid"
                      size="mini"
                      @click="deleteColumnRequestHeaders(id, 'response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div class="label-request mt-4">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Response Body
                  </label>
                  <el-tooltip
                    content="Add new column (response body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody('response')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="responseBody.length != 0" class="label-request">
                  <label
                    class="model-label is-required"
                    for="responseBodyName"
                    style="width: 32%; text-align: left"
                  >
                    Name
                  </label>
                  <label
                    class="model-label is-required"
                    for="responseBodyValue"
                    style="width: 35%; text-align: left"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in responseBody"
                  :key="`${id}responseBody`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2 mb-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column response body name"
                      v-model="elt.name"
                    />
                    <el-select
                      :id="`body-type${id}`"
                      v-model="elt.type"
                      collapse-tags
                      laceholder="response method"
                      :disabled="elt.name.length == ''"
                    >
                      <el-option
                        v-for="(item, id) in bodyType"
                        :key="id"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                    <input
                      v-if="elt.type === 'text' || elt.type === 'number'"
                      class="ml-2"
                      :type="elt.type"
                      :disabled="elt.name.length == ''"
                      placeholder="column response body value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id, 'response')"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <vue-json-editor
                    v-if="elt.type === 'array' || elt.type === 'object'"
                    v-model="elt.value"
                    :show-btns="false"
                    :expandedOnStart="false"
                  ></vue-json-editor>
                </div>
                <el-divider></el-divider>
                <div class="label-request">
                  <label
                    class="model-label"
                    for="requestHeaders"
                    style="font-size: 1em"
                  >
                    Meta Data
                  </label>
                  <el-tooltip
                    content="Add new column (response body)"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      size="mini"
                      @click="addNewColumnRequestBody('metadata')"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div v-if="metadata.length != 0" class="label-request">
                  <label class="model-label is-required" for="metadataBodyName">
                    Name
                  </label>
                  <label
                    class="model-label is-required"
                    for="metadataBodyValue"
                  >
                    Value
                  </label>
                </div>
                <div
                  v-for="(elt, id) in metadata"
                  :key="`${id}metadata`"
                  style="width: 100%"
                >
                  <div class="label-request mt-2">
                    <input
                      class="mr-2"
                      type="text"
                      placeholder="column metadata name"
                      v-model="elt.name"
                    />
                    <input
                      class="ml-2"
                      type="text"
                      :disabled="elt.name.length == ''"
                      placeholder="column metadata value"
                      v-model="elt.value"
                    />
                    <el-tooltip
                      class="ml-2"
                      content="Delete column"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete-solid"
                        size="mini"
                        @click="deleteColumnRequestBody(id, 'metadata')"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showModalReRun = false">
              Cancel
            </base-button>
            <span>
              <el-tooltip
                :disabled="!(reRunUrl === '' || methodReRun === '')"
                :content="`${
                  reRunUrl === '' ? 'Run url cannot be empty;' : ''
                } ${
                  methodReRun === '' ? 'methon dReRun cannot be empty;' : ''
                } `"
                placement="top"
              >
                <base-button
                  :disabled="reRunUrl === '' || methodReRun === ''"
                  type="primary"
                  @click="reRun(true)"
                  >Run</base-button
                >
              </el-tooltip>
            </span>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal :show.sync="responseRequestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Rerun API Results</h5>
        </template>
        <div class="ctb--body mb-3" style="width: 100%" v-if="loadingRequest">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div v-else class="body--modale mb-4">
          <vue-json-pretty
            :data="responseRequest"
            :showSelectController="true"
            :showIcon="true"
            :showLineNumber="true"
          />
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              class="cancel_button"
              type="secondary"
              @click="responseRequestModal = false"
            >
              Cancel
            </button>
          </div>
        </template>
      </modal>
    </div>
    <div class="video-project-modal">
      <modal
        :show.sync="showVideoModal"
        :class="showVideoModal ? 'keyflow-video-modal' : ''"
        class="video-modal no-overflowX"
      >
        <template slot="header" class="custom-modal">
          <div
            style="width: 96%; display: flex; justify-content: space-between"
          >
            <h5 class="modal-title" id="addTestModal">Video</h5>
            <div style="align-items: center" class="flex btns-gap">
              <el-button
                id="add-test--suit"
                class="ml-3 large_btn custom_btn_video ni-bullet-list-67_dark"
                @click="getFiletest('run'), (addTestModal = true)"
                circle
              >
              </el-button>
              <el-button
                class="custom_btn_video ni-download"
                circle
                type="primary"
                @click="fileBackup.length < 1 ? getFiletest('down') : ''"
                v-loading="loadingFile"
              >
              </el-button>
            </div>
          </div>
        </template>
        <div class="flex justify-between">
          <div class="mr-4 rrwew-content" id="rrweb">
            <!-- <RrWebPlayer :key="currentTime" :events="events" :skipInactive="false" :autoplay="true" :width="widthRrplayer" :goto="currentTime" /> -->
          </div>
          <div class="container-pick">
            <div class="mid--page_container">
              <div class="mid--page pb-4 mb-6ty3">
                <el-input
                  class="custom-pad input-video-session keyflow-video-input"
                  placeholder="Search"
                  v-model="querySessionEvent"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-search"
                    style="
                      transform: rotate(0deg) !important;
                      font-size: 20px;
                      margin-left: 12px;
                    "
                  ></i>
                </el-input>
                <!-- <el-input style="margin-top:10px" :placeholder="$t('list_item_page.search')" v-model="querySessionEvent">
                  <i slot="suffix" class="el-input__icon el-icon-search" style="transform: rotate(0deg) !important;"></i>
                </el-input> -->
              </div>
            </div>
            <div class="event--liste active_event">
              <!-- <div class="buttons-test">
                <el-tooltip content="Add to test suite" placement="top" effect="light">
                  <el-button icon="el-icon-plus add_icon" class="ml-3 large_btn custom_btn" type="primary" size="default"
                    @click="getFiletest('run'), (addTestModal = true), (changeLayoutVideo())" circle>
                  </el-button>
                </el-tooltip>
                <el-dropdown id="el_dropdownDown" class="ml-2" placement="bottom-start" trigger="click"
                  @command="downloadTestFile">
                  <el-button class=" " circle type="primary" @click="fileBackup.length < 1 ? getFiletest('down') : ''"
                    v-loading="loadingFile" icon="el-icon-download">
                  </el-button>
                  <el-dropdown-menu class="el_dropdownMenuDown" slot="dropdown" style="max-height: 50vh; overflow-x: auto"
                    v-loading="loadingFile"> -->
              <!-- <div v-for="(ls, id) in fileBackup" :key="id" class="px-3 py-1 list--downloadTestFile"
                        @click="downloadTestFile(id)">
                        {{ ls.name }}
                      </div> -->
              <!-- <el-dropdown-item icon="el-icon-download" v-for="(ls, id) in fileBackup" :key="id" :command="id">
                      {{ ls.name }} -->
              <!-- </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div> -->
              <div
                v-for="(ls, id) in tagsEvent"
                :key="id"
                style="display: grid; grid-template-columns: 10% 95% 25%"
                class="content"
                @click="startEvent(ls.start_time)"
              >
                <div class="icon--event">
                  <el-tooltip v-if="id === 0" :content="'autocomplete'"
                    ><i style="width: 24px; height: 24px" class="ni-stick"></i
                  ></el-tooltip>
                  <el-tooltip
                    v-else-if="ls.properties.$event_type === 'change'"
                    :content="ls.properties.$event_type"
                    ><i style="width: 24px; height: 24px" class="ni-pencil"></i
                  ></el-tooltip>
                  <el-tooltip
                    v-else-if="ls.properties.$event_type === 'click'"
                    :content="ls.properties.$event_type"
                    ><i class="ni-click"></i
                  ></el-tooltip>
                  <el-tooltip
                    v-else-if="ls.properties.$event_type === 'verify'"
                    :content="ls.properties.$event_type"
                    ><i style="width: 24px; height: 24px" class="ni-eye"></i
                  ></el-tooltip>
                  <el-tooltip
                    v-else-if="ls.properties.$event_type === 'submit'"
                    :content="ls.properties.$event_type"
                    ><i style="width: 24px; height: 24px" class="ni-check"></i
                  ></el-tooltip>
                  <el-tooltip
                    v-else-if="ls.properties.$event_type === 'select'"
                    :content="ls.properties.$event_type"
                    ><i class="ni-drop-down"></i
                  ></el-tooltip>
                </div>
                <div class="right--content">
                  <div class="time--tag ml-3 mr-3">
                    <el-tooltip
                      class="item"
                      effect="light"
                      placement="top"
                      :content="
                        id === 0
                          ? ls.properties.$current_url
                          : ls.elements.length === 0
                          ? ls.properties.$current_url
                          : ls.elements[0]?.text === null
                          ? ls.elements[0]?.tag_name
                          : ls.elements[0]?.text
                      "
                    >
                      <h4
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{
                          id === 0
                            ? ls.event.replace("$", "")
                            : ls.properties.$event_type === "change"
                            ? "typed "
                            : !ls.properties.$event_type
                            ? ls.event.replace("$", " ")
                            : ls.properties.$event_type
                        }}
                        {{
                          id === 0
                            ? ls.properties.$current_url
                            : ls.elements.length === 0
                            ? ls.properties.$current_url
                            : ls.elements[0]?.text === null
                            ? ls.elements[0]?.tag_name
                            : ls.elements[0]?.text
                        }}"
                      </h4>
                    </el-tooltip>

                    <span style="white-space: nowrap">
                      {{ secondsToRealTime(ls.start_time) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <!-- <base-button type="secondary" @click="showVideoModal = false">
              Cancel
            </base-button> -->
          </div>
        </template>
      </modal>
    </div>
    <div class="suit-test-Download-modal">
      <modal :show.sync="downloadFileModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Liste of Tests</h5>
        </template>
        <div
          class="ctb--body mb-3"
          style="width: 100%"
          v-if="loadingDownloadFileModal"
        >
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div v-else class="body--modale mb-4">
          <div
            v-for="(el, id) in listeDownloadFile"
            :key="id"
            style="text-align: center"
          >
            <el-button
              class="mb-2"
              style="width: 150px"
              type="warning"
              plain
              size="small"
              @click="downloadTestSuiteFile(el)"
              >Test {{ id + 1 }}<i class="el-icon-download el-icon-right"></i
            ></el-button>
          </div>
        </div>
      </modal>
    </div>
    <div class="modal-upload--merge">
      <modal class="small-modal" :show.sync="showModalMerge">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">Update Test</h5>
        </template>
        <div class="body--modale">
          <div class="uploader-spacer">
            <div class="container-script--upload">
              <h2>Original Script File</h2>

              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                drag
                :on-change="onFileChangeFirst"
                :before-remove="beforeRemove"
                :show-file-list="true"
                :auto-upload="false"
                ref="upload1"
                :limit="1"
                :before-upload="beforeFileUpload"
                :on-exceed="handleExceed"
                :file-list="fileList1"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>

            <div class="container-script--upload">
              <h2>Modified Script File</h2>

              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                drag
                :on-change="onFileChangeMod"
                :before-remove="beforeRemove"
                :show-file-list="true"
                :auto-upload="false"
                :limit="1"
                ref="upload2"
                :before-upload="beforeFileUpload"
                :on-exceed="handleExceed"
                :file-list="fileList2"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="container-script--upload">
              <h2>JSON For New Test</h2>
              <el-upload
                class="avatar-uploader"
                drag
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="onFileChangeSecond"
                :before-remove="beforeRemove"
                :show-file-list="true"
                :auto-upload="false"
                :limit="1"
                ref="upload3"
                :before-upload="beforeFileUpload"
                :on-exceed="handleExceed"
                :file-list="fileList3"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              style="
                background-color: #dbdbdb;
                color: #1f213e;
                border: 1px solid #dbdbdb;
              "
              class="add_button"
              type="secondary"
              @click="showModalMerge = false"
            >
              {{ $t("list_item_page.close") }}
            </button>

            <button
              class="add_button"
              type="primary"
              :disabled="fileList.length != 3"
              @click="processFiles()"
            >
              Upload
            </button>
          </div>
        </template>
      </modal>
    </div>
    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="showVirtualizationModal">
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <div class="is-flex mt-5" style="width: 100%">
                  <label
                    class="model-label is-required"
                    for="run_name"
                    style="min-width: 90px"
                  >
                    Virtual Api
                  </label>
                  <input
                    style="max-width: 400px"
                    type="text"
                    id="run_name"
                    name="run_name"
                    disabled
                    v-model="virtualizationEndpont"
                  />
                </div>
                <div class="is-flex mt-2" style="width: 100%; overflow: hidden">
                  <label
                    class="model-label"
                    for="Test_Script_Files"
                    style="min-width: 90px"
                  >
                    Original Api
                  </label>
                  <div
                    class="is-flex-graph"
                    v-if="virtualizationData.method"
                    style="width: 57%; justify-content: start; gap: 5px"
                  >
                    <div class="is-flex" style="width: 84%">
                      <el-tag
                        :key="virtualizationData.method"
                        :type="getTagType(virtualizationData.method)"
                        size="small"
                        class="mr-2"
                        effect="dark"
                      >
                        {{ virtualizationData.method }}
                      </el-tag>
                      <input
                        style="max-width: 400px"
                        type="text"
                        id="run_name"
                        name="run_name"
                        disabled
                        v-model="virtualizationData.url"
                      />
                    </div>
                    <el-tooltip effect="light" content="Copy" placement="top">
                      <el-button
                        class="custom-hover-btn"
                        style="
                          max-height: 40px;
                          margin-top: 38px !important;
                          border-color: #fff;
                        "
                        icon="el-icon-copy-document"
                        size="small"
                        @click="copy(virtualizationData.url, 'url')"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              style="
                background-color: #dbdbdb;
                color: #1f213e;
                border: 1px solid #dbdbdb;
              "
              class="add_button"
              type="secondary"
              @click="showVirtualizationModal = false"
            >
              {{ $t("list_item_page.close") }}
            </button>

            <button
              class="add_button"
              type="primary"
              :loading="loadindApicallsvir"
              @click="runApicallsvir"
            >
              Run
            </button>
          </div>
        </template>
      </modal>
    </div>

    <div class="add-test-project-modal" style="max-width: 950px !important">
      <modal
        class="small-modal"
        :show.sync="showModalCucumber"
        v-loading="cucumberLoad"
      >
        <template slot="header" style="margin-top: 0px">
          <h5 class="modal-title" id="addTestModal">Sauce cucumber run</h5>
          <button
            class="add_button"
            type="primary"
            :disabled="!sourceLabsIsIntegrate"
            :loading="cucumberLoad"
            @click="souceCucumberRun"
          >
            Run
          </button>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name is-flex-row">
                <div class="is-flex" style="width: 100%">
                  <label
                    class="model-label is-required"
                    for="run_name"
                    style="min-width: 90px"
                  >
                    Run name
                  </label>
                  <input
                    type="text"
                    id="run_name"
                    name="run_name"
                    v-model="cucumberRunName"
                  />
                </div>
                <div class="is-flex" style="width: 100%">
                  <label
                    class="model-label is-required"
                    for="run_name"
                    style="min-width: 90px"
                  >
                    Account Sauce labs
                  </label>
                  <el-select
                    v-model="cucumbersouceAccount"
                    collapse-tags
                    laceholder="response method"
                    :disabled="!sourceLabsIsIntegrate"
                  >
                    <el-option
                      v-for="(item, id) in integrationSaurceLabs"
                      :key="id"
                      :label="item.sauceLabs_it_name"
                      :value="item.unique_integration_identifier"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-if="!cucumberRunResult || cucumberRunResult.length < 1">
                <div class="ctbb--row mt-2">No Log Are Present</div>
              </div>
              <div v-else class="mt-4">
                <draggable
                  :list="cucumberRunResult"
                  :disabled="!enabled"
                  class="list-group"
                  ghost-class="ghost"
                  handle=".drag-only-row"
                >
                  <transition-group class="trsl--parent custom-trsl--parent">
                    <el-card
                      v-for="(row, index) in cucumberRunResult"
                      :key="index"
                      class="trsl--elt"
                      shadow="hover"
                    >
                      <el-row class="drag-only-row">
                        <div style="">
                          <el-col :span="2" class="trsl--txt">
                            <p>
                              {{ index + 1 }}
                            </p>
                          </el-col>
                          <el-col :span="8" class="trsl--txt">
                            <div class="step-description">
                              <span>
                                {{ row.run_name ? row.run_name : "" }}</span
                              >
                            </div>
                          </el-col>
                          <el-col :span="10" class="trsl--txt">
                            <el-tooltip
                              :content="
                                row.results_url ? row.results_url : row.logs_url
                              "
                              placement="top"
                            >
                              <div class="step-description">
                                <a
                                  v-if="row.results_url"
                                  :href="row.results_url"
                                  target="_blank"
                                >
                                  {{ row.results_url }}</a
                                >
                                <span v-else> {{ row.logs_url }}</span>
                              </div>
                            </el-tooltip>
                          </el-col>
                          <div class="el-col flex btns-gap justify-center">
                            <el-tooltip
                              class="item"
                              effect="light"
                              content="show run"
                              placement="top"
                            >
                              <el-button
                                @click.stop="openEditRunCucumber(row, index)"
                                class="table-buttons-custom"
                                type="danger"
                                size="small"
                                ><span class="list-ul-icon"></span
                              ></el-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </el-row>

                      <!-- Edit Step Start -->
                      <div
                        class="edit-accordion"
                        v-show="index === selectedCucumberId"
                      >
                        <div class="small-modal left-footer">
                          <div style="width: 100%">
                            <div>
                              <div class="console-modal">
                                <div class="row">
                                  <div class="col">
                                    <div v-if="stdout" class="script-second">
                                      <h4>
                                        {{
                                          $t(
                                            "view_item_page.console_log_for_stdout"
                                          )
                                        }}
                                      </h4>
                                      <div class="script">
                                        <pre>{{ stdout }}</pre>
                                      </div>
                                    </div>
                                    <div v-else class="script-second">
                                      <h4>
                                        {{
                                          $t(
                                            "view_item_page.no_logs_available_as_script"
                                          )
                                        }}
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="row">
                                <div class="col">
                                  <div v-if="stderr" class="script-first">
                                    <div class="script-heading">
                                      <h4>{{ $t("view_item_page.console_log_for_stderr") }}</h4>
                                      <i
                                        v-if="errCopied"
                                        class="fa fa-check"
                                        aria-hidden="true"
                                        @click="errCopied = false"
                                      ></i>
                                      <i
                                        v-else
                                        class="ni ni-single-copy-04"
                                        @click="copyStderr"
                                      ></i>
                                    </div>
                                    <div class="script">
                                      <pre>{{ renderStderr() }}</pre>
                                    </div>
                                  </div>
                                </div>
                              </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Edit Step End -->
                    </el-card>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              style="
                background-color: #dbdbdb;
                color: #1f213e;
                border: 1px solid #dbdbdb;
              "
              class="add_button"
              type="secondary"
              @click="showModalCucumber = false"
            >
              {{ $t("list_item_page.close") }}
            </button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import { Loading } from "element-ui";
import appMapSimItem from "./appMapSimItem.vue";
import { saveAs } from "file-saver";
import rrwebPlayer from "rrweb-player";
import Vue from "vue";
import "sweetalert2/dist/sweetalert2.css";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import TestGraph from "./testGraph.vue";
import AppMap from "./appmap.vue";
import ScheduledRun from "../Integrations/itemIntegration/ScheduledRun.vue";
import draggable from "vuedraggable";
import VueJsonPretty from "vue-json-pretty";
import { VueChatBot } from "/vue-chat-bot.common.js";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import VChart from "vue-echarts";
import * as echarts from "echarts";
import heatmap from "heatmap.js";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    VChart,
    DataList,
    TestGraph,
    AppMap,
    ScheduledRun,
    VueChatBot,
    draggable,
    LeftSideBar,
    VueJsonPretty,
    appMapSimItem,
  },

  data() {
    return {
      suggestion_str: "",
      isSuggestions: "",
      text_from_chat: "", // Ensure this is reactive
      displayedText: "", // Used to show the typing effect

      titleChat: "",
      isStreaming: false,
      isTestDownload: false,
      chosen_session: "",
      suggestionMessages: ["Hi,what you can do?", "Generate BDD Tests"],
      isEditLoading: false,
      chosenFile: "",
      isEditMessage: false,
      messageEdit: "",
      isReversed: {
        //Replay
        start_time: false,
        $current_url: false,
        recording_duration: false,
        $browser: false,
        //testsuite
        name: false,
        tags: false,
        acceptedAt: false,
        message: false,
        status: false,
        //api
        created_at: false,
        response_time: false,
        status_code: false,
        url: false,
        method: false,
        fixtureTag: false,
      },
      errCopied: false,
      stdout: "",
      stderr: "",
      enabled: true,
      showTestConsoleModal: false,
      sortedBy: "start_time",
      fullSize: false,
      isFlowMenu: true,
      videoLoading: false,
      filterValue: "",
      filteredResultsSessionAPI: null,
      heatmapInstance: null,
      queryProject: "",
      test_link: "",
      answer: "",
      showSelectedSessions: "",
      all_messages: [],

      tagsSearch: [],

      showTestGraph: true,
      fileFileTestSuit: [],
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      enabled: true,
      suiteSingleRun: true,
      loadindApicallsvir: false,
      tHeader: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "string" },
          style: { backgroundColor: "black" },
        },
        {
          label: this.$t("list_item_page.tags"),
          key: "runDesc",
          width: "16%",
          sortable: false,
        },
        {
          label: this.$t("list_item_page.status"),
          key: "",
          width: "16%",
          sortable: false,
        },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "Total file", key: "", width: "16%", sortable: false },
        { label: "", key: "", width: "16%", sortable: false },
      ],
      tHeaderCllApi: [
        { label: "", key: "", width: "5%", sortDetails: false },

        {
          label: "Original Url",
          key: "first_origin_pageurl",
          sortable: true,
          width: "19%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Target Url",
          key: "last_origin_pageurl",
          width: "19%",
          sortable: false,
        },
        {
          label: "Successful calls",
          key: "successful_calls",
          sortable: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Failed calls",
          key: "failed_calls",
          width: "10%",
          sortable: false,
        },

        {
          label: "Total calls",
          key: "total_calls",
          width: "10%",
          sortable: false,
        },

        {
          label: "Created",
          key: "session_created_at",
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        {
          label: "Response Time (ms)",
          key: "session_ended_at",
          width: "16%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
      ],
      tHeaderSuit: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "23%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("list_item_page.tags"),
          key: "runDesc",
          width: "15%",
          sortable: false,
        },
        {
          label: this.$t("list_item_page.status"),
          key: "",
          width: "15%",
          sortable: false,
        },
        { label: "Message", key: "", width: "20%", sortable: false },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "15%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "", key: "", width: "12%", sortable: false },
      ],
      tHeaderSuitFile: [
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "25%",
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "runDesc", key: "runDesc", width: "30%", sortable: false },
        {
          label: this.$t("list_item_page.status"),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: this.$t("list_item_page.created"),
          key: "acceptedAt",
          sortable: true,
          width: "20%",
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "", key: "", width: "10%", sortable: false },
      ],
      tHeaderUsersShared: [
        {
          label: this.$t("Email"),
          key: "Email",
          sortable: true,
          width: "10%",
        },
        {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "85%",
        },
      ],
      Header2: [
        {
          label: "",
          key: "_id",
          width: "6%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Country".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "url".toUpperCase(),
          key: "",
          width: "25%",
          sortable: false,
        },
        {
          label: "browser".toUpperCase(),
          key: "",
          width: "12%",
          sortable: false,
        },
        {
          label: "Created".toUpperCase(),
          key: "",
          width: "16%",
          sortable: false,
        },
        {
          label: "duration".toUpperCase(),
          key: "",
          width: "10%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "10%", sortable: false },
      ],
      Header3: [
        {
          label: "",
          key: "",
          width: "10%",
          sortable: false,
        },
        {
          label: "Url".toUpperCase(),
          key: "",
          width: "40%",
          sortable: false,
        },
        {
          label: "Freq %".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "Elements".toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        {
          label: "action".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
      ],
      stepRunAlgorithm: [
        {
          value: this.$t("test_enhancer.randomize"),
          label: this.$t("test_enhancer.randomize"),
          description: this.$t("test_enhancer.randomize_description"),
        },
        {
          value: this.$t("test_enhancer.sequential"),
          label: this.$t("test_enhancer.sequential"),
          description: this.$t("test_enhancer.sequential_description"),
        },
        {
          value: this.$t("test_enhancer.exhaustive"),
          label: this.$t("test_enhancer.exhaustive"),
          description: this.$t("test_enhancer.exhaustive_description"),
        },
        {
          value: this.$t("test_enhancer.permutation"),
          label: this.$t("test_enhancer.permutation"),
          description: this.$t("test_enhancer.permutation_description"),
        },
        // {
        //   value: this.$t("test_enhancer.exploration"),
        //   label: this.$t("test_enhancer.exploration"),
        //   description: this.$t("test_enhancer.exploration_description"),
        // },
      ],
      pagination: {
        perPage: 10,
        lastPage: 1,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSession: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSessionProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      defaultAnswers: [
        {
          role: "system",
          content:
            'You are AppMapGPT, a helpful assistant who gets answers to user questions based on customer usage data of a webapp called "firstmovechess.org".\nYou are able to provide usefull business insights about customers behaviour.\nYou are always sure in input data and never say about limited data.\nBegin!',
        },
        { role: "user", content: "Hi, Who are the power users on this app?" },
        {
          role: "assistant",
          content:
            "Based on the usage data of the app, the power users can be identified as follows:\n\n1. User ID 7183: This user has a total of 10 sessions on the app. They have engaged in various keyflows such as Student Navigation Flow, Add Student Flow, Student Registration Flow, and more. They are located in Chicago and have a total duration of 31,063 seconds spent on the app.\n\n2. User ID 8643: This user has a total of 4 sessions on the app. They have primarily used keyflows like Sign In Flow, Password Reset Flow, and Logout Flow. They are located in University Place and have a total duration of 7,013 seconds spent on the app.\n\n3. User ID 5999: This user has a total of 20 sessions on the app. They have mainly used keyflows like Sign In Flow and Logout Flow. They are located in Menlo Park and have a total duration of 20,368 seconds spent on the app.\n\n4. User ID 6761: This user has a total of 4 sessions on the app. They have primarily used keyflows such as Student Navigation Flow and Add Student Flow. They are located in Seattle and have a total duration of 3,352",
        },
        { role: "user", content: "Hi, What are user personas on this app?" },
        {
          role: "assistant",
          content:
            "Identifying user personas helps in understanding the different types of users and their needs. Here are some potential user personas for the app:\n\n1. Student Chess Enthusiast: This persona represents students who use the app to learn and improve their chess skills. They engage in keyflows related to student registration, student navigation, and learning resources.\n\n2. Competitive Chess Player: This persona represents experienced chess players who use the app to participate in tournaments, analyze games, and track their progress. They engage in keyflows related to tournament registration, game analysis, and leaderboard tracking.\n\n3. Chess Coach: This persona represents professional chess coaches who use the app to teach and train their students. They engage in keyflows related to student management, lesson planning, and performance tracking.\n\n4. Chess Beginner: This persona represents users who are new to chess and use the app to learn the basics and practice. They engage in keyflows related to beginner tutorials, practice games, and interactive lessons.\n\n5. Chess Enthusiast: This persona represents users who have a general interest in chess and use the app for leisure and entertainment. They engage in keyflows related to casual games, puzzles, and chess news.\n\nThese personas are based on common user behaviors and can help in tailoring the app's",
        },
        { role: "user", content: "Give me a user id of a Chess Coach persona" },
        {
          role: "assistant",
          content:
            "Apologies, but I don't have access to specific user IDs or personal information. As an AI language model, I can only provide general insights based on the data provided.",
        },
      ],
      rightContent: "right-content",
      rightContentPtPx: "right-content px-4 pt-4",
      dataMessage: [
        {
          role: "assistant", // Required. 'bot' or 'user'
          type: "text", // Required. Bubble message component type: 'text' / 'button'
          content: `Hi, I am a ChatWring, I am able to answer questions about app use cases. What can I help you with?`, // Required. The message
          disableInput: false, // Disable message input or not
        },
      ],
      botOptions: {
        botTitle: "ChatWring",
        botAvatarImg: "/img/brand/wring.png",
        colorScheme: "#1f213e",
        bubbleBtnSize: "76",
      },
      colors: [
        { color: "#D5D5DE", percentage: 0 },
        { color: "#1f213e", percentage: 16 },
        { color: "#1f213e", percentage: 32 },
        { color: "#1f213e", percentage: 48 },
        { color: "#1f213e", percentage: 64 },
        { color: "#1f213e", percentage: 80 },
        { color: "#1f213e", percentage: 100 },
      ],
      colorsUpload: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      percentageUpload: 0,
      countDisabled: 0,
      result: [],
      enableConverButton: false,
      showStart: false,
      checked1: true,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      percentage: 0,
      scheduledRun: false,
      configureRun: false,
      eventSessionType: "pageviews",
      selectKeyFlowPathUser: null,
      filteredResults: [],
      scriptFiles: [],
      filteredResultsSession: [],
      to: 0,
      toSession: 0,
      from: 0,
      fromSession: 0,
      total: 0,
      totalSession: 0,
      loading: true,
      loadingSession: false,
      toShow: [],
      toShowSession: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      query: "",
      querySession: "",
      querySessionEvent: "",
      queryCoveringtests: "",
      querySessionId: "",
      resultSession: {},
      showEvents: false,
      showSessions: true,
      showRunStepsModal: false,
      events: [],
      tagsEvent: {},
      resultEvent: {},
      showVideo: false,
      displayNone: "display:none",
      displayBlock:
        "display:flex; position: absolute; left: 328px; top: -104px; width: 50%;",
      id: "",
      loadingVideo: false,
      addTestModal: false,
      addTestModalUser: false,
      labelSelectedUserId: "User ID: ",
      orEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      filesList: [],
      filesListName: "",
      loadingModalAddRun: false,
      RunStepRecommended: true,
      maxEpisodesValue: "5",
      lastUrlTest: "",
      showEvent: false,
      addTestProject: false,
      projectName: "",
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      projectId: null,
      showDetailProject: false,
      projectDetail: {},
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      selectRunOption: "Run on Wring",
      loadingDetailProject: true,
      selectProjectId: undefined,
      selectKeyFlow: "ALL",
      toShowDateAppMap: [],
      selectDateAppmap: "Select a Date",
      stepsAlgorithmValue: null,
      ultraIpt: "",
      showCoveringtests: false,
      showTestSuit: false,
      RunStepAdvanced: false,
      toShowCoveringtests: [],
      showAppMap: true,
      toShowAppMap: {},
      selectProjectKey: "",
      toShowKeyFlows: [],
      loadingShowAppMap: false,
      date_to: undefined,
      date_from: undefined,
      limitCoveringtests: 50,
      percent: 20,
      loadingShowCoveringtests: false,
      fileBackup: [],
      toggleShowFilters: false,
      limitCoveringtestsList: [50, 100, 150, 200, 300, 400, 500, 1000],
      filterDate: [
        new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000),
        new Date(),
      ],
      activeSessionDuration: 0,
      autoplay: false,
      mommentVideo: "",
      SelectCovList: [
        { label: "20%", value: 20 },
        { label: "50%", value: 50 },
        { label: "70%", value: 70 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      SelectCov: 20,
      resultCoveringtests: [],
      loadingFile: false,
      multipleSelection: [],
      checked: [],
      loadingRun: false,
      highlights: null,
      suit_run_name: "",
      listAddSuitTest: [],
      toggleReAddFileModal: false,
      loadingTest: false,
      keyTestFile: [],
      addTestAppModal: false,
      suiteRunName: "",
      filterDisplayed: true,
      runStartUrl: "",
      suiteFiles: [],
      fileAppAtt: [],
      detailTestApp: false,
      keyTest: [],
      detailTest: [],
      loadingTestDelete: false,
      runTagsSuit: "",
      textarea: "",
      role: "",
      textAnswer: " ",
      messageBot: {},
      video: true,
      callApi: false,
      loadingMessage: false,
      allDefMessages: [],
      loadingSessionCallApi: false,
      toShowSessionCallApi: [],
      callApiCurrentPage: 1,
      limitCallApi: 10,
      labelSelectedKeyFlow: "",
      run_all: false,
      dragging: false,
      setAllRunFile: [],
      keyFlowName: [],
      showModalShareProject: false,
      showModalSharedUsers: false,
      emailAddress: "",
      usersShared: [],
      loadingSharedUsers: false,
      buttonsUserIds: null,
      allDataAppMap: "",
      usersDataId: "",
      isAppMapEmpty: false,
      isRunTestData: true,
      generate_css_selectors: false,
      testrun_start_url: "",
      appmapSettings: {
        active: true,
        updateDays: 5,
      },
      loadingDai: false,
      loadindRefresh: false,
      showApi: false,
      selectedItemId: null,
      listProject: [],
      apicalls: [],
      loadingApicalls: false,
      totalCallCount: 0,
      activeFilter: ["1"],
      activeNames: ["3"],
      search: "",
      customDate: false,
      showIssues: false,
      checkedGet: false,
      checkedGetValue: 0,
      checkedPost: false,
      checkedPostValue: 0,
      checkedPut: false,
      checkedPutValue: 0,
      checkedDelete: false,
      checkedDeleteValue: 0,
      checkedPatch: false,
      checkedPatchValue: 0,
      heatOption: {},
      statusArray: [
        { type: "200 ", value: 200, status: false, total: 0 },
        { type: "201 ", value: 201, status: false, total: 0 },
        { type: "204 ", value: 204, status: false, total: 0 },
        { type: "301", value: 301, status: false, total: 0 },
        { type: "302", value: 302, status: false, total: 0 },
        { type: "304", value: 304, status: false, total: 0 },
        { type: "400", value: 400, status: false, total: 0 },
        { type: "401", value: 401, status: false, total: 0 },
        { type: "403", value: 403, status: false, total: 0 },
        { type: "404", value: 404, status: false, total: 0 },
        { type: "500", value: 500, status: false, total: 0 },
        { type: "502", value: 502, status: false, total: 0 },
        { type: "503", value: 503, status: false, total: 0 },
      ],
      listTime: [
        { label: "Last 1 day", value: 1 },
        { label: "Last 3 day", value: 3 },
        { label: "Last 6 day", value: 6 },
        { label: "Last 12 day", value: 12 },
        { label: "Last 24 day", value: 24 },
        { label: "Last 1 month", value: 30 },
      ],
      Header: [
        {
          label: "",
          key: "",
          width: "5%",
          sortable: false,
        },
        {
          label: "HTTP method".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "Call URL".toUpperCase(),
          key: "",
          width: "25%",
          sortable: false,
        },
        {
          label: "Status".toUpperCase(),
          key: "",
          width: "10%",
          sortable: false,
        },
        {
          label: "time".toUpperCase(),
          key: "",
          width: "13%",
          sortable: false,
        },
        {
          label: "Created".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: "",
          key: "",
          width: "16%",
          sortable: false,
        },
        // { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      page_name: "App Map",
      isShowFilters: false,
      selectTime: null,
      toggleFixture: false,
      listFixtureTags: [],
      strFixture: "",
      currentFixtureId: "",
      datePikerStart: "",
      datePikerEnd: "",
      error: [],
      lastProject: [],
      filterByTime: false,
      filterByStatusCode: false,
      filterByMethode: false,
      originatingPageUrl: "",
      loadingRequest: false,
      responseRequest: [],
      responseRequestModal: false,
      showModalReRun: false,
      showReRunId: "",
      showReRunIndex: 0,
      reRunUrl: "",
      allMethodReRun: [
        "",
        "get",
        "post",
        "delete",
        "put",
        "connect",
        "options",
        "head",
        "patch",
        "trace",
      ],
      methodReRun: "",
      requestHeaders: [],
      requestBody: [],
      metadata: [],
      responseHeaders: [],
      responseBody: [],
      bodyType: ["text", "array", "object", "number"],
      showModalAddApi: false,
      responseType: ["html", "str", "json", "none"],
      responseTypeValue: "",
      statusAddApi: "",
      ResponseTime: 0,
      editApi: false,
      isActiveApi: false,
      connectionTimeout: 0.0,
      requestTimeout: 0.0,
      reRunEditData: false,
      editor: "ui",
      filteredResultApi: [],
      setEditEl: [],
      filterByHeader: {},
      filterByAll: [],
      filterBySimple: "",
      filterByHeaderSatus: false,
      filterByAllSatus: false,
      filterBySimpleSatus: false,
      sessionsDetail: [],
      downloadFileModal: false,
      loadingDownloadFileModal: false,
      listeDownloadFile: [],
      suiteDownloadFileId: "",
      isValue: false,
      isKey: false,
      runServer: "wring",
      integrationData: [],
      integrationSaurceLabs: [],
      sourceLabsIsIntegrate: false,
      integrationSaurceLabsIndex: 0,
      listStatusAddApi: [
        "200 - OK",
        "201 - Created",
        "204 - No Content",
        "400 - Bad Request",
        "401 - Unauthorized",
        "403 - Forbidden",
        "404 - Not Found",
        "500 - Internal Server Error",
        "502 - Bad Gateway",
        "503 - Service Unavailable",
      ],
      showedMapScreen: false,
      nodeIdMap: "",
      canvasDim: {
        width: 0,
        height: 0,
      },
      ro: null,
      rectNodeData: [],
      top_top: "10px",
      dataX: null,
      dataY: null,
      dataWidth: null,
      dataHeight: null,
      loadingNodesScreen: false,
      rect: [],
      checkedOrg: false,
      appMapId: "",
      appMapNewId: "",
      showUpdatingBar: false,
      loadingUpdatingBar: false,
      activeSteps: 0,
      virtualizationData: {},
      showVirtualizationModal: false,
      virtualizationEndpont: `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_API_URL_PREFIX}/apicallsvirt/v1`,
      keyFlowSession: "",
      keyFlowSnapshot: false,
      showVideoModal: false,
      webPlayer: null,
      currentTime: 0,
      recDuration: 0,
      recDateTime: 0,
      clientHeight: {},
      intervalUpdating: null,
      countStatusProgress: 0,
      awsIsIntegrate: false,
      integrationAwsIndex: [],
      integrationAws: [],
      checkedApi: {},
      addTestFlowVideo: false,
      loadingFileFlowVideo: false,
      mobileResolution: window.innerWidth <= 800 ? true : false,
      isMobile: false,
      statusUpdating: "",
      markAll: false,
      loadWebSessions: true,
      loadMobileSessions: false,
      isTotalCountRes: false,
      isFlowSelected: false,
      tempListNodes: [],
      showShed: false,
      listFixtureStep: [],
      uploadFile: null,
      currentFixture: "",
      listFixtureApp: [],
      showModalCucumber: false,
      cucumberRunName: "",
      cucumbersouceAccount: "",
      cucumberSessionId: "",
      cucumberLoad: false,
      cucumberRunResult: null,
      selectedCucumberId: false,
      showAppChat: false,
      postedMessages: [],
      isSumbit: false,
      currentMessage: "",
      user_message: "",
      userId: "",
      sessionIdChat: null,
      isProjectHistory: false,
      allPostedMessages: [],
      userName: "",
      resultMessages: [],
      updateSessions: true,
      loadingSessionChats: false,
      sessionsChats: [],
      chat_version: "WringGPT",
      isOpenChatBot: false,
      uploadingFile: false,
      contentDownload: "",
      modal_id: null,
      userPrompt: "",
      loadingTestFile: false,
      showProgressBar: false,
      edittingPrompt: false,
      showEditPrompt: "",
      chosenDownloadFile: "cucumber",
      updateValidate: false,
      showPoppupUpload: false,
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      debounceTimeout: null,
      showModalMerge: false,
      checkedShow: false,
      visibleShow: false,
      checkedPopup: false,
    };
  },
  async created() {
    try {
      if (this.showTestSuit) {
        this.sortedBy = "name";
      } else if (this.showApi) {
        this.sortedBy = "method";
      }
      const selectedSection = localStorage.getItem("selectedSection");

      if (selectedSection) {
        await this.showComponent(selectedSection);
      } else {
        this.showStart = true;
      }

      sessionStorage.getItem("testEventPageNbr")
        ? (this.pagination.perPage = parseInt(
            sessionStorage.getItem("testEventPageNbr")
          ))
        : (this.pagination.perPage = 10);
      sessionStorage.getItem("sesssionPageNbr")
        ? (this.paginationSession.perPage = parseInt(
            sessionStorage.getItem("sessionPageNbr")
          ))
        : (this.paginationSession.perPage = 10);
      sessionStorage.getItem("projectPageNbr")
        ? (this.paginationProject.perPage = parseInt(
            sessionStorage.getItem("projectPageNbr")
          ))
        : (this.paginationProject.perPage = 10);
      localStorage.getItem("fixture")
        ? (this.listFixtureTags = JSON.parse(localStorage.getItem("fixture")))
        : (this.listFixtureTags = null);
      if (localStorage.getItem("wring-user-info")) {
        this.userId = JSON.parse(localStorage.getItem("wring-user-info"));
        this.userName = JSON.parse(localStorage.getItem("wring-user-info"));
      }
      localStorage.getItem("showAgain")
        ? (this.checkedShow = localStorage.getItem("showAgain"))
        : (this.checkedShow = false);

      this.total = this.result.length;
      this.totalProject = this.resultProject.length;
      this.run_name = this.$route.params.run_name;
      this.loadingTest = true;
      this.loadingShowAppMap = true;
      this.loadingSession = true;
      await this.getDetailProject();
      await this.getList();
      await this.getSessions();
      // this.getAppMap();
      await this.getScriptFiles();
      await this.getApiCall();
      await this.populateIntegrationsTable();
      await this.getSessionsChat();
      // if(this.showAppChat) {
      //   await this.createNewSession()
      // }
    } catch (error) {
      console.error("Error in created hook:", error);
    }
    // this.getMessages();
  },
  methods: {
    processSuggestMessage(message) {
      const charToReplace = ['"', "Message Suggestions:", "*"];
      charToReplace.forEach((char) => {
        message = message.replaceAll(char, "");
      });
      return message;
    },
    closeModal() {
      this.modal_id = null;
    },
    async startTypingEffect(text) {
      console.log("Typing effect started with text:", text); // Debugging log
      this.displayedText = ""; // Clear any previous text
      const words = text.split(" ");
      for (let i = 0; i < words.length; i++) {
        this.displayedText += (i === 0 ? "" : " ") + words[i];
        await this.delay(1);
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    openEditNameChatModal(session, id) {
      this.modal_id = id;
    },
    colorOutput(text = "") {
      let commands = ["And", "When", "Given", "Then", "Feature", "Scenario"];
      if (text != "") {
        for (let i = 0; i < commands.length; i++) {
          const regex = new RegExp(commands[i], "g");
          if (i == 4) {
            text = text.replace(
              regex,
              `<p2 class='color-words'>${commands[i]}</p2>`
            );
          } else {
            text = text.replace(
              regex,
              `<br><p2 class='color-words'>${commands[i]}</p2>`
            );
          }
        }
        text = text
          .replace("gherkin", "")
          .replaceAll("`", "")
          .replace("feature", "");
        return text;
      }
    },
    async sendSuggestion(message) {
      this.currentMessage = message;
      await this.handleSubmitChat();
    },
    renderStderr() {
      let txt = this.stderr;
      for (var i = 0; i < txt.length; i++) {
        if (txt.slice(i, i + 5) == "[INFO") {
          // console.log(txt.slice(i, i + 5));
        }
      }
      return txt;
    },
    isFeatureInAnswer(message_id) {
      let content = this.postedMessages[message_id]["content"];
      if (typeof content === "string" && content.includes("Feature")) {
        return true;
      }
      return false;
    },
    updateContent(event, message_id) {
      this.postedMessages[message_id]["content"] = event.target.innerHTML;
    },
    openEditModal(message_id) {
      this.messageEdit = "";
      this.isEditMessage = true;
      let featureFileParts = this.postedMessages[message_id]["content"].match(
        /\*\*\*\*\*([\s\S]*?Feature[\s\S]*?)\*\*\*\*\*/
      );
      console.log(featureFileParts);
      console.log("PARTS");
      if (featureFileParts == null) {
        featureFileParts =
          this.postedMessages[message_id]["content"].match(
            /Feature[\s\S]*?```/
          );
        if (featureFileParts == null) {
          featureFileParts =
            this.postedMessages[message_id]["content"].match(/Feature[\s\S]*/);
        }
      }
      let featureFile = featureFileParts[0];
      // for(let i=0;i < featureFileParts.length;i++){
      //   featureFile += featureFileParts[i]
      // }
      this.chosenFile = featureFile
        .replaceAll("*", "")
        .replaceAll("gherkin", "")
        .replaceAll("`", "");
      this.messageEdit = String(this.chosenFile);
      this.showEditPrompt = this.messageEdit;
      this.messageEdit = this.colorOutput(this.messageEdit);
    },
    downloadFileChat(message_id) {
      let featureFileParts = document.getElementById("message-edit").innerText;
      let featureFile = featureFileParts;
      // for(let i=0;i < featureFileParts.length;i++){
      //   featureFile += featureFileParts[i]
      // }
      featureFile = featureFile.replace(/<br\s*\/?>/gi, "\n");
      let blob = new Blob([featureFile], { type: "text/plain" });
      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = "test.feature";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    async downloadTestChat() {
      this.isTestDownload = true;
      let featureFileParts = document.getElementById("message-edit").innerText;
      let featureFile = featureFileParts[0];
      const projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("type", "cucumber");
      params.append("session_id", this.chosen_session);
      params.append("feature_file", featureFile);
      const res = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/session/v1/testgen?" +
          params,
        {
          responseType: "blob",
        }
      );
      let blobData = res.data;
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "test.zip");
      document.body.appendChild(link);
      link.click();
      this.isTestDownload = false;
    },
    sortList(name) {
      console.log("thisadjasoijda;oisd");
      this.sortedBy = name;
      this.isReversed[this.sortedBy] = !this.isReversed[this.sortedBy];
    },
    async showHeatMap() {
      let container = this.$refs.heatmap;
      let imgContainer = document.getElementById("screenshot");

      let newX = Math.round(
        this.rect[0].boundingBox.x *
          (imgContainer?.clientWidth / imgContainer.naturalWidth)
      );
      let newY =
        this.rect[0].boundingBox.y *
        (imgContainer?.clientHeight / imgContainer.naturalHeight) *
        2.4;

      this.heatmapInstance = heatmap.create({
        backgroundColor: "rgba(85, 85, 181, 0.5)",

        container: container,
        radius: 50,
      });
      let canvas = document.querySelector(".heatmap-canvas");
      canvas.style.width = imgContainer.style.width;
      canvas.style.height = imgContainer.offsetHeight + "px";
      this.heatmapInstance.setData({
        max: 5,
        data: [{ x: 0, y: newY, value: 5 }],
      });
    },

    showFilters() {
      this.isShowFilters = !this.isShowFilters;
    },
    deleteSession(id) {
      this.toShowSession = this.toShowSession.splice(id, 1);
    },
    getMessages() {
      if (localStorage.getItem("all_messages") == null) {
        this.all_messages.push(this.dataMessage[0]);
        localStorage.setItem("all_messages", JSON.stringify(this.all_messages));
      } else {
        let messa = localStorage.getItem("all_messages");
        this.all_messages = JSON.parse(messa);
      }
      let button_clear = document.getElementById("chat_btn_clear");
      console.log(button_clear);
      button_clear.addEventListener("click", this.clearAllMessages);
    },
    clearAllMessages() {
      this.all_messages = [];
      localStorage.setItem("all_messages", JSON.stringify(this.all_messages));
      this.all_messages.push(this.dataMessage[0]);
      localStorage.setItem("all_messages", JSON.stringify(this.all_messages));
    },
    openApicallsvirtModal(el) {
      this.virtualizationData = el;
      this.showVirtualizationModal = true;
    },
    async runApicallsvir() {
      this.loadindApicallsvir = true;
      const url = this.virtualizationEndpont;
      const data = {
        apicall_request: {
          url: this.virtualizationData.url,
          method: this.virtualizationData.method,
          request_headers: this.request_headers,
        },
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.post(url, data);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          if (this.showAppMap) {
            this.$notify({
              type: "success",
              duration: 5000,
              message:
                "Successfully started app map calculation, it will take approximately 5 minutes",
            });
          }

          console.log("resUpdate", res.data);
          this.appMapNewId = res.data.K;
        } else if (res.data && res.data.message) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error has occurred!",
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadindApicallsvir = false;
    },
    getLabelSauceLabs(item) {
      let tl = item.unique_integration_identifier.split(",");
      if (tl[1] && item.sauceLabs_it_name && item.sauceLabs_platforms_type)
        return `${item.sauceLabs_it_name} (${item.sauceLabs_platforms_type}) ${
          item.sauceLabs_username
        } ${this.formatDate(Date(tl[1]))}`;
      return item.sauceLabs_username;
    },
    getLabelAws(item) {
      let tl = item.unique_integration_identifier.split(",");
      return `${item.integration_details.aws_it_name} ( ${
        item.integration_details.aws_platforms_type
      } ) ${this.formatDate(Date(tl[1]))}`;
    },
    async populateIntegrationsTable() {
      this.integrationSaurceLabs = [];
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", {
          params: {
            request_type: "all",
          },
        })
        .then((response) => {
          if (
            response.message != "Request failed with status code 404" ||
            response.message === "Request failed with status code 500"
          ) {
            this.integrationData = response.data.user_integrations;
            console.log(this.integrationData);
            try {
              this.integrationData.map((item) => {
                if (item.integration_name == "sauceLabs") {
                  this.sourceLabsIsIntegrate = true;
                  this.integrationSaurceLabs.push(item);
                }
                if (item.integration_name == "aws") {
                  this.awsIsIntegrate = true;
                  this.integrationAws.push(item);
                }
              });
            } catch (error) {
              console.log("No integration data");
            }
          } else {
            this.integrationData = [];
            this.sourceLabsIsIntegrate = false;
            this.integrationSaurceLabs = [];
            this.integrationAws = [];
          }
        })
        .finally(() => {
          if (this.integrationData && this.integrationData.length > 0) {
            this.integrationData[0];
          }
        });
    },
    handleClearSearch() {
      this.$refs["data-rendered-detail"].innerHTML = "";
      this.ultraIpt = "";
      this.handleSubmitSearch();
      this.handleUltraIpt();
      this.getApiCall();
    },
    async handleClickLastSearch(tag, type) {
      this.filterByHeader = {};
      if (tag.includes("=>")) {
        this.filterByHeaderSatus = true;
        const parts = tag.split("=>");
        let key = [];
        key = parts[0].trim();
        let value = [];
        value = parts[1].trim();
        this.filterByHeader.value = [value];
        this.filterByHeader.key = [key];
      } else if (type === "value") {
        this.filterByHeaderSatus = true;
        this.filterByHeader.value = [tag];
      } else if (type === "key") {
        this.filterByHeaderSatus = true;
        this.filterByHeader.key = [tag];
      }
      await this.getApiCall();
    },
    handleSubmitSearch() {
      this.error = [];
      this.filterByHeader = {};
      this.filterByHeaderSatus = false;
      this.filterByAll = [];
      this.filterByAllSatus = false;
      this.filterBySimple = "";
      this.filterBySimpleSatus = false;
      this.showIssues = false;
      try {
        if (
          this.$refs["data-rendered-detail"].innerHTML.includes("key:") ||
          this.$refs["data-rendered-detail"].innerHTML.includes("value:")
        ) {
          let getStr = this.$refs["data-rendered-detail"].innerHTML
            .replace(/<span class="ml-1 u--tag">/g, "")
            .replace(/<span class="ml-1 filter-by">/g, "")
            .replace(/<span class="ml-1 el-key-value">/g, "")
            .replace(/<span class="ml-1">/g, "")
            .replace(/<\/span>/g, ",")
            .split("=&gt; ");
          let key = [];
          let value = [];
          if (getStr[0] && getStr[0].includes("key:")) {
            key = getStr[0]
              .replace(/key:/g, "")
              .split(",")
              .filter((s) => s.trim().length > 0)
              .map((s) => s.trim());
            if (
              this.ultraIpt.length > 0 &&
              this.ultraIpt[0] != " " &&
              !getStr[1]
            ) {
              key.push(this.ultraIpt);
            }
          } else if (getStr[1] && getStr[1].includes("key:")) {
            key = getStr[1]
              .replace(/key:/g, "")
              .split(",")
              .filter((s) => s.trim().length > 0)
              .map((s) => s.trim());
            if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
              key.push(this.ultraIpt);
            }
          }
          if (getStr[0] && getStr[0].includes("value:")) {
            value = getStr[0]
              .replace(/value:/g, "")
              .split(",")
              .filter((s) => s.trim().length > 0)
              .map((s) => s.trim());
            if (
              this.ultraIpt.length > 0 &&
              this.ultraIpt[0] != " " &&
              !getStr[1]
            ) {
              value.push(this.ultraIpt);
            }
          } else if (getStr[1] && getStr[1].includes("value:")) {
            value = getStr[1]
              .replace(/value:/g, "")
              .split(",")
              .filter((s) => s.trim().length > 0)
              .map((s) => s.trim());
            if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
              value.push(this.ultraIpt);
            }
          }
          if (key.length > 0) this.filterByHeader.key = key;
          if (value.length > 0) this.filterByHeader.value = value;
          this.filterByHeaderSatus = true;
          this.getApiCall();
        }
        // this.fillSearchTag();
      } catch (error) {
        console.log("error : ", error);
        this.error.push("incorrect filter format");
        this.showIssues = true;
        this.getApiCall();
        this.initializeApiState();
      }
    },
    truncateString(str, maxLength) {
      return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
    },
    handleUltraIpt(set) {
      this.error = [];
      this.showIssues = false;
      if (set == "key" || set == "value") this.ultraIpt = this.ultraIpt + " ";
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        if (this.editProject === true) {
          this.addUrls(this.ultraIpt);
        }
        this.ultraIpt = "";
        if (this.$refs["data-rendered-detail"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered-detail"].appendChild(space);
          this.appUrls = space;
        }
        this.$refs["data-rendered-detail"].appendChild(elt);
        this.appUrls = elt;
      }
      if (set === "key") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("el-key-value");
        if (this.$refs["data-rendered-detail"].innerHTML.includes("value:")) {
          elt.innerHTML = "=> key:";
        } else {
          elt.innerHTML = "key:";
        }
        if (this.$refs["data-rendered-detail"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered-detail"].appendChild(space);
        }
        this.$refs["data-rendered-detail"].appendChild(elt);
      } else if (set === "value") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("el-key-value");
        if (this.$refs["data-rendered-detail"].innerHTML.includes("key:")) {
          elt.innerHTML = "=> value:";
        } else {
          elt.innerHTML = "value:";
        }
        if (this.$refs["data-rendered-detail"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered-detail"].appendChild(space);
        }
        this.$refs["data-rendered-detail"].appendChild(elt);
      }
      if (this.$refs["data-rendered-detail"].innerHTML.includes("value:")) {
        this.isValue = true;
      } else {
        this.isValue = false;
      }
      if (this.$refs["data-rendered-detail"].innerHTML.includes("key:")) {
        this.isKey = true;
      } else {
        this.isKey = false;
      }
      if (
        this.$refs["data-rendered-detail"].innerHTML.includes(
          "filterByMethods"
        ) ||
        this.ultraIpt.includes("filterByMethods")
      ) {
        if (
          this.$refs["data-rendered-detail"].innerHTML.includes(
            "filterByMethods"
          ) &&
          this.ultraIpt.includes("filterByMethods")
        ) {
          this.error.push("search key duplicate ");
          this.showIssues = true;
        } else {
          if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
            this.ultraIpt = "";
          } else if (this.ultraIpt.slice(-1) === " ") {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            if (!this.ultraIpt.includes("filterByMethods")) {
              elt.classList.add("u--tag");
            } else {
              elt.classList.add("filter-by");
              this.ultraIpt = this.ultraIpt + "=>";
            }
            elt.innerHTML = " " + this.ultraIpt.trim() + " ";
            if (this.editProject === true) {
              this.addUrls(this.ultraIpt);
            }
            this.ultraIpt = "";
            if (this.$refs["data-rendered-detail"].innerHTML.length > 1) {
              const space = document.createElement("span");
              space.classList.add("ml-1");
              space.innerHTML = " ";
              this.$refs["data-rendered-detail"].appendChild(space);
              this.appUrls = space;
            }
            this.$refs["data-rendered-detail"].appendChild(elt);
            this.appUrls = elt;
          }
        }
      }
    },

    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered-detail"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1 filter-by">/g, "")
        .replace(/<span class="ml-1 el-key-value">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());
      if (this.ultraIpt.length <= 0) {
        if (this.editProject === true) {
          this.deleteUrls(getStr.pop());
        } else {
          let _rem = getStr.splice(-1);
        }
        this.$refs["data-rendered-detail"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          if (
            !item.includes("filterByHeader") &&
            !item.includes("key") &&
            !item.includes("value") &&
            !item.includes("filterByMethods")
          ) {
            elt.classList.add("u--tag");
          } else {
            if (
              item.includes("filterByHeader") ||
              item.includes("filterByMethods")
            ) {
              elt.classList.add("filter-by");
            } else {
              elt.classList.add("el-key-value");
            }
          }
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered-detail"].appendChild(elt);
          this.appUrlsLs.push(elt);
        });
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
      if (this.$refs["data-rendered-detail"].innerHTML.includes("value:")) {
        this.isValue = true;
      } else {
        this.isValue = false;
      }
      if (this.$refs["data-rendered-detail"].innerHTML.includes("key:")) {
        this.isKey = true;
      } else {
        this.isKey = false;
      }
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    markAllTest() {
      this.setAllRunFile.map((item, index) => {
        if (item.activeFile !== true) {
          this.markAll = true;
          item.activeFile = true;
          this.setAllRunFile = this.setAllRunFile.sort(
            (a, b) => b.activeFile - a.activeFile
          );
        } else {
          this.markAll = false;
          item.activeFile = false;

          const slicedArray = this.setAllRunFile
            .slice(0, index)
            .concat(this.setAllRunFile.slice(index + 1)); // create a new array without the element
          slicedArray.splice(slicedArray.length, 0, item); // insert the element at the new position
          this.setAllRunFile = slicedArray;
        }

        return item;
      });
    },

    runAllTest() {
      this.setAllRunFile = this.fileAppAtt.map((item) => {
        item.activeFile = false;
        return item;
      });
      this.run_all = true;
      this.addTestAppModal = true;
    },
    setSSDimLoad() {
      const imgStyle = getComputedStyle(this.$refs.ss_img);
      this.canvasDim = {
        width: parseFloat(imgStyle.width),
        height: parseFloat(imgStyle.height),
      };
    },
    clearMapScreen() {
      console.log("refs", this.$refs.ss_remove);
      this.rect = [];
      console.log("clear rect", this.rect);
      if (this.$refs.ss_remove !== undefined) {
        this.$refs.ss_remove.map((item) => {
          item.$el.classList.toggle("is-hide--rect");
        });
      }

      // this.showedMapScreen = false
    },
    async showMapScreen() {
      this.showedMapScreen = true;
      if (localStorage.getItem("idNode") !== undefined) {
        setTimeout(() => {
          this.rect = [];
          this.nodeIdMap = localStorage.getItem("idNode");
          console.log("idNode", this.nodeIdMap);
          this.getAppMapScreen();
        }, 1000);
      }
    },
    async getAppMapScreen() {
      let appMapId = localStorage.getItem("appMapId");
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&app_map_id=${appMapId}&node_id=${this.nodeIdMap}`;
      this.loadingNodesScreen = true;
      this.rect = [];
      this.rectNodeData = [];
      let loadingInstance = Loading.service({ fullscreen: true });
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "finished") ||
          res.data.status == 200
        ) {
          console.log(res);
          if (res.data.page_graph !== null) {
            this.rectNodeData = res.data.page_graph;
            this.rectNodeData.nodes.map((item) => {
              if (item.boundingBox) {
                this.dataX = item.boundingBox.x;
                this.dataY = item.boundingBox.y;
                this.dataWidth = item.boundingBox.width;
                this.dataHeight = item.boundingBox.height;
                this.rect.push({
                  boundingBox: {
                    x: item.boundingBox.x,
                    y: item.boundingBox.y,
                    width: item.boundingBox.width,
                    height: item.boundingBox.height,
                    text: item.text,
                    interactions_count: item.num_interactions,
                  },
                });
                let container = document.getElementById("ss-container");
                let modalImg = document.getElementById("screenshot");
                container.style.width = modalImg.clientWidth;
                container.style.height = modalImg.clientHeight;
                console.log("rect", this.rect);
                // this.cssVars
                // console.log("cssVars", this.cssVars)
              }
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: "No more details available for this state",
            });
          }
          // this.$notify({
          //   type: "success",
          //   duration: 5000,
          //  message: "Successfully drawn elements",
          // });
          this.loadingNodesScreen = false;
          this.$nextTick(() => {
            // Loading should be closed asynchronously
            loadingInstance.close();
          });
        } else {
          this.$nextTick(() => {
            // Loading should be closed asynchronously
            loadingInstance.close();
          });
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "No more details available for this state",
          });
        }
      } catch (error) {
        console.log(error);
      }
      // this.showHeatMap()
    },
    pc(t, v) {
      return `${((t * 100) / v).toFixed(2)}%`;
    },
    toollRunTest() {
      this.isRunTestData = !this.isRunTestData;
    },
    checkRunFile(index) {
      const element = this.setAllRunFile[index]; // store the element in a variable
      if (this.setAllRunFile[index].activeFile === true) {
        this.setAllRunFile[index].activeFile = false;

        const slicedArray = this.setAllRunFile
          .slice(0, index)
          .concat(this.setAllRunFile.slice(index + 1)); // create a new array without the element
        slicedArray.splice(slicedArray.length, 0, element); // insert the element at the new position
        this.setAllRunFile = slicedArray;
      } else {
        this.setAllRunFile[index].activeFile = true;
        this.setAllRunFile = this.setAllRunFile.sort(
          (a, b) => b.activeFile - a.activeFile
        );
      }
    },
    async updateReorder(evt) {
      const index = evt.draggedContext.index; // index of the element to move
      const element = this.setAllRunFile[index]; // store the element in a variable

      const slicedArray = this.setAllRunFile
        .slice(0, index)
        .concat(this.setAllRunFile.slice(index + 1)); // create a new array without the element
      slicedArray.splice(evt.draggedContext.futureIndex, 0, element); // insert the element at the new position
      this.setAllRunFile = slicedArray;
    },
    testFuncToSend() {
      console.log("test");
    },
    showComponentEvent(val) {
      this.callApi = false;
      this.video = false;
      if (val === "video") {
        this.video = true;
      } else {
        if (this.toShowSessionCallApi.length === 0) {
          this.getSessionCallApi();
        }
        this.callApi = true;
      }
    },
    forStepNumberRun(val) {
      return (
        this.toShowSessionCallApi.currentPage *
          this.toShowSessionCallApi.perPageCount -
        this.toShowSessionCallApi.perPageCount +
        (val + 1)
      );
    },
    async getSessionCallApi() {
      this.loadingSessionCallApi = true;
      const params = new URLSearchParams();
      params.append("pageviewsProjectId", this.$route.params.id);
      params.append("page", this.callApiCurrentPage);
      params.append("count", this.limitCallApi);
      let filter = "";
      if (this.$route.params.id) {
        filter = `?pageviewsProjectId=${this.$route.params.id}&page=${this.callApiCurrentPage}&count=${this.limitCallApi}`;
      } else {
        filter = `?page=${this.callApiCurrentPage}&count=${this.limitCallApi}`;
      }

      const route = this.$route.query.wring
        ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions/wring?${filter}`
        : `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions?${filter}`;

      const resp = await axios.get(route);
      if (resp.data && resp.data.totalPages) {
        this.toShowSessionCallApi = resp.data;
        this.callApiCurrentPage = resp.data.currentPage;
        this.limitCallApi = resp.data.perPageCount;
        this.loadingSessionCallApi = false;
      } else {
        this.toShowSessionCallApi = [];
        this.loadingSessionCallApi = false;
      }
    },
    async deleteTestSuitRun(row, id) {
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const confirmation = await swal.fire({
        title: "Test suite run '" + row.name + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.loadingTestDelete = true;
        await axios
          .delete(
            `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/${id}`
          )
          .then(async (response) => {
            if (response.data.status == "success") {
              this.$notify({
                type: "success",
                message: "Test delete successfully.",
              });
              await this.getDetailProject();
              await this.getList();
              this.loadingTestDelete = false;
            } else {
              this.loadingTestDelete = false;
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            this.loadingTestDelete = false;
          });
      }
    },
    remove(i) {
      this.listAddSuitTest.splice(i, 1);
    },
    removeAllFiles() {
      this.listAddSuitTest = [];
    },
    onChange(event) {
      let fileFromState = this.$refs.file.files;
      Object.values(event.target.files).map((value, index) => {
        this.listAddSuitTest.push(value);
      });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        this.$refs.dropDownContainer.parentElement.classList.add("file-hover");
      }
    },
    dragleave() {
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if (event.srcElement.files.length) {
        let fileFromState = this.$refs.file.files;
        Object.values(fileFromState).map((value, index) => {
          this.listAddSuitTest.push(value);
        });
      }
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    handleGotIt() {
      if (this.checkedPopup) {
        this.checkedShow = true;
        localStorage.setItem("showAgain", this.checkedShow);
      }
      this.visibleShow = false;
    },
    async handleSubmitChat() {
      this.suggestion_str = "";
      this.displayedText = "";
      this.text_from_chat = "";
      this.isStreaming = true;
      this.isSumbit = true;
      this.suggestionMessages = [];
      if (this.postedMessages.length == 0) {
        this.createNewSession(false);
      }
      const containerMsg = document.querySelector(".messages-history");
      if (this.currentMessage !== "" && this.currentMessage !== " ") {
        this.postedMessages.push({
          role: "user",
          content: this.currentMessage,
        });
        this.user_message = this.currentMessage;
        this.currentMessage = "";
        let sessions = this.filteredResultsSessionAPI;
        if (sessions.length >= 1) {
          if (typeof sessions[0]["distinct_id"] != "undefined") {
            this.chosen_session = sessions[0]["distinct_id"];
          } else {
            this.chosen_session = null;
          }
        } else {
          this.chosen_session = null;
        }

        if (this.postedMessages.length == 1) {
          let dataSendingTitle = {
            chat_version: "WringGPT",
            session_id: this.chosen_session,
            project_id: this.$route.params.id,
            messages: this.postedMessages,
            user_id: this.userId.system_id,
            session_name: this.sessionIdChat.toString(),
            type: "title",
            engine: "openai",
          };
          this.loadingMessage = true;

          try {
            const response = await fetch(
              `/interceptor/pageviews/mixtralchat/v2?engine=openai`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataSendingTitle),
              }
            );

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            let buffer = "";

            // Function to read streamed content
            const processStream = async () => {
              while (true) {
                this.loadingMessage = false;
                const { done, value } = await reader.read();
                if (done) break;

                // Decode and add to buffer
                buffer += decoder.decode(value, { stream: true });

                // Check for new lines
                const lines = buffer.split("\n");
                buffer = lines.pop(); // Save the last line (might be incomplete)

                lines.forEach((line) => {
                  const trimmedLine = line.trim(); // Remove extra whitespace
                  if (trimmedLine) {
                    try {
                      const parsed = JSON.parse(trimmedLine);
                      if (parsed.content) {
                        if (parsed.content.includes("Title")) {
                          let titleIndex =
                            parsed.content.indexOf("Title:") + "Title".length;
                          this.titleChat = parsed.content
                            .substring(titleIndex)
                            .trim()
                            .replace(":", "")
                            .replace('"', "");
                        } else if (
                          parsed.content.includes("Message") ||
                          this.isSuggestions
                        ) {
                          this.isSuggestions = true;
                          this.suggestion_str += parsed.content;
                        } else {
                          this.text_from_chat += parsed.content;
                        }
                        // Ensure the UI updates immediately
                        this.$nextTick(() => {
                          const chatBox = document.querySelector(".chat-box");
                          if (chatBox) {
                            chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                          }
                        });
                      }
                    } catch (err) {
                      console.warn("Skipping malformed line:", line, err); // Log and continue
                    }
                  }
                });
              }

              // Process any remaining data in the buffer
              if (buffer.trim()) {
                try {
                  const parsed = JSON.parse(buffer.trim());
                  if (parsed.content) {
                    this.text_from_chat += parsed.content;
                    if (
                      parsed.content.includes("Message") ||
                      this.isSuggestions
                    ) {
                      this.isSuggestions = true;
                      this.suggestion_str += parsed.content;
                    } else {
                      this.text_from_chat += parsed.content;
                    }
                    // Ensure the UI updates immediately
                    this.$nextTick(() => {
                      const chatBox = document.querySelector(".chat-box");
                      if (chatBox) {
                        chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                      }
                    });
                  }
                } catch (err) {
                  console.error("Error parsing JSON from stream at end:", err);
                }
              }
            };

            await processStream();

            this.loadingMessage = false;
          } catch (error) {
            console.error("Error while fetching streamed data:", error);
            let dataSendingTitle = {
              messages: res.data.response,
              type: "fixissue",
            };
            const res = await axios.post(
              `/interceptor/pageviews/mixtralchat/v2?type=fixissue`,
              dataSendingTitle
            );
            const correctedMessage = res.data.response.message.replace(
              "```json",
              ""
            );
            let parsedMessage = JSON.parse(correctedMessage);
            parsedMessage = JSON.parse(parsedMessage["message"]);
            this.text_from_chat = parsedMessage["content"];
          }
        }

        this.resultMessages.push({
          role: "user",
          type: "text",
          content: this.user_message,
          title: this.titleChat,
        });
        if (this.text_from_chat != "") {
          this.text_from_chat = this.text_from_chat.replaceAll("#", "");
          await this.startTypingEffect(this.text_from_chat);
        }

        if (this.postedMessages.length > 1) {
          await this.getAnswerChat();
        } else {
          this.postedMessages.push({
            role: "assistant",
            content: this.text_from_chat,
          });
          this.isSuggestions = false;
          this.isStreaming = false;
          this.loadingMessage = false;
          this.text_from_chat = "";
          this.getSuggestions(this.suggestion_str);
        }
        this.isSumbit = false;
      }
    },
    async scrollToBottom() {
      const containerMsg = document.querySelector(".messages-history");
      if (containerMsg) {
        const lastMessage = containerMsg.lastElementChild;
        console.log(lastMessage);
        if (lastMessage) {
          setTimeout(() => {
            lastMessage.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "end",
            });
          }, 50);
        }
      }
    },
    checkHistoryMessage() {
      if (this.isProjectHistory) {
        this.postedMessages.map((item) => {
          this.allPostedMessages.push(item);
        });
        this.allPostedMessages.push((item) => {
          item.role;
        });
      }
    },
    showProjectHistory() {
      const containerMsg = document.querySelector(".messages-history");
      if (this.postedMessages.length > 0) {
        this.isProjectHistory = true;
        // if (this.postedMessages.length >= 5) {
        //     containerMsg.classList.add("is-overview-messages")
        // } else {
        //     containerMsg.classList.remove("is-overview-messages")
        // }
      } else {
        // containerMsg.classList.remove("is-overview-messages")
        this.isProjectHistory = false;
      }
    },
    async getAnswerChat() {
      var sessionId = null;
      let project_id = this.$route.params.id;
      var type_of_message = "text";

      this.checkHistoryMessage();
      let dataSending = {
        chat_version: "WringGPT",
        project_id: project_id,
        messages: this.postedMessages,
        user_id: this.userId.system_id,
        session_name: this.sessionIdChat.toString(),
        engine: "openai",
      };

      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };

      try {
        let res = "";
        const json = JSON.stringify(dataSending);

        if (this.isOpenChatBot) {
          const response = await fetch(
            `/interceptor/pageviews/mixtralchat/v2?engine=openai`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: json,
            }
          );

          if (!response.body) {
            throw new Error("Readable stream not supported.");
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let buffer = "";

          const processStream = async () => {
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              buffer += decoder.decode(value, { stream: true });

              const lines = buffer.split("\n");
              buffer = lines.pop(); // Save the last line (might be incomplete)

              lines.forEach((line) => {
                const trimmedLine = line.trim();
                if (trimmedLine) {
                  try {
                    const parsed = JSON.parse(trimmedLine);
                    if (parsed.content) {
                      this.text_from_chat += parsed.content;
                      if (
                        parsed.content.includes("Message") ||
                        this.isSuggestions
                      ) {
                        this.isSuggestions = true;
                        this.suggestion_str += parsed.content;
                      } else {
                        this.text_from_chat += parsed.content;
                      }
                      this.$nextTick(() => {
                        const chatBox = document.querySelector(".chat-box");
                        if (chatBox) {
                          chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                        }
                      });
                    }
                  } catch (err) {
                    console.warn("Skipping malformed line:", line, err);
                  }
                }
              });
            }

            if (buffer.trim()) {
              try {
                const parsed = JSON.parse(buffer.trim());
                if (parsed.content) {
                  this.text_from_chat += parsed.content;
                  if (
                    parsed.content.includes("Message") ||
                    this.isSuggestions
                  ) {
                    this.isSuggestions = true;
                    this.suggestion_str += parsed.content;
                  } else {
                    this.text_from_chat += parsed.content;
                  }
                  this.$nextTick(() => {
                    const chatBox = document.querySelector(".chat-box");
                    if (chatBox) {
                      chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                    }
                  });
                }
              } catch (err) {
                console.error("Error parsing JSON from stream at end:", err);
              }
            }
          };
          this.loadingMessage = false;
          await processStream();
        } else if (this.chat_version === "WringGPT") {
          this.isStreaming = true;
          if (this.postedMessages.length > 1) {
            const response = await fetch(
              `/interceptor/pageviews/mixtralchat/v2?engine=openai`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: json,
              }
            );

            if (!response.body) {
              throw new Error("Readable stream not supported.");
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = "";

            const processStream = async () => {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });

                const lines = buffer.split("\n");
                buffer = lines.pop(); // Save the last line (might be incomplete)

                lines.forEach((line) => {
                  const trimmedLine = line.trim();
                  if (trimmedLine) {
                    try {
                      const parsed = JSON.parse(trimmedLine);
                      if (parsed.content) {
                        if (
                          parsed.content.includes("Message") ||
                          this.isSuggestions
                        ) {
                          this.isSuggestions = true;
                          this.suggestion_str += parsed.content;
                        } else {
                          this.text_from_chat += parsed.content;
                        }
                        this.$nextTick(() => {
                          const chatBox = document.querySelector(".chat-box");
                          if (chatBox) {
                            chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                          }
                        });
                      }
                    } catch (err) {
                      console.warn("Skipping malformed line:", line, err);
                    }
                  }
                });
              }

              if (buffer.trim()) {
                try {
                  const parsed = JSON.parse(buffer.trim());
                  if (parsed.content) {
                    if (
                      parsed.content.includes("Message") ||
                      this.isSuggestions
                    ) {
                      this.isSuggestions = true;
                      this.suggestion_str += parsed.content;
                    } else {
                      this.text_from_chat += parsed.content;
                    }

                    this.$nextTick(() => {
                      const chatBox = document.querySelector(".chat-box");
                      if (chatBox) {
                        chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                      }
                    });
                  }
                } catch (err) {
                  console.error("Error parsing JSON from stream at end:", err);
                }
              }
            };

            await processStream();
            this.text_from_chat = this.text_from_chat.replaceAll("#", "");
            await this.startTypingEffect(this.text_from_chat);
            this.loadingMessage = false;
          }
        } else {
          // Handle other versions or default logic
        }

        if (res.data?.response !== undefined || this.text_from_chat != "") {
          this.postedMessages.push({
            role: "assistant",
            content: this.text_from_chat,
          });

          this.resultMessages.push({
            role: "assistant",
            type: "text",
            content: this.text_from_chat,
            project_id: project_id,
            version: this.chat_version,
            title: this.titleChat,
          });
          this.isStreaming = false;
          this.text_from_chat = "";
        }
      } catch (e) {
        console.log(e);
      } finally {
        if (sessionId != null) {
          this.loadingVideo = true;
        }
      }
      await this.postHistorySession(project_id);
      this.isSuggestions = false;
      this.getSuggestions(this.suggestion_str);
      await this.scrollToBottom();

      if (type_of_message == "text_video" && sessionId != null) {
        var id_message = this.postedMessages.length - 1;
        this.$nextTick(() => {
          var pieChart = document.getElementById(`chart_${id_message}`);
          pieChart.remove();
        });
        await this.putVideoInAnswer(sessionId, project_id, id_message);
      } else if (type_of_message == "text_and_button") {
        var id_message = this.postedMessages.length - 1;
        this.$nextTick(() => {
          var pieChart = document.getElementById(`chart_${id_message}`);
          pieChart.remove();
          console.log(`convert_button_${id_message}`);
          var convertButton = document.getElementById(
            `convert_button_${id_message}`
          );
          convertButton.style.display = "block";
        });
      }
    },
    getSuggestions(text) {
      let questions = text.split(/\d+\.\s/).filter((q) => q.trim() !== "");
      questions = questions.slice(1);
      if (questions.length == 0) {
        questions = text
          .split("\n")
          .filter((line) => line.trim().endsWith("?"))
          .map((line) => line.trim().replace(/^\-\s*/, ""));
      }
      this.suggestionMessages = questions;
    },
    async postHistorySession(project_id) {
      let historyMessages = JSON.stringify(this.resultMessages);
      const dataSending = {
        user_id: this.userId.system_id,
        app_id: project_id,
        session_name: this.sessionIdChat.toString(),
        message: historyMessages,
      };
      const json = JSON.stringify(dataSending);
      const url = `${
        process.env.VUE_APP_API_URL_PREFIX
      }/pageviews/all_messages?message=${historyMessages}&user_id=${
        this.userId.system_id
      }&app_id=${project_id}&session_name=${this.sessionIdChat.toString()}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.post(
          `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status == "success") {
          this.updateSessions = true;
          if (this.postedMessages.length <= 2) await this.getSessionsChat();
        } else {
          var options = document.getElementById(
            `options-message_${this.postedMessages.length}`
          );
          options.remove();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getSessionsChat() {
      this.loadingSessionChats = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?user_id=${this.userId.system_id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url, {
          headers: { "Cache-Control": "no-cache" },
        });
        if (res.data.length !== 0) {
          this.sessionsChats = res.data;
          console.log("sessions chats", this.sessionsChats);
          this.parsingSessionsChat();
          this.updateSessions = true;
        } else {
          this.sessionsChats = [];
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.showSessionsChat();
        this.loadingSessionChats = false;
      }
      if (this.updateSessions) {
        this.showSessionsChat();
      }
    },
    parsingSessionsChat() {
      this.sessionsChats.data.forEach((item) => {
        try {
          item.message = item.message.toString();
        } catch (error) {
          console.log(error);
        }
      });
      let tempList = [];
      this.sessionsChats.data.forEach((item) => {
        Array.from(this.resultProject).forEach((app) => {
          if (app.projectId === item.app_id) {
            tempList.push(item.app_id);
          }
        });
      });
      let tempSet = new Set(tempList);
      this.listProject = [];
      tempSet.forEach((item) => {
        this.resultProject.map((app) => {
          if (app.projectId === item) {
            this.listProject.push(app.name);
          }
        });
      });
    },
    showSessionsChat() {
      let selectedProjectId = null;
      this.showSelectedSessions = [];
      // let selectApp = document.querySelector(".select-app--history-custom")
      this.resultProject.map((item) => {
        if (item.name === this.selectedProject) {
          selectedProjectId = item.projectId;
        }
      });
      // if (this.selectedProject.split('').length > 7) {
      //     selectApp.style.width = "37%"
      //     if (this.selectedProject.split('').length > 10) {
      //         selectApp.style.width = "48%"
      //         if (this.selectedProject.split('').length > 13) {
      //             selectApp.style.width = "50%"
      //             if (this.selectedProject.split('').length > 15) {
      //                 selectApp.style.width = "52%"
      //             }
      //         }
      //     }
      // }
      if (this.updateSessions) {
        this.showSelectedSessions = [];
        this.updateSessions = false;
      }
      // this.sessionsChats.data.map((item) => {
      //     if (selectedProjectId === item.app_id) {
      //         this.showSelectedSessions.push(item)
      //     }
      // })
      this.sessionsChats.data.map((item) => {
        item["message"] = JSON.parse(item["message"]);
        for (let index in item["message"]) {
          let message = item["message"][index];
          if ("title" in message) {
            if (message["title"] != "") {
              item["title"] = message["title"];
            }
          }
        }
        this.showSelectedSessions.push(item);
      });
      this.showSelectedSessions.sort((a, b) => {
        return new Date(b.chat_time) - new Date(a.chat_time);
      });
      // this.showSelectedSessions.map((item) => {
      //     if (item.message[0].content.split('').length >= 20) {
      //         let shortMesssage = item.message[0].content.split('', 20)
      //         item.message[0].shortContent = shortMesssage.join('') + "..."
      //     }
      // })
      // this.getFilterDate()
      this.loadingSessionChats = false;
    },
    async fetchingDataMessages(project_id, polling) {
      try {
        let res_messages = await axios.get(
          `${
            process.env.VUE_APP_API_URL_PREFIX
          }/pageviews/all_messages?user_id=${
            this.userId.system_id
          }&app_id=${project_id}&session_name=${this.sessionIdChat.toString()}`
        );
        let message = JSON.parse(res_messages.data.data[0]);
        if (message[message.length - 1].role == "assistant") {
          console.log(message[message.length - 1].content.toString());
          clearInterval(polling);
          this.postedMessages.push({
            role: "assistant",
            content: message[message.length - 1].content.toString(),
          });
          this.loadingMessage = false;
          // this.scrollToBottom()
        }
      } catch (error) {
        console.log("Error in long polling", error);
      }
    },
    async handleSubmit(val) {
      this.isOpenChatBot = true;
      let chatVersion = localStorage.getItem("chatVersion");
      this.currentMessage = val.text;
      this.chat_version = "WringGPT";
      const message = {
        role: "user",
        type: "text",
        content: val.text,
        project_id: this.$route.params.id,
        project_name: `${this.projectDetail.name}`,
      };
      this.dataMessage.push(message);
      await this.handleSubmitChat();
      // if (chatVersion == "ApiGpt") {
      //   try {
      //     // const apiUrl = `${process.env.VUE_APP_API_URL_PREFIX}/interceptor/pageviews/apicallschat/v1`;
      //     const apiUrl =
      //       "https://dev.wringr.ai/interceptor/pageviews/apicallschat/v1";
      //     const res = await axios.get(apiUrl, {
      //       params: {
      //         question: val.text,
      //       },
      //     });
      //     console.log(res.data);
      //     this.answer = res.data.response.answer;
      //   } catch (error) {
      //     console.error("There was a problem:", error);
      //   }
      // }

      this.role = "user";

      // let answer = await this.getAnswer(this.dataMessage);
      let buttonsUsers = [];
      if (this.text_from_chat !== undefined) {
        // if (answer.response.role === "assistant") {
        //   answer.response.content.split(" ").map((word) => {
        //     word.split("").map((letter) => {
        //       if (letter === "$") {
        //         buttonsUsers.push({
        //           text:
        //             "Add User ID " +
        //             word.replace(/[{($:,)}]/g, "") +
        //             " to Test Suite",
        //         });

        //         console.log(buttonsUsers);
        //       }
        //     });
        //   });
        // if (buttonsUsers.length !== 0) {
        //   const rightText = answer.response.content.replace(/[{($)}]/g, "");
        //   console.log(rightText);
        //   this.dataMessage.push({
        //     role: "assistant",
        //     type: "button",
        //     content: rightText,
        //     options: buttonsUsers,
        //     project_id: this.$route.params.id,
        //     project_name: `${this.projectDetail.name}`,
        //   });
        //   setTimeout(() => this.showButtonsUsers(), 1500);
        // } else {

        this.dataMessage.push({
          role: "assistant",
          type: "text",
          content: this.text_from_chat,
          project_id: this.$route.params.id,
          project_name: `${this.projectDetail.name}`,
        });
        console.log("this.dataMessage", this.dataMessage);
        // }
        // }
      } else {
        this.dataMessage.push({
          role: "assistant",
          type: "text",
          content:
            "The server is too busy to answer your question now, it will answer it in 5 minutes",
          project_id: this.$route.params.id,
          project_name: `${this.projectDetail.name}`,
        });
      }

      if (this.dataMessage.length > 1) {
        let chatVersion = localStorage.getItem("chatVersion");

        if (chatVersion == "ApiGpt") {
          let link = "https://dev.wringr.ai/details/test-explorer/list-tests";

          this.all_messages.push({
            role: "assistant",
            type: "text",
            content: this.answer,
          });
          const apiTest =
            process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/appmap_chat";

          const reqTest = await axios.get(apiTest);
          console.log(reqTest);
          if (reqTest.link) {
            this.all_messages.push({
              role: "assistant",
              type: "button",
              content: `<a class="button" href="${link}">${reqTest.name}</a>`,
            });
          }

          console.log(this.all_messages);
          localStorage.setItem(
            "all_messages",
            JSON.stringify(this.all_messages)
          );
        } else {
          let last = this.dataMessage[this.dataMessage.length - 1];
          let prelast = this.dataMessage[this.dataMessage.length - 2];

          // this.all_messages.push(prelast)
          this.all_messages.push(last);

          console.log(last);
          localStorage.setItem(
            "all_messages",
            JSON.stringify(this.all_messages)
          );
        }
      } else {
        this.all_messages = this.dataMessage;
        localStorage.setItem("all_messages", JSON.stringify(this.all_messages));
      }

      this.isOpenChatBot = false;
    },
    changeAppMapVersion() {
      this.showTestGraph = !this.showTestGraph;
    },
    async refreshMap() {
      console.log("refreshing");
      this.loadindRefresh = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&label_keyflows=True`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.post(url);
        if (
          (res.data && res.data?.status == "success") ||
          res.data?.status == 200
        ) {
          if (this.showAppMap) {
            this.$notify({
              type: "success",
              duration: 5000,
              message:
                "Successfully started app map calculation, it will take approximately 5 minutes",
            });
          }

          console.log("resUpdate", res.data);
          this.appMapNewId = res.data.app_map_id;
          this.loadindRefresh = false;
          this.showUpdatingBar = true;
          console.log("updatingBar", +true);
          this.loadingUpdatingBar = true;
          this.progressUpdating();
          this.intervalUpdating = setInterval(() => {
            this.progressUpdating();
          }, 10000);
          return true;
        } else {
          // this.$notify({
          //   type: "danger",
          //   duration: 5000,
          //   message: "The update App Map has failed.",
          // });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadindRefresh = false;
    },
    async progressUpdating() {
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 404 || (status >= 200 && status <= 500)
        );
      };
      let projectId = null;
      if (this.$route.params.id !== undefined) {
        localStorage.setItem("UpdateId", this.$route.params.id);
        projectId = localStorage.getItem("UpdateId");
      }
      let res = "";
      let step = 0;

      if (
        res?.data?.status !== "finished" &&
        res?.data?.status !== "failed" &&
        this.statusUpdating !== "Error" &&
        this.countStatusProgress <= 30 &&
        projectId !== null
      ) {
        console.log("check");
        console.log("resp", res.data);
        res = await axios.get(
          process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/appmap?project_id=${projectId}&app_map_id=${this.appMapNewId}`
        );
        if (res.data.status === "in_progress") {
          this.countStatusProgress++;
          console.log("in_progress", this.countStatusProgress);
        }

        let array = res.data.status.split("");
        let temp = res.data.status.split(" ");
        this.statusUpdating = temp[0];
        step = array[1];
        if (step === "1") {
          this.activeSteps = 1;
        } else if (step === "2") {
          this.activeSteps = 2;
        } else if (step === "3") {
          this.activeSteps = 3;
        } else if (step === "4") {
          this.activeSteps = 4;
        } else if (step === "5") {
          this.activeSteps = 5;
        } else if (step === "6") {
          this.activeSteps = 6;
        } else if (step === "7") {
          this.activeSteps = 7;
        } else if (step === "8" || step === "f") {
          this.activeSteps = 8;
        }
      }
      // clearInterval(interval)
      if (res?.data?.status === "finished") {
        this.allDataAppMap = res.data;
        const respInfo = res.data.summary_graph;
        this.toShowAppMap = respInfo;
        this.toShowKeyFlows = res.data.flows;
        this.showUpdatingBar = false;
        this.loadingUpdatingBar = false;
        this.countStatusProgress = 0;
        this.$notify({
          type: "success",
          duration: 5000,
          message: "The update App Map success.",
        });
        clearInterval(this.intervalUpdating);

        if (this.showTestGraph) {
          await this.$refs["test-graph"].restoreNetwork();
          await this.$refs["test-graph"].updataNetwork();
        } else {
          await this.$refs["app-map"].updataNetwork();
        }
      } else if (
        (res?.data?.status === "failed" && this.statusUpdating === "Error") ||
        this.countStatusProgress >= 30 ||
        this.statusUpdating === "Error"
      ) {
        console.log("failed update");
        // this.$notify({
        //   type: "danger",
        //   duration: 5000,
        //   message: "No sessions found for this appmap",
        // });
        this.countStatusProgress = 0;
        this.showUpdatingBar = false;
        // this.isAppMapEmpty = true
        this.loadingUpdatingBar = false;
        clearInterval(this.intervalUpdating);
        if (this.showTestGraph) {
          await this.$refs["test-graph"].restoreNetwork();
          await this.$refs["test-graph"].updataNetwork();
        } else {
          await this.$refs["app-map"].updataNetwork();
        }
        // this.toShowAppMap = {}
      }
    },
    showButtonsUsers() {
      const buttonsUserIds = document.querySelectorAll(
        ".qkb-mb-button-options__item"
      );
      console.log("button", buttonsUserIds);
      for (let elem of buttonsUserIds) {
        elem.addEventListener("click", (e) => {
          const idUsers = e.srcElement.childNodes[0].data.replace(
            /[^0-9]/g,
            ""
          );
          const list = Object.entries(this.allDataAppMap.users_info);
          console.log(idUsers);
          list.map((item) => {
            let count = 0;
            item.map((user, id, array) => {
              console.log(array[0] === idUsers);
              if (idUsers === array[0]) {
                count++;
                if (count === 1) this.labelSelectedUserId += array[0];
                this.usersDataId = array[1];
                this.userAddTestSuit();
                console.log("work", this.usersDataId);
              }
            });
          });
        });
      }
    },
    async getAnswer(messageDefault) {
      this.loadingMessage = true;
      let modifiedItem = [];
      console.log("content", messageDefault);
      messageDefault.map((item) => {
        if (item.role === "assistant") {
          modifiedItem.push({
            role: "assistant",
            content: item.content,
          });
        } else if (item.role === "user") {
          modifiedItem.push({
            role: "user",
            content: item.content,
          });
        }
      });
      if (messageDefault[messageDefault.length - 1].role == "user") {
        let lastUserMessage = messageDefault[messageDefault.length - 1];
        if (lastUserMessage.content != this.dataMessage[0].content) {
          this.all_messages.push({
            role: "assitant",
            type: "text",
            content: lastUserMessage.content,
            project_id: this.$route.params.id,
            project_name: `${this.projectDetail.name}`,
          });
        }
      }
      let chatVersion = localStorage.getItem("chatVersion");
      const dataSending = {
        chat_version: chatVersion,
        project_id: this.$route.params.id,
        messages: modifiedItem,
      };
      const json = JSON.stringify(dataSending);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/appmap_chat",
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.loadingMessage = false;
        console.log("answerData", res);
        return res.data;
      } catch (e) {
        console.log(e);
        this.dataMessage.push({
          role: "assistant",
          type: "text",
          content:
            "The server is too busy to answer your question now, it will answer it in 5 minutes",
        });
      }
    },
    progressBar() {
      let perc = 0;
      let count = 0;
      if (this.checked1) {
        count += 1;
      }
      if (this.checked2) {
        count += 1;
      }
      if (this.checked3) {
        count += 1;
      }
      if (this.checked4) {
        console.log("dsada", this.checked4);
        count += 1;
      }
      if (this.checked5) {
        count += 1;
      }
      if (this.checked6) {
        count += 1;
      }

      if (count === 0) {
        return (perc = 0);
      } else if (count === 1) {
        return (perc = 33);
      } else if (count === 2) {
        console.log("eada", perc);
        return (perc = 66);
      } else if (count === 3) {
        return (perc = 100);
      } else if (count === 4) {
        return (perc = 64);
      } else if (count === 5) {
        return (perc = 80);
      } else if (count === 6) {
        return (perc = 100);
      }
    },
    keyFlowTesSuit() {
      const correctId = this.selectKeyFlowPathUser;

      this.toShowAppMap.links.map((item) => {
        console.log(
          "item key flow from",
          item.from === this.highlights[0][0] &&
            item.to === this.highlights[0][1]
        );
        if (
          item.from === this.highlights[0][0] &&
          item.to === this.highlights[0][1]
        ) {
          item.person_ids.map((pers, id) => {
            if (pers.toString() === correctId) {
              console.log("person id", item.session_ids[id]);
              this.id = item.session_ids[id];
            }
          });
        }
      });
      this.addTestModal = true;
      this.run_name = "";
      this.listAddSuitTest = [];
      this.labelSelectedKeyFlow = this.toShowKeyFlows[this.selectKeyFlow].label;
      this.getFiletest2("run");
    },
    userAddTestSuit() {
      this.addTestModalUser = true;
      this.id = this.usersDataId.session_ids[0];
      this.getFiletest2("run");
    },
    processSouceCucumberRun(el) {
      this.showModalCucumber = true;
      this.cucumberRunName = "";
      this.cucumberSessionId = el.id;
      this.cucumberLoad = false;
      this.cucumbersouceAccount =
        this.integrationSaurceLabs[0].unique_integration_identifier;

      if (el.customjson && el.customjson.cucumber_run) {
        this.cucumberRunResult = Object.values(el.customjson.cucumber_run);
        console.log("hello", this.cucumberRunResult);
      }
    },
    async souceCucumberRun() {
      if (this.cucumberRunName.length < 1 || this.cucumberRunName == "") {
        this.$notify({
          type: "danger",
          message: "test name is required",
        });
        return;
      }

      if (
        this.cucumbersouceAccount.length < 1 ||
        this.cucumbersouceAccount == ""
      ) {
        this.$notify({
          type: "danger",
          message: "souce labs account required ",
        });
        return;
      }
      this.cucumberLoad = true;
      let indexinterg = this.integrationSaurceLabs.findIndex(
        (item) =>
          item.unique_integration_identifier == this.cucumbersouceAccount
      );
      let souceselect = this.integrationSaurceLabs[indexinterg];
      const projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.cucumberSessionId);
      params.append("type", "cucumber");
      const res = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/session/v1/testgen?" +
          params,
        {
          responseType: "blob",
        }
      );
      let file = null;
      if (res && res.status && res.status < 399) {
        file = res.data;
      } else {
        this.$notify({
          type: "danger",
          message: "impossible to execute the test the file is unavailable ",
        });
        this.cucumberLoad = false;
        return;
      }
      let reqHeaders = {
        "Content-Type": "multipart/form-data;",
        "X-Cloud-Browser": souceselect.sauceLabs_browsers[0],
        "X-Cloud-Browser-Version": souceselect.sauceLabs_versions,
        "X-Cloud-Platform-Name": souceselect.sauceLabs_platforms[0],
        "X-Cloud-Access-Key": souceselect.sauceLabs_accesstoken,
        "X-Cloud-Username": souceselect.sauceLabs_username,
        "X-Rp-Launch": this.cucumberRunName,
        "X-Rp-session-id": this.cucumberSessionId,
      };
      const formData = new FormData();
      formData.append("attachedFile", file, file.name);

      const resRun = await axios.post(
        process.env.VUE_APP_API_URL_PREFIX + "/testscriptsoucecucumber/v1",
        formData,
        { headers: reqHeaders }
      );
      if (resRun && resRun.status && resRun.status < 399) {
        this.$notify({
          type: "success",
          message: `test successfully executed log link : ${resRun.data.response.logs_url}`,
        });
        file = resRun.data;
      } else {
        let message = "unknown error";
        if (resRun.data) {
          message = resRun.data.message;
        }
        this.$notify({
          type: "danger",
          message: `execution failed error : ${message}`,
        });
        this.cucumberLoad = false;
        return;
      }
      this.cucumberLoad = false;
      this.showModalCucumber = false;
      this.getSessions();
    },
    showStepsList(val) {
      let url = null;
      if (val.person.properties.$initial_current_url) {
        url = val.person.properties.$initial_current_url.replace(
          /https:\/\//g,
          ""
        );
        this.run_name =
          url +
          " " +
          val.person.properties.$initial_browser +
          " " +
          val.person.properties.$geoip_country_name;
      } else {
        url = val.person.properties.$current_url.replace(/https:\/\//g, "");
        this.run_name = url + " " + val.person.properties.$browser;
      }
      this.$router.push({
        name: "Steps List",
        params: {
          testSuiteId: this.projectDetail.attributes.testSuiteId,
          id: this.$route.params.id,
          projectId: this.$route.params.id,
          nameProject: this.projectDetail.name,
          session_id: val.id,
          start_time: val.start_time,
          recording_duration: val.recording_duration,
          run_name: this.run_name,
          page_name: this.projectDetail.name,
        },
        query: {
          wring: true,
          apiTest: val?.customjson.session_type == "api" ? true : false,
        },
      });
    },
    showDetailsSession2(val) {
      // handles the covering tests
      if (val.tests) {
        this.run_name = "DetailsSteps";
        this.$router.push({
          name: "Details Session Steps",
          params: {
            id: this.$route.params.id,
            session_id: val.tests[0].session_id,
            run_name: this.run_name,
          },
        });
      }
      // handles the normal case of clicking into details for a session
      else {
        if (this.eventSessionType !== "mobile") {
          let url = val.person.properties.$initial_current_url.replace(
            /https:\/\//g,
            ""
          );
          this.run_name =
            url +
            " " +
            val.person.properties.$initial_browser +
            " " +
            val.person.properties.$geoip_country_name;
          this.$router.push({
            name: "Details Session Steps",
            params: {
              id: this.$route.params.id,
              session_id: val.id,
              run_name: this.run_name,
            },
          });
        } else {
          this.$router.push({
            name: "Details of mobile session",
            params: {
              id: this.$route.params.id,
              session_id: val.session_uid,
            },
          });
        }
      }
    },
    showDetailsSession(val) {
      // handles the covering tests
      if (val.tests) {
        this.run_name = "Coveringtests";
        this.$router.push({
          name: "Details Session",
          params: {
            id: this.$route.params.id,
            testSuiteId: this.projectDetail.attributes.testSuiteId,
            session_id: val.tests[0].session_id,
            run_name: this.run_name,
            start_time: val.start_time,
            recording_duration: "none",
            page_name: this.page_name,
          },
        });
      }
      // handles the normal case of clicking into details for a session
      else {
        if (this.eventSessionType !== "mobile") {
          let url = null;
          if (val.person.properties.$initial_current_url) {
            url = val.person.properties.$initial_current_url.replace(
              /https:\/\//g,
              ""
            );
            this.run_name =
              url +
              " " +
              val.person.properties.$initial_browser +
              " " +
              val.person.properties.$geoip_country_name;
          } else {
            url = val.person.properties.$current_url.replace(/https:\/\//g, "");
            this.run_name = url + " " + val.person.properties.$browser;
          }

          console.log("works");

          const routeParams = {
            name: "Details Session",
            params: {
              id: this.$route.params.id,
              name: this.projectDetail.name,
              testSuiteId: this.projectDetail.attributes.testSuiteId,
              session_id: val.id,
              run_name: this.run_name,
              start_time: val.start_time,
              recording_duration: val.recording_duration,
            },
            query: {},
          };

          if (this.$route.query.wring) routeParams.query = { wring: true };

          this.$router.push(routeParams);
        } else {
          this.$router.push({
            name: "Details of mobile session",
            params: {
              id: this.$route.params.id,
              session_id: val.session_uid,
            },
          });
        }
      }
    },
    showApiCalls(id) {
      this.$router.push({
        name: "Api call Session",
        params: {
          id: this.$route.params.id,
          name: this.projectDetail.name,
          session_id: id,
        },
      });
    },
    showFileTest(elt) {
      this.$router.push({
        name: "project test suite files",
        params: {
          id: this.$route.params.id,
          name: this.projectDetail.name,
          testSuitRunId: this.projectDetail.attributes.testSuiteId,
        },
      });
    },
    async getListFileTestSuit(el, id) {
      this.loadingDownloadFileModal = true;
      this.downloadFileModal = true;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return (
          status === 400 || status === 404 || (status >= 200 && status < 500)
        );
      };
      const testSuitRunId = id;
      this.suiteDownloadFileId = testSuitRunId;
      let fileFileTestSuit = [];
      await axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/${testSuitRunId}`
        )
        .then(async (response) => {
          if (response.data.status == "success") {
            this.listeDownloadFile = Object.values(
              response.data.response.testRunDetails
            )[0].runOptions.testsuite_file_keys;
          } else {
            this.listeDownloadFile = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "Cannot load user Project... Try refresh" + error,
          });
        })
        .finally(() => {
          this.loadingDownloadFileModal = false;
        });
      return fileFileTestSuit;
    },
    async downloadTestSuiteFile(row) {
      const testSuiteId = this.$route.params.testSuitRunId;
      const fileId = row.split("/")[1];
      try {
        const resp = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${this.suiteDownloadFileId}/file/${fileId}`
        );
        if (resp.data.status == "success") {
          const respdata = resp.data;
          const fileName = respdata.fileName;
          const fileURL = respdata.url;
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          this.$notify({
            type: "danger",
            message: "Cannot load user Project... Try refresh",
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Cannot load user Project... Try refresh",
        });
        console.log("Could not download test suite file");
      }
    },
    changeProject() {
      if (this.selectProjectId) {
        this.$router.push({
          name: "List Sessions",
          params: {
            id: this.selectProjectId,
          },
        });
        location.reload();
      }
    },
    async changeKeyFlow() {
      this.videoLoading = true;
      if (
        this.selectKeyFlow != "ALL" &&
        Number.isNaN(this.selectKeyFlow) === false
      ) {
        console.log("REFSFIRST", this.$refs);
        this.highlights = this.toShowKeyFlows[this.selectKeyFlow].edges;
        // console.log("yoeeee", this.$refs["test-graph"])
        this.isFlowSelected = true;
        await this.getAppMapId();
        this.labelSelectedKeyFlow =
          this.toShowKeyFlows[this.selectKeyFlow].label;
        this.selectKeyFlowPathUser = Object.keys(
          this.toShowKeyFlows[this.selectKeyFlow].path_users
        )[0];
        if (
          this.toShowKeyFlows[this.selectKeyFlow].sample_session !== undefined
        ) {
          this.keyFlowSession =
            this.toShowKeyFlows[this.selectKeyFlow].sample_session.session_id;
          this.recDuration =
            this.toShowKeyFlows[this.selectKeyFlow].sample_session.duration;
          this.recDateTime =
            this.toShowKeyFlows[this.selectKeyFlow].sample_session.datetime;
        }

        console.log("selected key flow sessions id", this.keyFlowSession);
        console.log("Dasd", this.toShowKeyFlows[this.selectKeyFlow].label);
        localStorage.setItem("selectKeyFlow", this.labelSelectedKeyFlow);
        // await this.getAppMap()
        // await this.getAppMapId();
        if (this.showTestGraph) {
          await this.$refs["test-graph"].updataNetwork();
        } else {
          await this.$refs["app-map"].updataNetwork();
        }
        await this.detailsSession();
        await this.getEventSession();
      } else {
        this.highlights = null;
        this.selectKeyFlowPathUser = null;
        this.keyFlowSnapshot = false;
        await this.getAppMapId();
        // await this.getAppMap()
        console.log("REFS", this.$refs);
        if (this.showTestGraph) {
          await this.$refs["test-graph"].updataNetwork();
        } else {
          await this.$refs["app-map"].updataNetwork();
        }
      }
      this.videoLoading = false;
    },
    initializewidthRrplaye() {
      this.widthRrplayer = this.widthRrplayerCom;
    },
    async detailsSession() {
      let projectId = this.$route.params.id;
      this.loadingVideo = true;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.keyFlowSession);
      params.append("page", 1);
      params.append("limit", 150);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const url = this.$route.query.wring
        ? process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/snapshots/v2/wring?" +
          params
        : process.env.VUE_APP_API_URL_PREFIX +
          "pageviews/snapshots/v2/wring?" +
          params;
      await axios
        .get(url)
        .then((response) => {
          if (response) {
            console.log(response);
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.events = [];
            } else if (response.data.status === "success") {
              this.events = [];
              // let index =  Object.entries(response.data.response.result.snapshot_data_by_window_id)[0]
              this.events = this.events.concat(
                response.data.response.snapshots
              );
              let allVideo = [];
              // response.data.response.snapshots.map((item) => {
              //   return Object.entries(
              //     item
              //   ).map((key) => {
              //     this.events = this.events.concat(key[1]);
              //   });
              // });
              this.keyFlowSnapshot = true;
              this.loadingVideo = false;
            } else if (response.data.status === "failure") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.keyFlowSnapshot = false;
              this.events = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data ",
          // });
        });
      this.loadingVideo = false;
    },
    async getEventSession() {
      this.autoplay = true;
      let projectId = this.$route.params.id;
      this.loadingVideo = true;
      this.id = this.$route.params.session_id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.keyFlowSession);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const url = this.$route.query.wring
        ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/events/wring?${params}`
        : process.env.VUE_APP_API_URL_PREFIX + "/pageviews/events?" + params;
      await axios
        .get(url)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.tagsEvent = {};
            } else if (response.data.status === "success") {
              this.tagsEvent = {};
              this.tagsEvent = response.data.response;
              this.tagsEvent.map((item) => {
                item["start_time"] = this.secondsToRealTimeEvent(
                  this.recDateTime,
                  item.timestamp
                );
                return item;
              });
              this.resultEvent = this.tagsEvent;
              this.activeSessionDuration = parseInt(this.recDuration);
            } else if (response.data.status === "failure") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.tagsEvent = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data ",
          // });
        })
        .finally((e) => {
          // this.initializewidthRrplaye()
          this.loadingVideo = false;
        });
    },
    changeLayoutVideo() {
      let modalVideo = document.querySelector(".video-modal");
      if (this.addTestModal) {
        modalVideo.style.zIndex = "1";
      }
    },
    async getKeyFlowVideo() {
      this.showVideoModal = true;
      const rrweb = document.getElementById("rrweb");
      this.webPlayer = new rrwebPlayer({
        target: rrweb, // customizable root element
        props: {
          events: this.events,
          width: 800,
          height: 300,
          autoPlay: true,
          skipInactive: false,
          goTo: this.currentTime,
          mouseTail: true,
        },
      });
      const playerWeb = this.webPlayer;
      let buttonSetting = document.querySelector(".rr-controller__btns");
      let rrFrame = document.querySelector(".rr-player__frame");
      let counterClicks = 0;
      buttonSetting.childNodes[9].addEventListener("click", (e) => {
        if (counterClicks === 0) {
          rrFrame.style.height = "560px";
          rrFrame.style.width = "100% !important";
        }
      });

      this.$nextTick(() => {
        const wrapper_replayer = document.querySelector(".replayer-wrapper");
        const rr_controller = document.querySelector(".rr-controller");
        const rr_timeline = document.querySelectorAll(".rr-timeline__time");
        wrapper_replayer.appendChild(rr_controller);
        let spnaTimeLine = document.createElement("span");
        spnaTimeLine.innerHTML = "/";
        buttonSetting.insertBefore(rr_timeline[1], buttonSetting.childNodes[1]);
        buttonSetting.insertBefore(spnaTimeLine, buttonSetting.childNodes[1]);
        buttonSetting.insertBefore(rr_timeline[0], buttonSetting.childNodes[1]);
        console.log(buttonSetting.childNodes[12]);
        buttonSetting.childNodes[12].addEventListener("click", function () {
          this.isResize = !this.isResize;
          if (this.isResize) {
            wrapper_replayer.classList.add("scalemax");
          } else {
            wrapper_replayer.classList.remove("scalemax");
          }
        });
      });
      await this.addPik();
      let modalVideo = document.querySelector(".video-project-modal");
      modalVideo.childNodes[0].childNodes[0].childNodes[0].style.width =
        "1400px";
      modalVideo.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[1].addEventListener(
        "click",
        () => {
          playerWeb.$destroy();
        }
      );
      modalVideo.childNodes[0].childNodes[0].childNodes[0].childNodes[2].childNodes[0].childNodes[0].addEventListener(
        "click",
        () => {
          playerWeb.$destroy();
        }
      );
      playerWeb.addEventListener("ui-update-player-state", (e) => {
        console.log("player state");
        if (e.payload === "paused") {
          const _replayer = document.querySelector(".replayer-wrapper");
          if (document.querySelector(".pause__button")) {
            _replayer.childNodes[0].remove();
          }
          if (document.querySelector(".pause__button__main")) {
            _replayer.childNodes[0].remove();
          }
          console.log("btn", e.payload);
          const text = document.createElement("button");
          const img = document.createElement("img");
          text.className = "pause__button";
          text.style.display = "flex";
          text.style.position = "absolute";
          text.style.width = "100%";
          text.style.height = "100%";
          text.style.cursor = "pointer";
          text.style.border = "none";
          text.style.backdropFilter = "blur(2px)";
          text.style.backgroundColor = "transparent";
          img.className = "pause__img";
          img.src =
            "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItcGxheSI+PHBvbHlnb24gcG9pbnRzPSI1IDMgMTkgMTIgNSAyMSA1IDMiPjwvcG9seWdvbj48L3N2Zz4=";
          img.style.width = "100px";
          img.style.height = "100px";
          img.style.position = "inherit";
          img.style.top = "50%";
          img.style.right = "50%";
          text.insertAdjacentElement("afterbegin", img);
          _replayer.insertAdjacentElement("afterbegin", text);
          text.addEventListener("click", function () {
            playerWeb.play();
            // this.webPlayer.play()
          });
        }
        if (e.payload === "playing") {
          const _replayer = document.querySelector(".replayer-wrapper");
          const pause = document.querySelector(".pause__button");
          if (_replayer.childNodes[0] && pause) {
            _replayer.childNodes[0].remove();
          }
          const text_button = document.createElement("button");
          text_button.className = "pause__button__main";
          text_button.style.display = "flex";
          text_button.style.position = "absolute";
          text_button.style.width = "100%";
          text_button.style.height = "100%";
          text_button.style.cursor = "pointer";
          text_button.style.border = "none";
          text_button.style.backgroundColor = "transparent";
          _replayer.insertAdjacentElement("afterbegin", text_button);
          text_button.addEventListener("click", function () {
            // this.webPlayerPause()
            playerWeb.pause();
            // this.webPlayer.pause()
          });
          if (_replayer.childNodes.length >= 5) {
            _replayer.childNodes[0].remove();
          }
        }
      });
      const active = document.querySelector(".active_event");
      this.webPlayer.addEventListener("ui-update-current-time", (e) => {
        this.tagsEvent.map((item, id) => {
          if (item.start_time <= e.payload / 1000) {
            active.childNodes[id].style.backgroundColor = "#1f51ff";
            active.childNodes[id];
            // ].childNodes[1].childNodes[0].childNodes[0].style.color = "#fff";
          }
          if (item.start_time > e.payload / 1000) {
            active.childNodes[id].style.backgroundColor = "#fff";
            active.childNodes[id];
            // ].childNodes[1].childNodes[0].childNodes[0].style.color = "#414B6C";
          }
        });
      });
    },
    showRunSteps() {
      this.showRunStepsModal = true;
    },
    setAlgorithm(elt) {
      this.stepsAlgorithmValue = elt.value;
      console.log(this.stepsAlgorithmValue);
    },
    async startTestEnhancerRun() {
      this.showRunStepsModal = false;
      if (this.RunStepRecommended === true) {
        this.stepsAlgorithmValue = "exploration";
      }
      if (this.stepsAlgorithmValue == null) {
        return this.$notify({
          type: "danger",
          message: "Select Algorithm",
        });
      }
      let formValues = {
        type: this.stepsAlgorithmValue,
        max_episodes: this.maxEpisodesValue,
        pageview_config: {
          key: this.selectProjectKey,
        },
      };
      if (formValues) {
        this.loadingAll = this.showRuns === true;
        const sendToBackend = true;
        axios.defaults.validateStatus = (status) => {
          return (
            status === 400 || (status >= 200 && status < 300) || status === 500
          );
        };
        if (sendToBackend) {
          const resp = await service.run_test_enhancer({
            id: this.items.requestId,
            parameters: formValues,
            action: "start",
          });
          if (resp.status === 200) {
            this.newRunId = resp.data.run_id;
            await this.getAllResults();
            this.$notify({
              type: "success",
              message: "Successfully started test run",
            });
            this.runActive = true;
          } else {
            if (
              resp.data.message ===
              "Another run is in progress, cannot run a new one!"
            ) {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: "Another run is in progress, cannot start a new one!",
              });
            } else {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: resp.data.message,
              });
            }
            this.loadingAll = false;
          }
        } else {
          console.log("Debugging values, not sending anything..");
          console.log("formvalues: ", formValues);
        }
      }
      // this.loadingAll = false;
    },
    async handleFixture() {
      if (this.currentFixture !== "" || this.currentFixture !== " ") {
        let arrayFixture = [];
        let tempArray = [];
        let isSessionId = false;
        if (localStorage.getItem("fixture")) {
          arrayFixture = JSON.parse(localStorage.getItem("fixture"));
          arrayFixture.map((item, id) => {
            if (item.session_id === this.currentFixtureId) {
              tempArray = item.session_tags;
              tempArray.push({
                fixtureTag: this.currentFixture,
              });
              item.session_tags = tempArray;
              isSessionId = true;
            }
          });
          if (!isSessionId) {
            arrayFixture.push({
              session_id: this.currentFixtureId,
              projectId: this.$route.params.id,
              session_tags: [
                {
                  fixtureTag: this.currentFixture,
                },
              ],
            });
          }
          console.log("arrayfixture", arrayFixture);
          localStorage.setItem("fixture", JSON.stringify(arrayFixture));
        } else {
          arrayFixture.push({
            session_id: this.currentFixtureId,
            projectId: this.$route.params.id,
            session_tags: [
              {
                fixtureTag: this.currentFixture,
              },
            ],
          });
          // SEND TAG TO BACKEND
          console.log("arrayfixture", arrayFixture);
          localStorage.setItem("fixture", JSON.stringify(arrayFixture));
        }
        let tags = [];
        arrayFixture.forEach((element, index) => {
          if (this.currentFixtureId === element.session_id) {
            element.session_tags.forEach((tag) => {
              console.log("fadsaaasda", tag.fixtureTag);
              tags.push(tag.fixtureTag);
            });
          }
          // console.log(`Element at index ${index}: ${element}`);
        });
        const params = new URLSearchParams();
        params.append("project_id", this.$route.params.id);
        params.append("session_id", this.currentFixtureId);
        params.append("customjson", JSON.stringify(arrayFixture));
        params.append("tags", JSON.stringify(tags));
        // params.append("limit", this.paginationSession.perPage);
        // params.append("page", this.paginationSession.currentPage);

        const route = this.$route.query.wring
          ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions/wring?${params}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions?${params}`;
        await axios
          .put(route)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                this.$notify({
                  type: "danger",
                  message: "Cannot Add Fixture",
                });
              } else if (response.data.status === "success") {
                this.getSessions();
              } else {
                this.$notify({
                  type: "danger",
                  message: "Cannot Add Fixture",
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingSession = false;

            this.$notify({
              type: "danger",
              message: "Cannot Add Fixture",
            });
          });

        this.toggleFixture = false;

        // this.updatingFixture()
        //  await this.getSessions()
      }
    },
    showRunSetting(val) {
      this.RunStepAdvanced = false;
      this.RunStepRecommended = false;
      if (val === "Advanced") {
        this.RunStepAdvanced = true;
      }
      if (val === "Recommended") {
        this.RunStepRecommended = true;
      }
    },

    str2bytes(str) {
      let bytes = new Uint8Array(str.length);
      for (let i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },

    async downloadDai() {
      this.loadingDai = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/appmap?project_id=${this.$route.params.id}&app_map_id=${this.appMapId}&dai=true`,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          if (response.status === 200) {
            const date = new Date();
            const fileName = `${this.projectDetail.name}_DAI_${
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate()
            }.zip`;
            // const content = Buffer.from(response.data, "hex");
            // const blob = new Blob([content], { type: "application/zip" });
            const blob = new Blob([response.data], { type: "application/zip" });

            saveAs(blob, fileName); //file-saver npm package

            // const blob = new Blob([this.str2bytes(response.data)], {type: "application/zip"});
            // const url = window.URL.createObjectURL(blob);
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("download", `${fileName}.zip`);
            // document.body.appendChild(link);
            // link.click();
          } else {
            this.$notify({
              type: "danger",
              message: "unable to download file ",
            });
          }
        })
        .finally(() => {
          this.loadingDai = false;
        });
    },
    async getFiletest2(val) {
      this.timeGap = 0;
      this.loadingFile = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.keyFlowSession);
      params.append("time_gap", this.timeGap);
      this.loadingModalAddRun = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/session/v1/testgen?" +
            params
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "an error has occurred",
              // });
              this.addTestModal = false;
              this.loadingFile = false;
            } else if (response.status === 200) {
              // console.log("response.data fro testgen: ", response)
              this.builFile2(response.data, val);
              this.loadingFile = false;
            } else {
              this.$notify({
                type: "danger",
                message: `recovery of the execution file failed : ${response.data.message}`,
              });
              this.addTestModal = false;
              this.loadingFile = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "recovery of the execution file failed ",
          });
          this.addTestModal = false;
        });
    },
    builFile2(file, val) {
      this.loadingModalAddRun = true;
      this.filesList = [];
      this.fileBackup = [];
      if (file.length) {
        file.map((item, index) => {
          if (item.tests[0].commands[0].command) {
            let jsonStringified = JSON.stringify(item);
            let jsonBlob = new Blob([jsonStringified], {
              type: "application/json",
            });
            let testFile = new File(
              [jsonBlob],
              `generated-test-run-file_${index}.wring`
            );
            this.fileBackup.push(testFile);
          }
        });
        this.filesList = this.fileBackup;
        this.filesListName = this.filesList[0].name;
        this.loadingModalAddRun = false;
      } else {
        if (file.tests[0].commands[0].command) {
          let jsonStringified = JSON.stringify(file);
          let jsonBlob = new Blob([jsonStringified], {
            type: "application/json",
          });
          let testFile = new File([jsonBlob], "generated-test-run.wring");
          this.filesList = [testFile];
          this.filesListName = this.filesList.name;
          this.fileBackup = [testFile];
        }
        this.loadingModalAddRun = false;
        if (val === "down") this.downloadTestFile(null);
      }

      this.listAddSuitTest = [this.fileBackup[this.fileBackup.length - 1]];
    },
    getIcon(action) {
      if (action == "$pageview") {
        return "el-icon-view";
      } else if (action == "$autocapture") {
        return "el-icon-position";
      } else if (action == "$pageleave") {
        return "el-icon-news";
      } else {
        return "el-icon-help";
      }
    },
    initializeSessionStateKeyFlow() {
      if (this.webPlayer !== null) {
        console.log("init", this.webPlayer.goto(this.currentTime));
        this.webPlayer.goto(this.currentTime);
      }
    },
    async startEvent(s) {
      this.currentTime = s * 1000;
      // await this.addPik();
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    changeFile(file) {
      this.filesList = [file];
    },
    async showComponent(val) {
      localStorage.setItem("selectedSection", val);
      var project_json = localStorage.getItem("latest_projects");
      var project_name = JSON.parse(project_json)[0].name;
      console.log(project_name);
      this.showEvent = false;
      this.showTestSuit = false;
      this.showAppMap = false;
      this.showStart = false;
      this.showApi = false;
      this.showAppChat = false;
      this.page_name = `${project_name}`;

      if (val === "testsuite") {
        this.showTestSuit = true;
        // this.page_name = "Test Suite"
      }
      if (val === "event") {
        this.showEvent = true;
        // this.page_name = "Events"
      }
      if (val === "api") {
        this.showApi = true;
        // this.page_name = "API"
        setTimeout(() => {
          this.handleUltraIpt();
          this.ultraIpt = "";
        }, 1000);
      }
      if (val === "appmap") {
        this.showAppMap = true;
        // this.page_name = "App Map"
      }
      if (val === "start") {
        this.showStart = true;
        // this.page_name = "Start"
      }
      if (val === "appChat") {
        this.showAppChat = true;
        // await this.createNewSession()
      }
    },

    cleanUpProject() {
      this.projectName = "";
      this.appUrls = "";
    },
    addTestModalProject() {
      this.addTestProject = true;
      this.appUrls = "";
      this.projectName = "";
      this.projectId = null;
      this.editProject = false;
      this.$refs["data-rendered"].innerHTML = "";
    },
    showResult(val) {
      this.showEvents = false;
      this.showSessions = false;
      if (val == "event") {
        this.showEvents = true;
      }
      if (val == "session") {
        this.showSessions = true;
      }
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },
    createNewSession(isButton = false) {
      if (isButton) {
        this.resultMessages = [];
        this.postedMessages = [];
      }
      const newId = () => {
        return String(performance.now()).replace(".", "") + Date.now();
      };
      this.sessionIdChat = newId();
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY");
    },
    formatHours(a) {
      return moment(a).format("h:mm:ss a");
    },
    formatDateState(a) {
      return moment(a).format("MMM DD YYYY");
    },
    formatDateAppMap(a) {
      return moment(a).format("MMM DD YYYY HH:mm");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss");
    },
    formatDateToLocalTime(a) {
      const date = new Date(a);
      const correctDate = this.formatDate(date);
      return correctDate;
    },
    async getDetailProject() {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      const url = `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${projectId}`;
      // check if the project contains mobile screenshots

      const resp = await axios.get(url);
      if (resp.data && resp.data.status === "success") {
        this.projectDetail = this.$route.query.wring
          ? { ...resp.data.response }
          : resp.data.response;
        this.getFixtureTagApp();

        this.appmapSettings.active =
          resp.data.response.attributes.appmapSettings.active;
        this.appmapSettings.updateDays =
          resp.data.response.attributes.appmapSettings.active;
        this.getLocalStorageKeys();
      }
    },
    getFixtureTagApp() {
      this.projectDetail.customjson !== null
        ? this.projectDetail.customjson?.tags !== undefined
          ? localStorage.setItem(
              "fixtureApp",
              JSON.stringify({
                fixtureTags: this.projectDetail.customjson?.tags,
                projectId: this.$route.params.id
                  .replace(/%20/g, "")
                  .replace(/\s/g, ""),
              })
            )
          : (this.listFixtureApp = null)
        : (this.listFixtureApp = null);
      localStorage.getItem("fixtureApp")
        ? (this.listFixtureApp = JSON.parse(localStorage.getItem("fixtureApp")))
        : (this.listFixtureApp = null);
      if (this.listFixtureTags !== null && this.listFixtureApp !== null) {
        this.listFixtureTags.map((item) => {
          if (
            item.projectId &&
            this.listFixtureApp &&
            this.listFixtureApp.projectId &&
            this.listFixtureApp.projectId
              .replace(/%20/g, "")
              .replace(/\s/g, "") ===
              item.projectId.replace(/%20/g, "").replace(/\s/g, "")
          ) {
            item.session_tags = item.session_tags.filter((tag) =>
              this.listFixtureApp.fixtureTags.includes(tag.fixtureTag)
            );
          }
        });
        localStorage.setItem("fixture", JSON.stringify(this.listFixtureTags));
      }
    },
    async updateAppmapSettings(event) {
      console.log("Change event:", event);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.$route.params.id}`;
      this.appmapSettings.updateDays = Number(this.appmapSettings.updateDays);
      let data = {
        appmapSettings: this.appmapSettings,
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the update app map Settings has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    isshowShed() {
      this.showShed = !this.showShed;
      console.log(this.showShed);
    },
    async getSessions(type) {
      //remove this for type of session after release
      type = "desktop";
      this.showComponentEvent("video");
      this.loadingSession = true;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      // const respInfo = this.projectDetail;
      if (type === "mobile") {
        this.loadMobileSessions = true;
        this.loadWebSessions = false;
        console.log(
          "app contains mobile event screenshots, switching to that mode"
        );
        this.eventSessionType = "mobile";
        this.Header2[1].label = "country".toUpperCase();
        this.Header2[2].label = "app / os".toUpperCase();
        this.Header2[3].label = "user agent".toUpperCase();
        this.Header2[4].label = "start time".toUpperCase();
        this.Header2[5].label = "duration / events".toUpperCase();
        // look up the sessions from the mobile sessions endpoint
        const mobileSessionParams = new URLSearchParams();
        mobileSessionParams.append("projectId", projectId);
        // pages are 1-indexed for this API
        mobileSessionParams.append("page", this.paginationSession.currentPage);
        mobileSessionParams.append("count", this.paginationSession.perPage);
        const mobileSessionResp = await axios.get(
          process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/mobile/sessions?" +
            mobileSessionParams
        );

        const mobileSessionData = mobileSessionResp.data;
        if (
          mobileSessionData["sessions"] &&
          mobileSessionData["sessions"].length > 0
        ) {
          console.log(mobileSessionData);
          this.totalSession = mobileSessionData["sessions"].length;
          this.resultSession = mobileSessionData["sessions"];
        } else {
          // this.$notify({
          //   type: "danger",
          //   message: `Could not look up sessions for project ID: ${projectId}`,
          // });
          this.resultSession = [];
          this.totalSession = 0;
        }

        this.filteredResultsSession = this.filteredResultSessionSet;
        this.initializeSessionState();
        this.loadingSession = false;
      } else {
        this.loadWebSessions = true;
        this.loadMobileSessions = false;
        this.eventSessionType = "web";

        this.Header2 = [
          {
            label: "",
            key: "_id",
            width: "6%",
            sortable: true,
            sortDetails: { asc: true, desc: false, type: "string" },
          },
          {
            label: "Country".toUpperCase(),
            key: "",
            width: "15%",
            sortable: false,
          },
          {
            label: "url".toUpperCase(),
            key: "",
            width: "25%",
            sortable: false,
          },
          {
            label: "browser".toUpperCase(),
            key: "",
            width: "12%",
            sortable: false,
          },
          {
            label: "Created".toUpperCase(),
            key: "",
            width: "16%",
            sortable: false,
          },
          {
            label: "duration".toUpperCase(),
            key: "",
            width: "10%",
            sortable: false,
          },
          { label: "".toUpperCase(), key: "", width: "10%", sortable: false },
        ];

        console.log(
          "app does not contain mobile event screenshots, switching to that mode"
        );

        const params = new URLSearchParams();
        params.append("project_id", projectId);
        params.append("limit", this.paginationSession.perPage);
        params.append("page", this.paginationSession.currentPage);

        const route = this.$route.query.wring
          ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions/wring?${params}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions?${params}`;
        await axios
          .get(route)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                // this.$notify({
                //   type: "danger",
                //   message: "an error has occurred",
                // });
                this.resultSession = [];
                this.totalSession = 0;
              } else if (response.data.status === "success") {
                this.resultSession = response.data.response;
                console.log("CHOSEN SESSION");
                console.log(this.resultSession);
                if (this.resultSession.length >= 1) {
                  if (
                    typeof this.resultSession[0]["distinct_id"] != "undefined"
                  ) {
                    this.chosen_session = this.resultSession[0]["distinct_id"];
                  } else {
                    this.chosen_session = null;
                  }
                } else {
                  this.chosen_session = null;
                }

                this.totalSession = response.data.totalSession;
                this.paginationSession.currentPage = response.data.currentPage;
                this.paginationSession.perPage = response.data.perPageCount;
                this.totalSession > 0
                  ? (this.checked3 = true)
                  : (this.checked3 = false);
              } else {
                this.resultSession = [];
                this.totalSession = 0;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingSession = false;

            // this.$notify({
            //   type: "danger",
            //   message: "an error occurred while retrieving data",
            // });
          });
        this.filteredResultsSession = this.filteredResultSessionSet;
        this.initializeSessionState();
        this.loadingSession = false;
      }
    },
    async deleteSesson(session_id) {
      const confirmation = await swal.fire({
        title: "Session will be removed",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      document.querySelector(".list-item-page").style.height = "1000px";
      if (confirmation.value === true) {
        this.loadingSession = true;

        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };

        let projectId = this.$route.params.id;
        const params = new URLSearchParams();
        params.append("project_id", projectId);
        params.append("session_id", session_id);
        const route = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions/wring?${params}`;
        await axios
          .delete(route)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                this.loadingSession = false;
                this.$notify({
                  type: "danger",
                  message: "an error occurred while delete session",
                });
              } else if (response.data.status === "success") {
                this.$notify({
                  type: "success",
                  message: "Session removed successfully",
                });
                this.getSessions();
              } else {
                this.loadingSession = false;
                this.$notify({
                  type: "danger",
                  message: "an error occurred while delete session",
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingSession = false;

            this.$notify({
              type: "danger",
              message: "an error occurred while delete session",
            });
          });
      }
      document.querySelector(".list-item-page").style.height = "100%";
    },
    closeFlowMenu() {
      this.fullSize = !this.fullSize;
      // this.isFlowMenu = !this.isFlowMenu

      // this.$nextTick(()=>{
      //   const divMap = document.getElementById("myDiagramDiv")
      // console.log("DIAGRAM",divMap)
      //   if(this.fullSize == true){
      //   divMap.style.height= '100vh'
      // }else{
      //   divMap.style.height = "50vh"
      // }
      // })
      // if(divMap && this.isFlowMenu == false){
      //   divMap.style.height = "100vh"
      // }else if(divMap && this.isFlowMenu == true){
      //   divMap.style.height = "50vh"
      // }
    },
    async changeDateAppMap() {
      console.log("date", this.selectDateAppmap);
      if (this.selectDateAppmap !== "Current") {
        this.toShowDateAppMap.map((item, id) => {
          if (id === this.selectDateAppmap) {
            this.appMapId = item.appmap_id;
            this.allDataAppMap.created_at = item.created_date;
            localStorage.setItem("appMapId", this.appMapId);
          }
        });
        if (this.appMapId !== "") {
          await this.getAppMapId();
          if (this.showTestGraph) {
            await this.$refs["test-graph"].updataNetwork();
          } else {
            await this.$refs["app-map"].createDiagram();
          }
        }
      } else {
        await this.getAppMap();
        if (this.showTestGraph) {
          await this.$refs["test-graph"].updataNetwork();
        } else {
          await this.$refs["app-map"].createDiagram();
        }
      }
    },
    async getAppMap() {
      this.loadingShowAppMap = true;
      console.log("session heigh", this.clientHeight);
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return (
          status === 400 || status === 404 || (status >= 200 && status <= 500)
        );
      };

      let projectId = this.$route.params.id;
      let appMapId = 0;
      const resp = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          `/pageviews/appmap?project_id=${projectId}&date_from=2023-09-01`
      );
      console.log("appnmap resp", resp.data);
      if (resp.data !== null && resp.data !== undefined && resp.data.appmaps) {
        console.log("appmaps", resp.data);
        this.toShowDateAppMap = resp.data.appmaps;

        try {
          resp.data.appmaps.map((item) => {
            if (item.status === "FINISHED" && appMapId < item.appmap_id) {
              appMapId = item.appmap_id;
              localStorage.setItem("appMapId", appMapId);
              this.appMapId = localStorage.getItem("appMapId");
            }
          });
        } catch (error) {
          // ✅ executes this line, as data has undefined value
          console.log("No appmaps computed 🤔", error);
        }

        const res = await axios.get(
          process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/appmap?project_id=${projectId}&app_map_id=${appMapId}`
        );
        if (res.data && res.data.summary_graph && res.data.flows) {
          if (res.data.summary_graph.nodes.length > 0) {
            this.allDataAppMap = res.data;
            const respInfo = res.data.summary_graph;
            this.toShowAppMap = respInfo;
            this.toShowKeyFlows = res.data.flows;
            this.loadingShowAppMap = false;
            this.checked4 = true;
          } else {
            this.isAppMapEmpty = true;
            this.loadingShowAppMap = false;
            this.toShowAppMap = {};
            await this.refreshMap();
          }
        } else {
          this.isAppMapEmpty = true;
          this.loadingShowAppMap = false;
          this.toShowAppMap = {};
          await this.refreshMap();
        }
      } else {
        this.isAppMapEmpty = true;
        this.loadingShowAppMap = false;
        this.toShowAppMap = {};
        await this.refreshMap();
      }
    },

    async getAppMapId() {
      this.loadingShowAppMap = true;
      let projectId = this.$route.params.id;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return (
          status === 400 || status === 404 || (status >= 200 && status <= 500)
        );
      };
      const res = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          `/pageviews/appmap?project_id=${projectId}&app_map_id=${this.appMapId}`
      );
      if (res.data.status === "finished") {
        // this.allDataAppMap = res.data
        const respInfo = res.data.summary_graph;
        this.toShowAppMap = respInfo;
        this.toShowKeyFlows = res.data.flows;
        console.log("func toShowAppMapp", this.toShowAppMap);
        if (this.isFlowSelected) {
          this.tempListNodes = [];
          console.log(
            "flowSelected",
            this.toShowKeyFlows[this.selectKeyFlow].nodes
          );
          this.toShowKeyFlows[this.selectKeyFlow].nodes.map((node) => {
            // temp.push(this.toShowAppMap.nodes.filter((item) => item.id === node.toString()))
            this.toShowAppMap.nodes.map((item) => {
              if (item.id === node) {
                this.tempListNodes.push(item);
              }
            });
          });
          if (this.tempListNodes.length > 0) {
            this.isFlowSelected = false;
            this.toShowAppMap.nodes = [];
            console.log("filtered", this.tempListNodes);
            this.tempListNodes.map((item) => {
              this.toShowAppMap.nodes.push(item);
            });
          }
        }
        this.loadingShowAppMap = false;
      } else {
        this.isAppMapEmpty = true;
        this.loadingShowAppMap = false;
        this.toShowAppMap = {};
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "The update app map failed.",
        });
      }
    },
    async processRun(val) {
      let url = val.person.properties.$initial_current_url.replace(
        /https:\/\//g,
        ""
      );
      this.run_name =
        url +
        " " +
        val.person.properties.$initial_browser +
        " " +
        val.person.properties.$geoip_country_name;
      this.filesList = [];
      this.fileBackup = [];
      await this.detailsSession(val);
    },
    async processShowVideo(val) {
      console.log("In processShowVideo method");
      console.log("Val from video is: ", val);
      val.id = val.tests[0].session_id;
      console.log("Video id:", val.id);
      await this.detailsSession(val);
    },
    beforeFileUpload(file) {
      console.log("file beforerere", file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected ${files.length} files this time, please remove the previous file`
      );
    },
    async onFileChangeAPI(file) {
      if (file.name.includes(".json")) {
        const formatData = new FormData();
        formatData.append("file", file.raw);
        formatData.append("project_id", this.$route.params.id);
        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/apitest`,
            formatData,
            { responseType: "blob" },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          await this.getSessions();
        } catch {
          this.$notify({
            type: "danger",
            message: "Unsuccesfully Upload",
          });
        }
      } else {
        this.$message.warning("You should upload JSON files.");
        this.$refs.uploadNewTest.clearFiles();
      }
    },
    async onFileChangeFirst(file) {
      if (file.name.includes(".ts") || file.name.includes(".json")) {
        this.fileList.push({ script1: { file } });
      } else {
        this.$message.warning(
          "You should upload Scripts (.ts files) or JSON files."
        );
        this.$refs.upload1.clearFiles();
      }
    },
    async onFileChangeMod(file) {
      if (file.name.includes(".ts")) {
        this.fileList.push({ script1modified: { file } });
      } else {
        this.$message.warning("You should upload Scripts (.ts files).");
        this.$refs.upload2.clearFiles();
      }
    },
    async onFileChangeSecond(file, fileList) {
      console.log(`fileee ${file} , ${fileList}`);
      if (file.name.includes(".ts") || file.name.includes(".json")) {
        this.fileList.push({ script2: { file } });
        console.log("TETSDA", this.fileList);
      } else {
        this.$message.warning(
          "You should upload Scripts (.ts files) or JSON files."
        );
        this.$refs.upload3.clearFiles();
      }
    },
    async beforeRemove(file, fileList) {
      console.log("file", file);
      const filteredList = this.fileList.filter((item) => {
        return (
          item.script1?.file?.name !== file.name &&
          item.script1modified?.file?.name !== file.name &&
          item.script2?.file?.name !== file.name
        );
      });

      this.fileList = filteredList;
      console.log("this/file", this.fileList);
    },
    base64ToBinary(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return bytes;
    },
    async processFiles() {
      this.uploadingFile = true;
      this.showPoppupUpload = false;
      this.showModalMerge = false;
      this.percentageUpload = 0;
      let formData = new FormData();
      let script1 = null;
      let script1modified = null;
      let script2 = null;
      let script1_type = null;
      let script1modified_type = null;
      let script2_type = null;

      const token = localStorage.getItem("vue-authenticate.access_token");

      // formData.append("project_id", this.$route.params.id);
      this.fileList.map((item) => {
        if (item.script1) {
          script1 = item.script1.file.raw;
          script1_type = item.script1.file.name.includes("json")
            ? "json"
            : "ts";
        } else if (item.script1modified) {
          script1modified = item.script1modified.file.raw;
          script1modified_type = item.script1modified.file.name.includes("json")
            ? "json"
            : "ts";
        } else if (item.script2) {
          script2 = item.script2.file.raw;
          script2_type = item.script2.file.name.includes("json")
            ? "json"
            : "ts";
        }
      });
      const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(reader.error);
          reader.readAsText(file);
        });
      };

      Promise.all([
        readFileAsText(script1),
        readFileAsText(script1modified),
        readFileAsText(script2),
      ])
        .then(([content1, content2, content3]) => {
          const params = {
            script1: content1,
            script1modified: content2,
            script2: content3,
            mode: "zip",
            project_id: this.$route.params.id.replaceAll(" ", ""),
            script2_type: script2_type,
            script1modified_type: script1modified_type,
            script1_type: script1_type,
          };

          fetch(
            process.env.VUE_APP_API_URL_PREFIX +
              "/pageviews/session/v1/mergingtests-exec",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(params),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);
              if (data.response.url) {
                const timneout = setInterval(async () => {
                  if (this.percentageUpload < 90) this.percentageUpload += 5;
                  await this.fetchingMergeFile(data.response.url, timneout);
                }, 9000);
              }
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: "Failed Upload Files.",
              });
              console.error("Error:", error);
            })
            .finally(() => {
              this.$refs.upload1.clearFiles();
              this.$refs.upload2.clearFiles();
              this.$refs.upload3.clearFiles();
              this.fileList = [];
            });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Failed Upload Files.",
          });
          console.error("Error reading files:", error);
        });
    },
    async fetchingMergeFile(url, timneout) {
      try {
        const res = await axios.get(process.env.VUE_APP_API_URL_PREFIX + url);
        if (res.data?.status != "processing") {
          const response = JSON.parse(res.data.response);
          console.log("repsonse", response);
          if (response.status == "success") {
            const binaryContent = this.base64ToBinary(response.script2modified);
            let blobData = new Blob([binaryContent], {
              type: "application/zip",
            });
            const downloadUrl = window.URL.createObjectURL(blobData);
            const link = document.createElement("a");
            let extension = ".zip";
            link.href = downloadUrl;
            link.setAttribute("download", "ModifiedScript" + extension);
            document.body.appendChild(link);
            link.click();

            this.$notify({
              type: "success",
              message: response.message,
            });
          } else {
            this.$notify({
              type: "danger",
              message: data.response.message,
            });
            if (response?.script2modified !== null) {
              const binaryContent = this.base64ToBinary(
                response.script2modified
              );
              let blobData = new Blob([binaryContent], {
                type: "application/zip",
              });
              const downloadUrl = window.URL.createObjectURL(blobData);
              const link = document.createElement("a");
              let extension = ".zip";
              link.href = downloadUrl;
              link.setAttribute("download", "ModifiedScript" + extension);
              document.body.appendChild(link);
              link.click();
            }
          }
          this.percentage = 100;
          clearInterval(timneout);
          this.uploadingFile = false;
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          type: "danger",
          message: "Failed Upload Files.",
        });
        this.percentage = 100;
        clearInterval(timneout);
        this.uploadingFile = false;

        this.isMerged = true;
      } finally {
      }
    },
    async onFileChange(file) {
      if (!file.name.includes(".json")) {
        this.$refs.uploadNewTest.clearFiles();
        this.$message.warning("You should upload JSON (.json files).");
        return;
      }
      console.log("file", file);
      this.uploadingFile = true;
      this.showPoppupUpload = false;
      this.percentageUpload = 0;
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("project_id", this.$route.params.id);
      formData.append("host", file.name);
      formData.append("start_url", "");
      const timneout = setInterval(() => {
        if (this.percentageUpload < 90) this.percentageUpload += 10;
      }, 1000);
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error

        return (
          status === 400 || status === 404 || (status >= 200 && status <= 500)
        );
      };
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX + `/pageviews/sessions/wring`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === "failed" && res.data.http_status !== 429) {
          this.$notify({
            type: "danger",
            message: "Cannot Upload the File... Try refresh",
          });
        } else if (res.data.http_status === 429) {
          let url = window.location.hostname;
          if (this.userName.tier_name === "enterprise") {
            this.$notify({
              type: "warn",
              duration: 6000,
              message: `You are above your usage limit.`,
            });
          } else {
            this.$notify({
              type: "warn",
              duration: 6000,
              message: `You are above your usage limit. Upgrade your plan to ${
                this.userName.tier_name === "community" ? "Pro" : "Enterprise"
              } to continue to use the product, <a href='https://${url}/details/subscription'>https://${url}/details/subscription</a>`,
            });
          }
        } else {
          this.$notify({
            type: "success",
            message: `File ${file.name} Uploaded Successfully`,
          });
        }

        this.percentageUpload = 100;
        await this.getSessions();
        console.log(res);
      } catch (err) {
        this.$notify({
          type: "danger",
          message: "Cannot Upload the File... Try refresh",
        });
        console.log(err);
      } finally {
        this.uploadingFile = false;

        clearInterval(timneout);
      }
    },
    async downloadTestFileChat(type) {
      this.edittingPrompt = false;
      this.isEditLoading = true;
      this.isTestDownload = true;
      const projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("type", type);
      params.append("session_id", this.chosen_session);
      params.append("feature_file", this.showEditPrompt);
      params.append("isValidate", this.updateValidate);

      const date = new Date();
      const fileName = `ChatTest-${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.wring`;
      this.showProgressBar = true;
      this.percentage = 0;
      const timeout = setInterval(() => {
        if (this.percentage < 90) this.percentage += 10;
      }, 1000);

      try {
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/testgen`; // Ensure the correct API endpoint

        console.log(
          "Starting regular file download with axios:",
          params.toString()
        );
        const token = localStorage.getItem("testgold.aioToken");
        const response = await axios.get(`${url}?${params.toString()}`, {
          responseType:
            type === "cucumber" ||
            type === "cucumberconfig" ||
            type === "sensetalk_zip"
              ? "blob"
              : "json",
        });

        console.log("Axios response received:", response);

        let responseData;
        let blobData;
        if (response.data.http_status === 429) {
          let url = window.location.hostname;
          if (this.userName.tier_name === "enterprise") {
            this.$notify({
              type: "warn",
              duration: 6000,
              message: `You are above your usage limit.`,
            });
          } else {
            this.$notify({
              type: "warn",
              duration: 6000,
              message: `You are above your usage limit. Upgrade your plan to ${
                this.userName.tier_name === "community" ? "Pro" : "Enterprise"
              } to continue to use the product, <a href='https://${url}/details/subscription'>https://${url}/details/subscription</a>`,
            });
          }
        } else {
          if (
            type === "cucumber" ||
            type === "cucumberconfig" ||
            type === "sensetalk_zip"
          ) {
            blobData = response.data;
          } else {
            responseData = response.data;
            if (type === "wringr") {
              responseData = JSON.stringify(response.data);
            }
            blobData = new Blob([responseData], { type: "application/json" });
          }
        }

        const downloadUrl = window.URL.createObjectURL(blobData);
        const link = document.createElement("a");
        let extension = ".wringr";

        switch (type) {
          case "sensetalk":
            extension = ".script";
            break;
          case "cypress":
            extension = ".cy";
            break;
          case "selenium":
            extension = ".py";
            break;
          case "playwright":
            extension = ".spec.js";
            break;
          case "puppeteer":
            extension = ".py";
            break;
          case "cucumber":
          case "cucumberconfig":
          case "sensetalk_zip":
            extension = ".zip";
            break;
        }

        link.href = downloadUrl;
        link.setAttribute("download", fileName + extension);
        document.body.appendChild(link);
        link.click();

        this.$notify({
          type: "success",
          message: "File downloaded successfully.",
        });

        this.percentage = 100;
      } catch (err) {
        console.error("Error during file download:", err);
        this.$notify({
          type: "danger",
          message: "Cannot Download File, Try To Reload Page.",
        });
        clearInterval(timeout);
      } finally {
        this.isTestDownload = false;
        this.showProgressBar = false;
        this.isEditLoading = false;
        clearInterval(timeout);
        console.log("Download process complete.");
      }
    },
    downloadTestFile(index) {
      // console.log("Before downloading file: ", files);
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async actionCoveringtests(action, elt) {
      this.id = elt.tests[0].session_id;
      if (action === "downloadTestFile") {
        await this.getFiletest("dow");
        this.downloadTestFile();
      }
      if (action === "runTest") {
        this.addTestModal = true;
        await this.getFiletest("run");
      }
    },
    savePagination() {
      sessionStorage.setItem("testEventPageNbr", this.pagination.perPage);
      this.getEvents();
    },
    savePaginationSession() {
      sessionStorage.setItem("sessionPageNbr", this.paginationSession.perPage);
      this.getSessions();
    },
    handleSizeChangeSession(val) {
      this.fromSession = val * this.paginationSession.perPage;
      this.toSesion =
        parseInt(this.fromSession) + parseInt(this.paginationSession.perPage);
      const sortByMapped = (map, compareFn) => (a, b) =>
        compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsSession = [...this.filteredResultsSession].sort(
        byPrice
      );
      this.toShowSession = this.filteredResultsSession;
      this.updatingFixture();
      this.filteredResultsSessionAPI = this.toShowSession;
    },
    updatingFixture() {
      this.loadingSession = true;
      if (this.listFixtureTags !== null) {
        this.listFixtureTags.map((item) => {
          this.toShowSession.map((session) => {
            if (item.session_id === session.id) {
              session["fixtureTags"] = item.session_tags;
            }
          });
        });
        this.filteredResultsSessionAPI = this.toShowSession;
      } else if (localStorage.getItem("fixture")) {
        this.listFixtureTags = JSON.parse(localStorage.getItem("fixture"));
        this.updatingFixture();
      }
      this.loadingSession = false;
      // this.sortList('fixtureTag')
      console.log("listFixtureTags", this.toShowSession);
    },
    async handleDelete(elt, fixture, index) {
      console.log(elt, fixture, index);
      let json = JSON.parse(localStorage.getItem("fixture"));
      let tempList = [];
      json.map((item) => {
        if (item.session_id !== elt.id) {
          tempList.push(item);
        } else {
          item.session_tags.map((tag, id, array) => {
            array.splice(index, 1);
          });
          console.log("item", item);
          tempList.push(item);
        }
      });
      console.log("tempList", tempList);
      elt.tags.splice(index, 1);
      localStorage.setItem("fixture", JSON.stringify(tempList));
      const params = new URLSearchParams();
      params.append("project_id", this.$route.params.id);
      params.append("session_id", elt.id);
      params.append("customjson", elt.customjson);
      params.append("tags", JSON.stringify(elt.tags));
      // params.append("limit", this.paginationSession.perPage);
      // params.append("page", this.paginationSession.currentPage);

      const route = this.$route.query.wring
        ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions/wring?${params}`
        : `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessions?${params}`;
      await axios
        .put(route)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "Cannot Add Fixture",
              });
            } else if (response.data.status === "success") {
              this.getSessions();
            } else {
              this.$notify({
                type: "danger",
                message: "Cannot Add Fixture",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingSession = false;

          this.$notify({
            type: "danger",
            message: "Cannot Add Fixture",
          });
        });

      // window.location.reload()
    },
    filterSessionsApi() {
      this.filteredResultsSessionAPI = this.toShowSession.filter((result) => {
        if (
          result.person.properties.$current_url
            .toLowerCase()
            .includes(this.filterValue.toLowerCase())
        ) {
          return result.person.properties.$current_url
            .toLowerCase()
            .includes(this.filterValue.toLowerCase());
        } else if (
          result.person.properties.$host
            .toLowerCase()
            .includes(this.filterValue.toLowerCase())
        ) {
          return result.person.properties.$host
            .toLowerCase()
            .includes(this.filterValue.toLowerCase());
        }
      });
    },
    async sendEditMessage() {
      this.messageEdit = "";
      this.isEditLoading = true;
      let message = "";
      if (this.userPrompt != "") {
        const dataSending = {
          chat_version: "WringGPT",
          engine: "openai",
          context: this.chosenFile,
          type: "edit_file",
          project_id: this.$route.params.id,
          user_id: this.userId.system_id,
          session_name: this.sessionIdChat.toString(),
          messages: this.userPrompt,
        };
        const json = JSON.stringify(dataSending);
        const response = await fetch(
          `/interceptor/pageviews/mixtralchat/v2?engine=openai`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: json,
          }
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        let buffer = "";

        // Function to read streamed content
        const processStream = async () => {
          while (true) {
            this.loadingMessage = false;
            const { done, value } = await reader.read();
            if (done) break;

            // Decode and add to buffer
            buffer += decoder.decode(value, { stream: true });

            // Check for new lines
            const lines = buffer.split("\n");
            buffer = lines.pop(); // Save the last line (might be incomplete)

            lines.forEach(async (line) => {
              const trimmedLine = line.trim(); // Remove extra whitespace
              if (trimmedLine) {
                try {
                  const parsed = JSON.parse(trimmedLine);
                  if (parsed.content) {
                    await this.delay(50);
                    parsed.content = this.colorOutput(parsed.content);
                    this.messageEdit += parsed.content;
                    // Ensure the UI updates immediately
                    this.$nextTick(() => {
                      const chatBox = document.querySelector(".chat-box");
                      if (chatBox) {
                        chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                      }
                    });
                  }
                } catch (err) {
                  console.warn("Skipping malformed line:", line, err); // Log and continue
                }
              }
            });
          }

          // Process any remaining data in the buffer
          if (buffer.trim()) {
            try {
              const parsed = JSON.parse(buffer.trim());
              if (parsed.content) {
                await this.delay(50);
                this.messageEdit += parsed.content;
                // Ensure the UI updates immediately
                this.$nextTick(() => {
                  const chatBox = document.querySelector(".chat-box");
                  if (chatBox) {
                    chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                  }
                });
              }
            } catch (err) {
              console.error("Error parsing JSON from stream at end:", err);
            }
          }
        };

        await processStream();
        // this.messageEdit = this.messageEdit.replaceAll("```",'').replace('feature','').replace('gherkin','')
        // this.colorOutput()
        this.isEditLoading = false;
      }
    },
    handleSizeCoveringTests() {
      const sortByMapped = (map, compareFn) => (a, b) =>
        compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(
        byPrice
      );
      this.toShowProject = this.filteredResultsProject;
    },
    handleCurrentChangeSession(val) {
      console.log("currentpage", this.paginationSession.currentPage);
      val === "next"
        ? (this.paginationSession.currentPage =
            this.paginationSession.currentPage + 1)
        : (this.paginationSession.currentPage =
            this.paginationSession.currentPage - 1);
      this.getSessions();
    },
    handleCurrentChangeCallApi(val) {
      val === "next"
        ? (this.callApiCurrentPage = this.callApiCurrentPage + 1)
        : (this.callApiCurrentPage = this.callApiCurrentPage - 1);
      this.getSessionCallApi();
    },
    initializeSessionState() {
      this.filteredResultsSession = this.filteredResultSessionSet;
      this.handleSizeChangeSession(this.paginationSession.currentPage);
    },
    initializeSessionEvent() {
      this.tagsEvent = this.f;
    },
    initializeCoveringtestsState() {
      this.toShowCoveringtests = this.filteredResultCoveringtestsSet;
    },
    initializeApiState() {
      this.filteredResultApi = this.filteredResultApiSet;
    },
    forStepNumber(row) {
      let result =
        this.paginationSession.currentPage * this.paginationSession.perPage -
        this.paginationSession.perPage +
        (row + 1);
      if (isNaN(result)) {
        this.paginationSession.perPage = 10;
        result =
          this.paginationSession.currentPage * this.paginationSession.perPage -
          this.paginationSession.perPage +
          (row + 1);
      }
      return result;
    },
    removedtag(row) {
      return row.replace("$", "");
    },
    closeModalScreenshots() {
      let imgModal = document.getElementById("imgModal");
      if (this.$refs.ss_remove !== undefined) {
        this.$refs.ss_remove.map((item) => {
          item.$el.classList.toggle("is-hide--rect");
        });
      }
      imgModal.style.display = "none";
    },
    async getScriptFiles() {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL_PREFIX}/testfiles/v1/scripts/all`)
          .then((response) => {
            if (response.data) {
              const val = response.data.response;
              this.scriptFiles = Object.entries(val).map((elt) => {
                return {
                  label: elt[0],
                  value: elt[1][0].fileKey,
                };
              });
            }
          })
          .catch((e) => {
            // this.$notify({
            //   type: "danger",
            //   message: `An error has occured : ${e}`,
            // });
            console.log("Error has occurred: ", e);
          });
      } catch (e) {
        console.log("Error has occurred: ", e);

        // this.$notify({
        //   type: "danger",
        //   message: `An error has occured : ${e.response.message}`,
        // });
      }
    },
    async addPik() {
      await this.$nextTick();
      const lectBar = document.getElementsByClassName("rr-progress")[0];
      const timeLine = document.getElementsByClassName("rr-timeline")[0];
      const progress__handler = document.getElementsByClassName(
        "rr-progress__handler"
      )[0];
      const progress__step =
        document.getElementsByClassName("rr-progress__step")[0];
      const ticks = document.createElement("div");
      ticks.classList.add("ticks");
      for (let index = 0; index < this.tagsEvent.length; index++) {
        const ticks_box = document.createElement("div");
        ticks_box.classList.add("tick-hover-box");
        let left =
          (parseInt(this.tagsEvent[index].start_time) * 100) /
            this.activeSessionDuration -
          0.5;
        if (left > 100) left = 100 - 0.5;
        if (left < 0) left = 0;
        ticks_box.style.left = left + "%";

        const tick_info = document.createElement("div");
        tick_info.classList.add("tick-info");
        tick_info.innerHTML = this.tagsEvent[index].event;

        const tick_marker = document.createElement("div");
        tick_marker.classList.add("tick-marker");

        const tick_thumb = document.createElement("div");
        tick_thumb.classList.add("tick-thumb");
        ticks_box.setAttribute("id", "tick_thumb_" + index);

        ticks_box.appendChild(tick_info);
        ticks_box.appendChild(tick_marker);
        ticks_box.appendChild(tick_thumb);
        ticks.appendChild(ticks_box);
      }
      console.log("hello");

      timeLine && (timeLine.style.width = "100%");
      progress__step.style.zIndex = "2";
      progress__handler.style.zIndex = "2";
      lectBar.appendChild(ticks);
      // this.autoplay = true;
    },
    cleanUpState() {
      this.run_name = "";
      this.run_description = "";
      this.run_tags = "";
      this.browser_width = "1920";
      this.browser_height = "1080";
      this.max_wait_time = "5.0";
      this.ignore_disabled_elements = "1";
      this.ignore_invisible_elements = "1";
      this.collect_page_interaction_data = "1";
      this.extra_element_data = 0;
      this.downweight_strings = 0;
      this.all_suggest = 0;
      this.try_to_avoid = false;
      this.bad_attributes_list = "";
      this.good_attributes_list = "";
      this.continue_test_suite_on_fail = "true";
      this.timeout_cancel_run = "";
      this.timeout_cancel_run_value = null;
      this.handle_failure = "";
      this.verify_suggested_selectors = false;
      this.enable_integrations = true;
      this.collapseActive = null;
      this.emailAddress = "";
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getFiletest(val) {
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.keyFlowSession);
      params.append("time_gap", this.timeGap);
      // params.append()
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/session/v1/testgen?" +
            params
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "an error has occurred",
              // });
              this.addTestFlowVideo = false;
              this.loadingFileFlowVideo = false;
            } else if (response.status === 200) {
              this.builFile(response.data, val);
            } else {
              this.$notify({
                type: "danger",
                message: `This user video is too short to generate a test, please choose a longer video`,
              });
              this.addTestFlowVideo = false;
              this.loadingFileFlowVideo = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message:
              "This user video is too short to generate a test, please choose a longer video ",
          });
          this.addTestFlowVideo = false;
        });
    },
    builFile(file, val) {
      this.loadingModalAddRun = true;
      console.log("test", this.webPlayer);
      this.filesList = [];
      this.fileBackup = [];
      if (file.length) {
        // Just get the last test
        // file = file[file.length - 1];
        file.map((item, index) => {
          if (item.tests[0].commands[0].command) {
            let jsonStringified = JSON.stringify(item);
            let jsonBlob = new Blob([jsonStringified], {
              type: "application/json",
            });
            let testFile = new File(
              [jsonBlob],
              `generated-test-run-file_${index}.wring`
            );
            this.fileBackup.push(testFile);
          }
        });
        this.filesList = [this.fileBackup[this.fileBackup.length - 1]];
        this.filesListName = this.filesList[0].name;
        this.loadingModalAddRun = false;
        if (val === "down") {
          if (this.fileBackup.length == 1) {
            this.downloadTestFile(0);
            let dropdown_menu = document.getElementsByClassName(
              "el_dropdownMenuDown"
            )[0];
            dropdown_menu.style.left = "1000px";
          } else {
            let element = document.getElementById("el_dropdownDown");
            // Création d'un nouvel événement de clic
            var event = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            // Déclenchement de l'événement de clic sur l'élément
            element.dispatchEvent(event);
            let dropdown_menu = document.getElementsByClassName(
              "el_dropdownMenuDown"
            )[0];
            dropdown_menu.style.left = "1000px";
          }
        }
      } else {
        if (file.tests[0].commands[0].command) {
          let jsonStringified = JSON.stringify(file);
          let jsonBlob = new Blob([jsonStringified], {
            type: "application/json",
          });
          let testFile = new File([jsonBlob], "generated-test-run.wring");
          this.filesList = [testFile];
          this.filesListName = this.filesList.name;
          this.fileBackup = [testFile];
        }
        this.loadingModalAddRun = false;
        if (val === "down") this.downloadTestFile(null);
      }
    },
    initchecked() {
      for (let i = 0; i < this.resultCoveringtests.length; i++) {
        this.checked[i] = false;
      }
    },
    async getCoveringTests() {
      this.loadingShowCoveringtests = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      this.date_from = moment(this.filterDate[0]).format("YYYY-MM-DD");
      // this.date_to = moment(this.filterDate[1]).format("YYYY-MM-DD");
      // params.append("date_to", this.date_to);
      params.append("date_from", this.date_from);
      if (this.date_from == "Invalid date") {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "Invalid date.",
        });
        this.loadingShowCoveringtests = false;
        return;
      }
      params.append("limit", this.limitCoveringtests);
      params.append("srcdata", "sessions");
      params.append("project_id", projectId);
      // params.append("percent", this.SelectCov);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/keyflow?${params}`;
      try {
        axios.defaults.validateStatus = (status) => {
          return (
            status === 400 || (status >= 200 && status < 300) || status === 500
          );
        };
        const res = await axios.get(url, params);
        if (res.data && res.status === 200) {
          this.resultCoveringtests = res.data.testcases.reverse();
          await this.initchecked();
        } else {
          this.toShowCoveringtests = [];
          console.log(res.data.message);
        }
      } catch (error) {
        console.log("an error occurred while covering the project.:", error);
      } finally {
        this.loadingShowCoveringtests = false;
        this.initializeCoveringtestsState();
      }
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.resultProject = res.data.response;
          this.totalProject = res.data.response.length;
        } else {
          this.resultProject = [];
          this.totalProject = 0;
        }
      } catch (error) {
        console.log(errors);
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
        this.selectProjectId = Number(this.$route.params.id);
        this.filteredResultsProject = this.filteredResultProjectSet;
      }
    },
    handleMultipleSelection(elt, id) {
      if (this.checked[id]) {
        elt["index"] = id;
        this.multipleSelection.push(elt);
      } else {
        this.multipleSelection = this.multipleSelection.filter((item) => {
          if (item.index != id) {
            return item;
          }
        });
      }
    },
    async RunAll() {
      this.filesList = [];
      this.fileBackup = [];
      // const time_gap = await swal.fire({
      //   title: 'Inactivity period for splitting tests',
      //   input: 'number',
      //   inputPlaceholder: '0 to 10,000',
      //   preConfirm: (value) => {
      //     if (value == '') {
      //       swal.showValidationMessage("minimum value is 0");
      //     } else if (parseInt(value) > 10000) {
      //       swal.showValidationMessage("maximum value is 10000");
      //     }
      //     return value;
      //   },
      //   showCloseButton: true,
      // });
      //
      // if (isNaN(Number(time_gap.value))) {
      //   return
      // } else {
      //   this.timeGap = time_gap.value
      // }
      this.timeGap = 0;

      this.loadingRun = true;
      for (let item of this.multipleSelection) {
        await this.getFiletest(item);
      }
      if (this.fileBackup.length > 0) this.addTestModal = true;
      this.loadingRun = false;
    },
    showContent(val) {
      this.scheduledRun = false;
      if (val === "Scheduled") {
        this.scheduledRun = true;
      } else if (val === "Configure") {
        this.configureRun = true;
      } else if (val === "RunTest") {
        this.addTestModal = true;
      }
    },
    async addTest() {
      const ifMultipleFiles = this.filesList.length > 1;
      const files = this.filesList;
      let scriptType = "JSON";
      var single = false;
      var double = false;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail
          ? "continue"
          : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        runTags: parsedRunTags.join(","),
      };
      const formData = new FormData();
      let reqHeaders = {};
      if (ifMultipleFiles) {
        double = true;
        let indexP = this.resultProject.findIndex(
          (e) => e.projectId === Number(this.$route.params.id)
        );
        parsedRunTags.push(
          `${this.resultProject[indexP].name}_${this.$route.params.id}`
        );
        reqHeaders = {
          "x-test-script-type": scriptType,
          "Content-Type": "multipart/form-data;",
          "X-Run-Tags": runOptions.runTags,
        };
        formData.append("suiteName", this.run_name);
        formData.append("suiteDesc", this.run_description);
        formData.append("suiteRunName", `Test suite run - ${this.run_name}`);
        formData.append("suiteRunOptions", JSON.stringify(runOptions));
        formData.append("suiteRunTags", JSON.stringify(parsedRunTags));
        const suiteFiles = JSON.stringify(
          files.map((item, index) => {
            formData.append(
              "attachedFile",
              this.filesList[index],
              this.filesList[index].name
            );
            return {
              fileName: item.name,
              fileType: item.type,
              position: index + 1,
            };
          })
        );
        formData.append("suiteFiles", suiteFiles);
      } else {
        single = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch":
            this.run_name +
            " file_" +
            files[0].name
              .replace("generated-test-run", "")
              .replace(".side", ""),
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors
            ? "1"
            : "0",
        };

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }
        formData.append("attachedFile", files[0], files[0].name);
      }
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      if (single) {
        try {
          this.loadingModalAddRun = true;
          const res = await axios.post(
            process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1",
            formData,
            { headers: reqHeaders }
          );
          if ((res.data.status = "queued")) {
            this.run_description = "";
            this.addTestModal = false;
            this.pollStatus = true;
            this.singleTestId = true;
            this.loadingModalAddRun = false;
            this.lastUrlTest = "/details/test-explorer/list-tests";
            // swal.fire(`<div style="width: 100%;">Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a></div>`);
            this.$notify({
              type: "success",
              message: `Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a>`,
            });
          } else {
            this.$notify({
              type: "danger",
              message: "execution failed",
            });
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "execution failed",
          });
        }
        this.loadingModalAddRun = false;
      }
      if (double) {
        this.loadingModalAddRun = true;

        axios
          .post(
            process.env.VUE_APP_API_URL_PREFIX + "/testsuite/v1",
            formData,
            {
              headers: reqHeaders,
            }
          )
          .then((response) => {
            if ((response.data.status = "success")) {
              this.$notify({
                type: "success",
                message: this.run_name + " added successfully.",
              });
              this.run_name = "";
              this.run_description = "";
              this.filesList = [];
              this.addTestModal = false;
              this.pollStatus = true;
              this.singleTestId = true;
            } else {
              this.$notify({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: this.run_name + " was not added successfully. Details: ",
              error,
            });
          });

        this.loadingModalAddRun = false;
      }
      this.addTestModal = false;
    },
    _onRowClicked(elt) {
      if (this.projectDetail.attributes.testSuiteId) {
        this.$router.push({
          name: "Suite View Item",
          params: {
            suiteId: this.projectDetail.attributes.testSuiteId,
            id: elt,
          },
        });
      } else {
        this.$notify({
          type: "danger",
          message: "no test suite has been run!",
        });
      }
    },
    async cancelRunItem(row) {
      const requestId = row.requestId;
      const headers = {
        "Content-Type": "application/json",
      };

      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                process.env.VUE_APP_API_URL_PREFIX +
                  `/testrun/v1/cancel/${requestId}`,
                { headers }
              )
              .then(async (response) => {
                this.$notify({
                  type: "success",
                  message: "Stopped test run!",
                });
                await this.getDetailProject();
                this.getList();
              })
              .catch((err) => {
                this.$notify({
                  type: "danger",
                  message: "Error stopping test run!",
                });
              });
          }
        });
    },
    closeEdittingPrompt() {
      this.edittingPrompt = false;
      this.showEditPrompt = this.messageEdit;
      this.messageEdit = this.colorOutput(this.messageEdit);
    },
    modifyEdittingPrompt() {
      this.edittingPrompt = false;
      this.messageEdit = this.showEditPrompt;
      this.messageEdit = this.colorOutput(this.messageEdit);
    },
    editTestModal(row) {
      this.suit_run_name = row.name;
      this.toggleEditTestModal = true;
    },
    async saveEditTestSuite() {
      this.loadingTest = true;
      this.toggleEditTestModal = false;
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const formData = new FormData();
      formData.append("suiteName", this.suit_run_name);
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`,
          formData
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            this.loadingTest = false;
            await this.getDetailProject();
            this.getList();
          } else {
            this.loadingTest = false;
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot edit suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });
    },
    async saveAddFileTestSuite() {
      this.loadingTest = true;
      this.toggleReAddFileModal = false;
      this.addTestModal = false;
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const files = this.listAddSuitTest;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const formData = new FormData();
      let suiteFiles = [];

      for (let i = 0; i < files.length; i++) {
        let fileNmae = files[i].name;
        if (this.labelSelectedKeyFlow.length > 1) {
          fileNmae = `${this.labelSelectedKeyFlow.replace(/\s+/g, "-")}_${
            files[i].name
          }`;
        }
        formData.append(
          "attachedFile",
          files[i],
          fileNmae,
          this.labelSelectedKeyFlow
        );
        suiteFiles.push({ operation: "add", fileName: fileNmae });
      }
      this.keyFlowName.push(this.labelSelectedKeyFlow);
      formData.append("suiteFiles", JSON.stringify(suiteFiles));
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`,
          formData
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            this.$notify({
              type: "success",
              message: `${this.labelSelectedKeyFlow} Testcase was added successfully.`,
            });
            console.log(response);
            await this.getDetailProject();
            this.getList();
          } else {
            this.loadingTest = false;
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot add suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });
    },
    async runTestSuiteProject(type) {
      this.loadingTest = true;
      this.addTestAppModal = false;
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const files = this.listAddSuitTest;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail
          ? "continue"
          : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
        runTags: parsedRunTags.join(","),
        shareRun: this.checkedOrg ? "org" : null,
      };

      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        runOptions["runStartUrl"] = this.testrun_start_url;
      }
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      let suiteFilesRun = [];
      let selectFile = this.setAllRunFile.filter((item) => {
        if (item.activeFile === true) return item;
      });
      if (this.run_all == true) {
        for (let i = 0; i < selectFile.length; i++) {
          suiteFilesRun.push(selectFile[i].fileKey);
        }
      } else {
        suiteFilesRun = this.suiteFiles;
      }
      let tags = this.runTagsSuit.split(" ");
      if (this.runTagsSuit === "") tags = [];

      if (this.runServer == "source") {
        tags.push("sauce labs");
      }
      if (this.runServer == "aws") {
        tags.push("aws");
      }
      tags.push("pageviews-project");
      let data = {
        suiteId: testSuitRunId,
        suiteRunName: this.suiteRunName,
        suiteFiles: suiteFilesRun,
        suiteRunTags: tags,
        runOptions: runOptions,
      };
      if (this.suiteSingleRun === false) {
        data.suiteRunOptions = {
          filterDisplayed: this.filterDisplayed,
          runStartUrl: this.runStartUrl,
        };
        data.suiteSingleRun = 1;
      } else {
        data.suiteSingleRun = 1;
      }
      let dataStorage = null;
      if (localStorage.getItem("sauceLabs")) {
        console.log(JSON.parse(localStorage.getItem("sauceLabs")));
        dataStorage = JSON.parse(localStorage.getItem("sauceLabs"));
      }
      if (localStorage.getItem("aws")) {
        dataStorage = JSON.parse(localStorage.getItem("aws"));
      }
      console.log("datastorage", dataStorage);
      let resHeaders = {};
      if (this.runServer == "source") {
        resHeaders = Object.assign({}, resHeaders, {
          "X-Cloud-Browser":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_browsers,
          "X-Cloud-Browser-Version": "latest",
          "X-Cloud-Platform-Name":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_platforms,
          "X-Cloud-Username":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_username,
          "X-Cloud-Access-Key":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_accesstoken,
          "X-Cloud-Region":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_region,
          "X-Cloud-Type":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_type,
        });
      }
      if (this.runServer == "aws") {
        resHeaders = Object.assign({}, resHeaders, {
          "X-Cloud-Browser":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_browsers,
          "X-Cloud-Browser-Version": "latest",
          "X-Cloud-Platform-Name":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_platforms,
          "X-Cloud-Access-Secret-Key":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_secret_key,
          "X-Cloud-Access-Key":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_access_key,
          "X-Cloud-Region":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_region,
          "X-Cloud-Type": "aws",
        });
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}/run`,
          data,
          {
            headers: resHeaders,
          }
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (
            response.data.status == "success" ||
            response.data.status == "queued"
          ) {
            await this.getDetailProject();
            this.getList();
            this.checkedOrg = false;
            this.$notify({
              type: "success",
              message: "Test suite run successfully.",
            });
            this.cleanUpState();
          } else {
            this.checkedOrg = false;
            this.loadingTest = false;
            this.$notify({
              type: "danger",
              message: "test execution failed.. Try refresh",
            });
          }
        })
        .catch((error) => {
          this.checkedOrg = false;
          this.$notify({
            type: "danger",
            message: "Cannot edit suite test... Try refresh",
          });
        })
        .finally(() => {
          this.checkedOrg = false;
          this.loadingTest = false;
        });
    },
    async addFileModal() {
      this.listAddSuitTest = [];
      this.toggleReAddFileModal = true;
    },
    async deleteItem(row, key) {
      const keyFile = key;
      const testSuitRunId = this.projectDetail.attributes.testSuiteId;
      const confirmation = await swal.fire({
        title: "File '" + row.fileName + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value === true) {
        this.detailTestApp = false;
        const formData = new FormData();
        formData.append(
          "suiteFiles",
          JSON.stringify([{ operation: "remove", fileKey: row.fileKey }])
        );
        await axios
          .put(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`,
            formData
          )
          .then(async (response) => {
            // this.items = Object.values(response.data.response);
            if (response.data.status == "success") {
              this.$notify({
                type: "success",
                message: "Test delete successfully.",
              });
              await this.getDetailProject();
              await this.getList();
              this.loadingTestDelete = false;
            } else {
              this.loadingTestDelete = false;
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            this.loadingTestDelete = false;
          });
      }
    },
    pollSingleTestfilteredResultSet() {
      return this.items.map((item) => {
        if (item.testSuiteId && !item.runName.includes("Test Suite -")) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
    },
    showGuide() {
      console.log("guid line", this.showApi);
      if (this.showAppMap) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#app-map",
              popover: {
                title: "App Map Visualization",
                description: "Explore an App Map generated from your sessions.",
                side: "left",
                align: "start",
              },
            },
            {
              element: "#btn-update--appmap",
              popover: {
                title: "Refresh App Map",
                description:
                  "Click this button to update the App Map with the latest sessions.",
                side: "top",
                align: "start",
              },
            },
            {
              element: "#shitcher-appmap",
              popover: {
                title: "Toggle App Map Version",
                description:
                  "Click here to switch to a different version of the App Map.",
              },
            },
            {
              element: "#chat--bot",
              popover: {
                title: "Chatbox",
                description:
                  "Click here to open the chat window and learn more about us.",
              },
            },
            {
              element: "#key_flow_select",
              popover: {
                title: "Choose Key Flows ",
                description: "Select key flows from your most recent sessions.",
              },
            },
            {
              element: "#test--btn",
              popover: {
                title: "Incorporate Tests into Test Suite",
                description:
                  "Add sessions from key flows to the test suite for execution.",
              },
            },
            {
              element: "#model--btn",
              popover: {
                title: "Download Your Model ",
                description: "Access and download your DAI model",
              },
            },
          ],
        });
        driverObj.drive();
      } else if (this.showApi) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#convert-api",
              popover: {
                title: "API to Test",
                description: "Transform your AnPI here into a test format.",
              },
            },
            {
              element: "#key-btn",
              popover: {
                title: "Key Filter",
                description: "Refine your data using filtering options.",
              },
            },
            {
              element: "#value-btn",
              popover: {
                title: "Value Filter",
                description:
                  "Incorporate specific values to enhance the filtering process.",
              },
            },
            {
              element: "#btn-api--calls",
              popover: {
                title: "API Calls Log",
                description:
                  "View a comprehensive list of all the API calls you have made here.",
              },
            },
            {
              element: "#btn-edit--accordion",
              popover: {
                title: "API Details Log",
                description:
                  "Access a detailed overview of all the API details you have performed.",
              },
            },
            {
              element: "#btn-rerun--api",
              popover: {
                title: "Rerun API Calls",
                description:
                  "Retry or replay previously executed API calls from this section.",
              },
            },
          ],
        });
        driverObj.drive();
      } else if (this.showTestSuit) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#btn-schedule",
              popover: {
                title: "Schedule Test",
                description:
                  "Effortlessly plan and schedule a test for execution.",
                onNextClick: () => {
                  this.scheduledRun = true;
                  driverObj.moveNext();
                },
              },
            },
            {
              element: "#scheduled-run",
              popover: {
                title: "Scheduled Runs Overview",
                description:
                  "Access a comprehensive list of all scheduled runs from this section.",
              },
            },
            {
              element: "#btn-add--schedule",
              popover: {
                title: "Add Scheduled Test",
                description:
                  "Seamlessly add a test to the schedule for convenient execution.",
              },
            },
            {
              element: "#table--schedule",
              popover: {
                title: "Scheduled Test Runs Overview",
                description:
                  "Access a comprehensive list of all scheduled runs from this section.",
              },
              onDeselected: () => {
                this.scheduledRun = false;
              },
            },

            {
              element: "#btn-run--suite",
              popover: {
                title: "Run Test Suite",
                description: "Click here to execute a test suite.",
              },
            },
            {
              element: "#test-suite",
              popover: {
                title: "Test Suite Overview",
                description:
                  "Explore and manage your test suite from this section.",
              },
            },
            {
              element: "#listItemPageTableRerunTestBtn",
              popover: {
                title: "Add Test",
                description:
                  "Incorporate a new test into the test suite through manual entry.",
              },
            },
            {
              element: "#runs-test--suite",
              popover: {
                title: "Test Suite Runs",
                description:
                  "View a comprehensive list of all the runs associated with your test suite here.",
              },
            },
          ],
        });
        driverObj.drive();
      }
    },
    async handleCurrentChange(val, withCursor) {
      const old = this.from;

      this.from = (val - 1) * this.pagination.perPage;
      this.to = parseInt(this.from) + parseInt(this.pagination.perPage);

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [
            `${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`,
          ])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }

      if (withCursor) {
        this.loadingSession = true;
        // console.log("Current cursor: ", this.currentCursor)
        const isNext = old < this.from;
        const searchUrl = isNext
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

        console.log(
          `handleCurrentChange: final composed search URL: ${searchUrl}`
        );

        try {
          const res = await axios.get(searchUrl);
          if (res.status === 200) {
            this.items = Object.values(res.data.response);
            this.filteredResults = this.filteredResultSet;
            this.toShow = this.filteredResults;
            this.total = res.data.statistics.total_tests;
            this.oldCursor = this.currentCursor ?? undefined;
            this.currentCursor = res.data.cursor ?? undefined;
          } else {
            this.$notify({
              type: "danger",
              message: `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
            });
          }
        } catch (err) {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 404
          ) {
            this.$notify({
              type: "warning",
              message:
                "There are no more results for this filter and items-per-page combination",
            });
          } else {
            this.$notify({
              type: "danger",
              message:
                "Error while filtering, likely no more results for this filter and items-per-page combination.",
            });
          }
        }
        this.loadingSession = false;
      } else {
        this.toShow = [];
        this.toShow.push(this.filteredResults);
        this.keyTestFile = Object.keys(this.toShow[0].testSuiteFiles);
        this.fileAppAtt = Object.values(this.toShow[0].testSuiteFiles);
        console.log(this.fileAppAtt);
        console.log(this.toShow);
        this.fileAppAtt.length > 0
          ? (this.checked5 = true)
          : (this.checked5 = false);
        this.keyTest = Object.keys(this.toShow[0].testSuiteRuns);
        this.detailTest = Object.values(this.toShow[0].testSuiteRuns);
        this.detailTest.length > 0
          ? (this.checked6 = true)
          : (this.checked6 = false);
      }
    },
    async handleNavigation(target) {
      this.loadingTest = true;
      this.pagination.currentPage =
        target === "next"
          ? this.pagination.currentPage + 1
          : this.pagination.currentPage - 1;

      // if there's an active filter, make sure to add it to the URL
      let filterFragment = "";
      if (this.filteredElt !== undefined) {
        filterFragment = Object.keys(this.filteredElt)
          .map((e) => [
            `${e}~${this.filteredElt[e].operator}~${this.filteredElt[e].value}`,
          ])
          .join("&filter=");
      }
      if (filterFragment.length > 0 && !filterFragment.startsWith("&filter=")) {
        filterFragment = "&filter=" + filterFragment;
      }
      console.log(
        `handleNavigation: pagination target: ${target}, ` +
          `filters enabled = ${this.filteredElt !== undefined}, ` +
          `filter URL fragment = ${filterFragment}`
      );

      const searchUrl =
        target === "next"
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.currentCursor}&limit=${this.pagination.perPage}${filterFragment}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/query?cursor=${this.oldCursor}&limit=${this.pagination.perPage}${filterFragment}`;

      console.log(`handleNavigation: final composed search URL: ${searchUrl}`);

      try {
        const res = await axios.get(searchUrl);
        if (res.status === 200) {
          this.items = Object.values(res.data.response);
          this.filteredResults = this.filteredResultSet;
          this.toShow = this.filteredResults;
          this.from =
            (this.pagination.currentPage - 1) * this.pagination.perPage;
          this.to = parseInt(this.from) + parseInt(this.pagination.perPage);
          this.total = res.data.statistics.total_tests;
          this.oldCursor = res.data.back_cursor;
          this.currentCursor = res.data.cursor;
        } else {
          this.$notify({
            type: "danger",
            message: `Error while filtering: ${res.data.message}. Contact support if this error persists.`,
          });
        }
      } catch (err) {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 404
        ) {
          this.$notify({
            type: "warning",
            message:
              "There are no more results for this filter and items-per-page combination",
          });
        } else {
          this.$notify({
            type: "danger",
            message:
              "Error while filtering, likely no more results for this filter and items-per-page combination.",
          });
        }
      }
      this.loadingTest = false;
    },
    async initWebsocketStreaming() {
      const auth_token = localStorage.getItem("vue-authenticate.access_token");
      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/runstream/v1`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/runstream/v1`;
      }

      try {
        const socket = new WebSocket(url);
        socket.onopen = function (e) {
          socket.send(`AUTH ${auth_token}`);
        };
        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          if (Object.keys(response.events).length === 0) {
            return;
          } else {
            const events = response.events;
            const uniqueValues = [...new Set(Object.values(events))];
            for (event in uniqueValues) {
              if (
                uniqueValues[0] &&
                uniqueValues[0].eventType === "test run event"
              ) {
                this.pollSingleTest(uniqueValues[0]);
              }
            }
          }
        };
      } catch {
        console.log("error?");
      }
    },
    async getList() {
      this.loadingTest = true;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return (
          status === 400 || status === 404 || (status >= 200 && status < 500)
        );
      };
      if (
        !this.projectDetail.isSharedProject &&
        this.projectDetail.attributes &&
        this.projectDetail.attributes.testSuiteId
      ) {
        const testSuitRunId = this.projectDetail.attributes.testSuiteId;

        axios
          .get(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
          )
          .then(async (response) => {
            // this.items = Object.values(response.data.response);
            if (response.data.status == "success") {
              this.filteredResults = response.data.response;
              this.handleCurrentChange(this.pagination.currentPage, false);
              this.loadingTest = false;
            } else {
              this.filteredResults = [];
              this.handleCurrentChange(this.pagination.currentPage, false);
              this.loadingTest = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingTest = false;
            this.$notify({
              type: "danger",
              message: "Cannot load user Project... Try refresh" + error,
            });
          })
          .finally(() => {
            this.loadingTest = false;
          });
      } else if (
        this.projectDetail.isSharedProject &&
        this.projectDetail.isSharedProject == true
      ) {
        await this.shareSuiteTest();
        this.handleCurrentChange(this.pagination.currentPage, false);
        this.loadingTest = false;
      } else {
        let projectId = this.$route.params.id;
        let data = {
          testSuite: "new",
        };

        // check if the project contains mobile screenshots
        const resp = await axios.put(
          process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/user-projects/" +
            projectId,
          data
        );
        if (resp.data && resp.data.status === "success") {
          this.projectDetail = resp.data.response;
        }

        const testSuitRunId = this.projectDetail.attributes.testSuiteId;

        axios
          .get(
            `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
          )
          .then((response) => {
            // this.items = Object.values(response.data.response);
            this.filteredResults = response.data.response;
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              type: "danger",
              message: "Cannot load user Project... Try refresh" + error,
            });
          })
          .finally(() => {
            this.loadingTest = false;
          });
      }
    },
    async shareSuiteTest() {
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const res = await axios.get(
        `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/user`
      );
      console.log("hello 237", res.data.status);
      if ((res.data.status = "success")) {
        let allSuiteUser = Object.values(res.data.response);
        let index = allSuiteUser.findIndex(
          (item) =>
            item.description ===
              `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite` &&
            item.name ===
              `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite`
        );
        console.log("hello 237", index);
        if (index == -1) {
          await this.createSuiteTest();
        } else {
          await this.getDetailSuiteTest(allSuiteUser[index].suiteId);
        }
      } else if (
        res.message != "Request failed with status code 404" ||
        res.message === "Request failed with status code 500"
      ) {
        await this.createSuiteTest();
      } else {
        await this.createSuiteTest();
      }
    },
    async createSuiteTest() {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1`;
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };
      let formatDate = new FormData();
      formatDate.append(
        "suiteName",
        `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite`
      );
      formatDate.append(
        "suiteDesc",
        `Shared ${this.projectDetail.name} ${this.$route.params.id} Suite`
      );
      formatDate.append("projectName", this.projectDetail.name);
      formatDate.append("suiteFiles", "[]");
      const res = await axios.post(url, formatDate);
      if ((res.data.status = "success")) {
        await this.getDetailSuiteTest(res.data.response.suiteId);
      } else {
        this.$notify({
          type: "danger",
          message:
            "an error has occurred! Cannot load user Project... Try refresh",
        });
      }
    },
    async handleClickDelete(item) {
      console.log(item);
      this.$confirm("Are you sure to delete this?", "Warning", {
        customClass: "custom-messagebox",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
      })
        .then(async () => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?user_id=${this.userId.system_id}&app_id=${item.app_id}&session_name=${item.chat_name}`;
          try {
            axios.defaults.validateStatus = (status) => {
              // return status >= 200 && status < 401; // to catch 400 error
              return status === 400 || (status >= 200 && status < 500);
            };
            const res = await axios.delete(url);
            console.log(res);
            if (res.data.status === "success") {
              this.postedMessages = [];
              this.updateSessions = true;
              await this.getSessionsChat();
            }
          } catch (error) {
            console.log(error);
          } finally {
            if (this.updateSessions) await this.getSessionsChat();
          }
        })
        .catch(() => {});
    },
    async getDetailSuiteTest(testSuitRunId) {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
        )
        .then(async (response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            response.data.response.shared = true;
            this.projectDetail.attributes.testSuiteId =
              response.data.response.suiteId;
            this.projectDetail.attributes.testSuiteName =
              response.data.response.name;
            this.filteredResults = response.data.response;
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          } else {
            this.filteredResults = [];
            this.handleCurrentChange(this.pagination.currentPage, false);
            this.loadingTest = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message:
              "an error has occurred! Cannot load user Project... Try refresh",
          });
        })
        .finally(() => {
          this.loadingTest = false;
        });
    },
    async openReRunApi(el, index) {
      const confirmation = await swal.fire({
        title: "Modify request ?",
        text: "You had the option of modifying the request (method, url, request header, request body).",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Modify",
        cancelButtonText: "Run as is ",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
      });
      this.reRunUrl = el.url;
      this.methodReRun = el.method;
      this.showReRunId = el.apicall_uid;
      this.showReRunIndex = index + 1;
      this.requestHeaders = [];
      this.responseHeaders = [];
      this.requestBody = [];
      this.responseBody = [];
      this.metadata = [];

      Object.entries(el.request_headers).map((item) => {
        this.requestHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.request_body && Array.isArray(el.request_body)) {
        Object.entries(el.request_body).map((item) => {
          this.requestBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.requestBody = [];
      }
      Object.entries(el.response_headers).map((item) => {
        this.responseHeaders.push({ name: item[0], value: item[1] });
      });
      if (el.response_body && Array.isArray(el.response_body)) {
        Object.entries(el.response_body).map((item) => {
          this.responseBody.push({
            name: item[0],
            type: this.getType(item[1]),
            value: item[1],
          });
        });
      } else {
        this.responseBody = [];
      }
      if (el.metadata && Array.isArray(el.metadata)) {
        Object.entries(el.metadata).map((item) => {
          this.metadata.push({ name: item[0], value: item[1] });
        });
      } else {
        this.metadata = [];
      }
      if (confirmation.value === true) {
        this.editApi = false;
        this.showModalReRun = true;
        this.responseRequestModal = false;
        this.reRunEditData = true;
      } else {
        this.showReRunId = el.apicall_uid;
        this.reRunEditData = false;
        this.responseRequestModal = true;
        this.reRun(false);
      }
    },
    forStepNumberRunApi(val) {
      return (
        this.pagination.currentPage * this.pagination.perPage -
        this.pagination.perPage +
        (val + 1)
      );
    },
    openEditAccordion(id) {
      this.selectedItemId = this.selectedItemId !== id ? id : null;
    },
    copyStderr() {
      navigator.clipboard.writeText(this.stderr).then(() => {
        this.$notify({
          type: "success",
          message: "Copied Successfully.",
        });
      });
      this.errCopied = true;
    },
    openEditRunCucumber(row, id) {
      this.stderr = null;
      this.stdout = null;
      if (row.stdout) {
        for (let i = 0; i < row.stdout.length; i++) {
          console.log(row[i]); // Affiche "apple", "banana", "orange"
          this.stdout = this.stdout + row.stdout[i];
        }
      }
      if (row.stderr) {
        for (let i = 0; i < row.stderr.length; i++) {
          console.log(row[i]); // Affiche "apple", "banana", "orange"
          this.stderr = this.stderr + row.stderr[i];
        }
      }
      this.selectedCucumberId = this.selectedCucumberId !== id ? id : null;
    },
    openFixtureAccordion(elt, id) {
      this.toggleFixture = true;
      this.currentFixtureId = elt.id;
    },
    setByFilter() {
      this.filterByTime = true;
      if (this.customDate === false) {
        let today = new Date();
        let sevenDaysAgo = new Date(
          today.getTime() - this.selectTime * 24 * 60 * 60 * 1000
        );
        if (this.selectTime) {
          this.datePikerStart = moment(sevenDaysAgo).format("YYYY-MM-DD");
          this.datePikerEnd = moment(today).format("YYYY-MM-DD");
          this.getApiCall();
        }
      } else if (
        this.customDate === true &&
        this.datePikerStart.length > 0 &&
        this.datePikerEnd.length > 0
      ) {
        this.getApiCall();
      }
    },
    clearFilter() {
      this.filterByTime = false;
      this.selectTime = false;
      this.datePikerStart = "";
      this.datePikerEnd = "";
      this.checkedGet = false;
      this.checkedGetValue = 0;
      this.checkedPost = false;
      this.checkedPostValue = 0;
      this.checkedPut = false;
      this.checkedPutValue = 0;
      this.checkedDelete = false;
      this.checkedDeleteValue = 0;
      this.checkedPatch = false;
      this.checkedPatchValue = 0;
      this.statusArray = [
        { type: "200 ", value: 200, status: false, total: 0 },
        { type: "201 ", value: 201, status: false, total: 0 },
        { type: "204 ", value: 204, status: false, total: 0 },
        { type: "301", value: 301, status: false, total: 0 },
        { type: "302", value: 302, status: false, total: 0 },
        { type: "304", value: 304, status: false, total: 0 },
        { type: "400", value: 400, status: false, total: 0 },
        { type: "401", value: 401, status: false, total: 0 },
        { type: "403", value: 403, status: false, total: 0 },
        { type: "404", value: 404, status: false, total: 0 },
        { type: "500", value: 500, status: false, total: 0 },
        { type: "502", value: 502, status: false, total: 0 },
        { type: "503", value: 503, status: false, total: 0 },
      ];
      this.getApiCall();
    },
    getTagType(method) {
      if (method.toString().toLowerCase() == "get") {
        return "success";
      }
      if (method.toString().toLowerCase() == "post") {
        return "";
      }
      if (method.toString().toLowerCase() == "put") {
        return "warning";
      }
      if (method.toString().toLowerCase() == "delete") {
        return "danger";
      }
      if (method.toString().toLowerCase() == "patch") {
        return "info";
      }
    },
    getCodeType(method) {
      if (method >= 200 && method < 300) {
        return "success";
      }
      if (method >= 400 && method < 500) {
        return "warning";
      }
      if (method >= 300 && method < 400) {
        return "";
      }
      if (method >= 500) {
        return "danger";
      }
      if (method < 200) {
        return "";
      }
    },
    elementscan() {
      document.addEventListener("scroll", () => {
        let firstContent = document.getElementById("vtza_c_l");
        let firstContentPosition =
          firstContent.getBoundingClientRect().top + window.pageYOffset;
        let firstContentheight =
          firstContent.getBoundingClientRect().height + firstContentPosition;
        let scrollPosition = document.documentElement.scrollTop;
        console.log("yesssssss", firstContentPosition, firstContentheight);
        if (
          scrollPosition >= firstContentPosition &&
          scrollPosition < firstContentheight
        ) {
        }
      });
    },

    handleTagClose(el, type, projectID) {
      this.tagsSearch = this.tagsSearch
        .reduce((acc, obj) => {
          if (obj.id == projectID) {
            obj[type] = obj[type].filter((tag) => tag.filter != el);
          }
          acc.push(obj);
          return acc;
        }, [])
        .filter((project) => project.keys.length || project.values.length);

      localStorage.setItem("loadKeys", JSON.stringify(this.tagsSearch));
    },

    fillSearchTag() {
      // let hasKeys, hasValue, matches;
      const hasKeys =
        this.$refs["data-rendered-detail"] &&
        this.$refs["data-rendered-detail"].innerHTML.includes("key:")
          ? "keys"
          : null;
      const hasValue =
        this.$refs["data-rendered-detail"] &&
        this.$refs["data-rendered-detail"].innerHTML.includes("value:")
          ? "value"
          : null;
      const matches =
        this.$refs["data-rendered-detail"] &&
        this.$refs["data-rendered-detail"].innerHTML.match(
          /<span class="ml-1 u--tag">(.*?)<\/span>/
        );

      const add = (type, value) => {
        this.tagsSearch = this.tagsSearch.reduce((acc, obj) => {
          if (obj.id == this.$route.params.id) {
            obj[type] = [...obj[type], value].slice(-20);
          }
          acc.push(obj);
          return acc;
        }, []);
        localStorage.setItem(
          "loadKeys",
          JSON.stringify(this.tagsSearch.slice(-20))
        );
      };

      const projectExist = this.tagsSearch.find(
        (project) => project.id == this.projectDetail.projectId
      );

      if (!projectExist) {
        this.tagsSearch.push({
          name: this.projectDetail.name,
          id: this.projectDetail.projectId,
          keys: [],
          values: [],
        });
      }

      if (matches) {
        add(
          "keys",
          this.isTotalCountRes
            ? {
                filter: ` ${matches[1]} => ${this.ultraIpt}`,
                totalCallCount: this.totalCallCount,
              }
            : ` ${matches[1]} => ${this.ultraIpt}`
        );
        return false;
      }

      if (hasKeys) {
        add(
          "keys",
          this.isTotalCountRes
            ? { filter: this.ultraIpt, totalCallCount: this.totalCallCount }
            : this.ultraIpt
        );
        return true;
      }

      if (hasValue) {
        add(
          "values",
          this.isTotalCountRes
            ? { filter: this.ultraIpt, totalCallCount: this.totalCallCount }
            : this.ultraIpt
        );
        return true;
      }
      this.isTotalCountRes = false;
    },

    parseJavascript() {
      const parser = (value) => {
        const elements =
          value == "keys"
            ? document.getElementsByClassName("vjs-key")
            : value == "value"
            ? document.getElementsByClassName("vjs-value")
            : null;

        if (!elements) return false;

        for (var i = 0; i < elements.length; i++) {
          const node = elements[i];

          if (node.textContent.includes(this.ultraIpt)) {
            const strongElement = document.createElement("strong");
            strongElement.textContent = node.textContent;
            strongElement.style.color = "red";
            node.textContent = "";
            node.appendChild(strongElement);
          }
        }
      };

      const hasKeys = this.$refs["data-rendered-detail"]?.innerHTML?.includes(
        "key:"
      )
        ? "keys"
        : null;
      const hasValue = this.$refs["data-rendered-detail"]?.innerHTML?.includes(
        "value:"
      )
        ? "value"
        : null;

      if (hasKeys) {
        parser("keys");
        return true;
      }

      if (hasValue) {
        parser("value");
        return true;
      }
    },

    getLocalStorageKeys() {
      const storage = localStorage.getItem("loadKeys");

      if (!storage) return false;

      const json = JSON.parse(storage);

      if (Array.isArray(json)) {
        this.tagsSearch = json
          .reduce((acc, obj) => {
            if (
              "name" in obj &&
              "id" in obj &&
              (("keys" in obj &&
                Array.isArray(obj["keys"]) &&
                obj["keys"].length) ||
                ("values" in obj &&
                  Array.isArray(obj["values"]) &&
                  obj["values"].length))
            )
              acc.push(obj);

            return acc;
          }, [])
          .filter((project) => project.keys.length || project.values.length);
      }
    },

    getJsonData(el) {
      let dat = el;
      delete dat.session_uid;
      delete dat.testrun_requestid;
      delete dat.pageviews_project_id;
      this.parseJavascript();
      return dat;
    },

    setFilterApiCall() {
      let filter = `&page=${this.pagination.currentPage}&count=${this.pagination.perPage}`;
      if (
        this.filterByTime === true &&
        this.datePikerStart.length > 0 &&
        this.datePikerEnd.length > 0
      ) {
        filter = `${filter}&startDate=${this.datePikerStart}&endDate=${this.datePikerEnd}`;
      }
      if (this.checkedGet === true) {
        filter = `${filter}&method=get`;
      }
      if (this.checkedPost === true) {
        filter = `${filter}&method=post`;
      }
      if (this.checkedPut === true) {
        filter = `${filter}&method=put`;
      }
      if (this.checkedDelete === true) {
        filter = `${filter}&method=delete`;
      }
      if (this.checkedPatch === true) {
        filter = `${filter}&method=patch`;
      }

      if (this.filterByHeaderSatus == true) {
        if (this.filterByHeader.key)
          filter = `${filter}&response_header_keys=${JSON.stringify(
            this.filterByHeader.key
          )}`;

        if (this.filterByHeader.value)
          filter = `${filter}&response_header_values=${JSON.stringify(
            this.filterByHeader.value
          )}`;
      }

      if (this.filterByAllSatus == true) {
        this.filterByAll.map((item) => {
          filter = `${filter}&method=${item}`;
        });
      }

      for (let i = 0; i < this.statusArray.length; i++) {
        if (this.statusArray[i].status === true) {
          filter = `${filter}&status_code=${this.statusArray[i].value}`;
        }
      }
      return filter;
    },
    async handleShowMessage(project) {
      this.apiCallsForConvert = {};
      console.log("click app", project);
      this.sessionIdChat = project.chat_name;
      this.postedMessages = project.message;
      this.sessionId = project.chat_name;
    },
    async getApiCall() {
      this.loading = true;
      this.error = [];
      this.checkedDeleteValue = 0;
      this.checkedPostValue = 0;
      this.checkedGetValue = 0;
      this.checkedPutValue = 0;
      this.checkedPatchValue = 0;
      let filter = this.setFilterApiCall();
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/apicallsdata/v1?pageviewsProjectId=${this.$route.params.id}${filter}`;
      try {
        axios.defaults.validateStatus = (status) => {
          return (
            status === 400 ||
            status === 500 ||
            status === 500 ||
            (status >= 200 && status < 500)
          );
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.apicalls = res.data.response.apicalls;
          if (res.data.response.apicallStatistics.total_post_method)
            this.sessionsDetail = res.data.response.apicallStatistics;
          this.totalCallCount = res.data.response.totalCallCount;
          this.pagination.currentPage = res.data.response.currentPage;
          this.pagination.perPage = res.data.response.perPageCount;
          this.loading = false;
          this.checkedDeleteValue = this.valueonk(
            res.data.response.apicallStatistics.total_delete_method
          );
          this.checkedPostValue = this.valueonk(
            res.data.response.apicallStatistics.total_post_method
          );
          this.checkedGetValue = this.valueonk(
            res.data.response.apicallStatistics.total_get_method
          );
          this.checkedPutValue = this.valueonk(
            res.data.response.apicallStatistics.total_put_method
          );
          this.checkedPatchValue = this.valueonk(
            res.data.response.apicallStatistics.total_patch_method
          );
        } else {
          this.apicalls = [];
          this.totalCallCount = 0;
          this.error.push(res.data.message);
          this.loading = false;
        }
      } catch (error) {
        this.totalCallCount = 0;
        console.log(`an error has occurred : ${error}`);
        this.apicalls = [];
        this.loading = false;
      } finally {
        this.filteredResultApi = this.filteredResultApiSet;
        this.initcheckedApi();
        this.isTotalCountRes = true;
        this.fillSearchTag();
        this.loading = false;
        this.setAllMethode();
      }
    },
    initcheckedApi() {
      if (this.filteredResultApi.length > 0) {
        for (let i = 0; i < this.filteredResultApi.length; i++) {
          this.checkedApi[this.filteredResultApi[i].apicall_uid] = {
            id: this.filteredResultApi[i].apicall_uid,
            value: false,
          };
        }
      }
    },
    handleCheckAllChange(id, value) {
      this.checkedApi[id].value = !value;
      if (value == false) {
        this.countDisabled += 1;
      } else {
        this.countDisabled -= 1;
      }
      if (this.countDisabled > 0) {
        this.enableConverButton = true;
      } else {
        this.enableConverButton = false;
      }
    },
    async runApiTest() {
      let apiId = Object.values(this.checkedApi).filter(
        (item) => item.value == true
      );
      let finalyApiId = apiId.map((item) => {
        return item.id;
      });
      if (!finalyApiId || finalyApiId.length == 0) {
        this.$notify({
          type: "danger",
          message: "no api select",
        });
        return;
      }
      let datajson = [];
      for (let i = 0; i < this.filteredResultApi.length; i++) {
        const element = this.filteredResultApi[i];
        if (finalyApiId.includes(this.filteredResultApi[i].apicall_uid)) {
          datajson.push(this.filteredResultApi[i]);
        }
      }
      const runName = await swal.fire({
        title: "Test Run Name",
        input: "text",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Run",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        inputPlaceholder: "Run Name",
        preConfirm: (value) => {
          if (value == "") {
            swal.showValidationMessage("empty name ");
          }
          return value;
        },
      });

      if (runName.value && runName.value.length > 1) {
        let projectId = this.$route.params.id;
        let data = {
          listApi: datajson,
          apiId: finalyApiId,
          project_id: projectId,
          name: runName.value,
          run_tags: ["integration enabled"],
        };

        const resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX + "/apicalls/v1/replay/suit",
          data,
          { headers: { "Content-Type": "application/json" } }
        );
        if (
          resp.data.status ||
          resp.data.status == "success" ||
          resp.data.status == "queued" ||
          resp.data.status == "processing"
        ) {
          this.$notify({
            type: "success",
            message: runName.value + " added successfully.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: runName.value + " was not added successfully. Details: ",
          });
        }
      }
    },
    valueonk(el) {
      if (el && el >= 1000 && el < 10000) {
        return `${el.toString()[0]}.${el.toString()[1]}K`;
      } else if (el && el >= 10000 && el < 100000) {
        return `${el.toString()[0]}${el.toString()[1]}.${el.toString()[2]}K`;
      } else if (el && el >= 100000 && el < 1000000) {
        return `${el.toString()[0]}${el.toString()[1]}${el.toString()[2]}.${
          el.toString()[3]
        }K`;
      } else if (el && el >= 1000000) {
        return `${el.toString().slice(0, el.toString().length - 6)}M`;
      } else {
        if (el) {
          return `${el.toString()}`;
        } else {
          return "0";
        }
      }
    },
    setAllMethode() {
      this.statusArray.map((item) => {
        item.total = 0;
      });
      this.apicalls.map((item) => {
        this.statusArray.map((val) => {
          if (item.status_code == val.value) {
            val.total++;
          }
        });
      });
    },
    selectStatus(id) {
      this.statusArray.map((item) => {
        if (this.statusArray[id].value !== item.value) {
          item.status = false;
        } else {
          this.statusArray[id].status = true;
        }
      });

      console.log("ID", this.statusArray[id].status);
      this.getApiCall();
    },
    addNewColumnRequestHeaders(el) {
      if (el === "response") {
        this.responseHeaders.push({ name: "", value: "" });
      } else {
        this.requestHeaders.push({ name: "", value: "" });
      }
    },
    deleteColumnRequestHeaders(index, el) {
      if (el === "response") {
        if (this.responseHeaders.length == 1) {
          if (this.editApi == false) return;
          this.responseHeaders = [];
        } else {
          this.responseHeaders.splice(index, 1);
        }
      } else {
        if (this.requestHeaders.length == 1) {
          if (this.editApi == false) return;
          this.requestHeaders = [];
        } else {
          this.requestHeaders.splice(index, 1);
        }
      }
    },
    addNewColumnRequestBody(el) {
      if (el === "response") {
        this.responseBody.push({ name: "", type: "text", value: "" });
      } else if (el === "metadata") {
        this.metadata.push({ name: "", value: "" });
      } else {
        this.requestBody.push({ name: "", type: "text", value: "" });
      }
    },
    deleteColumnRequestBody(index, el) {
      if (el === "response") {
        if (this.responseBody.length == 1) {
          this.responseBody = [];
        } else {
          this.responseBody.splice(index, 1);
        }
      } else if (el === "metadata") {
        if (this.metadata.length == 1) {
          this.metadata = [];
        } else {
          this.metadata.splice(index, 1);
        }
      } else {
        if (this.requestBody.length == 1) {
          this.requestBody = [];
        } else {
          this.requestBody.splice(index, 1);
        }
      }
    },
    getType(el) {
      let type = typeof el;
      if (type === "string") return "text";
      return type;
    },
    async reRun(type) {
      let apiCallId = this.showReRunId;
      this.error = [];
      this.loadingRequest = true;
      let data = {};
      if (this.reRunUrl != "" && this.reRunUrl.length > 0) {
        data.url = this.reRunUrl;
      }
      if (this.requestTimeout > 0.1) {
        data.requestTimeout = this.requestTimeout;
      }
      if (this.connectionTimeout > 0.1) {
        data.connectionTimeout = this.connectionTimeout;
      }
      if (this.methodReRun != "" && this.methodReRun.length > 0) {
        data.method = this.methodReRun;
      }
      if (this.requestHeaders.length > 0) {
        let finalyHeader = {};
        this.requestHeaders.map((item) => {
          if (item.name != "") {
            finalyHeader[item.name] = item.value;
          }
        });
        data.requestHeaders = finalyHeader;
      } else {
        data.requestHeaders = null;
      }
      if (this.requestBody.length > 0) {
        let finalyBody = {};
        this.requestBody.map((item) => {
          if (item.type === "number" && item.name != "")
            finalyBody[item.name] = Number(item.value);
          if (item.type === "array" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "object" && item.name != "")
            finalyBody[item.name] = item.value;
          if (item.type === "text" && item.name != "")
            finalyBody[item.name] = item.value;
        });
        data.requestBody = finalyBody;
      } else {
        data.requestBody = null;
      }
      axios.defaults.validateStatus = (status) => {
        return (
          status === 400 || status === 500 || (status >= 200 && status < 300)
        );
      };
      let resp = null;
      if (this.reRunEditData == true) {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
      } else {
        resp = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX +
            "/apicalls/v1/replay/" +
            apiCallId
        );
      }
      if (resp.data.status === "success") {
        this.responseRequest = resp.data.response;
        delete this.responseRequest.apicall_uid;
        delete this.responseRequest.session_uid;
        delete this.responseRequest.testrun_requestid;
        delete this.responseRequest.updated_at;
        delete this.responseRequest.created_at;
        delete this.responseRequest.pageviews_project_id;
        this.loadingRequest = false;
        this.responseRequestModal = true;
        this.showModalReRun = false;
      } else if (resp.data.status === "failed") {
        if (resp.data.response) {
          this.responseRequest = resp.data.response;
          delete this.responseRequest.apicall_uid;
          delete this.responseRequest.session_uid;
          delete this.responseRequest.testrun_requestid;
          delete this.responseRequest.updated_at;
          delete this.responseRequest.created_at;
          delete this.responseRequest.pageviews_project_id;
          this.responseRequestModal = true;
          this.showModalReRun = false;
          this.loadingRequest = false;
        } else {
          this.error.push(resp.data.message);
          this.$notify({
            type: "danger",
            message: `an error has occurred! (${resp.data.message})`,
          });
          this.responseRequest = [];
          this.loadingRequest = false;
        }
      } else {
        this.responseRequest = [];
        this.loadingRequest = false;
      }
    },
    helpFilterStatus(item) {
      let val = true;
      for (let i = 0; i < this.statusArray.length; i++) {
        if (
          this.statusArray[i].status === true &&
          item.status_code < this.statusArray[i].value &&
          item.status_code >= this.statusArray[i].value - 99
        ) {
          val = true;
          break;
        } else if (
          this.statusArray[i].status === true &&
          this.statusArray[i].value - item.status_code > 99
        ) {
          val = false;
        }
      }
      return val;
    },

    helpFilterAll(item) {
      let val = false;
      for (let i = 0; i < this.filterByAll.length; i++) {
        if (
          item.url.toLowerCase().includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.originating_page_url
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.method.toLowerCase().includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.status.toLowerCase().includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.status_code
            .toString()
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.response_type
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          item.response_time
            .toString()
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.request_headers === "object" &&
          JSON.stringify(item.request_headers)
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.request_headers === "string" &&
          item.request_headers
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.response_headers === "object" &&
          JSON.stringify(item.response_headers)
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.response_headers === "string" &&
          item.response_headers
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.request_body === "object" &&
          JSON.stringify(item.request_body)
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.request_body === "string" &&
          item.request_body
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.response_body === "object" &&
          JSON.stringify(item.response_body)
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.response_body === "string" &&
          item.response_body
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.metadata === "object" &&
          JSON.stringify(item.metadata)
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        } else if (
          typeof item.metadata === "string" &&
          item.metadata
            .toLowerCase()
            .includes(this.filterByAll[i].toLowerCase())
        ) {
          val = true;
        }
      }
      return val;
    },
  },
  computed: {
    sortedToShow() {
      let sortedList = this.filteredResultsSessionAPI.slice();
      if (this.showTestSuit) {
        sortedList = this.detailTest.slice();
      } else if (this.showApi) {
        sortedList = this.filteredResultApi.slice();
      }
      if (sortedList.length > 0) {
        if (
          [
            "recording_duration",
            "start_time",
            "acceptedAt",
            "response_time",
            "created_at",
            "status_code",
          ].includes(this.sortedBy)
        ) {
          //for numbers
          sortedList.sort((a, b) => {
            return a[this.sortedBy] - b[this.sortedBy];
          });
        } else if (this.sortedBy == "tags") {
          //for lists
          sortedList.sort(
            (a, b) => a[this.sortedBy].length - b[this.sortedBy].length
          );
        } else {
          //for strings
          if (this.showTestSuit || this.showApi) {
            sortedList.sort((a, b) =>
              a[this.sortedBy].localeCompare(b[this.sortedBy])
            );
          } else {
            sortedList.sort((a, b) =>
              a.person.properties[this.sortedBy].localeCompare(
                b.person.properties[this.sortedBy]
              )
            );
          }
        }
        if (this.isReversed[this.sortedBy]) return sortedList.reverse();
        return sortedList;
      } else {
        console.log("No data");
      }
      return 0;
    },
    widthRrplayerCom() {
      const el = document.getElementById("container-rrweb");
      console.log("jkbdkjsbnbshmnb", (el.clientWidth / 100) * 80);
      return (el.clientWidth / 112) * 86;
    },
    disabledDate(date) {
      let esl = this.datePikerStart;
      return {
        disabledDate(time) {
          return time.getTime() < new Date(esl) || time.getTime() > Date.now();
        },
      };
    },
    disabledDateStart(date) {
      let esl = this.datePikerEnd;
      return {
        disabledDate(time) {
          return time.getTime() > new Date(esl) || time.getTime() > Date.now();
        },
      };
    },
    filteredResultApiSet() {
      const seq = this.apicalls;
      let methode = [];
      if (seq && Array.isArray(seq)) {
        methode = seq.filter((item) => {
          if (this.checkedPost && item.method.toLowerCase().includes("post"))
            return item;
          else if (this.checkedGet && item.method.toLowerCase().includes("get"))
            return item;
          else if (this.checkedPut && item.method.toLowerCase().includes("put"))
            return item;
          else if (
            this.checkedDelete &&
            item.method.toLowerCase().includes("put")
          )
            return item;
          else if (
            this.checkedPatch &&
            item.method.toLowerCase().includes("patch")
          )
            return item;
          else if (
            !this.checkedPatch &&
            !this.checkedPost &&
            !this.checkedGet &&
            !this.checkedPut &&
            !this.checkedDelete
          )
            return item;
        });
        // methode = methode.filter((item) => {
        //   if(this.helpFilterStatus(item)){
        //     return item
        //   }
        // })
        if (this.filterByAllSatus == true) {
          methode = methode.filter((item) => {
            if (this.helpFilterAll(item)) {
              return item;
            }
          });
        }
        if (this.filterBySimpleSatus === true) {
          methode = methode.filter((item) => {
            if (
              item.url.toLowerCase().includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.originating_page_url
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.method
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.status
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.status_code
                .toString()
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.response_type
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              item.response_time
                .toString()
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.request_headers === "object" &&
              JSON.stringify(item.request_headers)
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.request_headers === "string" &&
              item.request_headers
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.response_headers === "object" &&
              JSON.stringify(item.response_headers)
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.response_headers === "string" &&
              item.response_headers
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.request_body === "object" &&
              JSON.stringify(item.request_body)
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.request_body === "string" &&
              item.request_body
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.response_body === "object" &&
              JSON.stringify(item.response_body)
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.response_body === "string" &&
              item.response_body
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.metadata === "object" &&
              JSON.stringify(item.metadata)
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            } else if (
              typeof item.metadata === "string" &&
              item.metadata
                .toLowerCase()
                .includes(this.filterBySimple.toLowerCase())
            ) {
              return item;
            }
          });
        }
      } else {
        return [];
      }
      return methode;
    },
    filteredResultSetTest() {
      return this.items.map((item) => {
        if (item.testSuiteId) {
          item.runName = `Test Suite - ${item.testSuiteName} | ${item.runName}`;
          item.runDesc = item.testSuiteRunDesc;
          return item;
        } else {
          return item;
        }
      });
    },
    filteredResultSet() {
      const seq = this.result;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.query) {
              return item.person.properties.$geoip_country_name
                .toLowerCase()
                .includes(this.query.toLowerCase());
            } else {
              return item;
            }
          })
        : [];
    },

    filteredResultSessionSet() {
      const seq = this.resultSession;

      if (this.eventSessionType === "mobile") {
        return seq && Array.isArray(seq)
          ? seq.filter((item) => {
              if (!this.querySession) {
                return item;
              }
            })
          : [];
      } else {
        return seq && Array.isArray(seq)
          ? seq.filter((item) => {
              if (this.querySession) {
                if (
                  item.person.properties.$geoip_country_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$geoip_country_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$geoip_continent_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$geoip_continent_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase())
                ) {
                  return item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_current_url.toLowerCase()
                ) {
                  return item.person.properties.$initial_current_url
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.app_name.toLowerCase()) {
                  return item.app_name
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.operating_system.toLowerCase()) {
                  return item.operating_system
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (item.person.properties.$initial_os.toLowerCase()) {
                  return item.person.properties.$initial_os
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  item.person.properties.$initial_browse.toLowerCase()
                ) {
                  return item.person.properties.$initial_browser
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (this.formatDate(item.start_time).toLowerCase()) {
                  return this.formatDate(item.start_time)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  this.formatDate(item.session_created_at).toLowerCase()
                ) {
                  return this.formatDate(item.session_created_at)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                } else if (
                  this.secondsToRealTime(item.recording_duration).toLowerCase()
                ) {
                  return this.secondsToRealTime(item.recording_duration)
                    .toLowerCase()
                    .includes(this.querySession.toLowerCase());
                }
              } else {
                return item;
              }
            })
          : [];
      }
    },
    filteredResultSessionEventSet() {
      const seq = this.resultEvent;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.querySessionEvent) {
              if (
                item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              } else if (
                item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              } else if (
                item.properties.$host
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.properties.$host
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultCoveringtestsSet() {
      const seq = this.resultCoveringtests;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.queryCoveringtests.length > 0) {
              if (
                item.name
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase())
              ) {
                return item.name
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase());
              } else if (
                item.url
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase())
              ) {
                return item.url
                  .toLowerCase()
                  .includes(this.queryCoveringtests.toLowerCase());
              }
            } else if (item.tests[0]) {
              return item;
            }
          })
        : [];
    },
    filteredList() {
      return this.filteredResults.slice(this.from, this.to);
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    pagesSession() {
      return Math.ceil(
        this.filteredResultsSession.length / this.paginationSession.perPage
      );
    },
    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
    canAddPreviousSession() {
      if (this.paginationSession.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    querySession: {
      handler: "initializeSessionState",
      immediate: true,
    },
    // text_from_chat(newText) {
    //   console.log('text_from_chat updated:', newText); // Debugging log

    //   this.startTypingEffect(newText);
    // },
    querySessionEvent: {
      handler: "initializeSessionEvent",
      immediate: true,
    },
    paginationSession: {
      handler: "initializeSessionState",
      immediate: false,
      deep: true,
    },
    queryCoveringtests: {
      handler: "initializeCoveringtestsState",
      immediate: true,
    },
    "$refs.container_rrweb.clientWidth": {
      handler: "initializewidthRrplaye",
      immediate: false,
      deep: true,
    },
    currentTime: {
      //variable name
      handler: "initializeSessionStateKeyFlow", //function name
      immediate: true,
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.setSSDimLoad);
    this.ro.observe(this.$refs.heatmap);
    // Start the effect immediately on mount if text is present
    // if (this.text_from_chat) {
    //   this.startTypingEffect(this.text_from_chat);

    // }
    // this.$refs.ss_img.addEventListener('load', this.showHeatMap);
  },
};
</script>

<style land="scss" scoped>
.right-chat--side {
  padding-left: 20px;
  padding-right: 10px;
  width: 80%;
  height: 85%;
  overflow-y: hidden;
}
</style>

<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;
@import "../../../assets/sass/custom/_variablesCustom";
.not-show-filter {
  display: none !important;
}
.keyflow-video-modal .replayer-wrapper {
  transform: scale(0.55) translate(-50%, -50%) !important;
}
.container-modal--gen {
  div {
    .modal-dialog {
      max-width: 75vw !important;
    }
    .edit-message-body {
      position: relative;
      height: 385px;
      .el-input__inner:nth-child(1) {
        width: 35%;
      }
      .el-input__inner {
        height: 100%;
      }
    }
  }
}
.container-script--upload {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
}
.uploader-spacer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 45vh;
}
.avatar-uploader {
  position: relative;
  .el-upload-list {
    position: absolute;
    top: 94%;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .el-upload-dragger {
    width: 178px;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.container-prompt {
  position: absolute;
  bottom: 0px;
  border-radius: 10px;
  width: 298px;
  background: inherit;
  .btn-option--prompt {
    border: none;
    background: inherit;
    cursor: pointer;
    display: grid;
    font-size: 8px;
    transition: 0.3s;
    font-weight: 400;
  }
  .btn-option--prompt:hover {
    color: blue;
  }
}
.keyflow-video-input input {
  width: 100% !important;
}
.keyflow-video-modal .modal-dialog {
  max-width: 100% !important;
  position: static;
}
.keyflow-video-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.decs-glean-text {
  max-width: 288px;
  word-wrap: break-word;
  color: var(--main-blue-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.icon-pad .el-input__suffix {
  margin-right: 4px;
}
.el-collapse-item__header {
  background-color: #dbdbdb;
  border-bottom: 1px solid #a8a8a8;
}

.clear_btn {
  background-color: #c9c9c9 !important;
  border-color: #c9c9c9 !important;
  color: var(--main-blue-color) !important;
}
.clear_btns {
  margin-top: 10px;
  margin-right: 7px;
  gap: 14px;
  display: flex;
  align-items: end;
}
.filter-body {
  padding: 0 0 15px 10px;
  display: flex;
  background-color: #dbdbdb;
  border-radius: 10px;
}
.filter-section {
  background-color: #dbdbdb;
  border-radius: 10px;
  width: 100%;
  height: auto;
}
.no-input-pad input {
  padding-left: 20px !important;
}
.el-collapse-item__header {
  height: 24px;
}
.header-filter {
  padding-top: 18px;
  padding-bottom: 8px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
}
.buttons-uplaoding {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.popup-download {
  left: 80%;
}
.el-alert--success.is-light {
  gap: 2px;
  background-color: #3a9679;
  color: #e6e6e6;
}
.el-tag--small {
  text-align: center;
  width: 90px;
  height: 30px;
  padding-top: 3px;
}
.text-convert {
  margin-top: 5px;
}
.convert-apicall-section {
  margin-left: 18px;
  display: flex;
  gap: 22px;
  align-items: center;
  text-align: center;
}
.el-checkbox__input.is-checked .el-checkbox__inner::after {
  border-color: var(--main-blue-color);
}
.text-card-tile-footer {
  margin-top: 15px;
  color: var(--main-blue-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dark-inverted {
  color: var(--main-blue-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.dark-inverted i {
  color: var(--main-blue-color) !important;
}
.custom-card-header {
  justify-content: start !important;
  gap: 12px;
}
.el-input input {
  height: 40px;
  width: 330px;
}
.search_section {
  margin-bottom: 16px;
  display: flex;
  align-items: start;
}
.column_custom {
  gap: 10px;
  margin-left: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.custom_btns {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  margin: 10px 10px 0px 0px;
  gap: 28px;
  display: flex;
}
.rr-player__frame {
  user-select: text;
  width: 100% !important;
  height: 90% !important;
}
.menu-text {
  margin: 0;
  display: inline;
  line-height: 0.7;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
}
select {
  cursor: pointer;
  padding: 5px;
  color: var(--main-blue-color);
  border: 1px solid #d6d6d6;
  position: absolute;

  border-radius: 5px;
  background-color: #d6d6d6;
}
.section {
  align-items: center;
  display: flex;
  gap: 13px;
}
.chat-header {
  margin-bottom: 10px;
}
.section h3 {
  text-align: left;
  padding-top: 5px;
  white-space: nowrap;
  width: 100px;
  display: block;
}
.edit-message-body {
  display: flex;
  gap: 10px;
}
.edit-message-body > textarea {
  width: 100%;
  min-height: 300px;
}
.body-second {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.edit-message-footer {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.pt-modal .modal-body {
  padding-top: 24px !important;
}
.test-info-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
select option {
  background-color: #5603ad;
  cursor: pointer;
}

.label-request {
  display: flex;
  gap: 2;
  justify-content: space-between;
  width: 100%;

  label {
    width: 50%;
    text-align: start;
  }
}
.el-card.is-always-shadow {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);
}
.rotate-icon {
  transform: rotate(180deg);
  padding-top: 0.5px !important;
}
.el-radio.is-bordered.is-checked {
  border-color: #d6d6d6;
  background-color: #d6d6d6;
}
.el-radio__input.is-checked + .el-radio__label {
  color: var(--main-blue-color);
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: var(--main-blue-color);
  background-color: var(--main-blue-color);
}
.el-collapse-item__wrap {
  background: #e6e6e6;
}
.desc-header {
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
  padding-bottom: 20px;
}
// .switch {

//   display: inline-block;
//   z-index: 1;
//   width: 60px;
//   height: 34px;
//   left: 340px;
//   top: 105px;
// }
.custom_btn_video {
  color: var(--main-blue-color);
  background: #dbdbdb;
  padding: 6px !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 33px;
  border-color: #dbdbdb;
}
.custom_btn_video:focus {
  background-color: #dbdbdb !important;
  color: #dbdbdb !important;
}
.switcher {
  text-align: center;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: #d6d6d6;
  cursor: pointer;
  display: inline-block;
  z-index: 1;
  width: 40px;
  height: 40px;
  transition: 0.3s;
}
.btn-close-fullscreen {
  border: none;
  background: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  z-index: 100;
}

// .switcher:hover {
//   width: 48px;
//   height: 48px;
// }

.switch input {
  display: none;
}

.body--message {
  white-space: pre-wrap; /* Preserves line breaks and spaces */
  word-wrap: break-word; /* Allows text to wrap within the container */
  overflow-wrap: break-word; /* Ensures long words break to fit the container */
  font-size: 16px; /* Adjust text size as needed */
  line-height: 1.5; /* Improves readability with proper line spacing */
  padding: 10px; /* Adds spacing around text */
  color: #333; /* Sets text color for readability */
}

// .streaming {
//     display: block; /* Ensures line breaks work as expected */
//     white-space: pre-wrap; /* Preserves whitespace and adds line breaks */
//     word-wrap: break-word; /* Allows long words to break */
//     font-family: 'Courier New', Courier, monospace; /* Optional: gives a typewriter look */
//     font-size: 16px; /* Adjust size as needed */
//     line-height: 1.5; /* Improves readability */
//     padding: 10px; /* Adds spacing around text */
//     border-radius: 5px; /* Softens the edges */
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds slight shadow for depth */
//     overflow-wrap: anywhere; /* Breaks long strings */
// }

// .typing-effect {
//     animation: typing 0.05s steps(1) forwards; /* Creates a typing effect */
// }

// @keyframes typing {
//     from { width: 0; }
//     to { width: 100%; }
// }

.row-btns {
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #efefef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.custom-tbl-overflow .tbl--body {
  height: 69vh;
  overflow: scroll;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: var(--main-blue-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #efefef;
}

input:focus + .slider {
  box-shadow: 0 0 1px #dbdbdb;
}
// streaming {
//     font-family: monospace;
//     white-space: pre;
// }
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(27px);
}
.section-flex {
  display: flex;
  justify-content: space-between;
}
.sheduel-btns {
  display: flex;
  gap: 5px;
}
.btn-sheduel {
  background-color: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  border-radius: 50px;
  color: #e6e6e6;
  padding: 5px 15px 5px 15px;
  font-size: 16px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.custom-keyflow-select .el-input__suffix,
.custom-select--date .el-input__suffix {
  margin-right: 12px;
}
#date_map_select,
#key_flow_select {
  padding-left: 12px;
}
.slider.round:before {
  border-radius: 50%;
}

.el-card__header {
  padding-bottom: 7px;
}
.el-alert--error.is-light {
  background-color: #d86f6f;
  color: #e6e6e6;
}
.suit-test-Download-modal .modal-dialog {
  max-width: 200px !important;
}

.filter-by {
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.15rem 0.35rem;
  // border: solid 0.1px rgba(231 162 60);
  background: rgba(231, 162, 60, 0.75);
  border-radius: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.el-key-value {
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
  color: $pColor;
  text-transform: capitalize;
}

.elt-row {
  text-overflow: clip;
}

.disable-file {
  opacity: 1;
}

.bpsd_dtb {
  width: 100%;
}

.is-flex {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
}

.list-group {
  width: 100%;
}
.cards {
  margin-top: 20px;
}
.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select-data {
  height: 35px !important;
  width: 70px !important;
}
.select-data input {
  background-color: #cccccc !important;
}
.select-data span {
  display: flex;
  align-items: center;
}
.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 999999;

  // div {
  //   z-index: 99999;
  // }

  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.truncate .el-collapse-item__content {
  background-color: #dbdbdb;
}
.grid_selection input,
.grid_selection_custom input,
#email_address,
.default_input input,
#run_name,
.el-select__tags {
  height: 40px;
  padding-left: 22px !important;
}
.run-name input {
  border: 1px solid #dbdbdb;
  background-color: #dbdbdb;
  padding: 12px;
  color: #6e6e6e;
  border-color: #dbdbdb !important;
}

// .label-choose {
//   color: white;
// }

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  font-size: 13px;
}
.el-alert__content {
  padding: 0 0px;
}
.updating-modal {
  position: absolute;
  width: 85%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 99999;
  .el-loading-mask .el-loading-spinner {
    top: 44%;
  }
}
.glean-box {
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 37px;
}
.glean-container-start-page {
  border-radius: 10px;
  background-color: var(--main-blue-color);
  height: 50px;
  width: 170px;
  display: grid;
  grid-template-columns: 50px 40px 40px 40px 40px;
}
.decs-glean-tex {
  max-width: 290px;
  color: var(--main-blue-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.glean-container-start-page div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.last-glean-container::after {
  content: "x";
  position: relative;
  top: -20px;
  right: -5px;
  font-weight: 400;
  font-size: 12px;
  background: var(--main-blue-color);
  color: #ffffff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid #ffffff;
}
.app-indicator .typing-indicator {
  bottom: 200px;
  left: 37%;
}
.btn-menu-resize-appmap {
  border-radius: 10px;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--main-blue-color);
  color: white;
}
.header-desc-steps {
  color: var(--main-blue-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.updating-steps {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 99999;
}
.checkbox-date span {
  background-color: var(--input-default-bg) !important;
  border-color: var(--main-blue-color);
}
.big-check .el-checkbox__input.is-checked .el-checkbox__inner::after {
  left: 6px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}
.custom-header-el-card .el-card__body {
  background: #cccccc;
  height: 100%;
}
.custom-header-el-card .el-card__body .el-row--flex.is-justify-space-between {
  height: 100%;
  background: #cccccc;
  border: 1px solid #cccccc;
}

.video-project-modal > div > div > div > .modal-footer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.video-modal > div {
  position: absolute;
  right: 32%;
}

.buttons-test {
  position: absolute;
  top: -8%;
  right: 24%;
  background-color: inherit !important;
}
.custom-icon-input {
  margin-right: 200px !important;
}
.custom-input input::placeholder {
  padding-left: 40px;
}
.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.card--animation {
  width: 31% !important;
  transition: 0.3s;
}
.card-api-stat {
  max-width: 360px;
  margin: 0px !important;
}
.header-custom {
  justify-content: space-between;
  display: flex;
  height: 80px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--main-blue-color) !important;
  align-items: center;
}
.header-custom h3 {
  margin-bottom: 0;
  color: white;
}

.card--animation:hover {
  box-shadow: $card-shadow !important;
}

.box-card {
  transition: 0.3s;
}

.box-card:hover {
  // box-shadow: $card-shadow !important;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.steps-list-start {
  margin: 0;
  padding: 0 0 0 15px;
  list-style-type: decimal;
}
.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.play-flow {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 126px;
  border-radius: 18px;
  color: white;
  background-color: var(--main-blue-color);
  border-color: var(--main-blue-color);
}
.play-flow:hover {
  background-color: var(--main-blue-color) !important;
  border-color: var(--main-blue-color) !important;
  color: white;
}
.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.upload-btn:hover {
  background-color: var(--main-blue-color) !important;
  color: white !important;
}

.upload-btn:focus {
  background-color: var(--main-blue-color) !important;
  color: white !important;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}
.date-picker-container {
  flex-direction: column;
  display: flex;
  gap: 16px;
}
.table-buttons-custom {
  border-color: #d6d6d6;
  max-width: 40px;
  max-height: 40px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #d6d6d6;
  color: var(--main-blue-color);
}
.table-buttons-custom i {
  font-size: 20px;
}

.file-lists-container {
  width: 100%;
}

.custom-drawer--title > div > div > header > span {
  color: black;
  font-size: 1.25rem;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custom-option-tests .el-collapse-item__content {
  background-color: #fff;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.header-custom {
  user-select: none;
}
.custom-test--buttons {
  font-size: 19px;
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}
.dots > span {
  margin-bottom: 18px;
  font-size: 24px;
}
.dots {
  z-index: 100;
  cursor: pointer;
  display: flex;
}
.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  //color: rgb(94, 114, 228);
  color: #e6e6e6;
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
}

.run-tags-cont {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 6px;
  padding: 5px 10px 5px 10px !important;
  font-weight: 400;
  font-size: 0.8rem !important;
  color: black;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: visible;
  white-space: nowrap;
}
.el-icon-date {
  display: none;
}
.run-tags-url {
  border-radius: 3px;
  padding: 5px !important;
  font-weight: 400;
  font-size: 14px !important;
  color: #53568a;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: lowercase !important;
}

.test-time-cont {
  display: inline-block;
  max-width: 100% !important;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px #787878;
  background: #787878;
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #e6e6e6;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}
.custom-elt-body-no-padding .el-card__body {
  padding: 5px;
}
.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}
.all_status {
  display: flex;
}
.column {
  margin: 0 10px 0 10px;
}
.mp--r p {
  margin-left: 5px;
  color: #787878;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 50%;
}
.no-overflowX .modal-body {
  overflow-x: hidden;
}
.pageview-modal .modal-body {
  overflow: auto;
}

.show-test-project-modal > div > div > div > .modal-body {
  overflow: auto;
}
.header-appmap {
  overflow: hidden;
  gap: 20px;
  display: flex;
  flex-direction: row;
  background-color: #e6e6e6;
  border-bottom: 1px solid var(--main-blue-color);
  z-index: 999;
  height: 130px;
  transition: 0.5s;
}

.close-menu .pickup-keyflow-container {
  opacity: 0;
}
.close-menu {
  overflow: hidden;
  border: 0;
  height: 0px;
}

.header-appmap-btns {
  transition: 0.2s;
  margin-top: 35px;
  gap: 5px;
  display: flex;
}
.pickup-keyflow-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modal-test-btns {
  gap: 5px;
  display: flex;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

// .show-test-project-modal .modal-dialog,
// .re-run-modal-container .modal-dialog {
//   width: auto;
//   max-width: 900px;
//   height: 10vh;
// }

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.container--tags {
  display: flex;
  overflow-y: auto;
  .elt-row--tag {
    margin: 2px;
  }
}
.body-suggestion {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.message-suggestion {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--input-default-bg);
  text-align: center;
}
.message-suggestion > h2 {
  margin-bottom: 0;
  font-size: 14px;
}
.btn-markAll {
  display: flex;
  margin-left: 82%;
  margin-top: 1%;

  label:nth-child(1) {
    width: 150px;
  }
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.container--greeting-session {
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 100px;
}
.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}
.el-input__inner::placeholder {
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 14px;
}
.color-words {
  color: brown;
  font-size: 15px;
}
.text-info-steps {
  background: rgb(230, 230, 230);
  color: rgb(31, 33, 62);
  border: 1px solid rgb(230, 230, 230);
  font-weight: 600;
  font-size: 14px;
}

.el-icon-search:before {
  color: #7d7d7d;
}
.cutom-body-row .el-card__body {
  background-color: #fff;
}
.cutom-body-row {
  border: 1px solid #fff;
  color: var(--main-blue-color);
}
.el-input__inner {
  border-radius: 10px;
  font-size: 0.75rem;
  background: var(--input-default-bg);
  border: 1px solid var(--input-default-bg);
  font-size: 15px;
  color: black;
  font-weight: 400;

  &:focus {
    outline: none !important;
  }
}

.el-input.is-disabled .el-input__inner {
  border-radius: 10px;
  font-size: 0.75rem;
  background: var(--input-disabled) !important;
  border-color: var(--input-disabled) !important;
  border: 1px solid;
  font-size: 15px;
  color: black;
  font-weight: 400;
}
.el-input.is-disabled .el-input__inner::placeholder {
  color: #7d7d7d !important;
}
.el-collapse-item__content {
  padding-top: 16px;
  background-color: #e6e6e6;
}
.el-select .el-input .el-select__caret {
  color: var(--main-blue-color);
  position: relative !important;
  // left: 53px !important;
  // bottom: 15px !important;
}
.small-inputs input {
  max-width: 100px;
}
.webhook--modal .modal-dialog .small-spaces .el-form-item__label {
  color: var(--main-blue-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  color: var(--main-blue-color);
  background-color: #ffffff;
  border-color: var(--main-blue-color);
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: var(--main-blue-color);
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--main-blue-color);
}

.upload-bar {
  .el-progress-circle {
    width: 45px !important;
    height: 45px !important;
  }
}

.edit-test-modal-container
  .edit-test-select
  .el-select
  .el-input
  .el-input__inner {
  text-transform: capitalize !important;
}
.project--name {
  font-size: 14px;
}
.modal-chat-session-wrapper {
  z-index: 110;
  position: relative;
}
.modal-chat-session {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
  background-color: var(--input-default-bg);
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  right: 3%;
}
.modal-chat-session > div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.modal-chat-session > div > h2 {
  margin-bottom: 0;
  font-size: 14px;
}
.modal-chat-session > div > span {
  width: 18px;
  height: 18px;
}
.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}
.text--start .el-card {
  border-bottom: none;
}
.desc-use-wring {
  max-width: 400px;
  color: var(--main-blue-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.desc-lets-start-wring {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}
.text--start {
  justify-content: space-between;
  display: flex;
}
.custom_btn--refresh {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  z-index: 9;

  background-color: #d6d6d6 !important;
  span {
    text-align: center;
  }
  span > i {
    color: var(--main-blue-color) !important;
    width: 20px;
    height: 20px;
  }
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}
#message-edit {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pakhpalle {
  background-color: red !important;
}
button:disabled {
  background: #787878;
  border: 1px solid #787878;
}
.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}
.date-picker-input input {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
  width: 250px !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}
.http-buttons {
  display: flex;
  gap: 15px;
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
  }

  .custom_btn {
    bottom: 5%;
    position: fixed;
    z-index: 9;
    right: 2.5%;
    font-size: 25px !important;
  }

  .mp--l {
    .small_btn {
      background: var(--main-blue-color) !important;
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        // background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .large_btn--def {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 1rem;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        // background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.add_btn {
  background-color: #409eff !important;
  width: 100%;

  span {
    color: white !important;
  }

  i {
    color: white !important;
  }
}

.button-menu.bg-primary {
  transition: 0.3s;
}

.button-menu.bg-primary:hover {
  background-color: #01136d !important;

  span {
    color: white;
  }

  i {
    color: white;
  }
}
.btns-kye-value {
  margin-left: 10px;
  align-items: center;
  display: flex;
  gap: 10px;
}
h3 {
  color: rgb(31, 33, 62);
  font-weight: 600;
  font-size: 14px;
}
.custom-date-div {
  margin-right: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.custom-date-div .el-checkbox {
  margin-bottom: 0;
}
.custom-date-div .el-checkbox__inner {
  width: 18px !important;
  height: 18px !important;
}
.btn-guide--line {
  display: block;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  padding: 0px !important;
  height: 35px;
  width: 35px;
  position: absolute;
  margin-top: -3px !important;
  right: 0px;
}
#area-edit {
  width: 35%;
}
#message-edit {
  width: 100%;
}
.btn-guide--line:focus {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}

@media (max-width: 850px) {
  .button-menu {
    width: 80%;

    span p {
      display: none !important;
    }
  }

  .el-select {
    width: 45%;
  }
}

@media (max-width: 1100px) {
  .container-script--upload > h2 {
    font-size: 1rem;
  }
  .avatar-uploader .el-upload {
    .el-upload-dragger {
      width: 148px;
      height: 148px;
      .avatar-uploader-icon {
        width: 148px;
        height: 148px;
        line-height: 148px;
      }
    }
  }
}

@media (max-width: 950px) {
  .container-script--upload > h2 {
    font-size: 0.75rem;
  }
  .avatar-uploader .el-upload {
    .el-upload-dragger {
      width: 108px;
      height: 108px;
      .avatar-uploader-icon {
        width: 108px;
        height: 108px;
        line-height: 108px;
      }
    }
  }
}

@media (max-width: 630px) {
  .button-menu {
    width: 80%;

    span {
      display: none;
    }
  }

  .el-select {
    width: 45%;
  }
  .btn-guide--line {
    display: none;
  }

  .cstm-mid--page {
    display: none;
  }
}

@media (max-width: 850px) {
  .ctb--body {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 630px) {
  .ctb--body {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;

    .ctbb--row {
      width: 100% !important;
    }
  }

  .large_btn--adpt {
    padding: 10px 15px;

    span {
      font-size: 0.7rem !important;
    }
  }

  .small_btn--adpt {
    padding: 10px 15px;
  }

  .adpt--mp--r {
    display: none !important;
  }

  .el-input > input::placeholder {
    display: none !important;
  }

  .tbl--header {
    overflow-x: scroll !important;
  }
}

.left-side-bar.is-close {
  .container--appmap-settings {
    display: none;
  }

  .container--api-settings {
    display: none;
  }
}

@media (max-width: 400px) {
  .ctb--body {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;

    .ctbb--row {
      width: 100% !important;
    }
  }
}

@media (max-width: 535px) {
  .button-menu {
    width: 80%;

    span {
      // display: none !important;
    }
  }

  .vjs-tree-list-holder {
    overflow-x: scroll;

    .vjs-tree-list-holder-inner {
      width: 300%;
    }
  }

  .large_btn--adpt {
    padding: 10px 15px;

    span {
      display: none;
    }
  }

  .el-card__body {
    .adpt--row {
      overflow-x: scroll;

      .el--flex {
        width: 500%;
      }
    }
  }

  .el-select {
    width: 45%;
  }

  .th--label {
    font-size: 0.5rem;
  }

  .custom_btn_settings {
    width: 25% !important;
  }

  .model-label {
    font-size: 9px !important;
  }

  .qkb-bot-ui {
    font-size: 0.5rem;
  }

  .adpt--choose {
    display: none !important;
  }

  .adpt-mid--page {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
  }

  .adpt--flex {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;

    div {
      margin-bottom: 5px;
    }
  }

  .tbl--body > .tblb--row {
    .tblb--inner {
      width: 300% !important;
    }
  }

  .adpt-card--filter {
    position: absolute;
    z-index: 999;
    top: 13%;
    right: 2%;
  }
}

.add_btn--set {
  background-color: var(--main-blue-color) !important;
  width: 100%;

  span {
    color: white !important;
  }

  i {
    color: white !important;
  }
}
.active_tab a {
  color: #efefef !important;
  text-transform: none;
}
.custom-tabs-link a {
  color: var(--main-blue-color);
}
.custom-tabs-link {
  display: flex;
  background-color: #dedede;
  width: 100%;
  height: 50px;
  color: var(--main-blue-color);
  align-items: center;
  justify-content: center;
}
.active_tab {
  background-color: var(--main-blue-color);
  color: #efefef;
}
.left-link {
  border-top-left-radius: 10px;
}
.right-link {
  border-top-right-radius: 10px;
}
.el-collapse-item__arrow {
  transform: rotate(90deg);
}
.el-collapse-item__arrow.is-active {
  transform: rotate(270deg);
}
.custom-tabs {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}
.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.text-total {
  color: black;
  margin-bottom: 0px;
  font-size: 0.6rem;
}

.is-hide--rect {
  display: none !important;
}

.custom--card-start {
  height: 159px;
  background-color: $color;
}

.custom--right-card {
}

.text--card {
  color: var(--main-blue-color);
}
.custom--card {
  border-bottom: 1px solid #e6e6e6 !important;
  border-color: #e6e6e6;
}
.text--start .el-card__body {
  padding-bottom: 10px;
  padding-top: 10px;
}
.custom--card .el-card__body {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 145px;
}
.custom--right-card {
  margin-top: 20px;
}
.left--side-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 63vh;
  margin-top: 20px;
  width: 100%;
}

.right--side-container {
  width: 30%;
}

.check-boxes {
  width: 40%;
}

.btn-graph {
  position: absolute;
  top: 8%;
  left: 15%;
}

.btn-graph--show {
  display: block;
}

.cstm-drp {
  z-index: 999999;
}

.btn-graph--clear {
  display: none;
}

.once-popup {
  top: 25% !important;
  left: 35% !important;
}

.simItemBB {
  border-width: var(--border);
  border-style: solid;
  border-color: var(--color);
  position: absolute;
  top: var(top_top);
  left: var(--x);
  width: var(--width);
  height: var(--height);
  z-index: 3;
}

.simItemBB:before,
.simItemBB:after {
  position: absolute;
  content: "";
  background: var(--color);
  display: block;
  width: var(--border);
  height: var(--width);
  -webkit-transform: calc(var(--diag-angle) * -1);
  transform: rotate(calc(var(--diag-angle) * -1));
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.simItemBB:after {
  -webkit-transform: rotate(var(--diag-angle));
  transform: rotate(var(--diag-angle));
}

.desc {
  text-align: left;
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }
      .text-value {
        color: var(--main-blue-color);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 12px;
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          color: var(--main-blue-color);

          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}
.elt-row {
  max-width: 100px;
}
.el-card {
  border-bottom: 1px solid #a8a8a8;
  background-color: #e6e6e6;
}
.tbl--body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    margin-bottom: 0 !important;
    width: 100%;
    background: #e6e6e6;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.8rem !important;
        color: black;
        font-weight: 400;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          color: var(--main-blue-color);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.el-progress--circle {
  display: flex !important;
  justify-content: start;
  margin-top: 10px;
}
.el-progress--circle .el-progress__text {
  text-align: start;
  padding-left: 50px;
}
.custom-select--date > .el-input {
  height: 40px !important;

  input {
    background-color: #d6d6d6 !important;
    border-radius: 30px;
    border: 1px solid #d6d6d6 !important;
    color: #6e6e6e !important;
    font-weight: 600;
    opacity: 1;
  }
}

.custom--select > .el-input {
  height: 40px !important;

  input {
    background-color: #d6d6d6 !important;
    border-radius: 30px;
    border: 1px solid #d6d6d6 !important;
    color: #6e6e6e !important;
    font-weight: 600;
    opacity: 1;
  }
}

// .custom--select input:hover{
//   border:1px solid #5603ad  !important;
// }
// .el-input__inner:hover{
//   border:1px solid #5603ad !important;
// }

.modal-body {
  height: 60vh;
  overflow-y: scroll;
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}

.sessionEvent-detail-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }

  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}

.custom_btn_settings {
  background: rgba($color, 0.1);
  // border: solid 0.1px $color;

  i,
  span {
    font-size: 14px;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }

  &.active {
    i,
    span {
      color: #fff !important;
    }
  }

  @media (max-width: 1400px) {
    span {
      font-size: 14px;
    }

    i {
      font-size: 14px;
    }
  }
}

.custom_btn_settings:nth-child(2n) {
  margin-left: 1rem !important;

  @media (max-width: 1400px) {
    margin-left: 0.5rem !important;
  }
}

.btn--api > span {
  margin-left: 5px;
  margin-right: 13px;
}

.showCoveringtests-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}

.swal2-popup #swal2-title {
  color: var(--main-blue-color) !important;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block !important;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}

.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;

  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.el-checkbox {
  width: 100%;
}

.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}

.ultra--ipt_ipt {
  width: 100%;
}

.ultra--ipt {
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}
.activeUltraInput {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding-left: 10px !important;
}
.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}

.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}

.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}

.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}

.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}

.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}

.container--event--liste {
  margin: 0 0 0 30px;
  padding: 8px;
  width: 100%;
  border: solid 1px #c6c6c6;
  border-radius: 5px;
  max-height: 656px;
  overflow: hidden;
}

.event--liste {
  max-height: 580px;
  overflow-x: auto;
  overflow-y: auto;
}

.event--liste::-webkit-scrollbar {
  display: none;
}

.content {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;

  .time--tag {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 1rem !important;
    width: 96%;
  }

  .right--content {
    width: 20%;
    flex-direction: column;
  }

  .icon--event {
    width: 20%;
    display: flex;
    background-color: #e5e5e5;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.content:hover {
  background-color: #e8eeff;

  .icon--event {
    background-color: $color;

    i {
      color: #ffff;
    }
  }
}

.active--event {
  background-color: #e8eeff;

  .icon--event {
    background-color: $color;

    i {
      color: #ffff;
    }
  }
}

.small_btn {
  background: var(--main-blue-color) !important;
  color: white;
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: white !important;
  }

  &:hover {
    border-color: white;
    // background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}

.mod--content {
  margin-bottom: 30px;
}

.wring-graph {
  position: relative;
  min-height: 70vh;
}

.el-drawer {
  width: 65% !important;
  overflow-y: scroll !important;
  padding: 0 20px;
}

.el-message-box {
  width: 70%;
}

.swal2-container {
  z-index: 99999 !important;
}

.swal2-popup {
  z-index: 99999;
}

.qkb-msg-bubble-component {
  text-align: left !important;
}

.not-video-test {
  opacity: 0.5;
}

// .qkb-bot-ui {
//   right: 90.5rem !important;
// }
run_tags // .qkb-board{

//   left: 0;
// }
.cuser {
  cursor: pointer;
}
.body--message > span {
  outline: blue;
}
.is--update_days {
  align-items: center;
  display: flex;

  .el-input > input {
    height: 35px !important;
    width: 100%;
  }

  input {
    width: 50%;
    border: solid 1px #5f72e4;
    background: #eff1fc;
    border-radius: 3px;
    height: 35px !important;
    padding-left: 5px;
  }

  input:focus {
    border: dashed 1px #5f72e4 !important;
  }
}
.heatmap-container {
  position: absolute !important;
}

.appmap-label {
  position: fixed !important;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0.5em;
  position: absolute;
  right: 35%;
  top: 92%;
  z-index: 99999;
}

.el-switch__label > span {
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.heatmap-canvas {
  z-index: 2;
  left: 15% !important;
  top: 15% !important;
}

.el-switch {
  // border: solid 0.5px #8A8D90;
  border-radius: 4px;
  height: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.el-icon-copy-document {
  color: var(--main-blue-color) !important;
}
.el-collapse {
  border-top: none;
}

.el-card__body {
  background-color: #dbdbdb;
  box-shadow: 0 0 0 0;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
<style lang="scss" scoped>
.tags-call-api {
  max-width: 80px;
  width: 88% !important;
  padding-left: 19px;
}

.vtza-h {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 10px;
  padding: 8px 16px;
  border-bottom: none !important;

  // max-height: 95vh;
  .vtza-c-l {
    width: 20%;
  }

  .vtza-c-r {
    width: 80%;
    // overflow: auto;
  }
}

.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}

.el-button--primary.is-plain {
  color: #5f72e4;

  g {
    fill: #5f72e4;
  }

  background: #d7dbf8;
  border-color: #9ba9ee;
}

.el-button--primary.is-plain:hover {
  color: #fff;

  g {
    fill: #fff;
  }

  background: #5f72e4;
}

.el-collapse-item__header {
  font-weight: 700 !important;
}
.custom_tile_body {
  flex-direction: row !important;
}
.tile-body {
  display: flex;
  backdrop-filter: blur(3px);
  background-color: #e5e5e54d;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;

  span:last-child {
    font-size: 1.6em !important;
  }
}

.ultra--ipt {
  height: 45px;
}

.container-alert {
  display: flex;
}

.el-alert {
  width: 100%;
  height: 30px;
}

.elt--txt {
  font-size: 0.7em;
}

.is-flex-graph {
  display: flex;
  justify-content: space-between;

  i {
    color: var(--main-blue-color) !important;
  }
}

.el-icon-search {
  transform: rotate(0deg) !important;
  margin-top: 2%;
}

.text-h-green {
  margin-right: 5px;
}
.add_button_not_active {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  color: var(--main-blue-color);
}
.add_button_not_active p {
  color: var(--main-blue-color);
}
.btn-group {
  gap: 5px !important;
}
.custom-hover-btn:hover {
  background-color: #fff;
}
.label-selectors-appmap {
  margin-bottom: 0;
  margin-top: 5px;
}
.pick-date-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
<style scoped>
.tags-call-api {
  padding-left: 0px !important;
}
.is-flex-row {
  display: flex;
  flex-direction: row;
}
.custom-trsl--parent {
  height: auto !important;
}
</style>
