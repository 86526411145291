import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import VueMeta from "vue-meta";
import axios from "axios";

const aioTokenRefreshUrl =
  process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/aiorefresh";

const options = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("vue-authenticate.access_token") !== null) {
    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];
      const context = { from, next, to, router };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
  } else {
    if (to.query.walToken) {
      const token = to.query.walToken;
      const id = to.params.id;
      const suiteId = to.params.suiteId;

      localStorage.setItem("vue-authenticate.access_token", token);
      localStorage.setItem("vue-authenticate.is_sharing", true);

      return next({ name: "Shared", params: { id: id, suiteId: suiteId } });
    } else {
      if (to.name === "Login") {
        return next();
      } else if (from.name === "Login") {
        return next();
      } else if (to.name === "Email-Confirmation") {
        return next();
      } else if (to.name === "PasswordReset") {
        return next();
      } else if (to.name === "Register") {
        return next();
      } else if (to.name === "Service-Agreement") {
        return next();
      } else if (to.name === "PasswordEmail") {
        return next();
      } else if (to.redirectedFrom && to.redirectedFrom.startsWith("/verify")) {
        console.log("redirected-from: ", to.redirectedFrom);
        const host = window.location.protocol + window.location.host;
        const url = new URL(host + to.redirectedFrom);
        const searchParams = new URLSearchParams(url.search);
        const walToken = to.redirectedFrom.startsWith("/verify?code=")
          ? searchParams.get("code")
          : searchParams.get("walToken");
        if (searchParams.get("SignOut")) {
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("testgold.aioToken");
          return next({ name: "Login" });
        }
        if (!walToken || walToken === "None") {
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("testgold.aioToken");
          if (
            searchParams.get("error") &&
            searchParams.get("error") ===
              "email_not_verified_user_not_activated"
          ) {
            Vue.prototype.$notify({
              type: "danger",
              message: "Email not verified user not activated",
            });
          } else {
            Vue.prototype.$notify({
              type: "danger",
              message:
                "connection failed or Email not verified user not activated ",
            });
          }
          return next({ name: "Login" });
        } else {
          localStorage.setItem("vue-authenticate.access_token", walToken);
          axios.post(aioTokenRefreshUrl, options).then((response) => {
            const aioToken = response.data.response.aioToken;
            localStorage.setItem("testgold.aioToken", aioToken);
          });
          return next({ name: "Dashboard" });
        }
      } else {
        localStorage.removeItem("vue-authenticate.access_token");
        localStorage.removeItem("testgold.aioToken");
        return next({ name: "Login" });
      }
    }
  }
});

export default router;
