<template>
  <div class="right-content suite-view-item pt-4">
    <div class="container-fluid">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div style="margin-bottom: 10px" class="row">
            <div class="col-4">
              <!-- <div style="flex-wrap: nowrap;" slot="header" class="row align-items-center px-1">
                <div>
                  <base-button
                    @click="goBack"
                    type="button"
                    class="btn btn-sm button-aligned"
                    ><i
                      style="color: black"
                      class="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i
                  ></base-button>
                </div>

                <div
                  class="pl-2"
                  :style="{
                    width: '100%',
                  }"
                >
                  <el-tooltip :content="items.runName" placement="top-start">
                    <h3 style="overflow: visible;" class="mb-0 temp2" v-if="items.testSuiteName">
                      Test Suite - {{ items.testSuiteName }}
                    </h3>
                  </el-tooltip>
                </div>
              </div> -->
            </div>
            <!-- <div class="col-8">
              <div slot="header" class="d-flex justify-content-start">
                <div
                  role="group"
                  aria-label="Basic example"
                  class="btn-group align-items-center"
                >
                  <button
                    v-bind:class="{ active: showSuiteOverview }"
                    @click="showComponent('suiteOverview')"
                    type="button"
                    class="btn base-button btn-secondary"
                  >
                    Suite Overview
                  </button>
                  <button
                    v-bind:class="{ active: showSuiteListItem }"
                    @click="showComponent('showSuiteListItem')"
                    type="button"
                    class="btn base-button btn-secondary"
                  >
                    All Tests
                  </button>
                </div>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <div v-else class="w-100" id="content">
              <div v-show="showSuiteOverview">
                <suite-overview
                  ref="suiteOverview"
                  :items="items"
                ></suite-overview>
              </div>
              <div v-show="showSuiteListItem">
                <suite-list-item-page
                  ref="suiteItemsListPage"
                  :getSuiteFunction="get"
                  :suiteItems="suiteItems"
                  :suiteId="suiteId"
                  :loading="loading"
                ></suite-list-item-page>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import "sweetalert2/dist/sweetalert2.css";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import SuiteOverview from "../SuitePages/SuiteOverview.vue";
import SuiteListItemPage from "../SuitePages/SuiteListItemPage.vue";
import axios from "axios";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  layout: "DashboardLayout",
  components: {
    SuiteOverview,
    SuiteListItemPage,
    BaseButton,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [formMixin],
  data() {
    return {
      colorPalette: "red",
      log: console.log,
      loading: true,
      showSuiteOverview: false,
      showSuiteListItem: true,
      date_at: new Date(),
      items: {},
      suiteItems: {},
      suiteId: "",
      RLJson: {},
      RLtestcase: {
        sequence: {},
      },
      rl_sequence: {},
      id: "",
    };
  },
  methods: {
    async get() {
      this.loading = true;
      const testSuitRunId = this.$route.params.id;
      // console.log(testSuitRunId);
      // try {
      //   await this.$store.dispatch("items/get", id);
      //   this.items = this.$store.getters["items/item"];
      //   this.suiteId = this.items.testSuiteId;
      //   this.testSuiteRunId = this.items.testSuiteRunId;
      //   console.log("suit view item", this.items);
      //   this.$nextTick(() => {
      //     this.$refs.suiteOverview.getSuiteOverview();
      //     this.$refs.suiteItemsListPage.geSuitetList();
      //   });
      //   this.RLJson = this.items.RLJson;
      //   this.RLtestcase = this.items.RLJson.testcase;

      //   if (typeof this.RLtestcase === "undefined") {
      //     this.RLtestcase = {
      //       sequence: {},
      //     };
      //   }
      //   this.id = id;
      //   this.loading = false;
      // } catch (error) {
      //   console.log("Error: ", error);
      //   this.$notify({
      //     type: "danger",
      //     message: `Oops, something went wrong!`,
      //   });
      // }

      await axios
        .get(
          `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/testsuiterun/${testSuitRunId}`
        )
        .then((response) => {
          this.items = response.data.response;
          this.suiteItems = response.data.response.testRunDetails;
          this.$nextTick(() => {
            this.$refs.suiteOverview.getSuiteOverview();
            this.$refs.suiteItemsListPage.geSuitetList();
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSuiteOverview() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    formatRequired(row, col, value, index) {
      if (value) {
        return "Y";
      } else {
        return "N";
      }
    },
    goBack() {
      this.$router.push({
        name: "List Items",
      });
    },
    showComponent(name) {
      this.showSuiteOverview = false;
      this.showSuiteListItem = false;
      if (name === "suiteOverview") {
        this.showSuiteOverview = true;
      } else if (name === "showSuiteListItem") {
        this.showSuiteListItem = true;
      }
    },
  },

  created() {
    this.get();
  },
};
</script>

<style>
.w-100 .card-header,
.w-100 .card-footer {
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #e6e6e6;
}
.page-item.active .page-link {
  background-color: var(--main-blue-color);
  color: #efefef;
  border-color: var(--main-blue-color);
}
</style>
