import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import i18n from "./locales"; // Internationalization

//plugins

import DashboardPlugin from "./plugins/dashboard-plugin";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import IsDemo from "./plugins/isDemo";

// router&store setup
import router from "./router";
import store from "./store";

import "element-ui/lib/theme-chalk/index.css";
import "animate.css";
// import './assets/tailwind.css'
import "./assets/css/style.css";

// plugin setup
Vue.use(ElementUI, { locale });
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(IsDemo);
Vue.use(VueMeta, { keyName: "head" });

const app = new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: (h) => h(App),
});

store.$app = app;
