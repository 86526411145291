<template>
  <div class="empty-layout">
    <notifications></notifications>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {},
  watch: {},
};
</script>
<style lang="scss"></style>
