<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/test-explorer/list-tests"
        ><i class="el-icon-house"></i
      ></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span>Projects Explorer</span>
    </div>
    <div class="mid--page pb-4 mb-3">
      <div class="mp--l">
        <span
          @click="saveDataView('list')"
          :class="{ active: dataView === 'list' }"
          ><i class="ni-column-horizontal"></i
        ></span>
        <span :class="{ active: dataView === 'thumb' }" class="mx-1"
          ><i class="ni-intersect"></i
        ></span>
        <span :class="{ active: dataView === 'card' }"
          ><i class="ni-grid"></i
        ></span>
        <el-input class="ml-3" placeholder="Search" v-model="query">
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="transform: rotate(0deg) !important"
          ></i>
        </el-input>
        <el-button
          @click="toggleAddProjectModal = true"
          icon="el-icon-plus"
          class="ml-3 small_btn"
          plain
          size="small"
        >
          Add Project
        </el-button>
      </div>
      <div class="mp--r">
        <span class="pag--details mr-3">
          {{ pagination.total ? pagination.from + 1 : 1 }} to
          {{
            pagination.to > pagination.total ? pagination.total : pagination.to
          }}
          of {{ pagination.total }} entries
        </span>
        <el-select
          v-model="pagination.perPage"
          @change="savePagination"
          placeholder="Per page"
          class="small--select"
        >
          <el-option
            v-for="(item, id) in pagination.perPageOptions"
            :key="id"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="eff--page">
      <data-list
        v-if="dataView === 'list'"
        :tHeader="tHeader"
        :loading="loading"
        :items="toShow"
        ref="dataList"
      >
        <template #content>
          <div class="tblb--row mb-2" v-for="elt in toShow" :key="elt.id">
            <div class="tblb--inner">
              <div
                calss="elt-row"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                <span class="test-run-name">
                  {{ elt.name }}
                </span>
              </div>
              <div
                calss="elt-row"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                <span v-if="elt.attributes" class="run-tags-cont">
                  <span
                    class="run-tags"
                    v-for="(attr, index) in elt.attributes"
                    :key="index"
                  >
                    {{ attr.value }}
                  </span>
                </span>
              </div>
              <div
                class="elt-row"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                N/A
              </div>
              <div
                calss="elt-row"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                <span class="test-time-cont" style="max-width: 100%">
                  <i class="far fa-clock mr-1"></i>
                  {{ formatDate(elt.createdAt) }}
                </span>
              </div>
              <div
                calss="elt-row"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                <span class="test-time-cont" style="max-width: 100%">
                  <i class="far fa-clock mr-1"></i>
                  {{ formatDate(elt.updatedAt) }}
                </span>
              </div>
              <div
                class="elt-row flex--elt"
                :style="
                  'width: calc(100% /' +
                  tHeader.length +
                  '); padding: 1.4rem 0.8rem'
                "
              >
                <el-tooltip
                  class="item"
                  effect="light"
                  content="Edit this test"
                  placement="top"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    plain
                    size="small"
                  ></el-button>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="light"
                  content="Delete this test"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    plain
                    size="small"
                  ></el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </data-list>
      <div v-if="dataView === 'thumb'" class="bpsd_dtb">
        <table class="bpsd--table">
          <thead class="tbl--head mb-3">
            <tr>
              <th v-for="elt in tHeader" :key="elt.id">
                <span class="th--label">{{ elt.label }}</span>
                <span v-if="elt.sortable" class="th--sort ml-2"
                  ><i class="el-icon-sort"></i
                ></span>
              </th>
            </tr>
          </thead>
        </table>
        <div class="tbl--body mb-3" v-if="loading">
          <div class="tblb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div class="tbl--body mb-3" v-else>
          <template v-if="toShow.length > 0">
            {{ JSON.stringify(toShow) }}
          </template>
          <div class="tblb--row px-3 py-4" v-else>No items found</div>
        </div>
      </div>
      <div v-if="dataView === 'card'" class="bpsd_ctb">
        <div class="ctb--body mb-3" v-if="loading">
          <div class="ctbb--row mb-2">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div class="ctb--body mb-3" v-else>
          <template v-if="toShow.length > 0">
            {{ JSON.stringify(toShow) }}
          </template>
          <div class="ctbb--row px-3 py-7" v-else>No items found</div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        class="pagination-no-border"
        :total="pagination.total"
        :page-size="pagination.perPage"
        :page-sizes="pagination.perPageOptions"
        :current-page.sync="pagination.currentPage"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>

    <div class="is-scrollable webhook--modal">
      <modal :show.sync="toggleAddProjectModal" v-loading="addProjectLoading">
        <template slot="header">
          <h5 class="modal-title">
            {{ editedIndex > 0 ? "Update Project" : "Create a new Project" }}
          </h5>
        </template>
        <div class="mt--5">
          <div class="mod--content">
            <el-form
              class="small-spaces"
              @submit.native.prevent="handleAddEditProject"
            >
              <el-form-item label="Name" required>
                <el-input
                  v-model="editedProject.name"
                  placeholder="Enter a name for this project"
                ></el-input>
              </el-form-item>
              <el-form-item label="Description" class="pb-4">
                <el-input
                  type="textarea"
                  placeholder="Enter a description for this project"
                  v-model="editedProject.description"
                ></el-input>
              </el-form-item>
              <template v-if="editedProject.attributes.length > 0">
                <el-divider content-position="left">
                  <span class="b--title">Project Attributes</span>
                </el-divider>
                <el-row
                  :gutter="20"
                  class="f--center_y"
                  v-for="att in editedProject.attributes"
                  :key="att.id"
                >
                  <el-col :span="8">
                    <el-form-item label="Key">
                      <el-input
                        v-model="att.key"
                        placeholder="Enter the key"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Value">
                      <el-input
                        v-model="att.value"
                        placeholder="Set the value"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-tooltip
                      class="item"
                      content="Remove this attribute"
                      placement="top"
                    >
                      <el-button
                        style="transform: translateY(16px)"
                        @click.stop="removeAttribute(att)"
                        type="danger"
                        icon="el-icon-delete"
                        plain
                        small
                        ><small>Remove</small></el-button
                      >
                    </el-tooltip>
                  </el-col>
                </el-row>
              </template>
              <div class="c--add_btn px-2 py-3 mt-3" @click="addAttribute">
                <i class="el-icon-plus"></i>
                <span> New Attribute </span>
              </div>
              <div class="edit-accordion-btns mt-4">
                <base-button
                  type="secondary"
                  @click="toggleAddProjectModal = false"
                >
                  Cancel
                </base-button>
                <base-button type="primary" native-type="submit">
                  {{ editedIndex > 0 ? "Edit Project" : "Add Project" }}
                </base-button>
              </div>
            </el-form>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  components: { DataList },

  data() {
    return {
      dataView: "list",
      baseUrl: process.env.VUE_APP_API_URL_PREFIX,
      loading: false,
      toggleAddProjectModal: false,
      addProjectLoading: false,
      query: null,
      userId: undefined,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        from: 0,
        to: 0,
        total: 0,
      },
      items: [],
      toShow: [],
      newAttribute: { key: "", value: "" },
      editedAttribute: { key: "", value: "" },
      tHeader: [
        {
          label: "Name",
          key: "name",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "Attributes", key: "attributes", sortable: false },
        { label: "Status", key: "", sortable: false },
        {
          label: "Created At",
          key: "createdAt",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        {
          label: "Updated At",
          key: "updatedAt",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "date" },
        },
        { label: "", key: "", sortable: false },
      ],
      editedIndex: -1,
      editedProject: {
        name: "",
        description: "",
        attributes: [],
      },
      newProject: {
        name: "",
        description: "",
        attributes: [],
      },
    };
  },
  watch: {},
  async created() {
    this.pagination.perPage =
      sessionStorage.getItem("listProjectPageNbr") !== null
        ? parseInt(sessionStorage.getItem("listProjectPageNbr"))
        : 10;
    this.dataView =
      sessionStorage.getItem("data-view") !== null
        ? sessionStorage.getItem("data-view")
        : "list";

    await Promise.all([this.getProjects()]);
  },

  methods: {
    savePagination() {
      sessionStorage.setItem("listProjectPageNbr", this.pagination.perPage);
    },
    saveDataView(val) {
      this.dataView = val;
      sessionStorage.setItem("data-view", this.dataView);
    },
    formatDate(val) {
      let day_display = "";
      let time_display = "";
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (time_zone.toLowerCase().includes("america")) {
        day_display = moment(val).format("MMM Do YYYY");
        time_display = moment(val).format("LT");
      } else {
        day_display = moment(val).format("Do MMM YYYY");
        time_display = moment(val).format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    randomId() {
      return Math.random().toString(36).substr(2, 5);
    },
    handleCurrentChange(val) {
      this.pagination.from = (val - 1) * this.pagination.perPage;
      this.pagination.to =
        parseInt(this.pagination.from) + parseInt(this.pagination.perPage);
      this.toShow = this.items.filter((item, val) => {
        return this.pagination.from <= val && val < this.pagination.to;
      });
    },
    handleSizeChange(val) {
      this.pagination.perPage = val;
      this.handleCurrentChange(this.pagination.currentPage);
    },
    addAttribute() {
      const canAdd =
        this.editedProject.attributes.filter((elt) => elt.key.length === 0)
          .length > 0;
      if (!canAdd) {
        this.editedAttribute.$id = this.randomId();
        this.editedProject.attributes.push(this.editedAttribute);
        this.editedAttribute = Object.assign({}, this.newAttribute);
      } else {
        this.$notify({
          type: "danger",
          message: `Cannot have an empty key attribute`,
        });
      }
    },
    removeAttribute(att) {
      this.editedProject.attributes = this.editedProject.attributes.filter(
        (elt) => elt.$id !== att.$id
      );
    },

    async handleAddEditProject() {
      try {
        this.addProjectLoading = true;
        this.editedProject.attributes = this.editedProject.attributes.map(
          ({ $id, ...keep }) => keep
        );

        await axios
          .post(`${this.baseUrl}/projects/v1`, this.editedProject)
          .then((res) => {
            if (res.data) {
              this.$notify({
                type: "success",
                message: `Projec created successfully`,
              });

              this.getProjects();
            }
          });
      } catch (e) {
        action = this.editedIndex > 0 ? "edit" : "add";
        this.$notify({
          type: "danger",
          message: `Cannot ${action} this project : ${e.message}`,
        });
      } finally {
        this.addProjectLoading = false;
        this.toggleAddProjectModal = false;
      }
    },

    async getProjects() {
      try {
        this.loading = true;

        await axios
          .get(`${this.baseUrl}/projects/v1/user`)
          .then((res) => {
            if (res.message === "Request failed with status code 404") {
              this.$notify({
                type: "danger",
                message: `Nothing found`,
              });
            } else {
              if (res.data) {
                const data = res.data.response;
                this.items = Object.values(data);
              }
            }

            this.pagination.total = this.items.length;
            this.handleCurrentChange(this.pagination.currentPage);
          })
          .catch((e) => {
            this.$notify({
              type: "danger",
              message: `Cannot load user projects : ${e.message}`,
            });
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Cannot load user projects : ${e.message}`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss">
$txtColor: #4b4b71;
$color: #5e72e4;
.b--title {
  font-weight: 800;
  color: rgba($txtColor, 0.7);
}
.f--center_y {
  display: flex;
  align-items: center;
}

.c--add_btn {
  border-radius: 0.25rem;
  color: $color;
  border: dashed 1px $color;
  background: rgba($color, 0.05);
  text-align: center;
  cursor: pointer;

  &:hover {
    background: rgba($color, 0.12);
  }
}

.elt-row {
  padding: 1.4rem 0.8rem;
}
</style>
