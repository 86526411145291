import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
// import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import categories from "./modules/categories-module";
import tags from "./modules/tags-module";
import items from "./modules/items-module";
import reset from "./modules/reset";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showTrigger: true,
    showReport: false,
    showScheduled: false,
    showInstallation: true,
    imageSrc: "",
    isDarkMode: false,
  },
  mutations: {
    setImage(state, src) {
      state.imageSrc = src;
    },
    TOGGLE_DARK_MODE(state) {
      state.isDarkMode = !state.isDarkMode;
    },
    TOGGLE_TRIGGER(state) {
      state.showTrigger = true;
    },
  },
  actions: {
    loadImage({ commit }) {
      const image = new Image();
      image.src = "/img/loading.gif";
      image.onload = () => {
        commit("setImage", image.src);
      };
    },
    toggleDarkMode({ commit }) {
      commit("TOGGLE_DARK_MODE");
    },
    toggleTrigger({ commit }) {
      commit("TOGGLE_TRIGGER");
    },
  },
  getters: {
    isDarkMode: (state) => state.isDarkMode,
    showTrigger: (state) => state.showTrigger,
  },
  modules: {
    auth,
    // alerts,
    profile,
    users,
    roles,
    categories,
    tags,
    items,
    reset,
  },
});
