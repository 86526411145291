<template>
  <span v-if="startLoading && dataView === 'thumb'">
    <img src="/img/loading.gif" alt="loading..." height="100px" width="100px" />
  </span>
  <div v-else class="trs--container mt--2">
    <div style="border-bottom: none" class="mid--page mb-4">
      <div class="mp--l" style="gap: 10px">
        <span
          @click="saveDataView('thumb')"
          :class="{ active: dataView === 'thumb' }"
          ><i class="ni-intersect"></i
        ></span>
        <span
          @click="saveDataView('list')"
          :class="{ active: dataView === 'list' }"
          ><i class="ni-column-horizontal"></i
        ></span>
        <span
          @click="saveDataView('card')"
          :class="{ active: dataView === 'card' }"
          ><i class="ni-grid"></i
        ></span>
      </div>
      <div class="mp--r adpt--page-details">
        <span style="white-space: nowrap; margin: 0 10px 0 10px"
          >Steps : {{ total }}</span
        >
        <span>Show</span>
        <el-select
          v-model="perPage"
          @change="handleSizeChange"
          placeholder="Per page"
          class="small--select"
        >
          <el-option
            v-for="(item, id) in pageSizes"
            :key="id"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div v-if="dataView === 'card'" class="bpsd_ctb table-body-scroll-details">
      <div class="ctb--body mb-3" v-if="requestingForData">
        <div class="ctbb--row mb-2">
          <img
            src="/img/loading.gif"
            alt="loading..."
            style="height: 64px; width: 64px"
          />
        </div>
      </div>
      <div class="ctb--body mb-3" v-else>
        <template v-if="toShow.length > 0">
          <div
            v-for="(elt, id) in toShow"
            :key="elt.id"
            class="ctbb--row mb-2"
            :class="elt.status === 'processing' && 'ctbb--processing'"
          >
            <div class="ctbb--inner custom-card-details">
              <div class="elt--banner" v-if="elt.initialPageScreenshot">
                <img
                  v-if="elt.initialPageScreenshot"
                  :src="`data:image/png;base64,${elt.initialPageScreenshot}`"
                  alt="screenshot of current step"
                  class="elt--banner_img"
                />
                <div
                  class="elt--banner_overlay cursor--zoom"
                  @click="
                    previewPicture(
                      `data:image/png;base64,${elt.initialPageScreenshot}`,
                      elt
                    )
                  "
                ></div>
                <el-alert
                  class="no--close elt--banner_overlay_status"
                  show-icon
                  :title="elt.isActive ? 'Active' : 'Inactive'"
                  :type="elt.isActive ? 'success' : 'danger'"
                ></el-alert>
                <span
                  v-if="elt.status === 'passed'"
                  class="elt--banner_overlay_duration"
                >
                  <i class="far fa-clock mr-1"></i>
                  {{ secondsToRealTime(elt) }}
                </span>
                <span class="elt--banner_overlay_created">
                  {{ formatDate(elt.sentAt) }}
                </span>
              </div>

              <div
                class="elt--banner"
                v-else-if="elt.targetElement && elt.targetElement.step_pagescr"
              >
                <img
                  v-if="elt.targetElement.step_pagescr"
                  :src="`data:image/png;base64,${elt.targetElement.step_pagescr}`"
                  alt="screenshot of current step"
                  class="elt--banner_img"
                />
                <div
                  class="elt--banner_overlay cursor--zoom"
                  @click="
                    previewPictureWithoutSelector(
                      `data:image/png;base64,${elt.targetElement.step_pagescr}`
                    )
                  "
                ></div>
                <el-alert
                  class="no--close elt--banner_overlay_status"
                  show-icon
                  :title="elt.isActive ? 'Active' : 'Inactive'"
                  :type="elt.isActive ? 'success' : 'danger'"
                ></el-alert>
                <span
                  v-if="elt.status === 'passed'"
                  class="elt--banner_overlay_duration"
                >
                  <i class="far fa-clock mr-1"></i>
                  {{ secondsToRealTime(elt) }}
                </span>
                <span class="elt--banner_overlay_created">
                  {{ formatISODateTime(elt.createdAt) }}
                </span>
              </div>

              <div
                class="elt--banner"
                :class="elt.initialPageScreenshot === null && 'no--img p-3'"
                v-else
              >
                <img
                  v-if="elt.initialPageScreenshot !== null"
                  src="/img/loading.gif"
                  alt="loading..."
                  style="height: 100px; width: 100px"
                />
                <small v-else>
                  <em
                    ><u>{{ $t("test_run_steps.no_screenshot") }}</u></em
                  >
                </small>
              </div>

              <div class="card-no-mr elt--content">
                <div class="text-btns-container-card">
                  <span class="test-run-name"
                    >{{ $t("test_run_steps.step") }} {{ elt.$id + 1 }} </span
                  ><br />
                  <div>
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="$t('test_run_steps.edit')"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-edit"
                        @click.stop="handleAddEditStepModal(elt, id)"
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>

                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="$t('test_run_steps.add')"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-plus"
                        @click.stop="handleAddEditStepModal()"
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>

                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="$t('test_run_steps.delete')"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        @click.stop="deleteTestStep(elt)"
                        plain
                        size="small"
                      >
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>

                <span class="small-text">
                  <span> {{ elt.stepDescription }}</span>
                  <div
                    v-if="elt.selector"
                    class="only-selector link-card"
                    @click="elt.showSelector = !elt.showSelector"
                  >
                    <el-tooltip
                      v-if="elt.showSelector"
                      class="item"
                      content="Hide selector"
                      placement="top"
                    >
                      <span class="mark">{{ elt.selector }}</span>
                    </el-tooltip>
                    <el-tooltip
                      v-else
                      class="item"
                      content="Show selector"
                      placement="top"
                    >
                      <span class="empty--selector">...</span>
                    </el-tooltip>
                  </div>
                </span>
                <div
                  class="elt--footer"
                  style="display: flex; gap: 5px"
                  v-if="
                    (isSharing === 'true' &&
                      sharedJSON.scopes.includes('shared-edit')) ||
                    isSharing === null
                  "
                ></div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="ctbb--row mb-2">No items found</div>
        </template>
      </div>
    </div>

    <div v-if="dataView === 'list'">
      <card v-if="requestingForData" class="request-data-loading">
        <!-- <img src="/img/loading.gif" alt="loading..." height="100px" width="100px" /> -->
      </card>
      <div v-else>
        <div class="text-center" v-if="toShow.length === 0">
          Nothing to show
        </div>
        <div v-else>
          <div class="trs--list">
            <el-card
              v-show="adding"
              class="trsl--elt"
              id="add-form"
              ref="add-test-step"
              shadow="hover"
            >
              <div class="add-accordion">
                <div class="row mt-5">
                  <div class="col">
                    <div class="mod--content">
                      <el-form
                        v-loading="stepLoading"
                        @submit.native.prevent="addTestStepAction()"
                      >
                        <el-form-item
                          :label="$t('test_run_steps.insert_new_step')"
                          prop="existing step"
                        >
                          <el-select
                            class="select-primary"
                            :placeholder="$t('test_run_steps.new_step')"
                            v-model="editedStep.targetStepId"
                          >
                            <el-option
                              v-for="(item, index) in toShow"
                              class="select-primary"
                              :value="item.stepNumber"
                              :label="
                                index + from + 1 + ' - ' + item.stepDescription
                              "
                              :key="item.stepNumber"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          style="max-width: 220px"
                          :label="$t('test_run_steps.action_to_perform')"
                          prop="Action"
                        >
                          <el-select
                            class="select-danger"
                            placeholder="Actions"
                            v-model="editedStep.action"
                          >
                            <el-option
                              v-for="option in stepActionMap"
                              class="select-danger"
                              :value="option.value"
                              :label="option.label"
                              :key="option.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-row :gutter="20">
                          <el-col :span="6">
                            <el-form-item
                              style="max-width: 220px"
                              :label="$t('test_run_steps.selector_type')"
                              prop="prop3"
                            >
                              <el-select
                                class="select-danger"
                                :placeholder="
                                  $t('test_run_steps.choose_selector_type')
                                "
                                v-model="editedStep.selectorType"
                              >
                                <el-option
                                  v-for="option in stepSelectorList"
                                  class="select-danger"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="18">
                            <el-form-item
                              style="max-width: 220px"
                              :label="$t('test_run_steps.selector_to_use')"
                            >
                              <el-input
                                style="height: 90px"
                                type="text"
                                id="stepSelector"
                                name="stepSelector"
                                v-model="editedStep.selector"
                                :placeholder="
                                  $t('test_run_steps.enter_selector')
                                "
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-form-item
                          :label="$t('test_run_steps.input_values')"
                        >
                          <el-input
                            type="text"
                            id="stepInputValue"
                            name="stepInputValue"
                            v-model="editedStep.inputValue"
                            :placeholder="$t('test_run_steps.enter_value')"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="edit-accordion-btns"
                          style="display: flex; gap: 5px"
                        >
                          <div>
                            <button
                              class="cancel_button"
                              @click="adding = false"
                            >
                              {{ $t("test_run_steps.cancel") }}
                            </button>
                          </div>
                          <div>
                            <button class="add_button" native-type="submit">
                              {{ $t("test_run_steps.save") }}
                            </button>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col"></div>
                </div>
              </div>
            </el-card>
            <draggable
              :list="toShow"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              @start="dragging = true"
              @end="dragging = false"
              :move="updateReorder"
              handle=".drag-only-row"
            >
              <transition-group class="trsl--parent custom-trsl--parent">
                <el-card
                  v-for="(row, index) in toShow"
                  :key="row.$id"
                  class="trsl--elt"
                  shadow="hover"
                >
                  <el-row
                    type="flex"
                    justify="space-between"
                    class="el--flex drag-only-row"
                    :gutter="20"
                  >
                    <el-col :span="2" class="trsl--txt">
                      <p>
                        {{ row.$id + from + 1 }}
                      </p>
                    </el-col>
                    <el-col :span="18" class="trsl--txt">
                      <div class="step-description">
                        <span> {{ row.stepDescription }}</span>
                        <div
                          v-if="row.selector"
                          class="only-selector"
                          @click="row.showSelector = !row.showSelector"
                        >
                          <el-tooltip
                            v-if="row.showSelector"
                            class="item"
                            content="Hide selector"
                            placement="top"
                          >
                            <span class="mark">{{ row.selector }}</span>
                          </el-tooltip>
                          <el-tooltip
                            v-else
                            class="item"
                            content="Show selector"
                            placement="top"
                          >
                            <span class="empty--selector">...</span>
                          </el-tooltip>
                        </div>
                      </div>
                    </el-col>
                    <div
                      class="el-col flex btns-gap"
                      style="margin-right: 20px"
                      v-if="
                        (isSharing === 'true' &&
                          sharedJSON.scopes.includes('shared-edit')) ||
                        isSharing === null
                      "
                    >
                      <el-tooltip
                        :content="$t('test_run_steps.edit')"
                        placement="top"
                      >
                        <el-button
                          type="primary"
                          icon="el-icon-edit"
                          @click.stop="handleAddEditStepModal(index, row)"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>

                      <el-tooltip
                        :content="$t('test_run_steps.add')"
                        placement="top"
                      >
                        <el-button
                          type="success"
                          icon="el-icon-plus"
                          @click.stop="handleAddEditStepModal()"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>

                      <el-tooltip
                        :content="$t('test_run_steps.delete')"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="deleteTestStep(row)"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </el-row>

                  <!-- Edit Step Start -->
                  <div class="edit-accordion" v-show="index === selectedItemId">
                    <div class="row mt-5">
                      <div class="col">
                        <div class="mod--content">
                          <el-form
                            v-loading="stepLoading"
                            @submit.native.prevent="addTestStepAction()"
                          >
                            <el-form-item
                              :label="$t('test_run_steps.insert_new_step')"
                              prop="existing step"
                            >
                              <el-select
                                class="select-primary"
                                :placeholder="$t('test_run_steps.new_step')"
                                v-model="editedStep.targetStepId"
                              >
                                <el-option
                                  v-for="(item, index) in toShow"
                                  class="select-primary"
                                  :value="item.stepNumber"
                                  :label="
                                    index +
                                    from +
                                    1 +
                                    ' - ' +
                                    item.stepDescription
                                  "
                                  :key="item.stepNumber"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item
                              style="max-width: 220px"
                              :label="$t('test_run_steps.action_to_perform')"
                              prop="Action"
                            >
                              <el-select
                                class="select-danger"
                                placeholder="Actions"
                                v-model="editedStep.action"
                              >
                                <el-option
                                  v-for="option in stepActionMap"
                                  class="select-danger"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.value"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-row :gutter="20">
                              <el-col :span="6">
                                <el-form-item
                                  style="max-width: 220px"
                                  :label="$t('test_run_steps.selector_type')"
                                  prop="prop3"
                                >
                                  <el-select
                                    class="select-danger"
                                    :placeholder="
                                      $t('test_run_steps.choose_selector_type')
                                    "
                                    v-model="editedStep.selectorType"
                                  >
                                    <el-option
                                      v-for="option in stepSelectorList"
                                      class="select-danger"
                                      :value="option.value"
                                      :label="option.label"
                                      :key="option.label"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="18">
                                <el-form-item
                                  :label="$t('test_run_steps.selector_to_use')"
                                >
                                  <el-input
                                    style="height: 90px"
                                    type="text"
                                    id="stepSelector"
                                    name="stepSelector"
                                    v-model="editedStep.selector"
                                    :placeholder="
                                      $t('test_run_steps.enter_selector')
                                    "
                                  ></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>

                            <el-form-item
                              :label="$t('test_run_steps.input_values')"
                            >
                              <el-input
                                type="text"
                                id="stepInputValue"
                                name="stepInputValue"
                                v-model="editedStep.inputValue"
                                :placeholder="$t('test_run_steps.enter_value')"
                              >
                              </el-input>
                            </el-form-item>
                            <div
                              class="edit-accordion-btns"
                              style="display: flex; gap: 5px"
                            >
                              <div>
                                <button
                                  class="cancel_button"
                                  @click="selectedItemId = null"
                                >
                                  {{ $t("test_run_steps.cancel") }}
                                </button>
                              </div>
                              <div>
                                <button class="add_button" native-type="submit">
                                  {{ $t("test_run_steps.save") }}
                                </button>
                              </div>
                            </div>
                          </el-form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Edit Step End -->
                </el-card>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dataView === 'thumb'">
      <div v-if="!startLoading">
        <div class="text-center" v-if="toShow.length === 0">
          <!-- Nothing to show -->
        </div>
        <div v-else>
          <div class="trs--list">
            <el-card
              v-show="adding"
              class="trsl--elt"
              id="add-form"
              ref="add-test-step"
              shadow="hover"
            >
              <div class="add-accordion">
                <div class="row mt-5">
                  <div class="col">
                    <div class="mod--content">
                      <el-form
                        v-loading="stepLoading"
                        @submit.native.prevent="addTestStepAction()"
                      >
                        <el-form-item
                          :label="$t('test_run_steps.insert_new_step')"
                          prop="existing step"
                        >
                          <el-select
                            class="select-primary"
                            :placeholder="$t('test_run_steps.new_step')"
                            v-model="editedStep.targetStepId"
                          >
                            <el-option
                              v-for="(item, index) in toShow"
                              class="select-primary"
                              :value="item.stepNumber"
                              :label="
                                index + from + 1 + ' - ' + item.stepDescription
                              "
                              :key="item.stepNumber"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          style="max-width: 220px"
                          :label="$t('test_run_steps.action_to_perform')"
                          prop="Action"
                        >
                          <el-select
                            class="select-danger"
                            placeholder="Actions"
                            v-model="editedStep.action"
                          >
                            <el-option
                              v-for="option in stepActionMap"
                              class="select-danger"
                              :value="option.value"
                              :label="option.label"
                              :key="option.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-row :gutter="20">
                          <el-col :span="6">
                            <el-form-item
                              style="max-width: 220px"
                              :label="$t('test_run_steps.selector_type')"
                              prop="prop3"
                            >
                              <el-select
                                class="select-danger"
                                :placeholder="
                                  $t('test_run_steps.choose_selector_type')
                                "
                                v-model="editedStep.selectorType"
                              >
                                <el-option
                                  v-for="option in stepSelectorList"
                                  class="select-danger"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="18">
                            <el-form-item
                              style="max-height: 50px"
                              :label="$t('test_run_steps.selector_to_use')"
                            >
                              <el-input
                                style="height: 90px"
                                type="text"
                                id="stepSelector"
                                name="stepSelector"
                                v-model="editedStep.selector"
                                :placeholder="
                                  $t('test_run_steps.enter_selector')
                                "
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-form-item :label="$t('test_run_steps.input_value')">
                          <el-input
                            type="text"
                            id="stepInputValue"
                            name="stepInputValue"
                            v-model="editedStep.inputValue"
                            :placeholder="$t('test_run_steps.enter_value')"
                          ></el-input>
                        </el-form-item>
                        <div
                          class="edit-accordion-btns"
                          style="display: flex; gap: 5px"
                        >
                          <div>
                            <button
                              class="cancel_button"
                              @click="adding = false"
                            >
                              {{ $t("test_run_steps.cancel") }}
                            </button>
                          </div>
                          <div>
                            <button class="add_button" native-type="submit">
                              {{ $t("test_run_steps.save") }}
                            </button>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <draggable
              :list="toShow"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              @start="dragging = true"
              @end="dragging = false"
              :move="updateReorder"
              handle=".drag-only-row"
            >
              <transition-group class="trsl--parent custom-trsl--parent">
                <el-card
                  v-for="(row, index) in toShow"
                  :key="row.$id"
                  class="trsl--elt"
                  shadow="hover"
                >
                  <el-row
                    type="flex"
                    justify="space-between"
                    class="el--flex drag-only-row"
                    :gutter="20"
                  >
                    <el-col :span="3" class="trsl--img">
                      <img
                        v-if="row.initialPageScreenshot"
                        @click="
                          previewPicture(
                            `data:image/png;base64,${row.initialPageScreenshot}`,
                            row
                          )
                        "
                        :src="`data:image/png;base64,${row.initialPageScreenshot}`"
                      />
                      <img
                        v-else-if="
                          row.targetElement && row.targetElement.step_pagescr
                        "
                        @click="
                          previewPictureWithoutSelector(
                            `data:image/png;base64,${row.targetElement.step_pagescr}`
                          )
                        "
                        :src="`data:image/png;base64,${row.targetElement.step_pagescr}`"
                      />
                    </el-col>
                    <el-col :span="1" class="trsl--txt">
                      <p>
                        {{ row.$id + from + 1 }}
                      </p>
                    </el-col>
                    <el-col :span="15" class="trsl--txt">
                      <div class="step-description">
                        <span> {{ row.stepDescription }}</span>
                        <div
                          v-if="row.selector"
                          class="only-selector"
                          @click="
                            (row.showSelector = !row.showSelector),
                              (row.isExpendit = !row.isExpendit)
                          "
                        >
                          <el-tooltip
                            v-if="(row.showSelector, row.isExpendit)"
                            class="item expendit"
                            content="Hide selecStor"
                            placement="top"
                          >
                            <span class="mark"> {{ row.selector }} </span>
                          </el-tooltip>
                          <el-tooltip
                            v-else
                            class="item"
                            content="Show selector"
                            placement="top"
                          >
                            <span class="empty--selector">...</span>
                          </el-tooltip>
                        </div>
                      </div>
                    </el-col>
                    <div
                      class="el-col flex btns-gap"
                      style="margin-right: 20px"
                      v-if="
                        (isSharing === 'true' &&
                          sharedJSON.scopes.includes('shared-edit')) ||
                        isSharing === null
                      "
                    >
                      <el-tooltip
                        :content="$t('test_run_steps.edit')"
                        placement="top"
                      >
                        <el-button
                          type="primary"
                          icon="el-icon-edit"
                          @click.stop="handleAddEditStepModal(row, index, true)"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>

                      <el-tooltip
                        :content="$t('test_run_steps.add')"
                        placement="top"
                      >
                        <el-button
                          type="success"
                          icon="el-icon-plus"
                          @click.stop="
                            handleAddEditStepModal(row, index, false)
                          "
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>

                      <el-tooltip
                        :content="$t('test_run_steps.delete')"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="deleteTestStep(row)"
                          circle
                          plain
                          size="small"
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </el-row>

                  <!-- Edit Step Start -->
                  <!-- <div class="edit-accordion" v-show="index === selectedItemId">
                    <div class="row" style="margin-top: 1rem;">
                      <div class="col">
                        <div class="mod--content">
                          <el-form @submit.native.prevent="addTestStepAction()" v-loading="stepLoading">
                            <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.action_to_perform')" prop="Action">
                              <el-select class="select-danger" placeholder="Actions" v-model="editedStep.action">
                                <el-option v-for="option in stepActionMap" class="select-danger" :value="option.value"
                                  :label="option.label" :key="option.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-row :gutter="20">
                              <el-col :span="6">
                                
                                <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.selector_type')" prop="prop3">
                                  <el-select class="select-danger" :placeholder="
                                    $t('test_run_steps.choose_selector_type')
                                  " v-model="editedStep.selectorType">
                                    <el-option v-for="option in stepSelectorList" class="select-danger"
                                      :value="option.value" :label="option.label" :key="option.label">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="18">
                                <el-form-item :label="$t('test_run_steps.selector_to_use')" style="max-height: 50px;">
                                  <el-input style="height:90px" type="text" id="stepSelector" name="stepSelector"
                                    v-model="editedStep.selector" :placeholder="
                                      $t('test_run_steps.enter_selector')
                                    "></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          
                            <div style="display: flex;gap:5px;margin-bottom: 0.5rem;">
              <el-form-item :label="$t('test_run_steps.input_value')">
                <el-input type="text" id="stepInputValue" name="stepInputValue" v-model="editedStep.inputValue"
                  :placeholder="$t('test_run_steps.enter_value')" class="input-drag"></el-input>
              </el-form-item>
              <el-form-item :label="$t('test_run_steps.enable_step')" prop="prop6">
                <el-switch v-model="toggleStepValue" active-color="#1F213E" inactive-color="#759DCB"
                  @change="toggleStep(row)"></el-switch>
              </el-form-item>
            </div>

                            <div class="row">
                              <div class="col">
                                <div class="edit-accordion-btns" style="display:flex;gap:5px">
                                  <div>
                                    <button class="cancel_button" @click="selectedItemId = null">
                                      {{ $t("test_run_steps.cancel") }}
                                    </button>
                                  </div>
                                  <div>
                                    <button class="add_button" native-type="submit">
                                      {{ $t("test_run_steps.save") }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </el-form>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- Edit Step End -->
                </el-card>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>

    <el-pagination
      v-if="!startLoading"
      background
      class=""
      layout="prev, pager, next"
      :total="total"
      :page-size="perPage"
      :page-sizes="pageSizes"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
    />

    <div>
      <modal class="small-modal left-footer" :show.sync="addEditStep">
        <template slot="header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
        </template>
        <div style="width: 100%">
          <div>
            <el-form
              v-loading="stepLoading"
              @submit.native.prevent="addTestStepAction()"
            >
              <el-form-item
                v-if="editedIndex === -1"
                :label="$t('test_run_steps.insert_new_step')"
                prop="existing step"
              >
                <el-select
                  class="select-primary"
                  :placeholder="$t('test_run_steps.new_step')"
                  v-model="editedStep.targetStepId"
                >
                  <el-option
                    v-for="(item, index) in toShow"
                    class="select-primary"
                    :value="item.stepNumber"
                    :label="index + from + 1 + ' - ' + item.stepDescription"
                    :key="item.stepNumber"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="edit-body-modal-grid">
                <el-form-item
                  :label="$t('test_run_steps.action_to_perform')"
                  prop="Action"
                >
                  <el-select
                    class="input-width-tall"
                    popper-class="custom-dropdown-popup"
                    placeholder="Actions"
                    v-model="editedStep.action"
                  >
                    <el-option
                      v-for="option in stepActionMap"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-col :span="6">
                  <el-form-item
                    :label="$t('test_run_steps.selector_type')"
                    prop="prop3"
                  >
                    <el-select
                      class="select-danger"
                      :placeholder="$t('test_run_steps.choose_selector_type')"
                      v-model="editedStep.selectorType"
                    >
                      <el-option
                        v-for="option in stepSelectorList"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="18">
                  <el-form-item :label="$t('test_run_steps.selector_to_use')">
                    <el-input
                      class="input-width-tall"
                      type="text"
                      id="stepSelector"
                      name="stepSelector"
                      v-model="editedStep.selector"
                      :placeholder="$t('test_run_steps.enter_selector')"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <div>
                  <el-form-item :label="$t('test_run_steps.input_values')">
                    <el-input
                      class="input-width-tall"
                      type="text"
                      id="stepInputValue"
                      name="stepInputValue"
                      v-model="editedStep.inputValue"
                      :placeholder="$t('test_run_steps.enter_value')"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-form-item class="enable-switch-cont" prop="prop6">
                <div><h3 class="text-title">Enable Step</h3></div>
                <el-tooltip content="Enable Step">
                  <el-switch
                    v-model="toggleStepValue"
                    active-color="#1F213E"
                    inactive-color="#759DCB"
                    @change="toggleStep(row)"
                  ></el-switch>
                </el-tooltip>
              </el-form-item>
              <div class="edit-accordion-btns">
                <button class="add_button" native-type="submit">
                  {{ modalActionText }}
                </button>
                <button class="cancel_button" @click="addEditStep = false">
                  Cancel
                </button>
              </div>
            </el-form>
          </div>
        </div>
      </modal>
    </div>

    <div class="video-modal-container photo-modal">
      <modal :show.sync="viewPicture">
        <template slot="header">
          <h5 class="modal-title"></h5>
        </template>
        <img
          v-if="testStepBigSS === ''"
          src="../../../assets/images/loader.gif"
          alt="Loading"
          width="50px"
          height="50px"
          class="gif-img"
        />
        <template v-else>
          <template v-if="isMultiple">
            <div class="mr-2">
              <img :src="testStepBigSS[0]" alt="Preview Image" class="p--img" />
            </div>
            <div>
              <img :src="testStepBigSS[1]" alt="Preview Image" class="p--img" />
            </div>
          </template>
          <img v-else :src="testStepBigSS" alt="Preview Image" class="p--img" />
        </template>
      </modal>
      <modal
        :show.sync="showTestEdit"
        modal-classes="modal-danger"
        modal-content-classes="bg-gradient-danger"
      >
        <h6 slot="header" class="modal-title">Your attention is required</h6>

        <div class="py-3 text-center">
          <i class="ni ni-bell-55 ni-3x"></i>
          <h4 class="heading mt-4">You should read this!</h4>
          <p>
            A small river named Duden flows by their place and supplies it with
            the necessary regelialia.
          </p>
        </div>

        <template slot="footer">
          <base-button type="white">Ok, Got it</base-button>
          <base-button
            type="link"
            class="text-white ml-auto"
            @click="modals.notice = false"
            >Close</base-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "testRunSteps",
  components: { draggable },
  props: {
    items: {
      type: Object,
      default: undefined,
    },
    toggleTestBtn: Function,
  },
  data() {
    return {
      isExpendit: false,
      stepLoading: false,
      editedStep: {
        targetStepId: undefined,
        selector: undefined,
        selectorType: undefined,
        action: "",
        inputValue: "",
      },
      newStep: {
        targetStepId: undefined,
        selector: undefined,
        selectorType: undefined,
        action: "",
        inputValue: "",
      },
      stepActionMap: {
        url_open: {
          value: "url_open",
          label: "Navigate to page at given URL",
          requiresValue: true,
          requiresSelector: false,
        },
        dismiss_next_alert: {
          value: "dismiss_next_alert",
          label: "Click 'Cancel' on next alert popup",
          requiresValue: false,
          requiresSelector: false,
        },
        accept_next_alert: {
          value: "accept_next_alert",
          label: "Click 'OK' on next alert popup",
          requiresValue: false,
          requiresSelector: false,
        },
        frame_select: {
          value: "frame_select",
          label: "Switch to another frame in the webpage",
          requiresValue: false,
          requiresSelector: true,
        },
        click: {
          value: "click",
          label: "Click on a page element",
          requiresValue: false,
          requiresSelector: true,
        },
        double_click: {
          value: "double_click",
          label: "Double click on a page element",
          requiresValue: false,
          requiresSelector: true,
        },
        context_click: {
          value: "context_click",
          label: "Right click on a page element",
          requiresValue: false,
          requiresSelector: true,
        },
        mouse_over: {
          value: "mouse_over",
          label: "Hover mouse over a page element",
          requiresValue: false,
          requiresSelector: true,
        },
        send_keys: {
          value: "send_keys",
          label: "Type text into a form element",
          requiresValue: true,
          requiresSelector: true,
        },
        edit_content: {
          value: "edit_content",
          label: "Edit content of a page element (for rich text editor fields)",
          requiresValue: false,
          requiresSelector: true,
        },
        form_select: {
          value: "form_select",
          label: "Choose an option from a select control element",
          requiresValue: false,
          requiresSelector: true,
        },
        form_submit: {
          value: "form_submit",
          label: "Submit form on page",
          requiresValue: false,
          requiresSelector: true,
        },
        verify_text: {
          value: "verify_text",
          label: "Verify that an element on page contains the given text",
          requiresValue: true,
          requiresSelector: true,
        },
        pause: {
          value: "pause",
          label: "Pause for the given interval (in milliseconds)",
          requiresValue: true,
          requiresSelector: false,
        },
      },
      stepSelectorList: [
        { value: "css selector", label: "CSS Selector" },
        { value: "xpath", label: "Xpath" },
      ],
      showTestEdit: false,
      filteredResults: [],
      query: null,
      isMultiple: false,
      sharedJSON: undefined,
      isSharing: "false",
      dataView: "thumb",
      stepSelector: null,
      stepInputValue: null,
      toggleStepValue: null,
      loading: true,
      testStepBigSS: "",
      stepIdForInsertingNewStep: "",
      selectedItemId: null,
      editAccordionForStep: "false",
      selectorDetails: [],
      toggleEditStepModal: false,
      enabled: true,
      dragging: false,
      total: 0,
      perPage: 5,
      currentPage: 1,
      pageSizes: [5, 10, 25],
      toShow: [],
      to: 0,
      from: 0,
      addId: -1,
      adding: false,
      viewPicture: false,
      requestingForData: true,
      startLoading: true,
      activeItem: "test_run_steps",
      //For Test Enhancer
      RLJson: {},
      RLtestcase: {
        sequence: {},
      },
      RLdatafile: {},
      id: "",
      TestEnhancerItems: "",
      testH: {},
      newTest: {},
      editedTest: {},
      editedIndex: -1,
      addEditStep: false,
    };
  },
  beforeCreate() {
    this.startLoading = true;
  },
  methods: {
    //#region helpers
    selectorInfo(steps_row) {
      let steps = steps_row;
      let indexToTruncate = steps.indexOf(":");
      let textWithSelectorInfo = steps.slice(indexToTruncate + 1);
      return textWithSelectorInfo;
    },
    processCheck() {
      if (
        this.editedStep.targetStepId === undefined ||
        this.editedStep.action === undefined
      ) {
        return "Action and target step cannot be empty";
      }

      if (!(this.editedStep.action in this.stepActionMap)) {
        return `Unrecognized action: ${this.editedStep.action}`;
      }

      const actionLabel = this.stepActionMap[this.editedStep.action]["label"];

      if (
        this.editedStep.action &&
        this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresSelector"] &&
        this.editedStep.selector === undefined
      ) {
        return `Selector type and selector input can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action &&
        this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresSelector"] &&
        this.editedStep.selector &&
        this.editedStep.selector.trim().length == 0
      ) {
        return `Selector type and selector input can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action &&
        this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresValue"] &&
        this.editedStep.inputValue === undefined
      ) {
        return `Input value can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action &&
        this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresValue"] &&
        this.editedStep.inputValue &&
        this.editedStep.inputValue.trim().length == 0
      ) {
        return `Input value can't be empty for action: ${actionLabel}`;
      }

      return false;
    },
    formatISODateTime(val) {
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let momentDt = moment(val);
      let day_display = "";
      let time_display = "";

      if (time_zone.toLowerCase().includes("america")) {
        day_display = momentDt.format("MMM Do YYYY");
        time_display = momentDt.format("LT");
      } else {
        day_display = momentDt.format("Do MMM YYYY");
        time_display = momentDt.format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    formatDate(val) {
      let day_display = "";
      let time_display = "";
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (time_zone.toLowerCase().includes("america")) {
        day_display = moment(val * 1000).format("MMM Do YYYY");
        time_display = moment(val * 1000).format("LT");
      } else {
        day_display = moment(val * 1000).format("Do MMM YYYY");
        time_display = moment(val * 1000).format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    saveDataView(val) {
      this.dataView = val;
      sessionStorage.setItem("test-data-view", this.dataView);
    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    toggleTestButton(a) {
      // This function is conditionally rendering variable buttons on tabs card
      this.toggleTestBtn(a);
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.toggleTestButton(this.activeItem);
    },
    handleAddEditStepModal(elt, id, isEdit) {
      this.editedIndex = isEdit === false ? -1 : 0;
      if (elt) {
        this.selectedItemId = this.selectedItemId !== id ? id : null;
        this.toggleStepValue = elt.isActive;
        this.editedStep = Object.assign(
          {},
          {
            targetStepId: elt.stepId,
            selector: elt.selector,
            selectorType: elt.selectorType,
            action: elt.action,
            inputValue: elt.inputValue,
          }
        );
      }
      this.addEditStep = true;
    },
    openEditAccordion(id, row) {
      this.adding = false;
      this.editedIndex = id;
      this.selectedItemId = this.selectedItemId !== id ? id : null;
      this.toggleStepValue = row.isActive;
      this.editedStep = Object.assign(
        {},
        {
          targetStepId: row.stepId,
          selector: row.selector,
          selectorType: row.selectorType,
          action: row.action,
          inputValue: row.inputValue,
        }
      );
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      this.editedIndex = -1;
      this.selectedItemId = -1;
      this.editedStep = Object.assign({}, this.newStep);
      await this.getTestRunSteps();
      this.from = (val - 1) * this.perPage;
      this.to = parseInt(this.from) + parseInt(this.perPage);
    },
    async handleSizeChange(val) {
      this.perPage = val;
      await this.handleCurrentChange(this.currentPage);
    },
    handleAddStep(id, row) {
      this.selectedItemId = null;
      this.editedIndex = -1;
      this.stepIdForInsertingNewStep = row.stepId;
      this.adding = !this.adding;
      this.editedStep = Object.assign({}, this.newStep);
      const _par = document.querySelector(".trsl--parent");
      if (this.adding) {
        if (_par.children.length === this.perPage) {
          const _addForm = document.getElementById("add-form");
          _addForm.classList.add("trsl--to_remove");
          _par.insertBefore(_addForm, _par.children[id + 1]);
        } else {
          const _addForm = document.querySelector(".trsl--to_remove");
          _par.removeChild(_addForm);
          _par.insertBefore(_addForm, _par.children[id + 1]);
        }
      }
    },
    //#endregion

    //#region async
    async updateReorder(evt) {
      const stepId = evt.draggedContext.element.stepId;
      const newStepNumber = evt.draggedContext.futureIndex + this.from + 2;
      // const newStepNumber = evt.draggedContext.element.$id + this.from ;
      let requestId = this.items.requestId;
      const payload = {
        stepEdits: [
          {
            op: "reorder",
            stepId: stepId,
            newStepNumber: Number(newStepNumber),
          },
        ],
      };

      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 300);
        };
        await axios
          .put(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/steps/${requestId}`,
            payload
          )
          .then((response) => {
            if (
              response.data.status === 200 ||
              response.data.status === "success"
            ) {
              this.getTestRunSteps();

              this.$notify({
                type: "success",
                message: `${response.data.message}`,
              });
            } else {
              this.getTestRunSteps();

              this.$notify({
                type: "danger",
                message: `${response.data.message}`,
              });
            }
          });
      } catch (error) {
        if (error.response) {
          this.$notify({
            type: "danger",
            message: `${error.response.statusText}`,
          });
        }
      }
    },
    async toggleStep(row) {
      let requestId = this.items.requestId;
      console.log("ROW", row);
      const payload = {
        stepEdits: [
          {
            op: "toggle_active",
            stepId: row.stepId,
            isActive: this.toggleStepValue,
          },
        ],
      };
      try {
        await axios
          .put(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/steps/${requestId}`,
            payload
          )
          .then((response) => {
            this.$notify({
              type: "success",
              message: `${response.data.message}`,
            });
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `${error.response.message}`,
        });
      }
    },
    async addTestStepAction() {
      const _mess = this.processCheck();
      console.log(_mess);
      if (_mess !== false) {
        return this.$notify({
          type: "danger",
          message: _mess,
        });
      }

      this.stepLoading = true;

      let data = Object.assign({}, this.editedStep);
      let payload = undefined;
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/testrun/v1/steps/${this.items.requestId}`;

      // handle missing arguments
      if (this.editedIndex < 0) {
        payload = { newTestSteps: [this.editedStep] };

        if (payload.newTestSteps[0].selector === undefined) {
          payload.newTestSteps[0]["selector"] = null;
        }
        if (payload.newTestSteps[0].selectorType === undefined) {
          payload.newTestSteps[0]["selectorType"] = null;
        }
        if (payload.newTestSteps[0].inputValue === undefined) {
          payload.newTestSteps[0]["inputValue"] = null;
        }

        // figure out the targetStepId based on the value in the add-form
        payload.newTestSteps[0]["targetStepId"] = "end";
        try {
          console.log("PAYLOAD", payload);
          console.log("URI", uri);
          const res = await axios.post(uri, payload);
          console.log("RES", res);
          if (res.status === 200) {
            this.addEditStep = false;
            this.$notify({
              type: "success",
              message: res.data.message,
            });
            this.adding = false;
            this.editedStep = Object.assign({}, this.newStep);
            await this.getTestRunSteps();
          } else {
            this.$notify({
              type: "danger",
              message: `An error has ocurred:`,
            });
            this.adding = false;
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: `An error has ocurred: ${res.response.data.message}`,
          });
          this.adding = false;
        }
      } else {
        data.op = "edit";
        data.stepId = data.targetStepId;
        delete data.targetStepId;
        payload = { stepEdits: [data] };

        if (payload.stepEdits[0].selector === undefined) {
          payload.stepEdits[0]["selector"] = null;
        }
        if (payload.stepEdits[0].selectorType === undefined) {
          payload.stepEdits[0]["selectorType"] = null;
        }
        if (payload.stepEdits[0].inputValue === undefined) {
          payload.stepEdits[0]["inputValue"] = null;
        }

        const res = await axios.put(uri, payload);
        if (res.status === 200) {
          this.addEditStep = false;
          this.$notify({
            type: "success",
            message: "This step is edited successfully",
          });
          this.selectedItemId = null;
          this.editedStep = Object.assign({}, this.newStep);
          await this.getTestRunSteps();
        } else {
          this.$notify({
            type: "danger",
            message: `An error has ocurred: ${res.response.data.message}`,
          });
        }
      }

      this.editedStep = Object.assign({}, this.newStep);
      this.selectedItemId = null;
      this.stepLoading = false;
    },
    async deleteTestStep(row) {
      let requestId = this.items.requestId;
      const confirmation = await swal.fire({
        title:
          "Run step: '" +
          row.stepNumber +
          ". " +
          row.stepDescription +
          "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (requestId && confirmation.value === true) {
        try {
          await axios
            .delete(
              process.env.VUE_APP_API_URL_PREFIX +
                `/testrun/v1/steps/${requestId}/${row.stepId}`
            )
            .then((response) => {
              if (response.data.status === "success") {
                this.$notify({
                  type: "success",
                  message: `${response.data.message}`,
                });
                this.getTestRunSteps();
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Delete Unsuccessful`,
          });
        }
      }
    },
    async getTestRunSteps() {
      console.log("apicall", this.items);
      if (this.items) {
        if (this.items.requestId) {
          const params = new URLSearchParams();

          params.append("selectorDetails", 1);
          params.append("selectorScreenshots", 1);
          params.append("page", this.currentPage);
          params.append("count", this.perPage);

          const uri = `${process.env.VUE_APP_API_URL_PREFIX}/testrun/v1/steps/${this.items.requestId}/?${params}`;
          const res = await axios.get(uri);
          if (res.status === 200) {
            this.total = res.data.totalSteps;
            this.to = this.perPage;
            this.toShow = res.data.response.map((elt, id) => {
              return { ...elt, $id: id, showSelector: false };
            });
          } else {
            this.$notify({
              type: "danger",
              message: `Oops, Cannot load run steps!`,
            });
          }
        }
        this.requestingForData = false;
        this.startLoading = false;
      }
    },

    async previewPictureWithoutSelector(src) {
      this.viewPicture = true;
      this.isMultiple = false;
      this.testStepBigSS = src;
    },
    async previewPicture(src, row) {
      this.testStepBigSS = "";
      this.viewPicture = true;
      let requestId = this.items.requestId;
      let requestType = "";
      let selectorType = "";
      if (row.requestType.includes("-initial")) {
        requestType = "train";
      } else if (row.requestType.includes("-followup")) {
        requestType = "heal";
      } else if (row.requestType.includes("-suggest")) {
        requestType = "suggest";
      } else {
        requestType = "";
      }
      if (row.selectorType === "css selector") {
        selectorType = "css";
      } else {
        selectorType = "xpath";
      }
      if (this.items.requestId) {
        try {
          await axios
            .get(
              process.env.VUE_APP_API_URL_PREFIX +
                `/results/v1/${requestType}/${selectorType}/${row.selectorId}?screenshots=annotated&walServerRequestId=${requestId}&sentAt=${row.sentAt}`
            )
            .then((response) => {
              if (response.data) {
                if (
                  response.data.processingType === "xpath-heal" ||
                  response.data.processingType === "css-heal"
                ) {
                  this.isMultiple = true;
                  this.testStepBigSS = [];
                  this.testStepBigSS.push(
                    response.data.initialItem.screenshotB64
                  );
                  this.testStepBigSS.push(
                    response.data.followupItem.screenshotB64
                  );
                } else {
                  this.isMultiple = false;
                  this.testStepBigSS = response.data.screenshotB64;
                }
              }
              this.loading = false;
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Oops, cannot load preview picture !`,
          });
        }
      }
    },
    //#endregion
  },

  computed: {
    imageSrc() {
      return this.$store.state.imageSrc;
    },
    modalTitle() {
      return this.editedIndex === -1 ? "Add Step" : "Edit the step";
    },
    modalActionText() {
      return this.editedIndex === -1
        ? this.$t("test_run_steps.save")
        : this.$t("test_run_steps.update");
    },
  },

  async created() {
    if (window.window.innerWidth >= 801) {
      this.dataView =
        sessionStorage.getItem("test-data-view") !== null
          ? sessionStorage.getItem("test-data-view")
          : "thumb";
    } else {
      this.dataView = "card";
    }
    await Promise.all([this.getTestRunSteps()]);

    const walToken = localStorage.getItem("vue-authenticate.access_token");
    this.isSharing = localStorage.getItem("vue-authenticate.is_sharing");
    this.sharedJSON = Object.assign({}, this.parseJwt(walToken));
  },
};
</script>

<style lang="scss" scopped>
$pColor: #5e72e4;
$txtColor: #4b4b71;
.icon-views {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 10px;
  background-color: #d6d6d6;
  border-radius: 10px;
}
.card-no-mr {
  margin: 0 !important;
  border-bottom: 0 !important;
}
.step-description span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-col-18,
.el-col-6 {
  width: 100%;
}
.tab--btn {
  padding: 0.8rem 0.6rem;
  border: none;
  border: solid 0.1px rgba($pColor, 0.3);
  color: rgba($pColor, 0.8);
  background: transparent;

  span {
    font-size: 0.9rem !important;
    font-weight: 600;
    color: rgba($pColor, 0.8);
  }

  &:hover {
    background: rgba($pColor, 0.05);
  }

  &.active {
    border-color: var(--main-blue-color);
    background: var(--main-blue-color);
    color: #ffffff;

    span {
      color: #ffffff;
    }
  }

  &.secondary {
    border: solid 0.1px rgba(gray, 0.3);
    color: rgba(gray, 0.8);

    span {
      color: gray;
    }

    &:hover {
      background: rgba(gray, 0.05);
    }

    &.active {
      background: var(--main-blue-color);
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }
  }
}
.expendit {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  width: fit-content;
}
.custom-card-details {
  padding: 0;
}
.text-btns-container-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(171, 171, 171);
  padding: 5px 0 5px 5px;
}

.mod--content .el-form-item .el-input input {
  height: 43px !important;
}
.cursor--zoom {
  cursor: zoom-in;
}
.photo-modal .modal-dialog .modal-content {
  width: 70% !important;
  text-align: center !important;
  margin: 0 auto !important;
}

@media (max-width: 535px) {
  .adpt--page-details {
    display: none !important;
  }
}

.video-modal-container .modal-dialog-centered {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 !important;
}

.video-modal-container .modal-dialog .modal-content {
  text-align: center !important;
  margin: 0 auto !important;
  width: 90%;
}

.video-modal-container .modal-dialog .modal-content .modal-header .close span {
  font-size: 3rem !important;
}

.video-modal-container .modal-dialog .modal-body {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.request-data-loading {
  display: flex;
  justify-content: center;
}

.p--img {
  width: 100%;
}

.italic {
  font-style: italic;
}

.text-small {
  font-size: 0.85rem;
}

.bold {
  font-weight: 600;
  line-height: 0.9rem;
}

.el--flex {
  align-items: center;
}

.trs--container {
  padding: 10px 0;
}

.btn--container {
  margin: 20px 0;
  height: 70px;

  .btn--content {
    float: right;
    border: solid 0.1px rgba($pColor, 0.3);

    .el-card__body {
      padding: 10px !important;

      * {
        font-size: 0.8rem !important;
      }
    }
  }
}

.trs--list {
  .trsl--elt {
    margin: 10px 0;
    cursor: grab;

    p {
      font-weight: normal;
      //line-height: 1.5rem;
    }

    .trsl--img {
      img {
        max-width: 100%;
        display: inline-block;
        border-radius: 0.3rem;
        cursor: zoom-in;
      }
    }

    .trsl--txt {
      margin-top: 20px;
    }

    .trsl--btn_container {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button + .el-button {
        margin-left: 0 !important;
      }

      button {
        width: 70px;
        padding: 8px;
        font-size: 0.7rem;
      }
    }
  }
}
.custom-trsl--parent {
  overflow-y: scroll;
  height: 41vh;
}
.custom-trsl--parent .el-card__body {
  background-color: #e6e6e6;
  padding-top: 5px;
  padding-bottom: 5px;
}
.enable-switch-cont .el-form-item__content {
  margin: 10px 0 10px 0;
  align-items: center;
  display: flex;
}
.enable-switch-cont .el-switch {
  margin-left: 12px;
}
.enable-switch-cont {
  margin-bottom: 0;
}
.edit-body-modal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 22px;
}
.edit-body-modal-grid .el-form-item {
  margin-bottom: 0;
}

.edit-body-modal-grid .el-form-item__label {
  margin: 12px 0 5px 0 !important;
  color: var(--main-blue-color) !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.custom-trsl--parent .el-card {
  background-color: #e6e6e6;
  border-bottom: 1px solid rgba(82, 95, 127, 0.25);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-radius: 0;
}

.mod--content {
  .el-form-item {
    label {
      margin: 0;
      color: rgba(black, 1);
      font-weight: 700;
    }

    .el-input {
      input {
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid #dbdbdb;
      }

      &.no-border {
        input {
          border: none;
          color: black;
          font-weight: 600;
        }
      }
    }

    .el-textarea {
      textarea {
        border: 1px solid #ccc;
        background-color: #ccc;
        color: #6e6e6e;
      }

      &.no-border {
        textarea {
          border: none;
          color: $txtColor;
          font-weight: 600;
        }
      }
    }
  }

  &.lite {
    label {
      font-weight: 600;
      font-size: 0.7rem;
      line-height: unset;
    }

    .el-input {
      input {
        font-size: 0.75rem;
        border-color: rgba($txtColor, 0.3);
      }
    }

    .el-textarea {
      textarea {
        font-size: 0.75rem;
        border-color: rgba($txtColor, 0.3);
      }
    }
  }
}
.step-description {
  margin-bottom: 1.2rem;
  overflow-x: hidden;
  display: flex;
}
.step-description span {
  padding-top: 5px;
  word-break: normal;
  white-space: nowrap;
  font-size: 14px;
  color: var(--main-blue-color);
  font-weight: 400;
  // display: block;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // width: calc(80% - 10px);
}

// .step-description span:hover {
//   overflow: visible;
//   display: inline-block;
//   white-space: normal;
//   text-overflow: clip;
// }

.ellipsis-dots {
  cursor: pointer;
  margin-left: 0.5rem;
  color: red !important;
  font-weight: bold;
}
.link-card {
  margin-left: 0;
  .mark {
    background-color: var(--card-bg);
    border-color: var(--card-bg);
    padding-left: 0 !important;
  }
}
.only-selector {
  display: inline-block;
  width: 100%;
  overflow-y: hidden;

  .mark {
    color: #53568a;
    border-radius: 0.15rem;
    font-size: 0.65rem;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
  }

  .empty--selector {
    color: var(--main-blue-color);
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: help;
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
}
.el-button--primary.is-plain,
.el-button--success.is-plain,
.el-button--danger.is-plain,
.el-button--primary.is-plain:hover,
.el-button--success.is-plain:hover,
.el-button--danger.is-plain:hover,
.el-button--primary.is-plain:focus,
.el-button--success.is-plain:focus,
.el-button--danger.is-plain:focus {
  background-color: #d6d6d6;
  font-size: 20px !important;
  font-weight: 600;
  color: var(--main-blue-color) !important;
  border: 1px solid #d6d6d6;
}
.edit-accordion-btns {
  gap: 5px;
  display: inline-flex;
}
.left-footer .modal-footer {
  align-items: start;

  justify-content: start;
}
.edit-accordion-btns {
  margin-top: 0;
}
</style>
