<template>
  <div class="container-fluid list-item-page mt-4">
    <div
      class="c--breadcrumb space-between"
      :class="{ to_move: $sidebar.isMinimized }"
    >
      <div>
        <router-link to="/details/test-explorer/list-tests"
          ><i class="el-icon-house"></i
        ></router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link to="/details/project-explorer/list-projects">
          Apps
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link
          :to="`/details/project-explorer/view-project/${$route.params.id}`"
        >
          Live events
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <span>Details of mobile session</span>
      </div>

      <div>
        <el-button
          class=" "
          circle
          type="primary"
          @click="downloadTestFile"
          icon="el-icon-download"
        >
        </el-button>

        <!-- <el-tooltip content="Add to test suite" placement="top" effect="light">
          <el-button
            icon="el-icon-plus add_icon"
            class="ml-3 large_btn custom_btn"
            type="primary"
            size="default"
            @click="getFiletest('run'), (addTestModal = true)"
            circle
          >
          </el-button>
        </el-tooltip> -->
      </div>
    </div>
    <template>
      <div class="">
        <div class="tblb--row mb-2" v-if="loadingSessionDetails">
          <div
            class="tblb--inner"
            style="width: 100%; text-align: center; background: #ffff"
          >
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 100px; width: 100px"
            />
          </div>
        </div>
        <template v-else>
          <div class="top-bar mt-3">
            <div class="topbar-left">
              <el-button
                type="primary"
                icon="el-icon-arrow-left"
                :disabled="prevPageButtonDisabled"
                @click="changeEventPage(-1)"
              >
                Previous page
              </el-button>
            </div>
            <div class="topbar-middle">
              Events {{ this.eventStart }} to {{ this.pageEventCount }} of
              {{ this.totalEventCount }}
            </div>
            <div class="topbar-right">
              <el-button
                type="primary"
                icon="el-icon-arrow-right"
                :disabled="nextPageButtonDisabled"
                @click="changeEventPage(1)"
              >
                Next page
              </el-button>
            </div>
          </div>
          <div class="session-player-body pt-4" style="overflow: hidden">
            <div class="mr-2 rrwew-content">
              <div class="screenshot-container">
                <img
                  :src="loadedScreenshotSrc"
                  :style="loadedScreenshotStyle"
                  alt="Screenshot"
                />
              </div>
              <div class="screenshot-controls mx-1">
                <div class="screenshot-controls-left">
                  <el-button
                    type="primary"
                    icon="el-icon-arrow-left"
                    :disabled="prevEventButtonDisabled"
                    @click="changeSelectedEvent(-1)"
                  >
                    Previous event
                  </el-button>
                </div>
                <div class="screenshot-controls-right">
                  <el-button
                    type="primary"
                    icon="el-icon-arrow-right"
                    :disabled="nextEventButtonDisabled"
                    @click="changeSelectedEvent(1)"
                  >
                    Next event
                  </el-button>
                </div>
              </div>
            </div>
            <div class="container-pick">
              <div class="event--liste">
                <div
                  v-for="(event, idx) in sessionEvents"
                  :key="idx"
                  class="content"
                  :class="{ selectedEvent: event.isSelected }"
                  @click="startEvent(idx)"
                >
                  <div class="right--content">
                    <div class="time--tag">
                      <h4
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ eventStart + idx }}. {{ event.event }}
                      </h4>
                    </div>
                    <div>
                      <span
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ formatDate(event.created_at) }}
                      </span>
                    </div>
                    <div
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                      "
                    >
                      {{
                        event.attributes && event.attributes.element_id
                          ? event.attributes.element_id
                          : "no element ID"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {
  Button,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import moment from "moment-timezone";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      // event info
      sessionEvents: [],
      loadingSessionDetails: false,
      currentEventPage: 1,
      currentSelectedEventIndex: 0,
      totalEventCount: 0,
      totalEventPages: 0,
      perPageCount: 10,
      eventStart: 0,
      nextPageButtonDisabled: false,
      prevPageButtonDisabled: false,
      nextEventButtonDisabled: false,
      prevEventButtonDisabled: false,
      loadedScreenshotSrc: "/img/loading.gif",
      loadedScreenshotStyle: "height: 100px; width: 100px;",
      projectId: 0,
      sessionId: "",
      sessionData: {},
    };
  },
  async created() {
    await this.getSessionDetails(
      this.$route.params.id,
      this.$route.params.session_id
    );
    if (this.sessionEvents.length > 0) {
      this.loadScreenshot(0);
    }
  },
  computed: {
    pageEventCount() {
      if (this.totalEventCount < this.perPageCount) {
        return this.totalEventCount;
      } else {
        return (
          this.perPageCount * (this.currentEventPage - 1) +
          this.sessionEvents.length
        );
      }
    },
  },
  methods: {
    downloadTestFile() {
      const blob = new Blob([JSON.stringify(this.sessionData)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `generated-test-run-file-${this.sessionData.events[0].session_uid}.mobile`;
      link.click();
    },

    async getSessionDetails(projectId, sessionId) {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/mobile/events?projectId=${projectId}&sessionId=${sessionId}&screenshots=1&page=${this.currentEventPage}&count=${this.perPageCount}`;
      this.projectId = projectId;
      this.sessionId = sessionId;
      try {
        console.log("loading session details page 1");
        this.loadingSessionDetails = true;
        const resp = await axios.get(url);
        if (resp.data && resp.data.events) {
          this.sessionData = resp.data;
          this.sessionEvents = resp.data.events;
          this.sessionEvents.map((item) => {
            item.isSelected = false;
          });
          this.currentEventPage = resp.data.currentPage;
          this.totalEventCount = resp.data.totalEventCount;
          this.totalEventPages = resp.data.totalPages;
          this.perPageCount = resp.data.perPageCount;
          this.eventStart = 1;
          this.prevPageButtonDisabled = this.currentEventPage === 1;
          this.nextPageButtonDisabled =
            this.currentEventPage === this.totalEventPages;
        }
      } catch (err) {
        this.$notify({
          type: "danger",
          message: "Could not fetch any events for this session.",
        });
      } finally {
        console.log("done loading session details");
        this.loadingSessionDetails = false;
      }
    },
    loadScreenshot(screenshotIndex) {
      this.loadedScreenshotSrc = this.sessionEvents[screenshotIndex]
        .annotated_screenshot
        ? this.sessionEvents[screenshotIndex].annotated_screenshot
        : "/img/placeholder.jpg";
      this.loadedScreenshotStyle =
        "width: 100%; max-height: 700px; object-fit: contain;";
      this.currentSelectedEventIndex = screenshotIndex;
      this.prevEventButtonDisabled = this.currentSelectedEventIndex === 0;
      this.nextEventButtonDisabled =
        this.currentSelectedEventIndex === this.sessionEvents.length - 1;
      this.sessionEvents.map((item, index) => {
        item.isSelected = index === screenshotIndex;
      });
    },
    async changeEventPage(pageIncrement) {
      if (
        pageIncrement === 1 &&
        this.currentEventPage === this.totalEventPages
      ) {
        return;
      }
      if (pageIncrement === -1 && this.currentEventPage === 1) {
        return;
      }

      let goToPage;
      if (pageIncrement === 1) {
        goToPage = this.currentEventPage + 1;
      } else {
        goToPage = this.currentEventPage - 1;
      }

      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/mobile/events?projectId=${this.projectId}&sessionId=${this.sessionId}&screenshots=1&page=${goToPage}&count=${this.perPageCount}`;
      try {
        console.log(`loading session details page ${goToPage}`);
        this.loadingSessionDetails = true;
        const resp = await axios.get(url);
        if (resp.data && resp.data.events) {
          this.sessionEvents = resp.data.events;
          this.currentEventPage = resp.data.currentPage;
          this.prevPageButtonDisabled = this.currentEventPage === 1;
          this.nextPageButtonDisabled =
            this.currentEventPage === this.totalEventPages;
          if (this.currentEventPage === 1) {
            this.eventStart = 1;
          } else {
            this.eventStart =
              (this.currentEventPage - 1) * this.perPageCount + 1;
          }
          if (this.sessionEvents.length > 0) {
            this.loadScreenshot(0);
          }
        }
      } catch (err) {
        this.$notify({
          type: "danger",
          message: "Could not fetch any events for this page.",
        });
      } finally {
        console.log("done loading session details");
        this.loadingSessionDetails = false;
      }
    },
    changeSelectedEvent(selectedEventIncrement) {
      if (
        selectedEventIncrement === 1 &&
        this.currentSelectedEventIndex === this.perPageCount - 1
      ) {
        return;
      }
      if (
        selectedEventIncrement === -1 &&
        this.currentSelectedEventIndex === 0
      ) {
        return;
      }
      let goToEvent;
      if (selectedEventIncrement === 1) {
        goToEvent = this.currentSelectedEventIndex + 1;
      } else {
        goToEvent = this.currentSelectedEventIndex - 1;
      }
      this.loadScreenshot(goToEvent);
    },
    startEvent(index) {
      this.loadScreenshot(index);
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) === "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },
    formatDate(a) {
      const ltz = moment.tz.guess();
      return moment(a).tz(ltz).format("MMMM DD YYYY, h:mm:ss A z");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    },
  },
};
</script>

<style lang="scss" scoped>
$pColor: #525f7f;
$color: #5e72e4;

.space-between {
  display: flex;
  justify-content: space-between;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .topbar-left {
    max-width: 30%;
  }

  .topbar-right {
    max-width: 30%;
  }
}

.screenshot-container {
  height: 700px;
}

.screenshot-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .screenshot-controls-left {
    max-width: 30%;
  }

  .screenshot-controls-right {
    max-width: 30%;
  }
}

.selectedEvent {
  background: var(--primary);
  color: #fff;

  h4 {
    color: #fff;
  }
}

.selectedEvent:hover {
  background: var(--primary);
  color: #fff;

  .content {
    background: var(--primary);
    color: #fff;
  }

  h4 {
    color: #fff;
  }
}

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #6e6e6e;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px #222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px #222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  //color: rgb(94, 114, 228);
  color: #e6e6e6;
  font-weight: bold;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  display: inline-block;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#25a18e, 0.75);
  background: rgba(#25a18e, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #25a18e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 50%;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container
  .edit-test-select
  .el-select
  .el-input
  .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  // border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
  }

  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;

      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #e0e0e0;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}

.sessionEvent-detail-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }

  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}

.showCoveringtests-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}

.swal2-popup #swal2-title {
  color: var(--main-blue-color) !important;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block !important;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}

.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;

  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}

.ultra--ipt_ipt {
  width: 100%;
}

.ultra--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}

.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}

.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}

.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}

.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}

.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}

.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}

.container--event--liste {
  margin: 0 0 0 30px;
  padding: 8px;
  width: 100%;
  border: solid 1px #c6c6c6;
  border-radius: 5px;
  max-height: 656px;
  overflow: hidden;
}

.event--liste {
  max-height: 700px;
  overflow-x: auto;
  overflow-y: auto;
}

.event--liste::-webkit-scrollbar {
  display: none;
}

.content {
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}

.active--event {
  background-color: #e8eeff;

  .icon--event {
    background-color: white;

    i {
      color: #ffff;
    }
  }
}

.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}

.mod--content {
  margin-bottom: 30px;
}

.session-player-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .rrwew-content {
    width: 100%;
    flex: 1;
    min-width: 1px;
    position: relative;
    background: #ffff;
    padding: 10px;
    border-radius: 5px;
  }

  .container-pick {
    position: relative;
    min-height: 1px;
    min-width: 450px;
    background: #ffff;
    padding: 10px;
    border-radius: 5px;
  }
}
</style>
