<template>
  <div>
    <div style="display: flex">
      <!-- <LeftSideBar>
        <div class="custom-btn-group align-items-center">
          <el-button
            @click="showComponent('overview')"
            icon="el-icon-pie-chart"
            class="tab--btn button-menu button-menu-item truncate"
            :class="{ active: showOverview }"
          >
            <p style="display:inline; line-height: 0.7;">{{ $t("view_item_page.overview") }}</p>
          </el-button>
          <el-button
            @click="showComponent('suggestions')"
            icon="el-icon-sunrise-1"
            class="tab--btn button-menu button-menu-item truncate"
            :class="{ active: showSuggestions }"
          >
            <p style="display:inline; line-height: 0.7;">{{ $t("view_item_page.advanced") }}</p>
          </el-button>
          <el-button
            @click="showComponent('testenhancer')"
            icon="el-icon-s-ticket"
            class="tab--btn button-menu button-menu-item truncate"
            :class="{ active: showTestEnhancer }"
          >
            <p style="display:inline;line-height: 0.7;">{{ $t("view_item_page.enhancer") }}</p>
          </el-button>
          <el-tooltip content="Show Guide Line" placement="top" effect="light">
            <el-button icon="el-icon-question" class="btn-guide--line" type="info" size="default" circle @click="showGuide()"></el-button>
          </el-tooltip>
        </div>
      </LeftSideBar> -->
      <div style="overflow-y: hidden" class="right-content">
        <div>
          <!-- <div
                class="c--breadcrumb adaptive--breadcrumb my-3"
                style="width: auto"
              >
                <router-link to="/details/list-tests"
                  ><i class="el-icon-house"></i
                ></router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <router-link to="/details/list-tests">{{
                  $t("view_item_page.test_explorer")
                }}</router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <span v-if="items?.runName">{{
                  items.runName.split("").length > 100
                    ? shortName
                    : items.runName
                }}</span>
                <span v-else class="s--icon"
                  ><i class="el-icon-loading"></i
                ></span>
              </div> -->
          <div class="custom-header">
            <h3 class="page_name">Overview</h3>
          </div>
          <div class="mid--page" style="align-items: center">
            <div>
              <div style="border-bottom: 0px" class="custom_btns">
                <button
                  :class="
                    showOverview ? 'add_button_menu' : 'add_button_not_active'
                  "
                  @click="showComponent('overview')"
                >
                  {{ $t("view_item_page.overview") }}
                </button>
                <button
                  :class="
                    showSuggestions
                      ? 'add_button_menu'
                      : 'add_button_not_active'
                  "
                  @click="showComponent('suggestions')"
                >
                  {{ $t("view_item_page.advanced") }}
                </button>
                <button
                  :class="
                    this.showTestEnhancer
                      ? 'add_button_menu'
                      : 'add_button_not_active'
                  "
                  @click="showComponent('testenhancer')"
                >
                  {{ $t("view_item_page.enhancer") }}
                </button>
                <!-- <el-tooltip content="Show Guide Line" placement="top" effect="light">
            <el-button icon="el-icon-question" class="btn-guide--line" type="info" size="default" circle @click="showGuide()"></el-button>
          </el-tooltip> -->
              </div>
            </div>

            <div class="mp--r">
              <!-- <div
                class="c--breadcrumb adaptive--breadcrumb"
                style="width: auto"
              >
                <router-link to="/details/test-explorer/list-tests"
                  ><i class="el-icon-house"></i
                ></router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <router-link to="/details/test-explorer/list-tests">{{
                  $t("view_item_page.test_explorer")
                }}</router-link>
                <span
                  ><i class="el-icon-d-arrow-right mx-1 cb--divider"></i
                ></span>
                <span v-if="items?.runName">{{
                  items.runName.split("").length > 100
                    ? shortName
                    : items.runName
                }}</span>
                <span v-else class="s--icon"
                  ><i class="el-icon-loading"></i
                ></span>
              </div> -->
            </div>
            <div class="mp--l btns-gap">
              <div
                v-show="showOverview"
                class="fl--center btns-gap"
                id="fl-center"
              >
                <el-button
                  icon="el-icon-tickets"
                  style="margin-right: 5px !important; font-size: 18px"
                  @click="sharedTestRun()"
                  class="tab--btn mr-2 test-buttons-triple custom--btn-dropdown"
                  v-if="shareTest !== 'unshare'"
                >
                  <!-- <el-tooltip
                    :content="$t('view_item_page.view_websocket_logs')"
                    placement="top"
                  >
                    <small>
                      <i class="fas fa-code"></i>
                    </small>
                  </el-tooltip> -->
                </el-button>
                <button
                  v-if="items?.status === 'processing'"
                  @click="openWebSocketModal()"
                  class="ic--btn custom--btn-dropdown test-buttons-triple el-dropdown-selfdefine"
                >
                  <el-tooltip
                    :content="$t('view_item_page.view_websocket_logs')"
                    placement="top"
                  >
                    <small>
                      <i class="fas fa-code"></i>
                    </small>
                  </el-tooltip>
                </button>
                <button
                  v-if="showRunnerLogButton || items?.status !== 'processing'"
                  @click="openScriptModal()"
                  class="tab--btn adpt--btn test-buttons-triple custom--btn-dropdown ni-terminal"
                >
                  <el-tooltip
                    :content="$t('view_item_page.view_runner_log')"
                    placement="top"
                  >
                  </el-tooltip>
                </button>
              </div>
              <!-- Overview -->

              <div v-show="showSuggestions" class="down-btn-container">
                <span class="mr-2">
                  <!-- <base-dropdown
                    v-if="
                      items.runOptions &&
                      (items.runOptions.test_run_origin === 'submitted-katalon-xml' ||
                        items.runOptions.test_run_origin === 'submitted-selenium-ide')
                    "
                  > -->
                  <!-- <div class="subdownbtns px-2"> -->

                  <!-- </div> -->
                  <!-- </base-dropdown> -->
                </span>
              </div>
              <!-- Suggestions -->

              <div v-show="showTestEnhancer" class="down-btn-container">
                <button
                  v-show="lookedSalesforceIntegration"
                  @click="openListSalesforceVariables()"
                  class="ic--btn mr-2"
                  id="btnListSalesForce"
                >
                  <el-tooltip content="Import Salesforce Data" placement="top">
                    <i class="fa fa-cloud" aria-hidden="true"></i>
                  </el-tooltip>
                </button>
                <!-- <button @click="downloadEnhancerReport()" class="ic--btn mr-2">
                  <el-tooltip content="Download Test Enhancer Report" placement="top">
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </el-tooltip>
                </button> -->
                <button
                  @click="openListFileVariables()"
                  class="tab--btn adpt--btn test-buttons-triple custom--btn-dropdown folder-blue-custom"
                >
                  <el-tooltip
                    :content="$t('view_item_page.file_variables')"
                    placement="top"
                    id="btnListFileVariables"
                  >
                  </el-tooltip>
                </button>
                <button
                  @click="openVariables()"
                  class="tab--btn adpt--btn test-buttons-triple custom--btn-dropdown brackets-icon"
                  id="btnOpenVariables"
                >
                  <el-tooltip content="Variables" placement="top"> </el-tooltip>
                </button>
                <button
                  @click="showRunSteps()"
                  class="tab--btn adpt--btn test-buttons-triple custom--btn-dropdown arrow-right-icon"
                  id="btnShowRunSteps"
                >
                  <el-tooltip
                    :content="$t('view_item_page.Run_the_simulator')"
                    placement="top"
                  >
                  </el-tooltip>
                </button>
              </div>
              <!-- Test Enhancer end-->

              <div class="rerun-container" v-show="showOverview">
                <el-button
                  @click="showRerunModalWithCurrentSteps()"
                  class="tab--btn ml-2 adpt--btn custom--btn-dropdown test-buttons-triple ni-refresh"
                  id="reRunBtnModal"
                >
                </el-button>
                <el-button
                  style="
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  @click="handleShare('testrun')"
                  class="tab--btn adpt--btn custom--btn-dropdown test-buttons-triple ni-share"
                  id="runTestBtn"
                >
                </el-button>
              </div>
              <!-- Rus steps end-->
            </div>
          </div>
          <div class="second-card">
            <div class="row">
              <div
                class="w-100"
                id="content"
                :style="{
                  overflowY:
                    showTestEnhancer || showSuggestions ? 'hidden' : 'scroll',
                }"
              >
                <div v-if="showSuggestions" :key="items" class="mx-3">
                  <suggestions ref="suggestions" :items="items">
                    <div
                      class="is-flex"
                      style="
                        justify-content: right;
                        flex-direction: row;
                        gap: 5px;
                      "
                    >
                      <button
                        v-if="items?.status === 'processing'"
                        @click="openWebSocketModal()"
                        class="ic--btn custom--btn-dropdown test-buttons-triple el-dropdown-selfdefine"
                      >
                        <el-tooltip
                          :content="$t('view_item_page.view_websocket_logs')"
                          placement="top"
                        >
                          <small>
                            <i class="fas fa-code"></i>
                          </small>
                        </el-tooltip>
                      </button>
                      <div class="container--buttons">
                        <el-dropdown
                          trigger="click"
                          type="primary"
                          class=""
                          id="btnDownloadTests"
                        >
                          <button
                            class="ic--btn custom--btn-dropdown test-buttons-triple ni-download"
                            id="btnDownloadSccripts"
                          >
                            <el-tooltip
                              :content="$t('view_item_page.download_scripts')"
                              placement="top"
                            >
                            </el-tooltip>
                          </button>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                v-if="
                                  items?.runOptions &&
                                  items?.runOptions.test_run_origin ===
                                    'submitted-selenium-ide'
                                "
                                @click="downloadTestFile('JSON')"
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  content="Download wringr script file"
                                  placement="top"
                                >
                                  <img
                                    src="../../../assets/images/favicon.png"
                                    alt="Observer script file"
                                  />
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                v-if="
                                  items?.runOptions &&
                                  (items?.runOptions.test_run_origin ===
                                    'submitted-katalon-xml' ||
                                    items?.runOptions.test_run_origin ===
                                      'submitted-selenium-ide')
                                "
                                class="ic--btn ml-2 mr-2 mb-2"
                                @click="
                                  downloadTestFile('generated-pypom-script')
                                "
                              >
                                <el-tooltip
                                  :content="
                                    $t(
                                      'view_item_page.download_selenium_script'
                                    )
                                  "
                                  placement="top"
                                >
                                  <img
                                    src="../../../assets/images/selenium.png"
                                    alt="Selenium"
                                  />
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                v-if="
                                  items?.runOptions &&
                                  (items?.runOptions.test_run_origin ===
                                    'submitted-katalon-xml' ||
                                    items?.runOptions.test_run_origin ===
                                      'submitted-selenium-ide')
                                "
                                @click="
                                  downloadTestFile('generated-sensetalk-script')
                                "
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  :content="
                                    $t(
                                      'view_item_page.download_generated_sensetalk'
                                    )
                                  "
                                  placement="top"
                                >
                                  <img
                                    src="../../../assets/images/eggplant_logo.svg"
                                    alt="Sensetalk"
                                  />
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                v-if="
                                  items?.runOptions &&
                                  (items?.runOptions.test_run_origin ===
                                    'submitted-katalon-xml' ||
                                    items?.runOptions.test_run_origin ===
                                      'submitted-selenium-ide')
                                "
                                @click="
                                  downloadTestFile('generated-cypress-script')
                                "
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  :content="
                                    $t(
                                      'view_item_page.download_generated_cypress'
                                    )
                                  "
                                  placement="top"
                                >
                                  <img
                                    src="../../../assets/images/cypress_blue.png"
                                    alt="Katalon"
                                  />
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <!-- <el-dropdown-item class="custom--dropdown--item">
                              <button
                                @click="downloadTestFile('csv')"
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  :content="
                                    $t('view_item_page.download_csv_report')
                                  "
                                  placement="top"
                                >
                                  <i class="fas fa-file-csv"></i>
                                </el-tooltip>
                              </button>
                            </el-dropdown-item> -->
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                @click="downloadTestFile('report-pdf')"
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  :content="
                                    $t('view_item_page.download_pdf_report')
                                  "
                                  placement="top"
                                >
                                  <i
                                    class="fa fa-file-pdf"
                                    aria-hidden="true"
                                  ></i>
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <el-dropdown-item class="custom--dropdown--item">
                              <button
                                v-if="
                                  items?.runOptions &&
                                  items?.runOptions.test_run_origin ===
                                    'submitted-katalon-xml'
                                "
                                @click="downloadTestFile('katalonxml')"
                                class="ic--btn ml-2 mr-2 mb-2"
                              >
                                <el-tooltip
                                  content="Download your original Katalon test script XML"
                                  placement="top"
                                >
                                  <img
                                    src="../../../assets/images/Katalon.png"
                                    alt="Katalon"
                                  />
                                </el-tooltip>
                              </button>
                            </el-dropdown-item>
                            <el-dropdown-item class="custom--dropdown--item">
                              <span v-if="items?.runTags">
                                <button
                                  v-if="
                                    items?.runTags[0] ===
                                    'suggested selectors verified'
                                  "
                                  @click="
                                    downloadTestFile('verified-cypress-script')
                                  "
                                  class="ic--btn ml-2 mr-2 mb-2"
                                >
                                  <el-tooltip
                                    :content="
                                      $t(
                                        'view_item_page.download_generated_cypress_verified'
                                      )
                                    "
                                    placement="top"
                                  >
                                    <img
                                      src="../../../assets/images/verified-icon-blue.png"
                                      alt="Verified"
                                    />
                                  </el-tooltip>
                                </button>
                              </span>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        <!-- <button
                          v-if="
                            showRunnerLogButton || items?.status !== 'processing'
                          "
                          @click="openScriptModal()"
                          class="ic--btn mr-2"
                          id="btnOpenScriptModal"
                        >
                          <el-tooltip
                            :content="$t('view_item_page.view_runner_log')"
                            placement="top"
                          >
                            <small>
                              <i class="fas fa-terminal"></i>
                            </small>
                          </el-tooltip>
                        </button> -->
                        <div
                          style="display: flex; gap: 5px"
                          v-show="showSuggestions"
                        >
                          <el-tooltip
                            :content="$t('view_item_page.rerun')"
                            placement="top"
                          >
                            <el-button
                              @click="showRerunModalWithCurrentSteps()"
                              class="ic--btn custom--btn-dropdown test-buttons-triple ni-refresh"
                              id="btnReRunModalCurrentSteps"
                            >
                              <!-- {{ $t("view_item_page.rerun") }} -->
                            </el-button>
                          </el-tooltip>
                          <el-tooltip
                            :content="$t('view_item_page.share')"
                            placement="top"
                          >
                            <el-button
                              @click="handleShare('testrun')"
                              class="ic--btn custom--btn-dropdown test-buttons-triple ni-share"
                              id="btnTestRunShare"
                            >
                              <!-- {{ $t("view_item_page.share") }} -->
                            </el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </suggestions>
                </div>

                <div
                  v-if="showTestEnhancer"
                  class="mx-3 mt-3 custom-tableTestEnc"
                  id="tableTestEnchacer"
                >
                  <test-enhancer
                    ref="testEnhancer"
                    :key="testEnhancer"
                    :get="get"
                    :id="id"
                    :loadingVar="loading2"
                    :loadingSalesforce="loadingSalesforce"
                    :RLJson="RLJson"
                    @delete-step="myButtonEventHandler"
                  ></test-enhancer>
                </div>

                <div v-if="showOverview" class="mx-3">
                  <overview
                    ref="overview"
                    :items="items"
                    :loading="loading"
                  ></overview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Console modal start -->
    <div class="console-modal-container">
      <modal :show.sync="showTestConsoleModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("view_item_page.test_runner_console_logs") }}
          </h5>
        </template>
        <div>
          <div class="console-modal">
            <div class="row">
              <div class="col">
                <div v-if="stdout" class="script-first">
                  <h2>{{ $t("view_item_page.console_log_for_stdout") }}</h2>
                  <div class="script">
                    <pre>{{ stdout }}</pre>
                  </div>
                </div>
                <div v-else class="script-first">
                  <h2>
                    {{ $t("view_item_page.no_logs_available_as_script") }}
                  </h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div v-if="stderr" class="script-second">
                  <div class="script-heading">
                    <h2>{{ $t("view_item_page.console_log_for_stderr") }}</h2>
                    <i
                      v-if="errCopied"
                      class="fa fa-check"
                      aria-hidden="true"
                      @click="errCopied = false"
                    ></i>
                    <i
                      v-else
                      class="ni ni-single-copy-04"
                      @click="copyStderr"
                    ></i>
                  </div>
                  <div class="script">
                    <pre>{{ renderStderr() }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Console modal end -->

    <!-- Websocket modal start -->
    <div class="websocket-modal-container">
      <modal :show.sync="showWebSocketLogsModal">
        <template slot="header">
          <h5 id="exampleModalLabel">
            {{ $t("view_item_page.websocket_live_logs") }}
          </h5>
        </template>
        <div>
          <div class="websocket-modal" v-if="webSocketServerData">
            <div class="row">
              <div class="col">
                <div
                  :class="[
                    {
                      'log-status-blink': webSocketLogStatus === 'processing',
                      'log-status': webSocketLogStatus !== 'processing',
                    },
                  ]"
                >
                  <h2 class="text-sm">
                    {{ $t("view_item_page.logs_status") }}
                    <mark v-if="webSocketLogStatus">{{
                      webSocketLogStatus
                    }}</mark>
                  </h2>
                </div>

                <div class="script-cont">
                  <div class="script" id="WebSocketLiveLogs">
                    <pre><div
                      v-for="(val, index) in webSocketServerData"
                      :key="index"
                    >{{ val }}</div></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Websocket modal end -->

    <div class="add-test-modal">
      <modal :show.sync="rerunTestStepModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("view_item_page.rerun_test_with") }}
          </h5>
        </template>
        <div>
          <div class="add-test-modal">
            <div class="run-name">
              <label class="model-label is-required" for="run_name">
                {{ $t("view_item_page.run_name") }}
              </label>
              <input
                type="text"
                id="run_name"
                name="run_name"
                :placeholder="$t('view_item_page.placeholder_run_name')"
                v-model="run_name"
              />
              <label class="model-label" for="run_description">{{
                $t("view_item_page.description")
              }}</label>
              <textarea
                id="run_description"
                name="run_description"
                rows="2"
                :placeholder="$t('view_item_page.placeholder_run_description')"
                v-model="run_description"
              ></textarea>
              <label class="model-label" for="run_name">
                {{ $t("view_item_page.run_tags") }}
              </label>
              <input
                type="text"
                id="run_tags"
                name="run_tags"
                :placeholder="$t('view_item_page.placeholder_run_tags')"
                v-model="run_tags"
              />
              <label class="model-label" for="run_name">
                Execution Cloud
              </label>
              <div style="flex-direction: row" class="is-flex">
                <el-radio
                  style="margin-right: 0"
                  class="custom-el-radio"
                  v-model="runServer"
                  label="wringr"
                  border
                  ><img
                    src="/favicon.png"
                    alt="Wring"
                    class="mr-1"
                    style="width: 18px"
                  />Wringr</el-radio
                >
                <el-tooltip
                  :disabled="sourceLabsIsIntegrate"
                  :content="`${
                    sourceLabsIsIntegrate === false
                      ? 'No Sauce Labs integration was found'
                      : ''
                  } `"
                  placement="top"
                >
                  <span>
                    <el-radio
                      class="custom-el-radio"
                      :disabled="!sourceLabsIsIntegrate"
                      v-model="runServer"
                      label="source"
                      border
                      ><img
                        src="/img/integrations/Saucelabs.png"
                        alt="Sauce Labs"
                        class="mr-1"
                        style="width: 18px"
                      />Sauce Labs</el-radio
                    >
                  </span>
                </el-tooltip>

                <el-tooltip
                  :disabled="awsIsIntegrate"
                  :content="`${
                    awsIsIntegrate === false
                      ? 'No AWS integration was found'
                      : ''
                  } `"
                  placement="top"
                >
                  <el-radio
                    :disabled="!awsIsIntegrate"
                    v-model="runServer"
                    class="custom-el-radio"
                    label="aws"
                    border
                    ><img
                      src="/img/integrations/aws.svg"
                      alt="AWS"
                      class="mr-1"
                      style="width: 20px"
                    />AWS</el-radio
                  ></el-tooltip
                >
              </div>
              <label
                v-show="runServer == 'source'"
                class="model-label"
                for="Sauce_Labs_Integration"
              >
                Sauce Labs Integration
              </label>
              <el-select
                v-show="runServer == 'source'"
                v-model="integrationSaurceLabsIndex"
                placeholder="Select Souce labs"
              >
                <el-option
                  v-for="(item, id) in integrationSaurceLabs"
                  :key="id"
                  :label="getLabelSauceLabs(item)"
                  :value="id"
                >
                </el-option>
              </el-select>

              <label
                v-show="runServer == 'aws'"
                class="model-label"
                for="aws_Integration"
              >
                AWS Integration
              </label>
              <el-select
                v-show="runServer == 'aws'"
                v-model="integrationAwsIndex"
                placeholder="Select AWS"
              >
                <el-option
                  v-for="(item, id) in integrationAws"
                  :key="id"
                  :label="getLabelAws(item)"
                  :value="id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="s-a-tabs">
              <ul class="nav nav-tabs nav-justified custom-nav-tabs">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('settings')"
                    :class="{ active: isActive('settings') }"
                    href="#home"
                    >{{ $t("view_item_page.settings") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('advanced')"
                    :class="{ active: isActive('advanced') }"
                    href="#profile"
                    >{{ $t("view_item_page.advanced") }}</a
                  >
                </li>
              </ul>

              <div class="tab-content py-3" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('settings') }"
                  id="settings"
                >
                  <div class="mt-2">
                    <h4 class="fbb">{{ $t("view_item_page.test_script") }}</h4>
                    <div class="three-input">
                      <div class="tic">
                        <label class="model-label" for="browser_width">{{
                          $t("view_item_page.browser_width")
                        }}</label>
                        <input
                          type="text"
                          id="browser_width"
                          name="browser_width"
                          placeholder="1920"
                          v-model="browser_width"
                        />
                      </div>
                      <div class="tic">
                        <label class="model-label" for="browser_height">{{
                          $t("view_item_page.browser_height")
                        }}</label>
                        <input
                          type="text"
                          id="fname"
                          name="browser_height"
                          placeholder="1980"
                          v-model="browser_height"
                        />
                      </div>

                      <div class="tic">
                        <label class="model-label" for="max_wait_time">
                          Max Wait Time
                        </label>
                        <input
                          type="text"
                          id="max_wait_time"
                          name="max_wait_time"
                          placeholder="5.0"
                          v-model="max_wait_time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('advanced') }"
                  id="advanced"
                >
                  <div class="modal-checks-container">
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_disabled_elements"
                        name="ignore_disabled_elements"
                        v-model="ignore_disabled_elements"
                      />
                      <label for="ignore_disabled_elements">{{
                        $t("view_item_page.ignore_disabled")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_invisible_elements"
                        name="ignore_invisible_elements"
                        v-model="ignore_invisible_elements"
                      />
                      <label for="ignore_invisible_elements">{{
                        $t("view_item_page.ignore_invisible")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="collect_page_interaction_data"
                        name="collect_page_interaction_data"
                        v-model="collect_page_interaction_data"
                      />
                      <label for="collect_page_interaction_data">{{
                        $t("view_item_page.collect_interaction")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="extra_element_data"
                        name="extra_element_data"
                        v-model="extra_element_data"
                      />
                      <label for="extra_element_data">{{
                        $t("view_item_page.collect_extra_element")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="all_suggest"
                        name="all_suggest"
                        v-model="all_suggest"
                      />
                      <label for="all_suggest">{{
                        $t("view_item_page.generate_selectors")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="downweight_strings"
                        name="downweight_strings"
                        v-model="downweight_strings"
                      />
                      <label for="downweight_strings">{{
                        $t("view_item_page.try_to_avoid")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="verify_suggested_selectors"
                        name="verify_suggested_selectors"
                        v-model="verify_suggested_selectors"
                      />
                      <label for="verify_suggested_selectors">{{
                        $t("view_item_page.verify_suggested")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="enable_integrations"
                        name="enable_integrations"
                        v-model="enable_integrations"
                      />
                      <label for="enable_integrations">{{
                        $t("view_item_page.trigger_integrations")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="generate_css_selectors"
                        name="generate_css_selectors"
                        v-model="generate_css_selectors"
                      />
                      <label for="generate_css_selectors">
                        Generate CSS selectors during run (slow, needed for
                        Cypress export)
                      </label>
                    </div>

                    <br />

                    <label class="model-label mt-3" for="testrun_start_url">
                      Environment start URL for test run
                    </label>
                    <input
                      id="testrun_start_url"
                      name="testrun_start_url"
                      placeholder="https://example.com"
                      v-model="testrun_start_url"
                    />

                    <label class="model-label" for="bad_attributes_list">{{
                      $t("view_item_page.avoid_attributes")
                    }}</label>
                    <textarea
                      id="bad_attributes_list"
                      name="bad_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_avoid_attributes')
                      "
                      v-model="bad_attributes_list"
                    ></textarea>

                    <label class="model-label" for="good_attributes_list">{{
                      $t("view_item_page.use_attributes")
                    }}</label>
                    <textarea
                      id="good_attributes_list"
                      name="good_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_use_attributes')
                      "
                      v-model="good_attributes_list"
                    ></textarea>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="continue_test_suite_on_fail"
                        name="continue_test_suite_on_fail"
                        v-model="continue_test_suite_on_fail"
                      />
                      <label for="continue_test_suite_on_fail">{{
                        $t("view_item_page.continue_test_suite")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="timeout_cancel_run"
                        name="timeout_cancel_run"
                        v-model="timeout_cancel_run"
                      />
                      <label for="timeout_cancel_run">{{
                        $t("view_item_page.timeout_and_cancel")
                      }}</label>
                      <input
                        class="mod-chk-last-input"
                        type="text"
                        id="timeout_cancel_run_value"
                        name="timeout_cancel_run_value"
                        v-model="timeout_cancel_run_value"
                        :placeholder="
                          $t('view_item_page.placeholder_timeout_and_cancel')
                        "
                      />
                      {{ $t("view_item_page.seconds") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              class="cancel_button"
              type="secondary"
              @click="rerunTestStepModal = false"
            >
              {{ $t("view_item_page.close") }}
            </button>
            <button
              class="add_button"
              type="primary"
              @click="rerunWithCurrentSteps()"
            >
              {{ $t("view_item_page.start_rerun") }}
            </button>
          </div>
        </template>
      </modal>
    </div>

    <div class="is-scrollable webhook--modal">
      <modal
        class="small-modal"
        :show.sync="toggleShareModal"
        v-loading="gettingShareUrl"
      >
        <template slot="header">
          <h5 class="modal-title">
            {{ $t("view_item_page.share_this_item") }}
          </h5>
        </template>
        <div class="">
          <div style="display: flex">
            <el-form
              class="small-spaces"
              @submit.native.prevent="processShare()"
            >
              <label class="mr-5">{{
                $t("view_item_page.share_this_item_gen")
              }}</label>
              <label>{{ $t("view_item_page.permissions") }}</label>
              <el-checkbox-group
                v-model="editedShareValue.permissions"
                size="small"
              >
                <el-checkbox-button
                  class="custom-checkbox-group"
                  v-for="elt in permissionOptions"
                  :label="elt.value"
                  :key="elt.id"
                  >{{ elt.label }}</el-checkbox-button
                >
              </el-checkbox-group>
              <div class="edit-accordion-btns mt-3 ml--2 mb-4">
                <button
                  class="add_button"
                  native-type="submit"
                  :disabled="!canGenerate"
                >
                  Generate URL
                </button>
              </div>
            </el-form>
            <div v-if="items?.sharing === undefined">
              <el-form
                class="small-spaces ml-2"
                @submit.native.prevent="processShare()"
              >
                <label class="mr-5">{{
                  $t("view_item_page.share_this_item_org")
                }}</label>
                <label>{{ $t("view_item_page.permissions") }}</label>
                <!-- <el-checkbox-group
                  v-model="editedShareValue.permissions"
                  size="small"
                >
                  <el-checkbox-button
                    v-for="elt in permissionOptions"
                    :label="elt.value"
                    :key="elt.id"
                    >{{ elt.label }}</el-checkbox-button
                  >
                </el-checkbox-group> -->
                <div style="display: flex; flex-direction: column; gap: 5px">
                  <el-radio
                    style="max-width: 260px"
                    v-model="shareTest"
                    class=""
                    label="share"
                    @change="toggleShareTest"
                    border
                    >Share this test run</el-radio
                  >
                  <el-radio
                    style="max-width: 260px"
                    v-model="shareTest"
                    class="mr-2 ml-0"
                    label="unshare"
                    @change="toggleShareTest"
                    border
                    >Unshare this test run</el-radio
                  >
                </div>
              </el-form>
            </div>
          </div>
        </div>

        <template v-if="shareableUrl.length > 0">
          <hr class="my--1" />

          <div class="url-alert-container">
            <div style="display: flex; align-items: center; gap: 10px">
              <el-input
                class="input-width-tall"
                :readonly="true"
                ref="input"
                placeholder="Shareable url value should be provided here ..."
                v-model="shareableUrl"
              >
              </el-input>
              <el-tooltip content="Copy trigger URL" placement="top">
                <span
                  class="h--copy"
                  style="cursor: pointer"
                  id="clipboard_copy"
                  data-copytarget="#exampleFormControlTextarea3"
                  @click="handleCopy2()"
                >
                  <i class="el-icon-copy-document"></i>
                </span>
              </el-tooltip>
            </div>

            <el-alert type="warning" show-icon class="mb-3">
              <template slot="title">
                Make sure to copy your <code>Shareable url</code> above
              </template>
            </el-alert>
          </div>
        </template>
      </modal>
    </div>
    <div class="is-scrollable">
      <modal class="small-modal" :show.sync="toShowSharedTest">
        <template slot="header" id="showSharedId">
          <h5 class="modal-title">
            {{ $t("view_item_page.shared_test_run") }}
          </h5>
        </template>

        <div class="">
          <div class="mod--content">
            <el-form class="small-spaces">
              <div>
                <label class="mr-2">Shared by: </label>
                <label> {{ dataMe !== "" ? dataMe.email : "No data" }} </label>
              </div>
              <div>
                <label class="mr-2">Permissions: </label>
                <label> Read-Only </label>
              </div>
              <div>
                <label class="mar-2">In organization: </label>
                <label>
                  {{ dataMe !== "" ? dataMe.organization[0] : "No data" }}
                </label>
              </div>
            </el-form>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import Suggestions from "./Suggestions";
import TestEnhancer from "./TestEnhancer";
import Overview from "./Overview";
import axios from "axios";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue";
import moment from "moment";
import "moment-timezone";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export var runActive = false;
export default {
  layout: "DashboardLayout",
  components: {
    Overview,
    TestEnhancer,
    Suggestions,
    BaseButton,
    [Select.name]: Select,
    [Option.name]: Option,
    LeftSideBar,
  },

  mixins: [formMixin],
  data() {
    return {
      // subscription plan info
      subUser: {},
      subPlan: {},
      // For rerun test with current steps start
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      testrun_start_url: "",
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      generate_css_selectors: false,
      enable_integrations: true,
      activeItem: "settings",
      // For rerun test with current steps end
      showRunnerLogButton: true,
      webSocketLogStatus: null,
      hideWebSocketLogBtn: true,
      webSocketServerData: [],
      showWebSocketLogsModal: false,
      rerunTestStepModal: false,
      videoSrc: "",
      errCopied: false,
      stdout: "",
      stderr: "",
      // customDropdown: "custom--dropdown",
      // expanderCustomDropdown: "custom--dropdown--expander",
      showTestConsoleModal: false,
      showVideoModal: false,
      runActive: this.runActive,
      overviewPieChart: {
        title: {
          text: "Time saved chart",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["Enhancements", "Auto Heal"],
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 335,
                name: "Enhancments",
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 310,
                name: "Auto Heal",
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
      showSeleniumIcons: false,
      shareableUrl: "",

      log: console.log,
      file: "",
      loading: true,
      showOverview: true,
      showSuggestions: false,
      showTestRunSteps: false,
      showTestEnhancer: false,
      showAllSuggest: false,
      toggleAddStepModal: false,
      toggleReorderStepModal: false,
      date_at: new Date(),
      items: {},
      RLJson: {},
      RLtestcase: {
        sequence: {},
        variables: {},
      },
      RLdatafile: {},
      rl_sequence: {},
      id: "",
      enabled: true,
      list: [
        { name: "Lorem", id: 0 },
        { name: "Ipsum", id: 1 },
        { name: "Dolor", id: 2 },
      ],
      newShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      editedShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      permissionOptions: [
        { label: this.$t("view_item_page.read_only"), value: "read" },
        {
          label: this.$t("view_item_page.edit_item_properties"),
          value: "edit",
        },
        { label: this.$t("view_item_page.rerun_this_item"), value: "rerun" },
      ],
      dragging: false,
      canGenerate: true,
      showTestButtons: "test_run_steps",
      disableDwnCoverageBtn: true,
      toggleShareModal: false,
      gettingShareUrl: false,
      lookedSalesforceIntegration: false,
      loading2: false,
      loadingSalesforce: false,
      page_num: 1,
      page_size: 5,
      total_pages: 0,
      total_steps: 0,
      toShowSharedTest: false,
      visibilityShare: {},
      shareTest: "unshare",
      dataMe: "",
      runServer: "wring",
      integrationData: [],
      integrationSaurceLabs: [],
      sourceLabsIsIntegrate: false,
      integrationSaurceLabsIndex: 0,
      shortName: 0,
      awsIsIntegrate: false,
      integrationAwsIndex: [],
      integrationAws: [],
      testEnhancer: 0,
    };
  },
  methods: {
    //#region helpers
    getLabelSauceLabs(item) {
      let tl = item.unique_integration_identifier.split(",");
      if (tl[1] && item.sauceLabs_it_name && item.sauceLabs_platforms_type)
        return `${item.sauceLabs_it_name} ( ${
          item.sauceLabs_platforms_type
        } ) ${item.sauceLabs_username} ${this.formatDateSauceLbs(Date(tl[1]))}`;
      return item.sauceLabs_username;
    },
    getLabelAws(item) {
      let tl = item.unique_integration_identifier.split(",");

      return `${item.integration_details.aws_it_name} ( ${
        item.integration_details.aws_platforms_type
      } ) ${this.formatDateSauceLbs(Date(tl[1]))}`;
    },
    async populateIntegrationsTable() {
      this.integrationSaurceLabs = [];
      this.integrationAws = [];

      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", {
          params: {
            request_type: "all",
          },
        })
        .then((response) => {
          if (
            response.message != "Request failed with status code 404" ||
            response.message === "Request failed with status code 500"
          ) {
            this.integrationData = response.data.user_integrations;
            this.integrationData.map((item) => {
              if (item.integration_name == "sauceLabs") {
                this.sourceLabsIsIntegrate = true;
                this.integrationSaurceLabs.push(item);
              }
              if (item.integration_name == "aws") {
                this.awsIsIntegrate = true;
                this.integrationAws.push(item);
              }
            });
          } else {
            this.integrationData = [];
            this.sourceLabsIsIntegrate = false;
            this.integrationSaurceLabs = [];
            this.integrationAws = [];
          }
        })
        .finally(() => {
          if (this.integrationData && this.integrationData.length > 0) {
            this.integrationData[0];
          }
        });
    },
    formatDateSauceLbs(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    cleanSelectPlatform() {
      if (this.appType == "web") {
        this.selectPlatform = ["Windows 11"];
        const capitalizeFirstLetter = (string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        };
        const userAgent = navigator.userAgent;
        const browserVersion = userAgent.match(
          /(opera|chrome|safari|firefox|edge(?=\/))\/?\s*(\d+)/i
        );
        const browser = browserVersion[1];
        this.selectBrowser = [capitalizeFirstLetter(browser)];
      } else {
        this.browserVersion = [];
        this.selectPlatform = ["Android Simulator"];
      }
    },
    handleCopy2() {
      const ipt = this.$refs["input"].$el.children[0];
      ipt.select();
      document.execCommand("copy");
      this.$notify({
        type: "success",
        message: "Copied to the Clipboard!",
      });
    },
    handleShare(val) {
      this.shareableUrl = "";
      this.editedShareValue.shareableItem = val;
      this.editedShareValue.shareableId = this.id;
      this.toggleShareModal = true;
      this.canGenerate = true;
      this.getSharedTestRun();
    },
    openWebSocketModal() {
      this.showWebSocketLogsModal = true;
    },
    toggleTestBtn(a) {
      this.showTestButtons = a;
    },
    showRerunModalWithCurrentSteps() {
      this.rerunTestStepModal = true;
      const currentTest = this.items.runOptions;
      this.run_name = currentTest.testSuiteId
        ? currentTest.testSuiteName
        : currentTest.test_run_name;
      this.run_description = currentTest.test_run_description;
      this.ignore_disabled_elements = currentTest.filter_disabled_elements;
      this.ignore_invisible_elements = currentTest.filter_invisible_elements;
      this.run_tags = currentTest.runTags
        .filter((item) => {
          return item !== "test run retry" && item !== "integration enabled";
        })
        .join(", ");

      this.extra_element_data = currentTest.extra_element_data;
      this.all_suggest = currentTest.allsuggest_enabled;
      this.collect_page_interaction_data = currentTest.use_tg_analyze;
      this.handle_failure = currentTest.handle_failure;
      this.enable_integrations = currentTest.runTags.includes(
        "integration enabled"
      );
      this.downweight_strings = !!currentTest["xpath_downweight_strings"];
      this.verify_suggested_selectors =
        !!currentTest["run_selector_verification"];
      this.testrun_start_url = currentTest["testrun_start_url"];
      this.generate_css_selectors = currentTest["generate_css_selectors"];
      this.bad_attributes_list = currentTest["bad_attribute_list"];
      this.good_attributes_list = currentTest["good_attribute_list"];
      if (
        currentTest["user_set_timeout"] !== null &&
        currentTest["user_set_timeout"] !== undefined
      ) {
        this.timeout_cancel_run = true;
        this.timeout_cancel_run_value = currentTest["user_set_timeout"];
      }
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    renderStderr() {
      let txt = this.stderr;
      for (var i = 0; i < txt.length; i++) {
        if (txt.slice(i, i + 5) == "[INFO") {
          // console.log(txt.slice(i, i + 5));
        }
      }
      return txt;
    },
    getOverview() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    formatRequired(row, col, value, index) {
      if (value) {
        return "Y";
      } else {
        return "N";
      }
    },
    goBack() {
      this.$router.push({
        name: "List Items",
      });
    },
    showComponent(name) {
      this.showOverview = false;
      this.showTestRunSteps = false;
      this.showTestEnhancer = false;
      this.showSuggestions = false;
      this.showAllSuggest = false;
      if (this.items !== undefined) {
        if (this.items?.allSuggestResults) {
          this.showAllSuggest = true;
        }
      }

      if (name === "overview") {
        this.showOverview = true;
      } else if (name === "suggestions") {
        this.showSuggestions = true;
      } else if (name === "testenhancer") {
        this.showTestEnhancer = true;
        this.setSalesforceVar();
      }
    },
    removeImage() {
      this.file = null;
    },
    openScriptModal() {
      this.showTestConsoleModal = true;
    },
    showGuide() {
      if (this.showOverview) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#fl-center",
              popover: {
                title: "Console Log",
                description:
                  "Access all console logs generated during the test steps from this section.",
                side: "left",
                align: "start",
              },
            },
            {
              element: "#reRunBtnModal",
              popover: {
                title: "Rerun Test",
                description:
                  "Re-execute the exact test by selecting this option.",
                side: "top",
                align: "start",
              },
            },
            {
              element: "#runTestBtn",
              popover: {
                title: "Share",
                description:
                  "Collaborate by sharing tests with your organization or colleagues.",
              },
            },
            {
              element: 'canvas[data-zr-dom-id="zr_0"]',
              popover: {
                title: "Execution Details",
                description:
                  "Access specific details related to the execution time from this section.",
              },
            },
            {
              element: "#videoPlayer",
              popover: {
                title: "Session Video",
                description:
                  "Access the video recording of the session you performed.",
              },
            },
          ],
        });
        driverObj.drive();
      } else if (this.showSuggestions) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#viewEditSteps",
              popover: {
                title: "View/Edit Steps ",
                description:
                  "Review and modify steps according to your preferences in this section.",
                side: "left",
                align: "start",
              },
            },
            {
              element: "#btnAdvanced",
              popover: {
                title: "Advanced Inspection",
                description:
                  "Delve into a more advanced inspection to explore all possible selectors.",
                side: "top",
                align: "start",
              },
            },
            {
              element: "#btnDownloadSccripts",
              popover: {
                title: "Download Tests",
                description:
                  "Retrieve and download your tests from this section.",
              },
            },
            {
              element: "#btnOpenScriptModal",
              popover: {
                title: "Console Log",
                description:
                  "Access all console logs generated during the test steps from this section.",
              },
            },
            {
              element: "#btnReRunModalCurrentSteps",
              popover: {
                title: "Rerun Test",
                description:
                  "Re-execute the exact test by selecting this option.",
              },
            },
            {
              element: "#btnTestRunShare",
              popover: {
                title: "Share",
                description:
                  "Collaborate by sharing tests with your organization or colleagues.",
              },
            },
            {
              element: ".trsl--parent",
              popover: {
                title: "Test Steps Overview",
                description:
                  "Explore a table providing an overview of your test steps.",
              },
            },
          ],
        });
        driverObj.drive();
      } else if (this.showTestEnhancer) {
        const driverObj = driver({
          showProgress: true,
          steps: [
            {
              element: "#btnShowSetup",
              popover: {
                title: "Expander Setup",
                description:
                  "Examine and manage individual steps within the expander.",
                side: "left",
                align: "start",
              },
            },
            {
              element: "#btnShowRuns",
              popover: {
                title: "Expander Runs",
                description:
                  "Access information and details regarding the runs executed by the expander.",
                side: "top",
                align: "start",
              },
            },
            {
              element: "#btnListFileVariables",
              popover: {
                title: "Add file variables",
                description:
                  "Integrate file variables into your system or application.",
              },
            },
            {
              element: "#btnShowVariables",
              popover: {
                title: "Add and Verify Variables",
                description:
                  "Incorporate variables into your system and ensure their accuracy and functionality through validation.",
              },
            },
            {
              element: "#btnOpenVariables",
              popover: {
                title: "Execute Tests",
                description:
                  "Trigger the execution of tests to assess the performance and functionality of your system or application.",
              },
            },
            {
              element: "#tableTestEnchacer",
              popover: {
                title: "Expander Table",
                description:
                  "Explore a table summarizing information and details related to the expander's operations and executions.",
              },
            },
          ],
        });
        driverObj.drive();
      }
    },
    copyStderr() {
      navigator.clipboard.writeText(this.stderr).then(() => {
        this.$notify({
          type: "success",
          message: "Copied Successfully.",
        });
      });
      this.errCopied = true;
    },
    async sharedTestRun() {
      this.toShowSharedTest = true;
      await this.getSharedTestRun();
      await this.getAuthMe();
    },
    async getAuthMe() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/auth/v1/users/me`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if (res.data.response) {
          console.log("response", res);
          this.dataMe = res.data.response;
        } else {
          //   this.$notify({
          //   type: "danger",
          //   duration: 5000,
          //   message: "No more details available for this state",
          // });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // process.env.VUE_APP_API_URL_PREFIX +
    async getSharedTestRun() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/share?item=${this.items.requestId}&type=testrun`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if (res.data.response) {
          console.log("response", res);
          this.visibilityShare = res.data.response.visibility;
          if (
            this.visibilityShare === null ||
            this.visibilityShare === "private"
          ) {
            this.shareTest = "unshare";
          } else {
            this.shareTest = "share";
          }
        } else {
          //   this.$notify({
          //   type: "danger",
          //   duration: 5000,
          //   message: "No more details available for this state",
          // });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async toggleShareTest() {
      console.log("visibility", this.shareTest);
      let url = `${process.env.VUE_APP_API_BASE_URL}/share`;
      let data = JSON.stringify({
        changes: [
          {
            itemType: "testrun",
            itemId: this.items.requestId,
            visibility: this.shareTest === "unshare" ? "private" : "shared_org",
          },
        ],
      });

      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.response) {
          console.log("res");
          this.$notify({
            type: "success",
            duration: 5000,
            message: "Successfully changed",
          });
        } else {
          //   this.$notify({
          //   type: "danger",
          //   duration: 5000,
          //   message: "No more details available for this state",
          // });
        }
      } catch (error) {
        console.log(error);
      }
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    setRunStatus(status) {
      this.item.runStatus = status;
    },
    //#endregion

    //#region async
    async initWebsocketStreaming() {
      const id = this.$route.params.id;
      const auth_token = localStorage.getItem("vue-authenticate.access_token");

      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/results/v1/logs/${id}`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/results/v1/logs/${id}`;
      }
      const socket = new WebSocket(url);

      const errorMsg = `ERROR while processing log stream for test request ID: ${id}. Internal server error.`;
      const errorMsg2 = `ERROR while processing log stream for test request ID: ${id}. This request ID does not exist or the log stream has expired.`;

      socket.onopen = function (e) {
        socket.send(`AUTH ${auth_token}`);
      };

      socket.onmessage = (event) => {
        if (event.data === errorMsg || event.data === errorMsg2) {
          socket.onclose = function (event) {
            console.log(
              "The connection has been closed successfully View item Page",
              event
            );
          };
          this.hideWebSocketLogBtn = false;
          this.showRunnerLogButton = true;
        } else {
          this.webSocketLogStatus = JSON.parse(event.data).status;
          JSON.parse(event.data).lines.map((item, index) => {
            this.webSocketServerData.push(item.line);
          });
          this.hideWebSocketLogBtn = true;
          this.showRunnerLogButton = false;
        }
      };
    },
    async processShare() {
      this.editedShareValue.permissions =
        this.editedShareValue.permissions.length > 0
          ? this.editedShareValue.permissions.join(",")
          : "";
      const url =
        this.editedShareValue.permissions.length > 0
          ? `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/share/${this.editedShareValue.shareableItem}/${this.editedShareValue.shareableId}?regenerate=${this.editedShareValue.regenerate}&permissions=${this.editedShareValue.permissions}`
          : `${process.env.VUE_APP_API_URL_PREFIX}/results/v1/share/${this.editedShareValue.shareableItem}/${this.editedShareValue.shareableId}?regenerate=${this.editedShareValue.regenerate}`;
      try {
        this.gettingShareUrl = true;
        await axios
          .get(url)
          .then((res) => {
            if (res.message === "Request failed with status code 404") {
              this.$notify({
                type: "danger",
                message: `An error has occured : ${res.message}`,
              });
            } else {
              if (res.data) {
                this.shareableUrl = res.data.response.shareUrl;
              }
            }
          })
          .catch((e) => {
            this.$notify({
              type: "danger",
              message: `An error has occured : ${e.message}`,
            });
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured : ${e.message}`,
        });
      } finally {
        this.canGenerate = false;
        this.gettingShareUrl = false;
        this.editedShareValue = Object.assign({}, this.newShareValue);
      }
    },
    async openVariables() {
      await this.$refs.testEnhancer.toggleVariableModal();
    },
    async downloadEnhancerReport() {
      await this.$refs.testEnhancer.startDownloadingEnhancerReport();
    },
    async openListFileVariables() {
      await this.$refs.testEnhancer.toggleFileVariableModal();
    },
    async openListSalesforceVariables() {
      await this.$refs.testEnhancer.toggleSalesforceVariableModal();
      await this.setSalesforceVar();
    },
    async showRunSteps() {
      await this.$refs.testEnhancer.showRunSteps();
    },
    async rerunWithCurrentSteps() {
      let requestId = this.items.requestId;

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }
      if (this.runServer != "wring") {
        parsedRunTags.push("sauce labs");
      }
      let reRunWithUpdatedFields = {
        runName: this.run_name,
        runDesc: this.run_description,
        runTags: parsedRunTags.join(","),
        browserWidth: this.browser_width,
        browserHeight: this.browser_height,
        stepWait: this.max_wait_time,
        ignoreInvisible: this.ignore_invisible_elements,
        ignoreDisabled: this.ignore_disabled_elements,
        slowHeal: this.extra_element_data,
        allSuggest: this.all_suggest,
        tgAnalyze: this.collect_page_interaction_data,
        runnerUserTimeout: this.timeout_cancel_run_value,
        runSelectorVerification: this.verify_suggested_selectors,
        badAttributeList: this.bad_attributes_list,
        goodAttributeList: this.good_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
      };
      if (this.runServer !== "wring") {
        reRunWithUpdatedFields = Object.assign({}, reRunWithUpdatedFields, {
          "X-Cloud-Browser":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_browsers,
          "X-Cloud-Browser-Version": "latest",
          "X-Cloud-Platform-Name":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_platforms,
          "X-Cloud-Username":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_username,
          "X-Cloud-Access-Key":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_accesstoken,
          "X-Cloud-Region":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_region,
          "X-Cloud-Type":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_type,
        });
      }
      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        reRunWithUpdatedFields["runStartUrl"] = this.testrun_start_url;
      }

      if (requestId) {
        try {
          await axios
            .post(
              process.env.VUE_APP_API_URL_PREFIX +
                `/testrun/v1/steps/${requestId}/redo`,
              reRunWithUpdatedFields
            )
            .then((response) => {
              if (response.data.requestId && response.status === 202) {
                this.$notify({
                  type: "success",
                  message: `Rerun of test '${this.run_name}' started successfully`,
                });
                this.rerunTestStepModal = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Rerun is unsuccessful`,
          });
        }
      }
    },
    async get() {
      try {
        this.loading2 = true;
        const id = this.$route.params.id;
        await this.$store.dispatch("items/get", id);
        this.items = this.$store.getters["items/item"];
        console.log("test runName", this.items);
        if (this.items !== undefined) {
          if (this.items.runName.split("").length > 100) {
            this.shortName = this.items.runName
              .split("")
              .slice(0, 100)
              .join("");
            this.shortName += "...";
            console.log("item", this.shortName);
          }
        }

        this.$nextTick(() => {
          this.$refs.suggestions && this.$refs.suggestions.getSuggestions();
          this.$refs.suggestions && this.$refs.suggestions.getAllSuggestions();
          this.$refs.testEnhancer && this.$refs.testEnhancer.initializeState();
        });
        if (this.items.subprocess) {
          this.stdout = this.items.subprocess.stdout;
          this.stderr = this.items.subprocess.stderr;
        }

        this.id = id;
        this.loading = false;
        this.loading2 = false;
        await this.getSharedTestRun();
      } catch (error) {
        this.loading = false;
        this.loading2 = false;
      } finally {
        this.loading = false;
        this.loading2 = false;
      }
    },
    async getSubscriptionInfo() {
      try {
        let resp = await axios.get(
          `${process.env.VUE_APP_API_URL_PREFIX}/subscription/v1/plan/user`
        );
        if (resp.status < 400) {
          this.subUser = resp.data.response.user;
          this.subPlan = resp.data.response.user.tierLimits;
        } else {
          console.log("Could not get subscription plan for user");
        }
      } catch (e) {
        console.log(
          "Error when trying to retrieving the subscription plan: ",
          e
        );
      }
    },
    async myButtonEventHandler() {
      console.log("emit");
      this.testEnhancer += 1;
      await this.forToggleCoverageReportBtn();
    },
    async forToggleCoverageReportBtn() {
      this.loading2 = true;
      this.loading = true;
      console.log("emit");
      const id = this.$route.params.id;
      const params = new URLSearchParams();

      params.append("page_num", this.page_num - 1);
      params.append("page_size", this.page_size);

      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/steps/${id}?${params}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.RLJson = res.data;
        this.RLtestcase = res.data.testcase;
        this.total_pages = res.data.total_pages;
        this.total_steps = res.data.total_steps;
        if (res.data.testcase) {
          if (typeof this.RLtestcase === "undefined") {
            this.RLtestcase = {
              sequence: {},
            };
          }
          this.loading2 = false;
        } else {
          this.disableDwnCoverageBtn = false;
        }
      } else {
        console.error("An error has occured");
      }
      this.loading2 = false;
    },
    async openVideoModal() {
      this.showVideoModal = true;
      const aioToken = localStorage.getItem("vue-authenticate.access_token");
      const requestId = this.items.requestId;
      let videoUrl =
        process.env.VUE_APP_API_BASE_URL +
        `/video-presigned/v1?walServerRequestid=${requestId}&walToken=${aioToken}`;
      await axios
        .get(videoUrl)
        .then((response) => {
          if (response) {
            this.videoSrc = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Video is not available for the test run");
          this.videoSrc = "404";
        });
    },
    async downloadTestFile(type) {
      const timestamp = this.items.startedAt;
      const date = new Date(timestamp * 1000); // Unix Epoch в миллисекундах, поэтому умножаем на 1000
      // Получаем компоненты даты и времени
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Добавляем 1, так как месяцы в JavaScript нумеруются с 0
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);
      const fileName = `${this.items.runName}-${day}/${month}/${year}-${hours}:${minutes}:${seconds}.wring`;
      let message = "";
      if (type === "csv") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/export/${
                this.items.requestId
              }/csv?walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "katalonxml") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "JSON") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&fileName=${fileName}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;

            var fileURL = respdata.url;
            console.log("FILE", fileURL);

            const fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            console.log("NAME", response);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-python-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            message = "";
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-sensetalk-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            message = "";
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "allsuggest-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "verified-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=${type}&walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "pdf") {
        return (
          process.env.VUE_APP_API_URL_PREFIX +
          `/testrun/v1/export/${
            this.items.requestId
          }/pdf?walToken=${localStorage.getItem(
            "vue-authenticate.access_token"
          )}`
        );
      } else if (type === "report-pdf") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testrun/v1/export/${
                this.items.requestId
              }/pdf?walToken=${localStorage.getItem(
                "vue-authenticate.access_token"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "rebuild-side-file") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
              `/testscript/v1?requestId=${
                this.items.requestId
              }&fileType=JSON&walToken=${localStorage.getItem(
                "testgold.aioToken"
              )}`
          )
          .then((response) => {
            const respdata = response.data;
            // const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.$notify({
        type: "success",
        message: "Downloading file now! " + message,
      });
    },
    async handleSubmit() {
      if (this.$isDemo === 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default items.",
        });
        return;
      }
      try {
        await this.$store.dispatch("profile/me");
        this.user = {
          ...this.$store.getters["profile/me"],
        };
        if (this.user.roles[0].name === "member") {
          this.$notify({
            type: "danger",
            message: "Oops, you are not authorized to do this action.",
          });
          return;
        }
        if (this.file) {
          await this.$store.dispatch("items/upload", {
            item: this.item,
            image: this.file,
          });
          this.item.image = await this.$store.getters["items/url"];
        }
        this.item.tags = [];
        this.tags.forEach((tag) => {
          this.item.tags.push({
            id: tag,
            type: "tags",
          });
        });
        await this.$store.dispatch("items/update", this.item);
        this.$notify({
          type: "success",
          message: "Item updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, cannot update Item. If its persist, please contact support",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    async setSalesforceVar() {
      this.loadingSalesforce = true;
      const isSalesforceTest = false;
      if (this.items.testStepList) {
        isSalesforceTest = await this.items.testStepList.map((item) => {
          if (item.inputValue) {
            if (
              item.inputValue.includes("my.salesforce.com") ||
              item.inputValue.includes("salesforce.com") ||
              item.inputValue.includes("lightning.force.com")
            ) {
              return true;
            }
          }
        });
      }
      if (isSalesforceTest) {
        try {
          await axios
            .get(process.env.VUE_APP_API_URL_PREFIX + `/rl/v1/salesforce`)
            .then((response) => {
              if (response.status === 200) {
                this.lookedSalesforceIntegration = true;
                this.items.salesforcevar = response.data.results;
                this.loadingSalesforce = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `an error occurred while checking the salesforce integration `,
          });
          this.loadingSalesforce = false;
        }
      } else {
        this.loadingSalesforce = false;
      }
    },
    //#endregion
  },

  watch: {
    webSocketServerData: {
      handler() {
        const container = this.$el.querySelector("#WebSocketLiveLogs");
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
      },
      deep: true,
    },
  },

  async created() {
    try {
      await Promise.all([
        this.get(),
        this.initWebsocketStreaming(),
        this.forToggleCoverageReportBtn(),
        this.populateIntegrationsTable(),
        this.getSubscriptionInfo(),
      ]);
    } catch (e) {
      console.log(`ERROR ${e}`);
    }
  },
};
</script>

<style scopped lang="scss">
$pColor: #5e72e4;
$txtColor: #4b4b71;
// .custom-tableTestEnc .bpsd_dtb{
//   overflow-y: scroll;
//   height: 50vh;
// }
.italic {
  font-style: italic;
}

.list-group-item {
  margin-bottom: 25px;
  cursor: move;
  border-radius: 0.6rem !important;
  border: solid 0.1px rgba($txtColor, 0.26) !important;

  &:hover {
    box-shadow: 0 10px 30px -10px rgba(#131313, 0.3);
  }
}

.text-small {
  font-size: 0.85rem;
}

.bold {
  font-weight: 600;
  line-height: 0.9rem;
}

.el--flex {
  align-items: center;
}

.trs--container {
  padding: 13px 0 !important;
}

.btn--container {
  margin: 20px 0;
  height: 70px;

  .btn--content {
    float: right;
    border: solid 0.1px rgba($pColor, 0.3);

    .el-card__body {
      padding: 10px !important;

      * {
        font-size: 0.8rem !important;
      }
    }
  }
}

.b {
  border: 1px solid red;
}

.el-table__row {
  cursor: pointer;
}

.button-aligned {
  margin-bottom: 0;
}

.screenshot-div {
  height: auto;
  cursor: pointer;
  overflow: hidden;
}

.click-here {
  cursor: pointer;
}

.click-here a {
  color: black;
  text-decoration: none;
}
.custom-header {
  display: flex;
}
.test-run-left,
.test-run-right {
  border: 2px solid gray;
  height: 100%;
  padding: 5px;
}
.add_button_not_active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  font-size: 0.75rem;
  border-radius: 30px;
  color: var(--main-blue-color);
  width: 112px;
  height: 44px;
  line-height: normal;
}

.test-run-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.test-run-row p {
  margin: 0;
  color: black;
  font-size: 13px;
  font-weight: 600;
}

.temp2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.script-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.script-heading i {
  cursor: pointer;
}

.script {
  width: 100%;
  background-color: #464547;
  padding: 0px 5px;
  overflow: auto;
}

.script pre {
  color: #ffffff;
  font-size: 12px;
}

.script-second .script {
  color: #ffffff;
  background-color: #464547;
  font-size: 12px;
  width: 100%;
  height: 350px;
  padding: 0px 5px;
  overflow-y: scroll;
  word-break: break-all;
}

.script-second .script pre {
  /* inline-size: min-content;
  color: red;
  font-size: 12px;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-wrap: break-word; */
  white-space: pre-wrap;
}

.console-modal-container .modal-dialog {
  width: 90vw !important;
}

.console-modal-container .modal-dialog .modal-content {
  height: 600px;
}

.websocket-modal-container .modal-dialog .modal-content {
  max-height: 700px;
}

.websocket-modal .script-cont .script {
  padding: 0px 5px;
  overflow-y: scroll;
  max-height: 350px;
}

.websocket-modal .script-cont .script pre {
  white-space: pre-wrap;
}

.video-loader {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-nav-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.custom-nav-tabs .nav-link {
  padding-left: 77px;
  padding-right: 89px !important;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 0;
}
.nav-tabs .nav-link.active {
  background: var(--main-blue-color);
  color: #efefef;
}
.video-modal img {
  width: 70px;
  height: 70px;
}
.down-btn-container {
  display: flex;
  gap: 5px;
}
.conditional-download-icons .down-btn-container button {
  background: none;
  border: 0;
  padding-right: 15px;
  outline: none;
}

.conditional-download-icons .dropdown-menu {
  min-width: auto !important;
  clear: both;
  left: -100px;
}

.conditional-download-icons .subdownbtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.conditional-download-icons .dropdown-menu button {
  background: none;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.conditional-download-icons .down-btn-container button:hover {
  opacity: 0.65 !important;
}

.conditional-download-icons .dropdown-toggle::after {
  vertical-align: 0em !important;
}

.top-card .card-body {
  padding: 10px 20px !important;
}

.second-card .card-header {
  padding: 15px 10px !important;
}

.custom--dropdown {
  margin-left: 39vw;
  margin-right: 5px;
}

@media (max-width: 400px) {
  .adaptive--breadcrumb {
    display: none;
  }

  .adpt--btn {
    span {
      display: none;
    }
  }
}
.btn-group {
  gap: 5px;
}
.left-side-bar.is-close {
  .custom-btn-group .button-menu {
    i {
      // margin-left: 40%;
    }
    span {
      // display: none;
    }
  }
}
.rerun-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.rerun-container button {
  border: 1px solid var(--main-blue-color);
  color: var(--main-blue-color) !important;
}
.rerun-container button i span {
  color: var(--main-blue-color) !important;
}
.container--buttons {
  right: 10px;
  gap: 5px;
  display: flex;
}
.test-buttons-triple:focus {
  background-color: #d6d6d6 !important;
  border-color: #d6d6d6 !important;
}
.test-buttons-triple {
  padding: 6px !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-buttons-triple i {
  margin-left: 0 !important;
}

.custom--dropdown--item {
  padding: 0 !important;

  i {
    margin-right: 0 !important;
  }

  button {
    width: 37px !important;
  }
}
.tst-run {
  color: black;
}

.custom--btn-dropdown {
  width: 39px;
  height: 33px;
}
.url-alert-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.second-card-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 690px) {
  .second-card-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .down-btn-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 815px) {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
    margin: 1rem 3rem 1rem;
  }

  .modal-dialog img {
    width: 100%;
  }
}
#btnAdvanced,
#viewEditSteps,
#btnShowRuns,
#btnShowSetup {
  justify-content: center;
  text-align: center;
  padding: 8px 14px;
  height: 40px;
  width: 110px;
  span {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.mid--page {
  .tab--btn {
    padding: 1rem 1.5rem;
    border: none;
    border: solid 0.1px rgba(#dbdbdb, 0.3);
    border-radius: 30px;
    color: var(--main-blue-color);
    background: #dbdbdb;

    span {
      font-size: 0.75rem !important;
      font-weight: 600;
      color: var(--main-blue-color);
    }

    &.active {
      border-color: var(--main-blue-color);
      background: var(--main-blue-color);
      color: #e6e6e6;

      span {
        color: #e6e6e6;
      }
    }

    &.secondary {
      background-color: #dbdbdb;
      border: var(--main-blue-color);
      color: #e6e6e6;

      span {
        color: var(--main-blue-color);
      }
      i {
        color: var(--main-blue-color);
      }

      &.active {
        background: var(--main-blue-color) !important;
        color: #e6e6e6 !important;

        span {
          color: #e6e6e6 !important ;
        }
        i {
          color: #e6e6e6;
        }
      }
    }
  }

  &.no-border {
    border: none;
  }
}

.button-menu-item {
  display: flex;

  i {
    margin-left: 25%;
  }
}

.dropdown-menu {
  transform: translateX(-100px);
}

.ic--btn {
  border: solid 0.1px rgba(#d6d6d6, 0.5);
  background: #d6d6d6;
  padding: 8px 8px;
  border-radius: 0.25rem;

  i {
    color: $pColor;
    font-size: 0.9 rem;
  }

  img {
    width: 20px;
  }

  &:hover {
    background: #d6d6d6;
    border-color: #d6d6d6;
  }

  &::after {
    border-top-color: #d6d6d6;
    border-bottom-color: #d6d6d6;
  }

  &.no-border {
    border: none;
    padding: 0;
  }

  &.small {
    i {
      font-size: 0.7rem;
    }

    img {
      width: 15px;
    }
  }
}

.log-status mark,
.log-status-blink mark {
  background: #5f72e4;
  color: #fff;
  border-radius: 3px;
}

.log-status-blink mark {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 690px) {
  .second-card-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .down-btn-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 815px) {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
    margin: 1rem 3rem 1rem;
  }

  .modal-dialog img {
    width: 100%;
  }
}
.adpt--menu {
  display: flex;
  gap: 5px;
}
.custom-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
