<template>
  <div
    class="container-fluid list-item-page right-content"
    style="overflow-y: scroll !important"
  >
    <div class="c--breadcrumb my-3">
      <router-link to="/details/test-explorer/list-tests"
        ><i class="el-icon-house"></i
      ></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link to="/details/project-explorer/list-projects">
        Apps
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <router-link
        :to="`/details/project-explorer/view-project/${$route.params.id} ${
          $route.query.wring ? '?wring=true' : ''
        }`"
      >
        {{ $route.params.name }}
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Video</span>
    </div>
    <div class="page_name">
      <h3 style="margin-bottom: 0">Video Session</h3>
    </div>

    <template>
      <div id="container-rrweb" ref="container_rrweb" class="big-rrweb">
        <div class="tblb--row mb-2" v-if="loadingVideo">
          <div class="tblb--inner flex justify-center">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>

        <template v-else-if="events.length > 0">
          <div
            class="flex"
            style="
              justify-content: space-between;
              width: 100%;
              margin-top: 18px;
            "
          >
            <el-input
              class="custom-pad input-video-session"
              placeholder="Search"
              v-model="queryProject"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
                style="
                  transform: rotate(0deg) !important;
                  font-size: 20px;
                  margin-left: 12px;
                "
              ></i>
            </el-input>
            <div class="container-top--video mb-1" style="align-items: center">
              <div class="video-btns-commands">
                <el-tooltip content="Show Steps" placement="top" effect="light">
                  <el-button
                    @click.stop="showStepsList()"
                    class="table-buttons-custom"
                    type="danger"
                    size="small"
                    ><span class="list-ul-icon"></span
                  ></el-button>
                </el-tooltip>

                <!-- <el-dropdown  id="dropDownDownload" placement="bottom-start" trigger="click" @command="downloadTestFile">
        <el-button  class="custom_btn_video ni-download" circle type="primary" @click="fileBackup.length < 1 ? getFiletest('down') : ''"

          v-loading="loadingFile">
        </el-button>
        <el-dropdown-menu class="el_dropdownMenuDown" slot="dropdown" style="max-height: 50vh; overflow-x: auto"
          v-loading="loadingFile">
          <div v-for="(ls, id) in fileBackup" :key="id" class="px-3 py-1 list--downloadTestFile"
            @click="downloadTestFile(id)">
            {{ ls.name }}
          </div>
          <el-dropdown-item icon="el-icon-download" v-for="(ls, id) in fileBackup" :key="id" :command="id">
            {{ ls.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip content="Add to test suite" placement="top" effect="light">


        <el-button id="add-test--suit"    class="ml-3 large_btn custom_btn_video ni-bullet-list-67_dark" type="primary" size="default"
          @click="getFiletest('run'), addTestModal = true" circle>


        </el-button>
      </el-tooltip> -->
                <!-- <el-tooltip content="Show Guide Line" placement="top" effect="light">
        <el-button icon="el-icon-question " class="ml-3 large_bnt custom_btn_video" type="info" size="default" circle
          @click="showGuide()"></el-button>
      </el-tooltip> -->
              </div>
            </div>
          </div>
          <div class="session-player-body" style="overflow: hidden">
            <div
              style="margin-top: 70px"
              class="rrwew-content"
              id="rrweb"
              ref="rrweb"
              :class="setLoad == true ? 'buffering' : ''"
            >
              <!-- <RrWebPlayer :key="currentTime" :events="events" :skipInactive="false" :autoplay="true" :width="widthRrplayer" :goto="currentTime" /> -->
            </div>
            <div class="container-pick">
              <div class="mid--page_container">
                <h3 class="header-text-mid-page">Actions</h3>

                <div class="mid--page pb-4 mb-6ty3">
                  <div style="display: flex"></div>
                </div>
              </div>
              <div
                id="event-list"
                class="event--liste active_event"
                :v-loading="loadingEvent"
              >
                <div
                  style="overflow: visible"
                  v-for="(ls, id) in tagsEvent"
                  :key="id"
                  class="content"
                  @click="startEvent(ls.start_time)"
                >
                  <!-- <div class="icon--event">
                    <i style="color:black;font-size: 8px;" class="fas fa-circle"></i>
                  </div> -->
                  <div class="right--content">
                    <div class="time--tag" style="overflow: visible">
                      <el-tooltip v-if="id === 0" :content="'autocomplete'"
                        ><i
                          style="width: 24px; height: 24px"
                          class="ni-stick"
                        ></i
                      ></el-tooltip>
                      <el-tooltip
                        v-else-if="ls.properties.$event_type === 'change'"
                        :content="ls.properties.$event_type"
                        ><i
                          style="width: 24px; height: 24px"
                          class="ni-pencil"
                        ></i
                      ></el-tooltip>
                      <!-- <el-tooltip v-else-if="ls.properties.$event_type === 'wring:click'" :content="ls.properties.$event_type"></el-tooltip> -->
                      <el-tooltip
                        v-else-if="ls.properties.$event_type === 'click'"
                        :content="ls.properties.$event_type"
                        ><i class="ni-click"></i
                      ></el-tooltip>
                      <el-tooltip
                        v-else-if="ls.properties.$event_type === 'verify'"
                        :content="ls.properties.$event_type"
                        ><i style="width: 24px; height: 24px" class="ni-eye"></i
                      ></el-tooltip>
                      <el-tooltip
                        v-else-if="ls.properties.$event_type === 'submit'"
                        :content="ls.properties.$event_type"
                        ><i
                          style="width: 24px; height: 24px"
                          class="ni-check"
                        ></i
                      ></el-tooltip>
                      <el-tooltip
                        v-else-if="ls.properties.$event_type === 'select'"
                        :content="ls.properties.$event_type"
                        ><i class="ni-drop-down"></i
                      ></el-tooltip>
                      <el-tooltip v-else :content="ls.properties.$event_type"
                        ><i
                          style="width: 24px; height: 24px"
                          class="ni-check"
                        ></i
                      ></el-tooltip>

                      <!-- <h4 class="text-type-event">{{
                          
                            id === 0
                            ? "AUTO"
                            
                            : ls.properties.$event_type === "change"
                              ? "T"
                              : !ls.properties.$event_type
                                ? ls.event.replace("$", " ")
                                : ls.properties.$event_type
                                
                          }}</h4> -->
                      <el-tooltip
                        v-if="!$route.query.wring"
                        class="item"
                        effect="light"
                        placement="top"
                        :content="
                          id === 0
                            ? ls.properties.$current_url
                            : ls.elements.length === 0
                            ? ls.properties.$current_url
                            : ls.elements[0]?.$el_text === null ||
                              ls.elements[0]?.$el_text === ''
                            ? ls.elements[0]?.tag_name
                            : ls.elements[0]?.$el_text
                        "
                      >
                        <h4 v-if="!$route.query.wring" class="text-info-event">
                          {{
                            id === 0
                              ? ls.properties.$current_url
                              : ls.elements.length === 0
                              ? ls.properties.$current_url
                              : ls.elements[0]?.$el_text === null ||
                                ls.elements[0]?.$el_text === ""
                              ? ls.elements[0]?.tag_name
                              : ls.elements[0]?.$el_text
                          }}
                        </h4>
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        class="item"
                        effect="light"
                        placement="top"
                        :content="
                          id === 0
                            ? ls.properties.$current_url
                            : ls.elements.length === 0
                            ? ls.properties.$current_url
                            : ls.elements[0]?.$el_text === null ||
                              ls.elements[0]?.$el_text === ''
                            ? ls.elements[0]?.tag_name
                            : ls.elements[0]?.$el_text
                        "
                      >
                        <!-- <h4 class="text-type-event">{{
                            id === 0
                            ? ls.event.replace("$", "")
                            : ls.properties.$event_type === "change"
                              ? ls.elements[0]?.tag_name === 'select' ? "select" : "typed "
                              : !ls.properties.$event_type
                                ? ls.event.replace("$", " ")
                                : ls.properties.$event_type
                          }}</h4> -->
                        <h4 class="text-info-event">
                          {{
                            id === 0
                              ? ls.properties.$current_url
                              : ls.elements.length === 0
                              ? ls.properties.$current_url
                              : ls.elements[0]?.$el_text === null ||
                                ls.elements[0]?.$el_text === "" ||
                                ls.elements[0]?.$el_text === "KO"
                              ? ls.elements[0]?.tag_name === "select"
                                ? "dropdown"
                                : ls.elements[0]?.tag_name
                              : ls.elements[0]?.$el_text
                          }}
                        </h4>
                      </el-tooltip>

                      <span
                        style="
                          overflow: visible;
                          white-space: nowrap;
                          width: 20px;
                        "
                      >
                        {{ secondsToRealTime(ls.start_time) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="wrapper-alert--card">No Video Session Loaded</div>
        </template>
      </div>
    </template>

    <!-- Modal add test start-->
    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="addTestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("list_item_page.generate_test") }}
          </h5>
        </template>
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 64px; width: 64px"
            />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label is-required" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input
                  type="text"
                  id="run_name"
                  name="run_name"
                  :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="run_name"
                />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select
                  id="Test_Script_Files"
                  v-model="filesList"
                  multiple
                  collapse-tags
                  laceholder="Select execution file"
                >
                  <el-option
                    v-for="(item, id) in fileBackup"
                    :key="id"
                    :label="item.name"
                    :value="item"
                  >
                    <span style="float: left; width: 100%; color: #1f213e">{{
                      item.name
                    }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <button
              class="cancel_button"
              @click="
                addTestModal = false;
                cleanUpState();
              "
            >
              {{ $t("list_item_page.close") }}
            </button>

            <el-tooltip
              :disabled="!(filesList.length === 0 || run_name === '')"
              :content="`${run_name === '' ? 'Run name cannot be empty' : ''} ${
                filesList.length === 0 ? 'Please upload at least one file' : ''
              }`"
              placement="top"
            >
              <span>
                <button
                  :disabled="filesList.length === 0 || run_name === ''"
                  class="add_button"
                  @click="saveAddFileTestSuite"
                >
                  Add
                </button>
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add test end-->
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import RrWebPlayer from "@preflight-hq/rrweb-player-vue";
import rrwebPlayer from "rrweb-player";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "rrweb-player/dist/style.css";
export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  data() {
    return {
      isResize: false,
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      widthRrplayer: 0,
      queryProject: "",
      result: [],
      filteredResults: [],
      filteredResultsSession: [],
      to: 0,
      toSession: 0,
      from: 0,
      fromSession: 0,
      total: 0,
      totalSession: 0,
      loading: true,
      loadingSession: false,
      toShow: [],
      toShowSession: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      query: "",
      querySession: "",
      querySessionEvent: "",
      queryCoveringtests: "",
      querySessionId: "",
      resultSession: {},
      showEvents: false,
      showSessions: true,
      events: [],
      tagsEvent: {},
      resultEvent: {},
      showVideo: false,
      id: "",
      loadingVideo: false,
      addTestModal: false,
      orEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      filesList: [],
      filesListName: "",
      loadingModalAddRun: false,
      lastUrlTest: "",
      showProject: false,
      showEvent: true,
      addTestProject: false,
      projectName: "",
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      projectId: null,
      showDetailProject: false,
      loadingDetailProject: true,
      selectProjectId: null,
      ultraIpt: "",
      showCoveringtests: false,
      toShowCoveringtests: [],
      date_to: undefined,
      date_from: undefined,
      limitCoveringtests: 10,
      percent: 20,
      loadingShowCoveringtests: false,
      fileBackup: [],
      toggleShowFilters: false,
      activeSessionDuration: 0,
      autoplay: false,
      mommentVideo: "",
      resultCoveringtests: [],
      loadingFile: false,
      projectDetail: [],
      currentTime: 0,
      webPlayer: null,
      loadingEvent: true,
      suiteVideo: null,
      lasttime: 0,
      setLoad: false,
      intervalId: null,
      isFetching: false,
      isShowControls: false,
    };
  },
  async created() {
    console.log("PARAMS", this.$route);
    await this.detailsSession();
    if (
      this.$route.params.recording_duration != "none" &&
      this.$route.params.start_time != "none"
    )
      await this.getEventSession();
    this.filterSearchValue();
    this.run_name = this.$route.params.run_name;
    await this.$nextTick();
    await this.addPik();
    await this.nextVideoSession();
  },
  methods: {
    remove(i) {
      this.filesList.splice(i, 1);
    },
    getReplayerHtml(events) {
      const content = `<html><head><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/rrweb-player@latest/dist/style.css"\/><script src="https://cdn.jsdelivr.net/npm/rrweb-player@latest/dist/index.js"><\/script><\/head><body>
                <script>
                  const events = ${JSON.stringify(events).replace(
                    /<\/script>/g,
                    "<\\/script>"
                  )};
                  window.replayer = new rrwebPlayer({
                    target: document.body,
                    props: {
                      events,
                      showController: true,
                      width: document.body.clientWidth,
                      autoPlay: true, 
                      skipInactive: true,
                      mouseTail: false
                    },
                  });
                  
                  // window.replayer.addEventListener('event-cast', (event) => window.onReplayEvent(event));            
                <\/script>
              <\/body>
            <\/html>
        `;
      // Open a new window
      var newWindow = window.open();

      // Injects HTML code into new window
      newWindow.document.open();
      newWindow.document.write(content);
      newWindow.document.close();
    },
    // showControlls(){
    //   var controller = document.querySelector('.rr-controller.svelte-19ke1iv.svelte-19ke1iv')
    //   controller.style.display = 'flex'
    // },
    // closeControlls(){
    //   var controller = document.querySelector('.rr-controller.svelte-19ke1iv.svelte-19ke1iv')
    //   controller.style.display = 'none'
    // },
    onMountedRRweb() {
      if (this.webPlayer) {
        this.webPlayer.$destroy();
      }
      const rrweb = document.getElementById("rrweb");
      this.webPlayer = new rrwebPlayer({
        target: rrweb, // customizable root element
        props: {
          events: this.events,
          width: this.widthRrplayer,
          height: 390,
          autoPlay: true,
          skipInactive: true,
          goTo: this.currentTime,
          mouseTail: true,
        },
        liveMode: true,
      });
      // rrweb.addEventListener('mouseenter', () =>{
      //   this.showControlls()
      // })
      // rrweb.addEventListener('mouseleave', () =>{
      //   this.closeControlls()
      // })
      const playerWeb = this.webPlayer;
      let buttonSetting = document.querySelector(".rr-controller__btns");
      let rrFrame = document.querySelector(".rr-player__frame");
      let counterClicks = 0;
      buttonSetting.childNodes[9].addEventListener("click", (e) => {
        if (counterClicks === 0) {
          rrFrame.style.height = "560px";
          rrFrame.style.width = "100% !important";
        }
      });

      this.$nextTick(() => {
        const wrapper_replayer = document.querySelector(".replayer-wrapper");
        const rr_controller = document.querySelector(".rr-controller");
        const rr_timeline = document.querySelectorAll(".rr-timeline__time");
        wrapper_replayer.appendChild(rr_controller);
        let spnaTimeLine = document.createElement("span");
        spnaTimeLine.innerHTML = "/";
        buttonSetting.insertBefore(rr_timeline[1], buttonSetting.childNodes[1]);
        buttonSetting.insertBefore(spnaTimeLine, buttonSetting.childNodes[1]);
        buttonSetting.insertBefore(rr_timeline[0], buttonSetting.childNodes[1]);
        console.log(buttonSetting.childNodes[12]);
        buttonSetting.childNodes[12].addEventListener("click", function () {
          this.isResize = !this.isResize;
          if (this.isResize) {
            wrapper_replayer.classList.add("scalemax");
          } else {
            wrapper_replayer.classList.remove("scalemax");
          }
        });
      });

      playerWeb.addEventListener("ui-update-player-state", (e) => {
        if (e.payload === "paused") {
          if (this.suiteVideo != null) {
            this.setLoad = true;
            setTimeout(() => {
              this.setLoad = false;
            }, 2000);
          }
          // const _replayer = document.querySelector(".replayer-wrapper");
          // if (document.querySelector(".pause__button")) {
          //   _replayer.childNodes[0].remove();
          // }
          // if (document.querySelector(".pause__button__main")) {
          //   _replayer.childNodes[0].remove();
          // }
          // const text = document.createElement("button");
          // const img = document.createElement("img");
          // text.className = "pause__button";
          // text.style.display = "flex";
          // text.style.position = "absolute";
          // text.style.width = "100%";
          // text.style.height = "100%";
          // text.style.cursor = "pointer";
          // text.style.border = "none";
          // text.style.backdropFilter = "blur(2px)";
          // text.style.backgroundColor = "transparent";
          // img.className = "pause__img";
          // img.src =
          //   "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItcGxheSI+PHBvbHlnb24gcG9pbnRzPSI1IDMgMTkgMTIgNSAyMSA1IDMiPjwvcG9seWdvbj48L3N2Zz4=";
          // img.style.width = "100px";
          // img.style.height = "100px";
          // img.style.position = "inherit";
          // img.style.top = "50%";
          // img.style.right = "50%";
          // text.insertAdjacentElement("afterbegin", img);
          // _replayer.insertAdjacentElement("afterbegin", text);
          // text.addEventListener("click", function () {
          //   playerWeb.play();
          //   // this.webPlayer.play()
          // });
        }
        // if (e.payload === "playing") {
        //   // if (this.suiteVideo != null) {
        //   //   this.setLoad = true;
        //   //   setTimeout(() => {
        //   //     this.setLoad = false;
        //   //   }, 2000);
        //   // }
        //   // const _replayer = document.querySelector(".replayer-wrapper");
        //   // const pause = document.querySelector(".pause__button");
        //   // if (_replayer.childNodes[0] && pause) {
        //   //   _replayer.childNodes[0].remove();
        //   // }
        //   // const text_button = document.createElement("button");
        //   // text_button.className = "pause__button__main";
        //   // text_button.style.display = "flex";
        //   // text_button.style.position = "absolute";
        //   // text_button.style.width = "100%";
        //   // text_button.style.height = "100%";
        //   // text_button.style.cursor = "pointer";
        //   // text_button.style.border = "none";
        //   // text_button.style.backgroundColor = "transparent";
        //   // _replayer.insertAdjacentElement("afterbegin", text_button);
        //   // text_button.addEventListener("click", function () {
        //   //   // this.webPlayerPause()
        //   //   playerWeb.pause();
        //   //   // this.webPlayer.pause()
        //   // });
        //   // if (_replayer.childNodes.length >= 5) {
        //   //   _replayer.childNodes[0].remove();
        //   // }
        // }
      });
      console.log("webplayer", this.webPlayer.getMetaData());
      const active = document.querySelector(".active_event");
      let countSteps = 0;
      this.webPlayer.addEventListener("ui-update-current-time", async (e) => {
        this.lasttime = e.payload;
        // try {
        //   if (this.tagsEvent.length > 0) {
        //     this.tagsEvent.map((item, id) => {
        //       if (item.start_time <= e.payload / 1000 || active) {
        //         active.childNodes[id].style.backgroundColor = "#E6E6E6";
        //         active.childNodes[
        //           id
        //         ].childNodes[1].childNodes[0].childNodes[0].style.color = "rgb(65, 75, 108)";
        //       }
        //       if (item.start_time > e.payload / 1000 || active) {
        //         active.childNodes[id].style.backgroundColor = "#E6E6E6";
        //         active.childNodes[
        //           id
        //         ].childNodes[1].childNodes[0].childNodes[0].style.color =
        //           "#414B6C";
        //       }
        //     });
        //   }
        // } catch (error) {
        //   console.error(error)
        //   console.error("ERROR")
        // }
        // if (
        //   e.payload / 1000 >
        //   this.webPlayer.getMetaData().totalTime / 1000 / 2 &&
        //   !this.isFetching
        // ) {
        //   console.log("too much")
        //   await this.nextVideoSession();
        // }
      });
      this.addPik();
    },
    filterSearchValue() {
      if (Array.isArray(this.tagsEvent)) {
        this.tagsEvent.sort((a, b) => a.start_time - b.start_time);
        this.tagsEvent = this.tagsEvent.filter(
          (item) => !item.event.includes("wring:click")
        );
        console.log("dadsdadsad", this.tagsEvent);
      }

      if (!this.querySessionEvent) {
        return this.tagsEvent;
      } else {
        let filterRe = new RegExp(this.querySessionEvent, "i");
        if (this.querySessionEvent === "typed") {
          filterRe = new RegExp("change", "i");
        }
        return this.tagsEvent.filter((item) => {
          return (
            item.id.match(filterRe) ||
            item.distinct_id.match(filterRe) ||
            item.elements_chain.match(filterRe) ||
            item.event.match(filterRe) ||
            item.properties?.$event_type?.match(filterRe)
          );
        });
      }
    },
    initializeSessionState() {
      if (this.webPlayer !== null) this.webPlayer.goto(this.currentTime, true);
    },
    getIcon(action) {
      if (action == "$pageview") {
        return "el-icon-view";
      } else if (action == "$autocapture") {
        return "el-icon-position";
      } else if (action == "$pageleave") {
        return "el-icon-news";
      } else {
        return "el-icon-help";
      }
    },
    async startEvent(s) {
      this.currentTime = s * 1000;
      await this.addPik();
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    changeFile(file) {
      this.filesList = [file];
    },
    showResult(val) {
      this.showEvents = false;
      this.showSessions = false;
      if (val == "event") {
        this.showEvents = true;
      }
      if (val == "session") {
        this.showSessions = true;
      }
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.resultProject = res.data.response;
          this.resultProject.map((item) => {
            if (item.projectId.toString() === this.$route.params.id) {
              this.projectName = item.name;
            }
          });
        } else {
          this.resultProject = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error has occurred ",
        });
      } finally {
        this.loadingProject = false;
      }
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      } else if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      } else if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },

    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    },
    async processRun(val) {
      let url = val.person.properties.$initial_current_url.replace(
        /https:\/\//g,
        ""
      );
      this.run_name =
        url +
        " " +
        val.person.properties.$initial_browser +
        " " +
        val.person.properties.$geoip_country_name;
      this.filesList = [];
      this.fileBackup = [];
      await this.detailsSession(val);
    },
    async processShowVideo(val) {
      console.log("In processShowVideo method");
      console.log("Val from video is: ", val);
      val.id = val.tests[0].session_id;
      console.log("Video id:", val.id);
      await this.detailsSession(val);
    },
    async addTest() {
      console.log(this.filesList);
      const ifMultipleFiles = this.filesList.length > 1;
      const files = this.filesList;
      let scriptType = "JSON";
      var single = false;
      var double = false;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail
          ? "continue"
          : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        runTags: parsedRunTags.join(","),
      };
      const formData = new FormData();
      let reqHeaders = {};
      if (ifMultipleFiles) {
        double = true;
        reqHeaders = {
          "x-test-script-type": scriptType,
          "Content-Type": "multipart/form-data;",
        };
        formData.append("suiteName", this.run_name);
        formData.append("suiteDesc", this.run_description);
        formData.append("suiteRunName", `Test suite run - ${this.run_name}`);
        formData.append("suiteRunOptions", JSON.stringify(runOptions));
        formData.append("suiteRunTags", JSON.stringify(parsedRunTags));

        const suiteFiles = JSON.stringify(
          files.map((item, index) => {
            formData.append(
              "attachedFile",
              this.filesList[index],
              this.filesList[index].name
            );
            return {
              fileName: item.name,
              fileType: item.type,
              position: index + 1,
            };
          })
        );
        formData.append("suiteFiles", suiteFiles);
      } else {
        single = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch":
            this.run_name +
            " file_" +
            files[0].name
              .replace("generated-test-run", "")
              .replace(".side", ""),
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors
            ? "1"
            : "0",
        };

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }
        formData.append("attachedFile", files[0], files[0].name);
      }
      if (single) {
        try {
          this.loadingModalAddRun = true;
          const res = await axios.post(
            process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1",
            formData,
            { headers: reqHeaders }
          );
          if ((res.data.status = "queued")) {
            this.run_description = "";
            this.addTestModal = false;
            this.pollStatus = true;
            this.singleTestId = true;
            this.loadingModalAddRun = false;
            this.lastUrlTest = "/details/test-explorer/list-tests";
            // swal.fire(`<div style="width: 100%;">Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a></div>`);
            this.$notify({
              type: "success",
              message: `Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a>`,
            });
          } else {
            this.$notify({
              type: "danger",
              message: "execution failed",
            });
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "execution failed",
          });
        }
        this.loadingModalAddRun = false;
      }
      if (double) {
        this.loadingModalAddRun = true;

        axios
          .post(
            process.env.VUE_APP_API_URL_PREFIX + "/testsuite/v1",
            formData,
            {
              headers: reqHeaders,
            }
          )
          .then((response) => {
            if (response) {
              this.$notify({
                type: "success",
                message: this.run_name + " added successfully.",
              });
              this.run_name = "";
              this.run_description = "";
              this.filesList = [];
              this.addTestModal = false;
              this.pollStatus = true;
              this.singleTestId = true;
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              message: this.run_name + " was not added successfully. Details: ",
              error,
            });
          });

        this.loadingModalAddRun = false;
      }
    },
    downloadTestFile(index) {
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async actionCoveringtests(action, elt) {
      console.log("actionCoveringTests", elt.tests[0].sesion_id);
      this.id = elt.tests[0].sesion_id;
      if (action === "downloadTestFile") {
        await this.getFiletest("dow");
        this.downloadTestFile();
      }
      if (action === "runTest") {
        this.addTestModal = true;
        await this.getFiletest("run");
      }
    },
    initializeCoveringtestsState() {
      this.toShowCoveringtests = this.filteredResultCoveringtestsSet;
      // this.handleSizeCoveringTests();
    },
    forStepNumber(row) {
      return row + this.fromSession + 1;
    },
    removedtag(row) {
      return row.replace("$", "");
    },
    async detailsSession() {
      let projectId = this.$route.params.id;
      this.loadingVideo = true;
      this.id = this.$route.params.session_id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      params.append("page", 1);
      params.append("limit", 50);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const url = this.$route.query.wring
        ? process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/snapshots/v2/wring?" +
          params
        : process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/snapshots/v2/wring?" +
          params;
      await axios
        .get(url)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.events = [];
            } else if (response.data.status === "success") {
              this.events = [];
              // let index =  Object.entries(response.data.response.result.snapshot_data_by_window_id)[0]
              // this.events = response.data.response.result.snapshot_data_by_window_id[index[0]]
              let allVideo = [];
              this.events = response.data.response.snapshots;
              this.suiteVideo = response.data.next;

              this.loadingVideo = false;
            } else if (response.data.status === "failure") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.events = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data ",
          // });
        })
        .finally((e) => {
          this.loadingVideo = false;
          this.initializewidthRrplaye2();
          this.onMountedRRweb();
        });
    },
    releaodVideo() {
      if (
        this.suiteVideo != false ||
        this.suiteVideo != null ||
        this.suiteVideo != "null"
      ) {
        this.setLoad = true;
        setTimeout(() => {
          this.setLoad = false;
          this.currentTime = this.lasttime;
          this.onMountedRRweb();
          this.initializeSessionState();
        }, 500);
      }
    },
    async nextVideoSession() {
      // this.intervalId = setInterval(async () => {
      //   this.releaodVideo()
      // }, 30000);
      // console.log("siuteVideo", this.suiteVideo)
      this.isFetching = true;
      if (this.suiteVideo) {
        let projectId = this.$route.params.id;
        this.id = this.$route.params.session_id;
        const params = new URLSearchParams();
        params.append("project_id", projectId);
        params.append("session_id", this.id);
        params.append("page", this.suiteVideo);
        params.append("limit", 50);
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 300);
        };
        const url = this.$route.query.wring
          ? process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/snapshots/v2/wring?" +
            params
          : process.env.VUE_APP_API_URL_PREFIX +
            "/pageviews/snapshots/v2/wring?" +
            params;
        await axios
          .get(url)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                // this.$notify({
                //   type: "danger",
                //   message: "the recovery of the video failed ",
                // });
                console.log("Recovery of the video failed");
                this.events = [];
              } else if (response.data.status === "success") {
                // this.events = [];
                // let allVideo = [];
                this.events = this.events.concat(
                  response.data.response.snapshots
                );

                // replayer.addEvent(event);
                // for (event in response.data.response.snapshots)
                if (this.webPlayer) {
                  response.data.response.snapshots.forEach((x) => {
                    this.webPlayer.addEvent(x);
                  });
                }
                // this.webPlayer.addEvent(response.data.response.snapshots);

                if (response.data.next) {
                  this.suiteVideo = response.data.next;
                  this.isFetching = false;
                } else {
                  this.suiteVideo = null;
                }
                console.log("hello word", this.suiteVideo);
              } else if (response.data.status === "failure") {
                // this.$notify({
                //   type: "danger",
                //   message: "the recovery of the video failed ",
                // });
                console.log("Recovery of the Video failed");
                this.events = [];
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify({
              type: "danger",
              message: "an error occurred while retrieving data ",
            });
          })
          .finally(async (e) => {
            this.releaodVideo();

            clearInterval(this.intervalId);
          });
      }
    },
    showGuide() {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#event-list",
            popover: {
              title: "Event Log",
              description:
                "Explore a comprehensive list detailing each step you've taken throughout your session",
              side: "left",
              align: "start",
            },
          },
          {
            element: "#rrweb",
            popover: {
              title: "Video Session Overview",
              description: " Gain a visual representation of your session.",
              side: "top",
              align: "start",
            },
          },
          {
            element: "#add-test--suit",
            popover: {
              title: "Integrate into Test Suite ",
              description:
                "Effortlessly execute your session by incorporating it into the test suite.",
            },
          },
          {
            element: "#el_dropdownDown",
            popover: {
              title: "Download Your Sessions",
              description: "Conveniently download your session here.",
            },
          },
          {
            element: 'label[for="skip"]',
            popover: {
              title: "Skip Inactivity",
              description:
                "Accelerate video viewing by skipping inactive segments here.",
            },
          },
        ],
      });
      driverObj.drive();
    },
    async getEventSession() {
      this.autoplay = true;
      let projectId = this.$route.params.id;
      this.loadingEvent = true;
      this.id = this.$route.params.session_id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const url = this.$route.query.wring
        ? process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/events/wring?" +
          params
        : process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/events/wring?" +
          params;
      await axios
        .get(url)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.tagsEvent = {};
            } else if (response.data.status === "success") {
              this.tagsEvent = {};
              this.tagsEvent = response.data.response;
              this.tagsEvent.map((item) => {
                item["start_time"] = this.secondsToRealTimeEvent(
                  this.$route.params.start_time,
                  item.timestamp
                );
                return item;
              });
              this.resultEvent = this.tagsEvent;
              this.activeSessionDuration = parseInt(
                this.$route.params.recording_duration
              );
            } else if (response.data.status === "failure") {
              // this.$notify({
              //   type: "danger",
              //   message: "the recovery of the video failed ",
              // });
              this.tagsEvent = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data ",
          // });
        })
        .finally((e) => {
          this.loadingEvent = false;
        });
    },
    async addPik() {
      await this.$nextTick();
      if (this.tagsEvent.length > 0) {
        const lectBar = document.getElementsByClassName("rr-progress")[0];
        const timeLine = document.getElementsByClassName("rr-timeline")[0];
        const progress__handler = document.getElementsByClassName(
          "rr-progress__handler"
        )[0];
        const progress__step =
          document.getElementsByClassName("rr-progress__step")[0];
        const ticks = document.createElement("div");
        ticks.classList.add("ticks");
        for (let index = 0; index < this.tagsEvent.length; index++) {
          if (
            parseInt(this.tagsEvent[index].start_time) <
            parseInt(this.$route.params.recording_duration)
          ) {
            const ticks_box = document.createElement("div");
            ticks_box.classList.add("tick-hover-box");
            let left =
              (parseInt(this.tagsEvent[index].start_time) * 100) /
                this.activeSessionDuration -
              0.5;
            if (left > 100) left = 100 - 0.5;
            if (left < 0) left = 0;
            ticks_box.style.left = left + "%";

            const tick_info = document.createElement("div");
            tick_info.classList.add("tick-info");
            tick_info.innerHTML = this.tagsEvent[index].event;
            const tick_marker = document.createElement("div");
            tick_marker.classList.add("tick-marker");

            const tick_thumb = document.createElement("div");
            tick_thumb.classList.add("tick-thumb");
            ticks_box.setAttribute("id", "tick_thumb_" + index);
            let title_text = "";
            if (this.tagsEvent[index].elements[0].$el_text != "") {
              title_text = this.tagsEvent[index].elements[0].$el_text;
            } else {
              title_text = this.tagsEvent[index].elements[0].tag_name;
            }
            tick_thumb.title = `${title_text}`;

            ticks_box.appendChild(tick_info);
            ticks_box.appendChild(tick_marker);
            ticks_box.appendChild(tick_thumb);
            ticks.appendChild(ticks_box);
          }
        }

        timeLine && (timeLine.style.width = "100%");
        progress__step.style.zIndex = "2";
        progress__handler.style.zIndex = "2";
        lectBar.appendChild(ticks);
      }
      // this.autoplay = true;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    cleanUpState() {
      this.run_name = "";
      this.run_description = "";
      this.run_tags = "";
      this.browser_width = "1920";
      this.browser_height = "1080";
      this.max_wait_time = "5.0";
      this.ignore_disabled_elements = "1";
      this.ignore_invisible_elements = "1";
      this.collect_page_interaction_data = "1";
      this.extra_element_data = 0;
      this.downweight_strings = 0;
      this.all_suggest = 0;
      this.try_to_avoid = false;
      this.bad_attributes_list = "";
      this.good_attributes_list = "";
      this.continue_test_suite_on_fail = "true";
      this.timeout_cancel_run = "";
      this.timeout_cancel_run_value = null;
      this.handle_failure = "";
      this.verify_suggested_selectors = false;
      this.enable_integrations = true;
      this.collapseActive = null;
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getFiletest(val) {
      // const time_gap = await swal.fire({
      //   title: 'Inactivity period for splitting tests',
      //   input: 'number',
      //   inputPlaceholder: '0 to 10,000',
      //   preConfirm: (value) => {
      //     if (value == '') {
      //       swal.showValidationMessage("minimum value is 0");
      //     } else if (parseInt(value) > 10000) {
      //       swal.showValidationMessage("maximum value is 10000");
      //     }
      //     return value;
      //   },
      //   showCloseButton: true,
      // });
      // // if(!parseInt(time_gap.value)){
      // if (isNaN(Number(time_gap.value))) {
      //   if (val === 'run')
      //     this.addTestModal = false
      //   return
      // } else {
      //   this.timeGap = time_gap.value
      // }
      this.timeGap = 0;
      this.loadingFile = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      params.append("time_gap", this.timeGap);
      this.loadingModalAddRun = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL_PREFIX + "/pageviews/testgen?" + params
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
              this.addTestModal = false;
              this.loadingFile = false;
            } else if (response.data.length > 0 || response.status < 400) {
              this.builFile(response.data, val);
              this.loadingFile = false;
            } else {
              this.$notify({
                type: "danger",
                message: `This user video is too short to generate a test, please choose a longer video`,
              });
              this.addTestModal = false;
              this.loadingFile = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message:
              "This user video is too short to generate a test, please choose a longer video",
          });
          this.addTestModal = false;
        });
    },
    showStepsList() {
      this.$router.push({
        name: "Steps List",
        params: {
          projectId: this.$route.params.id,
          nameProject: this.$route.params.name,
          session_id: this.id,
          id: this.$route.params.id,
          testSuiteId: this.$route.params.testSuiteId,
          run_name: this.$route.params.run_name,
          start_time: this.$route.params.start_time,
          recording_duration: this.$route.params.recording_duration,
          page_name: this.$route.params.name,
          // start_time: val.start_time,
        },
        query: {
          wring: true,
        },
      });
    },
    builFile(file, val) {
      this.loadingModalAddRun = true;
      this.filesList = [];
      this.fileBackup = [];
      if (file.length) {
        // Just get the last test
        // file = file[file.length - 1];
        console.log("FILE", file);
        file.map((item, index) => {
          if (item.tests[0].commands[0].command) {
            let jsonStringified = JSON.stringify(item);
            let jsonBlob = new Blob([jsonStringified], {
              type: "application/json",
            });
            const dateTime = new Date(this.$route.params.start_time);
            const day = dateTime.getDate();
            const monthIndex = dateTime.getMonth() + 1;
            const paddedMonth = String(monthIndex).padStart(2, "0");
            const year = dateTime.getFullYear();
            const hours = dateTime.getHours();
            const min = dateTime.getMinutes();
            const sec = dateTime.getSeconds();

            let testFile = new File(
              [jsonBlob],
              `${this.$route.params.name}-${day}/${paddedMonth}/${year}_${hours}:${min}:${sec}.wring`
            );
            this.fileBackup.push(testFile);
          }
        });
        this.filesList = [this.fileBackup[this.fileBackup.length - 1]];
        this.filesListName = this.filesList[0].name;
        this.loadingModalAddRun = false;
        if (val === "down") {
          if (this.fileBackup.length == 1) {
            this.downloadTestFile(0);
            let dropdown_menu = document.getElementsByClassName(
              "el_dropdownMenuDown"
            )[0];
            dropdown_menu.style.left = "1000px";
          } else {
            let element = document.getElementById("el_dropdownDown");
            // Création d'un nouvel événement de clic
            var event = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            // Déclenchement de l'événement de clic sur l'élément
            element.dispatchEvent(event);
            let dropdown_menu = document.getElementsByClassName(
              "el_dropdownMenuDown"
            )[0];
            dropdown_menu.style.left = "1000px";
          }
        }
      } else {
        if (file.tests[0].commands[0].command) {
          let jsonStringified = JSON.stringify(file);
          let jsonBlob = new Blob([jsonStringified], {
            type: "application/json",
          });
          let testFile = new File([jsonBlob], "generated-test-run.wring");
          this.filesList = [testFile];
          this.filesListName = this.filesList.name;
          this.fileBackup = [testFile];
        }
        this.loadingModalAddRun = false;
        if (val === "down") this.downloadTestFile(null);
      }
    },
    initializewidthRrplaye() {
      this.widthRrplayer = this.widthRrplayerCom;
    },
    initializewidthRrplaye2() {
      this.widthRrplayer = this.widthRrplayerCom;
    },
    async saveAddFileTestSuite() {
      this.loadingModalAddRun = true;
      const testSuitRunId = this.$route.params.testSuiteId;
      const files = this.filesList;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const formData = new FormData();
      let suiteFiles = [];

      for (let i = 0; i < files.length; i++) {
        let fileNmae = files[i].name;
        if (this.run_name.length > 1) {
          fileNmae = `${this.run_name.replace(/\s+/g, "-")}_${files[i].name}`;
        }
        formData.append("attachedFile", files[i], fileNmae);
        suiteFiles.push({ operation: "add", fileName: fileNmae });
      }
      formData.append("suiteFiles", JSON.stringify(suiteFiles));
      axios
        .put(
          `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`,
          formData
        )
        .then((response) => {
          // this.items = Object.values(response.data.response);
          if (response.data.status == "success") {
            this.$notify({
              type: "success",
              message: `${this.run_name} added to suite successfully.`,
            });
            this.run_name = "";
            this.run_description = "";
            this.filesList = [];
            this.addTestModal = false;
          } else {
            this.loadingModalAddRun = false;
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Cannot add suite test... Try refresh",
          });
        })
        .finally(() => {
          this.loadingModalAddRun = false;
        });
    },
    async getDetailProject() {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      // check if the project contains mobile screenshots
      const resp = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX +
          "/pageviews/user-projects/" +
          projectId
      );
      if (resp.data && resp.data.status === "success") {
        this.projectDetail = resp.data.response;
      } else {
      }
    },
    async deleteSpan() {
      let buttons = document.querySelector(".rr-controller__btns");
      let div = buttons.querySelector("div");
      let span = div.querySelector("span");
      // [suggestion]
      // had to comment this because is blocking marker loads on the playback
      // span.remove();
    },
  },
  computed: {
    widthRrplayerCom() {
      const el = document.getElementById("container-rrweb");
      return (el.clientWidth / 112) * 86;
    },
  },
  watch: {
    "$refs.container_rrweb.clientWidth": {
      handler: "initializewidthRrplaye",
      immediate: false,
      deep: true,
    },
    currentTime: {
      //variable name
      handler: "initializeSessionState", //function name
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$pColor: #525f7f;
$color: #5e72e4;

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #6e6e6e;
  box-sizing: border-box;
  font-size: 13px;
}
.text-type-event {
  width: 50px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0;
  color: var(--main-blue-color);
  text-transform: uppercase;
}
.text-info-event {
  text-align: center;
  width: 70%;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-blue-color);
}
// .custom_btn {
//   position: fixed;
//   z-index: 9;
//   right: 2.5%;
//   font-size: 44px !important;
//   bottom: 82.5% !important;
// }

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}
.el-select .el-select__tags .el-tag {
  color: var(--main-blue-color);
  box-shadow: 0 1px 2px #dbdbdb;
  background-color: #dbdbdb;
}
.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}
.header-text-mid-page {
  margin: 0;
  padding-left: 30px;
  color: var(--main-blue-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom_btn_video {
  color: var(--main-blue-color);
  background: #dbdbdb;
  padding: 6px !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 33px;
  border-color: #dbdbdb;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}
.video-btns-commands {
  display: flex;
  gap: 5px;
  margin-bottom: 2px;
}

.el-popper[x-placement^="bottom"] {
  left: 79% !important;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.container-top--video {
  display: flex;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.wrapper-alert--cardd {
  margin: auto;
  max-width: 14%;
}

.mid--page {
  // border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
  }

  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;

      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.event--liste {
  overflow: scroll;
  height: 60vh;
  max-height: 580px;
  overflow-x: auto;
  overflow-y: none;
}

event--liste::-webkit-scrollbar {
  display: none;
}
.content {
  background-color: #e6e6e6;
}
.content {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;
  border-radius: 0;
  border-bottom: 1px solid rgba(82, 95, 127, 0.5);
  padding: 10px;

  .time--tag {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 1rem !important;
    width: 96%;
  }

  .right--content {
    width: 100%;
    flex-direction: column;
  }

  .icon--event {
    width: 20px;
    display: flex;
    max-width: 20px;
    background-color: #e6e6e6;
    height: 40px;
    border-radius: 5px;
    text-align: left;
    align-items: center;
    justify-content: center;
  }
}

.content:hover {
  background-color: #e6e6e6;

  .icon--event {
    background-color: #e6e6e6;
  }
}

.session-player-body {
  display: flex;
  // flex-wrap: wrap;
  // flex-direction: row;

  // .rrwew-content {
  //   width: 75%;
  // }

  .container-pick {
    position: relative;
    min-height: 1px;
    width: 25%;
    background: #e6e6e6;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
}

.page_name h3 {
  font-size: 40px;
}
</style>

<style lang="scss">
.buffering {
  .rr-player {
    .rr-player__frame {
      .replayer-wrapper::before {
        content: "buffering...";
        color: #fff;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background: #333333;
        font-size: 40px;
        opacity: 0.2;
      }
    }
  }
}

.replayer-wrapper {
  top: 51%;
}

.update-video {
  .rr-player {
    .rr-player__frame {
      .replayer-wrapper::before {
        content: "updating...";
        color: #fff;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background: #333333;
        font-size: 40px;
        opacity: 0.2;
      }
    }
  }
}

.u--tag {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.15rem 0.35rem;
  border: solid 0.1px rgba(#5e72e4, 0.75);
  background: rgba(#5e72e4, 0.15);
  border-radius: 0.25rem;
}
</style>
